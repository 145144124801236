export default {
  namespaced: true,
  getters: {
    getLayoutParams(state) {
      return state.layoutParams;
    },
  },
  state: {
    layoutParams: {},
  },
  mutations: {
    setLayoutParams(state, data) {
      state.layoutParams = data;
    },
    clearLayoutParams(state) {
      state.layoutParams = {};
    },
  },
  actions: {},
};
