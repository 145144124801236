<template>
  <div>
    <div class="tips-bar">
      <i class="iconfont icon-warning-fill" />
      <div class="right-bar">
        <div>请在节点”输入变量”中配置自定义云函数的输入参数</div>
        <div>1.配置参数内容为：①业务表单中的审批金额；②流程申请人或者其他用户信息</div>
        <div>例如：</div>
        <div>applyAmount_Input ：events_meeting.budget（业务表单的预算金额）</div>
        <div>applyUser_Input： 申请人或者节点用户信息</div>
        <div>2.自定义云函数的计算结果需要按以下指定格式返回指定顺序的审批用户UIN，</div>
        <div>返回格式为 { 顺序数字：用户UIN }，示例如下：</div>
        <div>{{ '{' }}</div>
        <div style="text-indent: 2em">{{ '1: user-uin0001' }}</div>
        <div style="text-indent: 2em">{{ '2: user-uin0002' }}</div>
        <div style="text-indent: 2em">{{ '3: user-uin0003' }}</div>
        <div>{{ '}' }}</div>
      </div>
    </div>
    <div class="node-title">节点ID</div>
    <el-input disabled :value="value.id" />
    <div class="node-title">
      节点名称
      <span class="require">*</span>
    </div>
    <el-input
      :value="value.fullname"
      maxlength="20"
      show-word-limit
      @input="(e) => valueUpdate('fullname', e)"
    />
    <div class="node-title">变量配置</div>
    <slot name="inputs"></slot>
    <slot name="outputs"></slot>
    <div class="node-title">
      选择云函数
      <span class="require">*</span>
    </div>
    <slot name="selectFunction"></slot>
    <div class="node-title">退回节点</div>
    <el-switch
      :value="value.metadata.rollback.enable"
      @change="
        (e) => {
          valueUpdate('metadata.rollback.enable', e);
          valueUpdate('metadata.rollback.rollback_type', e ? 3 : 0);
        }
      "
    ></el-switch>
    <div style="margin-top: 12px" v-if="value.metadata.rollback.enable">
      <el-radio
        :value="value.metadata.rollback.rollback_type"
        :label="1"
        disabled
        @input="
          (e) => {
            valueUpdate('metadata.rollback.rollback_type', e);
            valueUpdate('metadata.rollback.skip', 0);
          }
        "
      >
        上一节点
      </el-radio>
      <el-radio
        :value="value.metadata.rollback.rollback_type"
        :label="2"
        disabled
        @input="
          (e) => {
            valueUpdate('metadata.rollback.rollback_type', e);
            valueUpdate('metadata.rollback.skip', 0);
          }
        "
      >
        指定节点
      </el-radio>
      <el-radio
        :value="value.metadata.rollback.rollback_type"
        :label="3"
        @input="
          (e) => {
            valueUpdate('metadata.rollback.rollback_type', e);
            valueUpdate('metadata.rollback.skip', 0);
          }
        "
      >
        退回到申请人
      </el-radio>
      <el-select
        :value="value.metadata.rollback.skip"
        @change="
          (e) => {
            valueUpdate('metadata.rollback.skip', e);
          }
        "
        style="margin-top: 10px"
        v-if="value.metadata.rollback.rollback_type === 3"
      >
        <el-option
          v-for="item in resubmitPolicy"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="node-title">备注描述</div>
    <el-input
      type="textarea"
      :rows="2"
      :value="value.description"
      @input="(e) => valueUpdate('description', e)"
      maxlength="50"
      autocomplete="off"
      placeholder="请输入"
    ></el-input>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resubmitPolicy: [
        {
          value: 0,
          label: '被退回重新提交后直接回到退回审批人',
        },
        {
          value: 1,
          label: '被退回重新提交后按节点顺序重新审批',
        },
      ],
    };
  },
  computed: {},

  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
@import '@/styles/element/button.scss';

.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-inputbar {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f6f7fb;
}
.intro-title {
  height: 40px;
  line-height: 40px;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 14px;
  color: #667082;
  margin: 10px -10px 0;
  padding-left: 10px;
}
.tips-bar {
  display: flex;
  background: var(--Brand-Brand2-Focus, #d9e1ff);
  padding: 8px 16px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  .iconfont {
    font-size: 14px;
    margin-right: 8px;
    color: #0077ff;
    margin-top: 4px;
  }
  .right-bar {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #384050;
  }
}
</style>
