<template>
  <el-card class="box-card">
    <!-- 表单 -->
    <div class="head">
      <el-form
        :inline="true"
        :model="searchForm"
        class="form demo-form-inline"
        @submit.native.prevent
        @keyup.enter.native="searchList"
      >
        <el-form-item label="操作人">
          <el-input
            v-model="searchForm.sponsor"
            placeholder="操作人查询"
            clearable
            @clear="searchList"
          />
        </el-form-item>
        <el-form-item label="产品">
          <el-select
            v-model="searchForm.product_name"
            placeholder="请选择产品"
            clearable
            @clear="searchList"
            @change="searchList"
          >
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="操作类型">
          <el-input v-model="searchForm.api_name" placeholder="" clearable @clear="searchList" />
        </el-form-item>
        <el-form-item label="接口">
          <el-input v-model="searchForm.request" placeholder="" clearable @clear="searchList" />
        </el-form-item>
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="searchForm.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="searchList"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button @click="resetForm">清空</el-button>
        </el-form-item>
      </el-form>
      <div class="operation-area">
        <el-button type="primary" @click="showRule = true">规则</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table :data="list" v-loading="loading">
      <el-table-column
        v-for="item in white"
        :key="item.key"
        :prop="item.key"
        :label="item.lable"
        :width="item.width || ''"
      >
        <template slot-scope="scope">
          <span v-if="getValue(scope.row[item.key]).length < 41">
            {{ getValue(scope.row[item.key]) }}
          </span>
          <el-tooltip
            v-else
            effect="dark"
            :content="getValue(scope.row[item.key])"
            placement="top-start"
          >
            <span>{{ getValue(scope.row[item.key]).slice(0, 40) }}...</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
    <!-- 详情弹窗 -->
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      center
    >
      <div class="detail">
        <div class="line">
          <div class="left item">field</div>
          <div class="right item">value</div>
        </div>
        <div class="line" v-for="item in getDetail" :key="item.key">
          <div class="left item">{{ item.key }}:</div>
          <div class="right item">
            <span v-if="getValue(item.value).length < 51">
              {{ getValue(item.value) }}
            </span>
            <el-tooltip v-else effect="dark" :content="getValue(item.value)" placement="top-start">
              <span>{{ getValue(item.value).slice(0, 50) }}...</span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 规则弹窗 -->
    <rule :show="showRule" @cancel="showRule = false" />
  </el-card>
</template>

<script>
import rule from './rule/index.vue';
import { whereGql, formatStr } from '@/graphql/util';
import { GetArticleList } from '@/api/audit';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
const white = [
  { key: 'sponsor_name', lable: '操作人', width: '100' },
  { key: 'product_name', lable: '产品' },
  { key: 'api_name', lable: '操作类型' },
  { key: 'request', lable: '接口请求', width: '320' },
  { key: 'action_name', lable: '操作行为' },
  { key: 'operand_name', lable: '操作对象' },
  { key: 'is_success', lable: '操作结果', width: '100' },
  { key: 'create_time', lable: '操作时间', width: '160' },
];
export default {
  components: {
    rule,
  },
  data() {
    return {
      appOptions: [], // 产品列表
      loading: false,
      white,
      searchForm: {
        sponsor: '',
        product_name: '',
        api_name: '',
        request: '',
        date: null,
      },
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      detail: {},
      // 规则
      showRule: false,
    };
  },
  computed: {
    getDetail() {
      return Object.keys(this.detail)
        .map((key) => {
          if (this.keys.includes(key)) {
            return;
          }
          return { key, value: this.detail[key] };
        })
        .filter((item) => {
          return item;
        });
    },
    keys() {
      return white.map((item) => {
        return item.key;
      });
    },
  },
  created() {
    this.getList();
    this.loadAppOption();
  },
  methods: {
    async loadAppOption() {
      try {
        const { data } = await this.$request({
          url: this.$api.getAppList,
          data: { page_size: 999999 },
        });
        this.appOptions = data;
      } catch (err) {
        console.log(err);
      }
    },
    humanifyDate(str) {
      return dayjs(str).format('YYYY-MM-DD HH:mm:ss');
    },
    getValue(val) {
      if (val === null) {
        return '';
      }
      switch (typeof val) {
        case 'string':
          return `${val}`;
        case 'boolean':
          return val ? 'true' : 'false';
        case 'number':
          return `${val}`;
        case 'undefined':
          return '';
        default:
          return JSON.stringify(val);
      }
    },
    searchList() {
      this.page = 1;
      this.getList();
    },
    resetForm() {
      this.page = 1;
      this.searchForm = {
        sponsor: '',
        product_name: '',
        api_name: '',
        request: '',
        date: null,
      };
      this.getList();
    },
    async getList() {
      this.loading = true;
      try {
        // 筛选处理
        let filterArr = [];

        if (this.searchForm.date) {
          filterArr = [
            ...filterArr,
            {
              whereGqlType: 'min',
              create_time: new Date(this.searchForm.date[0]).getTime() / 1000,
            },
            {
              whereGqlType: 'max',
              create_time: new Date(this.searchForm.date[1]).getTime() / 1000,
            },
          ];
        }
        if (this.searchForm.sponsor) {
          filterArr = [
            ...filterArr,
            {
              whereGqlType: 'like',
              sponsor_name: `%${this.searchForm.sponsor}%`,
            },
          ];
        }
        if (this.searchForm.product_name) {
          filterArr = [
            ...filterArr,
            {
              whereGqlType: 'like',
              product_name: `%${this.searchForm.product_name}%`,
            },
          ];
        }
        if (this.searchForm.api_name) {
          filterArr = [
            ...filterArr,
            {
              whereGqlType: 'like',
              api_name: `%${this.searchForm.api_name}%`,
            },
          ];
        }
        if (this.searchForm.request) {
          filterArr = [
            ...filterArr,
            {
              whereGqlType: 'like',
              request: `%${formatStr(this.searchForm.request)}%`,
            },
          ];
        }
        // 请求列表
        const {
          audit_log: {
            _data,
            _aggregate: { count },
          },
        } = await GetArticleList({
          limit: this.pageSize,
          offset: this.pageSize * (this.page - 1),
          //   sponsor: this.searchForm.sponsor,
          filter: whereGql(filterArr),
        });
        this.total = count;
        this.list = _data.map((item) => {
          return {
            ...item,
            create_time: this.humanifyDate(item.create_time * 1000),
            delete_time: item.delete_time === 0 ? '' : this.humanifyDate(item.delete_time * 1000),
            update_time: item.update_time === 0 ? '' : this.humanifyDate(item.update_time * 1000),
            is_success: item.is_success === 1 ? '成功' : '失败',
          };
        });
        this.loading = false;
        console.log(_data);
      } catch {
        this.loading = false;
        this.list = [];
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.page = 1;
      this.getList();
    },
    handleClick(row) {
      this.detail = row;
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.head {
  .operation-area {
    display: flex;
    justify-content: flex-end;
  }
}

.detail {
  .line {
    border: 1px solid #f1f1f1;
    min-height: 36px;
    display: flex;
    .item {
      text-align: center;

      font-size: 16px;
      line-height: 36px;
      color: rgb(96, 98, 102);
      border-top: 1px solid #f1f1f1;
    }
    &:first-child {
      background-color: skyblue;
      border-top: none;
    }
    &:nth-child(2n) {
      background-color: #fafafa;
    }
    .left {
      width: 200px;
    }
    .right {
      flex: 1;
      border: 1px solid #f1f1f1;
    }
  }
}
</style>
