// import { resetPosition as addGatewayResetPosition } from './utils/addGateway';
// 节点默认 width: 80px height: 80px fill: #fff
import { cloneDeep } from 'lodash';
// 计算两点之间距离
import { twoPointDistance, randomNum } from '@/utils/util.js';
// 判断是否落在了combo内
function dropedOnCombo(selfModel, originChildNodes) {
  // 拿到当前节点位置信息
  const { id: selfId } = selfModel;
  let comboId = `combo_${randomNum(4)}`;
  while (this.graph.findById(comboId)) {
    comboId = `combo_${randomNum(4)}`;
  }
  this.graph.createCombo(comboId, [selfId, ...originChildNodes]);
}
// 用于放入后贴进组合节点组成combo
function dropedOnNearNodes(selfModel, comboNodes = []) {
  // 拿到当前节点位置信息
  const { id: selfId } = selfModel;
  // 拿到所有节点信息
  const { nodes, combos } = this.graph.save();
  // 指定排序的比较函数
  function compare(property) {
    return function (obj1, obj2) {
      const value1 = obj1[property];
      const value2 = obj2[property];
      return value1 - value2; // 升序
    };
  }
  const sortedNodesByDistance = cloneDeep(nodes)
    .map((model) => ({
      ...model,
      distance: twoPointDistance(selfModel, model),
    }))
    .sort(compare('distance'));
  // 最近距离的模型
  const closestModel = sortedNodesByDistance[1];
  if (closestModel) {
    const closestModelInstance = this.graph.findById(closestModel.id).get('model');
    // 小于等于80px 节点没combo则产生combo，有combo则将自身节点加入到combo里去
    // this.graph.findById 拿到对象实例 才可使用方法
    if (closestModel?.distance <= 80 && comboNodes.includes(closestModelInstance.type)) {
      if (closestModelInstance?.comboId) {
        const closestCombo = combos.find((combo) => combo.id === closestModelInstance.comboId);
        this.graph.findById(closestCombo.id).addNode(selfId);
      } else {
        let comboId = `combo_${randomNum(4)}`;
        while (this.graph.findById(comboId)) {
          comboId = `combo_${randomNum(4)}`;
        }
        this.graph.createCombo(comboId, [selfId, closestModel.id]);
      }
    }
  }
}
function nodeDragend(selfModel, comboNodes = []) {
  // 拿到当前节点位置信息
  const { id: selfId } = selfModel;
  // 拿到所有节点信息
  const { nodes } = this.graph.save();
  // 指定排序的比较函数
  function compare(property) {
    return function (obj1, obj2) {
      const value1 = obj1[property];
      const value2 = obj2[property];
      return value1 - value2; // 升序
    };
  }
  const sortedNodesByDistance = cloneDeep(nodes)
    .map((model) => ({
      ...model,
      distance: twoPointDistance(selfModel, model),
    }))
    .sort(compare('distance'));
  // 最近距离的模型
  const closestModel = sortedNodesByDistance[1];
  if (closestModel) {
    const closestModelInstance = this.graph.findById(closestModel.id).get('model');
    // 小于等于80px 节点没combo则产生combo，有combo则将自身节点加入到combo里去
    // this.graph.findById 拿到对象实例 才可使用方法
    if (closestModel?.distance <= 80 && comboNodes.includes(closestModelInstance.type)) {
      if (!closestModelInstance.comboId) {
        const allCombos = this.graph.getCombos();
        allCombos.forEach((combo) => {
          const { nodes } = combo.getChildren();
          if (nodes.length) {
            nodes.forEach((node) => {
              const nodeModel = node.get('model');
              if (nodeModel.id === selfId) {
                combo.removeNode(node);
              }
            });
          }
        });
        let comboId = `combo_${randomNum(4)}`;
        while (this.graph.findById(comboId)) {
          comboId = `combo_${randomNum(4)}`;
        }
        this.graph.createCombo(comboId, [selfId, closestModel.id]);
      }
    }
  }
}
const getStyle = {
  // 圆形
  circle({ style: { r } }) {
    return {
      width: 2 * r,
      height: 2 * r,
      r,
      x: 0,
      y: 0,
    };
  },
  // 方形节点
  square({ style: { width, height } }) {
    return {
      width,
      height,
      x: -width / 2,
      y: -height / 2,
    };
  },
  // 椭圆
  ellipse({ style: { width, height } }) {
    return {
      width,
      height,
      rx: width || 50,
      ry: height || 30,
      x: 0,
      y: 0,
    };
  },
  // 菱形
  rhombus({ style: { width, height } }) {
    return {
      width,
      height,
      path: [
        ['M', 0, -height / 2], // 上部顶点
        ['L', width / 2, 0], // 右侧顶点
        ['L', 0, height / 2], // 下部顶点
        ['L', -width / 2, 0], // 左侧顶点
        ['Z'], // 封闭
      ],
      x: 0,
      y: 0,
    };
  },
};
export const defaultUserNodeFormValue = {
  leader_type: 'currentLeader',
  leader_find_strategy: 'skip', // 查找策略默认为跳过当前节点
  attribute_keys: [],
  attribute_value_map: {
    role: [],
    department: [],
    region: [],
  },
  attribute_sign_type: '', // 默认为或签
  attribute_find_strategy: 'skip', // 查找策略默认为跳过当前节点
  attribute_checked_user: [],
  candidate_user: [],
  candidate_sign_type: '', // 默认为或签
  candidate_find_strategy: 'skip', // 查找策略默认为跳过当前节点
  custom_condition: [], // 自定义条件标签值
  custom_condition_sign_type: 'orSign', // 默认为或签
  custom_condition_find_strategy: 'skip', // 查找策略默认为跳过当前节点
  recursive_find_leader_enable: false, // 是否递归查找上级
};
export const nodeTypes = {
  start_node: {
    id: 1,
    name: '开始节点',
    class: ['circle'],
    defaultStyle: {
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98a2b2',
      },
    },
    getStyle: getStyle.circle,
    description: '', // 描述 备注
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        comboInfo: '',
        comboId: '',
        trigger: {
          resource: '',
          event_type: '', // afterCreate afterUpdate afterCreatOrUpdate
          constraints_enable: 2,
          formed_constraints: {
            conditional_symbol: 'and',
            conditionals: [],
          },
          conditional_conig: 1,
          constraints: '',
          execution_type: 'periodic', // periodic once
          periodic_value: '',
          initial_trigger_time: '',
        },
        timeout: {
          timeout_value: '',
          event_type: '',
          events: [],
        },
      },
      inputs: [],
      events2: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  gateway: {
    id: 11,
    name: '排他网关',
    class: ['rhombus'],
    panelType: 'gateway',
    defaultStyle: {
      width: 80,
      height: 80,
      fill: '#1993D0',
      labelCfg: {
        style: {
          fill: '#fff',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#006496',
      },
    },
    dragImg:
      'https://baike-med-1256891581.file.myqcloud.com/2022102/67a29ee0-5906-11ed-89ce-4ff3ab5f0cf9_0.png',
    getStyle: getStyle.rhombus,
    description: '', // 描述 备注
    default_config: {
      business_config: {
        extra_info: {},
      },
      subref: 'exclusive',
      inputs: [],
      outputs: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  gateway_inclusive: {
    id: 12,
    name: '包容网关',
    class: ['rhombus'],
    panelType: 'gateway',
    defaultStyle: {
      width: 80,
      height: 80,
      fill: '#1993D0',
      labelCfg: {
        style: {
          fill: '#fff',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#006496',
      },
    },
    dragImg:
      'https://baike-med-1256891581.file.myqcloud.com/2022102/67a29ee0-5906-11ed-89ce-4ff3ab5f0cf9_0.png',
    getStyle: getStyle.rhombus,
    description: '', // 描述 备注
    default_config: {
      business_config: {
        extra_info: {},
      },
      subref: 'inclusive',
      inputs: [],
      outputs: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  user: {
    id: 5,
    name: '用户节点',
    class: ['square'],
    description: '', // 描述 备注
    defaultStyle: {
      fill: '#ED7B2F',
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#fff',
          fontWeight: '700',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#d35a21',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        operator_type: 'leader',
        operator_value: {
          ...cloneDeep(defaultUserNodeFormValue),
        },
        prompt: '',
        rollback: {
          enable: false,
          rollback_type: 0, // 1 退回到上一节点 2 退回到指定节点
          skip: 0, // 审批单被退回后 重新提交的执行策略 0 不跳过审批人重新审批 1 跳过中间审批人
        },
        comboInfo: '',
        comboId: '',
      },
      subref: 'approval', // approval/空 审批节点， prompt 处理节点
      timed_config: {
        enabled: false,
        duration: 1,
        handle: 1,
      },
      events: [],
      field_permission: {},
      inputs: [],
      outputs: [],
      events2: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  doa: {
    id: 22,
    name: 'DOA审批节点',
    class: ['square'],
    description: '', // 描述 备注
    defaultStyle: {
      fill: '#ED7B2F',
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#fff',
          fontWeight: '700',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#d35a21',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        rollback: {
          enable: false,
          rollback_type: 0, // 1 退回到上一节点 2 退回到指定节点 3 申请人
          skip: 0, // 审批单被退回后 重新提交的执行策略 0 不跳过审批人重新审批 1 跳过中间审批人
        },
        faas_name: '',
      },
      events: [],
      inputs: [],
      outputs: [],
      events2: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  delay: {
    id: 21,
    name: '延时设置',
    class: ['square', 'delay-setting'],
    description: '', // 描述 备注
    defaultStyle: {
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#384050',
          fontWeight: '700',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98A2B2',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        wait_time: {
          type: 'abs', // abs / rel; 绝对时间/相对时间
          value: {
            time: '',
            operator: '+',
            day: 0,
            hour: 0,
            minute: 0,
          },
        },
      },
      inputs: [],
      outputs: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  function_wxgzh: {
    id: 23,
    name: '公众号模版消息',
    class: ['square', 'delay-setting'],
    panelType: 'notice_channel',
    description: '', // 描述 备注
    defaultStyle: {
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#384050',
          fontWeight: '700',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98A2B2',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        app_id: '',
        receiver_type: 'var', // 接收人类型, user: 按用户，role: 按角色，var: 按引用变量
        to: [], // 接收人变量
        template_id: '',
        params: '',
        link_type: 0,
        jump_url: '',
        mp_app_id: '',
        setting: {
          trigger_rule: 1,
          disturb_enbaled: false,
          disturb: {
            from: '22:00',
            to: '07:00',
          },
        },
      },
      inputs: [],
      outputs: [],
      subref: 'wxgzh',
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  function_lclmsg: {
    id: 91,
    name: '站内信通知',
    class: ['square', 'delay-setting'],
    panelType: 'notice_channel',
    description: '', // 描述 备注
    defaultStyle: {
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#384050',
          fontWeight: '700',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98A2B2',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        msg_type: '', // 站内信类型
        receiver_type: 'var', // 接收人类型, user: 按用户，role: 按角色，var: 按引用变量
        to: [], // 接收人变量
        subject: '', // 通知标题
        content: '', // 通知内容
      },
      inputs: [],
      outputs: [],
      subref: 'lclmsg',
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  function: {
    id: 14,
    name: '云函数节点',
    class: ['process'],
    description: '', // 描述 备注
    panelType: 'advance',
    defaultStyle: {
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98a2b2',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        name: '',
        comboInfo: '',
        comboId: '',
      },
      subref: 'faas',
      inputs: [],
      outputs: [],
      events2: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  wait: {
    id: 40,
    name: '流程等待',
    class: ['square', 'delay-setting'],
    description: '', // 描述 备注
    panelType: 'advance',
    defaultStyle: {
      width: 160,
      height: 30,
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98a2b2',
      },
    },
    getStyle: getStyle.square,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: {
        faas_name: '',
      },
      inputs: [],
      outputs: [],
      events2: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
  time_boundary_event: {
    id: 24,
    name: '定时边界事件',
    class: ['iconfont', 'icon-shijian'],
    panelType: 'boundary_event',
    comboTypes: ['user', 'start_node', 'function'],
    description: '', // 描述 备注
    defaultStyle: {
      width: 28,
      height: 28,
      fill: '#FFF',
      labelCfg: {
        style: {
          fill: '#384050',
          fontSize: 0,
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#1A2734',
      },
    },
    getStyle: getStyle.circle,
    default_config: {
      business_config: {
        extra_info: {},
      },
      metadata: { comboInfo: '', comboId: '' },
      events_value: {
        loop_time: '', // 循环时间
        cloud_fun_name: '', // 云函数名称
      },
      inputs: [],
      outputs: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
      dropedOnNearNodes.call(this, selfModel, ['user', 'start_node', 'function']);
    },
    onNodeDragend(selfModel) {
      nodeDragend.call(this, selfModel, ['user', 'start_node', 'function']);
    },
  },
  end_node: {
    id: 2,
    name: '结束节点',
    class: ['end'],
    description: '', // 描述 备注
    defaultStyle: {
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
    },
    stateStyles: {
      'nodeState:selected': {
        fill: '#98a2b2',
      },
    },
    getStyle: getStyle.circle,
    default_config: {
      business_config: {
        extra_info: {},
      },
      outputs: [],
    },
    onDrop(selfModel, originChildNodes) {
      if (originChildNodes.length) {
        dropedOnCombo.call(this, selfModel, originChildNodes);
        return;
      }
    },
  },
};
/* eslint-disable */
/**校验审批节点必填项 */
export function validateUserNode(node) {
  if (!node.label) {
    this.$message({ type: 'error', message: '节点名称不能为空' });
    return false;
  }
  if (!node.subref) {
    this.$message({ type: 'error', message: '用户节点业务选择不能为空' });
    return false;
  }
  if (
    node.metadata.operator_type === 'custom_condition' &&
    !node.metadata.operator_value.custom_condition.length
  ) {
    this.$message({ type: 'error', message: '用户节点自定义条件标签选择不能为空' });
    return false;
  }
  return true;
}
export function validateStartNode(node) {
  if (!node.label) {
    this.$message({ type: 'error', message: '节点名称不能为空' });
    return false;
  }
  if (node.metadata.trigger.resource && !node.metadata.trigger.event_type) {
    this.$message({ type: 'error', message: '开始节点选择业务实体后需选择触发方式' });
    return false;
  }
  if (node.metadata.trigger.event_type === 'cron' && !node.metadata.trigger.initial_trigger_time) {
    this.$message({ type: 'error', message: '开始节点选择定时触发后首次执行时间不可为空' });
    return false;
  }
  if (
    (node.metadata.trigger.event_type === 'cron' &&
      node.metadata.trigger.execution_type === 'periodic' &&
      !node.metadata.trigger.periodic_value) ||
    node.metadata.trigger.periodic_value.includes('undefined')
  ) {
    this.$message({ type: 'error', message: '开始节点选择定时周期触发后请填写执行周期' });
    return false;
  }
  return true;
}
export function validateDelaySettingNode(node) {
  if (!node.label) {
    this.$message({ type: 'error', message: '节点名称不能为空' });
    return false;
  }
  if (
    typeof node.metadata.wait_time.value.day !== 'number' ||
    typeof node.metadata.wait_time.value.hour !== 'number' ||
    typeof node.metadata.wait_time.value.minute !== 'number'
  ) {
    this.$message({ type: 'error', message: '延时时间不可设置为空' });
    return false;
  }
  if (node.metadata.wait_time.type === 'rel' && !node.metadata.wait_time.value.time) {
    this.$message({ type: 'error', message: '延时设置时间表达式不可为空' });
    return false;
  }
  return true;
}
export function validateOfficalAccountNode(node) {
  if (!node.label) {
    this.$message({ type: 'error', message: '节点名称不能为空' });
    return false;
  }
  if (!node.metadata.app_id) {
    this.$message({ type: 'error', message: '公众号模版消息节点服务号设置不可为空' });
    return false;
  }
  if (!node.metadata.template_id) {
    this.$message({ type: 'error', message: '公众号模版消息节点模版消息设置不可为空' });
    return false;
  }
  if (!node.metadata.to?.length) {
    this.$message({ type: 'error', message: '公众号节点请选择接收变量' });
    return false;
  }
  if (node.metadata.link_type === 1 && !node.metadata.jump_url) {
    this.$message({ type: 'error', message: '公众号模版消息节点消息跳转链接路径不可为空' });
    return false;
  }
  if (node.metadata.link_type === 2 && (!node.metadata.jump_url || !node.metadata.mp_app_id)) {
    this.$message({ type: 'error', message: '公众号模版消息节点消息小程序跳转配置不合规范' });
    return false;
  }
  return true;
}
export function validateInmailNode(node) {
  if (!node.label) {
    this.$message({ type: 'error', message: '节点名称不能为空' });
    return false;
  }
  if (!node.metadata.msg_type) {
    this.$message({ type: 'error', message: '站内信节点请选择站内信类型' });
    return false;
  }
  if (!node.metadata.to?.length) {
    this.$message({ type: 'error', message: '站内信节点请选择接收人变量' });
    return false;
  }
  if (!node.metadata.subject) {
    this.$message({ type: 'error', message: '站内信节点请填写通知标题' });
    return false;
  }
  if (!node.metadata.content) {
    this.$message({ type: 'error', message: '站内信节点请填写通知内容' });
    return false;
  }
  return true;
}
/* eslint-enable */
