<template>
  <div class="table-container">
    <div class="top">
      <el-tag>数据表名称：{{ objectData.name }}</el-tag>
      <el-tag type="success">表名：{{ objectData.code }}</el-tag>
      <el-button class="right-button" icon="el-icon-back" @click="goBack()">返回</el-button>
      <el-button
        class="add-button"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd()"
        v-if="jurisdiction.objectManage"
      >
        添加
      </el-button>
    </div>

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="名称" min-width="120"></el-table-column>
      <el-table-column prop="code" label="Code" min-width="120"></el-table-column>
      <el-table-column prop="description" label="描述" min-width="140">
        <template slot-scope="scope">
          <span>{{ scope.row.description | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="160" v-if="jurisdiction.objectManage">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      background
      hide-on-single-page
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="字段名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="Code" prop="code">
          <el-input v-model="formObj.code" :disabled="operation == 'update'"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{2,50}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为2到50'));
      }
      callback();
    };

    return {
      operation: 'create',
      listData: [],
      objectData: {},
      count: 0,
      formObj: {},
      oldSearchObj: {},
      searchObj: {
        object_id: Number(this.$route.query.object_id) || 0,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入字段名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入字段Code',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
    };
  },
  watch: {
    searchObj: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
          this.searchObj.page = 1;
        }
        this.oldSearchObj = Object.assign({}, this.searchObj);
        this.timer = setTimeout(() => {
          this.$router.push({ query: this.searchObj });
          sessionStorage.setItem('app_id', this.searchObj.appid);
          this.loadListData();
        }, 300);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        objectManage: this.getElementList.includes('object-manage'),
      };
    },
  },
  mounted() {
    if (this.searchObj.object_id) {
      this.loadObjectData();
      this.loadListData();
    }
  },
  methods: {
    loadObjectData() {
      const url = this.$api.getObject;
      const data = {
        id: this.searchObj.object_id,
      };
      const cb = (data) => {
        this.objectData = data;
      };
      this.$request({ url, data, cb });
    },
    loadListData() {
      const url = this.$api.getObjectFieldList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateObjectField : this.$api.createObjectField;
        const msg = id ? '修改成功' : '创建成功';
        let data = Object.assign({ object_id: this.searchObj.object_id }, this.formObj);
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            description: this.formObj.description,
          };
        }
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteObjectField;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {};
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该字段?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    // handleCurrentChange(val) {
    //   this.searchObj.page = val;
    //   this.loadListData();
    // },
    // handleSizeChange(val) {
    //   this.searchObj.page = 1;
    //   this.searchObj.page_size = val;
    //   this.loadListData();
    // },
  },
};
</script>

<style lang="scss" scoped></style>
