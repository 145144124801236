<template>
  <el-card class="box-card" v-loading="loading">
    <!-- 数据 -->
    <el-tree accordion :data="list">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span v-if="node.label">{{ node.label }}</span>
        <div v-else>
          <!-- {{ JSON.stringify(data) }} -->
          <template>
            <el-table :data="[data]" style="width: 100%">
              <el-table-column label="key:" fixed width="70">
                <template>value:</template>
              </el-table-column>
              <el-table-column
                :prop="key"
                :label="key"
                v-for="key in Object.keys(data)"
                :key="key"
                min-width="200"
              >
                <template slot-scope="scope">
                  <span v-if="getValue(scope.row[key]).length < 26">
                    {{ getValue(scope.row[key]) }}
                  </span>
                  <el-tooltip
                    v-else
                    effect="dark"
                    :content="getValue(scope.row[key])"
                    placement="top-start"
                  >
                    <span>{{ getValue(scope.row[key]).slice(0, 25) }}...</span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </span>
    </el-tree>
  </el-card>
</template>

<script>
import config from '@/config';
import { getValue } from '@/components/diff/diff';
export default {
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  created() {
    const { env, tag, tenant_id } = this.$route.query;
    if (!env || !tag || !tenant_id) {
      this.$router.replace({
        name: 'versions',
      });
    }
    this.getDetail(env, tag, tenant_id);
  },
  methods: {
    getValue(val) {
      return getValue(val);
    },
    async getDetail(env, tag, tenant_id) {
      this.$request({
        url: this.$api.metaGetMetadataByTag,
        data: {
          env,
          tag,
          tenant_id: ['development'].includes(config.env) ? tenant_id : undefined,
        },
        cb: ({ list }) => {
          this.list = list.map((item, index) => {
            const children = [];
            // 对象
            children.push({
              label: 'object',
              children: [item.object],
            });
            // 字段
            if (item.fields.length) {
              children.push({
                label: 'fields',
                children: item.fields,
              });
            }
            // 索引
            if (item.indexes.length) {
              children.push({
                label: 'indexes',
                children: item.indexes,
              });
            }

            return {
              label: index + 1,
              children,
            };
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree-node {
  .el-tree-node {
    .el-checkbox {
      display: none;
    }
    .el-tree-node__content {
      height: auto !important;
    }
    .custom-tree-node {
      width: 97%;
    }
  }
}
</style>
