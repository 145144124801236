<template>
  <div class="node-container">
    <el-table :data="propertyList">
      <el-table-column prop="name" label="字段"></el-table-column>
      <el-table-column label="仅查看">
        <template slot-scope="scope">
          <el-radio
            class="radio"
            :value="scope.row.value === COLUMN_PERMISSION.editable"
            :label="false"
            @change="updateValue(scope.row.name)"
          >
            {{ '' }}
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="可编辑">
        <template slot-scope="scope">
          <el-radio
            class="radio"
            :value="scope.row.value === COLUMN_PERMISSION.editable"
            :label="true"
            @change="updateValue(scope.row.name)"
            align="center"
          >
            {{ '' }}
          </el-radio>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { COLUMN_PERMISSION } from '@/utils/constant';

export default {
  name: 'WorkflowDetailConfigPermission',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      COLUMN_PERMISSION,
    };
  },
  computed: {
    propertyList() {
      const list = [];
      Object.keys(this.value).forEach((key) => {
        list.push({
          name: key,
          value: this.value[key],
        });
      });
      return list;
    },
  },
  methods: {
    updateValue(key) {
      this.$emit('update', {
        ...this.value,
        [key]:
          this.value[key] === COLUMN_PERMISSION.readonly
            ? COLUMN_PERMISSION.editable
            : COLUMN_PERMISSION.readonly,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';

.node-container {
  padding: 0 16px;
  .radio {
    ::v-deep.el-radio__label {
      display: none;
    }
  }
}
</style>
