export default {
  env: 'preview',
  domain: [
    'dataparispre.testsite.woa.com',
    'iamparispre.testsite.woa.com',
    'previewadmin.nges.qq.com',
  ],
  mobileDomain: 'pfizeruath5.tsepcn.com',
  aegisId: 'akNqIsanJXJnMpAhyv',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'nges',
  bucket: 'tsep-prod-1304685099',
  region: 'ap-guangzhou',
  sign: '预发',
};
