<template>
  <div>
    <el-dialog
      title="插入对象字段"
      :visible.sync="showDialog"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 对象 -->
        <el-tab-pane label="对象" name="object" v-loading="objectForm.loading">
          <!-- <el-form
            :inline="true"
            ref="searchForm"
            :model="objectForm"
            class="searchForm"
            @submit.native.prevent
            @keyup.enter.native="searchObjectList"
          >
            <el-form-item label prop="serch">
              <el-input
                v-model="objectForm.keyword"
                clearable
                placeholder="请输入"
                @clear="searchObjectList"
              />
            </el-form-item>
          </el-form> -->
          <el-table
            :data="objectForm.list"
            row-key="id"
            height="450"
            border
            ref="objectTab"
            style="width: 100%; overflow: auto"
            lazy
            fit
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="handleSelectionChange"
          >
            <el-table-column align="center" :width="50 + 50 * treeHeight" label="选择">
              <template slot-scope="scope">
                <el-radio class="radio" v-model="objectForm.slectObjectId" :label="scope.row.id">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="表名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="tenant_id" label="租户id" />
            <el-table-column prop="package" label="包名" />
          </el-table>
        </el-tab-pane>
        <!-- 字段 -->
        <el-tab-pane
          label="字段"
          name="field"
          :disabled="!objectForm.slectObjectId"
          v-loading="fieldForm.loading"
        >
          <el-form
            :inline="true"
            ref="searchForm"
            :model="fieldForm"
            class="searchForm"
            @submit.native.prevent
            @keyup.enter.native="getFilderField"
          >
            <el-form-item label="" prop="serch">
              <el-input
                v-model="fieldForm.keyword"
                clearable
                placeholder="请输入"
                @clear="getFilderField"
              >
                <i slot="suffix" class="el-input__icon el-icon-search" @click="getFilderField" />
              </el-input>
            </el-form-item>
          </el-form>
          <el-table
            :data="fieldForm.filterList"
            height="450"
            border
            ref="fieldTab"
            style="width: 100%; overflow: auto"
          >
            <el-table-column align="center" width="55" label="选择">
              <template slot-scope="scope">
                <div>
                  <el-radio class="radio" v-model="fieldForm.slectFields" :label="scope.row">
                    &nbsp;
                  </el-radio>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column prop="name" label="字段名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="description" label="描述">
              <template slot-scope="scope">
                <span v-if="scope.row.description.length < 21">{{ scope.row.description }}</span>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="scope.row.description"
                  placement="top"
                >
                  <span>{{ scope.row.description.slice(0, 20) }}...</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="value_type" label="值类型" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div>已选字段</div>
      <div class="select-field-box">
        <el-tag v-if="fieldForm.slectFields" closable @close="delSelectField">
          {{ fieldForm.slectFields.name }}.{{ fieldForm.slectFields.display_name }}.{{
            fieldForm.slectFields.value_type
          }}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    object: null,
    objectId: { type: String, default: '' },
  },
  data() {
    return {
      activeName: 'object',
      objectForm: {
        loading: false,
        keyword: '',
        offset: 0,
        limit: 9999,
        sort: 'create_time desc',
        count: 0, // 总数
        slectObjectId: null, // 选择的对象id
        list: [], // 表格数据
      },
      fieldForm: {
        loading: false,
        list: [],
        keyword: '',
        filterList: [],
        //  {
        //   对象1id: [ ]
        //   对象2id: [ ]
        //  }
        slectFields: null,
      },
      allObjects: [],
      allLoadObject: {},
      treeHeight: 1,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('cancel', val);
      },
    },
  },
  async created() {
    await this.getObjectList();
    let currentObj = this.allObjects.find((object) => object.name === this.object);
    currentObj = {
      ...currentObj,
      objectId: currentObj.id,
      id: Math.random().toString(16),
      totalObject: currentObj.name,
    };
    this.allLoadObject[currentObj.id] = currentObj;
    this.objectForm.list = [currentObj];
  },
  methods: {
    handleClose() {
      this.showDialog = false;
    },
    handleClick(tab) {
      if (tab.paneName === 'field') {
        this.searchFieldList();
        return;
      }
      this.$nextTick(() => {
        this.fieldForm.list = [];
      });
    },
    // --- 对象 ---
    // 搜索
    searchObjectList() {
      this.objectForm.offset = 0;
      this.objectForm.list = [];
      this.getObjectList();
    },
    async getObjectList() {
      try {
        this.objectForm.loading = true;
        const { offset, limit, keyword, sort } = this.objectForm;
        const { objects, count } = await this.$request({
          url: this.$api.metaGetObjectList,
          data: {
            offset,
            limit,
            keyword,
            sort,
          },
        });
        const newObjects = objects.map((object) => ({
          ...object,
          hasChildren: object.relations?.length > 0,
        }));
        this.objectForm.count = count;
        this.objectForm.loading = false;
        this.allObjects = newObjects;
      } catch (err) {
        console.log(err);
        this.objectForm.loading = false;
      }
    },
    async load(tree, treeNode, resolve) {
      console.log(tree, treeNode);
      const len = tree?.relations?.length || 0;
      const newTree = [];
      for (let i = 0; i < len; i++) {
        const relation = tree.relations[i];
        let subObject = this.allObjects.find((object) => {
          return object.name === relation?.target_object;
        });
        if (subObject) {
          const totalObject = tree.totalObject
            ? `${tree.totalObject}.${relation.virtual_field}`
            : `${tree.name}.${relation.virtual_field}`;
          subObject = {
            ...subObject,
            objectId: subObject.id,
            id: Math.random().toString(16),
            name: relation.virtual_field,
            totalObject,
          };
          newTree.push(subObject);
          this.allLoadObject[subObject.id] = subObject;
        }
      }
      const treeHeight = (tree.totalObject || '')?.split('.')?.length || 1;
      this.treeHeight = treeHeight;
      console.log('===newTree===', newTree);
      resolve(newTree);
    },
    getAllRelations(objects, parentObjectName = '', relations = []) {
      const currentObject = objects.find((object) => object.name === parentObjectName);
      relations.push(currentObject.name);
      console.log('relations', parentObjectName, currentObject, currentObject.relations);
      if (currentObject.relations) {
        const subRelations = currentObject.relations.reduce((pre, relation) => {
          if (relation.target_object !== parentObjectName) {
            pre.push(this.getAllRelations(objects, relation.target_object, relations));
          }
          return pre;
        }, []);
        return subRelations;
      }
      return currentObject;
    },
    handleSelectionChange(row) {
      this.objectForm.slectObjectId = row.id;
    },
    // --- 字段 ---
    searchFieldList() {
      this.getFieldList();
    },
    async getFieldList() {
      try {
        this.fieldForm.loading = true;
        // const { keyword } = this.fieldForm;
        const { fields } = await this.$request({
          url: this.$api.metaGetObjectFields,
          data: {
            // keyword,
            object_id: this.allLoadObject[this.objectForm.slectObjectId]?.objectId,
          },
        });
        this.fieldForm.list = fields;
        this.getFilderField();

        // 已勾选数据处理
        this.$nextTick(() => {
          this.fieldForm.loading = false;
          this.setSelect();
        });
      } catch (err) {
        console.log(err);
        this.$nextTick(() => {
          this.fieldForm.loading = false;
        });
      }
    },
    getFilderField() {
      this.fieldForm.filterList = this.fieldForm.list.filter((item) => {
        return item.name.includes(this.fieldForm.keyword);
      });
    },
    setSelect() {
      const oldData = this.fieldForm.slectFields?.[this.objectForm.slectObjectId];
      if (!oldData) {
        return;
      }
      const slectFieldId = oldData.map((row) => {
        return row.objectId;
      });
      const selectData = this.fieldForm.list.filter((item) => {
        return slectFieldId.includes(item.objectId);
      });
      if (selectData && selectData.length) {
        selectData.forEach((row) => {
          this.$refs.fieldTab.toggleRowSelection(row);
        });
      }
    },
    // 删除已选择的数据
    delSelectField() {
      this.fieldForm.slectFields = null;
    },
    save() {
      this.showDialog = false;
      if (this.fieldForm.slectFields?.name) {
        const totalObject = this.allLoadObject[this.objectForm.slectObjectId]?.totalObject;
        this.$emit('insertion', `${totalObject}.${this.fieldForm.slectFields.name}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-field-box {
  min-height: 54px;
  max-height: 304px;
  border: 1px solid #e5eaf2;
  border-radius: 4px;
  margin-top: 8px;
  overflow-y: auto;
  ::v-deep {
    .el-tag {
      margin: 3px;
    }
  }
}
</style>
