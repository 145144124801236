var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-table',{attrs:{"row-key":"id","data":_vm.objectPermissionData,"columns":_vm.columns,"height":_vm.tableHeight},scopedSlots:_vm._u([{key:"access_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.accessTypes[row.access_type] || row.access_type)+" ")]}},{key:"view_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getViewType(row))+" ")]}},{key:"可批量导入导出",fn:function(){return [_vm._v(" 可批量导入导出 "),_c('t-tooltip',{staticClass:"placement top center",attrs:{"content":"导入导出权限选中后，同时会选中角色对该表的全部列权限，但数据行权限需要根据实际需要自行调整","placement":"top","overlay-style":{ width: '100px' },"show-arrow":""}},[_c('t-icon',{attrs:{"name":"help-circle-filled"}})],1)]},proxy:true},{key:"import_export",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('el-checkbox',{attrs:{"value":!!row.import_export_action,"disabled":!_vm.jurisdiction.roleManage},on:{"change":function (checked) { return _vm.objectPermissionChange(row, 'object', 'import_export_action', checked, rowIndex); }}})]}},{key:"permission",fn:function(ref){
            var row = ref.row;
            var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"operation"},[_c('el-checkbox',{staticClass:"check-all",attrs:{"value":_vm.getObkjectSelect(row),"disabled":!_vm.jurisdiction.roleManage},on:{"change":function (checked) { return _vm.objectAllSelect(row, checked, rowIndex); }}},[_vm._v(" 全部 ")]),_vm._l((_vm.objectActionOptions),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value,"indeterminate":_vm.getTndeterminate(row, item.value),"value":!!row[item.value],"disabled":!_vm.jurisdiction.roleManage},on:{"change":function (checked) { return _vm.objectPermissionChange(row, 'object', item.value, checked, rowIndex); }}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)]}},{key:"col",fn:function(ref){
            var row = ref.row;
            var rowIndex = ref.rowIndex;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.openDataStructPermission(row, rowIndex)}}},[_vm._v(" 设置列权限 ")])]}},{key:"row",fn:function(ref){
            var row = ref.row;
return [([row.field_list, row.fields].some(function (item) { return item; }))?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.openDataPermission(row)}}},[_c('el-badge',{attrs:{"is-dot":_vm.haveFilter(row.data_filter)}},[_vm._v("设置行权限 ")])],1):_vm._e()]}}])}),_c('linePermission',{attrs:{"visible":_vm.opDialogVisible,"row":_vm.dataPermissionFormObj,"field-list":_vm.dataPermissionFields},on:{"success":_vm.setLinePermission,"close":function($event){_vm.opDialogVisible = false}}}),_c('el-dialog',{attrs:{"title":"数据列权限设置","visible":_vm.opObjectDialogVisible,"close-on-click-modal":false,"width":"800px"},on:{"update:visible":function($event){_vm.opObjectDialogVisible=$event}}},[_c('p',[_vm._v("对象权限：")]),_vm._l((_vm.objectActionOptions),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value,"indeterminate":_vm.getTndeterminate(_vm.objectStrcutData, item.value),"value":!!_vm.objectStrcutData[item.value],"disabled":!_vm.jurisdiction.roleManage},on:{"change":function (checked) { return _vm.objectPermissionChange(
            _vm.objectStrcutData,
            'object',
            item.value,
            checked,
            _vm.objectStrcutIndex
          ); }}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('p',[_vm._v("对象字段权限：")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.objectStrcutData.fields || _vm.objectStrcutData.field_list,"stripe":"","border":"","fit":"","row-key":"permission_id"}},[_vm._v(" > "),_c('el-table-column',{attrs:{"prop":"name","label":"名称","min-width":"140"}}),_c('el-table-column',{attrs:{"prop":"code","label":"code","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"description","label":"描述","min-width":"140"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"列权限","min-width":"255"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.objectActionOptions),function(item){return _c('el-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!item.value.includes('delete')),expression:"!item.value.includes('delete')"}],key:item.value,attrs:{"label":item.value,"value":!!scope.row[item.value],"disabled":!_vm.jurisdiction.roleManage},on:{"change":function (checked) { return _vm.objectPermissionChange(
                  _vm.objectStrcutData,
                  'filed',
                  item.value,
                  checked,
                  scope.$index
                ); }}},[_vm._v(" "+_vm._s(item.label)+" ")])})}}])})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.opObjectDialogVisible = false}}},[_vm._v("返 回")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }