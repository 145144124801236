import { getMeetingTypeList } from '@/graphql/api/meeting';
import { api, request } from '@/api';

export default {
  namespaced: true,
  getters: {
    getMeetingTypeOptions(state) {
      return state.meetingTypeOptions;
    },
    getType(state) {
      return state.type;
    },
    getMeetingEditData(state) {
      return state.meetingEditData;
    },
    getSelectedRules(state) {
      return state.selectedRules;
    },
    getObjectId(state) {
      return state.objectId;
    },
  },
  state: {
    meetingTypeOptions: [],
    type: 'add',
    meetingEditData: {},
    selectedRules: [],
    objectId: '',
  },
  mutations: {
    setMeetingTypeOptions(state, data) {
      state.meetingTypeOptions = data;
    },
    setType(state, data) {
      state.type = data;
    },
    setMeetingEditData(state, data) {
      state.meetingEditData = data;
    },
    setSelectedRules(state, data) {
      state.selectedRules = data;
    },
    setObjectId(state, data) {
      state.objectId = data;
    },
  },
  actions: {
    async requestMeetingTypeList({ commit }) {
      const res = await getMeetingTypeList();
      commit('setMeetingTypeOptions', res.events_meeting_type);
    },
    // 获取meeting_id
    async getObjectList({ state, commit }) {
      if (!state.objectId) {
        const res = await request({
          url: api.metaGetObjectList,
          data: {
            object_name: 'events_meeting',
          },
        });
        commit('setObjectId', res.objects[0].id);
        return res.objects[0].id;
      }
      return Promise.resolve(state.objectId);
    },
  },
};
