<template>
  <div>
    <el-button type="info" @click="showDialog = true">选择</el-button>
    &nbsp;
    <span>{{ selectRow.name || value }}</span>
    <!-- 弹窗 -->
    <el-dialog
      :modal="false"
      :title="objectId ? '字段选择' : '对象选择'"
      class="meta"
      :visible.sync="showDialog"
      width="700px"
    >
      <!-- 字段 -->
      <el-table :data="fieldList" v-if="objectId" v-loading="loading">
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="display_name" label="中文名"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="select(scope.row)">选择这个</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 对象 -->
      <template v-else>
        <!-- 搜索表单 -->
        <el-form :inline="true" :model="from">
          <el-form-item label="搜索">
            <el-input v-model="from.keyword" placeholder="关键字搜寻"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="geObjectList">搜索</el-button>
          </el-form-item>
        </el-form>
        <!-- 列表 -->
        <el-table :data="list" v-loading="loading">
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="display_name" label="中文名"></el-table-column>
          <el-table-column prop="description" label="描述"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="select(scope.row)">选择这个</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    objectId: {
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      from: { keyword: '', limit: 100, offset: 0, sort: '' },
      list: [], // 对象列表
      fieldList: [], // 字段列表
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      selectRow: {},
    };
  },
  created() {
    // 获取列表
    if (!this.objectId) {
      this.geObjectList();
    } else {
      this.getFieldList();
    }
  },
  methods: {
    async geObjectList() {
      this.loading = true;
      this.$request({
        url: this.$api.metaGetObjectList,
        data: {
          ...this.from,
          limit: this.pageSize,
          offset: (this.pageIndex - 1) * this.pageSize,
        },
        cb: ({ objects, count }) => {
          this.total = Number(count);
          this.list = objects;
          this.loading = false;
        },
        fault: () => {
          this.$message.error('列表请求失败');
          this.loading = false;
        },
      });
    },
    async getFieldList() {
      this.loading = true;
      try {
        const { fields } = await this.$request({
          url: this.$api.metaGetObjectFields,
          data: {
            object_id: this.objectId,
          },
        });
        this.fieldList = fields;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    select(row) {
      this.selectRow = row;
      this.$emit('select', row);
      this.showDialog = false;
    },
    // 分页器
    handleSizeChange(value) {
      this.pageIndex = 1;
      this.pageSize = value;
      this.geObjectList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.geObjectList();
    },
  },
};
</script>

<style lang="scss" scoped>
.meta {
  background-color: rgba(#000000, 0.5);
}
</style>
