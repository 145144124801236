import Cos from './index';
import { request } from '@/api/axios.js';
import { api } from '@/api/index.js';
import config from '@/config';

const getUploadSignApi = (data) => {
  return request({
    url: api.getUploadSign,
    data,
  });
};

const { cosAppId } = config;
Cos.initConfig({
  getTokenFunc: async ({ suffix }) => {
    const res = await getUploadSignApi({
      app_id: cosAppId,
      credentials: 1,
      suffix,
    });
    const { session_token, tmp_secret_id, tmp_secret_key } = res?.data?.credentials || {};
    const {
      cdn_url: cdnUrl,
      key,
      startTime,
      expiredTime,
      region,
      bucket,
      x_cos_security_token,
    } = res?.data || {};
    return {
      tmpSecretId: tmp_secret_id,
      tmpSecretKey: tmp_secret_key,
      sessionToken: session_token,
      cdnUrl,
      key,
      startTime,
      expiredTime,
      region,
      bucket,
      x_cos_security_token,
    };
  },
});

export default Cos;
