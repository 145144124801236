<template>
  <div class="login">
    <template>
      <p class="login-title">登录账号</p>
      <el-form class="login-form" ref="form" :model="formData" :rules="rules">
        <el-form-item prop="account" class="item">
          <el-input
            class="input"
            placeholder="请输入员工账号"
            v-model.trim="formData.account"
            maxlength="100"
            @keyup.enter.native="login"
          >
            <i slot="prefix" class="iconfont icon-user"></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password" class="item">
          <el-input
            type="password"
            class="input"
            placeholder="请输入员工密码"
            v-model.trim="formData.password"
            maxlength="100"
            @keyup.enter.native="login"
          >
            <i slot="prefix" class="iconfont icon-password"></i>
          </el-input>
        </el-form-item>

        <el-button
          :type="'primary'"
          class="login-btn"
          round
          :disabled="!loginValid"
          @click="login"
          :loading="loading"
        >
          登录
        </el-button>
      </el-form>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Account',
  props: {
    redirectUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        account: '',
        password: '',
      },
      // 表单验证对象
      rules: {
        account: [
          {
            required: true,
            message: '请输入员工账号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入员工密码',
            trigger: 'blur',
          },
        ],
      },
      loading: false,
    };
  },
  computed: {
    loginValid() {
      return this.formData.account && this.formData.password;
    },
  },
  methods: {
    async login() {
      if (this.loading) return;
      this.loading = true;
      await this.$request({
        url: this.$api.login,
        data: {
          account: this.formData.account,
          password: this.formData.password,
          login_type: 'admin',
        },
        client: {
          product: Number(this.product),
        },
      })
        .then((res) => {
          console.log('账号密码登录返回值', res);
          this.loginSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    loginSuccess(data) {
      this.$message({
        message: '登录成功',
        type: 'success',
      });
      /*
      this.$cookies.set('uin', data.uin);
      this.$cookies.set('saas_token', data.token);
      */
      // 登录成功后，重定向去客户的域名
      setTimeout(() => {
        if (this.redirectUrl) {
          window.location.href = `${decodeURIComponent(
            this.redirectUrl,
          )}?token=${encodeURIComponent(data.token)}&uin=${data.uin}&expires_in=${data.expires_in}`;
        }
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  .login-title {
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .login-img {
    width: 72px;
    height: 72px;
    margin: 28px 0 16px calc(50% - 36px);
    border-radius: 8px;
    background-color: #f2f3f8;
  }
  .login-form {
    margin-top: 36px;
    .item {
      margin-bottom: 36px;
    }
    .input {
      height: 40px;
      background-color: #fff;
      border-radius: 2px;
      ::v-deep .el-input__inner {
        padding-left: 40px;
      }
      ::v-deep .el-input__prefix {
        left: 12px;
      }
    }
  }
  .login-btn {
    width: 100%;
    background: #0069fa;
    border: none;
    &:disabled {
      background-color: #a0cfff;
    }
  }
}
</style>
