<template>
  <div class="table-container">
    <div class="top">
      <el-select v-model="searchObj.appid" clearable placeholder="应用">
        <el-option
          v-for="item in appOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-input clearable placeholder="请输入关键词" v-model="searchObj.keyword" class="keyword">
        <el-button slot="append" @click="loadListData" icon="el-icon-search">搜索</el-button>
      </el-input>
      <el-button class="add-button" type="primary" icon="el-icon-plus" @click="handleAdd()">
        添加
      </el-button>
    </div>

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="名称" min-width="120"></el-table-column>
      <el-table-column prop="appid" label="应用">
        <template slot-scope="scope">
          <span>{{ scope.row.appid | name(appOptions) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="path" label="路径" min-width="120"></el-table-column>
      <el-table-column prop="desc" label="描述" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.desc | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="success" @click="handleEditRole(scope.$index, scope.row)">
            权限
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      background
      hide-on-single-page
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="(val) => (searchObj.page = val)"
      @size-change="(val) => (searchObj.page_size = val)"
    />

    <role-box
      :visible.sync="roleDialogVisible"
      :check-role-url="checkRoleUrl"
      :update-role-url="updateRoleUrl"
    ></role-box>

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="应用" prop="appid">
          <template>
            <el-select v-model="formObj.appid" clearable placeholder="请选择">
              <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="路径" prop="path">
          <el-input v-model="formObj.path"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="formObj.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RoleBox from '@/components/RoleBox';

export default {
  components: {
    RoleBox,
  },
  data() {
    return {
      operation: 'create',
      listData: [],
      count: 0,
      formObj: {},
      appOptions: [],
      checkRoleUrl: '',
      updateRoleUrl: '',
      oldSearchObj: {},
      searchObj: {
        keyword: this.$route.query.keyword,
        appid: Number(this.$route.query.appid) || '',
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入接口名称',
            trigger: 'blur',
          },
        ],
        appid: [
          {
            required: true,
            message: '请选择应用名称',
            trigger: 'blur',
          },
        ],
        path: [
          {
            required: true,
            message: '请输入接口路径',
            trigger: 'blur',
          },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
    };
  },
  watch: {
    searchObj: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
          this.searchObj.page = 1;
        }
        this.oldSearchObj = Object.assign({}, this.searchObj);
        this.timer = setTimeout(() => {
          this.$router.push({ query: this.searchObj });
          sessionStorage.setItem('app_id', this.searchObj.appid);
          this.loadListData();
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadListData();
    this.loadAppOption();
  },
  methods: {
    loadListData() {
      const url = this.$api.getFileList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
      };
      this.$request({ url, data, cb });
    },
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? `${this.$api.updateFile}/${this.formObj.id}` : this.$api.createFile;
        const msg = id ? '修改成功' : '创建成功';
        const data = Object.assign({}, this.formObj);
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteFile;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {};
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleEditRole(index, data) {
      this.roleDialogVisible = true;
      this.checkRoleUrl = `${this.$api.getFile}/${data.id}/role`;
      this.updateRoleUrl = `${this.$api.updateFile}/${data.id}/role`;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
