import development from './development';
import test from './test';
import preview from './preview';
import production from './production';

function setConfig(config) {
  const domain = window.location.host;
  let outConfig = null;
  if (/^localhost/.test(domain)) {
    outConfig = {
      ...config,
      ...development,
    };
    return outConfig;
  }
  const configs = {
    development,
    test,
    preview,
    production,
  };
  Object.keys(configs).forEach((key) => {
    const configItem = configs[key];
    if (configItem.domain.includes(domain)) {
      outConfig = {
        ...config,
        ...configItem,
      };
    }
  });
  if (!outConfig) {
    outConfig = {
      ...config,
      ...production,
    };
  }
  return outConfig;
}
const commonConfig = {
  apiProduct: 1,
  product: 'saas',
  company: 'tencent',
  api: '/api/access/json',
  cloud: '/api/access/pb', // 正常
  workflowCloud: '', // 工作流
  apiList: [], // 非云上接口列表
  whiteFilter: ['SendSmsCode', 'Login'],
  layoutFilter: ['workflow-detail'],
};
const config = setConfig(commonConfig);
console.log('config', config);
export default config;
