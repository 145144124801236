<template>
  <div>
    <!-- 列表 -->
    <el-table :data="list">
      <template v-for="item in layout">
        <TooltipColumn
          v-if="item.limit"
          :key="item.name"
          :name="item.name"
          :label="item.label"
          :limit="item.limit"
        />
        <el-table-column
          v-else
          :key="item.name"
          :prop="item.name"
          :label="item.label"
        ></el-table-column>
      </template>
      <slot name="table-action"></slot>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import TooltipColumn from '@/components/tooltipColumn';

export default {
  components: { TooltipColumn },
  props: {
    request: {
      type: Function,
      default: undefined,
      require: true,
    },
    layout: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetPage() {
      this.pageIndex = 1;
      this.pageSize = 10;
    },
    getList() {
      this.request({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then(({ list, total }) => {
        this.list = list;
        this.total = Number(total);
      });
    },
    // 分页器
    handleSizeChange(value) {
      this.pageIndex = 1;
      this.pageSize = value;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
@import '@/styles/element/pagination.scss';
</style>
