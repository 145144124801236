<template>
  <el-card class="box" v-loading="loading">
    <div class="item from">
      <div v-if="sync_id">sync_id: {{ sync_id }}</div>
      <br />
      <div class="operation">
        <el-form :inline="true">
          <el-form-item label="资源类型">
            <el-select
              v-model="asyncForm.model"
              multiple
              clearable
              @change="changeSelect"
              :class="{ 'select-all': asyncForm.model.length >= modelTypes.length }"
            >
              <el-option
                :class="{ selected: asyncForm.model.length >= modelTypes.length }"
                label="全选"
                value="全选"
                @click.stop.native="handleCheckAllChange('info')"
              />
              <el-option
                v-for="item in modelTypes"
                :key="item.value"
                :label="`${item.label}&&权限`"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="操作类型">
            <el-select
              v-model="asyncForm.operator"
              multiple
              clearable
              @change="changeSelect"
              :class="{ 'select-all': asyncForm.operator.length >= operateTypes.length }"
            >
              <el-option
                :class="{ selected: asyncForm.operator.length >= operateTypes.length }"
                label="全选"
                value="全选"
                @click.stop.native="handleCheckAllChange('operate')"
              />
              <el-option
                v-for="item in operateTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="selectAll">全 选</el-button>
            <!-- :disabled="config.env === 'production'" -->
            <el-button @click="statisticsAsync">同 步</el-button>
            <!-- <el-button @click="statisticsAsync" v-if="jurisdiction.asyncIam">同 步</el-button>
            <el-button :disabled="true" v-else>同 步</el-button> -->
            <el-button @click="resetChecked">清 空</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 数据 -->
      <h3>资源信息</h3>
      <el-tree
        v-if="!loading"
        ref="config"
        accordion
        :data="filterDiffData.config"
        show-checkbox
        node-key="diff_code"
        @check="configCheckChange"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ [undefined].includes(data.tier) ? data.label : '' }}</span>
          <!-- 表格 -->
          <div
            v-if="
              [
                'diff_object',
                'diff_object_field',
                'diff_element',
                'diff_api',
                'diff_menu',
                'diff_role',
              ].includes(data.tier)
            "
          >
            <diff :data="data" type="table" @diffjson="showDiff" />
          </div>
        </span>
      </el-tree>
      <hr />
      <!-- 权限 -->
      <h3>策略信息</h3>
      <el-tree
        v-if="!loading"
        ref="perm"
        accordion
        :data="filterDiffData.permission"
        show-checkbox
        node-key="diff_code"
        @check="permCheckChange"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ [undefined].includes(data.tier) ? data.label : '' }}</span>
          <!-- 菜单 -->
          <div v-if="data.tier === 'diff_menu'">
            <diff :data="data" type="menu" />
          </div>
          <!-- api -->
          <div v-if="data.tier === 'diff_api'">
            <diff :data="data" type="api" />
          </div>
          <!-- 元素 -->
          <div v-if="data.tier === 'diff_element'">
            <diff :data="data" type="element" />
          </div>
          <!-- 对象 -->
          <div v-if="data.tier === 'diff_object'">
            <diff :data="data" type="object" />
          </div>
          <!-- 对象字段 -->
          <div v-if="data.tier === 'diff_object_field'">
            <diff :data="data" type="object_field" />
          </div>
        </span>
      </el-tree>
    </div>
    <!-- 同步统计弹窗 -->
    <el-dialog title="此操作,变更数量如下:" :visible.sync="tipDaig.visible" width="600px" center>
      <el-form label-position="top" label-width="80px" :model="tipDaig.asyncData">
        <el-form-item label="资源变更">
          <div v-for="(item, index) in tipDaig.asyncData.config" :key="index">
            {{ index + 1 }}、
            <span v-if="item.data.CREATE">
              新增{{ item.data.CREATE }}个{{ modelInfo[item.tier] }}
            </span>
            <span v-if="item.data.CREATE && item.data.MODIFY">,</span>
            <span v-if="item.data.MODIFY">
              更新{{ item.data.MODIFY }}个{{ modelInfo[item.tier] }}
            </span>
            <template v-if="item.data.field">
              <span v-if="item.data.CREATE || item.data.MODIFY">,</span>
              <span v-if="item.data.field.CREATE">新增{{ item.data.field.CREATE }}个字段</span>
              <span v-if="item.data.field.CREATE && item.data.field.MODIFY">,</span>
              <span v-if="item.data.field.MODIFY">更新{{ item.data.field.MODIFY }}个字段</span>
            </template>
            ;
          </div>
          <div v-if="tipDaig.asyncData.config.length === 0">无变更</div>
        </el-form-item>
        <el-form-item label="策略变更">
          <div v-for="(item, index) in tipDaig.asyncData.perm" :key="index">
            {{ index + 1 }}、 {{ modelInfo[item.tier] }}
            <span v-if="item.data.CREATE">新增{{ item.data.CREATE }}条权限</span>
            <span v-if="item.data.CREATE && item.data.MODIFY">,</span>
            <span v-if="item.data.MODIFY">更新{{ item.data.MODIFY }}条权限</span>
            <template v-if="item.data.field">
              <span v-if="item.data.CREATE || item.data.MODIFY">,</span>
              字段
              <span v-if="item.data.field.CREATE">新增{{ item.data.field.CREATE }}条权限</span>
              <span v-if="item.data.field.CREATE && item.data.field.MODIFY">,</span>
              <span v-if="item.data.field.MODIFY">更新{{ item.data.field.MODIFY }}条权限</span>
            </template>
            ;
          </div>
          <div v-if="tipDaig.asyncData.perm.length === 0">无变更</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="operation">确 定</el-button>
        <el-button @click="tipDaig.visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 差异展示 -->
    <code-diff
      :title="difDialog.title"
      :show="difDialog.visible"
      :old-string="difDialog.oldStr"
      :new-string="difDialog.newStr"
      :context="10"
      output-format="side-by-side"
      @cancel="difDialog.visible = false"
    />
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { getData, getAllKeys, getAllNodes, filterTree, statisticsAsync } from './data';
import diff from '@/components/diff/show.vue';
import CodeDiff from '@/components/codeDiff';
import config from '@/config';
const modelTypes = [
  { value: 'diff_menu', label: '菜单' },
  { value: 'diff_api', label: '接口' },
  { value: 'diff_element', label: '元素' },
  { value: 'diff_object', label: '对象' },
  { value: 'diff_role', label: '角色' },
];
const operateTypes = [
  { value: 'CREATE', label: '新增' },
  { value: 'MODIFY', label: '修改' },
  { value: 'DELETE', label: '删除' },
];
const diffTypes = [
  'diff_menu',
  'diff_api',
  'diff_element',
  'diff_object',
  'diff_object_field',
  'diff_role',
];
export default {
  components: { diff, CodeDiff },
  data() {
    return {
      modelTypes,
      operateTypes,
      sync_id: '',
      loading: false,
      diffData: {}, // 全部数据
      filterDiffData: {}, // 过滤后的数据
      defaultProps: {},
      fails: [],
      checkList: {
        config: [],
        perm: [],
      },
      asyncForm: {
        model: modelTypes.map((item) => {
          return item.value;
        }),
        operator: operateTypes.map((item) => {
          return item.value;
        }),
      },
      config,
      tipDaig: {
        visible: false,
        asyncData: {
          config: [],
          perm: [],
        },
      },
      difDialog: {
        visible: false,
        oldStr: '',
        newStr: '',
        title: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        asyncIam: this.getElementList.includes('async-iam'),
      };
    },
    allKey() {
      return {
        config: getAllKeys(this.filterDiffData.config),
        perm: getAllKeys(this.filterDiffData.permission),
      };
    },
    allNode() {
      return {
        config: getAllNodes(this.filterDiffData.config),
        perm: getAllNodes(this.filterDiffData.permission),
      };
    },
    modelInfo() {
      const obj = {
        diff_object_field: '字段',
      };
      modelTypes.forEach((item) => {
        obj[item.value] = item.label;
      });
      return obj;
    },
  },
  created() {
    this.init();
  },
  methods: {
    showDiff({ oldStr, newStr, title }) {
      this.difDialog = {
        visible: true,
        oldStr,
        newStr,
        title,
      };
    },
    init() {
      const { env, tag, appid } = this.$route.query;
      if ([env, tag, appid].includes(undefined)) {
        this.$router.replace({
          name: 'version-manage',
        });
      }
      this.sync_id = '';
      this.checkList = {
        config: [],
        perm: [],
      };
      this.asyncForm = {
        model: modelTypes.map((item) => {
          return item.value;
        }),
        operator: operateTypes.map((item) => {
          return item.value;
        }),
      };
      this.fails = [];
      this.getDiff({ env, tag, appid: Number(appid) });
    },
    async getDiff(req) {
      this.loading = true;
      try {
        const { items, sync_id: syncId } = await this.$request({
          url: this.$api.diffAuth,
          data: req,
          config: {
            timeout: 1000 * 120, // 超时120s
          },
        });
        console.log('接口数据:', items);
        this.diffData = getData(items);
        console.log('diff:', this.diffData);
        this.filterDiffData = JSON.parse(JSON.stringify(this.diffData));
        this.sync_id = syncId;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    configCheckChange(data, { checkedNodes }) {
      console.log(checkedNodes);
      this.checkList.config = checkedNodes.filter((item) => {
        return item.tier;
      });
    },
    permCheckChange(data, { checkedNodes }) {
      console.log(checkedNodes);
      this.checkList.perm = checkedNodes.filter((item) => {
        return item.tier;
      });
    },
    changeSelect() {
      this.loading = true;
      setTimeout(() => {
        const models = [...this.asyncForm.model];
        if (this.asyncForm.model.includes('diff_object')) {
          models.push('diff_object_field');
        }
        this.filterDiffData.config = filterTree(
          this.diffData.config,
          this.asyncForm.operator,
          models,
        );
        this.filterDiffData.permission = filterTree(
          this.diffData.permission,
          this.asyncForm.operator,
          models,
        );

        this.checkList = {
          config: [],
          perm: [],
        };
        this.$nextTick(() => {
          this.loading = false;
        });
      }, 300);
    },
    handleCheckAllChange(val) {
      const operator = this.asyncForm.operator.filter((item) => {
        return item !== '全选';
      });
      const model = this.asyncForm.model.filter((item) => {
        return item !== '全选';
      });

      switch (val) {
        case 'info':
          if (model.length < modelTypes.length) {
            this.asyncForm.model = modelTypes.map((item) => {
              return item.value;
            });
          } else {
            this.asyncForm.model = [];
          }
          break;
        case 'operate':
          if (operator.length < operateTypes.length) {
            this.asyncForm.operator = operateTypes.map((item) => {
              return item.value;
            });
          } else {
            this.asyncForm.operator = [];
          }
          break;
      }
    },
    selectAll() {
      // key
      const allKey = {
        config: this.allNode.config.map((item) => {
          return item.diff_code;
        }),
        perm: this.allNode.perm.map((item) => {
          return item.diff_code;
        }),
      };
      // 更新视图
      this.$refs.config.setCheckedKeys(allKey.config);
      this.$refs.perm.setCheckedKeys(allKey.perm);
      this.checkList = JSON.parse(JSON.stringify(this.allNode));
    },
    resetChecked() {
      this.$refs.config.setCheckedKeys([]);
      this.$refs.perm.setCheckedKeys([]);
      this.checkList = {
        config: [],
        perm: [],
      };
    },
    // 同步
    async operation() {
      // 同步参数
      const req = {
        sync_id: this.sync_id,
        sync_item: {
          diff_role: {},
          diff_menu: {},
          diff_element: {},
          diff_api: {},
          diff_object: {},
          diff_object_field: {},
        },
      };
      // 配置
      this.checkList.config.forEach((node) => {
        const { tier, diff_code } = node;
        if (diffTypes.includes(tier)) {
          if (!req.sync_item[tier].diff_item) {
            req.sync_item[tier].diff_item = [];
          }
          req.sync_item[tier].diff_item.push({ diff_code });
        } else {
          if (tier) {
            console.log('tier:', tier);
          }
        }
      });
      // 权限
      this.checkList.perm.forEach((node) => {
        const { tier, diff_code } = node;
        if (diffTypes.includes(tier)) {
          if (!req.sync_item[tier].diff_perm) {
            req.sync_item[tier].diff_perm = [];
          }
          req.sync_item[tier].diff_perm.push({ diff_code });
        } else {
          if (tier) {
            console.log('tier:', tier);
          }
        }
      });
      try {
        await this.$request({
          url: this.$api.syncAuth,
          data: req,
          config: {
            timeout: 1000 * 120, // 超时120s
          },
        });
        this.$message({
          message: '同步成功',
          type: 'success',
        });
        this.tipDaig.visible = false;
        this.init();
      } catch (err) {
        console.log(err);
        this.$message.error('同步失败');
      }
    },
    statisticsAsync() {
      console.log('选择节点:', this.checkList);
      if (this.checkList.config.length === 0) {
        if (this.checkList.perm.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择数据后,执行同步操作',
          });
          return;
        }
      }
      this.tipDaig.asyncData.config = [];
      this.tipDaig.asyncData.perm = [];
      const asyncData = statisticsAsync(this.checkList.config, this.checkList.perm);
      diffTypes.forEach((tier) => {
        if (asyncData.config[tier]) {
          this.tipDaig.asyncData.config.push({
            tier,
            data: asyncData.config[tier],
          });
        }
        if (asyncData.perm[tier]) {
          this.tipDaig.asyncData.perm.push({
            tier,
            data: asyncData.perm[tier],
          });
        }
      });
      this.tipDaig.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.box ::v-deep .el-card__body {
  display: flex;
  .item {
    flex: 1;
    margin: 0 2px;
  }
}
::v-deep .el-tree-node {
  .el-checkbox {
    z-index: 0;
  }
  .el-tree-node__content {
    height: auto !important;
  }
  .custom-tree-node {
    width: 80vw;
  }
}
::v-deep .el-select {
  width: 100%;
  // 全部选择时只显示全部不显示标签
  &.select-all {
    .el-select__tags {
      & > span::before {
        content: '';
        margin-left: 5px;
        display: block;
        background: url('https://baike-med-1256891581.file.myqcloud.com/2022092/b3a4c050-3ecd-11ed-89ce-4ff3ab5f0cf9_0.png')
          no-repeat;
        height: 25px;
        width: 43px;
      }
      .el-tag {
        display: none;
      }
    }
  }
}
.title {
  height: 25px;
  line-height: 25px;
  text-align: center;
}
</style>
