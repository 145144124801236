<template>
  <div>
    <div class="top">
      <t-tag>角色名称：{{ roleData.name }}</t-tag>
      <t-tag theme="success">Code：{{ roleData.code }}</t-tag>
      <t-button @click="goBack()" style="margin-left: 16px; height: 24px">
        <RollbackIcon slot="icon" />
        返回
      </t-button>
    </div>
    <t-tabs v-model="tabValue">
      <t-tab-panel value="InterfacePanel" label="接口管理" style="padding: 12px">
        <!-- <p style="margin: 20px">选项卡1内容区</p> -->
        <InterfacePanel
          :search-obj="searchObj"
          :permission-map="permissionMap"
          ref="InterfacePanel"
        ></InterfacePanel>
      </t-tab-panel>
      <t-tab-panel value="MenuPanel" label="菜单管理" style="padding: 12px">
        <MenuPanel
          :search-obj="searchObj"
          :permission-map="permissionMap"
          ref="MenuPanel"
        ></MenuPanel>
      </t-tab-panel>
      <t-tab-panel value="ObjectPanel" label="数据管理" style="padding: 12px">
        <ObjectPanel
          :search-obj="searchObj"
          :permission-map="permissionMap"
          :role-data="roleData"
          ref="ObjectPanel"
          @refresh="loadRolePermission"
        ></ObjectPanel>
      </t-tab-panel>
      <t-tab-panel value="ElementPanel" label="元素管理" style="padding: 12px">
        <ElementPanel
          :search-obj="searchObj"
          :permission-map="permissionMap"
          ref="ElementPanel"
        ></ElementPanel>
      </t-tab-panel>
    </t-tabs>
  </div>
</template>

<script>
import { RollbackIcon } from 'tdesign-icons-vue';
import InterfacePanel from './components/interface.vue';
import MenuPanel from './components/menu.vue';
import ElementPanel from './components/element.vue';
import ObjectPanel from './components/object.vue';
export default {
  components: {
    RollbackIcon,
    InterfacePanel,
    MenuPanel,
    ElementPanel,
    ObjectPanel,
  },
  data() {
    return {
      tabValue: 'InterfacePanel',
      roleData: {},
      searchObj: {
        role_id: Number(this.$route.query.role_id) || 0,
        appid: Number(this.$route.query.appid) || 0,
      },
      permissionData: [],
      permissionMap: {},
    };
  },
  watch: {
    async tabValue(value) {
      console.log('---tabValue-change---', value);
      await this.$nextTick();
      console.log('refs:', this.$refs);
      this.$refs[value].init();
    },
  },
  mounted() {
    if (this.searchObj.role_id) {
      Promise.all([this.loadRoleData()]).then(async () => {
        await this.loadRolePermission();
        this.$refs.InterfacePanel.init();
      });
    }
  },
  methods: {
    loadRoleData() {
      const url = this.$api.getRole;
      const data = {
        id: this.searchObj.role_id,
      };
      const cb = (data) => {
        this.roleData = data;
      };
      return this.$request({ url, data, cb });
    },
    loadRolePermission() {
      const url = this.$api.getRolePermission;
      const cb = (data) => {
        this.permissionData = data.data;
        console.log(
          '------',
          data.data.filter((item) => item.permission_id === 114203),
        );
        // eslint-disable-next-line no-restricted-syntax
        for (const index in this.permissionMap) {
          this.$set(this.permissionMap, index, []);
        }
        for (const item of data.data) {
          if (
            this.permissionMap[item.permission_id] &&
            this.permissionMap[item.permission_id]?.length > 0
          ) {
            this.permissionMap[item.permission_id].push(item.action);
          } else {
            this.$set(this.permissionMap, item.permission_id, [item.action]);
          }
        }
      };
      this.$request({
        url,
        cb,
        data: { role_id: this.searchObj.role_id },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.top {
  margin-bottom: 16px;
}
</style>
