<template>
  <div class="condition">
    <!-- 字段 -->
    <el-select
      class="field"
      v-model="item.field"
      placeholder="请选择字段"
      :disabled="['EXIST', 'NOT_EXIST'].includes(item.operation)"
      :class="{ empty: rules && !item.field, 'item-input': true }"
    >
      <el-option
        v-for="field in fields"
        :key="field.code"
        :label="field.name"
        :value="field.code"
      />
    </el-select>
    <!-- 逻辑 -->
    <el-select
      class="logic-type"
      :class="{ empty: rules && !item.operation, 'item-input': true }"
      v-model="item.operation"
      placeholder="请选择"
      @change="operationChange"
    >
      <el-option v-for="(option, key) in OPERATION_TYPES" :key="key" :label="option" :value="key" />
    </el-select>
    <!-- 类型 -->
    <el-select
      class="type"
      v-model="item.value.type"
      placeholder="请选择"
      @change="typeChange"
      :class="{ empty: rules && !item.value.type, 'item-input': true }"
    >
      <el-option
        v-for="(item_value, key) in valueTypes"
        :key="key"
        :label="item_value"
        :value="key"
      />
    </el-select>
    <!-- 类型 === 变量 -->
    <el-select
      class="value-type"
      :class="{ empty: rules && !item.value.value, 'item-input': true }"
      v-if="!['CONST', 'SQL', '', 'PARAM'].includes(item.value.type)"
      @change="valueChange"
      v-model="item.value.value"
      placeholder="请选择"
    >
      <el-option v-for="{ value: val, label } in varTypes" :key="val" :label="label" :value="val" />
    </el-select>
    <!-- 值 -->
    <el-input
      v-else
      class="value"
      v-model="item.value.value"
      :disabled="['PARAM'].includes(item.value.type)"
      placeholder="请输入"
      :class="{ empty: rules && !item.value.value, 'item-input': true }"
    />
    <template v-if="sw">
      <!-- 角色选择框 -->
      <el-select
        filterable
        v-if="['$_ext_role'].includes(item.value.value)"
        class="value-type"
        v-model="item.value.ext_value"
        placeholder="请选择"
        :class="{ empty: rules && !item.value.ext_value }"
        multiple
      >
        <el-option v-for="{ code, name } in roles" :key="code" :label="name" :value="code" />
      </el-select>
      <!-- 岗位多选框 -->
      <el-select
        filterable
        v-if="['$_ext_territory'].includes(item.value.value)"
        class="value-type"
        v-model="item.value.ext_value"
        placeholder="请选择"
        :class="{ empty: rules && !item.value.ext_value }"
        multiple
      >
        <el-option v-for="{ code, name } in posts" :key="code" :label="name" :value="code" />
      </el-select>
    </template>
  </div>
</template>

<script>
import { OPERATION_TYPES } from '@/utils/constant';
export default {
  props: {
    rules: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    roles: {
      type: Array,
      default: () => [],
    },
    posts: {
      type: Array,
      default: () => [],
    },
    externalParam: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OPERATION_TYPES,
      sw: true,
    };
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(value) {
        this.save(value);
      },
    },
    valueTypes() {
      if (['EXIST', 'NOT_EXIST'].includes(this.item.operation)) {
        return { SQL: '子查询' };
      }

      const types = {};

      if (!['IN', 'NIN'].includes(this.item.operation)) {
        types.CONST = '常量';
      }

      types.VAR = '变量';

      if (this.item.operation === '' || ['IN', 'NIN'].includes(this.item.operation)) {
        types.SQL = '子查询';
      }

      if (this.externalParam) {
        types.PARAM = '外部参数';
      }
      return types;
    },
    varTypes() {
      if (['ANY', ''].includes(this.item.operation)) {
        return [
          { value: '$_uin', label: '用户uin' },
          { value: '$_uin_sub_all', label: '本人及下属' },
          { value: '$_territory', label: '岗位code' },
          { value: '$_territory_all', label: '本岗位及下属岗位' },
          { value: '$_org', label: '组织code' },
          { value: '$_org_all', label: '本组织及下属组织' },
          { value: '$_ext_role', label: '角色' },
          { value: '$_ext_territory', label: '岗位' },
        ];
      }
      if (['IN', 'NIN'].includes(this.item.operation)) {
        return [
          { value: '$_uin_sub_all', label: '本人及下属' },
          { value: '$_territory_all', label: '本岗位及下属岗位' },
          { value: '$_org_all', label: '本组织及下属组织' },
          { value: '$_ext_role', label: '角色' },
          { value: '$_ext_territory', label: '岗位' },
        ];
      }
      return [
        { value: '$_uin', label: '用户uin' },
        { value: '$_territory', label: '岗位code' },
        { value: '$_org', label: '组织code' },
      ];
    },
  },
  methods: {
    operationChange() {
      if (['EXIST', 'NOT_EXIST'].includes(this.item.operation)) {
        this.item.field = '';
      }
      if (['IN', 'NIN'].includes(this.item.operation)) {
        switch (this.item.value.type) {
          case 'SQL':
            return;
          case 'VAR':
            if (this.item.value.value !== '$_territory_all') {
              this.item.value.value = '$_territory_all';
            }
            return;
          case 'CONST':
            this.item.value.type = '';
            return;
          default:
            return;
        }
      }
      switch (this.item.value.type) {
        case 'SQL':
          this.item.value.type = '';
          return;
        case 'VAR':
          if ('$_territory_all' === this.item.value.value) {
            this.item.value.value = '$_uin';
          }
          return;
        case 'CONST':
          return;
        default:
          return;
      }
    },
    typeChange() {
      if (this.item.value.type === 'PARAM') {
        this.item.value.value = '?';
      } else {
        this.item.value.value = '';
      }
    },
    valueChange() {
      if (['$_uin', '$_territory', '$_territory_all'].includes(this.item.value.value)) {
        delete this.item.value.ext_value;
      }
      if (['$_ext_role', '$_ext_territory'].includes(this.item.value.value)) {
        this.item.value.ext_value = [];
      }
      this.item = JSON.parse(JSON.stringify(this.item));
      this.sw = false;
      this.$nextTick(() => {
        this.sw = true;
      });
    },
    save(data) {
      this.$emit('change', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.condition {
  display: flex;
  .item-input {
    // 单选和输入框
    height: 40px;
  }
  ::v-deep .el-input {
    width: 90px;
    input {
      border-radius: 0;
    }
  }
  .field {
    ::v-deep .el-input {
      width: 120px;
    }
  }
  .logic-type {
    ::v-deep .el-input {
      width: 120px;
    }
  }
  .type {
    ::v-deep .el-input {
      width: 120px;
    }
  }
  .value-type {
    ::v-deep .el-input {
      width: 160px;
    }
  }
  .value {
    width: 150px;
  }
  .empty {
    ::v-deep input {
      border-color: red;
    }
  }
}
</style>
