<template>
  <div>
    <div class="intro-bar">
      <div class="title">{{ row.title }}</div>
      <div class="intro">
        <div class="intro-label">流程key:</div>
        <div class="intro-label">{{ row.process_key }}</div>
        <div class="intro-label">部署时间:</div>
        <div class="intro-label">{{ row.deploy_time }}</div>
      </div>
    </div>
    <div class="wrapper">
      <div class="time-bar">
        <i class="el-icon-warning logo"></i>
        <div>
          系统仅保留
          <span class="days">{{ logTimeLine }}天</span>
          之内的工作流运行日志, 您可以&nbsp;&nbsp;
        </div>
        <el-button type="text" @click="openTimeDia">设置日志保留时长</el-button>
      </div>
      <div class="search-bar">
        <el-input
          v-model="search.param"
          placeholder="搜索流程ID/流程发起方"
          class="search"
          style="width: 220px"
          suffix-icon="el-icon-search"
        ></el-input>
        <el-select
          class="search"
          v-model="search.status"
          placeholder="运行状态：请选择"
          clearable
          style="width: 160px"
        >
          <el-option
            v-for="(item, i) in processRunStatus"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-date-picker
          style="width: 330px"
          class="search"
          v-model="search.triggerTime"
          type="daterange"
          range-separator="-"
          start-placeholder="触发时间:开始日期"
          end-placeholder="触发时间:结束日期"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-date"
          clearable
        ></el-date-picker>
        <el-date-picker
          style="width: 330px"
          class="search"
          v-model="search.endTime"
          type="daterange"
          range-separator="-"
          start-placeholder="结束时间:开始日期"
          end-placeholder="结束时间:结束日期"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-date"
          clearable
        ></el-date-picker>
        <el-button type="primary" class="search" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button icon="el-icon-refresh-left" class="search" @click="reset">重置</el-button>
      </div>
      <div class="list-bar">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          element-loading-text="加载中..."
          element-loading-background="rgba(255,255,255,0.5)"
        >
          <el-table-column label="业务模块" prop="module" min-width="100"></el-table-column>
          <el-table-column label="流程ID" prop="id" min-width="130"></el-table-column>
          <el-table-column label="业务类型" prop="biz_type" min-width="130"></el-table-column>
          <el-table-column label="运行状态" prop="status" min-width="130">
            <template slot-scope="scope">
              <div v-if="scope.row.status === '1'" class="pendding">进行中</div>
              <div v-if="scope.row.status === '2'" class="success">完成</div>
              <div v-if="scope.row.status === '3'" class="end">终止</div>
              <div v-if="scope.row.status === '4'" class="fail">失败</div>
            </template>
          </el-table-column>
          <el-table-column
            label="流程发起方"
            prop="start_user"
            min-width="130"
            show-overflow-tooltip
          >
            <template slot="header">
              流程发起方
              <el-tooltip
                effect="dark"
                content="若是用户触发的审批业务则显示用户的员工编号；若是系统触发器或自动触发的流程，则显示为系统"
                placement="top"
              >
                <i class="el-icon-warning" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="耗时" prop="elapsed_time" min-width="130"></el-table-column>
          <el-table-column label="触发时间" prop="start_time" min-width="150">
            <template slot-scope="scope">
              {{ formatTime(scope.row.start_time) }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间" prop="end_time" min-width="150">
            <template slot-scope="scope">
              {{ formatTime(scope.row.end_time) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="170" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="checkDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="detailTitle"
      :visible="detailVisiable"
      width="960px"
      @close="detailVisiable = false"
    >
      <div class="detail-wrapper" v-loading="detailLoading">
        <div v-for="(item, i) in logs" :key="i" class="content-line">
          {{ `${i + 1}${item.content}` }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailVisiable = false">关 闭</el-button>
      </div>
    </el-dialog>
    <processLogTime
      :log-time-line="logTimeLine"
      :visible="visible"
      @close="visible = false"
      @setProcessLogConfigure="setProcessLogConfigure"
    />
  </div>
</template>
<script>
import processLogTime from './process-log-time.vue';
import { processRunStatus } from '../common';
import { mapGetters, mapActions } from 'vuex';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
export default {
  name: '',
  components: { processLogTime },
  props: {
    innerComponent: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      row: {},
      logTimeLine: 30,
      visible: false,
      detailVisiable: false,
      search: {
        param: '',
        status: '',
        module: '',
        biz_type: '',
        triggerTime: [],
        endTime: [],
      },
      originSearch: {},
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableLoading: false,
      processRunStatus,
      detailRow: {},
      detailLoading: false,
      logs: [],
    };
  },
  async created() {
    let rowInfo;
    if (!this.innerComponent) {
      this.$store.commit('user/setBreadcrumb', [
        { title: '流程管理', path: '/approval-process/process-manage' },
        { title: '流程日志', path: '/workflow/process-log' },
      ]);
      rowInfo = JSON.parse(this.$route.query.row);
    } else {
      rowInfo = this.processInfo;
    }
    this.row = {
      ...rowInfo,
      title: `${rowInfo.process_name} | ${rowInfo.version}`,
    };
    await this.requestBusinessOptionList();
  },
  mounted() {
    this.getProcessLogConfigure();
    this.reset();
  },
  methods: {
    ...mapActions({
      requestBusinessOptionList: 'workflow/requestBusinessOptionList',
    }),
    openTimeDia() {
      this.visible = true;
    },
    checkDetail(row) {
      this.detailRow = row;
      if (row.id) {
        this.detailVisiable = true;
        this.getProcessInstanceLog(row.id);
      }
    },
    handleSearch() {
      this.currentPage = 1;
      this.originSearch = _.cloneDeep(this.search);
      this.getList();
    },
    async getList() {
      try {
        this.tableLoading = true;
        const { data = [], total = 0 } = await this.$request({
          url: this.$api.getProcessInstanceList,
          data: {
            input_params: {
              start: `${(this.currentPage - 1) * this.pageSize}`,
              size: `${this.pageSize}`,
              param: this.originSearch.param,
              status: this.originSearch.status,
              module: this.originSearch.module,
              biz_type: this.originSearch.biz_type,
              process_define_id: this.row.process_id,
            },
            start_trigger_time: this.originSearch.triggerTime[0],
            end_trigger_time: this.originSearch.triggerTime[1],
            start_finish_time: this.originSearch.endTime[0],
            end_finish_time: this.originSearch.endTime[1],
          },
          showLoading: false,
        });
        this.tableData = data.map((item) => {
          return {
            ...item,
            biz_type: this.generateBizType(item.biz_type),
            module: this.generateModule(item.module),
          };
        });
        this.total = total;
        this.tableLoading = false;
      } catch (err) {
        console.log(err);
        this.tableLoading = false;
      }
    },
    async getProcessInstanceLog(id) {
      try {
        this.detailLoading = true;
        this.logs = [];
        const { logs } = await this.$request({
          url: this.$api.getProcessInstanceLog,
          data: {
            process_instance_id: id,
          },
          showLoading: false,
        });
        this.logs = logs;
        this.detailLoading = false;
      } catch (err) {
        console.error('errrr=', err);
        this.detailLoading = false;
      }
    },
    getProcessLogConfigure() {
      console.log('this.row', this.row);
      this.$request({
        url: this.$api.getProcessLogConfigure,
        data: {
          process_key: this.row.process_key,
        },
      }).then((res) => {
        if (typeof +res.log_clear_day === 'number') {
          this.logTimeLine = +res.log_clear_day;
        }
      });
    },
    setProcessLogConfigure(day) {
      this.$request({
        url: this.$api.setProcessLogConfigure,
        data: {
          process_key: this.row.process_key,
          log_clear_day: +day,
        },
      }).then(() => {
        this.getProcessLogConfigure();
      });
    },
    reset() {
      this.search.param = '';
      this.search.status = '';
      this.search.module = '';
      this.search.biz_type = '';
      this.search.triggerTime = [];
      this.search.endTime = [];
      this.currentPage = 1;
      this.pageSize = 10;
      this.originSearch = _.cloneDeep(this.search);
      this.getList();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    formatTime(val) {
      if (!val) return '-';
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    },
    generateModule(val) {
      if (!val) return '';
      return this.businessModuleList.find((item) => item.value === val)?.label || '';
    },
    generateBizType(val) {
      if (!val) return '';
      return this.businessTypeList.find((item) => item.value === val)?.label || '';
    },
  },
  computed: {
    ...mapGetters({
      businessTypeList: 'workflow/getBusinessTypeList',
      businessModuleList: 'workflow/getBusinessModuleList',
    }),
    detailTitle() {
      return `【${this.detailRow.module ? `${this.detailRow.module}-` : ''}${
        this.detailRow.biz_type ?? ''
      }】详情`;
    },
  },
};
</script>
<style lang="scss" scoped>
// dev环境引入的scss文件不生效
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/table.scss';
@import '@/styles/element/dialog.scss';
.el-button.is-disabled {
  color: #c0c4cc;
}
.intro-bar {
  width: 100%;
  background: #fff;
  padding: 16px;
  .title {
    font-weight: 700;
    font-size: 16px;
    color: #667082;
    line-height: 24px;
    padding-bottom: 16px;
  }
  .intro {
    display: flex;
    .intro-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #384050;
      &:nth-of-type(n + 2) {
        padding-left: 30px;
      }
    }
  }
}
.wrapper {
  width: 100%;
  padding: 16px;
  background: #fff;
  margin-top: 8px;
  .time-bar {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    background: #f9e0c7;
    border-radius: 3px;
    font-size: 14px;
    line-height: 22px;
    color: #384050;
    font-weight: 400;
    margin-bottom: 16px;
    .logo {
      font-size: 18px;
      color: #ffaf00;
      margin-right: 10px;
    }
    .days {
      color: #d35a21;
      font-weight: 500;
    }
  }
  .search-bar {
    display: flex;
    flex-flow: wrap;
    ::v-deep .el-input__inner {
      height: 32px !important;
    }
    ::v-deep .el-input__icon {
      line-height: 32px !important;
    }
    .search {
      margin-bottom: 8px;
      margin-left: 8px;
    }
    .el-button {
      font-weight: 400;
      padding: 5px 12px;
      height: 32px;
      &.el-button--text {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .el-button--info {
      border: 1px solid transparent;
      background-color: #f2f3f8;
      color: #1c2028;
      &.primary {
        color: #0069fa;
      }
      &.is-disabled {
        color: #c8d0dc;
        background: #f6f7fb;
        border: 1px solid transparent;
      }
      &:hover,
      &:focus {
        background: #a6a9ad;
      }
    }
    .el-button--primary {
      background: #0069fa;
      &.is-disabled {
        background: #96c3fa;
      }
      &:hover,
      &:focus {
        background: #409eff;
      }
    }
  }
  .list-bar {
    width: 100%;
    margin-top: 16px;
    ::v-deep .el-table__header th {
      background-color: #f6f7fb !important;
    }
    ::v-deep .cell {
      color: #384050;
      text-align: left !important;
    }
    ::v-deep thead th .cell {
      color: #667082;
      font-weight: 400;
    }
    ::v-deep .el-button--text {
      color: #0077ff;
      font-weight: 400;
    }
  }
}
.detail-wrapper {
  width: 100%;
  background: #f2f3f8;
  padding: 16px;
  max-height: 960px;
  overflow-y: scroll;
  .content-line {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #384050;
  }
}
.success {
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8f8f2;
  border: 1px solid #48c79c;
  border-radius: 2px;
  color: #078d5c;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 46px;
}
.fail {
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdecee;
  border: 1px solid #f36d78;
  border-radius: 2px;
  color: #c9353f;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 46px;
}
.end {
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f3f8;
  border: 1px solid #c8d0dc;
  border-radius: 2px;
  color: #384050;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 46px;
}
.pendding {
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f1ff;
  border: 1px solid #4a9bff;
  border-radius: 2px;
  color: #115ed9;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 62px;
}
.pagination-container {
  text-align: right;
  margin-top: 24px;
}
::v-deep .el-range-editor.el-input__inner {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}
.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
  color: #d8d8d8;
}

.el-select {
  ::v-deep .el-input__inner {
    height: 32px;
  }
  ::v-deep .el-input__icon {
    line-height: 32px;
  }
}

.el-select-dropdown__item {
  padding-left: 8px;
  height: 28px;
  line-height: 28px;
  &.hover,
  &.selected,
  &.hover.selected {
    background-color: #e6f1ff !important;
    color: #0077ff !important;
    font-weight: 400 !important;
  }
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  display: none;
}

.el-dialog__wrapper {
  ::v-deep .el-dialog__header {
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #e5eaf2;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #e5eaf2;
  }
}
</style>
