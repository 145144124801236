<template>
  <div class="login">
    <template v-if="!(needCode && phone)">
      <p class="login-title">登录账号</p>
      <el-form class="login-form" ref="form" :model="formData" :rules="rules">
        <el-form-item prop="account" class="item">
          <el-input
            class="input"
            placeholder="请输入员工账号"
            v-model.trim="formData.account"
            maxlength="100"
            @keyup.enter.native="login"
          >
            <i slot="prefix" class="iconfont icon-user"></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password" class="item">
          <el-input
            type="password"
            class="input"
            placeholder="请输入员工密码"
            v-model="formData.password"
            maxlength="100"
            @keyup.enter.native="login"
          >
            <i slot="prefix" class="iconfont icon-password"></i>
          </el-input>
        </el-form-item>

        <el-button
          :type="'primary'"
          class="login-btn"
          round
          :disabled="!loginValid"
          @click="login"
          :loading="loading"
        >
          登录
        </el-button>
        <div class="auto-login" v-if="storage">
          <el-checkbox v-model="autoLogin" class="content" :class="{ ischecked: autoLogin }">
            自动登录
          </el-checkbox>
        </div>
      </el-form>
    </template>
    <template v-else>
      <p class="login-title">输入验证码</p>
      <div>我们已向电话 {{ phone }} 发送短信，请输入验证码以登录</div>
      <Code class="code-input-group" length="6" @change="codeChange" />
      <el-button
        type="primary"
        class="login-btn"
        round
        :disabled="!codeValid"
        @click="loginWithCode"
        :loading="loading"
      >
        验证
      </el-button>
      <div class="login-text-btn">
        <el-button type="text" @click="getCode">重新获取</el-button>
        <div v-if="timeLimit !== 0">（{{ timeLimit }}秒后可获取）</div>
      </div>
    </template>
  </div>
</template>
<script>
import Code from './code.vue';
import { LOGIN_STEP } from '@/utils/constant';
import { gotoHome } from '@/utils/util';

export default {
  name: 'Account',
  components: { Code },
  props: {
    storage: {
      type: Boolean,
      default: true,
    },
    redirectUrl: {
      type: String,
      default: '',
    },
    fromUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    // 只有在按下登录后没有拿到手机号才会触发这个错误
    const hasPhone = (rule, value, callback) => {
      if (this.needCode && this.phone === '') {
        callback(new Error('该账号未绑定手机号，请联系管理员绑定手机号后登录'));
      } else {
        callback();
      }
    };
    return {
      formData: {
        account: '',
        password: '',
      },
      // 是否自动登录
      autoLogin: true,
      // 表单验证对象
      rules: {
        // 设置手机号与验证码的合格条件
        account: [
          {
            required: true,
            message: '请输入员工账号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入员工密码',
            trigger: 'blur',
          },
          { validator: hasPhone, trigger: 'blur' },
        ],
      },
      loading: false,
      // 需要手机验证码登录
      needCode: false,
      // 手机号
      phone: '',
      // 六位验证码
      loginCode: '',
      // 临时token
      tmpToken: '',
      // 验证码的时间限制
      timeLimit: 0,
      // 验证码计时器
      timer: null,
    };
  },
  computed: {
    loginValid() {
      return this.formData.account && this.formData.password;
    },
    codeValid() {
      return this.loginCode.length === 6;
    },
  },
  methods: {
    login() {
      if (this.loading) return;
      this.loading = true;
      const url = this.$api.login;
      const type = 'post';

      const data = {
        account: this.formData.account,
        password: this.formData.password,
        login_type: 'admin',
      };
      const cb = (data) => {
        if (data.next_step) {
          this.loading = false;
          this.loginStep(data);
        } else {
          this.loginSuccess(data);
        }
      };
      const fault = () => {
        this.loading = false;
      };
      this.$request({ url, cb, type, data, fault, config: this.apiConfig });
    },
    loginWithCode() {
      this.$request({
        url: this.$api.loginWithCode,
        type: 'post',
        data: {
          sms_code: this.loginCode,
          login_type: 'admin',
        },
        tmpToken: this.tmpToken,
        cb: (data) => {
          this.loginSuccess(data);
        },
        config: this.apiConfig,
      });
    },
    async loginSuccess(data) {
      console.log('------loginSuccess------', data);
      console.log('------storage------', this.storage);
      this.$message({
        message: '登录成功',
        type: 'success',
      });
      this.$cookies.set('uin', data.uin);
      this.$cookies.set('saas_token', data.token);
      if (this.storage) {
        this.$cookies.set('uin', data.uin);
        this.$cookies.set('saas_token', data.token);
        setTimeout(() => {
          if (this.redirectUrl) {
            window.location.href = `${this.redirectUrl}?token=${encodeURIComponent(data.token)}`;
          } else {
            gotoHome();
          }
        }, 500);
      } else {
        const userInfo = await this.$request({
          url: this.$api.getUserinfo,
          data: {},
          tmpToken: data.token,
        });
        const tenantInfo = await this.$request({
          url: this.$api.getTenantInfo,
          data: {},
          tmpToken: data.token,
        });
        parent.postMessage(
          {
            saas_token: data.token,
            uin: data.uin,
            user_info: userInfo,
            tenant_info: tenantInfo,
          },
          this.fromUrl,
        );
      }
    },
    loginStep(data) {
      if (data.next_step === LOGIN_STEP.phone) {
        this.tmpToken = data.token;
        this.needCode = true;

        this.$request({
          url: this.$api.loginGetPhone,
          type: 'post',
          data: {},
          tmpToken: this.tmpToken,
          cb: ({ phone }) => {
            if (phone) {
              this.phone = phone;
              this.getCode();
            } else {
              this.$refs.form.validate(() => {
                this.needCode = false;
              });
            }
          },
          config: this.apiConfig,
        });
      }
    },
    getCode() {
      if (this.timer !== null) return;
      this.$request({
        url: this.$api.sendSmsCode,
        type: 'post',
        data: {},
        tmpToken: this.tmpToken,
        config: this.apiConfig,
      });
      this.timeLimit = 30;
      this.timer = setInterval(() => {
        this.timeLimit -= 1;
        if (this.timeLimit === 0) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    codeChange(code) {
      this.loginCode = code;
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  .login-title {
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .login-form {
    margin-top: 36px;
    .item {
      margin-bottom: 36px;
    }
    .input {
      height: 40px;
      background-color: #fff;
      border-radius: 2px;
      ::v-deep .el-input__inner {
        padding-left: 40px;
      }
      ::v-deep .el-input__prefix {
        left: 12px;
      }
    }
  }
  .login-btn {
    width: 100%;
    background: #0069fa;
    border: none;
    &:disabled {
      background-color: #a0cfff;
    }
  }
  .login-text-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    ::v-deep.el-button--text {
      color: #0069fa;
    }
  }
  .auto-login {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      ::v-deep.el-checkbox__input {
        border: 1px solid #c8d0dc;
        box-sizing: border-box;
        background: #fff;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        &:checked {
          border: 1px solid #0069fa;
          background: #0069fa;
        }
      }
      ::v-deep.el-checkbox__label {
        color: #1c2028;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .ischecked {
      ::v-deep.el-checkbox__input {
        border: 1px solid #0069fa;
        background: #0069fa;
      }
    }
  }
  .code-tip {
    font-size: 14px;
    line-height: 20px;
    color: #1c2028;
  }
  .code-input-group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>
