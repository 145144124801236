<template>
  <div class="node-container">
    <EventTip />
    <div class="intro-title">节点开始</div>
    <EventCascader
      v-model="eventList.start"
      :event-options="eventOptions"
      node-type="start"
      ref-name="event_start_s"
    />
    <div class="intro-title">
      {{ config.subref === 'prompt' ? '节点通过' : '节点同意' }}
    </div>
    <EventCascader
      v-model="eventList.endPass"
      :event-options="eventOptions"
      node-type="endPass"
      ref-name="event_end_pass_s"
    />
    <div class="intro-title">
      {{ config.subref === 'prompt' ? '节点退回' : '节点拒绝' }}
      <el-tooltip
        effect="dark"
        content="通过配置排他网关节点，返回到上一个节点或其他指定节点"
        placement="top"
        v-if="config.subref === 'prompt'"
      >
        <i class="iconfont icon-question" />
      </el-tooltip>
    </div>
    <EventCascader
      v-model="eventList.endReject"
      :event-options="eventOptions"
      ref-name="event_end_reject_s"
      node-type="endReject"
    />
    <div class="intro-title" v-if="config.subref !== 'prompt'">节点退回</div>
    <EventCascader
      v-model="eventList.endRollback"
      :event-options="eventOptions"
      v-if="config.subref !== 'prompt'"
      node-type="endRollback"
      ref-name="event_end_refund_s"
    />
    <CreateCloudEventDiaVue
      ref="createCloudEventDialog"
      :info="info"
      @getEventOptions="getEventOptions"
      @addEventValue="addCloudEventValue"
    />
    <CreateNotifyEventDiaVue
      ref="creatNotifyEventDialog"
      :info="info"
      @getEventOptions="getEventOptions"
      @addEventValue="addNoticeEventValue"
    />
  </div>
</template>

<script>
import EventTip from '@/components/eventTip';
import EventCascader from '@/components/eventCascader';
import CreateCloudEventDiaVue from './createCloudEventDia.vue';
import CreateNotifyEventDiaVue from './createNotifyEventDia.vue';
export default {
  name: 'WorkflowDetailConfigEvent',
  components: { EventTip, EventCascader, CreateCloudEventDiaVue, CreateNotifyEventDiaVue },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // start, endPass, endReject
      nodeType: '',
      init: false,
      eventList: {
        start: [],
        endPass: [],
        endReject: [],
        endRollback: [],
      },
      eventOptions: [],
      // 云函数事件ids
      cloudEventIds: [],
      // 通知事件ids
      notifyEventIds: [],
    };
  },
  watch: {
    'eventList.start'() {
      if (this.init) this.submitList();
    },
    'eventList.endPass'() {
      if (this.init) this.submitList();
    },
    'eventList.endReject'() {
      if (this.init) this.submitList();
    },
    'eventList.endRollback'() {
      if (this.init) this.submitList();
    },
  },
  created() {
    this.getEventOptions();
  },
  methods: {
    // 添加新建的通知事件到输入框
    addNoticeEventValue(id) {
      if (this.nodeType && id) {
        this.eventList[this.nodeType].push(['notify', id]);
      }
    },
    // 添加新建的云函数到输入框
    addCloudEventValue(id) {
      if (this.nodeType && id) {
        this.eventList[this.nodeType].push(['cloud_fun', id]);
      }
    },
    // 获取事件选择项
    getEventOptions() {
      return new Promise((resolve) => {
        this.$request({
          url: this.$api.getProcessActionList,
          data: {
            page: 0,
            size: -1,
          },
          cb: (res) => {
            this.cloudEventIds = [];
            this.notifyEventIds = [];
            const notifyOptions = {
              value: 'notify',
              label: '通知消息',
              children: [],
            };
            const cloudFunOptions = {
              value: 'cloud_fun',
              label: '云函数自定义事件',
              children: [],
            };
            res.list.forEach((item) => {
              if (item.category === 1) {
                this.notifyEventIds.push(item.id);
                if (item.status === 2) {
                  notifyOptions.children.push({
                    value: item.id,
                    label: `通知消息-${item.name}`,
                    status: item.status,
                    disabled: true,
                    prefix: '通知消息-',
                  });
                } else {
                  notifyOptions.children.unshift({
                    value: item.id,
                    label: `通知消息-${item.name}`,
                    status: item.status,
                    disabled: false,
                    prefix: '通知消息-',
                  });
                }
              } else {
                this.cloudEventIds.push(item.id);
                if (
                  item.status === 2 ||
                  (item.keys.length && !item.keys.includes(this.info.process_key))
                ) {
                  cloudFunOptions.children.push({
                    value: item.id,
                    label: `云函数-${item.name}`,
                    status: item.status,
                    disabled: true,
                    prefix: '云函数-',
                  });
                } else {
                  cloudFunOptions.children.unshift({
                    value: item.id,
                    label: `云函数-${item.name}`,
                    status: item.status,
                    disabled: false,
                    prefix: '云函数-',
                  });
                }
              }
            });
            this.eventOptions = [notifyOptions, cloudFunOptions];
            this.init = true;

            // 更新回显
            this.updateList(this.value);
            resolve();
          },
        });
      });
    },
    // 同时满足节点数据结构将开始结束放在一个列表内与事件配置需要将开始结束分两个列表存放的情况
    updateList(value) {
      this.eventList.start = value
        .filter((i) => i.on === 'started')
        .map((i) => {
          if (this.cloudEventIds.includes(i.action_id)) {
            // 级联选择器返回的单元数据格式为 [父元素value, 字元素value]
            return ['cloud_fun', i.action_id];
          }
          if (this.notifyEventIds.includes(i.action_id)) {
            // 级联选择器返回的单元数据格式为 [父元素value, 字元素value]
            return ['notify', i.action_id];
          }
          return '';
        })
        .filter((i) => i);
      this.eventList.endPass = value
        .filter((i) => i.on === 'ended' && i.condition === 'pass')
        .map((i) => {
          if (this.cloudEventIds.includes(i.action_id)) {
            return ['cloud_fun', i.action_id];
          }
          if (this.notifyEventIds.includes(i.action_id)) {
            return ['notify', i.action_id];
          }
          return '';
        })
        .filter((i) => i);
      this.eventList.endReject = value
        .filter((i) => i.on === 'ended' && i.condition === 'reject')
        .map((i) => {
          if (this.cloudEventIds.includes(i.action_id)) {
            return ['cloud_fun', i.action_id];
          }
          if (this.notifyEventIds.includes(i.action_id)) {
            return ['notify', i.action_id];
          }
          return '';
        })
        .filter((i) => i);
      this.eventList.endRollback = value
        .filter((i) => i.on === 'ended' && i.condition === 'rollback')
        .map((i) => {
          if (this.cloudEventIds.includes(i.action_id)) {
            return ['cloud_fun', i.action_id];
          }
          if (this.notifyEventIds.includes(i.action_id)) {
            return ['notify', i.action_id];
          }
          return '';
        })
        .filter((i) => i);
    },
    submitList() {
      const events = [
        // 不处理的节点全部保留 即将处理的开始结束节点全部清除
        ...(this.value.filter((i) => i.on !== 'started' && i.on !== 'ended') || []),
        // 重新加上新的开始和结束节点
        ...this.eventList.start.map((i) => {
          return {
            on: 'started',
            // 级联选择的数据格式为【父元素value, 子节点value】
            action_id: i[1],
          };
        }),
        ...this.eventList.endPass.map((i) => {
          return {
            on: 'ended',
            // 级联选择的数据格式为【父元素value, 子节点value】
            action_id: i[1],
            condition: 'pass',
          };
        }),
        ...this.eventList.endReject.map((i) => {
          return {
            on: 'ended',
            // 级联选择的数据格式为【父元素value, 子节点value】
            action_id: i[1],
            condition: 'reject',
          };
        }),
        ...this.eventList.endRollback.map((i) => {
          return {
            on: 'ended',
            // 级联选择的数据格式为【父元素value, 子节点value】
            action_id: i[1],
            condition: 'rollback',
          };
        }),
      ];
      this.$emit('update', events);
    },
    openCreateCloudEventDialog(nodeType) {
      this.nodeType = nodeType;
      this.$refs.createCloudEventDialog.openDiaLog();
    },
    openCreateNotifyEventDialog(nodeType) {
      this.nodeType = nodeType;
      this.$refs.creatNotifyEventDialog.openDiaLog();
    },
  },
};
</script>

<style lang="scss" scoped>
.node-container {
  padding: 0 16px;
  .node-title {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #1c2028;
    font-weight: 700;
  }
  .node-split {
    margin: 16px 0;
    height: 1px;
    background: #e5eaf2;
  }
  .intro-title {
    height: 40px;
    line-height: 40px;
    background: #f6f7fb;
    font-weight: 500;
    font-size: 14px;
    color: #667082;
    margin: 10px -10px 8px;
    padding-left: 10px;
  }
}
</style>
