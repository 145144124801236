<template>
  <el-popover placement="top" width="160" v-model="popoverVisible">
    <slot name="body"></slot>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="popoverVisible = false">取消</el-button>
      <el-button type="primary" size="mini" @click="confirm">确定</el-button>
    </div>
    <slot name="reference" slot="reference"></slot>
  </el-popover>
</template>

<script>
export default {
  name: 'ConfirmButton',
  data() {
    return {
      popoverVisible: false,
    };
  },
  methods: {
    confirm() {
      this.popoverVisible = false;
      this.$emit('confirm');
    },
  },
};
</script>
