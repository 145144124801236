<template>
  <el-dialog
    class="role-dialog"
    title="关联的角色"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="700px"
  >
    <div class="title">{{ objectInfo.name }}</div>

    <el-table :data="info" stripe>
      <el-table-column label="角色">
        <template slot-scope="scope">
          {{ scope.row.role_name }}
        </template>
      </el-table-column>
      <el-table-column v-for="item in objectActionOptions" :key="item.value" :label="item.label">
        <template slot-scope="scope">
          <span v-if="scope.row[`${item.value}_action`]">
            <i class="el-icon-check" style="font-weight: 900" />
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" type="primary">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
const objectActionOptions = [
  { label: '查询', value: 'select' },
  { label: '新增', value: 'insert' },
  { label: '更新', value: 'update' },
  { label: '删除', value: 'delete' },
];
export default {
  props: {
    show: { type: Boolean, default: false },
    objectInfo: { type: Object, default: () => {} },
  },
  data() {
    return {
      objectActionOptions,
      info: [],
    };
  },
  computed: {
    visible: {
      get() {
        if (this.show) {
          this.getObjectRoleInfo();
        }
        return this.show;
      },
      set(val) {
        this.$emit('cancel', val);
      },
    },
  },
  methods: {
    async getObjectRoleInfo() {
      this.info = [];
      try {
        const { data } = await this.$request({
          url: this.$api.getObjectRolePermission,
          data: {
            appid: this.objectInfo.appid,
            code: this.objectInfo.code,
          },
        });
        this.info = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.role-dialog {
  ::v-deep {
    .el-dialog__header {
      padding: 16px 24px;
      font-weight: 550;
      font-size: 20px;
      line-height: 28px;
      box-shadow: inset 0px -1px 0px #e5eaf2;
    }
    .el-dialog__body {
      padding: 24px;
      .title {
        font-weight: 550;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }
    .el-dialog__footer {
      padding: 16px 25px;
      box-shadow: inset 0px 1px 0px #e5eaf2;
    }
  }
}
</style>
