<template>
  <div class="navbar" mode="horizontal">
    <hamburger
      class="hamburger-container"
      :toggle-click="toggleSideBar"
      :is-active="hamburgerActive"
    ></hamburger>
    <breadcrumb class="breadcrumb-container"></breadcrumb>
    <div class="tenant-info">
      租户名称：{{ getTenantInfo.name }}，租户ID：{{ getTenantInfo.id }}
    </div>
    <div class="right-menu">
      <div class="right-menu-item">
        <img
          class="user-pic"
          src="https://baike-med-1256891581.file.myqcloud.com/2022032/a5935090-a37c-11ec-a3f4-354087f4d05d_0.png"
          @click="freshSessionStorage"
        />
        <span class="user-name">{{ getUserInfo.staffname }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import { gotoUrl } from '@/utils/util';
import { mapGetters } from 'vuex';
export default {
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
  },
  props: {
    hamburgerActive: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user', ['getTenantInfo', 'getUserInfo']), // user 模块名称
  },
  methods: {
    toggleSideBar() {
      this.$emit('toggleSideBar', !this.hamburgerActive);
    },
    freshSessionStorage() {
      sessionStorage.removeItem('userinfo-ams');
      this.$cookies.set('uin', '');
      this.$cookies.set('saas_token', '');
      gotoUrl('/login', { replace: true, href: true });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  box-shadow: 0 5px 5px rgba(0, 21, 41, 0.08);
  background-color: #fff;
  display: flex;
  .tenant-info {
    text-align: center;
    flex: 1;
  }
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container {
    float: left;
  }
  .right-menu {
    height: 100%;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 20px;
    }
  }
  .user-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    float: left;
    overflow: hidden;
    transition: all 0.6s;
    margin-top: 5px;
    border: 1px solid #226ab6;
    margin-right: 8px;
    cursor: pointer;
  }
}
</style>
