<template>
  <div class="container">
    <div class="root-contianer" v-if="nodes.length > 1" id="root">
      <el-select
        :value="computedConditionalSymbol"
        placeholder="请选择"
        style="width: 60px"
        @change="rootSelectChange"
      >
        <el-option
          v-for="r in rootOperations"
          :key="r.value"
          :label="r.label"
          :value="r.value"
        ></el-option>
      </el-select>
    </div>
    <div>
      <div class="node-contianer" v-for="(item, i) in nodes" :key="item.id" :id="item.id">
        <el-select
          :value="item.field"
          placeholder="请选择"
          style="width: 120px"
          @change="(e) => fieldSelectChange(e, item)"
          :disabled="!fields.length"
        >
          <el-option
            v-for="f in fields"
            :key="f.name"
            :label="f.display_name"
            :value="f.name"
          ></el-option>
        </el-select>

        <el-select
          :value="item.operator"
          placeholder="请选择"
          style="width: 120px"
          :disabled="!item.field"
          @change="(e) => operatorSelectChange(e, item)"
          class="left-margin"
        >
          <el-option
            v-for="ope in item.operatorOptions"
            :key="ope.value"
            :label="ope.label"
            :value="ope.value"
          ></el-option>
        </el-select>
        <el-date-picker
          v-if="item.value_type === 'DATETIME'"
          v-model="item.value"
          type="datetime"
          placeholder="请选择"
          value-format="timestamp"
          @change="(e) => valueSelectChange(e, item)"
          :disabled="!item.field"
          style="width: 170px"
          class="left-margin"
        ></el-date-picker>
        <el-select
          v-else-if="item.value_type.includes('SELECT')"
          :value="item.value"
          placeholder="请选择"
          style="width: 170px"
          :disabled="!item.field"
          @change="(e) => valueSelectChange(e, item)"
          class="left-margin"
        >
          <el-option
            v-for="ope in item.valueOptions"
            :key="ope.value"
            :label="ope.label"
            :value="ope.value"
          ></el-option>
        </el-select>
        <el-input-number
          v-else-if="item.value_type === 'NUMBER'"
          :disabled="!item.field"
          size="mini"
          :controls="false"
          :value="item.value"
          @input="(e) => valueSelectChange(e, item)"
          style="width: 170px"
          class="left-margin"
        ></el-input-number>
        <el-input
          v-else
          :disabled="!item.field"
          :value="item.value"
          @input="(e) => valueSelectChange(e, item)"
          placeholder="请输入"
          style="width: 170px"
          class="left-margin"
        ></el-input>
        <img
          :src="deleteSvg"
          alt=""
          class="svg-class left-margin"
          v-if="nodes.length > 1"
          @click="deleteNodes(i)"
        />
        <img :src="addSvg" alt="" class="svg-class left-margin" @click="addNodes" />
      </div>
    </div>
  </div>
</template>

<script>
import LeaderLine from 'leader-line-vue';
import addSvg from './add.svg';
import deleteSvg from './delete.svg';
import { get, debounce } from 'lodash';
const _ = { get, debounce };
export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    conditionalSymbolPath: {
      type: String,
      default: '',
    },
    conditionalsPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addSvg,
      deleteSvg,
      lines: [],
      nodeId: 'n1',
      nodes: [
        {
          field: '',
          operator: '',
          value: '',
          id: 'n1',
          value_type: '',
        },
      ],
      rootVal: '&&',
      rootOperations: [
        { label: '且', value: 'and' },
        { label: '或', value: 'or' },
      ],
      textOperators: [
        { label: '等于', value: '==' },
        { label: '不等于', value: '!=' },
        { label: '开始为', value: 's%' },
        { label: '结束为', value: '%s' },
      ],
      numOperators: [
        { label: '等于', value: '==' },
        { label: '不等于', value: '!=' },
        { label: '大于', value: '>' },
        { label: '大于等于', value: '>=' },
        { label: '小于', value: '<' },
        { label: '小于等于', value: '<=' },
      ],
      dateOperators: [
        { label: '等于', value: '==' },
        { label: '不等于', value: '!=' },
        { label: '晚于', value: '>' },
        { label: '早于', value: '<' },
      ],
      domEle: '',
    };
  },
  computed: {
    computedConditionalSymbol() {
      return _.get(this.value, this.conditionalSymbolPath);
    },
  },

  async mounted() {
    const dom = document.getElementsByClassName('configPanel')[0];
    if (dom) {
      dom.addEventListener('scroll', this.refreshLines);
      this.domEle = dom;
    }
    if (_.get(this.value, this.conditionalsPath)?.length) {
      this.nodes = _.get(this.value, this.conditionalsPath).map((o) => {
        this.nodeId = this.nodeId + 1;
        let operatorOptions = [];
        const findFieldInfo = this.fields.find((fd) => fd.name === o.field);
        const valueOptions =
          findFieldInfo?.select_one_int_option?.options ||
          findFieldInfo?.select_one_option?.options ||
          [];
        let value = '';
        if (o.value_type === 'DATETIME') {
          operatorOptions = this.dateOperators;
          value = o.value * 1000;
        } else if (
          o.value_type?.includes('INT') ||
          o.value_type?.includes('FLOAT') ||
          o.value_type?.includes('NUMBER')
        ) {
          operatorOptions = this.numOperators;
        } else {
          operatorOptions = this.textOperators;
        }
        return {
          ...o,
          id: this.nodeId,
          operatorOptions,
          valueOptions,
          value: value ? value : o.value,
        };
      });
      setTimeout(() => {
        this.drawLines();
      }, 100);
    }
  },
  beforeDestroy() {
    /**
     * 离开页面时销毁所有line
     */
    this.destoryLine();
    if (this.domEle) {
      this.domEle.removeEventListener('scroll', this.refreshLines);
      this.domEle = null;
    }
  },
  methods: {
    fieldSelectChange(fieldName, fieldInfo) {
      // 操作符
      fieldInfo.operator = '';
      // 值
      fieldInfo.value = '';
      // 字段名
      fieldInfo.field = fieldName;
      const findFieldInfo = this.fields.find((fd) => fd.name === fieldName);
      const { value_type } = findFieldInfo;
      console.log('findFieldInfo====', findFieldInfo);
      const valueOptions =
        findFieldInfo?.select_one_int_option?.options ||
        findFieldInfo?.select_one_option?.options ||
        [];
      fieldInfo.valueOptions = valueOptions;
      if (value_type === 'DATETIME') {
        fieldInfo.operatorOptions = this.dateOperators;
        fieldInfo.value_type = 'DATETIME';
      } else if (
        (value_type.includes('INT') ||
          value_type.includes('FLOAT') ||
          value_type.includes('NUMBER')) &&
        !value_type.includes('SELECT')
      ) {
        fieldInfo.operatorOptions = this.numOperators;
        fieldInfo.value_type = 'NUMBER';
      } else {
        fieldInfo.operatorOptions = this.textOperators;
        fieldInfo.value_type = value_type;
      }
      this.updateNodes();
    },
    operatorSelectChange(ope, fieldInfo) {
      fieldInfo.value = '';
      fieldInfo.operator = ope;
      this.updateNodes();
    },
    valueSelectChange(value, fieldInfo) {
      fieldInfo.value = value;
      this.updateNodes();
    },
    addNodes() {
      this.nodeId = this.nodeId + 1;
      this.nodes.push({
        field: '',
        operator: '',
        value: '',
        id: this.nodeId,
        value_type: '',
      });
      this.updateNodes();
      setTimeout(() => {
        this.drawLines();
      });
    },
    deleteNodes(i) {
      this.nodes.splice(i, 1);
      this.updateNodes();
      setTimeout(() => {
        this.drawLines();
      });
    },
    rootSelectChange(e) {
      this.valueUpdate(this.conditionalSymbolPath, e);
    },
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
    updateNodes() {
      this.$nextTick(() => {
        this.valueUpdate(
          this.conditionalsPath,
          this.nodes.map((node) => ({
            field: node.field,
            operator: node.operator,
            value: node.value_type === 'DATETIME' ? node.value / 1000 : node.value,
            value_type: node.value_type,
          })),
        );
      });
    },
    // 画线
    drawLines() {
      this.destoryLine();
      if (this.nodes.length > 1) {
        setTimeout(() => {
          this.nodes.forEach((element) => {
            const start = document.getElementById('root');
            const line = LeaderLine.setLine(start, document.getElementById(element.id));
            line.startPlug = 'square';
            line.color = '#1890ff';
            line.path = 'grid';
            line.size = 2;
            line.setOptions({
              startSocket: 'right',
              endSocket: 'left',
            });
            this.lines.push(line);
          });
        }, 10);
      }
    },
    // 销毁所有线条
    destoryLine() {
      if (this.lines.length) {
        this.lines.forEach((line) => {
          line.remove();
        });
        this.lines = [];
      }
    },
    // 重置规则
    resetNodes() {
      this.destoryLine();
      this.nodes = [
        {
          field: '',
          operator: '',
          value: '',
          id: 'n1',
          value_type: '',
        },
      ];
      this.updateNodes();
    },
    refreshLines: _.debounce(function () {
      this.drawLines.call(this);
    }, 10),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .node-contianer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .svg-class {
    cursor: pointer;
  }
  .left-margin {
    margin-left: 5px;
  }
  ::v-deep .el-date-editor {
    .el-input__inner {
      padding-left: 25px;
      padding-right: 0;
    }
  }
}
</style>
