<template>
  <div class="app-wrapper" :class="{ hideSidebar: !sidebar.opened }">
    <template v-if="!layoutFilter.includes($route.name)">
      <sidebar class="sidebar-container" :is-collapse="sidebar.opened"></sidebar>
      <div class="main-container">
        <navbar :hamburger-active="sidebar.opened" @toggleSideBar="toggleSideBar"></navbar>
        <section class="app-main" v-if="isLogin">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </section>
      </div>
    </template>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Navbar';
import config from '@/config';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'Layout',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      sidebar: {
        opened: true,
      },
      layoutFilter: config.layoutFilter,
    };
  },
  computed: {
    ...mapGetters('user', ['isLogin']),
  },
  created() {},
  mounted() {
    this.$watch(
      () => this.$route,
      (toRoute) => {
        // 进入新元数据的详情页（三级菜单）,左侧主菜单收起
        if (toRoute.path.indexOf('new-metadata') !== -1) {
          if (toRoute.matched.length > 2 && toRoute.name !== 'metadata-details') {
            this.sidebar.opened = false;
          } else {
            this.sidebar.opened = true;
          }
        }
      },
    );
  },
  methods: {
    ...mapMutations({
      setUserInfo: 'user/setUserInfo',
      setTenantInfo: 'user/setTenantInfo',
    }),
    toggleSideBar(opened) {
      this.sidebar = Object.assign(
        {},
        {
          opened,
        },
      );
    },
  },
};
</script>

<style lang="scss">
.app-wrapper {
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
}
</style>
