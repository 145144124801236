<template>
  <el-dialog
    :title="title || '布局json差异对比'"
    :visible.sync="visible"
    width="1100px"
    :close-on-click-modal="false"
  >
    <code-diff
      v-if="oldString !== newString"
      :old-string="oldString"
      :new-string="newString"
      :context="10"
      output-format="side-by-side"
    />
    <div v-else>无差异</div>
  </el-dialog>
</template>

<script>
import CodeDiff from 'vue-code-diff';
export default {
  components: {
    CodeDiff,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    oldString: {
      type: String,
      default: '',
    },
    newString: {
      type: String,
      default: '',
    },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      },
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    max-height: 800px;
    overflow-y: auto;
  }
  .d2h-diff-tbody {
    line-height: 14px;
    tr:nth-child(1) {
      display: none;
    }
  }
}
</style>
