<template>
  <el-card class="app-container">
    <div class="top">
      <el-form :inline="true" ref="queryForm" class="search-form" :model="queryParams">
        <el-form-item>
          <el-input
            v-model="queryParams.keyword"
            placeholder="关键字搜寻"
            class="form-item"
            @keyup.enter.native="applyKeywordFilter"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="queryParams.object"
            placeholder="对象名精确查找"
            class="form-item"
            @keyup.enter.native="applyKeywordFilter"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="applyKeywordFilter">
            搜索
          </el-button>
          <el-button icon="el-icon-refresh-right" type="info" @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="float-right">
        <el-button v-if="jurisdiction.metaManage" type="primary" @click="addMetadata">
          新建对象
        </el-button>
      </div>
    </div>
    <!-- table表 -->
    <el-table
      v-loading="loading"
      :data="list"
      border
      fit
      highlight-current-row
      class="component-table"
    >
      <!-- <el-table-column prop="tenant_id" label="租户ID" align="center" /> -->
      <!-- <el-table-column prop="" label="对象ID" align="center">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.id">
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column> -->
      <el-table-column prop="name" label="表名" align="center" />
      <el-table-column prop="display_name" label="中文名" align="center" />
      <el-table-column prop="" label="定义类型" align="center">
        <template slot-scope="scope">
          <code>{{ DefineType[scope.row.define_type] }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="package" label="包名" align="center" />
      <el-table-column prop="description" label="备注描述" align="center">
        <template slot-scope="scope">
          <code>{{ scope.row.description }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本" align="center" />
      <el-table-column prop="" label="是否启用" align="center">
        <template slot-scope="scope">
          <el-switch
            :disabled="!jurisdiction.metaManage || checkSwitchOLAP(scope.row)"
            :value="scope.row.is_active"
            active-color="#0077ff"
            inactive-color="#eeeeee"
            @change="handleChangeStatus($event, scope.row)"
          />
          <span :style="{ 'margin-left': '8px' }">
            {{ scope.row.is_active ? '启用' : '禁用' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="" fixed="right" label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="gotoFieldList(scope.row)">详情</el-button>
          <el-button
            v-if="jurisdiction.metaManage && getCurrentEnv !== 'Prod'"
            type="text"
            size="small"
            @click="itemDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination
        :current-page="queryParams.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 新增抽屉 -->
    <object-drawer
      :title="'新建对象'"
      :drawer-visible="drawerVisible"
      :form-data="formData"
      @handleConfirm="submitForm"
      @handleClose="handleClose"
      :is-edit="false"
      ref="addDrawer"
    ></object-drawer>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { DEFINE_TYPE, DATA_ENGINE } from '@/utils/constant';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import objectDrawer from './components/object-drawer/index.vue';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const ObjectDefaults = {
  id: '',
  tenant_id: '',
  name: '',
  display_name: '',
  description: '',
  define_type: 'PACKAGE',
  enable_lock: false,
  version: 0,
  is_active: true,
  package: '',
  create_by: '',
  create_time: '',
  update_by: '',
  update_time: '',
  access_type: 1, // 访问控制
  appids: null, // 相关应用
  data_engine: 'PG', // 存储类型
};
const copy = (o) => JSON.parse(JSON.stringify(o));

export default {
  name: 'ObjectList',
  components: {
    objectDrawer,
  },
  data() {
    return {
      DATA_ENGINE,
      DefineType: DEFINE_TYPE,
      drawerVisible: false,
      queryParams: {
        page: this.$route.query.page ? Number(this.$route.query.page) : 1,
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 10,
        keyword: this.$route.query.keyword || '',
        object: this.$route.query.object || '',
        sort: 'create_time desc',
      },
      loading: true,
      list: [],
      formData: copy(ObjectDefaults),
      total: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList', 'getTenantInfo', 'getCurrentEnv']),
    jurisdiction() {
      return {
        metaManage: this.getElementList.includes('meta-manage'),
      };
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取元数据列表
    async getList() {
      this.loading = true;
      const { page, limit, keyword, sort, object } = this.queryParams;
      const offset = (page - 1) * limit;
      await this.$request({
        url: this.$api.metaGetObjectList,
        data: {
          offset,
          limit,
          keyword,
          sort,
          object_name: object,
        },
      })
        .then((res) => {
          this.list = res.objects;
          this.total = parseInt(res.count, 10);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 搜索
    applyKeywordFilter() {
      this.queryParams.page = 1;
      this.updateUrl();
    },
    updateUrl() {
      setTimeout(() => {
        const { page, limit, keyword, object } = this.queryParams;
        this.$router.push({
          query: {
            page: page !== 1 ? page : undefined,
            limit: limit !== 10 ? limit : undefined,
            keyword: keyword || undefined,
            object: object || undefined,
          },
        });
        this.getList();
      }, 300);
    },
    // 搜索重置
    resetForm() {
      this.queryParams.keyword = '';
      this.queryParams.object = '';
      this.getList();
    },
    // 翻页
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.updateUrl();
    },
    // 一页显示条数修改
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.updateUrl();
    },
    // 跳转详情页
    gotoFieldList({ id, name }) {
      // 元数据名和id存入本地，为后续面包屑导航和页面获取id
      sessionStorage.setItem('object_name', name);
      this.$router.push({
        path: `overview`,
        query: {
          id,
          name,
        },
      });
    },
    // 新增对象
    addMetadata() {
      this.formData = copy(ObjectDefaults);
      this.formData.define_type =
        this.getTenantInfo.tenant_type === 'extend' ? 'CUSTOM' : 'PACKAGE';
      this.drawerVisible = true;
    },
    async deleteMeatdata({ id }) {
      await this.$confirm('删除该分组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await this.$request({
        url: this.$api.metaDeleteObject,
        data: { id },
      });
      this.$message.success('删除成功');
      this.getList();
    },
    // 新建对象 - 确认
    async submitForm() {
      console.log(this.formData);
      if (this.formData.data_engine === DATA_ENGINE.OLAP) {
        this.formData.ck_option = {};
        this.formData.is_active = false;
      }
      await this.$request({
        url: this.$api.metaAddObject,
        data: {
          object: {
            ...this.formData,
            name:
              this.formData.define_type === 'CUSTOM'
                ? `${this.formData.name}__c`
                : this.formData.name,
          },
        },
      })
        .then((res) => {
          console.log(res);
          this.drawerVisible = false;
          this.gotoFieldList(res.object);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 新建对象 - 取消
    async handleClose() {
      this.drawerVisible = false;
      this.$refs.addDrawer.$refs.form.resetFields();
    },
    async itemDelete({ id }) {
      await this.$confirm('删除该分组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await this.$request({
        url: this.$api.metaDeleteObject,
        data: { id },
        cb: () => {
          this.getList();
        },
      });
      this.$message.success('删除成功');
    },
    handleChangeStatus(value, item) {
      let tip = '';
      if (value) {
        tip = `是否启用${item.display_name}的元数据配置`;
      } else {
        tip =
          '元数据禁用后，相关数据的存取，关联关系，以及布局访问等，可能会出现问题，且当前元数据将不允许继续添加/修改任何配置';
      }
      this.$confirm(tip, `请确认是否${value ? '启用' : '禁用'}${item.display_name}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          console.log('sss');
          await this.$request({
            url: this.$api.metaUpdateObject,
            data: {
              id: item.id,
              object: {
                ...item,
                is_active: value,
              },
            },
          })
            .then(() => {
              this.getList();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    checkSwitchOLAP(row) {
      // 如果存储类型为olap 则初始化完成前无法切换启用状态
      return row.data_engine === DATA_ENGINE.OLAP && !row.ck_option?.inited;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/pagination.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/table.scss';
@import '@/styles/element/form.scss';
.app-container ::v-deep .el-card__body {
  padding: 0px !important;
}
.top {
  display: flex;
  .search-form {
    flex: 1;
    display: flex;
    .form-item {
      width: 232px;
      margin-right: 8px;
    }
  }
}
</style>
