<template>
  <el-dialog title="设置日志保留时长" :visible="visible" width="600px" @close="close">
    <el-form :model="formValue" label-width="110px" ref="timeForm">
      <el-form-item
        label="保留时长(天)"
        prop="days"
        :rules="[{ required: true, message: '请输入数字', trigger: 'blur' }]"
      >
        <el-input
          v-model="formValue.days"
          placeholder="请输入数字"
          @input="
            () => {
              formValue.days = formValue.days.replace(/[^0-9]/g, '');
            }
          "
        />
      </el-form-item>
    </el-form>
    <div class="tip-bar">
      <div class="head-intro">
        <i class="el-icon-warning icon-color" />
        <span>填写说明</span>
      </div>
      <div class="text-intro">1. 仅支持输入数字</div>
      <div class="text-intro">2. 保存成功后系统将按照设置时长自动清理日志</div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    logTimeLine: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      formValue: {
        days: 30,
      },
    };
  },
  computed: {},
  watch: {
    logTimeLine(val) {
      this.formValue.days = val;
    },
    visible(val) {
      if (val) {
        this.formValue.days = this.logTimeLine;
      }
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$refs.timeForm.clearValidate();
      this.$emit('close');
    },
    submit() {
      this.$refs.timeForm.validate(async (valid) => {
        if (!valid) return;
        try {
          this.$emit('setProcessLogConfigure', this.formValue.days);
          this.close();
        } catch (err) {
          console.warn('setTimeErr', err);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';
@import '@/styles/element/input.scss';
.tip-bar {
  width: 100%;
  padding: 18px 25px;
  background: #f2f3f8;
  border-radius: 3px;
  .head-intro {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .icon-color {
      color: #0077ff;
      font-size: 18px;
    }
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #384050;
    }
  }
  .text-intro {
    padding-left: 20px;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    color: #667082;
  }
}
</style>
