<template>
  <div class="filter">
    <div class="item">
      <div class="connector">
        <el-select v-model="data.connector" placeholder="请选择">
          <el-option v-for="(type, key) in CONNECTOR_TYPES" :key="key" :label="type" :value="key" />
        </el-select>
      </div>
      <el-button class="add-condition" type="text" @click.stop="addCondition(data.conditions)">
        添加组
      </el-button>
      <div class="content">
        <div class="line" v-for="(item, index) in data.conditions" :key="index">
          <!-- 组 -->
          <data-filter
            v-if="item.expression"
            :fields="fields"
            v-model="item.expression"
            :rules="rule"
            :roles="ROLE_LIST"
            :posts="POST_LIST"
            :external-param="externalParam"
          />
          <!-- 单条 -->
          <condition
            v-else
            :value="item"
            @change="
              (val) => {
                conditionChange(val, index);
              }
            "
            :fields="fields"
            :rules="rule"
            :roles="ROLE_LIST"
            :posts="POST_LIST"
            :external-param="externalParam"
          />
          <el-button class="del" type="text" @click.stop="delItem(index)">删除</el-button>
        </div>
      </div>
      <el-button type="text" @click.stop="addItem(value.conditions)">
        <i class="el-icon-plus"></i>
        单条添加
      </el-button>
    </div>
  </div>
</template>

<script>
import { CONNECTOR_TYPES } from '@/utils/constant';
import condition from './condition.vue';
export default {
  name: 'DataFilter',
  components: {
    condition,
  },
  props: {
    rules: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    appid: {
      type: Number,
      default: 0,
    },
    roles: {
      type: [Array, null],
      default: () => {
        return null;
      },
    },
    posts: {
      type: [Array, null],
      default: () => {
        return null;
      },
    },
    externalParam: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONNECTOR_TYPES,
      flag: true,
      rule: false,
      ROLE_LIST: [], // 角色选项
      POST_LIST: [], // 岗位选项
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.save(value);
      },
    },
  },
  watch: {
    appid() {
      this.getRoleOptions();
      this.getPostOptions();
    },
  },
  created() {
    this.getRoleOptions();
    this.getPostOptions();
  },
  methods: {
    // 必填验证
    validate(cb) {
      //   this.rule = true; // 红框显示
      this.flag = true;
      this.mapMoListArr(this.value);
      if (!this.flag) {
        this.$message({
          message: '请完整填写条件信息',
          type: 'warning',
        });
      }
      cb(this.flag);
      return this.flag;
    },
    mapMoListArr({ conditions }) {
      for (const key in conditions) {
        const item = conditions[key];
        if (conditions[key].expression) {
          // 数组
          this.mapMoListArr(conditions[key].expression);
        } else {
          // 单条数据
          if (['EXIST', 'NOT_EXIST']) {
            if ([item.operation, item.value.type, item.value.value].includes('')) {
              this.flag = false;
              return false;
            }
          } else {
            if ([item.field, item.operation, item.value.type, item.value.value].includes('')) {
              this.flag = false;
              return false;
            }
          }
        }
      }
    },
    save(data) {
      this.$emit('input', data);
    },
    addCondition() {
      this.data = {
        ...this.data,
        conditions: [
          ...this.data.conditions,
          {
            expression: {
              connector: 'AND',
              conditions: [],
            },
          },
        ],
      };
    },
    addItem() {
      this.data = {
        ...this.data,
        conditions: [
          ...this.data.conditions,
          {
            field: '',
            operation: '',
            value: {
              type: '',
              value: '',
            },
          },
        ],
      };
    },
    delItem(number) {
      this.data = {
        ...this.data,
        conditions: this.data.conditions.filter((item, index) => {
          return number !== index;
        }),
      };
      // console.log(this.data.conditions[number]);
    },
    conditionChange(val, index) {
      const newdata = JSON.parse(JSON.stringify(this.data));
      newdata.conditions[index] = val;
      this.data = newdata;
    },
    // ---获取选项---
    // 角色
    async getRoleOptions() {
      if (this.roles) {
        this.ROLE_LIST = this.roles;
        return;
      }
      try {
        const { data } = await this.$request({
          url: this.$api.getRoleList,
          data: {
            appid: this.appid,
            page_size: 99999,
          },
        });
        this.ROLE_LIST = data;
      } catch (err) {
        console.log(err);
      }
    },
    // 岗位
    async getPostOptions() {
      if (this.posts) {
        this.POST_LIST = this.posts;
        return;
      }
      try {
        const { data } = await this.$request({
          url: this.$api.getJobList,
          data: {
            appid: this.appid,
            page_size: 99999,
          },
        });
        this.POST_LIST = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin: 6px;
  overflow: auto;
  .item {
    position: relative;
    display: inline-block;
    border: 1px solid rgba(155, 150, 150, 0.356);
    margin: 15px 15px 0 0;
    padding: 15px 15px 15px 25px;
    min-width: 180px;
    // 与 或
    .connector {
      position: absolute;
      left: 5px;
      top: -25px;
      ::v-deep .el-input {
        width: 60px;
        input {
          outline: none;
          border: none;
        }
      }
    }
    // 添加组
    .add-condition {
      position: absolute;
      right: 7px;
      top: -25px;
      background-color: #fff;
      padding: 12px;
    }
    // 条件
    .content {
      .line {
        margin: 7px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .del {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
