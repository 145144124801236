<template>
  <el-card class="table-container">
    <div class="top">
      <div class="search-form">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchObj"
          class="searchForm"
          @submit.native.prevent
          @keyup.enter.native="loadListData"
        >
          <el-form-item label="选择产品/应用：" prop="appid">
            <el-select v-model="searchObj.appid" placeholder="应用" @change="loadListData">
              <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input
              v-model="searchObj.keyword"
              clearable
              placeholder="请输入名称/编码"
              @clear="loadListData"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button icon="el-icon-search" type="primary" @click="loadListData">搜索</el-button>
            <el-button @click="resetForm">重 置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="float-right">
        <el-button
          v-if="jurisdiction.orgManage"
          class="add-button"
          type="primary"
          @click="handleAdd"
        >
          添加
        </el-button>
      </div>
    </div>

    <t-enhanced-table
      ref="tTable"
      row-key="id"
      :columns="columns"
      :data="filterListData"
      :max-height="tableHeight"
      :scroll="{ type: 'virtual', bufferSize: 30 }"
      table-layout="auto"
      :tree="{ childrenKey: 'children', treeNodeColumnIndex: 0 }"
    >
      <template #treeExpandAndFoldIcon="{ type }">
        <div v-if="type === 'expand'">
          <i class="el-icon-arrow-right" />
        </div>
        <div v-if="type === 'fold'">
          <i class="el-icon-arrow-down" />
        </div>
      </template>
      <template #name="{ row }">
        <div :class="{ 'text-highlight': row.name.includes(highlightKeyword) && highlightKeyword }">
          {{ row.name }}
        </div>
      </template>
      <template #code="{ row }">
        <div :class="{ 'text-highlight': row.code.includes(highlightKeyword) && highlightKeyword }">
          {{ row.code }}
        </div>
      </template>
      <template v-if="jurisdiction.orgManage" #operation="{ row }">
        <el-button size="mini" type="text" @click="handleEdit(row)">编辑</el-button>
        <el-button
          size="mini"
          type="text"
          :disabled="Boolean(row.children && row.children.length)"
          @click="handleDelete(row)"
        >
          删除
        </el-button>
      </template>
    </t-enhanced-table>
    <editDialog
      :visible="editVisible"
      :row-id="editRow"
      :appid="searchObj.appid"
      @success="editSuccess"
      @close="editVisible = false"
    />
    <MessageBox ref="messageBox" />
  </el-card>
</template>

<script>
import editDialog from './edit';
import MessageBox from '@/components/messageBox';
import { mapGetters } from 'vuex';

export default {
  components: { editDialog, MessageBox },
  data() {
    return {
      listData: [],
      filterListData: [],
      appOptions: [],
      oldSearchObj: {},
      searchObj: {
        appid: Number(this.$route.query.appid) || 0,
        keyword: '',
      },
      highlightKeyword: '',
      editRow: '',
      editVisible: false,
      tableHeight: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        orgManage: this.getElementList.includes('org-manage'),
      };
    },
    columns() {
      const list = [
        {
          colKey: 'name',
          title: '名称',
          minWidth: 240,
          fixed: 'left',
        },
        {
          colKey: 'code',
          title: 'Code',
          minWidth: 140,
        },
        {
          colKey: 'parent_code',
          title: '直属上级',
          minWidth: 140,
        },
        {
          colKey: 'description',
          title: '备注',
        },
        {
          colKey: 'create_by',
          title: '创建人',
        },
        {
          colKey: 'update_by',
          title: '最后修改人',
        },
        {
          colKey: 'update_time',
          title: '修改时间',
        },
        {
          colKey: 'create_time',
          title: '创建时间',
        },
      ];

      if (this.jurisdiction.orgManage) {
        list.push({
          colKey: 'operation',
          title: '操作',
          minWidth: 100,
        });
      }

      return list;
    },
  },
  async mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    await this.loadAppOption();
    this.loadListData(true);
    const totalHeight = document.body.clientHeight;
    const formHeight = document.querySelector('.top').clientHeight;
    const navBarHeight = document.querySelector('.navbar').clientHeight;
    this.tableHeight = `${totalHeight - formHeight - navBarHeight - 60}px`;
    console.log('---tableHeight---', this.tableHeight);
  },
  methods: {
    async loadListData(refresh = false) {
      if (refresh) {
        this.listData = [];
        this.filterListData = [];
      }
      console.log('-keyword-', this.searchObj.keyword, refresh);
      this.highlightKeyword = this.searchObj.keyword;
      if (refresh || this.searchObj.appid !== this.oldSearchObj.appid) {
        const url = this.$api.getOrganizationTree;
        const data = {
          appid: this.searchObj.appid,
        };
        const resp = await this.$request({ url, data });
        this.oldSearchObj = {
          ...this.searchObj,
        };
        this.listData = resp.data;
        this.filterListData = this.filterList(this.listData, this.searchObj);
      }
      if (this.searchObj.keyword !== this.oldSearchObj.keyword) {
        this.filterListData = this.filterList(this.listData, this.searchObj);
        console.log('---this.filterListData---', this.filterListData);
        this.oldSearchObj = {
          ...this.searchObj,
        };
      }
    },
    resetForm() {
      this.searchObj = {
        appid: this.appOptions[0]?.id || 0,
        keyword: this.$route.query.keyword || '',
      };
      this.loadListData();
    },
    filterList(tree, { keyword = '' }) {
      return tree.filter((item) => {
        const condition = [item.name, item.code].find((val) => {
          return val.includes(keyword);
        });
        // 显示状态
        if (condition) {
          return true;
        }
        if (item.children && item.children.length > 0) {
          return this.filterList(item.children, { keyword }).length > 0;
        }
        return false;
      });
    },
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
        this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
      };
      this.$request({ url, data, cb });
    },
    handleAdd() {
      this.editRow = '';
      this.editVisible = true;
    },
    handleEdit({ id }) {
      this.editRow = id;
      this.editVisible = true;
    },
    handleDelete(row) {
      this.$refs.messageBox.show({
        title: `确定要删除${row.name}吗？`,
        content: `删除后，${row.name}将失效`,
        titleIconType: 'warning',
        confirmText: '确认删除',
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.deleteOrganizationInfo,
            data: { id: row.id },
          }).then(() => {
            this.$message.success('删除成功');
            this.$refs.messageBox.close();
            this.loadListData(true);
          });
        },
      });
    },
    editSuccess() {
      this.editVisible = false;
      this.loadListData(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';

.top {
  display: flex;
  .search-form {
    flex: 1;
    display: flex;
    .form-item {
      width: 232px;
      margin-right: 8px;
    }
  }
}
::v-deep .t-table__header tr {
  th {
    background-color: rgba(246, 247, 251, 1);
    color: #667082;
    font-weight: 400;
  }

  border-bottom: 1px solid #ebeef5;
}

.text-highlight {
  color: #0077ff;
}

::v-deep .t-table__body tr {
  background-color: #fff;
  border-bottom: 1px solid #ebeef5;
}
</style>
