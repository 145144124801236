<template>
  <div class="node-container">
    <!-- 发起人节点 -->
    <template v-if="value.type === 'start_node'">
      <start-node :value="value" :info="info" @update="valueUpdate">
        <template slot="inputs">
          <el-button type="info" class="primary" @click="variableOpen('inputs')">
            输入变量
          </el-button>
        </template>
      </start-node>
    </template>
    <!-- 审批节点 -->
    <template v-else-if="value.type === 'user'">
      <div class="intro-title">基础信息</div>
      <div class="node-title">节点ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">节点名称</div>
      <el-input
        :value="value.fullname"
        maxlength="20"
        show-word-limit
        @input="(e) => valueUpdate('fullname', e)"
      />
      <div class="node-title">变量配置</div>
      <el-button type="info" class="primary" @click="variableOpen('inputs')">输入变量</el-button>
      <el-button type="info" class="primary" @click="variableOpen('outputs')">输出变量</el-button>
      <div class="node-title">备注描述</div>
      <el-input
        type="textarea"
        :rows="2"
        :value="value.description"
        @input="(e) => valueUpdate('description', e)"
        maxlength="50"
        autocomplete="off"
        placeholder="请输入"
      ></el-input>
      <div class="intro-title">其他信息</div>
      <div class="node-title">
        业务选择
        <span class="require">*</span>
      </div>
      <el-radio :value="value.subref" :label="'approval'" @input="bizTypeChange">审批业务</el-radio>
      <!-- <el-radio :value="value.subref" :label="'prompt'" @input="bizTypeChange">非审批业务</el-radio> -->
      <div class="node-title">
        {{ value.subref === 'prompt' ? '选择处理人' : '选择审批人' }}
        <span class="require">*</span>
      </div>
      <template v-if="value.subref !== 'prompt'">
        <div class="leader-type-radio-bar">
          <el-radio v-model="approvalSelect" label="current" @input="approvalChange">
            发起人
          </el-radio>
          <el-select
            :value="computedCurrentLeaderType"
            @change="approvalChange"
            style="width: 65%"
            :disabled="approvalSelect !== 'current'"
          >
            <el-option
              v-for="(item, index) in [
                { value: 'currentLeader', label: '岗位上级' },
                { value: 'currentUserInfoLeader', label: '用户上级(user-info)' },
                { value: 'currentEmpOrgLeader', label: '员工组织上级(emp-org)' },
              ]"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="leader-type-radio-bar">
          <el-radio v-model="approvalSelect" label="pre" @input="approvalChange">
            上一审批节点
          </el-radio>
          <el-select
            :value="computedPreLeaderType"
            @change="approvalChange"
            style="width: 65%"
            :disabled="approvalSelect !== 'pre'"
          >
            <el-option
              v-for="(item, index) in [
                { value: 'preLeader', label: '岗位上级' },
                { value: 'preUserInfoLeader', label: '用户上级(user-info)' },
                { value: 'preEmpOrgLeader', label: '员工组织上级(emp-org)' },
              ]"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </template>

      <el-radio
        v-if="value.subref === 'prompt'"
        style="margin-bottom: 16px"
        label="preLeader"
        v-model="approvalSelect"
        @input="approvalChange"
      >
        上一用户节点岗位上级
      </el-radio>

      <div class="leader-type-radio-bar">
        <el-radio label="candidate" v-model="approvalSelect" @input="approvalChange">
          {{ value.subref === 'prompt' ? '选择用户' : '指定审批人' }}
        </el-radio>
        <el-select
          style="width: 65%"
          :value="value.metadata.operator_value.candidate_user"
          :disabled="value.metadata.operator_type !== 'candidate'"
          multiple
          filterable
          remote
          placeholder="指定成员数量不超过20人"
          :remote-method="getUserList"
          :multiple-limit="20"
          @change="candidateUserChange"
        >
          <CheckboxOption
            v-for="item in nowUserList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :selected="value.metadata.operator_value.candidate_user"
          />
        </el-select>
      </div>

      <div class="node-radio-list">
        <el-radio label="attribute" v-model="approvalSelect" @input="approvalChange">
          {{ value.subref === 'prompt' ? '通过用户属性匹配' : '通过审批人属性匹配' }}
        </el-radio>
        <div class="node-checkbox-group" v-if="value.metadata.operator_type === 'attribute'">
          <div class="node-attribute-row" v-for="item in USER_PROPERTITY" :key="item.key">
            <el-checkbox
              :value="value.metadata.operator_value.attribute_keys.includes(item.key)"
              @change="(e) => changeAttributeKeys(item.key, e)"
            >
              {{ item.label }}
            </el-checkbox>
            <el-select
              class="node-attribute-select"
              :disabled="!value.metadata.operator_value.attribute_keys.includes(item.key)"
              size="small"
              :value="value.metadata.operator_value.attribute_value_map[item.key]"
              placeholder="请选择"
              multiple
              @change="(list) => handleApprovalUserSelectedAttrubiesChange(list, item.key)"
            >
              <CheckboxOption
                v-for="attribute in userAttributeSelectInfo[item.key]"
                :key="`${item.key}${attribute.value}`"
                :label="attribute.label"
                :value="attribute.value"
                :selected="value.metadata.operator_value.attribute_value_map[item.key]"
              />
            </el-select>
          </div>
        </div>
      </div>

      <div class="leader-type-radio-bar">
        <el-radio label="custom_condition" v-model="approvalSelect" @input="approvalChange">
          自定义条件
        </el-radio>

        <el-select
          style="width: 65%"
          :value="value.metadata.operator_value.custom_condition"
          :disabled="value.metadata.operator_type !== 'custom_condition'"
          multiple
          filterable
          placeholder="请选择标签"
          @change="customConditionChange"
        >
          <CheckboxOption
            v-for="item in coustomConditionTags"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :selected="value.metadata.operator_value.custom_condition"
          />
        </el-select>
      </div>

      <div
        class="node-selected-row"
        v-if="approvalSelect === 'attribute'"
        v-loading="attributesLoading"
      >
        <div class="node-selected-title">已选审批人</div>
        <el-select
          :value="value.metadata.operator_value.attribute_checked_user"
          multiple
          placeholder="请选择"
          :disabled="!value.metadata.operator_value.attribute_keys.length"
          @change="(e) => valueUpdate('metadata.operator_value.attribute_checked_user', e)"
        >
          <CheckboxOption
            v-for="(item, index) in userListByTag"
            :key="index"
            :label="item.label"
            :value="item.value"
            :selected="value.metadata.operator_value.attribute_checked_user"
          />
        </el-select>
      </div>

      <div
        v-if="
          value.metadata.operator_type === 'leader' ||
          value.metadata.operator_type === 'custom_condition'
        "
      >
        <div class="node-condition-row">
          <div class="condition">
            {{
              value.subref === 'prompt' ? '当节点无法匹配到用户时,' : '当节点无法匹配到审批人时,'
            }}
          </div>
          <el-select :value="computedFindStrategy" @change="changeFindStrategy">
            <el-option
              v-for="(item, index) in computedFindStrategyOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div v-if="computedFindStrategy === 'previous'">
          <div class="node-title">
            递归向上查找
            <el-tooltip
              effect="dark"
              content="当上级岗位仍然为空时，系统会继续沿岗位架构树继续向上查找，直到找到有效的审批人为止"
              placement="top"
            >
              <i class="iconfont icon-question" />
            </el-tooltip>
          </div>
          <el-switch
            :value="value.metadata.operator_value.recursive_find_leader_enable"
            @change="(e) => valueUpdate('metadata.operator_value.recursive_find_leader_enable', e)"
          ></el-switch>
        </div>
      </div>
      <div v-if="multipleApprovalUser" class="node-condition-row">
        <div class="condition">当节点匹配到多个审批人时,</div>
        <el-select :value="findSignType" @change="changeSignType">
          <el-option
            v-for="(item, index) in attributesApprovalTypes"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="node-title" v-if="value.subref !== 'prompt'">审批提示信息</div>
      <el-input
        :value="value.metadata.prompt"
        @input="(e) => valueUpdate('metadata.prompt', e)"
        :rows="2"
        type="textarea"
        maxlength="300"
        class="textarea-input"
        v-if="value.subref !== 'prompt'"
      ></el-input>
      <div class="node-split" v-if="value.subref !== 'prompt'" />
      <div v-if="value.subref !== 'prompt'">
        <div class="node-title">
          定时自动处理
          <el-tooltip
            effect="dark"
            content="在设定的时间限期内，审批人仍未处理时，系统自动同意/拒绝的设置"
            placement="top"
          >
            <i class="iconfont icon-question" />
          </el-tooltip>
        </div>
        <el-switch
          class="node-row"
          :value="value.timed_config.enabled"
          @change="(e) => valueUpdate('timed_config.enabled', e)"
          :active-text="value.timed_config.enabled ? '开启定时处理' : '关闭定时处理'"
        ></el-switch>
        <template v-if="value.timed_config.enabled">
          <div class="node-form-row">
            <div class="node-form-title">请指定天数</div>
            <el-input-number
              :value="value.timed_config.duration"
              @change="(e) => valueUpdate('timed_config.duration', e)"
              :min="1"
            ></el-input-number>
            <div style="margin-left: 16px">天</div>
          </div>
          <div class="node-form-row">
            <div class="node-form-title">系统自动处理</div>
            <el-radio
              :value="value.timed_config.handle"
              :label="1"
              @input="(e) => valueUpdate('timed_config.handle', e)"
            >
              同意
            </el-radio>
            <el-radio
              :value="value.timed_config.handle"
              :label="2"
              @input="(e) => valueUpdate('timed_config.handle', e)"
            >
              拒绝
            </el-radio>
          </div>
        </template>
        <div class="node-title">退回节点</div>
        <el-switch
          :value="value.metadata.rollback.enable"
          @change="
            (e) => {
              valueUpdate('metadata.rollback.enable', e);
              valueUpdate('metadata.rollback.rollback_type', e ? 3 : 0);
            }
          "
        ></el-switch>
        <div style="margin-top: 12px">
          <el-radio
            v-if="value.metadata.rollback.enable"
            :value="value.metadata.rollback.rollback_type"
            :label="1"
            :disabled="getGatewayInclusiveState"
            @input="
              (e) => {
                valueUpdate('metadata.rollback.rollback_type', e);
                valueUpdate('metadata.rollback.skip', 0);
              }
            "
          >
            上一节点
          </el-radio>
          <el-radio
            v-if="value.metadata.rollback.enable"
            :value="value.metadata.rollback.rollback_type"
            :label="2"
            :disabled="getGatewayInclusiveState"
            @input="
              (e) => {
                valueUpdate('metadata.rollback.rollback_type', e);
                valueUpdate('metadata.rollback.skip', 0);
              }
            "
          >
            指定节点
          </el-radio>
          <el-radio
            v-if="value.metadata.rollback.enable"
            :value="value.metadata.rollback.rollback_type"
            :label="3"
            @input="
              (e) => {
                valueUpdate('metadata.rollback.rollback_type', e);
                valueUpdate('metadata.rollback.skip', 0);
              }
            "
          >
            退回到申请人
          </el-radio>
          <el-select
            :value="value.metadata.rollback.skip"
            @change="
              (e) => {
                valueUpdate('metadata.rollback.skip', e);
              }
            "
            style="margin-top: 10px"
            v-if="
              value.metadata.rollback.rollback_type === 2 ||
              value.metadata.rollback.rollback_type === 3
            "
          >
            <el-option
              v-for="item in resubmitPolicy"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </template>
    <!-- 结束节点 -->
    <template v-else-if="value.type === 'end_node'">
      <div class="node-title">节点ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">节点名称</div>
      <el-input
        :value="value.fullname"
        maxlength="20"
        show-word-limit
        @input="(e) => valueUpdate('fullname', e)"
      />
      <div class="node-title">变量配置</div>
      <el-button type="info" class="primary" @click="variableOpen('outputs')">输出变量</el-button>
      <div class="node-title">备注描述</div>
      <el-input
        type="textarea"
        :rows="2"
        :value="value.description"
        @input="(e) => valueUpdate('description', e)"
        maxlength="50"
        autocomplete="off"
        placeholder="请输入"
      ></el-input>
    </template>
    <!-- 排他网关节点 -->
    <template v-else-if="value.type === 'gateway'">
      <gateway-node :value="value" @update="valueUpdate" />
    </template>
    <!-- 包容网关节点 -->
    <template v-else-if="value.type === 'gateway_inclusive'">
      <inclusive-gateway-node :value="value" @update="valueUpdate" />
    </template>
    <!-- 云函数节点 -->
    <template v-else-if="value.type === 'function'">
      <div class="node-title">节点ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">节点名称</div>
      <el-input
        :value="value.fullname"
        maxlength="20"
        show-word-limit
        @input="(e) => valueUpdate('fullname', e)"
      />
      <div class="node-title">变量配置</div>
      <el-button type="info" class="primary" @click="variableOpen('inputs')">输入变量</el-button>
      <el-button type="info" class="primary" @click="variableOpen('outputs')">输出变量</el-button>
      <div class="node-split" />
      <div class="node-selected-row">
        <div class="node-selected-title">选择自定义云函数</div>
        <el-select
          :value="value.metadata.name"
          placeholder="请选择"
          @change="(e) => valueUpdate('metadata.name', e)"
          filterable
          remote
          :remote-method="getFunctionList"
        >
          <el-option
            v-for="item in nowFunctionList"
            :key="item.func_name"
            :label="item.func_name.slice(0, 40)"
            :value="item.func_name"
          />
        </el-select>
      </div>
      <div class="node-title">备注描述</div>
      <el-input
        type="textarea"
        :rows="2"
        :value="value.description"
        @input="(e) => valueUpdate('description', e)"
        maxlength="50"
        autocomplete="off"
        placeholder="请输入"
      ></el-input>
    </template>
    <!-- 等待节点 -->
    <template v-else-if="value.type === 'wait'">
      <function-wait-node :value="value" @update="valueUpdate">
        <template slot="inputs">
          <el-button type="info" class="primary" @click="variableOpen('inputs')">
            输入变量
          </el-button>
        </template>
        <template slot="selectFunction">
          <el-select
            :value="value.metadata.faas_name"
            placeholder="请选择"
            @change="(e) => valueUpdate('metadata.faas_name', e)"
            filterable
            remote
            :remote-method="getFunctionList"
          >
            <el-option
              v-for="item in nowFunctionList"
              :key="item.func_name"
              :label="item.func_name.slice(0, 40)"
              :value="item.func_name"
            />
          </el-select>
        </template>
      </function-wait-node>
    </template>
    <template v-else-if="value.type === 'doa'">
      <doa-approval-node :value="value" @update="valueUpdate">
        <template slot="inputs">
          <el-button type="info" class="primary" @click="variableOpen('inputs')">
            输入变量
          </el-button>
        </template>
        <template slot="outputs">
          <el-button type="info" class="primary" @click="variableOpen('outputs')">
            输出变量
          </el-button>
        </template>
        <template slot="selectFunction">
          <el-select
            :value="value.metadata.faas_name"
            placeholder="请选择"
            @change="(e) => valueUpdate('metadata.faas_name', e)"
            filterable
            remote
            :remote-method="getFunctionList"
          >
            <el-option
              v-for="item in nowFunctionList"
              :key="item.func_name"
              :label="item.func_name.slice(0, 40)"
              :value="item.func_name"
            />
          </el-select>
        </template>
      </doa-approval-node>
    </template>
    <!-- 定时边界事件 -->
    <template v-else-if="value.type === 'time_boundary_event'">
      <div class="node-title">事件ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">节点名称</div>
      <el-input
        :value="value.fullname"
        maxlength="20"
        show-word-limit
        @input="(e) => valueUpdate('fullname', e)"
      />
      <div class="node-title">变量配置</div>
      <el-button type="info" class="primary" @click="variableOpen('inputs')">输入变量</el-button>
      <br />
      <el-button
        type="info"
        class="primary"
        @click="variableOpen('outputs')"
        style="margin-top: 8px"
      >
        输出变量
      </el-button>
      <div class="node-title">
        循环时间
        <el-tooltip effect="dark" content="示例：@every 5m 每5分钟执行一次" placement="top">
          <i class="iconfont icon-question" />
        </el-tooltip>
      </div>
      <el-input
        :value="value.events_value.loop_time"
        @input="(e) => valueUpdate('events_value.loop_time', e)"
        placeholder="请输入"
      />
      <div class="node-title">选择自定义云函数</div>
      <el-select
        :value="value.events_value.cloud_fun_name"
        placeholder="请选择"
        @change="(e) => valueUpdate('events_value.cloud_fun_name', e)"
        filterable
        remote
        :remote-method="getFunctionList"
      >
        <el-option
          v-for="item in nowFunctionList"
          :key="item.func_name"
          :label="item.func_name.slice(0, 40)"
          :value="item.func_name"
        />
      </el-select>
      <div class="node-title">备注描述</div>
      <el-input
        type="textarea"
        :rows="2"
        :value="value.description"
        @input="(e) => valueUpdate('description', e)"
        maxlength="50"
        autocomplete="off"
        placeholder="请输入"
      ></el-input>
    </template>
    <!-- 延时设置节点 -->
    <template v-else-if="value.type === 'delay'">
      <delay-setting-node :value="value" @update="valueUpdate"></delay-setting-node>
    </template>
    <!-- 公众号模版消息节点 -->
    <template v-else-if="value.type === 'function_wxgzh'">
      <offical-acount-node :value="value" @update="valueUpdate"></offical-acount-node>
    </template>
    <!-- 站内信消息节点 -->
    <template v-else-if="value.type === 'function_lclmsg'">
      <inmail-node :value="value" @update="valueUpdate"></inmail-node>
    </template>
    <!-- 边 -->
    <template v-else-if="value.nodeType === 'edge'">
      <div class="node-title">ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">变量配置</div>
      <el-button type="info" class="primary" @click="variableOpen('inputs')">输入变量</el-button>
      <el-button type="info" class="primary" @click="variableOpen('outputs')">输出变量</el-button>
      <template v-if="value.source_type === 'gateway' || value.source_type === 'gateway_inclusive'">
        <div class="node-title">连线标题</div>
        <el-input
          :value="value.expr_name"
          placeholder="请输入连线标题"
          maxlength="20"
          show-word-limit
          @input="(e) => valueUpdate('expr_name', e)"
        ></el-input>
        <div class="node-title" v-if="value.source_type === 'gateway_inclusive'">退回流程操作</div>
        <div style="margin-top: 12px" v-if="value.source_type === 'gateway_inclusive'">
          <el-radio
            :value="value.metadata.backward_setting"
            :label="1"
            @input="
              (e) => {
                valueUpdate('metadata.backward_setting', e);
              }
            "
          >
            退回当前分支流程至申请人
          </el-radio>
          <el-radio
            :value="value.metadata.backward_setting"
            :label="2"
            @input="
              (e) => {
                valueUpdate('metadata.backward_setting', e);
              }
            "
          >
            退回整体流程至申请人
          </el-radio>
        </div>
        <div class="node-title" v-if="value.source_type === 'gateway_inclusive'">拒绝流程操作</div>
        <div style="margin-top: 12px" v-if="value.source_type === 'gateway_inclusive'">
          <el-radio
            :value="value.metadata.refuse_setting"
            :label="1"
            @input="
              (e) => {
                valueUpdate('metadata.refuse_setting', e);
              }
            "
          >
            终止当前分支流程
          </el-radio>
          <el-radio
            :value="value.metadata.refuse_setting"
            :label="2"
            @input="
              (e) => {
                valueUpdate('metadata.refuse_setting', e);
              }
            "
          >
            终止整体流程
          </el-radio>
        </div>
        <div class="node-title">条件配置</div>
        <el-radio
          :value="value.conditional_conig"
          :label="1"
          @input="
            (e) => {
              valueUpdate('conditional_conig', e);
            }
          "
        >
          规则配置
        </el-radio>
        <el-radio
          :value="value.conditional_conig"
          :label="2"
          @input="
            (e) => {
              valueUpdate('conditional_conig', e);
            }
          "
        >
          脚本内容
        </el-radio>
        <el-input
          v-if="value.conditional_conig === 2"
          :value="value.expr_content"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
          placeholder="请输入脚本内容"
          @input="(e) => valueUpdate('expr_content', e)"
        ></el-input>
        <div style="margin-top: 5px">
          <conditionalSelectionTreeVue
            v-if="value.conditional_conig === 1 && loaded"
            :value="value"
            ref="conditionalSelectionTree"
            @update="valueUpdate"
            :fields="objectFiledsList"
            :conditional-symbol-path="'formed_expr.conditional_symbol'"
            :conditionals-path="'formed_expr.conditionals'"
          />
        </div>
      </template>
    </template>
    <!-- 输入变量弹窗 -->
    <INPUT
      v-if="variableVisible"
      :show="variableVisible"
      :type="variableType"
      :list="variableList"
      @cancel="variableClose"
      @save="variableSave"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import INPUT from '../input.vue';
import CheckboxOption from '@/components/checkboxOption';
import { USER_PROPERTITY } from '@/utils/constant.js';
import { defaultFindStrategy, attributesApprovalTypes, resubmitPolicy } from '../../common';
import { mapGetters } from 'vuex';
import startNode from './startNode.vue';
import delaySettingNode from './delaySettingNode.vue';
import officalAcountNode from './officalAcountNode.vue';
import { defaultUserNodeFormValue } from '../../config';
import conditionalSelectionTreeVue from '../../components/conditionalSelectionTree.vue';
import inmailNode from './inmailNode.vue';
import gatewayNode from './gatewayNode.vue';
import inclusiveGatewayNode from './inclusvieGatewayNode.vue';
import functionWaitNode from './functionWaitNode.vue';
import doaApprovalNode from './doaApprovalNode.vue';
export default {
  name: 'WorkflowDetailConfigNode',
  components: {
    INPUT,
    CheckboxOption,
    delaySettingNode,
    startNode,
    officalAcountNode,
    inmailNode,
    gatewayNode,
    conditionalSelectionTreeVue,
    inclusiveGatewayNode,
    functionWaitNode,
    doaApprovalNode,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      USER_PROPERTITY,
      attributesApprovalTypes,
      resubmitPolicy,
      // 输入输出变量弹框
      variableVisible: false,
      // 变量类型
      variableType: '',
      // 当前变量列表
      variableList: [],
      // 元数据弹框
      metadataVisible: false,
      // 审批人当前选项
      approvalSelect: 'start',
      // 选择指定审批人时搜索关键字获取的可选的用户列表
      candidateUserList: [],
      // 没有搜索关键字时按当前数据获取的已选择的用户列表
      selectedUserList: [],
      // 选择按属性匹配审批人时 可选的属性列表 包括角色列表 部门列表 地区列表
      userAttributeSelectInfo: {
        role: [],
        department: [],
        region: [],
      },
      // 选择属性标签后的审批人列表
      userListByTag: [],
      // 属性标签加载中
      attributesLoading: false,
      // 所有的云函数列表
      cloudFunctionList: [],
      // 已经选择的云函数列表
      selectedFunctionList: [],
      // 标签列表
      coustomConditionTags: [],
      // 对象字段列表
      objectFiledsList: [],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      businessEntityList: 'notice/getObjectList',
      startNodeObject: 'workflow/getStartNodeObject',
      getGatewayInclusiveState: 'workflow/getGatewayInclusiveState',
    }),
    computedFindStrategyOptions() {
      if (this.value.subref === 'prompt')
        return defaultFindStrategy.filter((item) => item.label !== '上级审批');
      switch (this.value.metadata.operator_type) {
        case 'custom_condition':
          return defaultFindStrategy.filter((item) => item.label !== '上级审批');
        default:
          return defaultFindStrategy;
      }
    },
    computedPreLeaderType() {
      if (this.approvalSelect !== 'pre') {
        return '';
      }
      return this.value.metadata.operator_value.leader_type;
    },
    computedCurrentLeaderType() {
      if (this.approvalSelect !== 'current') {
        return '';
      }
      return this.value.metadata.operator_value.leader_type;
    },
    multipleApprovalUser() {
      if (this.value.subref === 'prompt') return false;
      switch (this.value.metadata.operator_type) {
        case 'attribute':
          return this.value.metadata.operator_value.attribute_checked_user.length > 1;
        case 'candidate':
          return this.value.metadata.operator_value.candidate_user.length > 1;
        case 'custom_condition':
          return true;
        default:
          return false;
      }
    },
    computedFindStrategy() {
      switch (this.value.metadata.operator_type) {
        case 'leader':
          return this.value.metadata.operator_value.leader_find_strategy;
        case 'custom_condition':
          return this.value.metadata.operator_value.custom_condition_find_strategy;
        default:
          return '';
      }
    },
    findSignType() {
      switch (this.value.metadata.operator_type) {
        case 'leader':
          return '';
        case 'attribute':
          return this.value.metadata.operator_value.attribute_sign_type;
        case 'candidate':
          return this.value.metadata.operator_value.candidate_sign_type;
        case 'custom_condition':
          return this.value.metadata.operator_value.custom_condition_sign_type;
        default:
          throw new Error();
      }
    },
    // 列表排列顺序为：已选数据-搜索中数据
    nowUserList() {
      const notSelectedUser = this.candidateUserList.filter(({ account }) => {
        return !this.value.metadata.operator_value.candidate_user.includes(`(${account})`);
      });
      return this.selectedUserList.concat(notSelectedUser).map(this.showUserList);
    },
    nowFunctionList() {
      const notSelectedFunction = this.candidateUserList.filter(({ func_name }) => {
        return this.value.metadata.name !== func_name;
      });
      return this.cloudFunctionList.concat(notSelectedFunction);
    },
  },
  async created() {
    if (this.value.type === 'user') {
      this.userInit();
    } else if (this.value.type === 'start_node') {
      this.startNodeInit();
    } else if (['function', 'time_boundary_event', 'wait', 'doa'].includes(this.value.type)) {
      this.cloudFunctionInit();
    }

    if (this.startNodeObject) {
      const objId = this.businessEntityList.find(
        (object) => object.name === this.startNodeObject,
      )?.id;
      const { fields } = await this.$request({
        url: this.$api.metaGetObjectFields,
        data: {
          object_id: objId,
        },
      });
      this.objectFiledsList = fields;
    }
    this.loaded = true;
  },
  mounted() {},
  methods: {
    // 审批节点初始化
    userInit() {
      // 初始化标签列表
      this.getCoustomConditionTags();
      ['role', 'region', 'department'].forEach((tag) => this.getRoleSelectedList(tag));
      // 初始化当前选择的类型
      if (this.value.metadata.operator_type === 'leader') {
        if (
          ['preLeader', 'preUserInfoLeader', 'preEmpOrgLeader'].includes(
            this.value.metadata.operator_value.leader_type,
          )
        ) {
          this.approvalSelect = 'pre';
        } else if (
          ['currentLeader', 'currentUserInfoLeader', 'currentEmpOrgLeader'].includes(
            this.value.metadata.operator_value.leader_type,
          )
        ) {
          this.approvalSelect = 'current';
        }
      } else {
        this.approvalSelect = this.value.metadata.operator_type;
      }
      // 初始化当前可选的按属性配置的审批人列表
      this.refreshUserListByTag({
        ...this.value.metadata.operator_value.attribute_value_map,
      });
      // 初始化当前可选的选择审批人列表
      this.initSelectedUser();
    },
    // 开始节点初始化
    startNodeInit() {
      const { objectId } = this.$route.query;
      if (objectId) {
        const obecjtName =
          this.businessEntityList.find((object) => object.id === objectId)?.name || '';
        this.valueUpdate('metadata.trigger.resource', obecjtName);
      }
    },
    // 数据处理节点初始化
    cloudFunctionInit() {
      this.$request({
        data: {
          page: 0,
          size: 100000,
          search_key: '',
        },
        url: this.$api.getFuncList,
      }).then(({ list }) => {
        this.cloudFunctionList = list.filter(({ func_type }) => func_type === 0 || func_type === 4);
      });
    },
    valueUpdate(path, value) {
      console.log('meow?', path, value);
      this.$emit('update', {
        path,
        value,
      });
    },
    // 输入/输出变量
    variableOpen(type) {
      this.variableType = type;
      this.variableList = [...(this.value[type] || [])];
      this.$nextTick(() => {
        this.variableVisible = true;
      });
    },
    variableClose() {
      this.variableVisible = false;
      this.variableList = [];
    },
    variableSave(data) {
      this.valueUpdate(this.variableType, data);
      this.variableClose();
    },
    // 选择是否为审批业务时重置属性数据
    bizTypeChange(e) {
      this.valueUpdate('subref', e);
      if (this.value.metadata.operator_type) {
        this.valueUpdate('metadata.operator_value', {
          ..._.cloneDeep(defaultUserNodeFormValue),
        });
      }
    },
    // 选择审批人变更时修改数据 并清空不相关的数据
    approvalChange(e) {
      if (
        [
          'current',
          'pre',
          'currentLeader',
          'preLeader',
          'currentUserInfoLeader',
          'currentEmpOrgLeader',
          'preUserInfoLeader',
          'preEmpOrgLeader',
        ].includes(e)
      ) {
        this.valueUpdate('metadata.operator_type', 'leader');
        let leaderType = e;
        if (e === 'current') {
          leaderType = 'currentLeader';
        }
        if (e === 'pre') {
          leaderType = 'preLeader';
        }
        this.valueUpdate('metadata.operator_value', {
          ...this.value.metadata.operator_value,
          leader_type: leaderType,
          attribute_keys: [],
          attribute_value_map: {
            role: [],
            department: [],
            region: [],
          },
          attribute_checked_user: [],
          candidate_user: [],
        });
        this.candidateUserList = [];
      } else if (e === 'attribute') {
        this.valueUpdate('metadata.operator_type', 'attribute');
        this.valueUpdate('metadata.operator_value.candidate_user', []);
        this.candidateUserList = [];
      } else if (e === 'custom_condition') {
        this.valueUpdate('metadata.operator_type', 'custom_condition');
        this.valueUpdate('metadata.operator_value.candidate_user', []);
        this.candidateUserList = [];
      } else if (e === 'candidate') {
        this.valueUpdate('metadata.operator_type', 'candidate');
        this.valueUpdate('metadata.operator_value', {
          ...this.value.metadata.operator_value,
          attribute_checked_user: [],
          attribute_keys: [],
          attribute_value_map: {
            role: [],
            department: [],
            region: [],
          },
        });
      }
    },
    // 获取选择审批人的可选列表
    getUserList: _.debounce(function (input) {
      if (!input) {
        // 已经完成了选择 当前应该展示所有已选审批人的数据
        this.initSelectedUser();
      } else {
        // 正在输入新数据的清空 按关键字请求数据 这个数据将接在已选审批人数据之后
        this.$request({
          url: this.$api.getUserList,
          data: {
            page: 1,
            page_size: 100,
            keyword: input,
          },
          cb: ({ data }) => {
            this.candidateUserList = data;
          },
          showLoading: false,
        });
      }
    }, 200),
    initSelectedUser() {
      const regexp = /\((.*)\)/;
      if (this.value.metadata.operator_value.candidate_user.length > 0) {
        const accountList = this.value.metadata.operator_value.candidate_user.map((i) => {
          const result = regexp.exec(i);
          return result ? result[1] : '';
        });
        // 获取初始进入时的已选择用户列表
        this.$request({
          url: this.$api.getUserList,
          data: {
            page: 1,
            page_size: 100,
            account_list: accountList,
          },
          cb: ({ data }) => {
            this.selectedUserList = data;
            this.candidateUserList = [];
          },
          showLoading: false,
        });
      }
    },
    // 选择指定审批人
    candidateUserChange(list) {
      this.valueUpdate('metadata.operator_value.candidate_user', list);
      if (list.length > 1) {
        if (!this.value.metadata.operator_value.candidate_sign_type) {
          this.valueUpdate('metadata.operator_value.candidate_sign_type', 'orSign');
        }
      } else {
        this.valueUpdate('metadata.operator_value.candidate_sign_type', '');
      }
    },
    // 选择自定义条件标签
    customConditionChange(list) {
      this.valueUpdate('metadata.operator_value.custom_condition', list);
    },
    // 获取可选的审批人属性列表
    getRoleSelectedList(tag) {
      // 审批人求角色列表
      this.$request({
        url: this.$api.getApprovalUserAttributes,
        data: {
          appid: this.info.app_id || 0,
          tag,
          page: 1,
          page_size: 99999,
        },
        cb: ({ values }) => {
          this.userAttributeSelectInfo[tag] = values.map((value) => ({
            label: value,
            value,
          }));
        },
        showLoading: false,
      });
    },
    getCoustomConditionTags() {
      // 审批人求角色列表
      this.$request({
        url: this.$api.getApprovalUserAttributes,
        data: {
          appid: this.info.app_id || 0,
          page: 1,
          tag: 'customized',
          page_size: 99999,
        },
        cb: ({ values }) => {
          this.coustomConditionTags = values.map((value) => ({
            label: value,
            value,
          }));
        },
        showLoading: false,
      });
    },
    handleApprovalUserSelectedAttrubiesChange(list, key) {
      this.valueUpdate('metadata.operator_value.attribute_value_map', {
        ...this.value.metadata.operator_value.attribute_value_map,
        [key]: list,
      });

      this.refreshUserListByTag({
        ...this.value.metadata.operator_value.attribute_value_map,
        [key]: list,
      });
    },
    refreshUserListByTag(valueMap) {
      const tags = [];
      Object.keys(valueMap)?.forEach((tag) => {
        if (valueMap[tag].length) {
          tags.push({
            tag,
            values: valueMap[tag],
          });
        }
      });
      console.log('meowtag', tags, valueMap);
      this.getApprovalUserListByTag(tags);
    },
    getApprovalUserListByTag(tags = []) {
      this.attributesLoading = true;
      this.$request({
        url: this.$api.getApprovalUserListByTag,
        data: {
          appid: this.info.app_id || 0,
          tags,
          page: 1,
          page_size: 99999,
        },
        cb: ({ user_list }) => {
          const computedResData = user_list?.map(this.showUserList) || [];
          this.valueUpdate(
            'metadata.operator_value.attribute_checked_user',
            this.value.metadata.operator_value.attribute_checked_user.filter((value) => {
              return computedResData.some((resItem) => resItem.value === value);
            }),
          );

          this.userListByTag = computedResData;
          this.attributesLoading = false;
        },
        showLoading: false,
      });
    },
    // 更新审批人属性
    changeAttributeKeys(key, value) {
      let list = this.value.metadata.operator_value.attribute_keys;
      if (value) {
        list.push(key);
      } else {
        list = list.filter((i) => i !== key);
      }
      // 没有被选中的属性 需要清空已选项
      const attributeValueMapClear = {};
      USER_PROPERTITY.forEach(({ key }) => {
        if (!list.includes(key)) {
          attributeValueMapClear[key] = [];
        }
      });

      this.valueUpdate('metadata.operator_value.attribute_keys', list);
      this.valueUpdate('metadata.operator_value.attribute_value_map', {
        ...this.value.metadata.operator_value.attribute_value_map,
        ...attributeValueMapClear,
      });

      this.refreshUserListByTag({
        ...this.value.metadata.operator_value.attribute_value_map,
        ...attributeValueMapClear,
      });
    },
    // 变更空审批人时的自动选择策略
    changeFindStrategy(e) {
      this.valueUpdate('metadata.operator_value.leader_find_strategy', e);
      this.valueUpdate('metadata.operator_value.custom_condition_find_strategy', e);
      this.valueUpdate('metadata.operator_value.recursive_find_leader_enable', false);
    },
    // 变更多个审批人策略
    changeSignType(e) {
      this.valueUpdate('metadata.operator_value.candidate_sign_type', e);
      this.valueUpdate('metadata.operator_value.attribute_sign_type', e);
      this.valueUpdate('metadata.operator_value.custom_condition_sign_type', e);
    },
    // 格式化用户下拉列表
    showUserList(item) {
      return {
        label: `${item.name}${item.description ? `-${item.description}` : ''}`,
        value: `(${item.account})`,
      };
    },
    // 搜索云函数
    getFunctionList: _.debounce(function (input) {
      if (input) {
        this.$request({
          url: this.$api.getFuncList,
          data: {
            page: 0,
            size: 20,
            search_key: input,
          },
          cb: ({ list }) => {
            this.cloudFunctionList = list.filter(
              ({ func_type }) => func_type === 0 || func_type === 4,
            );
          },
          showLoading: false,
        });
      }
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';

.node-container {
  padding: 0 16px 20px;
  .start-node-tips {
    display: flex;
    justify-content: center;
    background: #f9e0c7;
    border-radius: 3px;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #384050;
    margin-bottom: 10px;
    .iconfont {
      font-size: 18px;
      margin-right: 8px;
      color: #fa9600;
    }
  }
  .intro-title {
    height: 40px;
    line-height: 40px;
    background: #f6f7fb;
    font-weight: 500;
    font-size: 14px;
    color: #667082;
    margin: 10px -10px 0;
    padding-left: 10px;
  }
  .node-title {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #1c2028;
    font-weight: 700;
    .require {
      color: #e34d59;
    }
  }
  .node-row {
    margin-bottom: 16px;
  }
  .node-split {
    margin: 16px 0;
    height: 1px;
    background: #e5eaf2;
  }
  .node-radio-list {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .node-checkbox-group {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .node-attribute-row {
        display: flex;
        align-items: center;
        margin-top: 16px;
        .node-attribute-select {
          margin-left: 24px;
        }
      }
    }
  }

  .node-condition-row {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .condition {
      margin-right: 8px;
      white-space: nowrap;
    }
  }
  .node-form-row {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    .node-form-title {
      margin-right: 16px;
    }
  }
  .textarea-input {
    ::v-deep .el-input__inner {
      height: auto;
    }
  }
}
.leader-type-radio-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.node-selected-row {
  margin-top: 16px;
  margin-bottom: 24px;
  .node-selected-title {
    margin-bottom: 8px;
  }
}
</style>
