<template>
  <div>
    <t-table row-key="index" :data="apiData" :columns="columns">
      <template #permission="{ row }">
        <t-checkbox-group v-model="permissionMap[row.permission_id]">
          <t-checkbox
            v-for="item in apiActionOptions"
            :key="item.value"
            :value="item.value"
            @change="(checked) => permissionChange(row, item.value, checked)"
            :disabled="!jurisdiction.roleManage"
          >
            {{ item.label }}
          </t-checkbox>
        </t-checkbox-group>
      </template>
    </t-table>
  </div>
</template>

<script>
// import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: {
    searchObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permissionMap: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      apiData: [],
      columns: [
        {
          colKey: 'name',
          title: '名称',
          width: 200,
        },
        {
          colKey: 'path',
          title: '路径',
          width: 200,
        },
        {
          colKey: 'description',
          title: '描述',
          Width: 300,
        },
        {
          colKey: 'permission',
          title: '权限',
          minWidth: 300,
        },
      ],
      apiActionOptions: [
        { label: 'RPC', value: 'RPC' },
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
  },
  mounted() {
    // this.loadApiData();
  },
  methods: {
    init() {
      // this.childrenPermissionMap = cloneDeep(this.permissionMap);
      this.loadApiData();
    },
    loadApiData() {
      const url = this.$api.getApiList;

      const cb = (data) => {
        this.initPermission(data.data);
        this.apiData = data.data;
      };
      return this.$request({
        url,
        cb,
        data: { page_size: 999999, appid: this.searchObj.appid },
      });
    },
    initPermission(list) {
      for (const item of list) {
        if (
          !this.permissionMap[item.permission_id] ||
          this.permissionMap[item.permission_id]?.length == 0
        ) {
          this.$set(this.permissionMap, item.permission_id, []);
        }

        if (item.children && item.children.length > 0) {
          this.initPermission(item.children);
        }
        if (item.fields && item.fields?.length > 0) {
          this.initPermission(item.fields);
        }
      }
    },
    permissionChange(data, action, value) {
      console.log(value);
      let url = '';
      if (value === true) {
        url = this.$api.addRolePermission;
      } else {
        url = this.$api.removeRolePermission;
      }

      const cb = (res) => {
        console.log('权限设置成功 :>> ', res);
      };
      this.$request({
        url,
        cb,
        data: {
          role_id: this.searchObj.role_id,
          permission_id: data.permission_id,
          action,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tdesign/table.scss';
</style>
