<template>
  <div>
    <el-card class="antvbox">
      <div class="button-group">
        <el-button type="info" @click="handleSelectCloudEvent">新建云函数自定义事件</el-button>
        <el-button type="primary" @click="handleCreateNoticeEvent">新建通知事件</el-button>
      </div>
      <el-tabs tab-position="left" class="event-tab-contianer" v-model="eventTypes">
        <el-tab-pane name="noticeEvent">
          <span slot="label">通知消息</span>
          <div v-if="eventTypes === 'noticeEvent'">
            <el-tabs v-model="status" type="card">
              <el-tab-pane name="1">
                <span slot="label">已启用</span>
                <notice-event-list-vue v-if="status === '1'" :status="status" />
              </el-tab-pane>
              <el-tab-pane name="2">
                <span slot="label">已停用</span>
                <notice-event-list-vue v-if="status === '2'" :status="status" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
        <el-tab-pane name="cloudEvent">
          <span slot="label">云函数自定义事件</span>
          <div v-if="eventTypes === 'cloudEvent'">
            <el-tabs v-model="status" type="card">
              <el-tab-pane name="1">
                <span slot="label">已启用</span>
                <cloud-event-list-vue v-if="status === '1'" :status="status" />
              </el-tab-pane>
              <el-tab-pane name="2">
                <span slot="label">已停用</span>
                <cloud-event-list-vue v-if="status === '2'" :status="status" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import noticeEventListVue from './component/notice-event-list.vue';
import cloudEventListVue from './component/cloud-event-list.vue';
export default {
  components: {
    noticeEventListVue,
    cloudEventListVue,
  },
  data() {
    return {
      eventTypes: 'noticeEvent',
      status: '1',
    };
  },
  created() {
    if (this.$route.query.eventTypes) {
      this.eventTypes = this.$route.query.eventTypes;
    }
    if (this.$route.query.statusTab) {
      this.status = this.$route.query.statusTab;
    }
  },
  methods: {
    handleSelectCloudEvent() {
      this.$router.push({
        name: 'cloud-event-detail',
      });
    },
    handleCreateNoticeEvent() {
      this.$router.push({
        name: 'noitce-event-detail',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.antvbox {
  background-color: #fff;
  padding: 15px;
  height: 100%;
  .button-group {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>
