<template>
  <div>
    <el-dialog :title="title" :visible.sync="showDialog" width="1200px">
      <el-button type="primary" @click="addData">添加项</el-button>
      <!-- 列表 -->
      <el-table :data="variableList" style="width: 100%" height="250">
        <el-table-column label="变量名(name)">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="请输入" />
          </template>
        </el-table-column>
        <el-table-column label="类型(type)">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.type"
              placeholder="请选择"
              @change="
                (type) => {
                  changeType(type, scope.$index);
                }
              "
            >
              <el-option
                v-for="item in VARIABLETYPES"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="值(vale)" width="300px">
          <template slot-scope="scope">
            <!-- 数字 -->
            <el-input
              v-if="scope.row.type === 'number'"
              v-model.number="scope.row.vale"
              placeholder="请输入"
            />
            <!-- 对象 -->
            <METADATA
              v-else-if="scope.row.type === 'object'"
              :value="scope.row.vale"
              @select="
                ({ id }) => {
                  scope.row.vale = id;
                }
              "
            />
            <!-- 布尔 -->
            <el-select
              v-else-if="scope.row.type === 'boolean'"
              v-model="scope.row.vale"
              placeholder="请选择"
            >
              <el-option label="true" :value="true" />
              <el-option label="false" :value="false" />
            </el-select>
            <!-- 其他 -->
            <el-input v-else v-model="scope.row.vale" placeholder="请输入" />
          </template>
        </el-table-column>
        <el-table-column label="描述(remark)">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" placeholder="请输入" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="del(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 元数据弹窗 -->
  </div>
</template>
<script>
import { VARIABLETYPES } from '@/utils/constant.js';
import METADATA from './metadata.vue';
export default {
  components: {
    METADATA,
  },
  props: {
    type: {
      type: String,
      default: 'inputs', // inputs: 输入变量   outputs: 输出变量
    },
    list: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variableList: [],
      selectIndex: -1,
      // 元数据弹窗
      showMetadata: false,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.cancel();
      },
    },
    VARIABLETYPES() {
      return VARIABLETYPES;
    },
    title() {
      return this.type === 'inputs' ? '输入变量配置' : '输出变量配置';
    },
  },
  created() {
    this.variableList = JSON.parse(JSON.stringify(this.list));
  },
  methods: {
    addData() {
      this.variableList.push({
        name: '',
        remark: '',
        type: '',
        vale: '',
      });
    },
    changeType(type, index) {
      switch (type) {
        case 'number':
          this.variableList[index].vale = 0;
          break;
        case 'boolean':
          this.variableList[index].vale = false;
          break;
        default:
          this.variableList[index].vale = '';
      }
      this.variableList = JSON.parse(JSON.stringify(this.variableList));
    },
    cancel() {
      this.$emit('cancel', false);
    },
    submit() {
      this.$emit('save', this.variableList);
    },
    openMetadata(index) {
      this.selectIndex = index;
      this.showMetadata = true;
    },
    selectMetadata({ name }) {
      this.variableList[this.selectIndex].key = name;
      this.closeMetadata();
    },
    closeMetadata() {
      this.showMetadata = false;
      this.selectIndex = -1;
    },
    del(index) {
      this.variableList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
