<template>
  <el-form :model="form" :rules="formRule" ref="form" class="container">
    <div class="form-title">基本信息</div>
    <el-form-item :label-width="labelWidth" prop="name" label="模板名称">
      <el-input class="form-input" v-model="form.name" placeholder="请输入" />
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="subject" label="业务实体">
      <el-select class="form-input" filterable v-model="form.subject" placeholder="请选择">
        <el-option
          v-for="item in showObjectList"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="templateCode" label="模板ID">
      <el-input
        :disabled="id !== ''"
        class="form-input"
        v-model="form.templateCode"
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="remark" label="备注">
      <el-input
        class="form-input"
        type="textarea"
        :rows="2"
        v-model="form.remark"
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <div class="form-title">接收人信息</div>
    <el-form-item :label-width="labelWidth" prop="messagingType" label="通知类型">
      <el-checkbox-group v-model="form.messagingType" @change="changeMessagingType">
        <el-checkbox :label="'mail'">邮件通知</el-checkbox>
        <el-checkbox :label="'wecom'">企微通知</el-checkbox>
        <el-checkbox :label="'system'">站内信</el-checkbox>
        <el-checkbox :label="'sms'">短信</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item
      v-if="needStation"
      :label-width="labelWidth"
      prop="systemMessagingType"
      label="站内信类型"
    >
      <el-select
        class="form-input"
        filterable
        v-model="form.systemMessagingType"
        placeholder="请选择"
      >
        <el-option
          v-for="item in systemMessageTypeList"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="recipientType" label="接收人设置">
      <el-radio-group v-model="form.recipientType" @change="form.recipient = []">
        <el-radio :label="1">手动指定</el-radio>
        <el-radio :label="2">由调用方指定</el-radio>
      </el-radio-group>
      <div
        v-if="form.messagingType.length === 1 && needStation && form.recipientType === 2"
        class="tip"
      >
        当调用方传入uin时，将发送给对应用户；当调用方传入belong_territory时，将发送给对应岗位
      </div>
    </el-form-item>
    <el-form-item label="收件人" :label-width="labelWidth">
      <UserSelect
        v-if="!loading"
        :need-wecom="needWecom"
        :need-email="needEmail"
        :disabled="form.recipientType === 2"
        class="form-input"
        v-model="form.recipient"
      />
    </el-form-item>
    <div class="form-title">
      通知信息
      <div v-if="false" class="form-warning">
        通知的标题和正文只能选择同一个对象的字段进行变量嵌入
      </div>
    </div>
    <el-form-item
      v-if="needWecom"
      required
      :label-width="labelWidth"
      prop="wecomMsgType"
      label="企微消息类型"
    >
      <el-radio-group v-model="form.wecomMsgType" :disabled="form.messagingType.length > 1">
        <el-radio :label="'text'">文本消息</el-radio>
        <el-radio :label="'miniprogram_notice'">小程序通知消息</el-radio>
        <el-radio v-if="false" :label="'news'">图文消息</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item
      v-if="form.messagingType.includes('sms')"
      :label-width="labelWidth"
      prop="sms_template_id"
      label="腾讯云短信模版ID"
    >
      <el-input
        class="form-input"
        @input="handleSmsTpl"
        v-model="form.sms_template_id"
        placeholder="请输入"
      />
      <el-button
        :loading="tpmlLoading"
        :disabled="!canGetSmsTpl"
        class="primary"
        @click="handleGetTmpl"
      >
        获取模版
      </el-button>
    </el-form-item>
    <el-form-item v-if="needTitle" :label-width="labelWidth" prop="title">
      <template v-slot:label>
        <span>
          通知标题
          <el-popover
            placement="bottom"
            trigger="hover"
            content="本标题将被应用于短信通知以外的所有其他通知类型中"
          >
            <i slot="reference" class="el-icon-question"></i>
          </el-popover>
        </span>
      </template>
      <el-input class="form-input" v-model="form.title" placeholder="请输入" />
      <el-button
        class="primary"
        :disabled="!form.subject"
        type="info"
        @click="openSelectColumn('title')"
      >
        插入对象字段
      </el-button>
    </el-form-item>
    <el-form-item v-if="needPlatform" :label-width="labelWidth" prop="platform" label="通知终端">
      <el-checkbox-group v-model="form.platform">
        <el-checkbox :label="1">小程序端</el-checkbox>
        <el-checkbox :label="2">web端</el-checkbox>
        <el-checkbox :label="3">H5端</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="content" label="通知内容">
      <div v-if="form.messagingType.length === 1 && needEmail" class="editor-type">
        <div class="radio-group">
          <div class="radio" @click.stop="changeEditorType(1)">
            <el-radio :value="form.editorType === 1 ? 1 : 0" :label="1">编辑富文本</el-radio>
          </div>
          <div class="radio" @click.stop="changeEditorType(2)">
            <el-radio :value="form.editorType === 2 ? 1 : 0" :label="1">编辑HTML</el-radio>
          </div>
        </div>
      </div>
      <template v-if="needMessage">
        <el-input
          class="form-input"
          type="textarea"
          disabled
          :rows="2"
          v-model="form.content"
          placeholder="请输入"
        ></el-input>
      </template>
      <!-- 企业微信的文本消息 -->
      <template v-else-if="loading || (needWecom && form.wecomMsgType === 'text')">
        <el-input
          class="form-input"
          type="textarea"
          :rows="2"
          v-model="form.content"
          placeholder="请输入"
        ></el-input>
        <el-button
          class="primary"
          :disabled="!form.subject"
          type="info"
          @click="openSelectColumn('content')"
        >
          插入对象字段
        </el-button>
      </template>
      <!-- 企业微信的小程序消息 json格式 -->
      <template v-else-if="contentJson">
        <div class="row">
          <InputJson class="form-input" v-model="form.content" />
          <el-button
            class="primary"
            :disabled="!form.subject"
            type="info"
            @click="openSelectColumn('content')"
          >
            插入对象字段
          </el-button>
        </div>
        <div>
          <a style="color: #0077ff" :href="documentLink" target="_blank">
            点击查看JSON格式相关文档，仅需填写miniprogram_notice属性下的对象
          </a>
        </div>
      </template>
      <!-- html消息 -->
      <template v-else-if="form.editorType === 2">
        <div class="editor-block">
          <div class="editor-part">
            <div class="editor-title">
              编辑HTML
              <el-button
                class="primary"
                :disabled="!form.subject"
                type="info"
                @click="openSelectColumn('content')"
              >
                插入对象字段
              </el-button>
            </div>
            <CodeEditor class="code-editor" v-model="form.content" :options="htmlOptions" />
            <el-button type="text" @click="openPreview">预览</el-button>
          </div>
          <div class="editor-part">
            <div class="editor-title">自定义脚本</div>
            <CodeEditor class="code-editor" v-model="form.script" :options="jsOptions" />
            <el-button type="text" @click="openHelp">帮助文档</el-button>
          </div>
        </div>
      </template>
      <!-- 富文本消息 -->
      <template v-else>
        <el-button
          class="primary"
          :disabled="!form.subject"
          type="info"
          @click="openSelectColumn('content', 'editor')"
        >
          插入对象字段
        </el-button>
        <RichEditor
          :content="form.content"
          :template-str="templateStr"
          @getContent="
            (e) => {
              form.content = e;
              $refs.form.validateField('content');
            }
          "
        />
      </template>
    </el-form-item>
    <el-form-item :label-width="labelWidth" prop="templateVars" label="">
      <template v-if="needMessage">
        <div :key="index" v-for="(item, index) in tmplVars" class="field-item">
          <div class="field">变量{{ item }}:</div>
          <el-input
            :value="form.templateVars[item]"
            @input="(e) => handleVar(e, item)"
            class="field-input"
            placeholder="请输入"
          ></el-input>
          <el-button
            :disabled="!form.subject"
            class="primary"
            type="info"
            @click="openSelectColumn('tmplvars', '', item)"
          >
            插入对象字段
          </el-button>
        </div>
      </template>
    </el-form-item>
    <div class="form-action">
      <el-button :disabled="isSubmit" type="primary" @click="submit">确定</el-button>
      <el-button type="info" class="primary" @click="cancel">取消</el-button>
    </div>
    <MessageBox ref="messageBox" />
    <MessageBox ref="previewBox" class="preview-box">
      <template v-slot:content>
        <div v-html="previewContent" />
      </template>
    </MessageBox>
    <MessageBox ref="helpBox">
      <template v-slot:content>
        <div>脚本语法使用js语法（部分支持）和 underscore(_)，注意变量名不要以双下划线开头</div>
        <div>1. 脚本中引用自定义变量，使用 args.xxx，如 args.task_id</div>
        <div>
          2. 脚本中引用元数据变量，使用 {object}.xxx，如
          events_meeting.meeting_name，注意元数据变量仅支持引用HTML模版中使用的字段，其余为undefined
        </div>
        <div>
          3. 模版中可以引用脚本中定义的变量，如 {% foo %}，可以在此处使用表达式计算值，如 {% foo + 1
          %}
        </div>
        <div>当前支持的IO方法：</div>
        <div>- debug_log([any],...) 打印日志，参数：任意类型，返回：无</div>
        <div>
          - get_query([query]) 获取query结果，参数：query字符串，返回：元数据query接口返回data参数
        </div>
        <div>
          - get_field_option([object],[field])
          获取枚举字段的选项，参数：任意对象名、字段名，返回：value到label的映射
        </div>
      </template>
    </MessageBox>
    <SelectColumn
      v-if="selectVisible"
      custom
      :show="selectVisible"
      :object="form.subject"
      @cancel="selectVisible = false"
      @insertion="insertion"
    />
    <Clipboard ref="copy" :copytext="copytext" />
  </el-form>
</template>

<script>
import _ from 'lodash';
import UserSelect from './components/userSelect';
import RichEditor from '@/components/richEditor';
import MessageBox from '@/components/messageBox';
import SelectColumn from '@/components/selectColumn';
import InputJson from '@/components/inputJson';
import { arrUnique } from '@/utils/util';
import { mapActions, mapGetters } from 'vuex';
import Clipboard from 'nges-common/src/web/components/Clipboard';
import CodeEditor from './editor';

const ESCAPE_MAP = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
};

export default {
  name: 'NoticeDetail',
  components: {
    UserSelect,
    RichEditor,
    MessageBox,
    SelectColumn,
    InputJson,
    Clipboard,
    CodeEditor,
  },
  data() {
    return {
      labelWidth: '150px',
      id: '',
      // 列表页状态
      listTab: '',
      // 延后userSelect的加载时间确保其能获取初始值
      loading: true,
      // 提交中
      isSubmit: false,
      form: {
        // 模板名称
        name: '',
        // 业务模块
        subject: '',
        // 模版ID
        templateCode: '',
        // 备注
        remark: '',
        // 通知类型
        messagingType: [],
        // 消息类型
        wecomMsgType: '',
        // 站内信类型
        systemMessagingType: '',
        // 接收人设置
        recipientType: 1,
        // 收件人
        recipient: [],
        // 编辑形式
        editorType: 1,
        // 通知标题
        title: '',
        // 通知内容
        content: '',
        // js脚本
        script: '',
        // 腾讯云短信模版id
        sms_template_id: '',
        // 自定义模版变量
        templateVars: {},
        // 通知终端
        platform: [1, 2],
      },
      // 校验规则
      formRule: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        templateCode: [{ required: true, message: '请输入模板ID', trigger: 'blur' }],
        messagingType: [{ required: true, message: '请选择通知类型 ', trigger: 'change' }],
        systemMessagingType: [{ required: true, message: '请选择站内信类型', trigger: 'change' }],
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入通知内容' },
          {
            validator: (rule, value, callback) => {
              const err = this.validateContent();
              if (err) {
                callback(err);
              } else {
                callback();
              }
            },
          },
        ],
        sms_template_id: [
          { required: true, message: '请输入腾讯云短信模版ID' },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+$/.test(value)) {
                this.canGetSmsTpl = false;
                callback(new Error('请输入纯数字'));
              } else {
                this.canGetSmsTpl = true;
                callback();
              }
            },
            trigger: 'change',
          },
        ],
        templateVars: [
          {
            validator: (rule, value, callback) => {
              const isInvalid = Object.keys(value || {}).some((key) => {
                if (!value[key]) {
                  return true;
                }
              });
              if (isInvalid) {
                callback('请填充完整的变量');
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
        platform: [{ required: true, message: '请选择通知终端 ', trigger: 'change' }],
      },
      // 展示选择窗口
      selectVisible: false,
      // 打开选择窗口的按钮
      selectType: 'title',
      editorType: '',
      // 短信变量
      varItem: '',
      // 要应用在编辑器里的模板字符串
      templateStr: '',
      // 自定义变量列表
      templateVar: [],
      // 对象变量列表
      templateMetadataVar: [],
      // 脚本变量列表
      templateScriptVar: [],
      // 站内信类型列表
      systemMessageTypeList: [],
      // 自动复制变量
      copytext: '',
      // 代码输入框配置
      htmlOptions: {
        placeholder: '<html></html>',
        autorefresh: true,
        mode: 'text/html',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      jsOptions: {
        placeholder: 'var a = 1',
        autorefresh: true,
        mode: 'text/javascript',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      // 短信模版loading
      tpmlLoading: false,
      canGetSmsTpl: false,
      currentTpl: {},
    };
  },
  computed: {
    ...mapGetters({
      objectList: 'notice/getObjectList',
    }),
    showObjectList() {
      return this.objectList.map(({ name, display_name }) => {
        return {
          label: display_name ? `${display_name}-${name}` : name,
          value: name,
        };
      });
    },
    needWecom() {
      return this.form.messagingType.includes('wecom');
    },
    needMessage() {
      return this.form.messagingType.includes('sms');
    },
    contentJson() {
      return this.needWecom && this.form.wecomMsgType !== 'text';
    },
    needEmail() {
      return this.form.messagingType.includes('mail');
    },
    needStation() {
      return this.form.messagingType.includes('system');
    },
    // 只选了站内信的时候,需要选择终端
    needPlatform() {
      return this.form.messagingType.length == 1 && this.form.messagingType[0] === 'system';
    },
    needTitle() {
      return this.form.messagingType.some((i) => ['mail', 'wecom', 'system'].includes(i));
    },
    documentLink() {
      if (this.contentJson) {
        switch (this.form.wecomMsgType) {
          case 'miniprogram_notice':
            return 'https://developer.work.weixin.qq.com/document/path/90236#%E5%B0%8F%E7%A8%8B%E5%BA%8F%E9%80%9A%E7%9F%A5%E6%B6%88%E6%81%AF';
          case 'news':
            return 'https://developer.work.weixin.qq.com/document/path/90236#%E5%9B%BE%E6%96%87%E6%B6%88%E6%81%AF';
        }
      }
      return '';
    },
    tmplVars() {
      return Object.keys(this.form.templateVars);
    },
    previewContent() {
      return this.form.content.replaceAll('href="mailto', '');
    },
  },
  watch: {
    // 与富文本编辑器保持一致
    templateStr(newVal) {
      if (this.form.messagingType.includes('wecom')) {
        this.form.content += newVal;
      }
    },
    'form.messagingType'(newVal, oldVal) {
      if (this.loading) return;
      if (!oldVal.includes('wecom') && newVal.includes('wecom')) {
        this.form.wecomMsgType = 'text';
      }
    },
  },
  mounted() {
    this.getObjectList();
    this.getTypeList();
    const { id, listTab } = this.$route.query;

    if (id) {
      this.id = id;
      this.listTab = listTab;
      this.$request({
        url: this.$api.getMessagingTemplate,
        data: {
          template_code: id,
        },
        cb: (data) => {
          this.form.name = data.name;
          this.form.templateCode = data.template_code;
          this.form.remark = data.remark;
          this.form.subject = data.subject;
          this.form.messagingType = data.messaging_type;
          this.form.systemMessagingType = data.system_messaging_type;
          this.form.wecomMsgType = data.wecom_msg_type;
          if (this.needWecom && !this.form.wecomMsgType) {
            this.form.wecomMsgType = 'text';
          }
          this.form.recipientType = data.recipient_type;
          this.form.recipient = data.recipient_list;
          this.form.title = data.title;
          this.form.content = data.content;
          this.form.script = data.script;
          if (this.form.script) this.form.editorType = 2;
          this.form.sms_template_id = data.sms_template_id;
          this.canGetSmsTpl = !!/^\d+$/.test(this.form.sms_template_id);
          this.form.templateVars = (data.sms_params || []).reduce((pre, item, index) => {
            pre[`${index + 1}`] = item;
            return pre;
          }, {});
          this.form.platform = data.platform;
          this.$nextTick(() => {
            this.loading = false;
          });
        },
      });
    } else {
      this.loading = false;
    }
    this.$refs.form.clearValidator();
  },
  methods: {
    ...mapActions({
      getObjectList: 'notice/requestObjectList',
    }),
    escapeHtmlToText(text = '') {
      let newText = text;
      for (const k of Object.keys(ESCAPE_MAP)) {
        newText = newText.replaceAll(k, ESCAPE_MAP[k]);
      }
      return newText;
    },
    submit() {
      if (this.contentJson) {
        try {
          JSON.parse(this.form.content);
        } catch (e) {
          console.log(e);
          this.$message.error('通知内容需要符合JSON格式');
          return;
        }
      }

      this.isSubmit = true;
      this.varCheck();

      const param = {
        name: this.form.name,
        template_code: this.form.templateCode,
        remark: this.form.remark,
        subject: this.form.subject,
        messaging_type: this.form.messagingType,
        system_messaging_type: this.form.systemMessagingType,
        wecom_msg_type: this.needWecom ? this.form.wecomMsgType : '',
        recipient_type: this.form.recipientType,
        recipient_list: this.form.recipient,
        title: this.form.title,
        script: this.form.script,
        content: this.escapeHtmlToText(this.form.content),
        template_var: arrUnique(this.templateVar),
        template_metadata_var: arrUnique(this.templateMetadataVar),
        template_expr_var: arrUnique(this.templateScriptVar),
        sms_params: Object.values(this.form.templateVars),
        platform: this.form.platform,
      };

      if (this.form.messagingType.includes('sms')) {
        param.sms_template_id = this.form.sms_template_id;
      }

      console.log('meow', param);

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$request({
            url: this.id ? this.$api.updateMessagingTemplate : this.$api.addMessagingTemplate,
            data: param,
            cb: () => {
              this.isSubmit = false;
              this.$message.success('保存成功');
              this.$router.push({
                name: 'notice-list',
                query: {
                  listTab: this.listTab,
                },
              });
            },
            fault: () => {
              this.isSubmit = false;
            },
          });
        } else {
          this.isSubmit = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel() {
      try {
        const query = {};
        if (this.id) query.listTab = this.listTab;
        this.$refs.messageBox.show({
          title: '提示',
          content: '此操作将不会保存已修改数据?',
          titleIconType: 'warning',
          needCancel: true,
          cancelAction: this.$refs.messageBox.close,
          confirmAction: () => {
            this.$refs.messageBox.close();
            this.$router.push({
              name: 'notice-list',
              query,
            });
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    openSelectColumn(type, editType = '', varItem) {
      this.selectVisible = true;
      this.selectType = type;
      this.editorType = editType;
      this.varItem = varItem;
    },
    // 向编辑器插入字符串
    insertion({ str = '' }) {
      const text = `{{${str}}}`;
      switch (this.selectType) {
        case 'title':
          this.form.title += text;
          break;
        case 'content':
          if (this.editorType) {
            this.templateStr = text;
          } else {
            if (this.contentJson || this.form.editorType === 2) {
              this.copytext = text;
              this.$refs.copy.copy();
            } else {
              this.form.content += text;
            }
          }
          break;
        case 'tmplvars': {
          if (this.varItem) {
            this.form.templateVars[this.varItem] += text;
            this.form.templateVars = { ...this.form.templateVars };
          }
          break;
        }
        default:
          break;
      }
    },
    // 搜寻所有符合逻辑的变量加入变量列表
    varCheck() {
      const templateMetadataVar = [];
      const templateVar = [];
      const templateScriptVar = [];

      const titleTemplateRegexp = /{{([A-z][\w._]*?\.[\w._]*?[A-z])}}/g;
      let titleTemplateResult = titleTemplateRegexp.exec(this.form.title);
      while (titleTemplateResult) {
        console.log('meowtitle1', titleTemplateResult, this.form.title);
        templateMetadataVar.push(titleTemplateResult[1]);
        titleTemplateResult = titleTemplateRegexp.exec(this.form.title);
        console.log('meowtitle12', titleTemplateResult);
      }
      const titleCustomRegexp = /{{(\w+?)}}/g;
      let titleCustomResult = titleCustomRegexp.exec(this.form.title);
      while (titleCustomResult) {
        console.log('meowtitle2', titleCustomResult);
        templateVar.push(titleCustomResult[1]);
        titleCustomResult = titleCustomRegexp.exec(this.form.title);
      }

      const contentTemplateRegexp = /{{([A-z][\w._]*?\.[\w._]*?[A-z])}}/g;
      let contentTemplateResult = contentTemplateRegexp.exec(this.form.content);
      while (contentTemplateResult) {
        console.log('meowcon1', contentTemplateResult);
        templateMetadataVar.push(contentTemplateResult[1]);
        contentTemplateResult = contentTemplateRegexp.exec(this.form.content);
      }
      const contentCustomRegexp = /{{(\w+?)}}/g;
      let contentCustomResult = contentCustomRegexp.exec(this.form.content);
      while (contentCustomResult) {
        console.log('meowcon2', contentCustomResult);
        templateVar.push(contentCustomResult[1]);
        contentCustomResult = contentCustomRegexp.exec(this.form.content);
      }
      if (this.form.editorType === 2) {
        const contentScriptRegexp = /{%(\w+?)%}/g;
        let contentScriptResult = contentScriptRegexp.exec(this.form.content);
        while (contentScriptResult) {
          console.log('meowcon3', contentScriptResult);
          templateScriptVar.push(contentScriptResult[1]);
          contentScriptResult = contentScriptRegexp.exec(this.form.content);
        }
      }

      Object.values(this.form.templateVars).forEach((smsTmplVar) => {
        const smsTmplVarRegexp = /{{([A-z][\w._]*?\.[\w._]*?[A-z])}}/g;
        let smsTmplVarResult = smsTmplVarRegexp.exec(smsTmplVar);
        while (smsTmplVarResult) {
          console.log('meowcon1', smsTmplVarResult);
          templateMetadataVar.push(smsTmplVarResult[1]);
          smsTmplVarResult = smsTmplVarRegexp.exec(smsTmplVar);
        }
        const smsTmplVarCustomRegexp = /{{(\w+?)}}/g;
        let smsTmplVarCustomResult = smsTmplVarCustomRegexp.exec(smsTmplVar);
        while (smsTmplVarCustomResult) {
          console.log('meowcon2', smsTmplVarCustomResult);
          templateVar.push(smsTmplVarCustomResult[1]);
          smsTmplVarCustomResult = smsTmplVarCustomRegexp.exec(smsTmplVar);
        }
      });
      this.templateVar = templateVar;
      this.templateMetadataVar = templateMetadataVar;
      this.templateScriptVar = templateScriptVar;
    },
    getTypeList() {
      this.$request({
        url: this.$api.metaGetObjectByName,
        data: {
          objects: ['message'],
        },
        cb: ({ list }) => {
          if (list.length) {
            const field = list[0].fields.filter(({ name }) => name === 'type');
            this.systemMessageTypeList = field[0].select_one_option.options;
          }
        },
      });
    },
    changeMessagingType() {
      if (this.needWecom && this.form.messagingType.length > 1) {
        this.form.wecomMsgType = 'text';
      }
      if (!this.needEmail || (this.needEmail && this.form.messagingType.length > 1)) {
        this.form.editorType = 1;
      }
    },
    openPreview() {
      this.$refs.previewBox.show({
        title: '预览',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.previewBox.close,
      });
    },
    async handleGetTmpl() {
      try {
        if (this.form.sms_template_id && this.canGetSmsTpl) {
          this.tpmlLoading = true;
          const res = await this.$request({
            url: this.$api.GetSmsTemplate,
            data: {
              sms_template_id: this.form.sms_template_id,
            },
          });
          this.form.templateVars = (this.getAllMatches(res.template_content) || []).reduce(
            (pre, v) => {
              pre[v] = '';
              return pre;
            },
            {},
          );
          this.form.content = res.template_content;
          this.currentTpl[this.form.sms_template_id] = res;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.tpmlLoading = false;
      }
    },
    getAllMatches(str) {
      const regex = /\{\d+\}/g;
      const matches = str.match(regex);
      return matches;
    },
    handleVar(e, item) {
      this.form.templateVars[item] = e;
      this.form.templateVars = { ...this.form.templateVars };
    },
    openHelp() {
      this.$refs.helpBox.show({
        title: '帮助',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.helpBox.close,
      });
    },
    changeEditorType: _.debounce(function (val) {
      if (this.form.content) {
        this.$message.error('请先清空当前内容，再进行切换');
      } else {
        this.form.editorType = val;
      }
    }, 100),
    handleSmsTpl() {
      const smsTpl = this.currentTpl[this.form.sms_template_id];
      if (!smsTpl) {
        this.form.templateVars = {};
        this.form.content = '';
      } else {
        this.form.templateVars = (this.getAllMatches(smsTpl.template_content) || []).reduce(
          (pre, v) => {
            pre[v] = '';
            return pre;
          },
          {},
        );
        this.form.content = smsTpl.template_content;
      }
    },
    validateContent() {
      if (this.needPlatform) {
        const platformFlagMap = { 1: 'wx', 2: 'web', 3: 'h5' };
        const platforms = (this.form.platform || []).map((item) => platformFlagMap[item]);
        const { content } = this.form;
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const links = doc.querySelectorAll('a');
        const hrefs = Array.from(links).map(function (link) {
          return link.getAttribute('href');
        });
        if (hrefs.length) {
          for (let i = 0; i < hrefs.length; i++) {
            const href = hrefs[i];
            const regex = /\[\(([a-zA-Z0-9]+)\).*?\]/g;
            let match;
            const plats = [];
            while ((match = regex.exec(href)) !== null) {
              plats.push(match[1]);
            }
            // 如果勾选了，而没有配对应平台的链接，则报错
            if (_.difference(platforms, plats).length) {
              return new Error('请确保为每个选中的终端填写对应的链接。');
            }
            if (_.difference(plats, platforms).length) {
              return new Error('请选择超链接对应的通知终端。');
            }
          }
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/form.scss';
@import '@/styles/element/checkbox.scss';
@import '@/styles/element/radio.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #fff;
  .form-title {
    display: flex;
    align-items: center;
    color: #1c2028;
    font-weight: 700;
    padding: 16px 0;
    .form-warning {
      padding-left: 6px;
      font-weight: 400;
      color: #e34d59;
    }
  }
  ::v-deep.el-form-item__label {
    width: 100px;
    color: #1c2028;
    font-weight: 400;
  }
  .form-input {
    width: 450px;
    margin-right: 16px;
  }
  .editor-type {
    margin: 4px 0;
    .radio-group {
      display: flex;
      .radio {
        margin-right: 16px;
      }
    }
  }
  .row {
    display: flex;
    align-items: start;
  }
  .form-action {
    margin-top: 40px;
  }
  .tip {
    color: #98a2b2;
    font-size: 12px;
  }
  .editor-block {
    display: flex;
    .editor-part {
      flex: 1;
      margin-right: 16px;
      .editor-title {
        margin-bottom: 8px;
      }
      .code-editor {
        border: 1px solid black;
        flex: 1;
      }
    }
  }
  .field-item {
    display: flex;
    margin-bottom: 10px;
    .field {
      margin-right: 10px;
    }
    .field-input {
      width: 390px;
      margin-right: 16px;
    }
  }
}

.preview-box {
  ::v-deep .el-dialog__body {
    padding-left: 20px;
  }
}
</style>
