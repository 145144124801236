<template>
  <div id="itemPanel" ref="itemPanel" :class="{ hidden: itemVisible }">
    <div class="icon-title">通用</div>
    <div class="icon-tool">
      <i
        v-for="(config, type) in normalNodeTypes"
        :key="type"
        draggable="true"
        :data-label="config.name"
        :data-shape="type"
        :class="['node', ...config.class]"
        :fill="getFill(config)"
        :width="getWidth(config)"
        :height="getHeight(config)"
        :description="config.description"
        :style="{ '--bgColor': getFill(config) }"
      >
        <div class="text">{{ config.name }}</div>
      </i>
      <i class="split" />
    </div>
    <div class="icon-title">网关</div>
    <div class="icon-tool">
      <i
        v-for="(config, type) in gatewayNodeTypes"
        :key="type"
        draggable="true"
        :data-label="config.name"
        :data-shape="type"
        :class="['node', ...config.class]"
        :fill="getFill(config)"
        :width="getWidth(config)"
        :height="getHeight(config)"
        :description="config.description"
        :style="{ '--bgColor': getFill(config) }"
      >
        <div class="text">{{ config.name }}</div>
      </i>
      <i class="split" />
    </div>
    <div class="icon-title">高级</div>
    <div class="icon-tool">
      <i
        v-for="(config, type) in advanceNodeType"
        :key="type"
        draggable="true"
        :data-label="config.name"
        :data-shape="type"
        :class="['node', ...config.class]"
        :fill="getFill(config)"
        :width="getWidth(config)"
        :height="getHeight(config)"
        :description="config.description"
        :style="{ '--bgColor': getFill(config) }"
      >
        <div class="text">{{ config.name }}</div>
      </i>
      <i class="split" />
    </div>
    <div class="icon-title">通知渠道</div>
    <div class="icon-tool">
      <i
        v-for="(config, type) in noticeChanneltypes"
        :key="type"
        draggable="true"
        :data-label="config.name"
        :data-shape="type"
        :class="['node', ...config.class]"
        :fill="getFill(config)"
        :width="getWidth(config)"
        :description="config.description"
        :height="getHeight(config)"
        :style="{ '--bgColor': getFill(config) }"
      >
        <div class="text">{{ config.name }}</div>
      </i>
      <i class="split" />
    </div>
    <div class="icon-title">边界事件</div>
    <div class="icon-event-tool">
      <template v-for="(config, type) in boundaryEventTypes">
        <div
          :key="type"
          class="node"
          draggable="true"
          :data-label="config.name"
          :data-shape="type"
          :fill="getFill(config)"
          :width="getWidth(config)"
          :height="getHeight(config)"
          :description="config.description"
          :style="{ '--bgColor': getFill(config) }"
        >
          <i :class="[...config.class]"></i>
        </div>
        <div class="text" :key="type + '1'">{{ config.name }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { nodeTypes } from './config.js';
import { getValue } from '@/utils/util';
export default {
  name: 'ItemPanel',
  props: {
    graph: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemVisible: false,
      img: {},
    };
  },
  computed: {
    normalNodeTypes() {
      const obj = JSON.parse(JSON.stringify(nodeTypes));
      delete obj.start_node;
      Object.keys(obj).forEach((key) => {
        if (obj[key].panelType) {
          delete obj[key];
        }
      });
      return obj;
    },
    advanceNodeType() {
      const obj = JSON.parse(JSON.stringify(nodeTypes));
      delete obj.start_node;
      Object.keys(obj).forEach((key) => {
        if (obj[key].panelType !== 'advance') {
          delete obj[key];
        }
      });
      return obj;
    },
    // 边界事件
    boundaryEventTypes() {
      const obj = JSON.parse(JSON.stringify(nodeTypes));
      delete obj.start_node;
      Object.keys(obj).forEach((key) => {
        if (obj[key].panelType !== 'boundary_event') {
          delete obj[key];
        }
      });
      return obj;
    },
    // 通知渠道
    noticeChanneltypes() {
      const obj = JSON.parse(JSON.stringify(nodeTypes));
      delete obj.start_node;
      Object.keys(obj).forEach((key) => {
        if (obj[key].panelType !== 'notice_channel') {
          delete obj[key];
        }
      });
      return obj;
    },
    // 网关
    gatewayNodeTypes() {
      const obj = JSON.parse(JSON.stringify(nodeTypes));
      delete obj.start_node;
      Object.keys(obj).forEach((key) => {
        if (obj[key].panelType !== 'gateway') {
          delete obj[key];
        }
      });
      return obj;
    },
  },
  mounted() {
    const icons = [];
    this.$refs.itemPanel.querySelectorAll('.icon-tool').forEach((item) => {
      icons.push(...item.querySelectorAll('.node'));
    });
    this.$refs.itemPanel.querySelectorAll('.icon-event-tool').forEach((item) => {
      icons.push(...item.querySelectorAll('.node'));
    });

    icons.forEach((icon) => {
      const shape = icon.getAttribute('data-shape');
      const width = icon.getAttribute('width');
      const height = icon.getAttribute('height');
      const description = icon.getAttribute('description');
      // 拖放虚影用图像代替元素
      if (nodeTypes[shape].dragImg) {
        this.img[shape] = new Image();
        this.img[shape].src = nodeTypes[shape].dragImg;
      }

      icon.addEventListener('dragstart', (event) => {
        const label = icon.getAttribute('data-label');
        /* 设置拖拽传输数据 */
        event.dataTransfer.setData(
          'dragComponent',
          JSON.stringify({
            label,
            fullname: label,
            shape,
            fill: icon.getAttribute('fill'),
            width,
            height,
            description,
            default_config: nodeTypes[shape].default_config,
          }),
        );
        if (this.img[shape]) {
          event.dataTransfer.setDragImage(this.img[shape], width / 2, height / 2);
        }
        if (nodeTypes[shape]?.comboTypes?.length) {
          const { comboTypes } = nodeTypes[shape];
          this.graph.getNodes().forEach((node) => {
            const nodeType = node.get('model').type;
            if (comboTypes.includes(nodeType)) {
              this.graph.setItemState(node, 'backgroundBreathing', true);
            }
          });
        }
      });
    });

    // 阻止默认动作
    document.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
      },
      false,
    );
  },
  methods: {
    getFill(config) {
      return getValue(config, '.defaultStyle.fill') || '#fff';
    },
    getWidth(config) {
      return getValue(config, '.defaultStyle.width') || 80;
    },
    getHeight(config) {
      return getValue(config, '.defaultStyle.height') || 80;
    },
  },
};
</script>

<style lang="scss" scoped>
#itemPanel {
  border-top: 1px solid #e5eaf2;
  position: absolute;
  top: 42px;
  bottom: 0;
  width: 240px;
  padding: 16px 24px;

  overflow-y: auto;
  background: #fff;
  //   padding-top: 65px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  &.hidden {
    transform: translate(-100%, 0);
  }

  .icon-h-drag {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    background: #f5f5f5;
    text-align: center;
    cursor: move;

    &:hover {
      background: #f1f1f1;
    }
  }

  .split {
    height: 1px;
    width: 100%;
    display: block;
    background: #e0e0e0;
  }

  .icon-title {
    color: #000;
    font-weight: 700;
  }
  .icon-tool {
    padding: 10px;
    margin: 16px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .node {
      display: block;
      margin-bottom: 16px;
      background-color: var(--bgColor);
      cursor: move;
      &::before {
        content: '';
      }
      .text {
        font-style: initial;
      }
    }
    .circle {
      height: 80px;
      line-height: 80px;
      border-radius: 50%;
      border: 1px solid #ccc;
      background: #eef5fe;
    }
    .warning {
      height: 40px;
      line-height: 40px;
      border-left: 4px solid #e6a23c;
      background: #f8ecda;
    }
    .end {
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 50%;
      border: 1px solid #e5eaf2;
    }
    .square {
      width: 160px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      border-radius: 4px;
    }
    .delay-setting {
      color: #000;
      border: 1px solid #e5eaf2;
    }
    .process {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      border: 1px solid #e5eaf2;
    }
    .rhombus {
      margin-bottom: 40px;
      text-align: center;
      line-height: 80px;
      position: relative;
      transform: rotate(45deg);
      width: 80px;
      height: 80px;
      color: #fff;
      .text {
        transform: rotate(-45deg);
      }
    }
    .oval {
      height: 50px;
      line-height: 50px;
      width: 80px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
    .blue {
      background-color: #ecf3ff;
    }
  }
  .icon-event-tool {
    padding: 10px;
    margin: 16px 0;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    .iconfont {
      font-size: 14px;
    }
    .text {
      font-size: 14px;
      line-height: 22px;
      color: #384050;
      margin-left: 10px;
    }
    .node {
      display: flex;
      align-items: center;
      border-bottom: 0;
    }
  }
}
</style>
