<template>
  <div>
    <el-dialog
      title="插入对象字段"
      :visible.sync="showDialog"
      width="700px"
      top="2%"
      :before-close="handleClose"
      append-to-body
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 对象 -->
        <el-tab-pane label="对象" name="object" v-loading="objectForm.loading">
          <div>
            <el-input
              class="search-input"
              v-model="objectForm.keyword"
              clearable
              placeholder="请输入想要选择的对象名称"
            >
              <i slot="suffix" class="el-input__icon el-icon-search" @click="searchObject" />
            </el-input>
          </div>
          <el-table
            :data="objectForm.list"
            row-key="id"
            height="450"
            border
            ref="objectTab"
            style="width: 100%; overflow: auto"
            lazy
            fit
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @row-click="handleSelectionChange"
          >
            <el-table-column align="center" :width="50 + 50 * treeHeight" label="选择">
              <template slot-scope="scope">
                <el-radio class="radio" v-model="objectForm.slectObjectId" :label="scope.row.id">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="表名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="tenant_id" label="租户id" />
            <el-table-column prop="package" label="包名" />
          </el-table>
        </el-tab-pane>
        <!-- 字段 -->
        <el-tab-pane
          label="字段"
          name="field"
          :disabled="!objectForm.slectObjectId"
          v-loading="fieldForm.loading"
        >
          <el-form
            :inline="true"
            ref="searchForm"
            :model="fieldForm"
            class="searchForm"
            @submit.native.prevent
            @keyup.enter.native="getFilderField"
          >
            <el-form-item label="" prop="serch">
              <el-input
                v-model="fieldForm.keyword"
                clearable
                placeholder="请输入"
                @clear="getFilderField"
              >
                <i slot="suffix" class="el-input__icon el-icon-search" @click="getFilderField" />
              </el-input>
            </el-form-item>
          </el-form>
          <el-table
            :data="fieldForm.filterList"
            height="450"
            border
            ref="fieldTab"
            style="width: 100%; overflow: auto"
          >
            <el-table-column align="center" width="55" label="选择">
              <template slot-scope="scope">
                <div>
                  <el-radio class="radio" v-model="fieldForm.slectFields" :label="scope.row">
                    &nbsp;
                  </el-radio>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column prop="name" label="字段名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="description" label="描述">
              <template slot-scope="scope">
                <span v-if="scope.row.description.length < 21">{{ scope.row.description }}</span>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="scope.row.description"
                  placement="top"
                >
                  <span>{{ scope.row.description.slice(0, 20) }}...</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="value_type" label="值类型" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div>已选字段</div>
      <div class="select-field-box">
        <el-tag v-if="fieldForm.slectFields" closable @close="delSelectField">
          {{ fieldForm.slectFields.name }}.{{ fieldForm.slectFields.display_name }}.{{
            fieldForm.slectFields.value_type
          }}
        </el-tag>
      </div>
      <template v-if="custom">
        <div class="select-title">输入自定义变量</div>
        <div class="custom-container">
          <el-input v-model="customName" placeholder="支持输入自定义变量" />
          <el-tooltip effect="dark" :content="customTip" placement="top-end">
            <i class="iconfont icon-question tip-icon" />
          </el-tooltip>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="showDialog = false">取消</el-button>
        <el-button type="primary" :disabled="!hasValue" @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    object: {
      type: [String, Array],
      default: '',
    },
    // 是否展示自定义变量
    custom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: 'object',
      objectForm: {
        loading: false,
        keyword: '',
        offset: 0,
        limit: 9999,
        sort: 'create_time desc',
        count: 0, // 总数
        slectObjectId: null, // 选择的对象id
        list: [], // 表格数据
      },
      fieldForm: {
        loading: false,
        list: [],
        keyword: '',
        filterList: [],
        //  {
        //   对象1id: [ ]
        //   对象2id: [ ]
        //  }
        slectFields: null,
      },
      allLoadObject: {},
      treeHeight: 1,
      // 自定义变量
      customName: '',
      // 自定义变量提示文案
      customTip:
        '当使用业务实体的主对象及关联对象都无法选择到期望插入的变量时，请使用输入自定义变量的方式，输入变量名，点击确定后，该自定义变量将自动嵌入到模板中。由系统约定逻辑进行转义',
    };
  },
  computed: {
    ...mapGetters({
      objectList: 'notice/getObjectList',
    }),
    allObjects() {
      return this.objectList.map((object) => ({
        ...object,
        hasChildren: object.relations?.length > 0,
      }));
    },
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('cancel', val);
      },
    },
    hasValue() {
      return (this.custom && this.customName) || this.fieldForm.slectFields?.name;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getObject();
      }
    },
  },
  created() {
    this.getObject();
  },
  methods: {
    ...mapActions({
      getObjectList: 'notice/requestObjectList',
    }),
    searchObject() {
      const currentObj = this.allObjects
        .filter((object) => object.name.includes(this.objectForm.keyword))
        .map((object) => {
          return {
            ...object,
            objectId: object.id,
            id: Math.random().toString(16),
            totalObject: object.name,
          };
        });
      this.objectForm.list = currentObj;
      currentObj.forEach((object) => {
        this.allLoadObject[object.id] = object;
      });
    },
    async getObject() {
      await this.getObjectList();
      let currentObj;
      if (_.isArray(this.object)) {
        currentObj = this.allObjects
          .filter((object) => this.object.includes(object.name))
          .map((object) => {
            return {
              ...object,
              objectId: object.id,
              id: Math.random().toString(16),
              totalObject: object.name,
            };
          });
        this.objectForm.list = currentObj;
        currentObj.forEach((object) => {
          this.allLoadObject[object.id] = object;
        });
      } else if (this.object !== '') {
        currentObj = this.allObjects.find((object) => object.name === this.object);
        currentObj = {
          ...currentObj,
          objectId: currentObj.id,
          id: Math.random().toString(16),
          totalObject: currentObj.name,
        };
        this.allLoadObject[currentObj.id] = currentObj;
        this.objectForm.list = [currentObj];
      }
    },
    handleClose() {
      this.showDialog = false;
    },
    handleClick(tab) {
      if (tab.paneName === 'field') {
        this.searchFieldList();
        return;
      }
      this.$nextTick(() => {
        this.fieldForm.list = [];
      });
    },
    // --- 对象 ---
    async load(tree, treeNode, resolve) {
      console.log(tree, treeNode);
      const len = tree?.relations?.length || 0;
      const newTree = [];
      for (let i = 0; i < len; i++) {
        const relation = tree.relations[i];

        // 只展示默认和一对一关联对象
        if (relation.relation_type === 'DEFAULT' || relation.relation_type === 'ONE_TO_ONE') {
          let subObject = this.allObjects.find((object) => {
            return object.name === relation?.target_object;
          });
          if (subObject) {
            const totalObject = tree.totalObject
              ? `${tree.totalObject}.${relation.virtual_field}`
              : `${tree.name}.${relation.virtual_field}`;
            subObject = {
              ...subObject,
              objectId: subObject.id,
              id: Math.random().toString(16),
              name: relation.virtual_field,
              totalObject,
            };
            newTree.push(subObject);
            this.allLoadObject[subObject.id] = subObject;
          }
        }
      }
      const treeHeight = (tree.totalObject || '')?.split('.')?.length || 1;
      this.treeHeight = treeHeight;
      console.log('===newTree===', newTree);
      resolve(newTree);
    },
    getAllRelations(objects, parentObjectName = '', relations = []) {
      const currentObject = objects.find((object) => object.name === parentObjectName);
      relations.push(currentObject.name);
      console.log('relations', parentObjectName, currentObject, currentObject.relations);
      if (currentObject.relations) {
        const subRelations = currentObject.relations.reduce((pre, relation) => {
          if (relation.target_object !== parentObjectName) {
            pre.push(this.getAllRelations(objects, relation.target_object, relations));
          }
          return pre;
        }, []);
        return subRelations;
      }
      return currentObject;
    },
    handleSelectionChange(row) {
      this.objectForm.slectObjectId = row.id;
    },
    // --- 字段 ---
    searchFieldList() {
      this.getFieldList();
    },
    async getFieldList() {
      try {
        this.fieldForm.loading = true;
        // const { keyword } = this.fieldForm;
        const { fields } = await this.$request({
          url: this.$api.metaGetObjectFields,
          data: {
            // keyword,
            object_id: this.allLoadObject[this.objectForm.slectObjectId]?.objectId,
          },
        });
        this.fieldForm.list = fields;
        this.getFilderField();

        // 已勾选数据处理
        this.$nextTick(() => {
          this.fieldForm.loading = false;
          this.setSelect();
        });
      } catch (err) {
        console.log(err);
        this.$nextTick(() => {
          this.fieldForm.loading = false;
        });
      }
    },
    getFilderField() {
      this.fieldForm.filterList = this.fieldForm.list.filter((item) => {
        return item.name.includes(this.fieldForm.keyword);
      });
    },
    setSelect() {
      const oldData = this.fieldForm.slectFields?.[this.objectForm.slectObjectId];
      if (!oldData) {
        return;
      }
      const slectFieldId = oldData.map((row) => {
        return row.objectId;
      });
      const selectData = this.fieldForm.list.filter((item) => {
        return slectFieldId.includes(item.objectId);
      });
      if (selectData && selectData.length) {
        selectData.forEach((row) => {
          this.$refs.fieldTab.toggleRowSelection(row);
        });
      }
    },
    // 删除已选择的数据
    delSelectField() {
      this.fieldForm.slectFields = null;
    },
    save() {
      if (this.custom && this.customName && this.customName.includes('.')) {
        this.$message.warning('自定义变量不可以使用.');
        return;
      }

      if (this.fieldForm.slectFields?.name) {
        const totalObject = this.allLoadObject[this.objectForm.slectObjectId]?.totalObject;
        this.$emit('insertion', {
          type: 'metadata',
          str: `${totalObject}.${this.fieldForm.slectFields.name}`,
        });
      }

      if (this.custom && this.customName) {
        // 避免两次事件同时处理
        this.$nextTick(() => {
          this.$emit('insertion', {
            type: 'custom',
            str: this.customName,
          });
          this.handleClose();
        });
      } else {
        this.handleClose();
      }
    },
  },
};
</script>

<style lang="scss">
// tooltip为根元素 需要单独添加非scoped的样式
@import '@/styles/element/tooltip.scss';
</style>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/dialog.scss';

.select-field-box {
  min-height: 54px;
  max-height: 304px;
  border: 1px solid #e5eaf2;
  border-radius: 4px;
  margin-top: 8px;
  overflow-y: auto;
  ::v-deep {
    .el-tag {
      margin: 3px;
    }
  }
}
.select-title {
  margin: 8px 0;
}
.custom-container {
  display: flex;
  align-items: center;
  .tip-icon {
    margin: 0 8px;
  }
}
.search-input {
  width: 300px;
  margin-bottom: 16px;
}
</style>
