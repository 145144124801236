<template>
  <div>
    <t-tabs v-model="tab">
      <t-tab-panel label="角色管理" value="1">
        <role v-if="tab === '1'"></role>
      </t-tab-panel>
      <t-tab-panel label="权限包管理" value="2">
        <permission-package v-if="tab === '2'"></permission-package>
      </t-tab-panel>
    </t-tabs>
  </div>
</template>

<script>
import Role from './tabs//role.vue';
import PermissionPackage from './tabs/permission-package.vue';
export default {
  components: {
    Role,
    PermissionPackage,
  },
  data() {
    return {
      tab: '1',
    };
  },
  methods: {},
};
</script>

<style lang="scss"></style>
