<template>
  <div>
    <div class="intro-title">基础信息</div>
    <div class="node-title">节点ID</div>
    <el-input disabled :value="value.id" />
    <div class="node-title">
      节点名称
      <span class="require">*</span>
    </div>
    <el-input
      :value="value.fullname"
      maxlength="20"
      show-word-limit
      @input="(e) => valueUpdate('fullname', e)"
    />
    <div class="node-title">
      服务号
      <span class="require">*</span>
    </div>
    <el-select
      :value="value.metadata.app_id"
      placeholder="请选择"
      @change="(e) => serviceAccountChange(e)"
      style="margin-bottom: 8px"
    >
      <el-option
        v-for="item in serviceAcountList"
        :key="item.appid"
        :label="item.app_name"
        :value="item.appid"
      />
    </el-select>
    <div class="node-title">备注描述</div>
    <el-input
      type="textarea"
      :rows="2"
      :value="value.description"
      @input="(e) => valueUpdate('description', e)"
      maxlength="50"
      autocomplete="off"
      placeholder="请输入"
    ></el-input>
    <div class="intro-title">推送信息</div>
    <div class="node-title">
      接收人
      <span class="require">*</span>
    </div>
    <el-radio
      :value="value.metadata.receiver_type"
      label="var"
      @input="(e) => valueUpdate('metadata.receiver_type', e)"
    >
      变量
    </el-radio>
    <el-input
      style="margin-top: 5px"
      v-if="value.metadata.receiver_type === 'var'"
      :value="value.metadata.to[0]"
      placeholder="插入变量"
      @focus="openVariantDia"
    />
    <div class="node-title">
      模版消息
      <span class="require">*</span>
    </div>
    <div style="display: flex; align-items: center">
      <el-select
        :value="value.metadata.template_id"
        placeholder="请选择"
        @change="(e) => accountTemplateChange(e)"
        style="margin-bottom: 8px; flex: 1"
        :disabled="!value.metadata.app_id"
      >
        <el-option
          v-for="item in serviceTemplateList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </el-select>
      <el-button
        size="mini"
        type="text"
        @click="dialogVisible = true"
        :disabled="!value.metadata.template_id"
        style="margin-left: 10px"
      >
        预览模版
      </el-button>
    </div>
    <div class="node-title" v-if="value.metadata.template_id">
      参数设置
      <span class="require">*</span>
    </div>
    <el-form label-position="right" label-width="80px" v-if="value.metadata.template_id">
      <template v-for="(title, key) in templateParams">
        <el-form-item :label="title" :key="key">
          <el-input v-model="params[key].value"></el-input>
        </el-form-item>
      </template>
    </el-form>
    <div class="node-title">
      跳转设置
      <span class="require">*</span>
    </div>
    <el-radio :value="value.metadata.link_type" :label="0" @input="jumpConifgChange">无</el-radio>
    <el-radio :value="value.metadata.link_type" :label="1" @input="jumpConifgChange">链接</el-radio>
    <el-radio :value="value.metadata.link_type" :label="2" @input="jumpConifgChange">
      小程序
    </el-radio>
    <div v-if="value.metadata.link_type === 1">
      <div class="node-title">
        链接地址
        <span class="require">*</span>
        <el-input
          placeholder="请输入内容"
          v-model="urlPath"
          @input="(e) => updateUrlLinkInputUrl(e)"
        >
          <el-select
            v-model="protocolSelect"
            slot="prepend"
            placeholder="请选择"
            @change="(e) => updateUrlLinkPrototaSelect(e)"
            style="width: 75px"
          >
            <el-option label="https://" value="https://"></el-option>
            <el-option label="http://" value="http://"></el-option>
          </el-select>
        </el-input>
      </div>
    </div>
    <div v-if="value.metadata.link_type === 2">
      <div class="node-title">
        小程序AppID
        <span class="require">*</span>
        <el-input
          placeholder="请输入"
          :value="value.metadata.mp_app_id"
          @input="(e) => valueUpdate('metadata.mp_app_id', e)"
        ></el-input>
      </div>
      <div class="node-title">
        小程序路径
        <span class="require">*</span>
        <el-input
          placeholder="请输入"
          :value="value.metadata.jump_url"
          @input="(e) => valueUpdate('metadata.jump_url', e)"
        ></el-input>
      </div>
    </div>
    <div class="node-title">
      勿扰设置
      <el-tooltip
        effect="dark"
        content="该设置可控制通知渠道的各通知节点的推送设置，默认勿扰开关是关闭状态，如果开启后，在指定时间范围内，即使流程触发了通知推送，也会停止消息推送。"
        placement="top"
      >
        <i class="iconfont icon-question" />
      </el-tooltip>
    </div>
    <el-switch
      style="margin-bottom: 8px"
      :value="value.metadata.setting.disturb_enbaled"
      @change="(e) => disturbEnbaleChange(e)"
    ></el-switch>
    <div class="time-wrapper" v-if="value.metadata.setting.disturb_enbaled">
      用户勿扰时间段
      <el-time-select
        placeholder="起始时间"
        :value="value.metadata.setting.disturb.from"
        :picker-options="{
          start: '00:00',
          step: '00:15',
          end: '24:00',
        }"
        @input="(e) => valueUpdate('metadata.setting.disturb.from', e)"
        style="width: 160px; margin: 0 8px"
      ></el-time-select>
      至
      <el-time-select
        placeholder="结束时间"
        :value="value.metadata.setting.disturb.to"
        :picker-options="{
          start: '00:00',
          step: '00:15',
          end: '24:00',
        }"
        @input="(e) => valueUpdate('metadata.setting.disturb.to', e)"
        style="width: 160px; margin: 0 8px"
      ></el-time-select>
    </div>
    <div style="display: flex; align-items: center" v-if="value.metadata.setting.disturb_enbaled">
      该时间段内触发规则
      <el-select
        :value="value.metadata.setting.trigger_rule"
        placeholder="请选择"
        style="margin-left: 8px; width: 390px"
        disabled
      >
        <el-option
          v-for="item in [{ label: '放弃本次推送', value: 1 }]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="diaBeforeClose">
      <div class="dia-wrapper">
        <div class="card-wrapper">
          <div class="title">{{ templateTitle }}</div>
          <div class="params-wrapper" v-for="(title, key) in templateParams" :key="key">
            <span class="label">{{ title + ':' }}</span>
            {{ key }}
          </div>
          <div class="detail-wrapper">查看详情</div>
        </div>
      </div>
    </el-dialog>
    <template v-if="varDiaShow">
      <notice-varibale-dialog
        :show="varDiaShow"
        :variable-text="variableText"
        @cancel="(e) => (varDiaShow = e)"
        @insertion="(e) => insertVariable(e)"
        :var-type="varType"
      />
    </template>
  </div>
</template>
<script>
import noticeVaribaleDialog from '../../components/noticeVaribaleDialog.vue';
export default {
  name: '',
  components: {
    noticeVaribaleDialog,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      serviceAcountList: [],
      serviceTemplateList: [],
      templateInfo: [],
      params: {},
      dialogVisible: false,
      protocolSelect: 'http://',
      urlPath: '',
      varType: '', // var title content
      varDiaShow: false,
    };
  },
  computed: {
    variableText() {
      if (this.varType === 'var') {
        return this.value.metadata.to[0] || '';
      }
      return '';
    },
    templateTitle() {
      return (
        this.serviceTemplateList.find((template) => template.id === this.value.metadata.template_id)
          ?.title || ''
      );
    },
    templateParams() {
      return (
        this.serviceTemplateList.find((template) => template.id === this.value.metadata.template_id)
          ?.fields || {}
      );
    },
  },
  watch: {
    params: {
      handler(newVal) {
        if (typeof newVal === 'object') {
          this.valueUpdate('metadata.params', JSON.stringify(newVal));
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.value.metadata.params && typeof this.value.metadata.params === 'string') {
      this.params = JSON.parse(this.value.metadata.params);
    }
    this.getServiceAcountList();
    if (this.value.metadata.app_id) {
      this.getOfficialAccountTemplateList(this.value.metadata.app_id);
    }
    const protocolReg = /(http|https):\/\//gi;
    const matchRes = (this.value.metadata?.jump_url || '').match(protocolReg);
    if (matchRes) {
      this.protocolSelect = matchRes[0];
      this.urlPath = this.value.metadata?.jump_url.replace(matchRes[0], '');
    } else {
      this.urlPath = this.value.metadata?.jump_url;
    }
  },
  methods: {
    openVariantDia() {
      this.varType = 'var';
      this.varDiaShow = true;
    },
    insertVariable(e) {
      switch (this.varType) {
        case 'var':
          this.valueUpdate('metadata.to', [e]);
          return;
      }
    },
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
    getServiceAcountList() {
      this.$request({
        url: this.$api.getWxAppList,
        data: {
          page: 1,
          page_size: 99999,
        },
        cb: ({ data }) => {
          this.serviceAcountList = data.filter((item) => item.type === 2);
        },
      });
    },
    getOfficialAccountTemplateList(app_id) {
      this.$request({
        url: this.$api.GetOfficialAccountTemplateList,
        data: {
          app_id,
        },
        cb: ({ templates }) => {
          this.serviceTemplateList = templates;
        },
      });
    },
    serviceAccountChange(e) {
      this.valueUpdate('metadata.app_id', e);
      this.valueUpdate('metadata.template_id', '');
      // 查找 templateInfo 1. 设置 serviceTemplateList 2. 清空Params
      // this.serviceTemplateList = [];
      this.params = {};
      this.getOfficialAccountTemplateList(e);
    },
    accountTemplateChange(e) {
      this.valueUpdate('metadata.template_id', e);

      // 根据选择的模版 从新设置 模版列表
      const fields = this.serviceTemplateList.find((template) => template.id === e)?.fields || {};
      const params = {};
      for (const key in fields) {
        params[key] = { value: '', color: '#173177' };
      }
      this.params = params;
    },
    jumpConifgChange(e) {
      this.valueUpdate('metadata.link_type', e);
      // 数据重置
      this.protocolSelect = 'http://';
      this.urlPath = '';
      this.valueUpdate('metadata.mp_app_id', '');
      this.valueUpdate('metadata.jump_url', '');
    },
    updateUrlLinkInputUrl(e) {
      if (e) {
        this.valueUpdate('metadata.jump_url', this.protocolSelect + e);
      } else {
        this.valueUpdate('metadata.jump_url', '');
      }
    },
    updateUrlLinkPrototaSelect(e) {
      if (this.urlPath) {
        this.valueUpdate('metadata.jump_url', e + this.urlPath);
      } else {
        this.valueUpdate('metadata.jump_url', '');
      }
    },
    disturbEnbaleChange(e) {
      if (!e) {
        this.valueUpdate('metadata.setting.disturb.from', '');
        this.valueUpdate('metadata.setting.disturb.to', '');
      } else {
        this.valueUpdate('metadata.setting.disturb.from', '22:00');
        this.valueUpdate('metadata.setting.disturb.to', '07:00');
      }

      this.valueUpdate('metadata.setting.disturb_enbaled', e);
    },
    diaBeforeClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
.intro-title {
  height: 40px;
  line-height: 40px;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 14px;
  color: #667082;
  margin: 10px -10px 0;
  padding-left: 10px;
}
.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.el-input--prefix {
  ::v-deep .el-input__inner {
    padding: 0 30px;
  }
}
.dia-wrapper {
  padding: 10px;
  background: #f6f7fb;
  .card-wrapper {
    background: #fff;
    .title {
      padding: 8px;
      font-size: 14px;
      color: black;
    }
    .params-wrapper {
      padding: 8px;
      font-size: 12px;
      color: black;
      .label {
        color: black;
        margin-right: 10px;
      }
    }
    .detail-wrapper {
      padding: 8px;
      border-top: 1px solid #f6f7fb;
      color: black;
      font-size: 14px;
    }
  }
}
</style>
