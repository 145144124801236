<template>
  <div class="version-number-box">
    <div class="input">
      <!-- <div class="joint">
        {{ `${env}-${tenement}${app ? `-${app}` : ''}-${date}-${operator}-` }}
      </div>
      <el-input @input="changeVersion" v-model="version" placeholder="请输入" class="version" /> -->
      <el-input placeholder="请输入" v-model="version" @input="changeVersion">
        <template slot="prepend">
          {{ `${env}-${tenement}${app ? `-${app}` : ''}-${date}-${operator}-` }}
        </template>
      </el-input>
    </div>
    <div class="tips">填写参考：22R4(SP1-SP3) , 22R4, 22R4(紧急迭代1)</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config';
const getTime = () => {
  const nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth() + 1 < 10 ? `0${nowDate.getMonth() + 1}` : nowDate.getMonth() + 1;
  const day = nowDate.getDate() < 10 ? `0${nowDate.getDate()}` : nowDate.getDate();
  return `${year}${month}${day}`;
};
export default {
  props: {
    app: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      env: config.env,
      date: getTime(),
      version: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getUserInfo', 'getTenantInfo']),
    operator() {
      return this.getUserInfo.staffname;
    },
    tenement() {
      return `T${this.getTenantInfo.id}`;
    },
    str() {
      const { env, tenement, app, date, operator, version } = this;
      if (!version) {
        return '';
      }
      const str = `${env}-${tenement}${app ? `-${app}` : ''}-${date}-${operator}-${version}`;
      return str;
    },
  },
  methods: {
    changeVersion() {
      this.$emit('change', this.str);
    },
  },
};
</script>

<style lang="scss" scoped>
.version-number-box {
  .input {
    display: flex;
    .version {
      flex: 1;
    }
  }
  .tips {
    color: #aaaaaa;
  }
}
</style>
