<template>
  <el-card class="table-container">
    <div class="top">
      <div class="search-form">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchObj"
          class="searchForm"
          @submit.native.prevent
          @keyup.enter.native="loadListData"
        >
          <el-form-item label="选择产品/应用：" prop="appid">
            <el-select v-model="searchObj.appid" placeholder="应用" @change="loadListData">
              <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input
              v-model="searchObj.keyword"
              clearable
              placeholder="请输入名称/编码"
              @clear="loadListData"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button icon="el-icon-search" type="primary" @click="loadListData">搜索</el-button>
            <el-button @click="resetForm">重 置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="float-right">
        <el-button
          class="add-button"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd()"
          v-if="jurisdiction.jobManage"
        >
          添加
        </el-button>
      </div>
    </div>

    <t-enhanced-table
      ref="tTable"
      row-key="id"
      :columns="columns"
      :data="filterListData"
      :tree="{ childrenKey: 'children', treeNodeColumnIndex: 0 }"
      :max-height="tableHeight"
      :scroll="{ type: 'virtual', bufferSize: 30 }"
      :expanded-tree-nodes.sync="expandedRowKeys"
      table-layout="auto"
    >
      <template #treeExpandAndFoldIcon="{ type }">
        <div v-if="type === 'expand'">
          <i class="el-icon-arrow-right" />
        </div>
        <div v-if="type === 'fold'">
          <i class="el-icon-arrow-down" />
        </div>
      </template>
      <template #name="{ row }">
        <div :class="{ 'text-highlight': row.name.includes(highlightKeyword) && highlightKeyword }">
          {{ row.name }}
        </div>
      </template>
      <template #code="{ row }">
        <div :class="{ 'text-highlight': row.code.includes(highlightKeyword) && highlightKeyword }">
          {{ row.code }}
        </div>
      </template>
      <template #operation="{ row, rowIndex }">
        <el-button
          size="mini"
          type="text"
          @click="handleSubAdd(rowIndex, row)"
          v-if="jurisdiction.jobManage"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleEdit(rowIndex, row)"
          v-if="jurisdiction.jobManage"
        >
          编辑
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleRole(rowIndex, row, 'role')"
          v-if="jurisdiction.jobManage"
        >
          角色
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleRole(rowIndex, row, 'perm_set')"
          v-if="jurisdiction.jobManage"
        >
          权限包
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleDelete(rowIndex, row)"
          v-if="jurisdiction.jobManage"
        >
          删除
        </el-button>
      </template>
    </t-enhanced-table>
    <el-dialog
      :title="distributeDialogTitle"
      :visible.sync="roleDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-tree
        :data="roleData"
        show-checkbox
        default-expand-all
        node-key="id"
        :check-strictly="true"
        ref="roleTree"
        :default-checked-keys="[5]"
        :props="defaultRoleProps"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisable"
          @click="saveRole"
          v-if="jurisdiction.jobManage"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="岗位名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="Code" prop="code">
          <el-input v-model="formObj.code" :disabled="operation == 'update'"></el-input>
        </el-form-item>
        <el-form-item label="父级岗位" prop="parent_code">
          <template>
            <el-select
              v-model="formObj.parent_code"
              placeholder="请选择"
              :disabled="operation == 'update'"
            >
              <el-option
                v-for="item in jobOptions"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="岗位描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{3,30}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为3到30'));
      }
      callback();
    };

    return {
      operation: 'create',
      listData: [],
      filterListData: [],
      jobOptions: [],
      roleData: [],
      count: 0,
      appOptions: [],
      formObj: {},
      operateJobId: 0,
      oldSearchObj: {},
      searchObj: {
        appid: Number(this.$route.query.appid) || 0,
        keyword: '',
      },
      highlightKeyword: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入角色Code',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      defaultRoleProps: {
        children: 'children',
        label: 'name',
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
      tableHeight: '',
      roleType: '',
      expandedRowKeys: [],
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        jobManage: this.getElementList.includes('job-manage'),
      };
    },
    columns() {
      const hasPermission = this.jurisdiction.jobManage;
      return [
        {
          colKey: 'name',
          title: '名称',
          minWidth: 240,
          fixed: 'left',
        },
        {
          colKey: 'id',
          title: 'ID',
          minWidth: 140,
        },
        {
          colKey: 'code',
          title: 'Code',
          minWidth: 140,
        },
        {
          colKey: 'description',
          title: '描述',
          minWidth: 300,
        },
        {
          colKey: 'create_by',
          title: '创建人',
          minWidth: 120,
        },
        {
          colKey: 'update_by',
          title: '最后修改人',
          minWidth: 120,
        },
        {
          colKey: 'update_time',
          title: '修改时间',
          minWidth: 220,
        },
        {
          colKey: 'create_time',
          title: '创建时间',
          minWidth: 220,
        },
        {
          colKey: 'operation',
          title: '操作',
          minWidth: hasPermission ? 300 : 100,
        },
      ];
    },
    distributeDialogTitle() {
      return this.roleType === 'role' ? '角色分配' : '权限包分配';
    },
  },
  async mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    await this.loadAppOption();
    this.loadListData();
    // this.loadRoleData();
    const totalHeight = document.body.clientHeight;
    const formHeight = document.querySelector('.top').clientHeight;
    const navBarHeight = document.querySelector('.navbar').clientHeight;
    this.tableHeight = `${totalHeight - formHeight - navBarHeight - 60}px`;
    console.log('---tableHeight---', this.tableHeight);
  },
  methods: {
    async loadListData() {
      this.highlightKeyword = this.searchObj.keyword;
      let isFilter = false;
      if (this.searchObj.appid !== this.oldSearchObj.appid) {
        const url = this.$api.getJobTree;
        const data = {
          appid: this.searchObj.appid,
        };
        const resp = await this.$request({ url, data });
        this.oldSearchObj = {
          ...this.searchObj,
        };
        this.count = resp.total;
        this.listData = resp.data;
        this.filterListData = this.filterListNew(this.listData, this.searchObj);
        this.loadJobOption();
      }
      if (this.searchObj.keyword !== this.oldSearchObj.keyword) {
        isFilter = true;
        this.expandedRowKeys = [];
        this.filterListData = this.filterListNew(this.listData, this.searchObj);
        await this.$nextTick();
        this.$refs.tTable.expandAll();
        this.oldSearchObj = {
          ...this.searchObj,
        };
      }
      this.$nextTick(() => {
        // this.$refs.tTable.expandAll();.
        if (!isFilter) {
          this.expandedRowKeys = [];
          this.filterListData.forEach((item1) => {
            if (item1.children.length > 0) {
              this.expandedRowKeys.push(item1.id);
            }
            item1.children.forEach((item2) => {
              if (item2.children.length > 0) {
                this.expandedRowKeys.push(item2.id);
              }
            });
          });
        }
      });
    },
    resetForm() {
      this.searchObj = {
        appid: this.appOptions[0]?.id || 0,
        keyword: this.$route.query.keyword || '',
      };
      this.loadListData();
    },
    filterList(tree, { keyword = '' }) {
      return tree.filter((item) => {
        const condition = [item.name, item.code].find((val) => {
          return val.includes(keyword);
        });
        // 显示状态
        if (condition) {
          return true;
        }
        if (item.children && item.children.length > 0) {
          return this.filterList(item.children, { keyword }).length > 0;
        }
        return false;
      });
    },
    filterListNew(arr, { keyword = '' }) {
      // 递归函数，用于遍历和筛选匹配的项
      const filterAndClone = (item) => {
        // 深拷贝当前节点，但不包括其子节点
        const newItem = cloneDeep(item);

        // 递归地筛选子节点，只有当子节点或其子节点包含关键字时才添加
        const children = item.children.map(filterAndClone).filter((child) => child !== null);

        // 如果当前节点名包含关键字或者有子节点被添加，则返回新的节点
        const condition = [item.name, item.code].find((val) => {
          return val.includes(keyword);
        });
        if (condition || children.length > 0) {
          if (condition) {
            newItem.children = item.children;
            return newItem;
          }
          newItem.children = children;
          return newItem;
        }

        // 否则，表示当前节点及其子节点都不包含关键字
        return null;
      };

      // 对数组中的每一项应用筛选逻辑，并过滤掉空结果
      return arr.map(filterAndClone).filter((item) => item !== null);
    },
    loadJobOption() {
      if (this.jobOptions.length > 0) return;
      const url = this.$api.getJobList;
      const data = Object.assign({}, this.searchObj);
      data.page_size = 99999;
      const cb = (data) => {
        this.jobOptions = data.data;
        this.jobOptions.push({
          id: 0,
          name: 'Root',
          code: 'root',
        });
      };
      this.$request({ url, data, cb });
    },
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
        this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateJob : this.$api.createJob;
        const msg = id ? '修改成功' : '创建成功';

        let data = {};
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            description: this.formObj.description,
          };
        } else {
          data = {
            parent_code: this.formObj.parent_code,
            name: this.formObj.name,
            code: this.formObj.code,
            description: this.formObj.description,
            appid: this.searchObj.appid,
          };
        }
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteJob;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { openType: 0, hidden: 0, parent_code: 'root' };
      this.opDialogVisible = true;
    },
    handleSubAdd(index, data) {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { openType: 0, hidden: 0, parent_code: data.code };
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该岗位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    loadRoleData(type) {
      if (type !== 'perm_set') {
        const url = this.$api.getRoleTree;
        const data = { appid: this.searchObj.appid };
        const cb = (data) => {
          this.roleData = data.data;
        };
        this.$request({ url, data, cb });
      } else {
        const url = this.$api.getRoleList;
        const data = { appid: this.searchObj.appid, type };
        const cb = (data) => {
          this.roleData = data.data;
        };
        this.$request({ url, data, cb });
      }
    },
    loadRoleCheckData(type) {
      const url = this.$api.getJobRole;
      const data = {
        job_id: this.operateJobId,
        type,
      };
      const cb = (data) => {
        this.$refs.roleTree.setCheckedKeys(data.data.map((v) => v.id));
      };
      this.$request({ url, data, cb });
    },
    async handleRole(index, data, type) {
      this.operateJobId = data.id;
      this.roleType = type;
      await this.loadRoleData(type);
      await this.loadRoleCheckData(type);
      this.roleDialogVisible = true;
    },
    saveRole() {
      const url = this.$api.updateJobRole;
      const type = 'post';

      const nodes = this.$refs.roleTree.getCheckedNodes();

      const data = {
        job_id: this.operateJobId,
        role_ids: nodes.map((v) => v.id),
        type: this.roleType,
      };
      const cb = () => {
        this.roleDialogVisible = false;
        this.$message({
          message: `保存${this.roleType === 'role' ? '角色' : '权限包'}成功`,
          type: 'success',
        });
        // this.loadListData();
      };
      this.$request({ url, cb, type, data });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';

.top {
  display: flex;
  .search-form {
    flex: 1;
    display: flex;
    .form-item {
      width: 232px;
      margin-right: 8px;
    }
  }
}
::v-deep .t-table__header tr {
  th {
    background-color: rgba(246, 247, 251, 1);
    color: #667082;
    font-weight: 400;
  }

  border-bottom: 1px solid #ebeef5;
}

.text-highlight {
  color: #0077ff;
}

::v-deep .t-table__body tr {
  background-color: #fff;
  border-bottom: 1px solid #ebeef5;
}
</style>
