module.exports.nullString = (val) => {
  if (!val) {
    return '无';
  }
  return val;
};

module.exports.operationName = (val) => {
  switch (val) {
    case 'create':
      return '创建';
      break;
    case 'update':
      return '更新';
      break;
    default:
      return '其他';
      break;
  }
};

module.exports.textTrueFalse = (val) => {
  if (val) {
    return '是';
  }
  return '否';
};

module.exports.textTrueFalseV2 = (val) => {
  if (val == 1) {
    return '是';
  }
  return '否';
};

module.exports.formatDate = (val) => {
  let str = val;
  if (!val.includes('T')) {
    str = val.replace(/\s/g, 'T');
  }
  const match = str.match(`[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}`);
  if (match) {
    return match[0].replace('T', ' ');
  }
  return '无';
};

module.exports.groupName = (val, options) => {
  for (const key in options) {
    const item = options[key];
    if (val === item.id) {
      return item.groupName;
    }
  }
  return '无';
};

module.exports.OptionListName = (val, options) => {
  for (const key in options) {
    const item = options[key];
    if (val === item.id) {
      return item.name;
    }
  }
  return '无';
};

module.exports.name = (val, options) => {
  for (const key in options) {
    const item = options[key];
    if (val === item.id) {
      return item.name;
    }
  }
  return '无';
};

module.exports.openTypeName = (val) => {
  let name = '';
  switch (val) {
    case 0: {
      name = '默认';
      break;
    }
    case 1: {
      name = '新页面';
      break;
    }
    case 2: {
      name = '当前页';
      break;
    }
  }
  return name;
};

module.exports.userStatus = (val) => {
  let statusName = '';
  switch (val) {
    case 0: {
      statusName = '在职中';
      break;
    }
    case 1: {
      statusName = '被冻结';
      break;
    }
    case 2: {
      statusName = '已离职';
      break;
    }
  }
  return statusName;
};
