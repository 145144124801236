<template>
  <div class="basic-form">
    <div class="title">基础信息</div>
    <el-form :model="value" label-width="100px" ref="form">
      <el-form-item
        class="form-item"
        label="产品应用:"
        prop="app_id"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <el-select
          class="form-input"
          :value="value.app_id"
          placeholder="请选择"
          disabled
          @change="(e) => updateData(e, 'app_id')"
        >
          <el-option
            v-for="obj in appList"
            :key="obj.label"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="名称:"
        prop="edit_process_name"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-input
          class="form-input"
          :value="value.edit_process_name"
          autocomplete="off"
          placeholder="请输入"
          @input="(e) => updateData(e, 'edit_process_name')"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="流程key:"
        prop="process_key"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-input
          disabled
          class="form-input"
          :value="value.process_key"
          autocomplete="off"
          maxlength="25"
          placeholder="请输入"
          @input="(e) => updateData(e, 'process_key')"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="业务模块:" prop="module">
        <el-select
          class="form-input"
          :value="value.module"
          placeholder="请选择"
          disabled
          @change="(e) => updateData(e, 'module')"
        >
          <el-option
            v-for="obj in businessModuleList"
            :key="obj.value"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="业务类型:"
        prop="biz_type"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <el-select
          class="form-input"
          :value="value.biz_type"
          placeholder="请选择"
          @change="(e) => updateData(e, 'biz_type')"
        >
          <el-option
            v-for="obj in generateBusinessTypeList"
            :key="obj.value"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="form-item"
        label="系统预置:"
        prop="preset"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <el-radio :value="value.preset" :label="true" @input="(e) => updateData(e, 'preset')">
          是
        </el-radio>
        <el-radio :value="value.preset" :label="false" @input="(e) => updateData(e, 'preset')">
          否
        </el-radio>
      </el-form-item>
      <el-form-item class="form-item" label="备注:">
        <el-input
          class="form-input"
          type="textarea"
          :rows="2"
          :value="value.edit_process_desc"
          maxlength="50"
          show-word-limit
          placeholder="请输入"
          autocomplete="off"
          @input="(e) => updateData(e, 'edit_process_desc')"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'WorkflowDetailBasic',
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      businessTypeList: 'workflow/getBusinessTypeList',
      businessModuleList: 'workflow/getBusinessModuleList',
      appList: 'workflow/getAppList',
    }),
    generateBusinessTypeList() {
      return [
        ...this.businessTypeList,
        { label: '统一使用DCR业务类型变量', value: 'custom_variable' },
      ];
    },
  },
  created() {
    this.getAppList();
  },
  methods: {
    ...mapActions({
      getAppList: 'workflow/requestAppList',
    }),
    // 用于保存时外部调用检查
    checkData(callback) {
      this.$refs.form.validate(callback);
    },
    updateData(e, key) {
      this.$emit('update', {
        ...this.value,
        [key]: e,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/select.scss';
@import '@/styles/element/input.scss';

.basic-form {
  margin: 16px;
  padding: 16px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 16px;
    color: #1c2028;
    font-weight: 700;
  }
  .form-item {
    margin-top: 16px;
    .form-input {
      width: 480px;
    }
  }
}
</style>
