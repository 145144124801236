<template>
  <div>
    <el-dialog title="选择函数" :visible.sync="selectCloudVisable">
      <div style="margin-bottom: 15px">
        <el-form label-width="70px">
          <el-form-item label="函数名">
            <el-input
              placeholder="请输入内容"
              v-model="func_name"
              @input="searchCloudFunList"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="cloudFunList"
        highlight-current-row
        v-loadmore="loadMoreFunList"
        @current-change="handleSelectChange"
        height="450"
        style="width: 100%; overflow: auto"
      >
        <el-table-column prop="func_name" label="函数名"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ statusList[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column prop="ctime" label="创建时间"></el-table-column>
        <el-table-column prop="mtime" label="修改时间"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectCloudVisable = false">取 消</el-button>
        <el-button type="primary" @click="setCloudFunName">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const statusList = {
  0: '正常',
  1: '删除',
  Creating: '函数创建中',
  CreateFailed: '函数创建失败（如果已生成函数信息，则可以删除后再创建）',
  Active: '函数可用',
  Updating: '函数更新中',
  UpdateFailed: '函数更新失败',
  Publishing: '函数版本发布中',
  PublishFailed: '函数版本发布失败',
  Deleting: '函数删除中',
  DeleteFailed: '函数删除失败',
};
export default {
  name: '',
  components: {},
  props: [''],
  data() {
    return {
      statusList,
      selectCloudVisable: false,
      cloudFunList: [],
      pageIndex: 1,
      pageSize: 20,
      total: 0,
      func_name: '',
      currentRow: null,
    };
  },

  computed: {},
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    searchCloudFunList(e) {
      this.pageSize = 20;
      this.pageIndex = 1;
      this.cloudFunList = [];
      this.func_name = e;
      this.getCloudFuncList();
    },
    getCloudFuncList() {
      this.$request({
        url: this.$api.getFuncList,
        data: {
          page: this.pageIndex - 1,
          size: this.pageSize,
          search_key: this.func_name,
        },
        showLoading: false,
        cb: ({ list, total }) => {
          this.cloudFunList = [
            ...this.cloudFunList,
            ...list?.filter((item) => item.func_type === 0 || item.func_type === 4),
          ];
          this.total = Number(total);
        },
      });
    },
    loadMoreFunList() {
      if (this.cloudFunList.length >= this.total) {
        return;
      }
      this.pageIndex += 1;
      this.getCloudFuncList();
    },
    openDiaLog() {
      this.func_name = '';
      this.currentRow = null;
      (this.cloudFunList = []),
        (this.pageIndex = 1),
        (this.pageSize = 20),
        (this.total = 0),
        this.getCloudFuncList();
      this.selectCloudVisable = true;
    },
    handleSelectChange(val) {
      this.currentRow = val;
    },
    setCloudFunName() {
      if (this.currentRow) {
        this.$emit('setCloudFun', this.currentRow);
      }
      this.selectCloudVisable = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .current-row {
  background: #f2f3f8;
}
</style>
