import { api, request } from '@/api';

export default {
  namespaced: true,
  getters: {
    getObjectList(state) {
      return state.objectList;
    },
  },
  state: {
    objectList: [],
  },
  mutations: {
    setObjectList(state, data) {
      state.objectList = data;
    },
  },
  actions: {
    async requestObjectList(context) {
      if (context.state.objectList.length > 0) return;
      const { objects: data } = await request({
        url: api.metaGetObjectList,
        data: {
          offset: 0,
          limit: 100000,
        },
      });
      context.commit('setObjectList', data);
    },
  },
};
