<template>
  <el-dialog
    class="message-box"
    :class="{ noBody }"
    :visible.sync="visible"
    :width="width"
    :show-close="exitButton"
    :top="top"
    :append-to-body="appendToBody"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :before-close="handleClose"
    :destroy-on-close="destroyOnClose"
  >
    <span slot="title" class="message-title">
      <i v-if="titleIconType" :class="`iconfont icon-${titleIconType}-fill message-title-icon`" />
      {{ title }}
    </span>
    <span class="message-content">{{ content }}</span>
    <slot name="content"></slot>
    <span slot="footer" class="message-footer">
      <el-button
        v-if="needCancel"
        class="message-footer-button"
        :type="cancelType"
        @click="cancelAction"
      >
        {{ cancelText }}
      </el-button>
      <el-button
        v-if="needConfirm"
        class="message-footer-button"
        :type="confirmType"
        @click="confirmAction"
      >
        {{ confirmText }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 显示
      visible: false,
      // 宽度
      width: '400px',
      // 离顶部高度
      top: '40vh',
      // 标题
      title: '',
      // 标题最右侧的叉按钮
      exitButton: false,
      // 标题前的icon类型
      titleIconType: '',
      // 内容文字
      content: '',
      // 确认按钮文字
      confirmText: '',
      // 确认按钮类型
      confirmType: '',
      // 确认后操作
      confirmAction: () => {},
      // 显示返回按钮
      needCancel: false,
      // 显示确认按钮
      needConfirm: true,
      // 返回按钮文字
      cancelText: '',
      // 返回按钮类型
      cancelType: '',
      // 返回按钮操作
      cancelAction: () => {},
      // 关闭前操作
      handleClose: this.close,
      // 是否可以通过点击 modal 关闭 Dialog
      closeOnClickModal: true,
      // 是否可以通过按下 ESC 关闭 Dialog
      closeOnPressEscape: true,
      // 关闭时是否销毁内部元素
      destroyOnClose: false,
    };
  },
  computed: {
    noBody() {
      return !this.content && !this.$slots.content;
    },
  },
  methods: {
    show(option) {
      const {
        // 标题
        title = '信息提示',
        // 宽度
        width = '400px',
        // 离顶部高度
        top = '40vh',
        // 标题最右侧的叉按钮
        exitButton = false,
        // 标题前的icon类型
        titleIconType = '',
        // 内容文字
        content = '',
        // 确认按钮文字
        confirmText = '确认',
        // 确认按钮类型
        confirmType = 'primary',
        // 确认后操作
        confirmAction = () => {},
        // 显示返回按钮
        needCancel = false,
        // 显示确认按钮
        needConfirm = true,
        // 返回按钮文字
        cancelText = '取消',
        // 返回按钮类型
        cancelType = 'info',
        // 返回按钮操作
        cancelAction = this.close,
        // 关闭前操作
        handleClose = this.close,
        // 是否可以通过点击 modal 关闭 Dialog
        closeOnClickModal = true,
        // 是否可以通过按下 ESC 关闭 Dialog
        closeOnPressEscape = true,
        // 关闭时是否销毁内部元素
        destroyOnClose = false,
      } = option;
      this.title = title;
      this.width = width;
      this.top = top;
      this.exitButton = exitButton;
      this.titleIconType = titleIconType;
      this.content = content;
      this.confirmText = confirmText;
      this.confirmType = confirmType;
      this.confirmAction = confirmAction;
      this.needCancel = needCancel;
      this.needConfirm = needConfirm;
      this.cancelText = cancelText;
      this.cancelType = cancelType;
      this.cancelAction = cancelAction;
      this.handleClose = handleClose;
      this.closeOnClickModal = closeOnClickModal;
      this.closeOnPressEscape = closeOnPressEscape;
      this.destroyOnClose = destroyOnClose;
      this.visible = true;
    },
    close() {
      this.visible = false;
      console.log('messageBox close');
    },
    closeAction() {
      this.visible = false;
      this.$emit('closeAction');
      console.log('messageBox close action');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
.message-box {
  ::v-deep.el-dialog__body {
    padding: 0px 20px 16px 0px;
    padding-left: 45px;
  }
  &.noBody {
    ::v-deep.el-dialog__body {
      display: none;
    }
  }
  ::v-deep.el-dialog__footer {
    padding-right: 0;
  }
  .message-title {
    font-size: 16px;
    color: #1c2028;
    font-weight: 500;
    .message-title-icon {
      font-size: 20px;
      &.icon-info-fill {
        color: #1664ff;
      }
      &.icon-warning-fill {
        color: #fa9600;
      }
      &.icon-danger-fill {
        color: #e34d59;
      }
    }
  }
  .message-content {
    font-size: 14px;
    line-height: 22px;
    color: #4d5762;
  }
  .message-footer {
    padding: 24px;
  }
}
</style>
