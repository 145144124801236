<template>
  <div v-if="configVisible" :class="{ hidden: !configVisible, configPanel: true }">
    <div class="panel-top">
      <div class="panel-title">
        {{ config.nodeType === 'edge' ? '连线' : nodeTypes[config.type].name }}设置
      </div>
      <div class="panel-action">
        <el-button type="danger" @click="delNode" v-if="!['start_node'].includes(config.type)">
          删除
        </el-button>
      </div>
    </div>
    <div class="tab-wrapper">
      <el-tabs v-if="config.type === 'user' || config.type === 'doa'" v-model="tab">
        <el-tab-pane label="节点设置" name="1"></el-tab-pane>
        <el-tab-pane label="触发事件" name="2"></el-tab-pane>
        <el-tab-pane
          v-if="info.module !== 'DCR' && config.type !== 'doa'"
          label="权限配置"
          name="3"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <NodeConfig v-if="tab === '1'" :value="config" :info="info" @update="updateConfig" />
    <EventConfig
      v-if="tab === '2'"
      :value="config.events"
      @update="(value) => updateConfig({ path: 'events', value })"
      :info="info"
      :config="config"
    />
    <PermissionConfig
      v-if="tab === '3'"
      :value="config.field_permission"
      @update="(value) => updateConfig({ path: 'field_permission', value })"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { nodeTypes } from '../config.js';
import { getValue, fittingString } from '@/utils/util';
import { generateUserNodeConfig } from '../common';

import NodeConfig from './components/nodeConfig.vue';
import EventConfig from './components/eventConfig.vue';
import PermissionConfig from './components/permissionConfig.vue';
import nodeUpdateMixin from './mixin.js';
import { mapActions } from 'vuex';
export default {
  components: {
    NodeConfig,
    EventConfig,
    PermissionConfig,
  },
  mixins: [nodeUpdateMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    graph: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      config: {},
      configVisible: true,
      // 输入弹窗
      putType: 'inputs',
      putList: [],
      showPut: false,
      // 对象
      showMetadata: false,
      graphql: '',
      nodeTypes,
      // 当前tab
      tab: '1',
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('workflow', ['requestModelAssignersInfo']),
    init() {
      this.config = JSON.parse(JSON.stringify(this.data));
      // 保证读取旧数据时组件获得正常初始值
      if (!this.config.events) this.config.events = [];
    },
    getValue,
    cancel() {
      this.$emit('cancel');
    },
    delNode() {
      const { id } = this.config;
      const item = this.graph.findById(id);

      this.graph.removeItem(item);
      // 拆解 单个子节点 或没有子节点 的 Combo
      this.$nextTick(() => {
        const allCombos = this.graph.getCombos();
        allCombos.forEach((combo) => {
          const { nodes, combos } = combo.getChildren();
          if (nodes.length <= 1 && combos.length <= 1) {
            // 拆解 Combo，即拆分组/解组。调用后，combo 本身将被删除，而该分组内部的子元素将会成为该分组父分组（若存在）的子元素。
            this.graph.uncombo(combo);
          }
        });
      });
      this.cancel();
    },
    updateNode: _.debounce(function () {
      const { id, nodeType, type } = this.config;
      const item = this.graph.findById(id);
      let model = {
        ...this.config,
      };

      if (nodeType === 'node') {
        // 审批节点特殊处理
        if (type === 'user') {
          const generateConfig = generateUserNodeConfig.call(this, this.config);
          if (!Object.keys(generateConfig).length) {
            return;
          }
          model = {
            ...model,
            ...generateConfig,
          };
        }
        // 样式
        model.style.fill = getValue(nodeTypes[type].defaultStyle, '.fill') || '#fff';
        if (typeof nodeTypes[type].getStyle === 'function') {
          const style = nodeTypes[type].getStyle(model);
          model.style = {
            ...style,
            ...model.style,
          };
        }
        model.label =
          model.fullname.length > 6 ? `${model.fullname.substr(0, 6)}...` : model.fullname;
      }

      if (nodeType === 'edge') {
        // 条件节点后面连线标题特殊处理
        model.label = fittingString(this.config.expr_name || '', 100, 10) || '';
      }

      delete model.id;
      delete model.nodeType;
      console.log('model--', model);
      item.update(model);
      item.refresh();
      // 刷新节点连线
      if (nodeType === 'node') {
        if (item._cfg && item._cfg.edges) {
          item._cfg.edges.forEach((item) => {
            this.graph.findById(item._cfg.id).refresh();
          });
        }
        this.generateSelectdAssigneers();
      }
    }, 50),
    // 输入/输出变量
    openPut(type) {
      this.putType = type;
      this.putList = [...(getValue(this.config, `.${type}`) || [])];
      this.$nextTick(() => {
        this.showPut = true;
      });
    },
    closePut() {
      this.showPut = false;
      this.putList = [];
    },
    submitPut(data) {
      this.config[this.putType] = data;
      this.closePut();
    },
    // 对象
    openMetadata() {
      this.showMetadata = true;
    },
    closeMetadata() {
      this.showMetadata = false;
    },
    selectMetadata({ name }) {
      this.config.business_config.key = name;
      this.closeMetadata();
    },
    updateConfig({ path, value }) {
      this.config = _.set(this.config, path, value);
      this.updateNode();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/tab.scss';
.configPanel {
  position: absolute;
  top: 43px;
  right: 0;
  bottom: 0;
  width: 640px;
  overflow: auto;
  background: #fff;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 8px 10px -5px rgba(0, 0, 0, 0.08);

  &.hidden {
    transform: translate(100%, 0);
  }
  ::v-deep .el-form-item {
    margin: 15px 20px 15px 0;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .panel-top {
    padding: 10px;
    border-bottom: 1px solid #e5eaf2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .panel-title {
      font-size: 16px;
      color: #1c2028;
      font-weight: 700;
    }
  }
  .pannel-child-title {
    font-size: 14px;
    padding: 5px 14px;
    background: #f0f2f5;
  }
  .gb-toggle-btn {
    width: 10px;
    height: 20px;
    top: 50%;
    right: 100%;
    border-radius: 10px 0 0 10px;
    box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.1);
    transform: translate(0, -50%);
  }
  .config-data {
    padding: 5px 10px;
  }
  .config-item {
    input {
      width: 80px;
      padding: 5px;
      margin: 5px 10px;
      background: #f4f7fd;
      border-radius: 4px;
    }
  }
  .config-tab {
    padding-left: 20px;
  }
}
.tab-wrapper {
  ::v-deep .el-tabs--top .el-tabs__item {
    padding-left: 20px !important;
  }
}
</style>
