<template>
  <el-table :data="actionList" style="width: 100%">
    <el-table-column prop="name" label="名称"></el-table-column>
    <el-table-column label="流程key">
      <template slot-scope="scope">
        {{ scope.row.keys.join(',') }}
      </template>
    </el-table-column>
    <el-table-column prop="remark" label="备注"></el-table-column>
    <el-table-column prop="updated_by" label="最后更新人"></el-table-column>
    <el-table-column prop="updated_at" label="更新时间"></el-table-column>
    <el-table-column fixed="right" label="操作" width="75">
      <template slot-scope="scope">
        <ConfirmButton @confirm="deleteAction(scope.row)">
          <template v-slot:body>
            <p class="confirm">
              <i class="iconfont icon-warning-fill" />
              你确定要删除吗？
            </p>
          </template>
          <template v-slot:reference>
            <el-button type="text"><i class="iconfont icon-delete" /></el-button>
          </template>
        </ConfirmButton>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import ConfirmButton from '@/components/confirmButton';

export default {
  name: 'WorkflowConfigActionList',
  components: { ConfirmButton },
  props: {
    actionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 弹框
      popoverVisible: false,
    };
  },
  methods: {
    deleteAction({ id }) {
      this.popoverVisible = false;
      this.$emit('delete-item', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';

.confirm {
  .icon-warning-fill {
    font-size: 18px;
    color: #fa9600;
  }
}
.icon-delete {
  font-size: 16px;
  color: #979797;
}
</style>
