import { ERROR_CODE, UPLOAD_TYPE } from './lib/constant';
import { toBuffer, convertBase64UrlToBlob } from './utils';
import CosSdk from 'cos-js-sdk-v5';

// 图片需要矫正方向的枚举
const IMAGE_ORIENTATION = [2, 3, 4, 5, 6, 7, 8];

// 需要校验图片方向
const IMAGE_TYPE = ['jpg', 'jpeg', 'png', 'gif'];

class Cos {
  static config;
  constructor({
    uploadType = UPLOAD_TYPE.direct,
    uploadImmediately = true,
    types = [],
    multiple = false,
    uploadButton,
    container,
    maxSize = 4, // MB
    fileAdded,
    completeQueue,
    isCheckImageOrientation = true,
    ...rest
  }) {
    console.log({ rest });
    this.uploadType = uploadType;
    this.types = types;
    this.multiple = multiple;
    this.processList = [];
    this.uploadButton = uploadButton;
    this.container = container;
    this.fileAdded = fileAdded;
    this.maxSize = maxSize;
    this.restOptions = rest;
    this.uploadImmediately = uploadImmediately;
    this.completeQueue = completeQueue;
    this.isCheckImageOrientation = isCheckImageOrientation;
    this.originFileName = '';
    this.startTask = this.startTask.bind(this);
    this.initUploader().then(() => {
      this.initCss();
    });
  }

  static initConfig(
    config = {
      getTokenFunc: () => {},
      bucket: '',
      region: '',
    },
  ) {
    Cos.config = config;
  }

  async initUploader() {
    this.uploader = require('./lib/upload').default;
  }

  initCss = () => {
    if (!this.uploadButton || !this.container) {
      console.warn('缺少上传按钮id和父元素id');
      return;
    }
    const { uploadButton, container } = this;
    const containerDom = document.getElementById(container);
    if (containerDom) {
      containerDom.style.position = 'relative';
      this.inputDiv = document.createElement('input');
      this.inputDiv.type = 'file';
      this.inputDiv.accept = this.types ? this.types.join(',') : '*';
      this.inputDiv.multiple = this.multiple;
      const uploadButtonStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        'z-index': 0,
        opacity: 0,
        cursor: 'pointer',
      };
      const styles = Object.keys(uploadButtonStyle);
      styles.forEach((item) => {
        this.inputDiv.style[item] = uploadButtonStyle[item];
      });

      // 如果没有获取到父元素的宽高，则绑定点击事件
      const isZero = !containerDom.offsetWidth;
      this.inputDiv.style.width = `${containerDom.offsetWidth}px`;
      this.inputDiv.style.height = `${containerDom.offsetHeight}px`;

      containerDom.appendChild(this.inputDiv);
      if (isZero) {
        document.getElementById(uploadButton).onclick = () => {
          this.inputDiv.click();
        };
      }
      this.inputDiv.onchange = async () => {
        console.log(this.inputDiv.files, this.inputDiv.value);
        let success = false;
        try {
          await Promise.all(
            [...this.inputDiv.files].map(async (file) => {
              if (this.validFile(file, this)) {
                const { cosInstance, cdnUrl, key } = await Cos.getInstance();
                this.originFileName = key || file.name;
                const [_file, _suffix] = await Cos.imageFileCheck(
                  file,
                  '',
                  this.isCheckImageOrientation,
                );
                this.processList.push(
                  new this.uploader({
                    uploadType: this.uploadType,
                    file: _file,
                    fileName: this.originFileName,
                    suffix: _suffix,
                    cdnUrl,
                    cosInstance,
                    ...this.restOptions,
                    ...Cos.config,
                  }),
                );
              }
            }),
          );
          success = true;
        } catch (err) {
          this.inputDiv.value = '';
          this.restOptions.error && this.restOptions.error(err);
        }
        console.log(this.processList);
        if (success && this.processList.length) {
          this.fileAdded && this.fileAdded(this.processList, this);
          if (!this.processList.every((item) => item.state === 'stop')) {
            this.uploadImmediately && this.processList.length && this.start();
          }
        } else {
          console.error('初始化任务失败');
        }
      };
    }
  };

  static async imageFileCheck(file, suffix, isCheckImageOrientation = true) {
    let _file = file;
    let _suffix = suffix;
    if (isCheckImageOrientation) {
      const isImage = IMAGE_TYPE.some((i) => file.type.indexOf(i) > 0);
      if (isImage) {
        _file = await Cos.validImageOrientation(file);
        const filename = file.name || '';
        const pos = filename.lastIndexOf('.');
        if (pos !== -1) {
          _suffix = filename.substring(pos);
        }
      }
    }
    return [_file, _suffix];
  }

  static async validImageOrientation(file) {
    try {
      let _resolve;
      //   let _reject;
      const promise = new Promise((re) => {
        _resolve = re;
        // _reject = rj;
      });
      const exifjs = require('exif-js');
      exifjs.getData(file, async function () {
        const orientation = exifjs.getTag(this, 'Orientation');
        console.log('orientation>>>>>>', orientation);
        const imageDataUrl = await toBuffer(file, true);
        const needRotate = IMAGE_ORIENTATION.some((i) => i === orientation);
        if (orientation === 1) {
          console.log('图片无需处理');
          _resolve(file);
        } else if (orientation !== '' && needRotate) {
          let uploadBase64 = new Image();
          uploadBase64.src = imageDataUrl;
          uploadBase64.onload = function () {
            // 修正旋转图片
            const expectWidth = uploadBase64.width;
            const expectHeight = uploadBase64.height;

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            canvas.width = expectWidth;
            canvas.height = expectHeight;
            ctx.save();
            const { width } = canvas;
            const styleWidth = canvas.style.width;
            const { height } = canvas;
            const styleHeight = canvas.style.height;

            if (orientation) {
              if (orientation > 4) {
                canvas.width = height;
                canvas.style.width = styleHeight;
                canvas.height = width;
                canvas.style.height = styleWidth;
              }
              switch (orientation) {
                case 2:
                  ctx.translate(width, 0);
                  ctx.scale(-1, 1);
                  break;
                case 3:
                  ctx.translate(width, height);
                  ctx.rotate(Math.PI);
                  break;
                case 4:
                  ctx.translate(0, height);
                  ctx.scale(1, -1);
                  break;
                case 5:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.scale(1, -1);
                  break;
                case 6:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.translate(0, -height);
                  break;
                case 7:
                  ctx.rotate(0.5 * Math.PI);
                  ctx.translate(width, -height);
                  ctx.scale(-1, 1);
                  break;
                case 8:
                  ctx.rotate(-0.5 * Math.PI);
                  ctx.translate(-width, 0);
                  break;
              }
            }

            ctx.drawImage(uploadBase64, 0, 0);
            ctx.restore();

            // 输出转换后的base64图片
            let base64 = canvas.toDataURL(file.type, 1);
            // 输出转换后的流
            const newBlob = convertBase64UrlToBlob(base64, file.type);
            uploadBase64.onload = null;
            uploadBase64.onerror = null;
            ctx = null;
            canvas = null;
            base64 = null;
            uploadBase64 = null;
            _resolve(newBlob);
          };
          uploadBase64.onerror = function () {
            uploadBase64.onload = null;
            uploadBase64.onerror = null;
            _resolve(file);
          };
        } else {
          console.log('图片读取不了方向信息');
          _resolve(file);
        }
      });
      return promise;
    } catch (e) {
      console.log(e);
      return file;
    }
  }

  validFile(file, uploader) {
    // 大小判断
    if (uploader.maxSize && file.size > uploader.maxSize * 1024 * 1024) {
      throw {
        code: ERROR_CODE.SizeError,
      };
    }

    // 根据后缀判断类型
    const pos = file.name.lastIndexOf('.');
    let suffix = '';
    if (pos !== -1) {
      suffix = file.name.substring(pos).toLowerCase();
    }
    let { types } = uploader;
    if (types && types.length) {
      types = types.map((item) => (item.indexOf('/') >= 0 ? `.${item.split('/')[1]}` : item));
    }
    console.log(types.findIndex((typeItem) => typeItem.indexOf('*') >= 0) >= 0);
    // 根据文件type判断类型
    if (types && types.length) {
      if (types.findIndex((typeItem) => typeItem.indexOf('*') >= 0) >= 0) {
        return true;
      }

      if (types.findIndex((type) => type.toLowerCase() === suffix) < 0) {
        throw {
          code: ERROR_CODE.FormatError,
        };
      }
    }
    return true;
  }

  async addFile(file) {
    try {
      const { cosInstance, cdnUrl, key } = await Cos.getInstance();
      this.originFileName = key || file.name;
      const [_file, _suffix] = await Cos.imageFileCheck(file, '', this.isCheckImageOrientation);
      const options = {
        uploadType: this.uploadType,
        file: _file,
        fileName: this.originFileName,
        suffix: _suffix,
        cdnUrl,
        cosInstance,
        ...this.restOptions,
        ...Cos.config,
      };
      this.processList.push(new this.uploader(options));
      // this.fileAdded && this.fileAdded(this.processList, this);
      this.uploadImmediately && this.processList.length && this.start();
    } catch (err) {
      this.restOptions.error && this.restOptions.error(err);
    }
  }

  startTask() {
    return Promise.all(
      this.processList.map((item) => {
        let r;
        let rj;
        const promise = new Promise((resolve, reject) => {
          r = resolve;
          rj = reject;
        });
        item.start && item.start(r, rj);
        return promise;
      }),
    )
      .then(() => {
        (this.processList = []), this.completeQueue && this.completeQueue();
        this.inputDiv.value = '';
      })
      .catch((err) => {
        this.processList = [];
        this.inputDiv.value = '';
        console.log('err', err);
      });
  }

  async start() {
    console.log('start');
    return this.startTask();
  }

  pause() {
    this.processList.map((item) => item.pause && item.pause());
  }

  abort() {
    this.processList.map((item) => item.abort && item.abort());
    delete this.processList;
    this.processList = [];
    if (this.inputDiv && this.inputDiv.value) {
      this.inputDiv.value = '';
    }
  }

  restart() {
    this.processList.map((item) => item.restart && item.restart());
  }

  destory() {
    const containerDom = document.getElementById(this.container);
    this.uploader = null;
    if (containerDom) {
      containerDom.removeChild(this.inputDiv);
      this.inputDiv = null;
    }
  }

  static async getInstance({ customGetTokenFunc, suffix }) {
    let { getTokenFunc = () => Promise.resolve() } = Cos.config;
    if (customGetTokenFunc) {
      getTokenFunc = customGetTokenFunc;
    }
    const {
      tmpSecretId,
      tmpSecretKey,
      sessionToken,
      startTime,
      expiredTime,
      cdnUrl,
      key,
      region,
      bucket,
    } = await getTokenFunc({ suffix });
    Cos.config.region = region;
    Cos.config.bucket = bucket;
    const cosInstance = new CosSdk({
      getAuthorization: async (options, callback) => {
        const { Method, Pathname, Query, Headers } = options;
        const authorization = CosSdk.getAuthorization({
          SecretId: tmpSecretId, // 可传固定密钥或者临时密钥
          SecretKey: tmpSecretKey, // 可传固定密钥或者临时密钥
          Method,
          Pathname,
          Query,
          Headers,
        });

        callback({
          Authorization: authorization,
          XCosSecurityToken: sessionToken,
          StartTime: startTime,
          ExpiredTime: expiredTime,
        });
      },
    });
    return {
      cosInstance,
      cdnUrl,
      key,
    };
  }
}

export default Cos;
