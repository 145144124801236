<template>
  <div>
    <el-dialog :visible.sync="visible" width="985px" :before-close="beforeClose">
      <span slot="title" class="dialog-title">新建通知消息</span>
      <div class="notify-wrapper">
        <div class="sub-title">基本信息</div>
        <el-form
          :model="basicInfo"
          label-width="90px"
          label-position="right"
          ref="basicForm"
          class="form-wrapper"
        >
          <el-form-item
            label="模版名称"
            prop="name"
            :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
            class="form-item"
          >
            <el-input v-model="basicInfo.name" class="form-input" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="流程key" prop="keys" class="form-item">
            <el-select
              v-model="basicInfo.keys"
              disabled
              placeholder="请选择"
              multiple
              class="form-input"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="event_key"
            prop="event_key"
            :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
            class="form-item"
          >
            <el-input
              v-model="basicInfo.event_key"
              class="form-input"
              placeholder="请输入"
            ></el-input>
            <el-tooltip effect="dark" placement="top-start">
              <i class="el-icon-question" style="margin-left: 10px" />
              <div style="width: 200px" slot="content">
                该字段仅应用于版本管理中的发版同步功能，为发版同步功能中标识通知消息事件的唯一性
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item
            label="通知类型"
            prop="subref"
            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            class="form-item"
          >
            <el-select
              v-model="basicInfo.subref"
              placeholder="请选择"
              class="form-input"
              @change="onSubrefChange"
            >
              <el-option
                v-for="obj in noticeTemplateTypes"
                :key="obj.label"
                :label="obj.label"
                :value="obj.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="basicInfo.subref === 'lclmsg'"
            prop="system_type"
            label="站内信类型"
            label-width="100px"
            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          >
            <el-select
              class="form-input"
              filterable
              v-model="basicInfo.system_type"
              placeholder="请选择"
              style="width: 453px"
            >
              <el-option
                v-for="item in systemMessageTypeList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="企微消息类型"
            prop="wxmsg_type"
            label-width="110px"
            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            v-if="basicInfo.subref === 'wxmsg'"
          >
            <el-radio-group v-model="basicInfo.wxmsg_type">
              <el-radio :label="1">文本消息</el-radio>
              <el-radio :label="2">小程序通知消息</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark" class="form-item">
            <el-input
              type="textarea"
              :rows="2"
              v-model="basicInfo.remark"
              maxlength="50"
              autocomplete="off"
              class="form-input"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form
          :model="templateInfo"
          :rules="templateInfoFormRule"
          label-width="120px"
          ref="templateForm"
          label-position="right"
          class="form-wrapper"
        >
          <div class="sub-title" style="margin-top: 40px">
            接收人设置
            <span style="color: #f56c6c; margin-left: 5px">(至少选填一项)</span>
          </div>
          <el-form-item label="指定接收人变量" prop="to2" class="form-item">
            <el-select
              v-model="templateInfo.to2"
              placeholder="请选择"
              class="form-input"
              style="width: 435px"
            >
              <el-option
                v-for="obj in candidateUserAtributes"
                :key="obj.label"
                :label="obj.label"
                :value="obj.value"
              ></el-option>
            </el-select>
            <el-input
              v-if="templateInfo.to2 === 'specify_variable'"
              v-model="templateInfo.variable"
              class="form-input"
              placeholder="请输入指定变量"
              style="width: 314px; margin-left: 4px"
            ></el-input>
            <el-tooltip effect="dark" placement="top-start">
              <i class="el-icon-question" style="margin-left: 10px" />
              <div style="width: 200px" slot="content">
                该选择框仅用于在审批流中配置审批人节点触发通知消息时的接收人设置指定接收人变量
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="指定其他接收人" prop="to" class="form-item">
            <el-select
              v-model="templateInfo.to"
              multiple
              filterable
              remote
              placeholder="请选择"
              :remote-method="getCandidateUserList"
              class="form-input"
              collapse-tags
              style="width: 435px"
            >
              <el-option
                v-for="item in candidateUserList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="sub-title">通知信息</div>
          <el-form-item label="通知标题" prop="title" class="form-item" label-width="80px">
            <el-input
              class="form-input notice-title"
              v-model="templateInfo.title"
              autocomplete="off"
              style="width: 346px"
            ></el-input>
            <el-button
              @click="openMetaDiagLog('title')"
              style="margin-left: 10px; color: #0069fa"
              :disabled="basicInfo.keys.length !== 1"
              type="info"
            >
              插入对象字段
            </el-button>
          </el-form-item>
          <el-form-item v-if="needPlatform" class="form-item" prop="platform" label="通知终端">
            <el-checkbox-group v-model="templateInfo.platform">
              <el-checkbox :label="1">小程序端</el-checkbox>
              <el-checkbox :label="2">web端</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="通知内容" prop="content" class="form-item" label-width="80px">
            <template v-if="basicInfo.subref === 'wxmsg'">
              <div style="display: flex">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="templateInfo.content"
                  autocomplete="off"
                  style="width: 470px"
                  placeholder="请输入"
                  class="notice-content"
                  v-show="basicInfo.wxmsg_type !== 2"
                ></el-input>
                <InputJson
                  v-model="templateInfo.content"
                  v-show="basicInfo.wxmsg_type === 2"
                  class="notice-content"
                />
                <el-button
                  @click="openMetaDiagLog('content')"
                  style="margin-left: 10px; color: #0069fa; height: 32px"
                  :disabled="basicInfo.keys.length !== 1"
                  type="info"
                >
                  插入对象字段
                </el-button>
              </div>
              <a
                style="color: #0077ff"
                :href="documentLink"
                target="_blank"
                v-if="basicInfo.wxmsg_type === 2"
              >
                点击查看JSON格式相关文档，仅需填写miniprogram_notice属性下的对象
              </a>
            </template>
            <template v-if="basicInfo.subref !== 'wxmsg'">
              <div v-if="basicInfo.subref === 'email'">
                <el-radio-group v-model="templateInfo.editor_type" @change="emailMsgTypeChange">
                  <el-radio :label="1">编辑富文本</el-radio>
                  <el-radio :label="2">编辑HTML</el-radio>
                </el-radio-group>
              </div>
              <template v-if="templateInfo.editor_type !== 2">
                <el-button
                  @click="openMetaDiagLog('content')"
                  style="margin-left: 0; color: #0069fa"
                  :disabled="basicInfo.keys.length !== 1"
                  type="info"
                >
                  插入对象字段
                </el-button>
                <RichEditor
                  :content="templateInfo.content"
                  @getContent="
                    (e) => {
                      templateInfo.content = e;
                      $refs.templateForm.validateField('content');
                    }
                  "
                />
              </template>
              <!-- html消息 -->
              <template v-else-if="templateInfo.editor_type === 2">
                <div class="editor-block">
                  <div class="editor-part">
                    <div class="editor-title">
                      编辑HTML
                      <el-button
                        style="margin-left: 0; color: #0069fa"
                        :disabled="basicInfo.keys.length !== 1"
                        type="info"
                        @click="openMetaDiagLog('content')"
                      >
                        插入对象字段
                      </el-button>
                    </div>
                    <CodeEditor
                      class="code-editor"
                      v-model="templateInfo.content"
                      :options="htmlOptions"
                    />
                    <el-button type="text" @click="openPreview">预览</el-button>
                  </div>
                  <div class="editor-part">
                    <div class="editor-title">自定义脚本</div>
                    <CodeEditor
                      class="code-editor"
                      v-model="templateInfo.script"
                      :options="jsOptions"
                    />
                    <el-button type="text" @click="openHelp">帮助文档</el-button>
                  </div>
                </div>
              </template>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleCloseCreateDia">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
    <select-meta-dialog-vue ref="metaDialog" :object-names="objectNames" @insertion="insertion" />
    <Clipboard ref="copy" :copytext="copytext" />

    <!-- messageBox -->
    <MessageBox ref="previewBox" class="preview-box">
      <template v-slot:content>
        <div v-html="previewContent" />
      </template>
    </MessageBox>
    <MessageBox ref="helpBox">
      <template v-slot:content>
        <div>脚本语法使用js语法（部分支持）和 underscore(_)，注意变量名不要以双下划线开头</div>
        <div>1. 脚本中引用自定义变量，使用 args.xxx，如 args.task_id</div>
        <div>
          2. 脚本中引用元数据变量，使用 {object}.xxx，如
          events_meeting.meeting_name，注意元数据变量仅支持引用HTML模版中使用的字段，其余为undefined
        </div>
        <div>
          3. 模版中可以引用脚本中定义的变量，如 {% foo %}，可以在此处使用表达式计算值，如 {% foo + 1
          %}
        </div>
        <div>当前支持的IO方法：</div>
        <div>- debug_log([any],...) 打印日志，参数：任意类型，返回：无</div>
        <div>
          - get_query([query]) 获取query结果，参数：query字符串，返回：元数据query接口返回data参数
        </div>
        <div>
          - get_field_option([object],[field])
          获取枚举字段的选项，参数：任意对象名、字段名，返回：value到label的映射
        </div>
      </template>
    </MessageBox>
  </div>
</template>
<script>
import { noticeTemplateTypes, candidateUserAtributes } from '../../../../views/workflow/common';
import { throttle } from '@/utils/util';
import selectMetaDialogVue from '../../../../views/workflow/component/select-meta-dialog.vue';
import RichEditor from '@/components/richEditor';
import InputJson from '@/components/inputJson';
import Clipboard from 'nges-common/src/web/components/Clipboard';
import CodeEditor from '../../../../views/notice/noticeDetail/editor.vue';
import MessageBox from '@/components/messageBox';
import { difference } from 'lodash';
const _ = { difference };
export default {
  components: {
    selectMetaDialogVue,
    RichEditor,
    InputJson,
    Clipboard,
    CodeEditor,
    MessageBox,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noticeTemplateTypes,
      candidateUserAtributes,
      visible: false,
      basicInfo: {
        name: '',
        keys: [],
        event_key: '',
        remark: '',
        subref: '',
        wxmsg_type: 1,
        system_type: '',
      },
      templateInfo: {
        title: '',
        content: '',
        to: [],
        to2: '',
        variable: '',
        platform: [1, 2],
        editor_type: 1,
        script: '',
      },
      candidateUserList: [],
      insertType: '',
      objectNames: [],
      copytext: '',
      documentLink:
        'https://developer.work.weixin.qq.com/document/path/90236#%E5%B0%8F%E7%A8%8B%E5%BA%8F%E9%80%9A%E7%9F%A5%E6%B6%88%E6%81%AF',
      systemMessageTypeList: [],
      // 代码输入框配置
      htmlOptions: {
        placeholder: '<html></html>',
        autorefresh: true,
        mode: 'text/html',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      jsOptions: {
        placeholder: 'var a = 1',
        autorefresh: true,
        mode: 'text/javascript',
        matchBrackets: true,
        tabSize: 4,
        lineNumbers: true,
        lineWrapping: true,
      },
      // 校验规则
      templateInfoFormRule: {
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入通知内容' },
          {
            validator: (rule, value, callback) => {
              const err = this.validateContent();
              if (err) {
                callback(err);
              } else {
                callback();
              }
            },
          },
        ],
        platform: [{ required: true, message: '请选择通知终端 ', trigger: 'change' }],
      },
    };
  },
  computed: {
    generateTo2() {
      if (!this.templateInfo.to2) {
        return [];
      }
      return this.templateInfo.to2 === 'specify_variable'
        ? [this.templateInfo.variable]
        : [this.templateInfo.to2];
    },
    generateToType() {
      if (!this.templateInfo.to2) {
        return 0;
      }
      return this.templateInfo.to2 === 'specify_variable' ? 2 : 1;
    },
    previewContent() {
      return this.templateInfo.content.replaceAll('href="mailto', '');
    },
    // 只选了站内信的时候,需要选择终端
    needPlatform() {
      return this.basicInfo.subref === 'lclmsg';
    },
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {
    this.getObjectByRelatedWorkflow();
    this.getSystemMessageTypes();
  },
  methods: {
    onSubrefChange() {
      this.templateInfo.content = '';
    },
    openDiaLog() {
      this.basicInfo.keys = [this.info.process_key];
      this.getObjectByRelatedWorkflow();
      this.visible = true;
    },
    beforeClose() {
      this.basicInfo = {
        name: '',
        keys: [],
        remark: '',
        subref: '',
        wxmsg_type: 1,
        system_type: '',
        module: '',
      };
      this.templateInfo = {
        title: '',
        content: '',
        to: [],
        to2: '',
        variable: '',
        editor_type: 1,
        platform: [1, 2],
        script: '',
      };
      this.candidateUserList = [];
      this.$refs.basicForm.clearValidate();
      this.$refs.templateForm.clearValidate();
      this.visible = false;
    },
    handleCloseCreateDia() {
      this.beforeClose();
    },
    createNoticeEvent(data) {
      this.$request({
        url: this.$api.createProcessAction,
        data,
      }).then(async (res) => {
        if (res.id) {
          await this.$emit('getEventOptions');
          this.$emit('addEventValue', res.id);
          this.beforeClose();
          this.$message.success('创建成功');
        }
      });
    },
    getCandidateUserList(name = '', delay = 300) {
      if (!name) {
        this.candidateUserList = this.templateInfo?.to?.map((value) => {
          return {
            value,
            label: value.replace(/\((.+?)\)/g, ''),
          };
        });
        return;
      }
      throttle(() => {
        this.$request({
          url: this.$api.getUserList,
          data: {
            page_size: 100,
            page: 1,
            keyword: name,
          },
          showLoading: false,
          cb: ({ data }) => {
            if (data) {
              const map = {};
              this.candidateUserList = [
                ...(this.templateInfo.to || []).map((value) => ({
                  value,
                  label: value.replace(/\((.+?)\)/g, ''),
                })),
                ...data.map((item) => ({
                  label: `${item.name}${item.description ? `-${item.description}` : ''}`,
                  value: `(${item.account})${item.name}${
                    item.description ? `-${item.description}` : ''
                  }`,
                })),
              ].reduce((prev, cur) => {
                map[cur.value] ? '' : (map[cur.value] = true && prev.push(cur));
                return prev;
              }, []);
            } else {
              this.candidateUserList = [];
            }
          },
        });
      }, delay).call(this);
    },
    getObjectByRelatedWorkflow() {
      this.$request({
        url: this.$api.metaGetObjectByRelatedWorkflow,
        data: {
          process_def_key: this.info.process_key,
        },
        cb: ({ list }) => {
          this.objectNames = list.map((i) => i.name);
        },
      });
    },
    submit() {
      this.$refs.basicForm.validate((basicValid) => {
        this.$refs.templateForm.validate((templateValid) => {
          if (!templateValid || !basicValid) {
            return;
          }
          if (!this.templateInfo.to.length && !this.templateInfo.to2) {
            this.$message.error('接收人设置至少选填一项');
            return;
          }
          if (this.templateInfo.to2 === 'specify_variable' && !this.templateInfo.variable) {
            this.$message.error('请输入指定变量');
            return;
          }
          const detailData = {
            category: 1, // 1是通知事件， 2是云函数
            name: this.basicInfo.name,
            remark: this.basicInfo.remark,
            event_key: this.basicInfo.event_key,
            status: 1,
            template: {
              notify: {
                editor_type: this.templateInfo.editor_type,
                script: this.templateInfo.script,
                title: this.templateInfo.title,
                platform: this.templateInfo.platform,
                content: this.templateInfo.content,
                to: this.templateInfo.to,
                to2: this.generateTo2,
                to_type: this.generateToType,
                subref: this.basicInfo.subref,
                wxmsg_type: this.basicInfo.wxmsg_type || undefined,
                system_type: this.basicInfo.system_type || undefined,
              },
            },
          };

          this.createNoticeEvent(detailData);
        });
      });
    },
    openMetaDiagLog(type) {
      this.insertType = type;
      this.$refs.metaDialog.openDialog();
    },

    insertion(str = '') {
      let selector = '';
      let field = '';
      if (this.insertType === 'title') {
        selector = '.notice-title .el-input__inner';
        field = 'title';
        const dom = document.querySelector(selector);
        this.templateInfo[field] = `${
          this.templateInfo[field].substring(0, dom.selectionStart) +
          str +
          this.templateInfo[field].substring(dom.selectionEnd, dom.textLength)
        }`;
      } else if (this.insertType === 'content' && this.basicInfo.subref !== 'wxmsg') {
        this.templateInfo.content += str;
      } else if (
        this.insertType === 'content' &&
        this.basicInfo.subref === 'wxmsg' &&
        this.basicInfo.wxmsg_type !== 1
      ) {
        this.copytext = str;
        this.$refs.copy.copy();
      } else {
        selector = '.notice-content .el-textarea__inner';
        field = 'content';
        const dom = document.querySelector(selector);
        this.templateInfo[field] = `${
          this.templateInfo[field].substring(0, dom.selectionStart) +
          str +
          this.templateInfo[field].substring(dom.selectionEnd, dom.textLength)
        }`;
      }
    },
    getSystemMessageTypes() {
      this.$request({
        url: this.$api.metaGetObjectByName,
        data: {
          objects: ['message'],
        },
        cb: ({ list }) => {
          if (list.length) {
            const field = list[0].fields.filter(({ name }) => name === 'type');
            this.systemMessageTypeList = field[0].select_one_option.options;
          }
        },
      });
    },
    emailMsgTypeChange() {
      this.templateInfo.content = '';
    },
    openPreview() {
      this.$refs.previewBox.show({
        title: '预览',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.previewBox.close,
      });
    },
    openHelp() {
      this.$refs.helpBox.show({
        title: '帮助',
        width: '960px',
        top: '30vh',
        confirmText: '返回',
        exitButton: true,
        confirmAction: this.$refs.helpBox.close,
      });
    },
    validateContent() {
      if (this.needPlatform) {
        const platformFlagMap = { 1: 'wx', 2: 'web' };
        const platforms = (this.templateInfo.platform || []).map((item) => platformFlagMap[item]);
        const { content } = this.templateInfo;
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const links = doc.querySelectorAll('a');
        const hrefs = Array.from(links).map(function (link) {
          return link.getAttribute('href');
        });
        if (hrefs.length) {
          for (let i = 0; i < hrefs.length; i++) {
            const href = hrefs[i];
            const regex = /\[\(([a-zA-Z0-9]+)\).*?\]/g;
            let match;
            const plats = [];
            while ((match = regex.exec(href)) !== null) {
              plats.push(match[1]);
            }
            // 如果勾选了，而没有配对应平台的链接，则报错
            if (_.difference(platforms, plats).length) {
              return new Error('请确保为每个选中的终端填写对应的链接。');
            }
            if (_.difference(plats, platforms).length) {
              return new Error('请选择超链接对应的通知终端。');
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';
@import '@/styles/element/radio.scss';
.notify-wrapper {
  max-height: 500px;
  overflow-y: auto;
  .sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #1c2028;
  }
  .form-wrapper {
    margin-top: 16px;
    .form-item {
      margin-bottom: 18px;
    }
    .form-input {
      width: 470px;
      height: 32px;
    }
  }
  .notice-content {
    width: 470px;
  }
}
::v-deep .el-form-item__label {
  line-height: 32px;
  color: #384050;
  font-weight: 500;
}
::v-deep .el-form-item__content {
  line-height: 32px;
}

::v-deep .el-input__inner {
  height: 32px;
  border-radius: 2px;
}
::v-deep .el-input__icon {
  line-height: 32px;
}
::v-deep .ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 200px;
}
.editor-block {
  display: flex;
  .editor-part {
    flex: 1;
    margin-right: 16px;
    .editor-title {
      margin-bottom: 8px;
    }
    .code-editor {
      border: 1px solid black;
      flex: 1;
    }
  }
}

.preview-box {
  ::v-deep .el-dialog__body {
    padding-left: 20px;
  }
}
</style>
