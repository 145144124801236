<template>
  <el-card>
    <!-- 表单 -->
    <div class="top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="searchForm"
        @submit.native.prevent
        @keyup.enter.native="searchList"
      >
        <el-form-item label="" prop="serch">
          <el-input
            v-model="searchForm.serch"
            clearable
            placeholder="请输入规则名称"
            @clear="searchList"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button
          class="float-right"
          type="primary"
          @click="edit()"
          v-if="jurisdiction.metaManage"
        >
          新增分组
        </el-button>
      </div>
    </div>

    <!-- 列表 -->
    <el-table :data="list" v-loading="loading">
      <el-table-column prop="name" label="规则名称" />
      <el-table-column prop="tip_pos" label="提示位置" />
      <el-table-column prop="tip_msg" label="提示内容" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.enable ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="操作时间" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | getTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_by" label="操作人" />
      <el-table-column prop="desc" label="规则描述" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <template v-if="jurisdiction.metaManage">
            <el-button
              @click="changeStatus(scope.row, '禁用')"
              v-if="scope.row.enable"
              type="text"
              size="small"
            >
              禁用
            </el-button>
            <el-button @click="changeStatus(scope.row, '启用')" v-else type="text" size="small">
              启用
            </el-button>
          </template>
          <el-button @click="edit(scope.row.id, scope.row.version)" type="text" size="small">
            编辑
          </el-button>
          <el-button
            @click="del(scope.row)"
            type="text"
            size="small"
            v-if="jurisdiction.metaManage"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      v-if="allFilterList.length > 10"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="searchForm.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="allFilterList.length"
    />
    <!-- 删除弹窗 -->
    <el-dialog
      title=""
      class="del-dialog"
      :visible.sync="delDialog.visible"
      width="500px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span slot="title">
        <span class="del-dialog-title">删除提示</span>
      </span>
      <span class="del-dialog-content">确定删除验证规则记录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="danger" @click="confirmDelete">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { isNil } from 'lodash';
dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
export default {
  filters: {
    getTime(val) {
      return dayjs(val * 1000).format('lll');
    },
  },
  data() {
    return {
      objectId: null,
      object: null,
      // 表单
      searchForm: {
        serch: '',
        page: 1,
        pageSize: 10,
      },
      // 表格
      loading: false,
      allData: [],
      allFilterList: [],
      // 删除弹窗
      delDialog: {
        id: null,
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        metaManage: this.getElementList.includes('meta-manage'),
      };
    },
    list() {
      const offset = (this.searchForm.page - 1) * this.searchForm.pageSize;
      return this.allFilterList.slice(offset, offset + this.searchForm.pageSize);
    },
  },
  created() {
    this.objectId = this.$route.query.object_id;
    this.object = this.$route.query.object;
    if (!this.object) {
      this.$message.error('缺少必要参数');
      this.$router.go(-1);
      return;
    }
    this.getList();
  },
  methods: {
    searchList() {
      this.searchForm.page = 1;
      this.getList();
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.searchList();
    },
    async getList() {
      this.loading = true;
      try {
        const { rules } = await this.$request({
          url: this.$api.getValidateRules,
          data: {
            object: this.object,
          },
        });
        this.allData = rules;
        this.getFilterData();
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.allData = [];
        this.getFilterData();
        this.loading = false;
      }
    },
    getFilterData() {
      if (this.searchForm.serch) {
        this.allFilterList = this.allData.filter((item) => {
          return item.name.includes(this.searchForm.serch);
        });
        return;
      }
      this.allFilterList = this.allData;
    },
    // 翻页器
    handleCurrentChange(val) {
      this.searchForm.page = val;
      //   this.getList();
    },
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.searchForm.page = 1;
      //   this.getList();
    },
    // 表格
    async changeStatus(row, type) {
      try {
        await this.$confirm(`确定${type}该数据(${row.name})?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        this.$message({
          type: 'success',
          message: `${type}成功!`,
        });
        // 调用接口
        await this.$request({
          url: this.$api.enableValidateRule,
          data: {
            version: row.version,
            id: row.id,
            is_active: !row.enable,
          },
        });
        this.getList();
      } catch (err) {
        console.log(err);
      }
    },
    edit(rule_id, version) {
      const pramas = rule_id ? { rule_id } : {};
      if (!isNil(version)) {
        pramas.version = version;
      }
      this.$router.push({
        name: 'rule-edit',
        query: {
          ...pramas,
          object_id: this.objectId,
          object: this.object,
        },
      });
    },
    del({ id }) {
      this.delDialog.id = id;
      this.delDialog.visible = true;
    },
    // 弹窗关闭
    handleClose() {
      this.delDialog.visible = false;
    },
    async confirmDelete() {
      try {
        await this.$request({
          url: this.$api.deleteValidateRule,
          data: {
            id: this.delDialog.id,
          },
        });
        this.handleClose();
        this.searchList();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  .searchForm {
    flex: 1;
  }
}
.del-dialog {
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  .del-dialog-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #1c2028;
  }
  .del-dialog-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4d5762;
  }
}
</style>
