<template>
  <el-drawer
    :title="title"
    :visible.sync="drawerVisible"
    width="480px"
    :before-close="handleClose"
    :wrapper-closable="false"
  >
    <div class="drawer">
      <el-form
        class="drawer-body"
        ref="form"
        :rules="rules"
        :model="formData"
        :validate-on-rule-change="false"
      >
        <div class="type-title">
          元数据对象(API名称)
          <span class="tip-icon">*</span>
        </div>
        <el-form-item prop="name">
          <el-input
            v-model="formData.name"
            :disabled="isEdit"
            maxlength="50"
            show-word-limit
            placeholder="可填写英文，数字，下划线，请输入50字以内的名称"
          />
        </el-form-item>
        <div class="type-title">
          业务名称
          <span class="tip-icon">*</span>
        </div>
        <el-form-item prop="display_name">
          <el-input v-model="formData.display_name" placeholder="请输入内容" />
        </el-form-item>
        <div class="type-title">
          存储类型
          <span class="tip-icon">*</span>
        </div>
        <el-form-item class="storage-type">
          <el-radio :disabled="isEdit" v-model="formData.data_engine" :label="'PG'">OLTP</el-radio>
          <el-popover placement="bottom" trigger="hover" content="创建PostgreSQL对象表">
            <i slot="reference" class="el-icon-question"></i>
          </el-popover>
          <el-radio :disabled="isEdit" v-model="formData.data_engine" :label="'CK'">OLAP</el-radio>
          <el-popover placement="bottom" trigger="hover" content="创建ClickHouse对象表">
            <i slot="reference" class="el-icon-question"></i>
          </el-popover>
        </el-form-item>
        <div class="type-title">
          定义类型
          <span class="tip-icon">*</span>
        </div>
        <el-form-item>
          <el-radio
            v-for="item in defineTypeOption"
            :key="item.value"
            v-model="formData.define_type"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-form-item>
        <div class="type-title">
          访问控制
          <span class="tip-icon">*</span>
        </div>
        <el-form-item>
          <el-radio v-model="formData.access_type" :label="1">私有</el-radio>
          <el-radio v-model="formData.access_type" :label="2">公开读/私有写</el-radio>
          <el-radio v-model="formData.access_type" :label="3">公开读写</el-radio>
        </el-form-item>

        <div class="type-title">
          是否允许加锁
          <span class="tip-icon">*</span>
        </div>
        <el-form-item>
          <el-switch v-model="formData.enable_lock"></el-switch>
        </el-form-item>
        <div class="type-title">相关应用</div>
        <el-form-item>
          <el-select v-model="formData.appids" multiple>
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="type-title">包名</div>
        <el-form-item>
          <el-select v-model="formData.package" placeholder="请选择">
            <el-option
              v-for="item in packageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="type-title">备注描述</div>
        <el-form-item prop="description">
          <el-input type="textarea" v-model="formData.description" placeholder="请输入备注描述" />
        </el-form-item>
      </el-form>
      <div class="drawer-footer" style="text-align: center">
        <el-button class="drawer-footer-btn" type="primary" @click="handleConfirm">确定</el-button>
        <el-button type="info" @click="handleClose">取消</el-button>
      </div>
    </div>
    <messageBox ref="messageBox" />
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { DEFINE_TYPE } from '@/utils/constant';
import messageBox from '@/components/messageBox';

export default {
  name: 'ObjectDrawer',
  components: { messageBox },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appOptions: [], // 相关应用
      DefineType: DEFINE_TYPE,
      packageOptions: [], // 包列表
      rules: {
        name: [{ required: true, message: '请输入元数据对象名', trigger: 'blur' }],
        display_name: [{ required: true, message: '请输入业务名称', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList', 'getTenantInfo']),
    defineTypeOption() {
      const DefineType = [];
      Object.keys(this.DefineType).forEach((key) => {
        DefineType.push({
          label: this.DefineType[key],
          value: key,
        });
      });
      if (this.getTenantInfo.tenant_type === 'extend') {
        return DefineType.filter((item) => item.value === 'CUSTOM');
      }
      return DefineType;
    },
  },
  mounted() {
    this.getAppOptions();
    this.getPackageOptions();
  },
  methods: {
    // 获取相关应用列表
    async getAppOptions() {
      await this.$request({
        url: this.$api.getAppList,
        data: { page_size: 999999 },
      }).then((res) => {
        this.appOptions = res.data;
      });
    },
    async getPackageOptions() {
      await this.$request({
        url: this.$api.metaGetPackageList,
        data: {},
      })
        .then((res) => {
          this.packageOptions = res.list;
        })
        .catch(() => {});
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message.error('请输入必填项');
          return;
        }
        if (/^[a-zA-Z0-9_]{1,}$/.test(this.formData.name)) {
          this.$emit('handleConfirm');
        } else {
          this.$message.error('元数据对象名允许英文/数字/下划线');
        }
      });
    },
    handleClose() {
      this.$refs.messageBox.show({
        title: '放弃编辑后，本次编辑内容将会丢失，确认放弃？',
        titleIconType: 'warning',
        needCancel: true,
        cancelText: '取消',
        confirmType: 'primary',
        confirmAction: () => {
          this.$refs.messageBox.close();
          this.$emit('handleClose');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/drawer.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/radio.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/form.scss';
.drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  .drawer-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e5eaf2;
    padding: 12px 64px 0px 16px;
    .type-title {
      padding-bottom: 8px;
      .tip-icon {
        font-size: 14px;
        color: #e34d59;
      }
    }
    .el-form-item.storage-type {
      .el-radio {
        padding-right: 6px;
        margin-right: 0;
        &:last-of-type {
          margin-left: 16px;
        }
      }
    }
  }
  .drawer-footer {
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #e5eaf2;
    padding: 12px;
    .drawer-footer-btn {
      margin: 0 16px;
    }
  }
}
</style>
