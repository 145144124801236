<template>
  <el-card class="box-card">
    <!-- 表单 -->
    <div class="top">
      <el-form
        ref="form"
        :model="search"
        label-width="80px"
        :inline="true"
        class="searchForm"
        @keyup.enter.native="onSubmit"
      >
        <el-form-item label="租户id">
          <el-input v-model="search.tenant_id"></el-input>
        </el-form-item>
        <el-form-item label="选择环境">
          <el-select v-model="search.env" placeholder="请选择环境">
            <el-option
              v-for="item in ENVIRONMENT"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜 索</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="creatVisible = true">设置当前元数据版本号</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table :data="list" style="width: 100%" v-loading="loading">
      <el-table-column prop="tag" label="版本" />
      <el-table-column prop="tenant_id" label="租户id" />
      <el-table-column prop="description" label="备注" />
      <el-table-column prop="create_by" label="操作人" />
      <el-table-column prop="tag_time" label="操作时间" />
      <el-table-column prop="" label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="itemDetail(scope.row)">详情</el-button>
          <el-button type="text" size="small" @click="itemDiff(scope.row)">版本对比</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="search.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="search.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="设置版本"
      :visible.sync="creatVisible"
      width="550px"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form :model="creatFrom" :rules="creatRules" ref="creatFrom" label-position="top">
        <el-form-item label="版本号" prop="tag">
          <versionNumber
            @change="
              (val) => {
                creatFrom.tag = val;
              }
            "
          />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="creatFrom.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="creatVisible = false">取 消</el-button>
        <el-button type="primary" @click="setTag">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config';

import versionNumber from '@/components/versionNumber';
const ENVIRONMENT = [
  { label: '开发', value: 'Dev' },
  { label: '测试', value: 'Test' },
  { label: '预发布', value: 'Pre' },
  { label: '正式', value: 'Prod' },
];
const envs = {
  development: 'Dev',
  test: 'Test',
  preview: 'Pre',
  production: 'Prod',
};
export default {
  components: {
    versionNumber,
  },
  data() {
    return {
      loading: false,
      search: {
        env: '',
        tenant_id: '',
        page: 1,
        page_size: 10,
      },
      creatFrom: {
        tag: '',
        description: '',
      },
      total: 0,
      list: [],
      creatVisible: false,
      creatRules: {
        tag: {
          required: true,
          message: '请输入完整版本号',
          trigger: 'blur',
        },
      },
    };
  },
  computed: {
    ENVIRONMENT() {
      return ENVIRONMENT;
    },
    ...mapGetters('user', ['getTenantInfo']),
  },
  created() {
    this.search.tenant_id = `T${this.getTenantInfo.id}`;
    this.search.env = envs[config.env || 'production'] || 'Prod';
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        this.$request({
          url: this.$api.metaGetTagList,
          data: {
            env: this.search.env,
            tenant_id: this.search.tenant_id, // 租户id，不为空则查找对应租户id的版本
            limit: this.search.page_size,
            offset: this.search.page_size * (this.search.page - 1),
          },
          cb: ({ list, count }) => {
            this.list = list;
            this.total = Number(count);
            this.loading = false;
          },
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    onSubmit() {
      this.search.page_size = 10;
      this.search.page = 1;
      this.getList();
    },
    async setTag() {
      this.$refs.creatFrom.validate(async (valid) => {
        if (valid) {
          try {
            this.$request({
              url: this.$api.metaSetTag,
              data: { ...this.creatFrom },
              cb: () => {
                this.creatFrom = {
                  tag: '',
                  description: '',
                };
                this.$message({
                  message: '设置成功',
                  type: 'success',
                });
                this.creatVisible = false;
                this.search.page = 1;
                this.getList();
              },
            });
          } catch (err) {
            console.log(err);
            this.creatVisible = true;
          }
        } else {
          return false;
        }
      });
    },
    itemDetail({ tag, tenant_id }) {
      this.$router.push({
        name: 'versions-detail',
        query: {
          env: this.search.env,
          tag,
          tenant_id,
        },
      });
    },
    itemDiff({ tag, tenant_id }) {
      this.$router.push({
        name: 'versions-diff',
        query: {
          env: this.search.env,
          tag,
          tenant_id,
        },
      });
    },
    handleSizeChange(value) {
      this.search.page_size = value;
      this.search.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.top {
  display: flex;
  margin-bottom: 10px;
  .searchForm {
    flex: 1;
  }
}
</style>
