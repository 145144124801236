<template>
  <el-card class="box-card" v-loading="loading">
    <div v-if="sync_id">sync_id: {{ sync_id }}</div>
    <br />
    <div class="operation">
      <el-form :inline="true">
        <el-form-item label="操作类型">
          <el-select
            v-model="asyncForm.operator"
            multiple
            clearable
            @change="changeSelect"
            :class="{ 'select-all': asyncForm.operator.length >= operateTypes.length }"
          >
            <el-option
              :class="{ selected: asyncForm.operator.length >= operateTypes.length }"
              label="全选"
              value="全选"
              @click.stop.native="handleCheckAllChange()"
            />
            <el-option
              v-for="item in operateTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="selectAll">全 选</el-button>
          <el-button @click="getAsyncData">同 步</el-button>
          <!-- <el-button @click="getAsyncData" v-if="jurisdiction.asyncMeta">同 步</el-button>
          <el-button :disabled="true" v-else>同 步</el-button> -->
          <el-button @click="resetChecked">清 空</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 数据 -->
    <el-tree
      ref="tree"
      accordion
      :data="filterDiffData"
      show-checkbox
      node-key="diff_id"
      :props="defaultProps"
      @check="handleCheckChange"
    >
      <span
        slot-scope="{ node, data }"
        :class="{ 'custom-tree-node': true, diff: node.label === '字段差异列表' }"
      >
        <span>{{ node.label === '字段差异列表' ? '' : node.label }}</span>
        <div v-if="node.label === '字段差异列表'" :class="['code-diff']">
          <diffTable :list="data.fields.list" :keys="data.fields.keys" @diffjson="showDiff" />
        </div>
      </span>
    </el-tree>
    <!-- 同步失败列表 -->
    <el-dialog title="同步失败的diff_id列表" :visible.sync="showFails" width="500px">
      <span>{{ fails.join(',') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeFails">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 同步统计弹窗 -->
    <el-dialog title="此操作,变更数量如下" :visible.sync="tipDaig.visible" width="400px" center>
      <div v-for="(item, index) in tipDaig.asyncData" :key="index">
        {{ index + 1 }}、
        <span v-if="item.data.CREATE">新增{{ item.data.CREATE }}个{{ modelInfo[item.tier] }}</span>
        <span v-if="item.data.CREATE && item.data.MODIFY">,</span>
        <span v-if="item.data.MODIFY">更新{{ item.data.MODIFY }}个{{ modelInfo[item.tier] }}</span>
        ;
      </div>
      <div v-if="tipDaig.asyncData.length === 0">无变更</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="operation">确 定</el-button>
        <el-button @click="tipDaig.visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 差异展示 -->
    <code-diff
      :title="difDialog.title"
      :show="difDialog.visible"
      :old-string="difDialog.oldStr"
      :new-string="difDialog.newStr"
      :context="10"
      output-format="side-by-side"
      @cancel="difDialog.visible = false"
    />
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import diffTable from '@/components/diff/diffTable.vue';
import CodeDiff from '@/components/codeDiff';
import { getData, getAllNodes, filterTree } from '../data';
const operateTypes = [
  { value: 'CREATE', label: '新增' },
  { value: 'MODIFY', label: '修改' },
  { value: 'DELETE', label: '删除' },
];
export default {
  components: {
    diffTable,
    CodeDiff,
  },
  data() {
    return {
      modelInfo: {
        object: '对象',
        fields: '字段',
        indexes: '索引',
      },
      operateTypes,
      asyncForm: {
        operator: operateTypes.map((item) => {
          return item.value;
        }),
      },
      loading: false,
      tableData: {},
      sync_id: '',
      DiffObject: [],
      filterDiffData: [],
      defaultProps: {},
      checkList: {},
      checkedNodes: [],
      fails: [],
      showFails: false,
      tipDaig: {
        visible: false,
        asyncData: [],
      },
      difDialog: {
        visible: false,
        oldStr: '',
        newStr: '',
        title: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        asyncMeta: this.getElementList.includes('async-meta'),
      };
    },
    allKeys() {
      return this.filterDiffData
        .map((item) => {
          return item.children.map((item2) => {
            if (['indexes', 'fields'].includes(item2.label)) {
              return item2.children.map((item3) => {
                return item3.diff_id || '';
              });
            }
            return item2.diff_id || '';
          });
        })
        .join()
        .split(',')
        .filter((item) => item);
    },
    allNodes() {
      return getAllNodes(this.filterDiffData);
    },
  },
  async created() {
    this.init();
  },
  methods: {
    handleCheckAllChange() {
      const operator = this.asyncForm.operator.filter((item) => {
        return item !== '全选';
      });

      if (operator.length < operateTypes.length) {
        this.asyncForm.operator = operateTypes.map((item) => {
          return item.value;
        });
      } else {
        this.asyncForm.operator = [];
      }
    },
    changeSelect() {
      this.loading = true;
      setTimeout(() => {
        this.filterDiffData = filterTree(this.DiffObject, this.asyncForm.operator);
        this.resetChecked();
        this.$nextTick(() => {
          this.loading = false;
        });
      }, 300);
    },
    showDiff({ oldStr, newStr, title }) {
      this.difDialog = {
        visible: true,
        oldStr,
        newStr,
        title,
      };
    },
    selectAll() {
      this.checkedNodes = this.allNodes;
      this.checkList = [...this.allKeys];
      this.$refs.tree.setCheckedKeys(this.allKeys);
    },
    resetChecked() {
      this.checkList = [];
      this.checkedNodes = [];
      this.$refs.tree.setCheckedKeys([]);
    },
    init() {
      const { env, tag, tenant_id } = this.$route.query;
      if (!env || !tag || !tenant_id) {
        this.$router.replace({
          name: 'versions',
        });
      }
      this.sync_id = '';
      this.checkList = {};
      this.fails = [];
      this.getDiff(env, tag, tenant_id);
    },
    async getDiff(env, tag, tenant_id) {
      this.loading = true;
      try {
        this.$request({
          url: this.$api.metaDiffMetadataByTag,
          data: {
            env,
            tag,
            tenant_id,
          },
          cb: ({ items, sync_id: syncId }) => {
            this.DiffObject = getData(items);
            this.filterDiffData = this.DiffObject;
            this.sync_id = syncId;
            this.loading = false;
          },
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    handleCheckChange(data, { checkedKeys, checkedNodes }) {
      this.checkedNodes = checkedNodes.filter((item) => {
        return item.tier && item.diff_id;
      });
      this.checkList = checkedKeys.filter((item) => {
        return item;
      });
    },
    getAsyncData() {
      if (!Array.isArray(this.checkList) || this.checkList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择数据后,执行同步操作',
        });
        return;
      }
      console.log(this.checkedNodes);
      const asyncData = {};

      this.checkedNodes.forEach((node) => {
        const { action, tier } = node;
        if (!asyncData[tier]) {
          asyncData[tier] = {};
        }
        if (!asyncData[tier][action]) {
          asyncData[tier][action] = 0;
        }
        asyncData[tier][action]++;
      });
      this.tipDaig.asyncData = [];
      ['object', 'fields', 'indexes'].forEach((tier) => {
        if (asyncData[tier]) {
          this.tipDaig.asyncData.push({
            tier,
            data: asyncData[tier],
          });
        }
      });
      this.tipDaig.visible = true;
    },
    operation() {
      try {
        this.$request({
          url: this.$api.metaSyncMetadataByTag,
          data: {
            sync_id: this.sync_id,
            diff_ids: this.checkList,
          },
          cb: ({ fails }) => {
            if (fails && fails.length > 0) {
              this.fails = fails;
              this.showFails = true;
              return;
            }
            this.$message({
              message: '同步成功',
              type: 'success',
            });
            this.tipDaig.visible = false;
            this.init();
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    closeFails() {
      this.showFails = false;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree-node {
  .el-tree-node {
    .el-tree-node {
      //   .el-checkbox {
      //     display: none;
      // }
      .el-tree-node__content {
        height: auto !important;
      }
      .custom-tree-node {
        max-width: 97%;
      }
      .diff {
        background-color: #fff;
        transform: translateX(-23px);
        z-index: 8;
      }
    }
  }
}
::v-deep .el-select {
  // 全部选择时只显示全部不显示标签
  &.select-all {
    .el-select__tags {
      & > span::before {
        content: '';
        margin-left: 5px;
        display: block;
        background: url('https://baike-med-1256891581.file.myqcloud.com/2022092/b3a4c050-3ecd-11ed-89ce-4ff3ab5f0cf9_0.png')
          no-repeat;
        height: 25px;
        width: 43px;
      }
      .el-tag {
        display: none;
      }
    }
  }
}
</style>
