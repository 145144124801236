<template>
  <div class="container">
    <el-tabs class="top-tab" v-model="activeStatus" type="card">
      <el-tab-pane label="已启用" name="1"></el-tab-pane>
      <el-tab-pane label="已停用" name="2"></el-tab-pane>
    </el-tabs>
    <!-- 功能 -->
    <div class="table-container">
      <div class="table-action-row">
        <div class="table-action-button">
          <el-button type="primary" @click="addNotice">新建通知模板</el-button>
        </div>
      </div>
      <List ref="list" :request="getList" :layout="layout">
        <template v-slot:table-action>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button class="text-button" type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button
                v-if="activeStatus === '1'"
                class="text-button"
                type="text"
                @click="changeStatus(scope.row, '2')"
              >
                停用
              </el-button>
              <el-button
                v-else
                class="text-button"
                type="text"
                @click="changeStatus(scope.row, '1')"
              >
                启用
              </el-button>
              <el-button
                v-if="activeStatus === '2'"
                class="text-button"
                type="text"
                @click="deleteNotice(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </template>
      </List>
    </div>
    <messageBox ref="messageBox" />
  </div>
</template>

<script>
import List from '@/components/table';
import messageBox from '@/components/messageBox';
import { mapGetters, mapActions } from 'vuex';
import { NOTICE_TYPE } from '@/utils/constant';

export default {
  name: 'NoticeList',
  components: { messageBox, List },
  data() {
    return {
      // 加载标志 防止过早刷新列表
      loading: false,
      // 列表字段
      layout: [
        { name: 'template_code', label: '模板ID' },
        { name: 'name', label: '模板名称' },
        { name: 'subject', label: '业务实体' },
        { name: 'messaging_type', label: '通知类型' },
        { name: 'remark', label: '备注', limit: 8 },
        { name: 'update_by', label: '最后更新人' },
        { name: 'update_time', label: '最后更新时间' },
      ],
      // 当前类型
      activeStatus: '1',
      // 类型列表
      tabList: [
        { name: '1', label: '已启用' },
        { name: '2', label: '已停用' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      objectList: 'notice/getObjectList',
    }),
  },
  watch: {
    activeStatus() {
      if (this.loading) this.refresh();
    },
  },
  created() {
    if (this.$route.query.listTab) this.activeStatus = this.$route.query.listTab;
    this.loading = true;
  },
  methods: {
    ...mapActions({
      getObjectList: 'notice/requestObjectList',
    }),
    getList({ pageIndex, pageSize }) {
      const request = this.$request({
        url: this.$api.getMessagingTemplateList,
        data: {
          page: pageIndex,
          page_size: pageSize,
          active_status: Number(this.activeStatus) === 1,
        },
      });
      return Promise.all([request, this.getObjectList()]).then(([{ data, total = 0 }]) => {
        data.forEach((item) => {
          item.subject = this.getName(item.subject);
          if (item.messaging_type && item.messaging_type.length) {
            item.messaging_type = item.messaging_type.map((i) => NOTICE_TYPE[i]).join(',');
          }
        });
        return {
          list: data,
          total,
        };
      });
    },
    getName(name) {
      const result = this.objectList.filter((i) => i.name === name);
      if (result.length) {
        if (result[0].display_name) {
          return `${result[0].display_name}-${result[0].name}`;
        }
        return result[0].name;
      }
      return '';
    },
    refresh() {
      this.$refs.list.resetPage();
      this.$refs.list.getList();
    },
    addNotice() {
      this.$router.push({
        name: 'notice-detail',
      });
    },
    edit({ template_code }) {
      this.$router.push({
        name: 'notice-detail',
        query: {
          id: template_code,
          listTab: this.activeStatus,
        },
      });
    },
    // 启用/禁用
    changeStatus({ template_code, name }, status) {
      // 标题
      let title;
      // 图标类型
      let type;
      switch (status) {
        case '1':
          title = `确定要启用【${name}】模版吗？`;
          type = 'warning';
          break;
        case '2':
          title = `确定要停用【${name}】模版吗？`;
          type = 'danger';
          break;
      }

      this.$refs.messageBox.show({
        title,
        noBody: true,
        titleIconType: type,
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.updateMessagingTemplateStatus,
            data: {
              template_code,
              active_status: status === '1',
            },
            cb: () => {
              this.$message.success('操作成功');
              this.$refs.messageBox.close();
              this.refresh();
            },
          });
        },
      });
    },
    deleteNotice({ template_code, name }) {
      this.$refs.messageBox.show({
        title: `确定要删除【${name}】模版吗？`,
        noBody: true,
        titleIconType: 'warning',
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.deleteMessagingTemplate,
            data: {
              template_code,
            },
            cb: () => {
              this.$message.success('删除成功');
              this.$refs.messageBox.close();
              this.refresh();
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/tab.scss';

.container {
  display: flex;
  flex-direction: column;
}
.top-tab {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 16px;
  padding-right: 16px;
}
.table-container {
  flex: 1;
  margin: 16px 0;
  padding: 16px;
  background: #fff;
  .table-action-row {
    margin-bottom: 16px;
  }
  .text-button {
    margin-right: 5px;
  }
}
</style>
