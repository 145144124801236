import { api, request } from '@/api';

export default {
  namespaced: true,
  getters: {
    getBusinessTypeList(state) {
      return state.businessTypeList;
    },
    getBusinessModuleList(state) {
      return state.businessModuleList;
    },
    getAppList(state) {
      return state.appList;
    },
    getStartNodeObject(state) {
      return state.startNodeObject;
    },
    getGatewayInclusiveState(state) {
      return state.gatewayInclusiveState;
    },
    getSelectdAssigneers(state) {
      return state.selectdAssigneers;
    },
  },
  state: {
    appList: [],
    businessTypeList: [],
    businessModuleList: [],
    appList: [],
    startNodeObject: '', // 开始节点 已选元数据对象名
    gatewayInclusiveState: false,
    selectdAssigneers: [], // 当前模型中已选的审批人
  },

  mutations: {
    setAppList(state, data) {
      state.appList = data;
    },
    setBusinessTypeList(state, data) {
      state.businessTypeList = data;
    },
    setBusinessModuleList(state, data) {
      state.businessModuleList = data;
    },
    setAppList(state, data) {
      state.appList = data;
    },
    setStartNodeObject(state, data) {
      state.startNodeObject = data;
    },
    setGatewayInclusiveState(state, data) {
      state.gatewayInclusiveState = data;
    },
    setSelectdAssigneers(state, data) {
      state.selectdAssigneers = data;
      console.log('setSelectdAssigneers=====', data);
    },
  },
  actions: {
    async requestBusinessOptionList(context) {
      if (context.state.businessTypeList.length > 0) return;
      const {
        list: [data],
      } = await request({
        url: api.metaGetObjectByName,
        data: {
          objects: ['approval_applies'],
        },
      });
      const Type = data.fields.filter((i) => i.name === 'type')[0].select_one_option.options;
      context.commit('setBusinessTypeList', Type);

      const Module = data.fields.filter((i) => i.name === 'module')[0].select_one_option.options;
      context.commit('setBusinessModuleList', Module);
    },
    async requestAppList(context) {
      if (context.state.appList.length > 0) return;
      const { data } = await request({
        url: api.getAppList,
        data: {
          page_size: 100000,
        },
      });
      context.commit(
        'setAppList',
        data.map((item) => ({
          value: `${item.id}`,
          label: item.name,
        })),
      );
    },
    async requestModelAssignersInfo({ commit }, accountArr) {
      const requestArr = accountArr.map((account) => {
        return request({
          url: api.getUserList,
          showLoading: false,
          data: {
            page_size: 1,
            page: 1,
            account: account.replace(/\(/g, '').replace(/\)/g, ''),
          },
        });
      });
      Promise.all(requestArr)
        .then((res) => {
          let result = [];
          res.map((item = {}) => {
            const { data = [] } = item;
            result = [...result, ...data];
          });
          result = result
            .map((item) => ({ ...item, account: item.account }))
            .map((item) => ({
              value: item.account,
              label: item.name,
            }));
          const mapInfo = new Map();
          result = result.filter((item) => {
            if (!mapInfo.has(item.value)) {
              mapInfo.set(item.value, 1);
              return true;
            }
            return false;
          });
          commit('setSelectdAssigneers', result);
        })
        .catch((err) => console.error(err));
    },
  },
};
