<template>
  <div class="container">
    <div class="container-title">升级维护配置</div>
    <div class="config">
      <div class="config-title">升级维护配置</div>
      <div class="config-item">
        <div class="config-name">启用升级维护</div>
        <el-switch class="config-form" v-model="onUpdate"></el-switch>
      </div>
      <div class="config-item">
        <div class="config-name">配置文字信息</div>
        <el-input
          class="config-form"
          type="textarea"
          :rows="2"
          maxlength="30"
          placeholder="请输入内容"
          show-word-limit
          v-model="updateMessage"
        ></el-input>
      </div>
      <div class="config-confirm">
        <el-button type="primary" @click="confirm">保存</el-button>
        <el-button type="info" @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateManage',
  data() {
    return {
      onUpdate: false,
      updateMessage: '',
      oldData: null,
    };
  },
  mounted() {
    const cb = (data) => {
      console.log(data);
      this.onUpdate = data.status === 2;
      this.updateMessage = data.tips;
      this.oldData = data;
    };
    this.$request({ url: this.$api.getUpdateInfo, data: {}, cb });
  },
  methods: {
    confirm() {
      console.log(this.onUpdate, this.updateMessage);
      if (this.updateMessage === '') {
        this.$message.error('配置文字信息不能为空');
        return;
      }
      const cb = () => {
        this.$message.success('修改成功');
      };
      this.$request({
        url: this.$api.setUpdateInfo,
        data: {
          status: this.onUpdate ? 2 : 1,
          tips: this.updateMessage,
        },
        cb,
      });
    },
    cancel() {
      this.onUpdate = this.oldData.status === 2;
      this.updateMessage = this.oldData.tips;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  .container-title {
    padding-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #1d2129;
  }
  .config {
    flex-grow: 1;
    padding: 24px;
    background: #fff;
    .config-title {
      padding-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #1d2129;
    }
    .config-item {
      display: flex;
      padding-left: 54px;
      padding-bottom: 24px;
      .config-name {
        padding-right: 16px;
        padding-bottom: 24px;
        line-height: 22px;
        color: #6a7684;
      }
      .config-form {
        max-width: 320px;
      }
    }
    .config-confirm {
      padding-left: 150px;
      ::v-deep.el-button--info {
        border: 1px solid transparent;
        background-color: #f2f3f8;
        color: #1c2028;
      }
    }
  }
}
</style>
