<template>
  <div>
    <div class="config-content">
      <div class="title">
        当同一个审批人重复审批同一单据时
        <span class="title-tip">（当同一个审批人重复审批同一单据时）</span>
      </div>
      <div class="radio-group">
        <el-radio class="radio" :value="value" :label="1" @input="(e) => $emit('update', e)">
          仅首个节点需审批，其余自动同意
        </el-radio>
        <el-radio class="radio" :value="value" :label="2" @input="(e) => $emit('update', e)">
          仅连续审批时自动同意
        </el-radio>
        <el-radio class="radio" :value="value" :label="3" @input="(e) => $emit('update', e)">
          每个节点都需要审批
        </el-radio>
      </div>
    </div>
    <div class="config-content" style="margin-top: 16px">
      <div class="title">审批任务单查看设置</div>
      <div class="content-wrapper">
        <div class="left-bar">
          选择用户节点
          <el-tooltip
            effect="dark"
            content="仅展示选择处理人选项中设置为”指定审批人“或”通过审批人属性匹配“以下两个选项的用户节点"
            placement="top"
          >
            <i class="iconfont icon-question" />
          </el-tooltip>
        </div>
        <div class="right-bar">
          <div class="intro-info">
            申请提交后，审批任务尚未流转至审批人时，通过该设置，可使审批人提前查看到审批单，但无权操作审批。待流程流转至该审批人时，可正常操作审批相关功能
          </div>
          <el-select
            :value="advanceSelectAssigners"
            multiple
            placeholder="请选择"
            @change="advanceSelectAssignersChange"
            style="width: 100%"
          >
            <el-option
              v-for="item in enableSelectdAssigneers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="config-content" style="margin-top: 16px">
      <div class="title">当提交人与相邻节点审批人为同一人时</div>
      <div class="content-wrapper">
        <div class="radio-group">
          <el-radio class="radio" :value="neighborSet" :label="1" @input="neighborSetting">
            审批人节点审批后，自动交由岗位上级审批
          </el-radio>
          <el-radio class="radio" :value="neighborSet" :label="2" @input="neighborSetting">
            审批人节点审批后，按模型配置顺序执行
          </el-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdvanceConfigAutoRepeat',
  computed: {
    ...mapGetters({
      enableSelectdAssigneers: 'workflow/getSelectdAssigneers',
    }),
  },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    advanceSelectAssigners: {
      type: Array,
      default: () => [],
    },
    neighborSet: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    value(newval) {
      console.log(newval);
    },
  },
  methods: {
    advanceSelectAssignersChange(e) {
      this.$emit('updateAssigners', e);
    },
    neighborSetting(e) {
      this.$emit('neighborSetting', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
.config-content {
  padding: 16px;
  .title {
    font-weight: 700;
    color: #1c2028;
    margin-bottom: 16px;
  }
  .title-tip {
    font-weight: 700;
    color: #667082;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    .radio {
      margin-bottom: 16px;
      ::v-deep.el-radio__label {
        font-weight: 400;
        color: #384050;
      }
    }
  }
  .content-wrapper {
    display: flex;
    .left-bar {
      color: #3c4957;
      font-size: 14px;
      font-weight: 400;
      .iconfont {
        font-size: 14px;

        color: #3c4957;
      }
    }
    .right-bar {
      margin-left: 16px;
      width: 400px;
      .intro-info {
        color: #98a2b2;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}
</style>
