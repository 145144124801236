<template>
  <div class="root">
    <!-- 左侧节点列表 -->
    <item-panel :graph="graph" />
    <!-- 挂载节点 -->
    <div id="canvasPanel" ref="canvasPanel" @dragover.prevent @click.stop />
    <!-- 节点配置面板 -->
    <configuration
      v-if="configVisible"
      :data="config"
      :info="info"
      @cancel="closeConfiguration"
      :graph="graph"
    />
    <!-- 上下文工具菜单 -->
    <context-menu
      :show-contexmenu="showContexmenu"
      :point-x="left"
      :point-y="top"
      :model-data="config"
      :graph="graph"
      :model-info="modelInfo"
      @close="closeContextMenu"
      @open-edit="configVisible = true"
    />
  </div>
</template>

<script>
import G6 from '@antv/g6';
import _ from 'lodash';
import registerFactory from './graph/graph';
import ItemPanel from './ItemPanel.vue';
import configuration from './configuration';
import {
  nodeTypes,
  validateStartNode,
  validateUserNode,
  validateDelaySettingNode,
  validateOfficalAccountNode,
  validateInmailNode,
} from './config.js';
import { getValue } from '@/utils/util';
import insertCss from 'insert-css';
import { mapMutations } from 'vuex';
import contextMenu from './components/context-menu.vue';

insertCss(`
  .g6-component-tooltip {
    background: #1A2734;
    opacity: 0.9;
    border-radius: 2px;
    padding: 10px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    color: #fff;
  }
`);
const tooltip = new G6.Tooltip({
  offsetX: 5,
  offsetY: 5,
  // the types of items that allow the tooltip show up
  // 允许出现 tooltip 的 item 类型
  itemTypes: ['node'],
  // custom the tooltip's content
  // 自定义 tooltip 内容
  getContent: (e) => {
    const outDiv = document.createElement('div');
    outDiv.style.width = 'fit-content';
    const model = e.item.getModel();
    // outDiv.style.padding = '0px 0px 20px 0px';
    outDiv.innerHTML = `
      ${model.label || ''}
    `;
    return outDiv;
  },
  shouldBegin: (e) => {
    const model = e.item.getModel();
    return ['time_boundary_event'].includes(model.type);
  },
});
const defaultJson = JSON.stringify({ nodes: [], edges: [] });
export default {
  components: {
    ItemPanel,
    configuration,
    contextMenu,
  },
  props: {
    editData: {
      type: String,
      default: defaultJson,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'drag-shadow-node',
      graph: {},
      highLight: {
        undo: false,
        redo: false,
      },
      // 保存线条样式
      lineStyle: {
        type: 'line',
        width: 1,
      },
      // 节点信息
      labelCfg: {},
      configVisible: false,
      config: null,
      // 提示信息
      tooltip: '',
      top: 0,
      left: 0,
      localData: null,
      showContexmenu: false,
      modelInfo: null,
    };
  },
  created() {
    try {
      this.localData = JSON.parse(this.editData);
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    // 创建画布
    this.$nextTick(() => {
      // 加载失败时不初始化
      if (this.localData) {
        this.createGraphic();
        this.initGraphEvent();
      }
    });
  },
  beforeDestroy() {
    this.graph.destroy && this.graph.destroy();
  },
  methods: {
    ...mapMutations('workflow', ['setGatewayInclusiveState']),
    createGraphic() {
      const minimap = new G6.Minimap({
        size: [200, 100],
      });
      // new G6.Graph({
      const cfg = registerFactory(G6, {
        width: window.innerWidth,
        height: window.innerHeight,
        // renderer: 'svg',
        layout: {
          type: '', // 位置将固定
        },
        // Set groupByTypes to false to get rendering result with reasonable visual zIndex for combos
        groupByTypes: false,
        background: {
          color: '#f2f3f8',
        },
        defaultCombo: {
          type: 'rect',
          /* The minimum size of the combo. combo 最小大小 */
          size: [50, 50],
          labelCfg: {
            /* label's offset to the keyShape */
            // refY: 10,
            /* label's position, options: center, top, bottom, left, right */
            position: 'top',
            /* label's style */
            style: {
              fontSize: 0,
            },
          },
        },
        // 所有节点默认配置
        defaultNode: {
          type: 'rect-node',
          style: {
            radius: 10,
            // width: 100, // 默认宽度
            // height: 50, // 默认高度
            cursor: 'move',
            fill: '#ecf3ff',
            stroke: 'transparent',
          },
          labelCfg: {
            fontSize: 20,
            style: {
              cursor: 'move',
            },
          },
        },
        // 所有边的默认配置
        defaultEdge: {
          type: 'polyline-edge', // 扩展了内置边, 有边的事件
          style: {
            radius: 5,
            offset: 15,
            stroke: '#98A2B2',
            lineAppendWidth: 10, // 防止线太细没法点中
            endArrow: true,
          },
        },
        // 覆盖全局样式
        nodeStateStyles: {
          'nodeState:default': {
            opacity: 1,
          },
          'nodeState:hover': {
            opacity: 0.8,
          },
          'nodeState:selected': {
            opacity: 0.6,
          },
        },
        // 默认边不同状态下的样式集合
        edgeStateStyles: {
          'edgeState:default': {
            stroke: '#aab7c3',
          },
          'edgeState:selected': {
            stroke: '#1890FF',
          },
          'edgeState:hover': {
            animate: true,
            animationType: 'dash',
            stroke: '#1890FF',
          },
        },
        modes: {
          // 支持的 behavior
          default: [
            'drag-canvas',
            'drag-shadow-node',
            'drag-node',
            'drag-combo',
            'canvas-event',
            'delete-item',
            'select-node',
            'hover-node',
            'active-edge',
          ],
          originDrag: [
            'drag-canvas',
            'drag-node',
            'canvas-event',
            'delete-item',
            'select-node',
            'hover-node',
            'active-edge',
          ],
        },
        plugins: [minimap, tooltip],
        // ... 其他G6原生入参
      });

      this.graph = new G6.Graph(cfg);
      this.graph.read(this.localData); // 读取数据
    },
    // 初始化图事件
    initGraphEvent() {
      this.graph.on('drop', (e) => {
        e.preventDefault();
        const { originalEvent } = e;
        if (originalEvent.dataTransfer) {
          e.stopPropagation();
          const transferData = originalEvent.dataTransfer.getData('dragComponent');
          if (transferData) {
            // 拿到已添加到canvas的model信息
            const { combos } = this.graph.save();
            let originChildNodes = [];
            const dropedCombos = _.cloneDeep(combos)
              .map((combo) => ({
                ...combo,
                isInCombo:
                  e.x >= combo.x - combo.style.width / 2 &&
                  e.x <= combo.x + combo.style.width / 2 &&
                  e.y >= combo.y - combo.style.height / 2 &&
                  e.y <= combo.y + combo.style.height / 2,
              }))
              .find((combo) => combo.isInCombo);
            if (dropedCombos) {
              originChildNodes =
                dropedCombos.children.map((node) => {
                  return node.id;
                }) || [];
              this.graph.uncombo(dropedCombos.id);
            }
            const addedModelInfo = this.addNode(transferData, e);
            if (nodeTypes[addedModelInfo.type]?.onDrop) {
              nodeTypes[addedModelInfo.type].onDrop.call(this, addedModelInfo, originChildNodes);
            }
          }
        }
        const breathingNodes = this.graph.findAllByState('node', 'backgroundBreathing');
        breathingNodes.forEach((node) => {
          this.graph.setItemState(node, 'backgroundBreathing', false);
        });
      });

      this.graph.on('node:drop', (e) => {
        e.item.getOutEdges().forEach((edge) => {
          edge.clearStates('edgeState');
        });
      });

      this.graph.on('on-node-dragend', (e) => {
        const model = e.item.get('model');
        this.graph.updateItem(e.item, {
          ...model,
          x: e.x,
          y: e.y,
        });
        if (nodeTypes[model.type]?.onNodeDragend) {
          nodeTypes[model.type].onNodeDragend.call(this, model);
        }
        const breathingNodes = this.graph.findAllByState('node', 'backgroundBreathing');
        breathingNodes.forEach((node) => {
          this.graph.setItemState(node, 'backgroundBreathing', false);
        });
        // this.clearUnValidateComboNodes();
        this.clearSingleChlidCombo();
      });
      this.graph.on('on-node-dragstart', (e) => {
        const model = e.item.get('model');
        this.$nextTick(() => {
          this.showContexmenu = false;
        });
        if (nodeTypes[model.type]?.comboTypes?.length) {
          const { comboTypes } = nodeTypes[model.type];
          this.graph.getNodes().forEach((node) => {
            const nodeType = node.get('model').type;
            if (comboTypes.includes(nodeType)) {
              this.graph.setItemState(node, 'backgroundBreathing', true);
            }
          });
        }
      });
      this.graph.on('afteradditem', ({ item, model }) => {
        const nodes = this.graph.getNodes();
        const hasInclusiveGateway = nodes.find(
          (node) => node.get('model').type === 'gateway_inclusive',
        );
        this.setGatewayInclusiveState(!!hasInclusiveGateway);
        console.log('item===', item, 'model===', model);
        if (model.type.includes('edge')) {
          if (model.source.includes('gateway_inclusive')) {
            this.graph.updateItem(item, {
              ...model,
              expr_name: '',
              name: '',
              expr_content: '',
              conditional_conig: 1,
              formed_expr: {
                conditional_symbol: 'and',
                conditionals: [],
              },
              metadata: {
                backward_setting: 1,
                refuse_setting: 1,
              },
            });
          }
          if (model.source.includes('gateway') && !model.source.includes('inclusive')) {
            this.graph.updateItem(item, {
              ...model,
              expr_name: '',
              name: '',
              expr_content: '',
              conditional_conig: 1,
              formed_expr: {
                conditional_symbol: 'and',
                conditionals: [],
              },
              metadata: {},
            });
          }
        }
      });
      this.graph.on('afterremoveitem', () => {
        const nodes = this.graph.getNodes();
        const hasInclusiveGateway = nodes.find(
          (node) => node.get('model').type === 'gateway_inclusive',
        );
        this.setGatewayInclusiveState(!!hasInclusiveGateway);
      });
      this.graph.on('node:click', (e) => {
        const itemModel = e.item.get('model');
        this.graph.setItemState(e.item, 'nodeState:selected', true);
        const selectedNodes = this.graph.findAllByState('node', 'nodeState:selected');
        selectedNodes.forEach((node) => {
          if (node.get('model').id !== itemModel.id) {
            node.clearStates(['nodeState:hover', 'nodeState:selected']);
          }
        });
      });
      this.graph.on('edge:click', () => {
        const selectedNodes = this.graph
          .getNodes()
          .filter((node) => node.hasState('nodeState:selected'));
        selectedNodes.forEach((node) => {
          node.clearStates(['nodeState:selected', 'nodeState:hover']);
        });
      });
      this.graph.on('after-node-selected', (e) => {
        this.configVisible = false;
        this.showContexmenu = false;
        console.log('e=====', e);
        if (e && e.item) {
          const model = e.item.get('model');
          console.log('model====', model);
          const { x, y, style } = model;
          if (nodeTypes[model.type]?.afterNodeSelected) {
            nodeTypes[model.type].afterNodeSelected.call(this, model);
          } else {
            this.getNodeInfo(model, 'node');
            console.log('x===', x);
            console.log('y==', y);
            this.left = e.clientX + style.width / 2;
            this.top = e.clientY - style.height / 2;
            this.$nextTick(() => {
              this.showContexmenu = true;
            });
          }
        }
      });

      this.graph.on('on-node-mouseenter', (e) => {
        if (e && e.item) {
          e.item.getOutEdges().forEach((edge) => {
            edge.clearStates('edgeState');
            edge.setState('edgeState', 'hover');
          });
        }
      });

      this.graph.on('on-node-mousemove', () => {});

      this.graph.on('on-node-mouseleave', (e) => {
        if (e && e.item) {
          e.item.getOutEdges().forEach((edge) => {
            edge.clearStates('edgeState');
          });
        }
      });

      this.graph.on('before-node-removed', ({ target, callback }) => {
        console.log(target);

        // setTimeout(() => {
        // 确认提示
        callback && callback(true);
        // }, 1000);
      });

      this.graph.on('after-node-dblclick', (e) => {
        if (e && e.item) {
          console.log(e.item);
          const model = e.item.get('model');
          if (nodeTypes[model.type]?.afterNodeDblclick) {
            nodeTypes[model.type].afterNodeDblclick.call(this, model);
          }
        }
      });

      this.graph.on('after-edge-selected', (e) => {
        this.configVisible = false;
        if (e && e.item) {
          const model = e.item.get('model');
          this.getNodeInfo(model, 'edge');
          this.graph.updateItem(e.item, {
            // shape: 'line-edge',
            style: {
              radius: 10,
              lineWidth: 2,
            },
          });
        }
        this.$nextTick(() => {
          this.configVisible = !!e;
        });
      });
      this.graph.on('on-edge-mousemove', () => {});

      this.graph.on('on-edge-mouseleave', () => {});

      this.graph.on('before-edge-add', ({ source, target, sourceAnchor, targetAnchor }) => {
        setTimeout(() => {
          const sourceId = source.get('id');
          const targetId = target.get('id');
          this.graph.addItem('edge', {
            id: `${sourceId}_to_${targetId}`, // edge id
            source: sourceId,
            target: targetId,
            sourceAnchor,
            targetAnchor,
            // label:  'edge label',
          });
        }, 100);
      });

      // Combo事件
      this.graph.on('combo:mouseenter', (evt) => {
        const { item } = evt;
        this.graph.setItemState(item, 'active', true);
      });
      this.graph.on('combo:mouseleave', (evt) => {
        const { item } = evt;
        this.graph.setItemState(item, 'active', false);
      });
      this.graph.on('combo:click', (evt) => {
        const { item } = evt;
        this.graph.setItemState(item, 'selected', true);
      });
      this.graph.on('canvas:click', () => {
        this.graph.getCombos().forEach((combo) => {
          this.graph.clearItemStates(combo);
        });
        this.graph.getNodes().forEach((node) => {
          this.graph.clearItemStates(node);
        });
        this.graph.getEdges().forEach((edge) => {
          this.graph.clearItemStates(edge);
        });
      });
    },
    getNodeInfo(model, nodeType) {
      this.modelInfo = model;
      const { id, label, style, type, fullname, description } = model;
      this.config = {
        id,
        nodeType,
        type,
        label,
        fullname,
        description,
        style: {
          fill: style.fill,
          width: style.width || 80,
          height: style.height || 80,
          label: label || '',
          r: style.r || style.width / 2,
        },
        labelCfg: {
          style: {
            fill: getValue(model, '.labelCfg.style.fill') || '#666',
            fontSize: getValue(model, '.labelCfg.style.fontSize') || 12,
          },
        },
        // business_config,
      };
      if (nodeType === 'edge') {
        const { source, target } = model;
        const sourceModel = this.graph.findById(source).get('model');
        const targetModel = this.graph.findById(target).get('model');
        // 初始节点是 排他网关 包容网关
        if (sourceModel.type === 'gateway') {
          this.config = {
            ...this.config,
            expr_name: model.expr_name || '',
            name: model.expr_name || '',
            expr_content: model.expr_content || '',
            source_type: sourceModel.type,
            target_type: targetModel.type,
            conditional_conig: model.conditional_conig ?? 1,
            formed_expr: {
              conditional_symbol: model.formed_expr?.conditional_symbol ?? 'and',
              conditionals: model.formed_expr?.conditionals ?? [],
            },
            metadata: {},
          };
          return;
        }
        if (sourceModel.type === 'gateway_inclusive') {
          this.config = {
            ...this.config,
            expr_name: model.expr_name || '',
            name: model.expr_name || '',
            expr_content: model.expr_content || '',
            source_type: sourceModel.type,
            target_type: targetModel.type,
            conditional_conig: model.conditional_conig ?? 1,
            formed_expr: {
              conditional_symbol: model.formed_expr?.conditional_symbol ?? 'and',
              conditionals: model.formed_expr?.conditionals ?? [],
            },
            metadata: {
              backward_setting: model.metadata?.backward_setting ?? 1,
              refuse_setting: model.metadata?.refuse_setting ?? 1,
            },
          };
          return;
        }
      }
      if (nodeType === 'node') {
        const defaultConfig = nodeTypes[type].default_config || {};
        Object.keys(defaultConfig).forEach((key) => {
          this.config[key] = model[key];
          // eslint-disable-next-line no-prototype-builtins
          if (!model.hasOwnProperty(key)) {
            this.config[key] = defaultConfig[key];
          }
        });
      }
      delete this.config.expr_name;
      delete this.config.expr_content;
    },
    deleteNode(item) {
      this.graph.removeItem(item);
    },
    addNode(transferData, { x, y } = {}) {
      const {
        label,
        fullname,
        description,
        shape,
        fill,
        width,
        height,
        default_config: defaultConfig = {},
      } = JSON.parse(transferData);
      // 获取id
      let id = 1;
      const { nodes } = this.graph.save();
      nodes.forEach((node) => {
        const nodeId = Number(node.id.split('_').pop());
        if (nodeId >= id) {
          id = nodeId + 1;
        }
      });

      const { defaultStyle } = nodeTypes[shape];

      // 设置节点信息
      const model = {
        label,
        fullname,
        description,
        id: `${shape}_node_${id}`, // 相同类型节点 1,2,3累加
        // 形状
        type: shape,
        style: {
          fill: fill || '#ecf3ff',
        },
        labelCfg: defaultStyle.labelCfg,
        // 坐标
        x,
        y,
        // 配置信息
        ...defaultConfig,
        stateStyles: {
          // 选中时的颜色
          'nodeState:selected': _.get(nodeTypes[shape], 'stateStyles["nodeState:selected"]', {}),
          'nodeState:default': {
            fill: _.get(nodeTypes[shape], 'defaultStyle.fill', '#fff'),
          },
        },
      };
      if (width) {
        model.style.width = Number(width);
      }
      if (height) {
        model.style.height = Number(height);
      }

      this.graph.addItem('node', model);

      return model;
    },

    addEdge(config) {
      this.graph.addItem('edge', config);
    },
    // 解除 单个子节点 或者 单个子combo的 combo
    clearSingleChlidCombo() {
      this.$nextTick(() => {
        const allCombos = this.graph.getCombos();
        allCombos.forEach((combo) => {
          const { nodes, combos } = combo.getChildren();
          if (nodes.length <= 1 && combos.length <= 1) {
            // 拆解 Combo，即拆分组/解组。调用后，combo 本身将被删除，而该分组内部的子元素将会成为该分组父分组（若存在）的子元素。
            this.graph.uncombo(combo);
          }
        });
      });
    },
    save(event = 'save') {
      const data = this.graph.save();
      // eslint-disable-next-line
      let valadate = true;
      for (const node of data.nodes) {
        if (node.type === 'user') {
          valadate = validateUserNode.call(this, node);
          if (!valadate) break;
        }
        if (node.type === 'start_node') {
          valadate = validateStartNode.call(this, node);
          if (!valadate) break;
        }
        if (node.type === 'delay') {
          valadate = validateDelaySettingNode.call(this, node);
          if (!valadate) break;
        }
        if (node.type === 'function_wxgzh') {
          valadate = validateOfficalAccountNode.call(this, node);
          if (!valadate) break;
        }
        if (node.type === 'function_lclmsg') {
          valadate = validateInmailNode.call(this, node);
          if (!valadate) break;
        }
      }
      if (valadate) {
        try {
          const json = JSON.stringify(data);
          this.$emit(event, {
            data: json,
          });
        } catch (err) {
          console.error(err);
        }
      }
    },
    // 配置面板
    closeConfiguration() {
      this.configVisible = false;
      // 由切换顶部tab触发时要顺带清除所有选中态
      this.clearSelected();
    },
    closeContextMenu() {
      this.showContexmenu = false;
    },
    // 清空已选
    clearSelected() {
      const selectedNodes = this.graph.findAllByState('node', 'nodeState:selected');
      selectedNodes.forEach((node) => {
        node.clearStates(['nodeState:selected', 'nodeState:hover']);
        // 状态清空后样式依然存在 用另一个样式覆盖它
        node.setState('nodeState', 'default');
      });

      const selectedEdges = this.graph.findAllByState('edge', 'edgeState:selected');

      selectedEdges.forEach((edge) => {
        edge.clearStates(['edgeState:selected', 'edgeState:hover']);
      });
      this.graph.emit('after-node-selected');
    },
    // 菜单事件
    menuEvnt({ event }) {
      this.save(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.gb-toggle-btn {
  position: absolute;
  background: #fff;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
}

.root {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
#canvasPanel {
  background: #f2f3f8;
  flex-shrink: 1;
  height: calc(100vh - 42px);
}
.g6-grid {
  transform: none !important;
}

.g6-minimap {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
}
</style>
