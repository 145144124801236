<template>
  <div
    ref="showPanel"
    v-if="showContexmenu"
    class="context-container"
    :style="{ top: pointY + 'px', left: pointX + 'px' }"
  >
    <img :src="editSvg" alt="" class="edit-svg" @click="showEditPannel" />
    <img :src="copySvg" alt="" class="edit-svg" @click="copyNodeModel" />
    <img :src="deleteSvg" alt="" class="edit-svg" @click="deleteNodeModel" />
  </div>
</template>

<script>
import editSvg from './edit.svg';
import copySvg from './copy.svg';
import deleteSvg from './colorDelete.svg';
import { cloneDeep } from 'lodash';
export default {
  props: {
    showContexmenu: {
      type: Boolean,
      default: false,
    },
    pointX: {
      type: Number,
      default: 0,
    },
    pointY: {
      type: Number,
      default: 0,
    },
    modelData: {
      type: Object,
      default: () => ({}),
    },
    modelInfo: {
      type: Object,
      default: () => ({}),
    },
    graph: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editSvg,
      copySvg,
      deleteSvg,
    };
  },
  mounted() {
    document.addEventListener('click', this.clickHandle);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickHandle);
  },
  methods: {
    clickHandle(e) {
      if (this.$refs.showPanel) {
        const isSelf = this.$refs.showPanel.contains(e.target);
        if (!isSelf) {
          this.$emit('close');
        }
      }
    },
    showEditPannel() {
      this.$nextTick(() => {
        this.$emit('open-edit');
        this.$emit('close');
      });
    },
    copyNodeModel() {
      const { id: preNodeId } = this.modelInfo;
      const preNodeIdStrArr = preNodeId.split('_');
      const preNodeIdType = preNodeIdStrArr
        .map((str, index) => {
          if (index === preNodeIdStrArr.length - 1) {
            return '';
          }
          return str;
        })
        .filter((str) => !!str)
        .join('_');
      // 获取id
      let id = 1;
      const { nodes } = this.graph.save();
      nodes.forEach((node) => {
        const nodeId = Number(node.id.split('_').pop());
        if (nodeId >= id) {
          id = nodeId + 1;
        }
      });
      const newId = `${preNodeIdType}_${id}`;
      const newModel = {
        ...cloneDeep(this.modelInfo),
        id: newId,
        x: this.modelInfo.x + 40,
        y: this.modelInfo.y + 20,
      };
      this.graph.addItem('node', newModel);
      this.$nextTick(() => {
        this.$emit('close');
        const item = this.graph.findById(newId);
        this.graph.refreshItem(item);
      });
    },
    deleteNodeModel() {
      const { id, label } = this.modelInfo;
      this.$confirm(`确定删除"${label}"吗`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const item = this.graph.findById(id);
        this.graph.removeItem(item);
        // 拆解 单个子节点 或没有子节点 的 Combo
        this.$nextTick(() => {
          const allCombos = this.graph.getCombos();
          allCombos.forEach((combo) => {
            const { nodes, combos } = combo.getChildren();
            if (nodes.length <= 1 && combos.length <= 1) {
              // 拆解 Combo，即拆分组/解组。调用后，combo 本身将被删除，而该分组内部的子元素将会成为该分组父分组（若存在）的子元素。
              this.graph.uncombo(combo);
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.context-container {
  display: flex;
  align-items: center;
  position: fixed;
  .edit-svg {
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
