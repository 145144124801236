<template>
  <el-card class="box-card">
    <!-- 表单 -->
    <el-form
      ref="searchForm"
      :inline="true"
      :model="searchForm"
      class="demo-form-inline"
      @keyup.enter.native="searchList"
    >
      <el-form-item label="uin:">
        <!-- <el-input
          oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
          type="number"
          v-model="searchForm.uin"
          placeholder="请输入"
        ></el-input> -->
        <el-input
          clearable
          @clear="searchList"
          v-model="searchForm.uin"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名:">
        <el-input
          clearable
          @clear="searchList"
          v-model="searchForm.name"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号:">
        <el-input
          clearable
          @clear="searchList"
          v-model="searchForm.account"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号:">
        <el-input
          clearable
          @clear="searchList"
          v-model="searchForm.phone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱:">
        <el-input
          clearable
          @clear="searchList"
          v-model="searchForm.email"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型:">
        <el-select v-model="searchForm.type" placeholder="请选择" @change="searchList">
          <el-option label="全部" :value="undefined"></el-option>
          <el-option
            v-for="(label, key) in typeOptions"
            :key="key"
            :label="label"
            :value="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.status" placeholder="请选择" @change="searchList">
          <el-option label="全部" :value="undefined"></el-option>
          <el-option
            v-for="(label, key) in statusOptions"
            :key="key"
            :label="label"
            :value="key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">查询</el-button>
        <el-button @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="operation-area">
      <el-button
        type="primary"
        @click="openDialog({ type: 'create' })"
        v-if="jurisdiction.createAccount"
      >
        创建账号
      </el-button>
    </div>
    <!-- 列表 -->
    <el-table :data="list" v-loading="loading">
      <el-table-column prop="uin" label="uin" width="180" />
      <el-table-column prop="name" width="120" label="用户名" />
      <el-table-column prop="account" label="账号" />
      <el-table-column prop="type" label="类型" width="80">
        <template slot-scope="scope">
          {{ typeOptions[scope.row.type] || scope.row.type }}
        </template>
      </el-table-column>
      <el-table-column prop="unionid" width="160" label="微信unionid" />
      <el-table-column prop="introduce" width="160" label="介绍" />
      <el-table-column prop="status" label="用户状态" width="80">
        <template slot-scope="scope">
          {{ statusOptions[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="=240">
        <template slot-scope="scope">
          <el-button
            @click="openDialog({ uin: scope.row.uin, type: 'view' })"
            type="text"
            size="small"
          >
            查看
          </el-button>
          <el-button
            @click="openDialog({ uin: scope.row.uin, type: 'update' })"
            type="text"
            size="small"
            v-if="jurisdiction.createAccount"
          >
            编辑
          </el-button>
          <el-button
            @click="openPassWord(scope.row)"
            type="text"
            size="small"
            v-if="jurisdiction.changePassword"
          >
            修改密码
          </el-button>
          <el-button
            @click="openStatus(scope.row)"
            type="text"
            size="small"
            v-if="jurisdiction.createAccount"
          >
            修改状态
          </el-button>
          <el-button
            @click="openBinding(scope.row)"
            type="text"
            size="small"
            v-if="jurisdiction.unbindApp"
          >
            应用解绑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="searchForm.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="searchForm.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passWordVisible"
      width="600px"
      :before-close="closePassWord"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="passWordForm" :model="passWord" label-width="130px" :rules="passWordRules">
        <el-form-item label="新密码:" prop="number1">
          <el-input placeholder="请输入密码" v-model="passWord.number1" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="number2">
          <el-input placeholder="请输入密码" v-model="passWord.number2" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePassWord">取 消</el-button>
        <el-button type="primary" @click="changePassWord">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改状态弹窗 -->
    <el-dialog
      title="修改状态"
      :visible.sync="statusVisible"
      width="600px"
      :before-close="closeStatus"
      center
      :close-on-click-modal="false"
    >
      <el-select v-model="changeStatusForm.status" placeholder="请选择">
        <el-option
          v-for="(label, key) in statusOptions"
          :key="key"
          :label="label"
          :value="key"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeStatus">取 消</el-button>
        <el-button type="primary" @click="changeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 账号应用解绑 -->
    <el-dialog
      title="应用解绑"
      :visible.sync="bindingConfig.visible"
      width="600px"
      :before-close="closeBinding"
      center
      :close-on-click-modal="false"
    >
      <el-select v-model="bindingConfig.third_party" placeholder="请选择">
        <el-option v-for="(label, key) in bindingOptions" :key="key" :label="label" :value="key" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBinding">取 消</el-button>
        <el-button type="primary" @click="changeBinding">确 定</el-button>
      </span>
    </el-dialog>
    <account-form
      v-if="accountDialog.show"
      :show="accountDialog.show"
      :uin="accountDialog.uin"
      :type="accountDialog.type"
      @cancel="accountDialog.show = false"
      @refresh="refresh"
    />
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import accountForm from './accountForm.vue';
import { validatePass, typeOptions, statusOptions } from './common';
let number2timeId = null;
export default {
  components: {
    accountForm,
  },
  data() {
    return {
      // 表单
      searchForm: {
        page: 1,
        page_size: 10,
      },
      // 表格
      loading: false,
      list: [],
      total: 0,
      // 修改密码
      passWordVisible: false,
      changePassWordUin: null,
      passWord: {
        number1: '',
        number2: '',
      },
      passWordRules: {
        number1: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            validator: validatePass,
            trigger: 'change',
          },
        ],
        number2: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (number2timeId) {
                clearTimeout(number2timeId);
              }
              number2timeId = setTimeout(() => {
                if (this.passWord.number1 !== this.passWord.number2) {
                  callback(new Error('密码不一致'));
                } else {
                  callback();
                }
              }, 300);
            },
            trigger: 'change',
          },
        ],
      },
      // 修改状态
      statusVisible: false,
      changeStatusForm: {
        uin: null,
        status: null,
      },
      statusOptions,
      typeOptions,
      // 弹窗
      accountDialog: {
        show: false,
        uin: '',
        type: 'view',
      },
      // 解绑
      bindingConfig: {
        visible: false,
        row: {},
        third_party: '0',
      },
      bindingOptions: {
        0: 'unkown',
        1: '订阅号',
        2: '服务号',
        3: '小程序',
        4: '腾会应用',
        5: '企业微信',
        6: '手机号',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        changePassword: this.getElementList.includes('change-password'),
        unbindApp: this.getElementList.includes('unbind-app'),
        createAccount: this.getElementList.includes('create-account'),
      };
    },
  },
  created() {
    this.getList();
  },
  methods: {
    searchList() {
      this.searchForm.page = 1;
      this.getList();
    },
    resetForm() {
      this.searchForm = {
        page: 1,
        page_size: 10,
      };
      this.searchList();
    },
    async getList() {
      this.loading = true;
      const { name, account, uin, phone, email } = this.searchForm;
      try {
        const { data, total } = await this.$request({
          url: this.$api.AccountGetUserList,
          data: {
            ...this.searchForm,
            name: name || undefined,
            account: account || undefined,
            uin: uin || undefined,
            phone: phone || undefined,
            email: email || undefined,
          },
        });
        this.list = data;
        this.total = total;
        this.loading = false;
      } catch (err) {
        this.list = [];
        this.loading = false;
      }
    },
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.searchForm.page_size = val;
      this.searchForm.page = 1;
      this.getList();
    },
    openDialog({ uin, type }) {
      if (uin) {
        this.accountDialog.uin = uin;
      }
      this.accountDialog.type = type;
      this.$nextTick(() => {
        this.accountDialog.show = true;
      });
    },
    refresh() {
      this.accountDialog.show = false;
      this.searchList();
    },

    // 修改密码
    openPassWord({ uin }) {
      this.changePassWordUin = uin;
      this.passWordVisible = true;
    },
    closePassWord() {
      this.passWordVisible = false;
      this.$nextTick(() => {
        this.$refs.passWordForm.resetFields();
      });
    },
    changePassWord() {
      this.$refs.passWordForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          await this.$request({
            url: this.$api.AccountUpdateUserPassword,
            data: { uin: this.changePassWordUin, password: this.passWord.number2 },
          });
          this.closePassWord();
          this.getList();
        } catch (err) {
          console.log(err);
        }
      });
    },
    // 修改状态
    openStatus({ status, uin }) {
      this.changeStatusForm.uin = uin;
      this.changeStatusForm.status = status;
      this.$nextTick(() => {
        this.statusVisible = true;
      });
    },
    closeStatus() {
      this.statusVisible = false;
    },
    async changeStatus() {
      try {
        await this.$confirm(
          `确认修改uin: ${this.changeStatusForm.uin} 的状态为:${
            this.statusOptions[this.changeStatusForm.status]
          }?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        );
        const res = await this.$request({
          url: this.$api.AccountUpdateUserStatus,
          data: { uin: this.changeStatusForm.uin, status: this.changeStatusForm.status },
        });
        res?.msg && this.$message.error(res.msg);
        this.closeStatus();
        this.getList();
      } catch (err) {
        console.log(err);
      }
    },
    // 解绑
    openBinding(row) {
      this.bindingConfig.row = row;
      this.bindingConfig.visible = true;
    },
    closeBinding() {
      this.bindingConfig.visible = false;
    },
    async changeBinding() {
      try {
        await this.$confirm(`确定解绑账号:${this.bindingConfig.row.uin}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({
          url: this.$api.unbindUserAppByUin,
          data: {
            uin: this.bindingConfig.row.uin,
            third_party: this.bindingConfig.third_party,
          },
        });
        this.$message({
          message: '解绑成功',
          type: 'success',
        });
        this.bindingConfig.visible = false;
        this.getList();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
::v-deep .el-select {
  width: 100%;
}
.operation-area {
  display: flex;
  justify-content: flex-end;
}
</style>
