import {
  getMeetingTypeListGql,
  getEventsComplianceInfoListGql,
  addComplianceGql,
  addRestRuleGql,
  updateRestRuleGql,
  updateComplianceGql,
  deleteComplianceGql,
  updateComplianceStatusGql,
} from '@/graphql/gql/meeting';
import { calVariables, request } from '@/graphql/util';

const complianceGql = (gql, data) => {
  let fieldList = '';
  const keyList = {
    id: 'id',
    name: 'name',
    meeting_type_id: 'meeting_type_id',
    identity_tag: 'identity_tag',
    description: 'description',
    status: 'status',
  };
  Object.keys(data).forEach((key) => {
    if (keyList[key]) {
      fieldList += `${keyList[key]}:$${key},`;
    }
  });
  const str = gql.replace('fieldList', fieldList);
  return str;
};

const ruleGql = (gql, list, keyList) => {
  let fieldList = '';
  let fields = '';

  Object.keys(list[0]).forEach((key) => {
    if (keyList[key]) {
      fieldList += `${keyList[key]}:$${key},`;
    }
  });
  list.forEach((item) => {
    fields += `{${calVariables(fieldList, item)}}, `;
  });
  const str = gql.replace('fieldList', fields);
  return str;
};

export function getMeetingTypeList(data) {
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(getMeetingTypeListGql, data),
  });
}
export function getEventsComplianceInfoList(data) {
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(getEventsComplianceInfoListGql, data),
  });
}
export function addCompliance(data, list, ruleKeyList) {
  const query =
    calVariables(complianceGql(addComplianceGql, data), data, false) +
    ruleGql(addRestRuleGql, list, ruleKeyList);
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query,
  });
}
export function updateCompliance(data, list, ruleKeyList) {
  const query =
    calVariables(complianceGql(updateComplianceGql, data), data, false) +
    ruleGql(updateRestRuleGql, list, ruleKeyList);
  console.log('=====query', query);
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query,
  });
}
export function updateComplianceStatus(data) {
  const query = calVariables(complianceGql(updateComplianceStatusGql, data), data, false);
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query,
  });
}
export function deleteCompliance(data) {
  return request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(deleteComplianceGql, data),
  });
}
