<template>
  <div>
    <t-table row-key="id" :data="elementData" :columns="columns">
      <template #permission="{ row }">
        <t-checkbox-group v-model="permissionMap[row.permission_id]">
          <t-checkbox
            value="view"
            label="可见"
            @change="(checked) => permissionChange(row, 'view', checked)"
            :disabled="!jurisdiction.roleManage"
          ></t-checkbox>
        </t-checkbox-group>
      </template>
    </t-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    searchObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permissionMap: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      elementData: [],
      columns: [
        {
          colKey: 'name',
          title: '名称',
          minWidth: 140,
        },
        {
          colKey: 'code',
          title: 'Code',
          minWidth: 140,
        },
        {
          colKey: 'description',
          title: '描述',
          minWidth: 140,
        },
        {
          colKey: 'permission',
          title: '权限',
          minWidth: 140,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
  },
  methods: {
    init() {
      this.loadElementData();
    },
    loadElementData() {
      const url = this.$api.getElementList;
      const cb = (data) => {
        this.initPermission(data.data);
        this.elementData = data.data;
      };
      return this.$request({
        url,
        cb,
        data: { page_size: 999999, appid: this.searchObj.appid },
      });
    },
    initPermission(list) {
      for (const item of list) {
        if (
          !this.permissionMap[item.permission_id] ||
          this.permissionMap[item.permission_id]?.length == 0
        ) {
          this.$set(this.permissionMap, item.permission_id, []);
        }

        if (item.children && item.children.length > 0) {
          this.initPermission(item.children);
        }
        if (item.fields && item.fields?.length > 0) {
          this.initPermission(item.fields);
        }
      }
    },
    permissionChange(data, action, value) {
      console.log(value);
      let url = '';
      if (value === true) {
        url = this.$api.addRolePermission;
      } else {
        url = this.$api.removeRolePermission;
      }

      const cb = (res) => {
        console.log('权限设置成功 :>> ', res);
      };
      this.$request({
        url,
        cb,
        data: {
          role_id: this.searchObj.role_id,
          permission_id: data.permission_id,
          action,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tdesign/table.scss';
</style>
