<template>
  <div>
    <el-dialog
      title="规则"
      :visible.sync="showRule"
      width="600px"
      center
      :close-on-click-modal="false"
    >
      <el-form label-width="60px" ref="ruleForm">
        <el-form-item v-for="(item, index) in rule" :key="index" :label="`规则${index + 1}:`">
          <rule
            :value="item.ruler"
            :rule-length="rule.length"
            @edit="editRule(item)"
            @del="delRule(index)"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="text" @click="editRule()">+ 添加规则</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showRule = false">确 定</el-button>
      </span>
    </el-dialog>
    <edit
      v-if="showEdit"
      :show="showEdit"
      @cancel="showEdit = false"
      @refresh="GetRule"
      :rule="editRuleInfo"
    />
  </div>
</template>
<script>
import rule from './item-rule.vue';
import edit from './edit.vue';
import { GetRule, DeleteRule } from '@/api/audit';
export default {
  components: { rule, edit },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 规则
      oldRule: [],
      rule: [
        [
          {
            type: '',
            operator: '',
            val: '',
          },
        ],
      ],
      // 编辑
      editRuleInfo: {
        id: '',
        ruler: [
          {
            type: '',
            operator: '',
            val: '',
          },
        ],
      },
      showEdit: false,
    };
  },
  computed: {
    showRule: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.$emit('cancel');
        }
      },
    },
  },
  created() {
    this.GetRule();
  },
  methods: {
    async GetRule() {
      this.showEdit = false;
      const { audit_rule: rule } = await GetRule();
      this.oldRule = rule.map((item) => {
        return {
          ...item,
          ruler: item.ruler.split('&&').map((rule) => {
            const xxx = rule.split('_');
            return {
              type: xxx[1],
              operator: xxx[2].split('(')[0],
              val: xxx[2].match(/("(\S*)")/)[2] || '',
            };
          }),
        };
      });
      this.rule =
        this.oldRule.length > 0
          ? this.oldRule
          : [
              [
                {
                  type: '',
                  operator: '',
                  val: '',
                },
              ],
            ];
    },
    editRule(info) {
      this.editRuleInfo = info || {
        id: '',
        ruler: [
          {
            type: '',
            operator: '',
            val: '',
          },
        ],
      };
      this.$nextTick(() => {
        this.showEdit = true;
      });
    },
    async delRule(index) {
      try {
        await this.$confirm('此操作将删除整条规则, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        const { id } = this.rule[index];
        await DeleteRule({ id });
        this.GetRule();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .is-disabled {
  .el-input__inner {
    color: black;
  }
}
</style>
