<template>
  <el-card class="table-container">
    <div class="top">
      <el-form
        ref="searchForm"
        :inline="true"
        :model="searchObj"
        @keyup.enter.native="loadListData"
      >
        <el-form-item label="">
          <el-select v-model="searchObj.appid" placeholder="应用">
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            clearable
            placeholder="请输入关键词"
            v-model="searchObj.keyword"
            class="keyword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadListData" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-select v-model="searchObj.appid" placeholder="应用">
        <el-option
          v-for="item in appOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-input clearable placeholder="请输入关键词" v-model="searchObj.keyword" class="keyword">
        <el-button slot="append" @click="loadListData" icon="el-icon-search">搜索</el-button>
      </el-input>
      <el-button
        class="add-button"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd()"
        v-if="jurisdiction.userManage"
      >
        添加
      </el-button> -->
    </div>

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="姓名" min-width="120"></el-table-column>
      <el-table-column prop="account" label="账号" min-width="120"></el-table-column>
      <el-table-column prop="description" label="描述" min-width="180"></el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="jurisdiction.userManage ? '350px' : '220px'">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            v-if="jurisdiction.userManage"
          >
            编辑
          </el-button>
          <el-button type="text" size="mini" @click="handleDetail(scope.$index, scope.row)">
            标签
          </el-button>
          <el-button type="text" size="mini" @click="handleJob(scope.$index, scope.row)">
            岗位
          </el-button>
          <el-button type="text" size="mini" @click="handleRole(scope.$index, scope.row)">
            角色
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
            v-if="jurisdiction.userManage"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      hide-on-single-page
      layout="total,sizes, prev, pager, next, jumper"
      @current-change="(val) => (this.searchObj.page = val)"
      @size-change="(val) => (this.searchObj.page_size = val)"
    />

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="账户" prop="account">
          <el-input v-model="formObj.account" :disabled="operation == 'update'"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="岗位设置"
      :visible.sync="jobDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-tree
        :data="jobData"
        show-checkbox
        default-expand-all
        :check-strictly="true"
        node-key="id"
        ref="jobTree"
        :props="defaultJobProps"
        @check-change="jobTreeCheck"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <div class="dialog-footer-select">
          <div class="dialog-footer-select-contain">
            <span>主岗位：</span>
            <el-select v-model="mainJobValue" placeholder="请选择">
              <el-option
                v-for="item in mainJoboptions"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
        </div>

        <el-button @click="cancelJob">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisable"
          @click="saveJob"
          v-if="jurisdiction.userManage"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="角色设置"
      :visible.sync="roleDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-tree
        :data="roleData"
        show-checkbox
        default-expand-all
        :check-strictly="true"
        node-key="id"
        ref="roleTree"
        :default-checked-keys="[5]"
        :props="defaultRoleProps"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisable"
          @click="saveRole"
          v-if="jurisdiction.userManage"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="用户标签"
      :visible.sync="tagDialog.visible"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-table :data="tagDialog.tagList" stripe style="width: 100%" v-loading="tagDialog.loading">
        <el-table-column prop="tag_name" label="标签组名称" />
        <el-table-column prop="value_name" label="标签名称" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tagDialog.visible = false">确定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    return {
      operation: 'create',
      listData: [],
      count: 0,
      jobData: [],
      roleData: [],
      appOptions: [],
      statusOption: [0, 1, 2],
      formObj: {},
      operateUserId: 0,
      oldSearchObj: {},
      searchObj: {
        appid: Number(this.$route.query.appid) || 0,
        keyword: this.$route.query.keyword,
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        account: [
          {
            required: true,
            message: '请输入账户',
            trigger: 'blur',
          },
        ],
      },
      defaultJobProps: {
        children: 'children',
        label: 'name',
      },
      jobDialogVisible: false,
      defaultRoleProps: {
        children: 'children',
        label: 'name',
      },
      roleDialogVisible: false,
      opDialogVisible: false,
      submitDisable: false,
      mainJoboptions: [],
      mainJob: '',
      mainJobCode: '',
      jobChecked: [],
      // 用户标签弹窗
      tagDialog: {
        visible: false,
        loading: false,
        tagList: [],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        userManage: this.getElementList.includes('user-manage'),
      };
    },
    mainJobValue: {
      get() {
        return this.mainJob;
      },
      set(newVal) {
        this.mainJobCode = newVal;
        const index = this.mainJoboptions.findIndex((item) => item.code === newVal);
        this.mainJob = this.mainJoboptions[index].name;
      },
    },
  },
  watch: {
    searchObj: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
          this.searchObj.page = 1;
        }
        this.oldSearchObj = Object.assign({}, this.searchObj);
        this.timer = setTimeout(() => {
          this.$router.push({ query: this.searchObj });
          sessionStorage.setItem('app_id', this.searchObj.appid);
          this.loadListData();
          this.loadJobData();
          this.loadRoleData();
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    this.loadAppOption();
    this.loadListData();
    this.loadJobData();
    this.loadRoleData();
  },
  methods: {
    cancelJob() {
      this.jobDialogVisible = false;
      this.mainJoboptions = [];
      this.mainJob = ' ';
      this.mainJobCode = '';
    },
    jobTreeCheck(obj) {
      this.mainJoboptions = this.$refs.jobTree.getCheckedNodes();
      if (this.mainJoboptions.length === 1) {
        this.mainJob = obj.name;
        this.mainJobCode = obj.code;
      }
      const mainJobIndex = this.jobChecked.findIndex((item) => item.priority === 0);
      if (
        mainJobIndex !== -1 &&
        this.mainJoboptions.some((item) => item.id === this.jobChecked[mainJobIndex].id)
      ) {
        this.mainJob = this.jobChecked[mainJobIndex].name;
        this.mainJobCode = this.jobChecked[mainJobIndex].code;
      }
      if (this.mainJoboptions.every((item) => item.name !== this.mainJob)) {
        this.mainJob = this.mainJoboptions.length > 0 ? this.mainJoboptions[0].name : ' ';
        this.mainJobCode = this.mainJoboptions.length > 0 ? this.mainJoboptions[0].code : '';
      }
    },
    loadListData() {
      const url = this.$api.getUserList;
      const data = {
        keyword: this.searchObj.keyword,
        page: this.searchObj.page,
        page_size: this.searchObj.page_size,
      };
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
      };
      this.$request({ url, data, cb });
    },
    loadJobData() {
      const url = this.$api.getJobTree;
      const data = { appid: this.searchObj.appid };
      const cb = (data) => {
        this.jobData = data.data;
      };
      this.$request({ url, data, cb });
    },
    loadJobCheckData() {
      const url = this.$api.getUserJob;
      const data = {
        appid: this.searchObj.appid,
        user_id: this.operateUserId,
      };
      const cb = (data) => {
        this.jobChecked = data.data;
        this.$refs.jobTree.setCheckedKeys(data.data.map((v) => v.id));
      };
      this.$request({ url, data, cb });
    },
    loadRoleData() {
      const url = this.$api.getRoleTree;
      const data = { appid: this.searchObj.appid };
      const cb = (data) => {
        this.roleData = data.data;
      };
      this.$request({ url, data, cb });
    },
    loadRoleCheckData() {
      const url = this.$api.getUserRole;
      const data = {
        appid: this.searchObj.appid,
        user_id: this.operateUserId,
      };
      const cb = (data) => {
        this.$refs.roleTree.setCheckedKeys(data.data.map((v) => v.id));
      };
      this.$request({ url, data, cb });
    },
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
        this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateUser : this.$api.createUser;
        const msg = id ? '修改成功' : '创建成功';
        let data = {};
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            description: this.formObj.description,
          };
        } else {
          data = {
            name: this.formObj.name,
            account: this.formObj.account,
            description: this.formObj.description,
          };
        }

        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteUser;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {};
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async handleDetail(index, { id }) {
      this.tagDialog.visible = true;
      this.tagDialog.loading = true;
      try {
        const { tags } = await this.$request({
          url: this.$api.getUserTagInfo,
          data: {
            user_id: id,
            appid: this.searchObj.appid,
          },
        });
        this.tagDialog.tagList = tags;
        this.tagDialog.loading = false;
      } catch (err) {
        this.tagDialog.tagList = [];
        this.tagDialog.loading = false;
        console.log(err);
      }
    },
    handleJob(index, data) {
      this.operateUserId = data.id;
      this.loadJobCheckData();
      this.jobDialogVisible = true;
    },
    jobChange() {
      console.log('node :>> ', this.$refs.jobTree.getCheckedNodes());
    },
    saveJob() {
      const url = this.$api.updateUserJob;
      const type = 'post';

      const nodes = this.$refs.jobTree.getCheckedNodes();
      const data = {
        appid: this.searchObj.appid,
        user_id: this.operateUserId,
        job_codes: nodes.map((v) => v.code),
        prefer_job_code: `${this.mainJobCode}`,
      };
      const cb = () => {
        this.jobDialogVisible = false;
        this.$message({
          message: '保存岗位成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data });
    },
    handleRole(index, data) {
      this.operateUserId = data.id;
      this.loadRoleCheckData();
      this.roleDialogVisible = true;
    },
    saveRole() {
      const url = this.$api.updateUserRole;
      const type = 'post';

      const nodes = this.$refs.roleTree.getCheckedNodes();

      const data = {
        appid: this.searchObj.appid,
        user_id: this.operateUserId,
        role_codes: nodes.map((v) => v.code),
      };
      const cb = () => {
        this.roleDialogVisible = false;
        this.$message({
          message: '保存角色成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
</style>
