export const PASS_MENU = ['login']; // 默认用户可进入的页面

// 无任何菜单权限且 用户uin === 租户uin的使用该菜单
export const DEFAULT_MENU = [
  {
    appid: 1,
    children: [],
    code: 'home',
    description: '',
    display: 2,
    icon: '-',
    id: 1180468,
    layout_json: '',
    name: '主页',
    order_weight: 11,
    parent_code: 'root',
    path: 'home',
  },
  {
    appid: 1,
    code: 'update',
    custom_layout_usage: '',
    custom_type: 'jsonpage',
    description: '',
    display: 1,
    icon: 'el-icon-s-check',
    id: 6763,
    layout_json: '',
    name: '发版管理',
    object_code: '',
    open_type: '',
    order_weight: 5,
    params: '',
    parent_code: 'root',
    path: '',
    type: 'custom',
    url: '',
    children: [
      {
        appid: 1,
        children: [],
        code: 'version-iam',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 1,
        icon: '-',
        id: 11695,
        layout_json: '{}',
        name: 'iam版本管理',
        object_code: '',
        open_type: '',
        order_weight: 7,
        params: '',
        parent_code: 'update',
        path: 'version-iam',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [],
        code: 'version-iam-diff',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 2,
        icon: '-',
        id: 11694,
        layout_json: '{}',
        name: 'iam版本管理diff',
        object_code: '',
        open_type: '',
        order_weight: 6,
        params: '',
        parent_code: 'update',
        path: 'version-iam-diff',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [],
        code: 'version-iam-detail',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 2,
        icon: '-',
        id: 11692,
        layout_json: '{}',
        name: 'iam版本管理详情',
        object_code: '',
        open_type: '',
        order_weight: 5,
        params: '',
        parent_code: 'update',
        path: 'version-iam-detail',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [
          {
            appid: 1,
            children: [],
            code: 'version-meta-remote',
            custom_layout_usage: '',
            custom_type: 'jsonpage',
            description: '',
            display: 1,
            icon: '',
            id: 742,
            layout_json: '{}',
            name: '远端租户版本',
            object_code: '',
            open_type: '',
            order_weight: 0,
            package: '',
            params: '',
            parent_code: 'meta-entry',
            path: 'version-meta-remote',
            type: 'custom',
            url: '',
          },
        ],
        code: 'version-meta',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 1,
        icon: '-',
        id: 11690,
        layout_json: '{}',
        name: '元数据版本管理',
        object_code: '',
        open_type: '',
        order_weight: 4,
        params: '',
        parent_code: 'update',
        path: 'version-meta',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [],
        code: 'version-meta-diff',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 2,
        icon: '-',
        id: 11693,
        layout_json: '{}',
        name: '元数据版本管理diff',
        object_code: '',
        open_type: '',
        order_weight: 3,
        params: '',
        parent_code: 'update',
        path: 'version-meta-diff',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [],
        code: 'version-meta-detail',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 2,
        icon: '-',
        id: 11691,
        layout_json: '{}',
        name: '元数据版本管理详情',
        object_code: '',
        open_type: '',
        order_weight: 2,
        params: '',
        parent_code: 'update',
        path: 'version-meta-detail',
        type: 'custom',
        url: '',
      },
      {
        appid: 1,
        children: [
          {
            appid: 1,
            children: [],
            code: 'version-all-list',
            custom_layout_usage: '',
            custom_type: 'jsonpage',
            description: '',
            display: 2,
            icon: '-',
            id: 2474998,
            layout_json: '{}',
            name: '版本列表',
            object_code: '',
            open_type: '',
            order_weight: 0,
            package: '',
            params: '',
            parent_code: 'version-all-entry',
            path: 'version-all-list',
            type: 'custom',
            url: '',
          },
          {
            appid: 1,
            children: [],
            code: 'version-all-diff',
            custom_layout_usage: '',
            custom_type: 'jsonpage',
            description: '',
            display: 1,
            icon: '-',
            id: 2474999,
            layout_json: '{}',
            name: '版本diff',
            object_code: '',
            open_type: '',
            order_weight: 0,
            package: '',
            params: '',
            parent_code: 'version-all-entry',
            path: 'version-all-diff',
            type: 'custom',
            url: '',
          },
          {
            appid: 1,
            children: [],
            code: 'version-all-remote',
            custom_layout_usage: '',
            custom_type: 'jsonpage',
            description: '',
            display: 2,
            icon: '-',
            id: 2475001,
            layout_json: '{}',
            name: '远端版本列表',
            object_code: '',
            open_type: '',
            order_weight: 0,
            package: '',
            params: '',
            parent_code: 'version-all-entry',
            path: 'version-all-remote',
            type: 'custom',
            url: '',
          },
          {
            appid: 1,
            children: [],
            code: 'version-all-detail',
            custom_layout_usage: '',
            custom_type: 'jsonpage',
            description: '',
            display: 1,
            icon: '-',
            id: 2475002,
            layout_json: '{}',
            name: '版本详情',
            object_code: '',
            open_type: '',
            order_weight: 0,
            package: '',
            params: '',
            parent_code: 'version-all-entry',
            path: 'version-all-detail',
            type: 'custom',
            url: '',
          },
        ],
        code: 'version-all-entry',
        custom_layout_usage: '',
        custom_type: 'jsonpage',
        description: '',
        display: 1,
        icon: '-',
        id: 2475003,
        layout_json: '{}',
        name: '版本管理',
        object_code: '',
        open_type: '',
        order_weight: 0,
        package: '',
        params: '',
        parent_code: 'update',
        path: 'version-all-entry',
        type: 'custom',
        url: '',
      },
    ],
  },
];
