<template>
  <el-table-column :label="label">
    <template slot-scope="scope">
      <span v-if="scope.row[name].length <= limit">{{ showText(scope.row[name]) }}</span>
      <el-tooltip v-else effect="dark" :content="scope.row[name]" placement="top">
        <span>{{ showText(scope.row[name]) }}...</span>
      </el-tooltip>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'TooltipColumn',
  props: {
    // 列名
    label: {
      type: String,
      default: '',
    },
    // 属性名
    name: {
      type: String,
      default: '',
    },
    // 字数限制
    limit: {
      type: Number,
      default: 20,
    },
  },
  methods: {
    showText(text) {
      if (this.limit) {
        return text.length > this.limit ? text.slice(0, this.limit) : text;
      }
      return text;
    },
  },
};
</script>
