import {
  getAuditLogList,
  insertRule,
  getRule,
  deleteRule,
  updateRule,
} from '@/graphql/api/audit_log';

export const GetArticleList = (data = {}) => getAuditLogList(data); // 获取文章列表

export const InsertRule = (data = {}) => insertRule(data); // 插入规则

export const GetRule = (data = {}) => getRule(data); // 查询规则

export const DeleteRule = (data = {}) => deleteRule(data); // 删除规则

export const UpdateRule = (data = {}) => updateRule(data); // 更新规则
