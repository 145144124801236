<template>
  <el-dialog
    :title="newRule[0].id ? '编辑规则' : '创建规则'"
    :visible.sync="showDialog"
    width="600px"
    center
    :close-on-click-modal="false"
  >
    <el-form label-width="60px" :model="ruleObject" ref="ruleForm">
      <el-form-item
        v-for="(item, index) in newRule"
        :key="index"
        :label="`规则${index + 1}:`"
        :prop="`${index}`"
        :rules="[{ validator: validator, trigger: 'change' }]"
      >
        <rule
          type="edit"
          :value="item.ruler"
          :rule-length="newRule.length"
          @input="
            (val) => {
              changeRule(val, index);
            }
          "
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import rule from './item-rule.vue';
import { InsertRule, UpdateRule } from '@/api/audit';
const getRuleStr = (rule) => {
  const ruleStr = [];
  rule.forEach((line) => {
    const lineStr = [];
    line.ruler.forEach((item) => {
      lineStr.push(`req_${item.type}_${item.operator}(\\"${item.val}\\")`);
    });
    ruleStr.push({
      ruler: `${lineStr.join('&&')}`,
      id: line.id,
    });
  });
  return ruleStr;
};
export default {
  components: { rule },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: Object,
      default: () => {
        return {
          id: '',
          ruler: [
            {
              type: '',
              operator: '',
              val: '',
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      newRule: [],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.$emit('cancel');
        }
      },
    },
    ruleObject() {
      const obj = {};
      this.newRule.forEach((item, index) => {
        obj[index] = item;
      });
      return obj;
    },
  },
  created() {
    this.newRule = [JSON.parse(JSON.stringify(this.rule))];
  },
  methods: {
    validator(rule, value, callback) {
      if (
        value.ruler.some((item) => {
          return [item.type, item.operator, item.val].includes('');
        })
      ) {
        callback(new Error('请输入完整参数'));
        return;
      }
      callback();
    },
    changeRule(val, index) {
      this.$set(this.newRule[index], 'ruler', val);
    },
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          if (this.newRule[0].id) {
            await UpdateRule({
              ...getRuleStr(this.newRule)[0],
            });
          } else {
            await InsertRule({
              ruler: getRuleStr(this.newRule)[0].ruler,
            });
          }
          this.$emit('refresh');
        } catch (err) {
          console.log(err);
        }
      });
    },
  },
};
</script>

<style></style>
