<template>
  <div class="event-alarm">
    <div class="event-alarm-title">
      <i class="iconfont icon-warning-fill" />
      <div>
        如果要创建或者修改通知消息模板或者云函数自定义事件，请前往
        <span class="event-alarm-link" @click="jump">事件中心</span>
        操作
      </div>
    </div>
    <div class="event-alarm-item">
      1.云函数自定义事件选择后，系统将该流程的流程Key值绑定到所选择的云函数事件中；
    </div>
    <div class="event-alarm-item">2.每个云函数事件仅允许绑定一个流程Key值；</div>
    <div class="event-alarm-item">
      3.已被绑定流程Key值的云函数事件，则在其他审批节点中无法选择到；
    </div>
    <div class="event-alarm-item">4.如需更换绑定，则需先解除原来的绑定关系。</div>
    <MessageBox ref="messageBox" />
  </div>
</template>

<script>
import MessageBox from '@/components/messageBox';

export default {
  name: 'WorkflowDetailEventTip',
  components: { MessageBox },
  methods: {
    jump() {
      try {
        this.$refs.messageBox.show({
          title: '提示',
          content: '此操作将不会保存已修改数据?',
          titleIconType: 'warning',
          needCancel: true,
          cancelAction: this.$refs.messageBox.close,
          confirmAction: () => {
            this.$refs.messageBox.close();
            this.$router.push({
              name: 'workflow-event-list',
            });
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-alarm {
  display: flex;
  flex-direction: column;
  padding: 12px 52px;
  margin-bottom: 24px;
  background: #fef3e6;
  color: #4d5762;
  .event-alarm-item {
    line-height: 22px;
  }
  .event-alarm-title {
    margin-left: -30px;
    margin-bottom: 8px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: #1a2734;
    display: flex;
    .iconfont {
      font-size: 18px;
      margin-right: 8px;
      color: #fa9600;
    }
    .event-alarm-link {
      color: #0069fa;
      cursor: pointer;
    }
  }
}
</style>
