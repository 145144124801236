import { get } from 'lodash';
const _ = { get };
export default {
  methods: {
    // 计算审批节点中已选择的审批人
    generateSelectdAssigneers() {
      let result = [];
      this.graph.getNodes().forEach((node) => {
        const nodeModel = node.get('model');
        const nodeType = nodeModel.type;

        if (nodeType === 'user') {
          const candidateUser = _.get(nodeModel, 'metadata.operator_value.candidate_user', []);
          const attributeCheckedUser = _.get(
            nodeModel,
            'metadata.operator_value.attribute_checked_user',
            [],
          );
          result = [...result, ...candidateUser, ...attributeCheckedUser];
        }
      });
      console.log('result====', result);
      this.requestModelAssignersInfo(Array.from(new Set(result)));
    },
  },
};
