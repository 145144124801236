<template>
  <div class="login-wrapper">
    <template v-if="canShowPage">
      <div class="login-bg">
        <div class="left">
          <div class="logo">
            <img class="img" :src="web_logo" />
          </div>
          <div class="copyright">
            Copyright©️2022 Tencent Inc. All Rights Reserved 腾讯公司 版权所有
          </div>
        </div>
        <div class="right"></div>
      </div>
      <div class="login-area">
        <account :redirect-url="redirectUrl" />
      </div>
    </template>
    <div
      v-else
      class="loading"
      v-loading="true"
      element-loading-text="Loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.7)"
    />
  </div>
</template>
<script>
import account from './components/account.vue';
// import { PRODUCT_MAP } from '../../utils/constant';
const getHostFromUrl = function (urlStr) {
  const url = new URL(decodeURIComponent(urlStr));
  return url.host;
};

export default {
  components: {
    account,
  },
  data() {
    return {
      domain: '',
      // 业务链接
      redirectUrl: '',
      tenantId: '',
      product: '',
      clientId: '',
      web_logo:
        'https://baike-med-1256891581.file.myqcloud.com/2022070/0e294310-0bfd-11ed-89ce-4ff3ab5f0cf9_0.png',
      login_mode: [],
      // 默认不显示页面
      canShowPage: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    // idp认证成功后，返回code，将code存入浏览器中
    const { code = '' } = this.$route?.query;
    if (code) {
      localStorage.setItem('auth_code', code);
    }
  },
  mounted() {
    this.domain = document.domain;
    console.log('客户域名为:', this.domain);
    const { state } = this.$route.query;
    if (localStorage.getItem('auth_code') && state) {
      const { target_uri } = this.$route.query;
      this.redirectUrl = target_uri || '';
      if (state) {
        let curState;
        try {
          curState = JSON.parse(state);
        } catch (e) {
          console.error(e);
          curState = {};
        }
        this.redirectUrl = curState?.target_uri || this.redirectUrl;
        this.tenantId = curState.tenant_id;
        this.product = curState.product;
        this.clientId = curState.client_id;
      }
      this.loginWithOAuth();
    } else {
      const { tenantId, product, redirect_uri } = this.$route.query; // 中间页
      this.redirectUrl = redirect_uri || '';
      this.tenantId = tenantId || '';
      this.product = product || '';
      this.getWebLoginInfo();
    }
  },
  methods: {
    // 根据产品域名获取详细信息
    async getWebLoginInfo() {
      await this.$request({
        url: this.$api.getWebLoginConfig,
        data: {
          domain: getHostFromUrl(this.redirectUrl) || this.domain,
          redirect_uri: this.redirectUrl,
        },
      })
        .then((res) => {
          this.login_mode = res.login_mode || [];
          this.canShowPage = this.login_mode.indexOf('SSO') === -1;
          this.web_logo = res.web_logo ? res.web_logo : this.web_logo;
          // sso登录时造授权链接去认证
          if (res.login_mode.indexOf('SSO') !== -1) {
            const ssoConfig = res?.sso_config;
            const state = {
              client_id: ssoConfig?.client_id || '',
              tenant_id: this.tenantId,
              product: this.product,
            };
            const oauth_timestamp = Date.now();
            const callbackUrl = `https://${this.domain}/callback/login`;
            const vendor = ssoConfig?.vendor;
            const protocolType = ssoConfig?.protocol_type;
            const scope = ssoConfig?.scope;
            let authorizedUrl;
            if (protocolType === 'SAML') {
              authorizedUrl = `/api/sso/${this.tenantId}/${
                this.product
              }/LoginSaml?redirect_url=${encodeURIComponent(this.redirectUrl)}`;
            } else if (vendor === 'Azure') {
              state.target_uri = encodeURIComponent(this.redirectUrl); // Azure只支持传state，故将target_uri合入state中
              authorizedUrl = `${ssoConfig?.authorize_url}?client_id=${
                ssoConfig?.client_id
              }&response_type=code&redirect_uri=${encodeURIComponent(
                callbackUrl,
              )}&response_mode=query&scope=${scope}&state=${encodeURIComponent(
                JSON.stringify(state),
              )}`;
            } else {
              authorizedUrl = `${ssoConfig?.authorize_url}?client_id=${
                ssoConfig?.client_id
              }&response_type=code&oauth_timestamp=${oauth_timestamp}&target_uri=${
                this.redirectUrl
              }&state=${encodeURIComponent(
                JSON.stringify(state),
              )}&redirect_uri=${encodeURIComponent(callbackUrl)}`;
            }
            window.location.replace(authorizedUrl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 单点登录
    async loginWithOAuth() {
      const auth_code = localStorage.getItem('auth_code');
      // 请求前清除缓存
      localStorage.removeItem('auth_code');
      await this.$request({
        url: this.$api.loginWithOAuth,
        data: {
          tenant_id: `${this.tenantId}`,
          client_id: this.clientId,
          auth_code,
        },
        client: {
          product: Number(this.product),
        },
      })
        .then((res) => {
          this.$message({
            message: '登录成功',
            type: 'success',
          });
          /*
          const defaultTokenKey = 'saas_token';
          let tokenPrefix = PRODUCT_MAP[this.product] || '';
          tokenPrefix = tokenPrefix ? `${tokenPrefix}_` : tokenPrefix;
          this.$cookies.set('uin', res.uin);
          this.$cookies.set(`${tokenPrefix}${defaultTokenKey}`, res.token);
          */
          // 此时的redirectUrl为客户域名
          if (this.redirectUrl) {
            const redirectUrl = decodeURIComponent(this.redirectUrl);
            window.location.replace(
              `${redirectUrl}${
                redirectUrl.indexOf('?') === -1 ? '?' : '&'
              }token=${encodeURIComponent(res.token)}&uin=${res.uin}&expires_in=${res.expires_in}`,
            );
          }
        })
        .catch((err) => {
          // this.$message.error('登录失败');
          console.log(err);
          // 登录失败带错误信息回跳
          if (this.redirectUrl) {
            const redirectUrl = decodeURIComponent(this.redirectUrl);
            window.location.replace(
              `${redirectUrl}${redirectUrl.indexOf('?') === -1 ? '?' : '&'}auth_error=${(
                err || 'unknown'
              ).toString()}&auth_type=SSO`,
            );
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  .login-bg {
    display: flex;
    height: 100vh;
    background: #f2f3f8;
    .left {
      position: relative;
      flex: 1;
      height: 100%;
      background: url('https://baike-med-1256891581.file.myqcloud.com/2022013/091f16e0-6f97-11ec-a3f4-354087f4d05d_0.png')
        no-repeat center;
      .logo {
        position: absolute;
        left: 40px;
        top: 40px;
        font-size: 0;
        .img {
          width: 136px;
          height: 36px;
        }
      }
      .copyright {
        position: absolute;
        bottom: 32px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #98a2b3;
      }
    }
    .right {
      width: 480px;
      height: 100%;
      background: url('https://baike-med-1256891581.file.myqcloud.com/2022015/08c33b40-6f97-11ec-acc4-2daa03d2616b_0.png')
        #0069fa no-repeat center bottom;
    }
  }
  .login-area {
    position: absolute;
    top: 50%;
    margin-top: -204px;
    right: 300px;
    width: 360px;
    height: 408px;
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 60px 40px;
  }
  .loading {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
