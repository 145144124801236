<template>
  <div>
    <el-dialog
      title="插入对象字段"
      :visible.sync="showDialog"
      width="700px"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 对象 -->
        <el-tab-pane label="对象" name="object" v-loading="objectForm.loading">
          <el-form
            :inline="true"
            ref="searchForm"
            :model="objectForm"
            class="searchForm"
            @submit.native.prevent
          >
            <el-form-item label="" prop="serch">
              <el-input v-model="objectForm.keyword" clearable placeholder="请输入对象名称" />
            </el-form-item>
          </el-form>
          <el-table
            :data="objectListArr"
            height="450"
            border
            ref="objectTab"
            style="width: 100%; overflow: auto"
            @row-click="handleSelectionChange"
          >
            <el-table-column align="center" width="55" label="选择">
              <template slot-scope="scope">
                <el-radio class="radio" v-model="objectForm.slectObjectId" :label="scope.row.id">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="表名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="tenant_id" label="租户id" />
            <el-table-column prop="package" label="包名" />
          </el-table>
        </el-tab-pane>
        <!-- 字段 -->
        <el-tab-pane
          label="字段"
          name="field"
          :disabled="!objectForm.slectObjectId"
          v-loading="fieldForm.loading"
        >
          <el-form
            :inline="true"
            ref="searchForm"
            :model="fieldForm"
            class="searchForm"
            @submit.native.prevent
          >
            <el-form-item label="" prop="serch">
              <el-input v-model="fieldForm.keyword" clearable placeholder="请输入字段名称" />
            </el-form-item>
          </el-form>
          <el-table
            :data="fieldListArr"
            height="450"
            border
            ref="fieldTab"
            style="width: 100%; overflow: auto"
            @row-click="handleSelectionlectFields"
          >
            <el-table-column align="center" width="55" label="选择">
              <template slot-scope="scope">
                <el-radio class="radio" v-model="fieldForm.slectFields" :label="scope.row">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column prop="name" label="字段名" width="180" />
            <el-table-column prop="display_name" label="中文名" width="180" />
            <el-table-column prop="description" label="描述">
              <template slot-scope="scope">
                <span v-if="scope.row.description.length < 21">{{ scope.row.description }}</span>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="scope.row.description"
                  placement="top"
                >
                  <span>{{ scope.row.description.slice(0, 20) }}...</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="value_type" label="值类型" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div>已选字段</div>
      <div class="select-field-box">
        <el-tag v-if="fieldForm.slectFields" closable @close="delSelectField">
          {{ fieldForm.slectFields.name }}.{{ fieldForm.slectFields.display_name }}.{{
            fieldForm.slectFields.value_type
          }}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const initObjectForm = {
  loading: false,
  keyword: '',
  offset: 0,
  limit: 10,
  sort: 'create_time desc',
  count: 0, // 总数
  slectObjectId: null, // 选择的对象id
  list: [], // 表格数据
};
const initFieldForm = {
  loading: false,
  list: [],
  slectFields: null,
  keyword: '',
};
export default {
  props: {
    objectNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialog: false,
      activeName: 'object',
      objectForm: {
        ...initObjectForm,
      },
      fieldForm: {
        ...initFieldForm,
      },
    };
  },
  computed: {
    objectListArr() {
      return this.objectForm?.list?.filter((item) =>
        item?.name?.includes(this.objectForm?.keyword),
      );
    },
    fieldListArr() {
      return this.fieldForm?.list?.filter((item) => item?.name?.includes(this.fieldForm?.keyword));
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
      this.activeName = 'object';
      this.objectForm = {
        ...initObjectForm,
      };
      this.fieldForm = {
        ...initFieldForm,
      };
      this.getObjectList();
    },
    handleClose() {
      this.showDialog = false;
    },
    handleClick(tab) {
      if (tab.paneName === 'field') {
        this.fieldForm.slectFields = null;
        this.getFieldList();
        return;
      }
      this.$nextTick(() => {
        this.fieldForm.list = [];
      });
    },
    async getObjectList() {
      if (!this.objectNames.length) {
        return;
      }
      try {
        this.objectForm.loading = true;
        const { offset, limit, sort } = this.objectForm;
        const promiseArr = [];
        this.objectNames.forEach((object_name) => {
          promiseArr.push(
            new Promise((resolve) => {
              this.$request({
                url: this.$api.metaGetObjectList,
                data: {
                  offset,
                  limit,
                  sort,
                  object_name,
                },
                showLoading: false,
              }).then((res) => {
                resolve(res.objects);
              });
            }),
          );
        });
        const resArr = await Promise.all(promiseArr);
        this.objectForm.list = resArr.flat();
        this.objectForm.loading = false;
      } catch (err) {
        console.log(err);
        this.objectForm.loading = false;
      }
    },
    handleSelectionChange(row) {
      this.objectForm.slectObjectId = row.id;
    },
    handleSelectionlectFields(row) {
      this.fieldForm.slectFields = row;
    },
    async getFieldList() {
      try {
        this.fieldForm.loading = true;
        const { fields } = await this.$request({
          url: this.$api.metaGetObjectFields,
          data: {
            object_id: this.objectForm.slectObjectId,
          },
        });
        this.fieldForm.list = fields;

        // 已勾选数据处理
        this.$nextTick(() => {
          this.fieldForm.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.$nextTick(() => {
          this.fieldForm.loading = false;
        });
      }
    },
    // 删除已选择的数据
    delSelectField() {
      this.fieldForm.slectFields = null;
    },
    save() {
      this.showDialog = false;
      if (this.fieldForm.slectFields?.name) {
        this.$emit('insertion', `{{${this.fieldForm.slectFields.name}}}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-field-box {
  min-height: 54px;
  max-height: 304px;
  border: 1px solid #e5eaf2;
  border-radius: 4px;
  margin-top: 8px;
  overflow-y: auto;
  ::v-deep {
    .el-tag {
      margin: 3px;
    }
  }
}
</style>
