<template>
  <el-card class="box-card">
    <el-table
      :data="diffList"
      style="width: 100%"
      :cell-style="markCellStyles"
      @cell-click="showJsonDiff"
    >
      <el-table-column label="fields:" fixed width="70">
        <template slot-scope="scope">
          {{ getTitle(scope.$index) }}
        </template>
      </el-table-column>
      <el-table-column :prop="key" :label="key" v-for="key in keys" :key="key" min-width="200">
        <template slot-scope="scope">
          <span v-if="getValue(scope.row[key]).length < 31">
            {{ getValue(scope.row[key]) }}
          </span>
          <template v-else-if="showDiffFields[$route.name].includes(key)">
            <el-tooltip effect="dark" content="点击查看差异" placement="top-end">
              <span>{{ getValue(scope.row[key]).slice(0, 30) }}...</span>
            </el-tooltip>
          </template>
          <el-tooltip v-else effect="dark" :content="getValue(scope.row[key])" placement="top-end">
            <span>{{ getValue(scope.row[key]).slice(0, 30) }}...</span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { getValue } from './diff';
const showDiffFields = {
  // 元数据版本差异
  'versions-diff': ['relations', 'ext'],
  // IAM版本差异
  'version-manage-diff': ['layout_json'],
};
export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDiffFields,
    };
  },
  computed: {
    diffList() {
      return this.list;
    },
  },
  methods: {
    showJsonDiff(row, column) {
      const field = column.property;
      if (showDiffFields[this.$route.name].includes(field)) {
        let oldStr = this.diffList[0][field];
        let newStr = this.diffList[1][field];
        try {
          if (this.diffList[0][field]) {
            if (typeof this.diffList[0][field] === 'object') {
              oldStr = JSON.stringify(this.diffList[0][field], null, '\t');
            } else {
              oldStr = JSON.stringify(JSON.parse(this.diffList[0][field]), null, '\t');
            }
          }
          if (this.diffList[1][field]) {
            if (typeof this.diffList[0][field] === 'object') {
              newStr = JSON.stringify(this.diffList[1][field], null, '\t');
            } else {
              newStr = JSON.stringify(JSON.parse(this.diffList[1][field]), null, '\t');
            }
          }
        } catch (err) {
          console.log(err);
        }
        this.$emit('diffjson', {
          oldStr,
          newStr,
          title: `${column.property}版本差异展示`,
        });
      }
    },
    getTitle(index) {
      return index === 0 ? '同步前:' : '同步后:';
    },
    getValue(val) {
      return getValue(val);
    },
    markCellStyles({ column, columnIndex }) {
      const key = column.property;
      return {
        backgroundColor: this.getType(this.diffList[0][key], this.diffList[1][key], columnIndex),
        color: 'black',
      };
    },
    getType(oldVal, newVal, columnIndex) {
      if (columnIndex === 0) {
        return '';
      }
      // 权限
      switch (`${this.action}`) {
        case 'CREATE': // 新增
          return '#7FF20B';
        case 'MODIFY': // 修改
          return '#f8c01f';
        case 'DELETE': // 删除
          return '#e53e31';
      }
      // 未变动
      if (typeof oldVal === 'object' && typeof newVal === 'object') {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return '';
        }
      } else {
        if (newVal === oldVal) {
          return '';
        }
      }

      // 删除
      if (newVal === undefined) {
        return '#e53e31';
      }
      // 新增
      if (oldVal === undefined) {
        return '#7FF20B';
      }
      // 修改
      return '#f8c01f';
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 6px 0;
  max-width: 100%;
}
</style>
