<template>
  <div>
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column label="流程Key">
        <template slot-scope="scope">
          <template v-for="(item, index) in scope.row.keys">
            <el-tag :key="index" type="warning">{{ item }}</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="event_key" label="event_key"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="updated_at" label="最后更新时间"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
          <el-dropdown trigger="click">
            <el-button type="text"><i class="iconfont icon-more" /></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="status === '1'" @click.native="changeStatus(scope.row, '2')">
                停用
              </el-dropdown-item>
              <el-dropdown-item v-else @click.native="changeStatus(scope.row, '1')">
                启用
              </el-dropdown-item>
              <el-dropdown-item @click.native="del(scope.row, scope.$index)" style="color: red">
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <messageBox ref="messageBox" />
  </div>
</template>
<script>
import messageBox from '@/components/messageBox';
export default {
  components: { messageBox },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.$request({
        url: this.$api.getProcessActionList,
        data: {
          page: this.pageIndex,
          size: this.pageSize,
          input_params: {
            status: this.status,
            category: '1',
          },
        },
        cb: ({ list, total }) => {
          if (list?.length) {
            this.list = list
              .filter((item) => item.category === 1)
              .map((obj) => ({
                ...obj,
                ...(obj.template?.notify || {}),
              }));
          } else {
            this.list = [];
          }
          this.total = Number(total);
        },
      });
    },
    goEdit({ id }) {
      this.$router.push({
        name: 'noitce-event-detail',
        query: {
          id,
          eventTypes: 'noticeEvent',
          statusTab: this.status,
        },
      });
    },
    del({ id }) {
      this.$refs.messageBox.show({
        title: '提示',
        content: '确定删除该数据吗?',
        titleIconType: 'warning',
        exitButton: true,
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.deleteProcessAction,
            data: { id },
            cb: () => {
              this.$refs.messageBox.close();
              this.pageIndex = 1;
              this.getList();
            },
          });
        },
      });
    },
    // 启用/禁用
    changeStatus({ id }, status) {
      // 接口名
      let api;
      switch (status) {
        case '1':
          api = this.$api.enableProcessAction;
          break;
        case '2':
          api = this.$api.disableProcessAction;
          break;
      }
      if (!api) return;
      this.$request({
        url: api,
        data: { id },
        cb: () => {
          this.pageIndex = 1;
          this.getList();
        },
      });
    },
    // 分页器
    handleSizeChange(value) {
      this.pageIndex = 1;
      this.pageSize = value;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.pagination {
  margin-top: 30px;
  text-align: right;
}

.menu-button {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
