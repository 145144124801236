<template>
  <div class="event">
    <div class="event-item">
      <div>事件类型</div>
      <el-radio-group class="radio-group" v-model="eventConfigFilter">
        <el-radio label="notify">通知消息</el-radio>
        <el-radio label="cloud_func">云函数自定义事件</el-radio>
      </el-radio-group>
    </div>
    <div class="event-item">
      <div>选择事件</div>
      <el-select :value="value" @change="valueChange" clearable multiple>
        <CheckboxOption
          v-for="item in eventListFilter"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="checkDisabled(item)"
          :selected="value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { getActionList } from './util';
import CheckboxOption from '@/components/checkboxOption';
export default {
  name: 'EventTransfer',
  components: { CheckboxOption },
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 触发事件内筛选的事件类型
      eventConfigFilter: 'notify',
      // 筛选事件类型列表
      eventTypeList: [
        {
          label: '通知消息',
          value: 'notify',
        },
        {
          label: '云函数自定义事件',
          value: 'cloud_func',
        },
      ],
      // 可选事件列表
      eventList: [],
      // 是否完成初始化
      init: false,
    };
  },
  computed: {
    eventListFilter() {
      // 按类型获取事件
      const typeList = this.eventList.filter((i) => i.template[this.eventConfigFilter]);

      // 拿到没有被选中的事件
      const selectedID = this.selected.map((i) => i.id);
      const list = typeList.filter(({ value }) => !selectedID.includes(value));

      return list;
    },
  },
  watch: {
    eventConfigFilter() {
      if (this.init) this.filterChange();
    },
  },
  mounted() {
    getActionList().then((res) => {
      this.eventList = res.list.map((i) => {
        return {
          label: i.name.length > 100 ? `${i.name.slice(0, 100)}...` : i.name,
          value: i.id,
          template: i.template,
          status: i.status,
          keys: i.keys,
        };
      });
      if (this.value.length) {
        const aim = this.value[0];
        const result = this.eventList.filter((i) => i.value === aim);
        // 所有值都必然属于同一类 所以只取一个值判断是通知模板还是云函数
        if (result[0].template.notify) {
          this.eventConfigFilter = 'notify';
        } else if (result[0].template.cloud_func) {
          this.eventConfigFilter = 'cloud_func';
        }
      }
      this.$nextTick(() => {
        this.init = true;
      });
    });
  },
  methods: {
    valueChange(e) {
      this.$emit('update', e);
    },
    // 变更时清空已选择的数据
    filterChange() {
      this.$emit('update', []);
    },
    checkDisabled(item) {
      // 禁用状态下的事件 以及已经被选择的云函数事件都不可以再选择
      return (
        item.status === 2 || (this.eventConfigFilter === 'cloud_func' && item.keys.length !== 0)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/select.scss';

.event {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .event-item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    ::v-deep.el-select {
      flex: 1;
      padding-left: 20px;
    }
    .radio-group {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
