<template>
  <t-card>
    <div class="top">
      <t-form layout="inline" :data="searchInfo" ref="searchForm">
        <t-form-item label="" name="keyword">
          <t-input placeholder="搜索名称" v-model="searchInfo.keyword">
            <template #suffixIcon>
              <search-icon :style="{ cursor: 'pointer' }" @click="getList" />
            </template>
          </t-input>
        </t-form-item>

        <t-form-item label="" name="appid">
          <t-select
            :options="appOptions"
            label="相关应用"
            placeholder="请选择"
            v-model="searchInfo.appid"
            :keys="{ label: 'name', value: 'id' }"
            @change="getList"
          ></t-select>
        </t-form-item>

        <t-form-item label="" name="">
          <t-button theme="primary" class="form-button-margin" @click="getList">搜索</t-button>
          <t-button theme="default" class="form-button-margin" @click="resetForm">重置</t-button>
        </t-form-item>
      </t-form>

      <div>
        <t-button theme="primary" @click="handleCreate">新建公共用户</t-button>
      </div>
    </div>

    <t-table
      :data="groupList"
      :columns="columns"
      class="table"
      row-key="index"
      :pagination="pagination"
      @page-change="onPageChange"
    >
      <template #operation="{ row }">
        <t-link theme="primary" hover="color" @click="handleEdit(row)" style="margin-right: 8px">
          编辑
        </t-link>
        <t-link theme="primary" hover="color" @click="handleDelete(row)">删除</t-link>
      </template>
    </t-table>

    <update-group
      :show="updateOrgsIsShow"
      :is-edit="isEdit"
      :appid="searchInfo.appid"
      :id="editId"
      @close="updateOrgsIsShow = false"
      @refresh="handleRefresh"
    ></update-group>
  </t-card>
</template>

<script>
import { DialogPlugin } from 'tdesign-vue';
import { SearchIcon } from 'tdesign-icons-vue';
import updateGroup from './components/updateGroup.vue';
export default {
  components: {
    SearchIcon,
    updateGroup,
  },
  data() {
    return {
      searchInfo: {
        keyword: '',
        appid: null,
      },
      appOptions: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      groupList: [],
      updateOrgsIsShow: false,
      isEdit: false,
      editId: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          colKey: 'name',
          title: '名称',
          width: 200,
        },
        {
          colKey: 'code',
          title: 'Code',
          width: 200,
        },
        {
          colKey: 'create_by',
          title: '创建人',
          width: 150,
        },
        {
          colKey: 'create_time',
          title: '创建时间',
          width: 150,
        },
        {
          colKey: 'update_time',
          title: '更新时间',
          width: 150,
        },
        {
          colKey: 'description',
          title: '描述',
          width: 400,
        },
        {
          colKey: 'operation',
          title: '操作',
          width: 150,
          fixed: 'right',
        },
      ];
    },
  },
  async mounted() {
    await this.getAppOptions();
    this.getList();
  },
  methods: {
    async getList() {
      console.log('------getList------');
      const payload = {
        ...this.searchInfo,
        appid: this.searchInfo.appid ? this.searchInfo.appid : 0,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      };
      const url = this.$api.getUserGroupList;
      const { data, total } = await this.$request({ url, data: payload });
      this.groupList = data;
      this.pagination.total = total;
    },
    async getAppOptions() {
      const res = await this.$request({
        url: this.$api.getAppList,
        data: { page_size: 9999 },
      });
      this.appOptions = res.data;
      if (this.appOptions.length > 0) {
        this.searchInfo.appid = this.appOptions[0].id;
        console.log('-------this.searchInfo.appid------', this.searchInfo.appid);
      }
    },
    resetForm() {
      this.searchInfo.keyword = '';
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    handleCreate() {
      this.updateOrgsIsShow = true;
      this.isEdit = false;
    },
    onPageChange(pageInfo, newDataSource) {
      console.log('pageInfo', pageInfo);
      this.pagination = pageInfo;
      console.log('newDataSource', newDataSource);
      this.getList();
    },
    handleEdit({ id }) {
      this.updateOrgsIsShow = true;
      this.isEdit = true;
      this.editId = id;
    },
    async handleDelete(row) {
      const confirmDia = DialogPlugin.confirm({
        header: `确定要删除（${row.name}）吗`,
        body: `删除后，（${row.name}）将消失`,
        confirmBtn: '确定',
        theme: 'warning',
        cancelBtn: '取消',
        onConfirm: async () => {
          console.log(row);
          try {
            const url = this.$api.deleteUserGroup;
            const data = { id: row.id };
            await this.$request({ url, data });
            this.$message.success('删除成功');
            this.getList();
            confirmDia.hide();
          } catch (e) {
            confirmDia.hide();
          }
        },
        onClose: () => {
          confirmDia.hide();
        },
      });
    },
    handleRefresh() {
      this.updateOrgsIsShow = false;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tdesign/form.scss';
@import '@/styles/tdesign/table.scss';
.top {
  display: flex;
  justify-content: space-between;
  .form-button-margin {
    margin-right: 16px;
  }
}
.table {
  margin-top: 16px;
}
</style>
