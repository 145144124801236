<template>
  <div>
    <v-tour class="myTour" name="myTour" :steps="steps" :callbacks="myCallback">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="actions" class="v-step__buttons">
                <button @click="handleSkip" class="v-step__button v-step__button-skip">跳过</button>
                <button
                  v-if="tour.currentStep < tour.steps.length - 1"
                  @click="tour.nextStep"
                  class="v-step__button v-step__button-next"
                >
                  下一步
                  <span class="num">{{ tour.currentStep + 1 + '/' + tour.steps.length }}</span>
                </button>
                <button v-else @click="tour.finish" class="v-step__button v-step__button-next">
                  完成
                </button>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <div v-if="isEnterOverView" class="mock" />
  </div>
</template>

<script>
import { MENU_DESC } from '@/layout-threeMenu/menu-desc';
export default {
  name: 'MetadataTour',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myCallback: {
        onStart: this.myonStart, // 在您开始游览时调用
        onStop: this.myonStop, // 停止游览时调用
        onNextStep: this.myonNextStep, // 进行下一步时调用
      },
      steps: [],
      nextStep: -1,
      stepOver: false,
      isClickSkip: false,
      // 是否关闭帮助执行
      removeStep: false,
      // 是否第一次进入帮助总览
      isEnterOverView: false,
      // 当前对象id
      objectID: '',
    };
  },
  watch: {
    nextStep() {
      this.$emit('change-step', this.nextStep);
    },
    stepOver() {
      this.$emit('tour-switch', this.stepOver);
    },
    list() {
      this.getStepList();
    },
    // 需要打开教程时获取当前对象id 判断对象符合条件才打开
    objectID() {
      this.startTour();
    },
  },
  mounted() {
    this.getStepList();
    this.stepOver = localStorage.getItem('show_help');
    this.nextStep = this.stepOver ? -1 : 0;

    this.$watch(
      () => this.$route,
      (toRoutes) => {
        // 路由变化时判断是否显示过新手指引
        const show_help = localStorage.getItem('show_help');
        if (show_help) {
          this.stepOver = true;
          this.nextStep = -1;
          this.isEnterOverView = false;
          this.$tours.myTour.finish();
        } else {
          // 用户首次进入元数据详情页，显示帮助指引
          if (toRoutes.name !== 'metadata-details') {
            const objectID = toRoutes.query.id;
            if (objectID) {
              this.objectID = objectID;
            }
          } else if (this.isEnterOverView) {
            // 用户从指引中返回元数据列表页时 关闭帮助指引
            this.stepOver = true;
            this.nextStep = -1;
            this.isEnterOverView = false;
            this.$tours.myTour.finish();
          }
        }
      },
    );
  },
  destroyed() {
    if (this.isEnterOverView) {
      this.removeStep = true;
      document.removeEventListener('click', this.handleClickBody, true);
      this.$tours.myTour.finish();
    }
  },
  methods: {
    myonStart() {
      return false;
    },
    myonStop() {
      this.stepOver = true;
      this.isEnterOverView = false;
      this.nextStep = -1;
      localStorage.setItem('show_help', true);
      return false;
    },
    myonNextStep(currentStep) {
      this.nextStep = currentStep + 1;
    },
    getStepList() {
      this.steps = this.list.map((menu) => {
        return {
          target: `.${menu?.code}`,
          content: `${MENU_DESC[menu.path] && MENU_DESC[menu.path].help_desc}`,
          params: {
            placement: 'right',
          },
        };
      });
    },
    handleSkip(value = false) {
      this.isClickSkip = true;
      this.$confirm(
        `您可以在各个配置步骤查看对应的帮助说明，并可以在查询帮助文档获取更多信息。帮助文档链接：<a style='color:#0077ff' href="https://help.nges.tencent.com/" target="_blank" rel="noopener noreferrer">https://help.nges.tencent.com/</a>`,
        '确定要关闭引导教程吗？',
        {
          confirmButtonText: '退出教程',
          cancelButtonText: '继续学习',
          type: 'warning',
          dangerouslyUseHTMLString: true,
        },
      )
        .then(() => {
          this.$tours.myTour.skip();
          if (value) {
            document.removeEventListener('click', this.handleClickBody, true);
          }
          this.isClickSkip = false;
        })
        .catch(() => {
          this.isClickSkip = false;
        });
    },
    handleClickBody(e) {
      if (!this.stepOver) {
        const dom = document.querySelector('.v-tour');
        const dom1 = document.querySelector('.main-container');
        const isIncluded = dom.contains(e.target);
        const isIncluded1 = dom1.contains(e.target);
        setTimeout(() => {
          if (dom) {
            if (!isIncluded && isIncluded1 && !this.isClickSkip && !this.removeStep) {
              this.handleSkip(true);
            }
          }
        }, 100);
      }
    },
    startTour() {
      this.$request({
        data: { id: this.objectID },
        url: this.$api.metaGetObjectDetail,
      })
        .then((res) => {
          if (res.object.data_engine !== 'CK') {
            this.stepOver = false;
            this.nextStep = 0;
            this.$tours.myTour.start();
            this.removeStep = false;
            this.isEnterOverView = true;
            // 点击其他交互区域也可关闭指引
            document.addEventListener('click', this.handleClickBody, true);
          } else {
            this.nextStep = -1;
            this.stepOver = true;
            this.removeStep = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mock {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.myTour {
  ::v-deep .v-step {
    width: 254px;
    height: 112px;
    background: #0077ff;
    padding: 12px;
    border-radius: 8px;
    margin-left: 16px !important;
    z-index: 1000;
    .v-step__content {
      color: #fff;
      font-weight: 700;
      margin-bottom: 18px;
      text-align: left;
    }
    .v-step__buttons {
      text-align: right;
      .v-step__button {
        border: none;
      }
      .v-step__button-next {
        width: 72px;
        height: 28px;
        color: #0077ff;
        background: #fff;
        border-radius: 4px;
        .num {
          margin-left: 1.5px;
        }
      }
      .v-step__button-skip:hover {
        background: none;
        color: #fff;
      }
    }
  }
}
</style>
