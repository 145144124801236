<template>
  <el-select
    :value="idList"
    multiple
    filterable
    remote
    :disabled="disabled"
    :remote-method="getUserList"
    @change="update"
  >
    <CheckboxOption
      v-for="(item, index) in nowUserList"
      :key="index"
      :label="item.label"
      :value="item.value"
      :selected="idList"
    />
  </el-select>
</template>

<script>
import _ from 'lodash';
import CheckboxOption from '@/components/checkboxOption';

export default {
  name: 'UserMultipleSelect',
  components: { CheckboxOption },
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    // 选中的值
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否需要筛选启用了企业微信/邮箱的用户
    needWecom: {
      type: Boolean,
      default: false,
    },
    needEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 筛选出的用户
      filterUserList: [],
      // 已选择的用户
      selectedUserList: [],
    };
  },
  computed: {
    // 展示的用户列表 已选项+筛选后的展示项
    nowUserList() {
      const noSelected = this.filterUserList.filter(({ id }) => {
        const result = this.selectedUserList.filter((i) => i.id === id);
        return result.length === 0;
      });
      return this.selectedUserList.concat(noSelected).map(this.renderFunction);
    },
    // 格式化后的id列表
    idList() {
      return this.value.map(({ object, value }) => `${object}:${value}`);
    },
  },
  watch: {
    needWecom() {
      this.filterUserList = [];
    },
    needEmail() {
      this.filterUserList = [];
    },
  },
  mounted() {
    this.initSelectedUser();
  },
  methods: {
    initSelectedUser() {
      if (this.value.length > 0) {
        const hcpList = this.value.filter(({ object }) => object === 'hcp').map((i) => i.value);
        const userList = this.value
          .filter(({ object }) => object === 'user_info')
          .map((i) => i.value);

        const query = `
          {
            user_info(
              id:{_in:[${userList.map((i) => `"${i}"`).join(',')}]},
            ) {
              id,
              name,
              email
            }
            hcp(
              id:{_in:[${hcpList.map((i) => `"${i}"`).join(',')}]},
            ) {
              id,
              name,
              email
            }
          }
        `;

        // 获取初始进入时的已选择用户列表
        this.$request({
          url: this.$api.queryData,
          data: {
            query,
          },
          cb: ({ data }) => {
            const hcpList = (data.hcp || []).map((i) => {
              return {
                ...i,
                obejct: 'hcp',
                id: `hcp:${i.id}`,
              };
            });
            const userList = (data.user_info || []).map((i) => {
              return {
                ...i,
                obejct: 'user_info',
                id: `user_info:${i.id}`,
              };
            });
            this.selectedUserList = hcpList.concat(userList);
          },
          showLoading: false,
        });
      }
    },
    // 获取选择审批人的可选列表
    getUserList: _.debounce(function (input) {
      if (input) {
        const query = `
          {
            user_info(
            name:{_like:"%${input}%"},
            ${this.needEmail ? 'email: {_is_null: false, _neq: ""},' : ''}
            ${this.needWecom ? 'enterprise_wx_id: {_is_null: false, _neq: ""},' : ''}
            _limit: 10, _offset: 0) {
              id,
              name,
              email
            }
            hcp(
            name:{_like:"%${input}%"},
            ${this.needEmail ? 'email: {_is_null: false, _neq: ""},' : ''}
            _limit: 10, _offset: 0) {
              id,
              name,
              email
            }
          }
        `;
        // 正在输入新数据的清空 按关键字请求数据 这个数据将接在已选审批人数据之后
        this.$request({
          url: this.$api.queryData,
          data: {
            query,
          },
          cb: ({ data }) => {
            const hcpList = (data.hcp || []).map((i) => {
              return {
                ...i,
                obejct: 'hcp',
                id: `hcp:${i.id}`,
              };
            });
            const userList = (data.user_info || []).map((i) => {
              return {
                ...i,
                obejct: 'user_info',
                id: `user_info:${i.id}`,
              };
            });
            this.filterUserList = hcpList.concat(userList);
          },
          showLoading: false,
        });
      }
    }, 200),
    // 默认渲染函数
    renderFunction({ name, email, id }) {
      if (email) {
        return {
          label: `${name}-${email}`,
          value: id,
        };
      }
      return {
        label: name,
        value: id,
      };
    },
    update(list) {
      // 更新已选数据列表
      const deleteList = [];
      const addList = [];
      list.forEach((i) => {
        if (!this.idList.includes(i)) addList.push(i);
      });
      this.idList.forEach((i) => {
        if (!list.includes(i)) deleteList.push(i);
      });
      addList.forEach((i) => {
        this.selectedUserList.push(...this.filterUserList.filter(({ id }) => id === i));
      });
      deleteList.forEach((i) => {
        const index = this.selectedUserList.findIndex(({ id }) => id === i);
        this.selectedUserList.splice(index, 1);
      });
      this.filterUserList = [];

      // 按加上的object属性区分选中的id是哪里来的
      const hcpList = [];
      const userList = [];
      list.forEach((i) => {
        const [obj, id] = i.split(':');
        switch (obj) {
          case 'hcp':
            hcpList.push(id);
            break;
          case 'user_info':
            userList.push(id);
            break;
          default:
            break;
        }
      });
      const result = [
        ...hcpList.map((id) => {
          return {
            type: 'id',
            object: 'hcp',
            value: id,
          };
        }),
        ...userList.map((id) => {
          return {
            type: 'id',
            object: 'user_info',
            value: id,
          };
        }),
      ];
      this.$emit('update', result);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/select.scss';
</style>
