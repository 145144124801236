<template>
  <el-dialog :visible.sync="visible" width="600px" :before-close="handleClose">
    <span slot="title" class="dialog-title">模型导入</span>
    <!-- <ImportExport @importFile="importFile" class="import-export" /> -->
    <!-- 导入 -->
    <el-upload
      class="import"
      drag
      action=""
      :on-change="onUploadChange"
      :on-error="onUploadErr"
      :on-remove="moveFile"
      :auto-upload="false"
      :show-file-list="true"
      :file-list="fileList"
      :multiple="false"
    >
      <img
        class="upload-icon"
        src="https://baike-med-1256891581.file.myqcloud.com/2022085/ccae6a70-12d5-11ed-89ce-4ff3ab5f0cf9_0.png"
        alt=""
      />
      <div class="el-upload__text">点击这里或将文件拖拽到这里上传</div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submit" :disabled="strList.length === 0">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
const acceptList = ['json'];
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
      strList: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('cancel', val);
        this.$nextTick(() => {
          this.fileList = [];
          this.strList = [];
        });
      },
    },
  },
  methods: {
    moveFile() {
      this.fileList = [];
      //   this.$emit('importFile', []);
      this.strList = [];
    },
    onUploadChange(evet, fileList) {
      if (!acceptList.includes(evet.name.split('.').pop())) {
        this.fileList = fileList.slice(-2, 1);
        this.$message.error(`仅支持上传${acceptList.join(',')}格式的文件`);
        return;
      }
      this.fileList = fileList.slice(-1);
      const reader = new FileReader();
      reader.readAsText(this.fileList[0].raw, 'UTF-8');
      reader.onload = (e) => {
        //   this.$emit('importFile', [e.target.result]);
        this.strList = [e.target.result];
      };
    },
    onUploadErr() {
      this.$message.error('文件导入失败');
    },
    handleClose() {
      this.visible = false;
    },
    async submit() {
      // 获取数据并验证
      let pressConfig = {};
      //   let modelConfig = {};
      try {
        pressConfig = JSON.parse(this.strList[0]);
      } catch (err) {
        console.log(err);
        this.$message.error('json文件格式错误');
        return;
      }
      const tips = [];
      try {
        JSON.parse(pressConfig.edit_json);
      } catch (err) {
        console.log(err);
        tips.push('模型json格式错误');
      }
      [
        // 'edit_process_desc',
        'edit_json',
        'edit_process_name',
        'module',
        'process_key',
      ].forEach((key) => {
        if (!Object.keys(pressConfig).includes(key)) {
          tips.push(`缺少配置参数:${key}`);
        }
      });
      if (tips.length) {
        tips.forEach((type) => {
          setTimeout(() => {
            this.$message.error(`导入文件${type}`);
          }, 10);
        });
        return;
      }
      // 新建
      try {
        await this.$request({
          url: this.$api.createEditProcess,
          data: {
            edit_process_name: pressConfig.edit_process_name,
            edit_json: pressConfig.edit_json,
            edit_process_desc: pressConfig.edit_process_desc || '',
            module: pressConfig.module,
            preset: pressConfig.preset,
            process_key: pressConfig.process_key,
            remark: pressConfig.remark || '',
            app_id: pressConfig.app_id,
            actions: pressConfig.actions || [],
            biz_type: pressConfig.biz_type,
          },
        });
        this.visible = false;
        this.$emit('refresh');
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';

.upload-icon {
  height: 56px;
  margin: 40px 0 10px;
}
.el-upload__text {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}
.import {
  text-align: center;
  ::v-deep.el-upload-dragger {
    border: none;
  }
}
</style>
