<template>
  <div>
    <div id="editor" ref="editor" />
  </div>
</template>
<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import QuillBetterTable from 'quill-better-table';
// 注册表格模块
Quill.register(
  {
    'modules/better-table': QuillBetterTable,
  },
  true,
);

const FontAttributor = Quill.import('attributors/class/size');
FontAttributor.whitelist = ['ft10', 'ft12', 'ft14', 'ft16', 'ft18', 'ft32'];
Quill.register(FontAttributor, true);
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      quill: null,
      options: {
        theme: 'snow',
        modules: {
          table: false,
          toolbar: {
            container: [
              [
                'undo',
                'redo',
                'clean',
                // { size: ["ft10", 'ft12', 'ft14', 'ft16', "ft18", "ft32"]},
                { header: [1, 2, 3, 4, 5, 6, false] },
                'bold',
                'italic',
                'underline',
                'strike',
                { color: [] },
                { background: [] },
                { align: [] },
                { indent: '+1' },
                { indent: '-1' },
                { list: 'ordered' },
                'better-table',
                'blockquote',
                'link',
              ],
            ],
            handlers: {
              'better-table'() {
                this.quill.getModule('better-table').insertTable(3, 3);
              },
              undo() {
                this.quill.history.undo();
              },
              redo() {
                this.quill.history.redo();
              },
            },
          },
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
          'better-table': {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: '插入右列',
                },
                insertColumnLeft: {
                  text: '插入左列',
                },
                insertRowUp: {
                  text: '插入上行',
                },
                insertRowDown: {
                  text: '插入下行',
                },
                mergeCells: {
                  text: '合并表格',
                },
                unmergeCells: {
                  text: '取消合并',
                },
                deleteColumn: {
                  text: '删除列',
                },
                deleteRow: {
                  text: '删除行',
                },
                deleteTable: {
                  text: '删除表格',
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
        placeholder: '从这里开始输入正文',
      },
    };
  },
  watch: {
    value(val) {
      if (this.quill) {
        if (!val) {
          this.quill.setText('');
        }
      }
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.initQuill();
  },
  methods: {
    initQuill() {
      this.quill = new Quill('#editor', this.options);
      // 撤销
      this.$el.querySelector(
        '.ql-undo',
      ).innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>撤回</title><g id="pc_icon/编辑器/撤回" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="编组-21"><rect id="矩形" fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24"></rect><path d="M12,11.9203588 L11.3573335,13 L6,9 L11.3573335,5 L12,6.07964123 L9.428,8 L18,8 L18,8.75 L17.999,16 L18,16 L18,17.5 L10,17.5 L10,16 L16.499,16 L16.5,9.5 L8.759,9.5 L12,11.9203588 Z" id="形状结合" fill-opacity=".65" fill="#000000"></path></g></g></svg>`;
      // 重做
      this.$el.querySelector(
        '.ql-redo',
      ).innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>重做</title><g id="pc_icon/编辑器/重做" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="编组-22"><rect id="矩形备份-43" fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24"></rect><g id="编组-7备份" transform="translate(12, 11.5) scale(-1, 1) translate(-12, -11.5) translate(6, 5)" fill="#000000" fill-opacity=".65"><path d="M6,6.92035877 L5.35733354,8 L0,4 L5.35733354,0 L6,1.07964123 L3.428,3 L12,3 L12,3.75 L11.999,11 L12,11 L12,12.5 L4,12.5 L4,11 L10.499,11 L10.5,4.5 L2.759,4.5 L6,6.92035877 Z" id="形状结合"></path></g></g></g></svg><div class="tools-border"></div>`;
      // 清除
      this.$el.querySelector(
        '.ql-clean',
      ).innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>清除</title><g id="橡皮擦" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="编组-23"></g><g id="编组-7" transform="translate(12.5, 11) rotate(135) translate(-12.5, -11) translate(9, 5)" fill="#000000" fill-rule="nonzero"><path d="M7,0 L7,12 L0,12 L0,0 L7,0 Z M6.125,0.923076923 L0.875,0.923076923 L0.875,11.0769231 L6.125,11.0769231 L6.125,0.923076923 Z" id="矩形" fill-opacity="0.65"></path><path d="M7,11.6 L0,11.6 M0.875,6 L0.875,11.08 L6.125,11.08 L6.125,6" id="矩形备份-5" fill-opacity="0.647590691"></path></g></g></svg><div class="tools-border"></div>`;
      // 表格
      this.$el.querySelector(
        '.ql-better-table',
      ).innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>表格</title><g id="pc_icon/编辑器/表格" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="编组-40"><rect id="矩形备份-61" fill="#FFF7F7" opacity="0" x="0" y="0" width="24" height="24"></rect><polygon id="矩形" fill-opacity="0.65" fill="#000000" points="6.5 9 17.5 9 17.5 10.5 6.5 10.5"></polygon><polygon id="矩形备份-36" fill-opacity="0.65" fill="#000000" points="6.5 13 17.5 13 17.5 14.5 6.5 14.5"></polygon><polygon id="矩形备份-38" fill-opacity="0.65" fill="#000000" transform="translate(10, 11.750000) rotate(-270) translate(-10, -11.750000) " points="8.75 11 11.25 11 11.25 12.5 8.75 12.5"></polygon><polygon id="矩形备份-40" fill-opacity="0.65" fill="#000000" transform="translate(13.750000, 11.750000) rotate(-270) translate(-13.750000, -11.750000) " points="12.5 11 15 11 15 12.5 12.5 12.5"></polygon><polygon id="矩形备份-39" fill-opacity="0.65" fill="#000000" transform="translate(10, 16) rotate(-270) translate(-10, -16) " points="8.5 15.25 11.5 15.25 11.5 16.75 8.5 16.75"></polygon><polygon id="矩形备份-41" fill-opacity="0.65" fill="#000000" transform="translate(13.750000, 16) rotate(-270) translate(-13.750000, -16) " points="12.25 15.25 15.25 15.25 15.25 16.75 12.25 16.75"></polygon><path d="M19,5 L19,19 L5,19 L5,5 L19,5 Z M17.5,6.5 L6.5,6.5 L6.5,17.5 L17.5,17.5 L17.5,6.5 Z" id="矩形" fill-opacity="0.650841346" fill="#000000" fill-rule="nonzero"></path></g></g></svg>`;
      // 超链接
      this.$el.querySelector(
        '.ql-link',
      ).innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>超链接</title><g id="pc_icon/编辑器/超链接" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g><rect id="矩形备份-64" fill="#FFF7F7" opacity="0" x="0" y="0" width="24" height="24"></rect><g id="编组" transform="translate(5, 4)" fill="#000000" fill-opacity="0.65" fill-rule="nonzero"><path d="M5.04,14 L-2.16271445e-13,8.96 L4.15195959,4.80804041 L4.991,5.648 L1.67944,8.95955959 L5.03944,12.3195596 L8.351,9.008 L9.19195959,9.84804041 L5.04,14 Z M8.96,1.50990331e-13 L14,5.04 L10.3119596,8.72804041 L9.471,7.888 L12.31944,5.03955959 L8.95944,1.67955959 L6.111,4.528 L5.27195959,3.68804041 L8.96,1.50990331e-13 Z" id="形状结合" transform="translate(7, 7) rotate(180) translate(-7, -7) "></path><polygon id="路径-13" transform="translate(6.995534, 6.995534) rotate(180) translate(-6.995534, -6.995534) " points="8.65021442 4.49553391 9.49553391 5.34085339 5.34085339 9.49553391 4.49553391 8.65021442"></polygon></g></g></g></svg>`; // 对齐方式
      this.quill.on('text-change', () => {
        let html = this.$refs.editor.children[0].innerHTML;
        if (html === '<p><br></p>') html = '';
        console.log('html===', html);
        this.$emit('input', html);
      });
      this.quill.on('selection-change', (range) => {
        if (!range) {
          if (
            this.$refs.editor.children[0].innerHTML === '<p><br></p>' ||
            this.$refs.editor.children[0].innerHTML === '<p></p>' ||
            this.$refs.editor.children[0].innerHTML === ''
          ) {
            const placeHolderWord = document.getElementsByClassName('ql-editor')[0];
            placeHolderWord.dataset.placeholder = '从这里开始输入正文';
          }
          this.$emit('blur', this.quill);
        } else {
          const placeHolderWord = document.getElementsByClassName('ql-editor')[0];
          placeHolderWord.dataset.placeholder = '';
          this.$emit('focus', this.quill);
        }
      });
      // Emit ready event
      this.$emit('ready', this.quill);
    },
    insertText(text) {
      if (this.quill && text.length) {
        const positionIndex = this.quill.selection.savedRange.index;
        this.quill.insertText(positionIndex, text);
        this.quill.setSelection(positionIndex + text.length);
      }
    },
    initHtml(html) {
      this.$refs.editor.children[0].innerHTML = html;
    },
  },
};
</script>
<style lang="scss">
.qlbt-operation-menu {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  z-index: 100000;
  overflow: hidden;
}
.qlbt-operation-menu .qlbt-operation-menu-dividing {
  height: 1px;
  background-color: #efefef;
}
.qlbt-operation-menu .qlbt-operation-menu-subtitle {
  color: #999;
  font-size: 14px;
  padding: 5px 16px;
}
.qlbt-operation-menu .qlbt-operation-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #fff;
  cursor: pointer;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qlbt-operation-menu .qlbt-operation-menu-item:hover {
  background-color: #efefef;
}
.qlbt-operation-menu .qlbt-operation-menu-item .qlbt-operation-menu-icon {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  font-size: 0;
}

.qlbt-col-tool {
  position: absolute;
  align-items: flex-end;
  overflow: hidden;
  height: 16px;
  display: none;
}
.qlbt-col-tool .qlbt-col-tool-cell {
  position: relative;
  background-color: #fff;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.qlbt-col-tool .qlbt-col-tool-cell:first-child {
  border-left: 1px solid #000;
}
.qlbt-col-tool .qlbt-col-tool-cell-holder {
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: transparent;
  cursor: ew-resize;
}
.qlbt-col-tool .qlbt-col-tool-cell-holder:hover {
  background-color: #0589f3;
}
.qlbt-col-tool .qlbt-col-tool-cell-holder::before {
  content: '';
  position: absolute;
  top: 0;
  left: -6px;
  display: block;
  width: 8px;
  height: 100%;
}
.qlbt-col-tool .qlbt-col-tool-cell-holder::after {
  content: '';
  position: absolute;
  top: 0;
  right: -6px;
  display: block;
  width: 8px;
  height: 100%;
}
</style>
<style lang="scss" scoped>
::v-deep .ql-toolbar.ql-snow {
  border: 1px solid transparent;
}
::v-deep .ql-toolbar.ql-snow button:hover {
  background: #f6f7fb;
}
.ql-container.ql-snow {
  border: 1px solid transparent;
  background: #f6f7fb;
  border-radius: 4px;
}
// 表格
// ::v-deep .quill-better-table-wrapper {
//   overflow-x: auto;
// }

// ::v-deep table.quill-better-table {
//   table-layout: fixed;
//   border-collapse: collapse;
// }
// ::v-deep table.quill-better-table td {
//   border: 1px solid #000;
//   padding: 2px 5px;
// }

// 字体大小
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft10']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft10']::before {
  content: '10px';
}
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft12']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft12']::before {
  content: '12px';
}
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft14']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft14']::before {
  content: '14px';
}
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft16']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft16']::before {
  content: '16px';
}
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft18']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft18']::before {
  content: '18px';
}
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='ft32']::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='ft32']::before {
  content: '32px';
}

::v-deep .ql-size-ft10 {
  font-size: 10px;
}
::v-deep .ql-size-ft12 {
  font-size: 12px;
}

::v-deep .ql-size-ft14 {
  font-size: 14px;
}

::v-deep .ql-size-ft16 {
  font-size: 16px;
}

::v-deep .ql-size-ft18 {
  font-size: 18px;
}

::v-deep .ql-size-ft32 {
  font-size: 32px;
}
</style>
