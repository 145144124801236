import Vue from 'vue';
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import { set } from 'lodash';

// const ajaxUrl = env === 'development' ? 'http://127.0.0.1:3000' : ''
const ajaxUrl = '';

const newAxios = axios.create({
  baseURL: ajaxUrl,
  timeout: 90000,
});

let loadingInstance;
let requestCount = 0;
let loadFlag = true;
let showErrMessage = true;

newAxios.interceptors.request.use((config) => {
  const { showErrMessage: showMessage = true } = config;
  showErrMessage = showMessage;
  if (requestCount === 0 && loadFlag) {
    loadingInstance = Loading.service({ target: '.app-main' });
  }
  requestCount = requestCount + 1;
  return config;
});

newAxios.interceptors.response.use(
  (res) => {
    requestCount = requestCount - 1;
    if (loadingInstance && requestCount === 0) {
      loadingInstance.close();
      loadFlag = false;
      setTimeout(() => {
        loadFlag = true;
      }, 500);
    }

    // 登录失效，重新登录
    if (!res.data.body || [-405, -406, 1004].includes(res.data.body.retcode)) {
      if (window.location.pathname.indexOf('/callback/login') === -1) {
        window.location.href = '/login';
      }
    }

    // 响应失败
    if (!res.data.body || res.data.body.bizcode !== 0 || res.data.body.retcode !== 0) {
      showErrMessage && Message.error(res.data.body.message);
      return Promise.reject(res.data.body.message || '接口异常');
    }
    return res.data.body.payload;
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
      loadingInstance = null;
    }
    showErrMessage && Message.error(error.message);
    return Promise.reject(error);
  },
);

const request = function ({
  url,
  cb,
  data = [],
  type = 'post',
  fault,
  tmpToken,
  config = {},
  showLoading = true,
  client = {},
  tst = false,
}) {
  let httpProcess;
  loadFlag = showLoading;
  // 注入当前页面路径到headers中
  set(config, 'headers["X-Nges-Location"]', encodeURI(location.pathname + location.search));
  if (type === 'get') {
    httpProcess = newAxios.get(url, { params: data }, config);
  } else {
    // 类似登录后发送验证码的请求需要用到临时token 此时由业务手动传参防止token记录到cookie用于其他请求
    const token = tmpToken || Vue.$cookies.get('saas_token');
    if (window.location.pathname.indexOf('/callback/login') === -1) {
      if (!token) {
        this.$router.push({
          path: '/login',
        });
      }
    }
    httpProcess = newAxios.post(
      url,
      {
        header: {
          version: '',
          flag: 0,
        },
        body: {
          seq: 1,
          cmd: '',
          token,
          isTSTReq: tst,
          client: {
            platform: client.platform || 2, // 1-小程序、2-PC、3-H5
            env: client.env || '',
            isTourist: client.isTourist || 48,
            product: client.product || 1,
          },
          payload: data,
        },
      },
      config,
    );
  }
  return new Promise(function (resolve, reject) {
    httpProcess
      .then((data) => {
        cb && cb(data);
        resolve(data);
      })
      .catch((res) => {
        fault && fault(res);
        reject(res);
      });
  });
};

export { newAxios, request };
