// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import TDesign from 'tdesign-vue';
import App from './App';
import router from './router';
import ElementUI from 'element-ui';
import { api, request, axios } from './api';
import filters from './filters';
import VueCookies from 'vue-cookies';
import store from './store';
import config from '@/config';
import plugins from './plugins';
import VueTour from 'vue-tour';
import { codemirror } from 'vue-codemirror';
import { registerGlobalDirective } from '@/config/register-global';

import 'jsoneditor/dist/jsoneditor.min.css'; // json编辑器
import 'element-ui/lib/theme-chalk/index.css'; // element-ui
import '@/styles/index.scss';
import '@/styles/element/index.scss';
import '@/styles/iconfont/style.css';
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css';
import { ExpressionScriptManage } from './utils/expressionScript';
require('vue-tour/dist/vue-tour.css');

Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(codemirror);
Vue.use(TDesign);
Vue.use(plugins);
Vue.use(VueTour);

const expressionScriptManage = new ExpressionScriptManage();

Vue.prototype.$request = request;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$expManage = expressionScriptManage;

registerGlobalDirective(Vue);

if (config.sign) {
  document.getElementsByTagName('title')[0].innerText = `(${config.sign})${
    document.getElementsByTagName('title')[0].innerText
  }`;
}
// v-loadmore 监听表格滚动触底
Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper');
    selectWrap.addEventListener('scroll', function () {
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
      if (scrollDistance <= 0) {
        binding.value();
      }
    });
  },
});
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>',
});
