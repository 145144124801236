<template>
  <el-dialog title="新建模型" :visible="visible" width="600px" @close="close">
    <el-form :model="processModelInfo" label-width="100px" ref="processModelForm">
      <el-form-item
        label="产品应用:"
        prop="app_id"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <el-select v-model="processModelInfo.app_id" placeholder="请选择" style="width: 100%">
          <el-option
            v-for="obj in appList"
            :key="obj.label"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="名称:"
        prop="edit_process_name"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-input
          v-model="processModelInfo.edit_process_name"
          autocomplete="off"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="流程key:"
        prop="process_key"
        :rules="[
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z]/,
            message: '请输入字母开头的流程key',
          },
        ]"
      >
        <el-input
          v-model="processModelInfo.process_key"
          autocomplete="off"
          maxlength="25"
          placeholder="请输入"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="业务模块:" prop="module">
        <el-select v-model="processModelInfo.module" placeholder="请选择" style="width: 100%">
          <el-option
            v-for="obj in businessModuleList"
            :key="obj.value"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="业务类型:"
        :prop="processModelInfo.dcrTypeOption === 'biz_type' ? 'biz_type' : 'dcrTypeOption'"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <el-select
          v-model="processModelInfo.biz_type"
          placeholder="请选择业务类型"
          style="width: 100%"
          v-if="processModelInfo.module !== 'DCR'"
        >
          <el-option
            v-for="obj in businessTypeList"
            :key="obj.value"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
        <div v-else>
          <div>
            <el-radio v-model="processModelInfo.dcrTypeOption" label="biz_type">
              <el-select
                v-model="processModelInfo.biz_type"
                placeholder="请选择业务类型"
                style="width: 200px"
                :disabled="processModelInfo.dcrTypeOption !== 'biz_type'"
              >
                <el-option
                  v-for="obj in businessTypeList"
                  :key="obj.value"
                  :label="obj.label"
                  :value="obj.value"
                ></el-option>
              </el-select>
            </el-radio>
            <el-radio v-model="processModelInfo.dcrTypeOption" label="custom_variable">
              统一使用DCR业务类型变量
            </el-radio>
          </div>
          <div class="dcr-type-intro">
            勾选 “统一使用DCR业务类型变量“后，将为DCR业务类型配置统一的审批流程
            <br />
            需在【模型设计 ->发起人->输入变量 】中指定统一的dcr变量“
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="系统预置:"
        prop="preset"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-radio v-model="processModelInfo.preset" :label="true">是</el-radio>
        <el-radio v-model="processModelInfo.preset" :label="false">否</el-radio>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          type="textarea"
          :rows="2"
          v-model="processModelInfo.edit_process_desc"
          maxlength="50"
          show-word-limit
          placeholder="请输入"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 流程模版信息
      processModelInfo: {
        // 业务模块
        module: '',
        // 业务类型
        biz_type: '',
        process_key: '',
        edit_process_name: '',
        edit_process_desc: '',
        edit_json: '{}',
        app_id: '',
        preset: false,
        // dcr 业务类型区分选择 默认使用 业务类型(biz_type); 类型变量 custom_variable
        dcrTypeOption: 'biz_type',
      },
    };
  },
  watch: {
    'processModelInfo.dcrTypeOption'(val) {
      if (val === 'custom_variable') {
        this.processModelInfo.biz_type = '';
      }
    },
    'processModelInfo.module'(val) {
      if (val !== 'DCR') {
        this.processModelInfo.dcrTypeOption = 'biz_type';
      }
    },
  },
  computed: {
    ...mapGetters({
      businessTypeList: 'workflow/getBusinessTypeList',
      businessModuleList: 'workflow/getBusinessModuleList',
    }),
  },
  methods: {
    submit() {
      this.$refs.processModelForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          const params = {
            ...this.processModelInfo,
            biz_type:
              this.processModelInfo.dcrTypeOption === 'custom_variable'
                ? this.processModelInfo.dcrTypeOption
                : this.processModelInfo.biz_type,
            dcrTypeOption: undefined,
          };
          const { id } = await this.$request({
            url: this.$api.createEditProcess,
            data: params,
          });
          if (id) {
            this.$message.success('创建成功');
            setTimeout(() => {
              this.$router.push({
                name: 'workflow-detail',
                query: {
                  id,
                },
              });
            }, 500);
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    close() {
      this.processModelInfo.app_id = '';
      this.processModelInfo.edit_process_name = '';
      this.processModelInfo.process_key = '';
      this.processModelInfo.edit_process_desc = '';
      this.processModelInfo.module = '';
      this.processModelInfo.biz_type = '';
      this.processModelInfo.preset = false;
      (this.processModelInfo.dcrTypeOption = 'biz_type'), this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
.dcr-type-intro {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #98a2b2;
}
</style>
