export default {
  env: 'development',
  domain: ['ngesdev.testsite.woa.com', 'iamparisdev.testsite.woa.com', 'devadmin.nges.qq.com'],
  mobileDomain: 'v2devh5.tsepcn.com',
  aegisId: 'akNqIsanJXJnMpAhyv',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'nges',
  bucket: 'tsep-test-1304685099',
  region: 'ap-guangzhou',
  sign: '开发',
};
