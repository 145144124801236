import { defaultUserNodeFormValue } from '@/components/antv/config.js';
export const candidatApprovalTypes = [
  {
    value: 'orSign',
    label: '或签（一人通过即可）',
  },
  {
    value: 'andSign',
    label: '会签（全部通过才可通过）',
  },
  {
    value: 'inTurnSign',
    label: '依次审批（按顺序依次审批）',
  },
];
export const attributesApprovalTypes = [
  {
    value: 'orSign',
    label: '或签（一人通过即可）',
  },
  {
    value: 'andSign',
    label: '会签（全部通过才可通过）',
  },
];
export const defaultFindStrategy = [
  {
    value: 'skip',
    label: '自动跳过',
  },
  {
    value: 'previous',
    label: '上级审批',
  },
  {
    value: 'empty',
    label: '--空--',
  },
];
export const resubmitPolicy = [
  {
    value: 1,
    label: '被退回重新提交后直接回到本节点',
  },
  {
    value: 0,
    label: '被退回重新提交后按节点顺序重新审批',
  },
];
/**
 * @description: 审批人节点config转换
 * @param {Object} defaultConfig
 * @return {Object} config
 */
export function generateUserNodeConfig(defaultConfig) {
  const config = { ...defaultConfig };
  let validated = true;
  // metadata里面的数据格式为方便展示由前端来定，后端存储
  //  审批类型 leader attribute candidate 其中之一
  const { operator_type } = config.metadata;

  // {
  //   leader_type: '',  领导类型
  //   attribute_keys: [ key, ], 属性勾选数组
  //   attribute_value_map: { key: value }, 属性值map
  //   attribute_sign_type: '' 按属性审批通过类型
  //   candidate_user: [], 指定人
  //   candidate_sign_type: '' 指定审批人审批通过类型
  // }
  const { operator_value } = config.metadata;
  const new_operator_value = {};
  Object.keys(operator_value).forEach((key) => {
    if (key.includes(operator_type)) {
      if (key === 'leader_type') {
        if (!config.metadata.operator_value[key]) {
          this.$message({
            message: '请选择岗位上级类型',
            type: 'error',
          });
          validated = false;
          return;
        }
      }
      if (
        ['attribute_find_strategy', 'candidate_find_strategy', 'leader_find_strategy'].includes(key)
      ) {
        if (!config.metadata.operator_value[key]) {
          this.$message({
            message: '请选择当前节点未找到审批人时的方案',
            type: 'error',
          });
          validated = false;
          return;
        }
      }
      if (key === 'attribute_keys') {
        if (!config.metadata.operator_value[key]?.length) {
          this.$message({
            message: '请指定用户属性',
            type: 'error',
          });
          validated = false;
          return;
        }
      }
      if (key === 'attribute_value_map') {
        Object.keys(operator_value[key]).forEach((attributeKey) => {
          if (!operator_value.attribute_keys.includes(attributeKey)) {
            config.metadata.operator_value.attribute_value_map[attributeKey] = [];
          }
        });
      }
      if (key === 'candidate_user') {
        if (operator_value[key].length > 1 && !config.metadata.operator_value.candidate_sign_type) {
          this.$message({
            message: '请指定多人审批时的审批通过类型',
            type: 'error',
          });
          validated = false;
          return;
        }
      }
      if (key === 'custom_condition') {
        if (!config.metadata.operator_value.custom_condition.length) {
          this.$message({
            message: '请选择自定义标签',
            type: 'error',
          });
          validated = false;
          return;
        }
      }
      new_operator_value[key] = config.metadata.operator_value[key];
    } else {
      config.metadata.operator_value[key] = defaultUserNodeFormValue[key];
    }
  });
  if (!validated) {
    return {};
  }
  config.metadata.operator_value = {
    ...defaultUserNodeFormValue,
    ...new_operator_value,
  };
  return config;
}
