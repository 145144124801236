<template>
  <div>
    <div class="intro-title">基础信息</div>
    <div>
      <div class="node-title">节点ID</div>
      <el-input disabled :value="value.id" />
      <div class="node-title">节点名称</div>
      <el-input
        :value="value.fullname"
        maxlength="20"
        show-word-limit
        @input="(e) => valueUpdate('fullname', e)"
      />
      <div class="node-title">
        站内信类型
        <span class="require">*</span>
      </div>
      <el-select
        filterable
        :value="value.metadata.msg_type"
        placeholder="请选择"
        @change="(e) => valueUpdate('metadata.msg_type', e)"
      >
        <el-option
          v-for="item in systemMessageTypeList"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
      <div class="node-title">备注描述</div>
      <el-input
        type="textarea"
        :rows="2"
        :value="value.description"
        @input="(e) => valueUpdate('description', e)"
        maxlength="50"
        autocomplete="off"
        placeholder="请输入"
      ></el-input>
    </div>
    <div class="intro-title">接收人信息</div>
    <div>
      <div class="node-title">
        接收人
        <span class="require">*</span>
      </div>
      <el-radio
        :value="value.metadata.receiver_type"
        label="var"
        @input="(e) => valueUpdate('metadata.receiver_type', e)"
      >
        变量
      </el-radio>
      <el-input
        style="margin-top: 5px"
        v-if="value.metadata.receiver_type === 'var'"
        :value="value.metadata.to[0]"
        placeholder="插入变量"
        @focus="openVariantDia"
      />
    </div>
    <div class="intro-title">通知信息</div>
    <div>
      <div class="node-title">
        通知标题
        <span class="require">*</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-input
          style="flex: 1"
          :value="value.metadata.subject"
          placeholder="请输入"
          @input="(e) => valueUpdate('metadata.subject', e)"
        />
        <el-button type="info" class="primary" @click="openTitleDia" style="margin-left: 8px">
          插入变量
        </el-button>
      </div>
      <div class="node-title">
        通知内容
        <span class="require">*</span>
      </div>
      <el-button type="info" class="primary" @click="openContentDia" style="margin-left: 8px">
        插入变量
      </el-button>
      <QuillEditorVue ref="quillEditor" v-model="noticeContent" />
    </div>
    <template v-if="varDiaShow">
      <notice-varibale-dialog
        :show="varDiaShow"
        :variable-text="variableText"
        @cancel="(e) => (varDiaShow = e)"
        @insertion="(e) => insertVariable(e)"
        :var-type="varType"
      />
    </template>
  </div>
</template>
<script>
import noticeVaribaleDialog from '../../components/noticeVaribaleDialog.vue';
import QuillEditorVue from './quillEditor.vue';

export default {
  name: '',
  components: {
    noticeVaribaleDialog,
    QuillEditorVue,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      systemMessageTypeList: [],
      varDiaShow: false,
      varType: '', // var title content
      noticeContent: '',
    };
  },
  computed: {
    variableText() {
      if (this.varType === 'var') {
        return this.value.metadata.to[0] || '';
      }
      return '';
    },
  },
  watch: {
    noticeContent(val) {
      this.valueUpdate('metadata.content', val);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.getInmailTypeList();
    this.noticeContent = this.value.metadata.content;
    this.$refs.quillEditor.initHtml(this.value.metadata.content);
  },
  methods: {
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
    getInmailTypeList() {
      this.$request({
        url: this.$api.metaGetObjectByName,
        data: {
          objects: ['message'],
        },
        cb: ({ list }) => {
          if (list.length) {
            const field = list[0].fields.filter(({ name }) => name === 'type');
            this.systemMessageTypeList = field[0].select_one_option.options;
            const systemNoticeVar =
              field[0].select_one_option.options.find((item) => item.label === '系统通知')?.value ||
              '';
            if (!this.value.metadata.msg_type) {
              this.valueUpdate('metadata.msg_type', systemNoticeVar);
            }
          }
        },
      });
    },
    openVariantDia() {
      this.varType = 'var';
      this.varDiaShow = true;
    },
    openTitleDia() {
      this.varType = 'title';
      this.varDiaShow = true;
    },
    openContentDia() {
      this.varType = 'content';
      this.varDiaShow = true;
    },
    insertVariable(e) {
      switch (this.varType) {
        case 'var':
          this.valueUpdate('metadata.to', [e]);
          return;
        case 'title':
          this.valueUpdate('metadata.subject', this.value.metadata.subject + e);
          return;
        case 'content':
          this.$refs.quillEditor.insertText(e);
          return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
@import '@/styles/element/button.scss';

.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-inputbar {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f6f7fb;
}
.intro-title {
  height: 40px;
  line-height: 40px;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 14px;
  color: #667082;
  margin: 10px -10px 0;
  padding-left: 10px;
}
::v-deep #editor {
  width: auto;
}
::v-deep .ql-toolbar.ql-snow {
  width: auto;
}
::v-deep .ql-editor {
  padding: 12px 20px;
}
</style>
