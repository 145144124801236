<template>
  <el-card class="table-container">
    <!-- <div class="top">
      <el-button class="add-button" type="primary" icon="el-icon-plus" @click="handleAdd()">
        添加
      </el-button>
    </div> -->

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="应用名" min-width="100"></el-table-column>
      <el-table-column prop="ext.lang_type_desc" label="应用语言" min-width="100"></el-table-column>
      <el-table-column prop="app_key" label="App Key" min-width="140"></el-table-column>
      <el-table-column prop="description" label="应用描述" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.description | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="170">
        <template slot-scope="scope">
          <el-button type="text" @click="handleSwithLanguage(scope.$index, scope.row)">
            切换语言
          </el-button>
          <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      hide-on-single-page
      background
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="(val) => (searchObj.page = val)"
      @size-change="(val) => (searchObj.page_size = val)"
    />

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="应用名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="应用Key" prop="app_key" v-if="operation != 'update'">
          <el-input v-model="formObj.app_key"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="切换语言"
      :visible.sync="languageFormObj.visible"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form ref="form" :model="languageFormObj" :rules="rules" label-width="80px">
        <el-form-item
          label="应用语言"
          prop="selectType"
          :rules="[{ required: true, message: '语言不能为空' }]"
        >
          <el-select v-model="languageFormObj.selectType" placeholder="请选择">
            <el-option
              v-for="item in languageFormObj.languageList"
              :key="item.type"
              :label="item.desc"
              :value="item.type"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="languageFormObj.visible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="!languageFormObj.selectType"
          @click="handleConfirmLanguage"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { I18N_LANG_SELECT } from '@/utils/constant';
export default {
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{3,30}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为3到30'));
      }
      callback();
    };

    return {
      operation: 'create',
      listData: [],
      count: 0,
      groupOption: [],
      formObj: {},
      oldSearchObj: {},
      searchObj: {
        keyword: this.$route.query.keyword,
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入应用名称',
            trigger: 'blur',
          },
        ],
        app_key: [
          {
            required: true,
            message: '请输入应用Key',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      opDialogVisible: false,
      submitDisable: false,

      languageFormObj: {
        visible: false, // 切换语言弹框
        selectData: {}, // 弹框选择的数据
        selectType: '', // 弹框正在切换的数据
        languageList: [], // 弹框语言列表
      },
    };
  },
  watch: {
    searchObj: {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
          this.searchObj.page = 1;
        }
        this.oldSearchObj = Object.assign({}, this.searchObj);
        this.timer = setTimeout(() => {
          this.$router.push({ query: this.searchObj });
          sessionStorage.setItem('app_id', this.searchObj.appid);
          this.loadListData();
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    loadListData() {
      const url = this.$api.getAppList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
        this.processData();
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateApp : this.$api.createApp;
        const msg = id ? '修改成功' : '创建成功';
        const data = this.formObj;
        if (id) {
          data.id = id;
        }
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteApp;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {};
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {
        id: data.id,
        name: data.name,
        description: data.description,
      };
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该应用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // eslint-disable-next-line no-unused-vars
    handleSwithLanguage(index, data) {
      const { languageFormObj } = this;
      languageFormObj.visible = true;
      languageFormObj.selectData = data;
      languageFormObj.selectType = data.ext.lang_type;
      const { languageList } = languageFormObj;
      if (!languageList.length) {
        const url = this.$api.getLanguageList;
        const cb = (data) => {
          data?.lang_type_list?.forEach((type) => {
            languageList.push(this.getLanguageTypeDescObj(type));
          });
        };
        this.$request({ url, data: {}, cb });
      }
    },
    getLanguageTypeDescObj(type) {
      const name = I18N_LANG_SELECT[type] || '';
      return {
        name,
        type,
        desc: `${name}（${type}）`,
      };
    },
    processData() {
      this.listData.forEach((rowData) => {
        this.processLanguageData(rowData);
      });
    },
    processLanguageData(rowData) {
      if (!rowData.ext) {
        // eslint-disable-next-line no-param-reassign
        rowData.ext = {};
      }

      if (rowData.ext.lang_type_desc) {
        return rowData.ext.lang_type_desc;
      }

      if (!rowData.ext.lang_type) {
        // eslint-disable-next-line no-param-reassign
        rowData.ext.lang_type = 'zh';
      }

      const lang_type_desc = this.getLanguageTypeDescObj(rowData.ext.lang_type).desc;
      // eslint-disable-next-line no-param-reassign
      rowData.ext.lang_type_desc = lang_type_desc;
      return lang_type_desc;
    },
    handleConfirmLanguage() {
      const { selectData, selectType } = this.languageFormObj;

      const url = this.$api.updateLanguage;
      const cb = () => {
        selectData.ext.lang_type = selectType;
        selectData.ext.lang_type_desc = this.getLanguageTypeDescObj(selectType).desc;
        this.languageFormObj.visible = false;
        this.listData = [...this.listData];
      };
      this.$request({ url, data: { id: selectData.id, lang_type: selectType }, cb });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
</style>
