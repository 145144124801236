<template>
  <div>
    <el-dialog
      title="插入变量"
      :visible.sync="showDialog"
      width="960px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="container">
        <div class="left-wrapper">
          <el-select
            @change="insertSystemVariable"
            :value="undefined"
            placeholder="系统变量: 请选择"
          >
            <el-option
              v-for="item in systemVariableOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            style="margin-top: 10px"
          ></el-input>
          <div class="var-title">引用变量</div>
          <el-tree
            style="margin-top: 10px; width: 100%"
            :data="treeData"
            :props="treeProps"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="rigth-wrapper">
          <el-input
            type="textarea"
            :rows="23"
            placeholder="请在左侧点击选择内容或直接输入变量"
            class="varilable_text"
            v-model="varText"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    show: { type: Boolean, default: false },
    variableText: { type: String, default: '' },
    varType: { type: String, default: '' },
  },
  data() {
    return {
      systemVariableOptions: [
        {
          label: '上一节点处理人',
          value: 'previous_assignees',
        },
        {
          label: '发起人',
          value: 'start_user',
        },
        {
          label: '上一节点处理人岗位上级',
          value: 'func.Leader(previous_assignees)',
        },
      ],
      varText: '',
      filterText: '',
      startObjectFiledsList: [],
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'label',
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      businessEntityList: 'notice/getObjectList',
      startNodeObject: 'workflow/getStartNodeObject',
    }),
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('cancel', val);
      },
    },
  },
  mounted() {
    this.varText = this.variableText;
    if (this.startNodeObject) {
      const objId = this.businessEntityList.find(
        (object) => object.name === this.startNodeObject,
      )?.id;
      const objDisPlayName = this.businessEntityList.find(
        (object) => object.name === this.startNodeObject,
      )?.display_name;
      const bizNode = {
        id: this.startNodeObject,
        label: `${objDisPlayName}-${this.startNodeObject}`,
        children: [],
      };
      this.$request({
        url: this.$api.metaGetObjectFields,
        data: {
          object_id: objId,
        },
      }).then((data) => {
        bizNode.children = data.fields.map((fie) => ({
          id: fie.name,
          label: `${fie.display_name}-${fie.name}`,
        }));
        this.treeData.push(bizNode);
      });
    }
  },
  methods: {
    handleClose() {
      this.varText = '';
      this.showDialog = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    insertSystemVariable(e) {
      const selector = '.varilable_text .el-textarea__inner';
      const dom = document.querySelector(selector);
      this.varText = `${this.varText.substring(
        0,
        dom.selectionStart,
      )}{{${e}}}${this.varText.substring(dom.selectionEnd, dom.textLength)}`;
    },
    handleNodeClick(e, data) {
      const selector = '.varilable_text .el-textarea__inner';
      const dom = document.querySelector(selector);
      if (!e.children) {
        this.varText = `${this.varText.substring(0, dom.selectionStart)}{{${data.parent.data.id}.${
          e.id
        }}}${this.varText.substring(dom.selectionEnd, dom.textLength)}`;
      }
    },
    save() {
      this.$emit('insertion', this.varText);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
@import '@/styles/element/button.scss';

.container {
  display: flex;
  justify-content: space-between;
  .left-wrapper {
    display: flex;
    width: 320px;
    padding: 16px 16px 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid var(--00-color-system-gray-gray-5-text-disabledoutline, #c8d0dc);
    height: 500px;
    overflow: scroll;
    .var-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  .rigth-wrapper {
    display: flex;
    margin-left: 10px;
    flex: 1;
  }
}
</style>
