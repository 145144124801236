<template>
  <t-card>
    <div class="top">
      <t-form layout="inline" :data="searchInfo" ref="searchForm">
        <t-form-item label="" name="appid">
          <t-select
            :options="appOptions"
            label="相关应用"
            placeholder="请选择"
            v-model="searchInfo.appid"
            :keys="{ label: 'name', value: 'id' }"
            @change="getList"
          ></t-select>
        </t-form-item>
        <t-form-item label="" name="keyword">
          <t-input placeholder="请输入名称编码" v-model="searchInfo.keyword" @enter="getList">
            <template #suffixIcon>
              <search-icon :style="{ cursor: 'pointer' }" @click="getList" />
            </template>
          </t-input>
        </t-form-item>

        <t-form-item label="" name="">
          <t-button theme="primary" class="form-button-margin" @click="getList">搜索</t-button>
          <t-button theme="default" class="form-button-margin" @click="resetForm">重置</t-button>
        </t-form-item>
      </t-form>

      <div>
        <t-button theme="primary" @click="onCreate">新建权限包</t-button>
      </div>
    </div>

    <t-table
      :data="list"
      :columns="columns"
      class="table"
      row-key="index"
      :pagination="pagination"
      @page-change="onPageChange"
    >
      <template #operation="{ row }">
        <t-link
          theme="primary"
          hover="color"
          style="margin-right: 8px"
          @click="handleCopy(row)"
          v-if="jurisdiction.roleManage"
        >
          复制
        </t-link>
        <t-link
          theme="primary"
          hover="color"
          style="margin-right: 8px"
          @click="handleManage(row)"
          v-if="jurisdiction.roleManage"
        >
          分配管理
        </t-link>
        <t-link
          theme="primary"
          hover="color"
          style="margin-right: 8px"
          @click="goToPermission(row)"
          v-if="jurisdiction.roleManage"
        >
          权限
        </t-link>
        <t-link
          theme="primary"
          hover="color"
          style="margin-right: 8px"
          @click="onEdit(row)"
          v-if="jurisdiction.roleManage"
        >
          编辑
        </t-link>
        <t-link
          theme="primary"
          hover="color"
          @click="handleDelete(row)"
          v-if="jurisdiction.roleManage"
        >
          删除
        </t-link>
      </template>
    </t-table>

    <update-package
      :visible="updateIsvisible"
      :edit-row="editRow"
      :appid="searchInfo.appid"
      :is-copy="isCopy"
      @close="onClose"
      @refresh="onRefresh"
    ></update-package>
  </t-card>
</template>

<script>
import { DialogPlugin } from 'tdesign-vue';
import { SearchIcon } from 'tdesign-icons-vue';
import updatePackage from '../components/updatePackage.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    SearchIcon,
    updatePackage,
  },
  data() {
    return {
      searchInfo: {
        keyword: '',
        appid: null,
      },
      columns: [
        {
          colKey: 'name',
          title: '名称',
          minWidth: 240,
          fixed: 'left',
        },
        {
          colKey: 'code',
          title: 'Code',
          minWidth: 140,
        },
        {
          colKey: 'create_by',
          title: '创建人',
          minWidth: 80,
        },
        {
          colKey: 'update_by',
          title: '最后修改人',
          minWidth: 80,
        },
        {
          colKey: 'create_time',
          title: '创建时间',
          minWidth: 220,
        },
        {
          colKey: 'update_time',
          title: '修改时间',
          minWidth: 220,
        },
        {
          colKey: 'description',
          title: '描述',
          minWidth: 300,
        },
        {
          colKey: 'operation',
          title: '操作',
          minWidth: 350,
          fixed: 'right',
        },
      ],
      appOptions: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      updateIsvisible: false,
      editRow: {},
      isCopy: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
  },
  async mounted() {
    await this.getAppOptions();
    this.getList();
  },
  methods: {
    async getList() {
      console.log('------getList------');
      const payload = {
        ...this.searchInfo,
        appid: this.searchInfo.appid ? this.searchInfo.appid : 0,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        type: 'perm_set',
      };
      const url = this.$api.getRoleList;
      const { data, total } = await this.$request({ url, data: payload });
      this.list = data.map((item) => {
        return {
          ...item,
          create_time: String(item.create_time).replace(/\.\d+$/, ''),
          update_time: String(item.update_time).replace(/\.\d+$/, ''),
        };
      });
      this.pagination.total = total;
    },
    async getAppOptions() {
      const res = await this.$request({
        url: this.$api.getAppList,
        data: { page_size: 9999 },
      });
      this.appOptions = res.data;
      if (this.appOptions.length > 0) {
        this.searchInfo.appid = this.appOptions[0].id;
        console.log('-------this.searchInfo.appid------', this.searchInfo.appid);
      }
    },
    resetForm() {
      this.searchInfo.keyword = '';
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    onPageChange(pageInfo, newDataSource) {
      console.log('pageInfo', pageInfo);
      this.pagination = pageInfo;
      console.log('newDataSource', newDataSource);
      this.getList();
    },
    onCreate() {
      this.editRow = {
        name: '',
        code: '',
        description: '',
      };
      this.isCopy = false;
      this.updateIsvisible = true;
    },
    onEdit(row) {
      this.editRow = {
        ...row,
        code: row.code.replace('PermissionSet-', ''),
      };
      this.isCopy = false;
      this.updateIsvisible = true;
    },

    onClose() {
      this.updateIsvisible = false;
    },
    onRefresh() {
      this.updateIsvisible = false;
      this.getList();
    },
    goToPermission(row) {
      this.$router.push({
        name: 'permission_setting',
        query: {
          role_id: row.id,
          appid: row.appid,
          platform: this.searchInfo.appid || 2,
          type: 'perm_set',
        },
      });
    },
    async handleDelete(row) {
      const confirmDia = DialogPlugin.confirm({
        header: `确定要删除（${row.name}）吗`,
        body: `删除后，（${row.name}）将消失`,
        confirmBtn: '确定',
        theme: 'warning',
        cancelBtn: '取消',
        onConfirm: async () => {
          console.log(row);
          try {
            const url = this.$api.deleteRole;
            const data = { id: row.id, type: 'perm_set' };
            await this.$request({ url, data });
            this.$message.success('删除成功');
            this.getList();
            confirmDia.hide();
          } catch (e) {
            confirmDia.hide();
          }
        },
        onClose: () => {
          confirmDia.hide();
        },
      });
    },
    handleCopy(row) {
      this.isCopy = true;
      this.editRow = {
        ...row,
        code: `${row.code}_COPY`.replace('PermissionSet-', ''),
        name: `${row.name}_副本`,
        from_role_code: row.code,
        from_role_name: row.name,
      };
      this.updateIsvisible = true;
    },
    handleManage(row) {
      this.$router.push({
        name: 'perm-set-manage',
        query: {
          role_code: row.code,
          appid: row.appid,
          platform: this.searchInfo.appid || 2,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tdesign/form.scss';
@import '@/styles/tdesign/table.scss';
.top {
  display: flex;
  justify-content: space-between;
  .form-button-margin {
    margin-right: 16px;
  }
}
.table {
  margin-top: 16px;
}
</style>
