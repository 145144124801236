<template>
  <el-card class="table-container">
    <div class="top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchObj"
        class="searchForm"
        @submit.native.prevent
        @keyup.enter.native="searchList"
      >
        <el-form-item label="选择产品/应用：" prop="appid">
          <el-select v-model="searchObj.appid" placeholder="应用" @change="searchList">
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="keyword">
          <el-input
            v-model="searchObj.keyword"
            clearable
            placeholder="关键词搜索"
            @clear="searchList"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchList">查 询</el-button>
          <el-button @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button
          class="add-button"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd()"
          v-if="jurisdiction.apiManage"
        >
          添加
        </el-button>
      </div>
    </div>

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="名称" min-width="140"></el-table-column>
      <el-table-column prop="path" label="路径" min-width="140"></el-table-column>
      <el-table-column prop="appid" label="应用" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.appid | name(appOptions) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.description | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="160" v-if="jurisdiction.apiManage">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      background
      hide-on-single-page
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
        @keyup.enter.native="submitForm"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="path">
          <el-input v-model="formObj.path" :disabled="operation == 'update'"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
// import RoleBox from '@/components/RoleBox';

export default {
  //   components: {
  //     RoleBox,
  //   },
  data() {
    return {
      operation: 'create',
      listData: [],
      count: 0,
      formObj: {},
      appOptions: [],
      oldSearchObj: {},
      searchObj: {
        keyword: this.$route.query.keyword || '',
        appid: Number(this.$route.query.appid) || 0,
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入接口名称',
            trigger: 'blur',
          },
        ],
        appid: [
          {
            required: true,
            message: '请选择应用名称',
            trigger: 'blur',
          },
        ],
        path: [
          {
            required: true,
            message: '请输入接口路径',
            trigger: 'blur',
          },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        apiManage: this.getElementList.includes('api-manage'),
      };
    },
  },
  async mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    await this.loadAppOption();
    if (this.searchObj.appid) {
      this.loadListData();
    }
  },
  methods: {
    searchList() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
        this.searchObj.page = 1;
      }
      this.oldSearchObj = Object.assign({}, this.searchObj);
      this.timer = setTimeout(() => {
        this.$router.push({ query: this.searchObj });
        sessionStorage.setItem('app_id', this.searchObj.appid);
        this.loadListData();
      }, 300);
    },
    resetForm() {
      this.searchObj.keyword = '';
      this.searchList();
    },
    loadListData() {
      const url = this.$api.getApiList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
      };
      this.$request({ url, data, cb });
    },
    async loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const res = await this.$request({ url, data });
      this.appOptions = res.data;
      this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateApi : this.$api.createApi;
        const msg = id ? '修改成功' : '创建成功';
        let data = Object.assign({}, this.formObj);
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            path: this.formObj.path,
            description: this.formObj.description,
          };
        }
        this.submitDisable = false;
        const cb = () => {
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteApi;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {
        appid: this.searchObj.appid,
      };
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该接口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    handleCurrentChange(val) {
      this.searchObj.page = val;
      this.searchList();
    },
    handleSizeChange(val) {
      this.searchObj.page = 1;
      this.searchObj.page_size = val;
      this.searchList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.el-tag {
  margin: 0 2px 2px 0;
}
.top {
  display: flex;
  margin-bottom: 10px;
  .searchForm {
    flex: 1;
  }
}
</style>
