<template>
  <div>
    <t-table row-key="id" :data="objectPermissionData" :columns="columns" :height="tableHeight">
      <template #access_type="{ row }">
        {{ accessTypes[row.access_type] || row.access_type }}
      </template>

      <template #view_type="{ row }">
        {{ getViewType(row) }}
      </template>

      <template #可批量导入导出>
        可批量导入导出
        <t-tooltip
          class="placement top center"
          content="导入导出权限选中后，同时会选中角色对该表的全部列权限，但数据行权限需要根据实际需要自行调整"
          placement="top"
          :overlay-style="{ width: '100px' }"
          show-arrow
        >
          <t-icon name="help-circle-filled" />
        </t-tooltip>
      </template>

      <template #import_export="{ row, rowIndex }">
        <el-checkbox
          :value="!!row.import_export_action"
          @change="
            (checked) =>
              objectPermissionChange(row, 'object', 'import_export_action', checked, rowIndex)
          "
          :disabled="!jurisdiction.roleManage"
        ></el-checkbox>
      </template>

      <template #permission="{ row, rowIndex }">
        <div class="operation">
          <el-checkbox
            class="check-all"
            @change="(checked) => objectAllSelect(row, checked, rowIndex)"
            :value="getObkjectSelect(row)"
            :disabled="!jurisdiction.roleManage"
          >
            全部
          </el-checkbox>
          <el-checkbox
            v-for="item in objectActionOptions"
            :key="item.value"
            :label="item.value"
            :indeterminate="getTndeterminate(row, item.value)"
            :value="!!row[item.value]"
            @change="
              (checked) => objectPermissionChange(row, 'object', item.value, checked, rowIndex)
            "
            :disabled="!jurisdiction.roleManage"
          >
            {{ item.label }}
          </el-checkbox>
        </div>
      </template>

      <template #col="{ row, rowIndex }">
        <el-button type="text" @click="openDataStructPermission(row, rowIndex)">
          设置列权限
        </el-button>
      </template>

      <template #row="{ row }">
        <el-button
          type="text"
          v-if="[row.field_list, row.fields].some((item) => item)"
          @click="openDataPermission(row)"
        >
          <el-badge :is-dot="haveFilter(row.data_filter)">设置行权限&nbsp;</el-badge>
        </el-button>
      </template>
    </t-table>

    <linePermission
      :visible="opDialogVisible"
      :row="dataPermissionFormObj"
      :field-list="dataPermissionFields"
      @success="setLinePermission"
      @close="opDialogVisible = false"
    />

    <el-dialog
      title="数据列权限设置"
      :visible.sync="opObjectDialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <p>对象权限：</p>
      <el-checkbox
        :key="item.value"
        v-for="item in objectActionOptions"
        :label="item.value"
        :indeterminate="getTndeterminate(objectStrcutData, item.value)"
        :value="!!objectStrcutData[item.value]"
        @change="
          (checked) =>
            objectPermissionChange(
              objectStrcutData,
              'object',
              item.value,
              checked,
              objectStrcutIndex,
            )
        "
        :disabled="!jurisdiction.roleManage"
      >
        {{ item.label }}
      </el-checkbox>
      <p>对象字段权限：</p>
      <el-table
        :data="objectStrcutData.fields || objectStrcutData.field_list"
        stripe
        border
        fit
        style="width: 100%"
        row-key="permission_id"
      >
        >
        <el-table-column prop="name" label="名称" min-width="140"></el-table-column>
        <el-table-column prop="code" label="code" min-width="180"></el-table-column>
        <el-table-column prop="description" label="描述" min-width="140"></el-table-column>
        <el-table-column fixed="right" label="列权限" min-width="255">
          <template slot-scope="scope">
            <el-checkbox
              :key="item.value"
              v-for="item in objectActionOptions"
              v-show="!item.value.includes('delete')"
              :label="item.value"
              :value="!!scope.row[item.value]"
              @change="
                (checked) =>
                  objectPermissionChange(
                    objectStrcutData,
                    'filed',
                    item.value,
                    checked,
                    scope.$index,
                  )
              "
              :disabled="!jurisdiction.roleManage"
            >
              {{ item.label }}
            </el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opObjectDialogVisible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import linePermission from './row';
import { VIEW_DATA_TYPE, VIEW_JOB_TYPE, VIEW_ORG_TYPE, VIEW_USER_TYPE } from '@/utils/constant';

export default {
  components: {
    linePermission,
  },
  props: {
    searchObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permissionMap: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      keyword: '',
      objectPermissionData: [],
      columns: [
        {
          colKey: 'name',
          title: '名称',
          width: 140,
          fixed: 'left',
        },
        {
          colKey: 'code',
          title: 'Code',
          width: 140,
        },
        {
          colKey: 'description',
          title: '描述',
          width: 140,
        },
        {
          colKey: 'access_type',
          title: '访问控制',
          width: 140,
        },
        {
          colKey: 'view_type',
          title: '数据可见范围',
          width: 140,
        },
        {
          colKey: 'data_filter_desc',
          title: '自定义filter',
          width: 150,
        },
        {
          colKey: 'import_export',
          title: '可批量导入导出',
          width: 200,
        },
        {
          colKey: 'permission',
          title: '角色基本权限',
          width: 450,
        },
        {
          colKey: 'col',
          title: '列权限',
          width: 100,
        },
        {
          colKey: 'row',
          title: '行权限',
          width: 100,
        },
      ],
      accessTypes: {
        0: '私有',
        1: '私有',
        2: '公开读/私有写',
        3: '公开读写',
      },
      objectActionOptions: [
        { label: '查询', value: 'select_action' },
        { label: '新增', value: 'insert_action' },
        { label: '更新', value: 'update_action' },
        { label: '删除', value: 'delete_action' },
      ],
      tableHeight: 800,
      dataPermissionFormObj: {
        role_id: Number(this.$route.query.role_id) || 0,
        table_code: '',
        object_id: 0,
        view_data_type: '',
        view_job_type: '',
        view_org_type: '',
        view_user_type: 2,
        data_filter_desc: '',
        data_filter: [
          {
            action: ['select', 'create', 'update', 'delete'],
            connector: 'AND',
            conditions: [],
          },
        ],
      },
      opDialogVisible: false,
      dataPermissionFields: [],
      objectStrcutData: {},
      objectStrcutIndex: -1,
      opObjectDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
  },
  mounted() {
    const totalHeight = document.body.clientHeight;
    const formHeight = document.querySelector('.top').clientHeight || 0;
    const navBarHeight = document.querySelector('.navbar').clientHeight || 0;
    const tabHeaderHeight = document.querySelector('.t-tabs__header').clientHeight || 0;
    this.tableHeight = `${totalHeight - formHeight - navBarHeight - tabHeaderHeight - 60}px`;
  },
  methods: {
    async init() {
      this.getObjectPermission();
    },
    getViewType: (row) => {
      const data = [];
      if (row.view_data_type) {
        data.push(VIEW_DATA_TYPE.find((i) => i.value === row.view_data_type).label);
      }
      if (row.view_job_type) {
        data.push(VIEW_JOB_TYPE.find((i) => i.value === row.view_job_type).label);
      }
      if (row.view_org_type) {
        data.push(VIEW_ORG_TYPE.find((i) => i.value === row.view_org_type).label);
      }
      if (row.view_user_type === 2) {
        data.push(VIEW_USER_TYPE.find((i) => i.value === row.view_user_type).label);
      }
      return data.join('，');
    },
    getTndeterminate(data, action) {
      if (action === 'delete_action') {
        return false;
      }
      return data[action] === 1;
    },
    getObkjectSelect(obj) {
      return this.objectActionOptions.every((action) => {
        if (action.value === 'delete_action') {
          return !!obj[action.value];
        }
        return obj[action.value] === 2;
      });
    },
    haveFilter(dataFilter) {
      if (dataFilter) {
        if (dataFilter?.length > 0) {
          for (let index = 0; index < dataFilter.length; index++) {
            const item = dataFilter[index];
            if (item.conditions?.length > 0) {
              return true;
            }
          }
          return false;
        }
        // 兼容老数据（对象形式）
        return dataFilter.conditions?.length > 0;
      }
      return false;
    },
    async getObjectPermission() {
      try {
        const { data } = await this.$request({
          url: this.$api.getRoleObjectPermission,
          data: { role_code: this.roleData.code, appid: this.searchObj.appid },
        });
        this.initPermission(data);
        this.objectPermissionData = data;
      } catch (err) {
        console.log(err);
      }
    },
    initPermission(list) {
      for (const item of list) {
        if (
          !this.permissionMap[item.permission_id] ||
          this.permissionMap[item.permission_id]?.length == 0
        ) {
          this.$set(this.permissionMap, item.permission_id, []);
        }

        if (item.children && item.children.length > 0) {
          this.initPermission(item.children);
        }
        if (item.fields && item.fields?.length > 0) {
          this.initPermission(item.fields);
        }
      }
    },

    /*
      index: dataType 为 object: 对象索引  filed: 字段索引
      */
    async objectPermissionChange(obj, dataType, action, value, index) {
      console.log('---index---', index);
      let objectIndex = this.objectStrcutIndex;
      const objData = JSON.parse(JSON.stringify(obj));
      let is_all = false;
      let field_code_list = [];
      // 对象权限变更
      if (dataType === 'object') {
        objectIndex = index;
        is_all = value;
        if (value) {
          field_code_list = undefined;
        }
        // 更新视图
        objData[action] = value ? 2 : 0;
        objData.field_list.forEach((field) => {
          field[action] = value ? 1 : 0;
        });
      }
      if (action === 'import_export_action' && value) {
        this.objectActionOptions.forEach((action) => {
          if (objData[action.value] === 0) {
            this.objectPermissionChange(obj, 'object', action.value, true, index);
          }
        });
      }
      // 字段权限变更时候，自动检查对象权限
      if (dataType === 'filed') {
        objData.field_list[index][action] = value ? 1 : 0;

        field_code_list = objData.field_list
          .filter((item) => {
            return item[action] === 1;
          })
          .map((item) => item.code);

        // --改变对象状态--
        // 1.全选字段
        if (field_code_list?.length === objData.field_list?.length) {
          objData[action] = value;
          is_all = value;
          if (value) {
            field_code_list = undefined;
          }
        } else {
          objData[action] = 1;
        }
        // 1.全不选字段
        if (field_code_list && field_code_list?.length === 0) {
          objData[action] = value;
          is_all = false;
        }
      }

      try {
        await this.$request({
          url: this.$api.setRoleObjectColumnPermission,
          data: {
            appid: this.searchObj.appid,
            object_code: objData.code,
            role_code: this.roleData.code,
            action: action.split('_action')[0],
            is_all,
            field_code_list,
          },
        });
        // 更新视图
        // 1.弹窗
        this.objectStrcutData = objData;
        // 2.表格 1.响应式不生效 2.防止全选时互相覆盖数据
        console.log('---objectIndex---', objectIndex);
        const newObj = this.objectPermissionData[objectIndex];
        newObj[action] = objData[action];
        newObj.field_list = newObj.field_list.map((field, index) => {
          const newField = JSON.parse(JSON.stringify(field));
          newField[action] = objData.field_list[index][action];
          return newField;
        });
        this.$set(this.objectPermissionData, objectIndex, newObj);
      } catch (err) {
        console.log(err);
      }
    },
    objectAllSelect(object, val, index) {
      // 修改数据(接口)
      this.objectActionOptions.forEach((item) => {
        this.objectPermissionChange(object, 'object', item.value, val, index);
      });
    },
    async openDataPermission(data) {
      const { field_list } = await this.$request({
        url: this.$api.getRoleObjectColumnPermission,
        data: {
          appid: this.searchObj.appid,
          object_code: data.code,
          role_code: this.roleData.code,
        },
      });
      this.dataPermissionFields = field_list;
      this.dataPermissionFormObj.table_code = data.code;
      this.dataPermissionFormObj.object_id = data.id;
      this.dataPermissionFormObj.view_data_type = '';
      this.dataPermissionFormObj.view_job_type = '';
      this.dataPermissionFormObj.view_org_type = '';
      this.dataPermissionFormObj.view_org_type = 2;
      this.dataPermissionFormObj.data_filter = [
        {
          action: ['select', 'create', 'update', 'delete'],
          connector: 'AND',
          conditions: [],
        },
      ];
      this.dataPermissionFormObj.data_filter_desc = '';
      this.loadObjectDataView();
    },
    async loadObjectDataView() {
      try {
        const data = await this.$request({
          url: this.$api.getRoleObjectView,
          data: {
            role_id: this.dataPermissionFormObj.role_id,
            object_id: this.dataPermissionFormObj.object_id,
          },
        });

        // 可见范围默认为私有
        if (data.view_type === 0) {
          data.view_type = 1;
        }
        this.dataPermissionFormObj.view_data_type = data.view_data_type ? data.view_data_type : '';
        this.dataPermissionFormObj.view_job_type = data.view_job_type ? data.view_job_type : '';
        this.dataPermissionFormObj.view_org_type = data.view_org_type ? data.view_org_type : '';
        this.dataPermissionFormObj.view_user_type = data.view_user_type ? data.view_user_type : 2;
        this.dataPermissionFormObj.data_filter_desc = data.data_filter_desc;
        const { data_filter: filter = [] } = data;
        if (filter.length === 0) {
          this.dataPermissionFormObj.data_filter = [
            {
              action: ['select', 'create', 'update', 'delete'],
              connector: 'AND',
              conditions: [],
            },
          ];
        } else {
          this.dataPermissionFormObj.data_filter = filter;
        }
        this.opDialogVisible = true;
      } catch (err) {
        console.log(err);
      }
    },
    setLinePermission() {
      this.opDialogVisible = false;
      this.getObjectPermission();
      this.$emit('refresh');
    },
    async openDataStructPermission(data, index) {
      const { field_list } = await this.$request({
        url: this.$api.getRoleObjectColumnPermission,
        data: {
          appid: this.searchObj.appid,
          object_code: data.code,
          role_code: this.roleData.code,
        },
      });
      console.log('---data---', data);
      this.opObjectDialogVisible = true;
      this.objectStrcutData = data;
      this.objectStrcutData.field_list = field_list;
      const { code } = this.objectStrcutData;
      console.log('--code--', code);
      this.objectStrcutIndex = index;
      console.log('data :>> ', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tdesign/table.scss';
</style>
