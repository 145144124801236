<template>
  <el-option class="option" :label="label" :value="value" :disabled="disabled">
    <el-checkbox class="option-checkbox" :value="isSelected">
      {{ label }}
    </el-checkbox>
  </el-option>
</template>

<script>
export default {
  name: 'CheckboxOption',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 只有多选会使用带checkbox的option，所以已选数据一定是列表
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    isSelected() {
      return this.selected.includes(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/checkbox.scss';
.option {
  .option-checkbox {
    // checkbox不处理任何事件 事件以option的处理为准
    pointer-events: none;
  }
  &.hover,
  &.selected,
  &.hover.selected {
    ::v-deep.el-checkbox__label {
      color: #0077ff;
    }
  }
  &.is-disabled {
    ::v-deep.el-checkbox__label {
      color: #c0c4cc;
    }
  }
}
</style>
