<template>
  <el-drawer
    :title="title"
    :visible="visible"
    :close-on-click-modal="false"
    width="500px"
    :before-close="close"
  >
    <div class="container">
      <el-form class="form" ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Code" prop="code">
          <el-input v-model="form.code" :disabled="Boolean(rowId)"></el-input>
        </el-form-item>
        <el-form-item label="直属上级" prop="parent_code">
          <el-select
            class="select"
            v-model="form.parentCode"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in parentList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              :disabled="form.code === item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分配用户">
          <t-select
            v-model="form.user"
            multiple
            filterable
            :options="userList"
            value-type="object"
            @search="searchUser"
          ></t-select>
        </el-form-item>
        <el-form-item label="备注描述" prop="description">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
      </el-form>
      <div class="drawer-footer">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button class="drawer-footer-btn" type="info" @click="close">取消</el-button>
      </div>
    </div>
    <MessageBox ref="messageBox" />
  </el-drawer>
</template>

<script>
import MessageBox from '@/components/messageBox';

const initForm = {
  name: '',
  code: '',
  parentCode: '',
  user: [],
  description: '',
};
export default {
  name: 'EmployeeorgEdit',
  components: { MessageBox },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: [Number, String],
      default: '',
    },
    appid: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      form: initForm,
      list: [],
      userList: [],
      parentList: [],
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入code',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.rowId ? '编辑员工组织' : '新增员工组织';
    },
  },
  watch: {
    visible(n) {
      if (n) {
        this.getOrgList();
        this.searchUser();
        if (this.rowId) {
          this.$request({
            url: this.$api.getOrganizationInfo,
            data: { id: this.rowId },
          }).then((res) => {
            this.form = {
              name: res.name,
              code: res.code,
              parentCode: res.parent_code,
              user: res.user_list.map((i) => {
                return {
                  label: i.name,
                  value: i.account,
                };
              }),
              description: res.description,
            };
            this.$nextTick(() => {
              this.$refs.form.clearValidate();
            });
          });
        } else {
          this.form = { ...initForm };
          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });
        }
      }
    },
  },
  methods: {
    close() {
      this.$refs.messageBox.show({
        title: '放弃编辑后，本次编辑内容将会丢失，确认放弃？',
        titleIconType: 'warning',
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$refs.messageBox.close();
          this.$emit('close');
        },
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        console.log('meowf', this.form);
        let request;
        if (this.rowId) {
          request = this.$request({
            url: this.$api.updateOrganizationInfo,
            data: {
              name: this.form.name,
              parent_code: this.form.parentCode,
              description: this.form.description,
              user_accounts: this.form.user.map((i) => i.value),
              id: this.rowId,
            },
          });
        } else {
          request = this.$request({
            url: this.$api.addOrganizationInfo,
            data: {
              name: this.form.name,
              code: this.form.code,
              parent_code: this.form.parentCode,
              description: this.form.description,
              user_accounts: this.form.user.map((i) => i.value),
              appid: this.appid,
            },
          });
        }
        request.then(() => {
          this.$message.success(this.rowId ? '编辑成功' : '新增成功');
          this.$emit('success');
        });
      });
    },
    searchUser(keyword = '') {
      const url = this.$api.getUserList;
      const data = {
        keyword,
        page: 1,
        type: 1,
        page_size: 10,
      };

      this.$request({ url, data }).then((data) => {
        this.userList = data.data.map((i) => {
          return {
            value: i.account,
            label: i.name,
          };
        });
      });
    },
    getOrgList() {
      const url = this.$api.getOrganizationList;
      const data = {
        appid: this.appid,
        page: 1,
        page_size: 999999,
      };

      this.$request({ url, data }).then((data) => {
        this.parentList = data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/drawer.scss';
@import '@/styles/element/form.scss';

.container {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    padding: 16px;
    .select {
      width: 100%;
    }
  }
  .drawer-footer {
    display: flex;
    border-top: 1px solid #e5eaf2;
    padding: 12px;
    .drawer-footer-btn {
      margin: 0 8px;
    }
  }
}
</style>
