export default {
  env: 'production',
  domain: ['dataparis.woa.com', 'admin.nges.tencnet.com'],
  mobileDomain: 'pfizerh5.tsepcn.com',
  aegisId: 'akNqIsanjBcOOiYfZS',
  reportDomain: 'd.baike.qq.com',
  cosAppId: 'nges',
  bucket: 'tsep-prod-1304685099',
  region: 'ap-guangzhou',
  sign: '',
};
