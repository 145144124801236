<template>
  <el-card class="box-card" v-loading="loading">
    <!-- 数据 -->
    <h3>配置信息</h3>
    <el-tree accordion :data="treeData.config">
      <span class="custom-tree-node" slot-scope="{ data }">
        <span>{{ [undefined].includes(data.tier) ? data.label : '' }}</span>
        <!-- 表格 -->
        <div v-if="['config'].includes(data.tier)">
          <show type="table" :data="data" scene="detail" />
        </div>
      </span>
    </el-tree>
    <hr />
    <!-- 权限 -->
    <h3>权限信息</h3>
    <el-tree accordion :data="treeData.perm">
      <span class="custom-tree-node" slot-scope="{ data }">
        <span>{{ [undefined].includes(data.tier) ? data.label : '' }}</span>
        <div v-if="data.tier">
          <show :data="data" :type="data.tier" scene="detail" />
        </div>
      </span>
    </el-tree>
  </el-card>
</template>

<script>
import { getData } from './data';
import show from '@/components/diff/show.vue';
export default {
  components: { show },
  data() {
    return {
      loading: false,
      treeData: {
        config: [],
        perm: [],
      },
    };
  },
  created() {
    const { env, tag, appid } = this.$route.query;
    if ([env, tag, appid].includes(undefined)) {
      this.$router.replace({
        name: 'version-manage',
      });
      return;
    }
    this.getDetail(env, tag, appid);
  },
  methods: {
    async getDetail(env, tag) {
      this.loading = true;
      try {
        const res = await this.$request({
          url: this.$api.getAuthByTag,
          data: { env, tag },
        });
        console.log('接口数据:', res);
        this.treeData = getData(res);
        console.log('detail:', this.treeData);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree-node {
  .el-tree-node {
    .el-tree-node__content {
      height: auto !important;
    }
    .custom-tree-node {
      width: 97%;
    }
  }
}
</style>
