<template>
  <el-card class="table-container">
    <div class="top">
      <div class="search-form">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchObj"
          class="searchForm"
          @submit.native.prevent
          @keyup.enter.native="loadListData"
        >
          <el-form-item label="选择产品/应用：" prop="appid">
            <el-select v-model="searchObj.appid" placeholder="应用" @change="loadListData">
              <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input
              v-model="searchObj.keyword"
              clearable
              placeholder="请输入名称/编码"
              @clear="loadListData"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button icon="el-icon-search" type="primary" @click="loadListData">搜索</el-button>
            <el-button @click="resetForm">重 置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="float-right">
        <el-button
          class="add-button"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd()"
          v-if="jurisdiction.roleManage"
        >
          添加
        </el-button>
      </div>
    </div>

    <t-enhanced-table
      v-if="tableIsShow"
      ref="tTable"
      row-key="id"
      :columns="columns"
      :data="filterListData"
      :tree="{ childrenKey: 'children', treeNodeColumnIndex: 0 }"
      :max-height="tableHeight"
      :scroll="{ type: 'virtual', bufferSize: 30 }"
      table-layout="auto"
    >
      <template #treeExpandAndFoldIcon="{ type }">
        <div v-if="type === 'expand'">
          <i class="el-icon-arrow-right" />
        </div>
        <div v-if="type === 'fold'">
          <i class="el-icon-arrow-down" />
        </div>
      </template>
      <template #name="{ row }">
        <div :class="{ 'text-highlight': row.name.includes(highlightKeyword) && highlightKeyword }">
          {{ row.name }}
        </div>
      </template>
      <template #code="{ row }">
        <div :class="{ 'text-highlight': row.code.includes(highlightKeyword) && highlightKeyword }">
          {{ row.code }}
        </div>
      </template>
      <template #operation="{ row, rowIndex }">
        <el-button size="mini" type="text" @click="handleCopy(row)" v-if="jurisdiction.roleManage">
          复制
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleSubAdd(rowIndex, row)"
          v-if="jurisdiction.roleManage"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleEdit(rowIndex, row)"
          v-if="jurisdiction.roleManage"
        >
          编辑
        </el-button>
        <el-button size="mini" type="text" @click="goToPermission(rowIndex, row)">权限</el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleDelete(rowIndex, row)"
          v-if="jurisdiction.roleManage"
        >
          删除
        </el-button>
      </template>
    </t-enhanced-table>

    <el-dialog
      :title="title"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      class="dialog"
      width="520px"
    >
      <el-alert
        title="仅复制角色自身权限，不包含父级角色权限，如果选择复制至已存在的角色时，会对已存角色权限进行全量覆盖"
        type="warning"
        v-if="operation === 'copy'"
        show-icon
        class="alert"
        :closable="false"
      />
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="复制方式" v-if="operation === 'copy'">
          <el-radio-group v-model="formObj.copy_type" @change="handleCopyTypeChange">
            <el-radio :label="1">覆盖</el-radio>
            <el-radio :label="0">创建</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="ID" prop="id" v-if="operation === 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <template v-if="operation === 'copy' && formObj.copy_type === 1">
          <el-form-item label="角色名" prop="from_role_name">
            <el-input v-model="formObj.from_role_name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="Code" prop="from_role_code" required>
            <el-input v-model="formObj.from_role_code" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="目标角色" prop="code" required>
            <template>
              <el-cascader
                v-model="formObj.code"
                placeholder="请选择"
                :options="getRoleTreeOptions(formObj.from_role_code, false)"
                :props="{ checkStrictly: true, value: 'code', label: 'name', emitPath: false }"
                filterable
                class="cascade"
                ref="cascader"
                @change="handleCascaderChange()"
              />
            </template>
          </el-form-item>
          <el-form-item label="目标角色Code" prop="code" required>
            <el-input v-model="formObj.code" :disabled="true"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="角色名" prop="name">
            <el-input v-model="formObj.name"></el-input>
          </el-form-item>
          <el-form-item label="Code" prop="code">
            <el-input v-model="formObj.code" :disabled="operation === 'update'"></el-input>
          </el-form-item>
          <el-form-item label="父级角色" prop="parent_code">
            <template>
              <el-cascader
                v-model="formObj.parent_code"
                placeholder="请选择"
                :options="getRoleTreeOptions()"
                :props="{ checkStrictly: true, value: 'code', label: 'name', emitPath: false }"
                filterable
                :disabled="operation === 'update'"
                class="cascade"
                ref="cascader"
                @change="handleCascaderChange"
              />
              <!--              <el-select-->
              <!--                v-model="formObj.parent_code"-->
              <!--                placeholder="请选择"-->
              <!--                :disabled="operation === 'update' || operation === 'copy'"-->
              <!--              >-->
              <!--                <el-option-->
              <!--                  v-for="item in roleOptions"-->
              <!--                  :key="item.id"-->
              <!--                  :label="item.name"-->
              <!--                  :value="item.code"-->
              <!--                ></el-option>-->
              <!--              </el-select>-->
            </template>
          </el-form-item>
        </template>
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep, isArray } from 'lodash';
export default {
  components: {},
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{3,100}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为3到100'));
      }
      callback();
    };

    return {
      operation: 'create',
      listData: [],
      filterListData: [],
      roleOptions: [],
      count: 0,
      appOptions: [],
      formObj: {},
      oldSearchObj: {},
      searchObj: {
        appid: Number(this.$route.query.appid) || 0,
        keyword: this.$route.query.keyword || '',
      },
      highlightKeyword: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入角色Code',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
      tableHeight: '',
      tableIsShow: true,
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
    columns() {
      const hasPermission = this.jurisdiction.roleManage;
      return [
        {
          colKey: 'name',
          title: '名称',
          minWidth: 240,
          fixed: 'left',
        },
        {
          colKey: 'id',
          title: 'ID',
          minWidth: 140,
        },
        {
          colKey: 'code',
          title: 'Code',
          minWidth: 140,
        },
        {
          colKey: 'description',
          title: '描述',
          minWidth: 300,
        },
        {
          colKey: 'create_by',
          title: '创建人',
          minWidth: 120,
        },
        {
          colKey: 'update_by',
          title: '最后修改人',
          minWidth: 120,
        },
        {
          colKey: 'update_time',
          title: '修改时间',
          minWidth: 220,
        },
        {
          colKey: 'create_time',
          title: '创建时间',
          minWidth: 220,
        },
        {
          colKey: 'operation',
          title: '操作',
          minWidth: hasPermission ? 220 : 100,
          fixed: 'right',
        },
      ];
    },
    title() {
      let title = '';
      switch (this.operation) {
        case 'create':
          title = '创建';
          break;
        case 'copy':
          title = '复制';
      }
      return title;
    },
  },
  async mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    await this.loadAppOption();
    await this.loadListData(true);
    const totalHeight = document.body.clientHeight;
    const formHeight = document.querySelector('.top').clientHeight;
    const navBarHeight = document.querySelector('.navbar').clientHeight;
    this.tableHeight = `${totalHeight - formHeight - navBarHeight - 60}px`;
    console.log('---tableHeight---', this.tableHeight);
  },
  methods: {
    async loadListData(refresh = false) {
      console.log('---loadListData---');
      this.highlightKeyword = this.searchObj.keyword;
      if (this.searchObj.appid !== this.oldSearchObj.appid || refresh) {
        await this.loadListDataFromRemote();
      }
      if (this.searchObj.keyword !== this.oldSearchObj.keyword) {
        this.filterListData = this.filterList(this.listData, this.searchObj);
        this.oldSearchObj = {
          ...this.searchObj,
        };
      }
      // if (refresh) {
      //   await this.loadListDataFromRemote();
      // }
      this.$nextTick(() => {
        this.$refs.tTable.expandAll();
      });
    },
    async loadListDataFromRemote() {
      const url = this.$api.getRoleTree;
      const data = {
        appid: this.searchObj.appid,
      };
      const resp = await this.$request({ url, data });
      this.oldSearchObj = {
        ...this.searchObj,
      };
      this.count = resp.total;
      this.listData = resp.data;
      this.filterListData = this.filterList(this.listData, this.searchObj);
      // 表格存在响应式刷新问题，数据不更新，重新渲染一下
      this.tableIsShow = false;
      await this.$nextTick();
      this.tableIsShow = true;
      this.loadRoleOption();
    },
    resetForm() {
      this.searchObj = {
        appid: this.appOptions[0]?.id || 0,
        keyword: this.$route.query.keyword || '',
      };
      this.loadListData();
    },

    filterList(tree, { keyword = '' }) {
      return tree.filter((item) => {
        const condition = [item.name, item.code].find((val) => {
          return val.includes(keyword);
        });
        // 显示状态
        if (condition) {
          return true;
        }
        if (item.children && item.children.length > 0) {
          return this.filterList(item.children, { keyword }).length > 0;
        }
        return false;
      });
    },
    loadRoleOption() {
      const url = this.$api.getRoleList;
      const data = Object.assign({}, this.searchObj);
      data.page_size = 99999;
      const cb = (data) => {
        this.roleOptions = data.data;
        this.roleOptions.push({
          id: 0,
          code: 'root',
          name: 'Root',
        });
      };
      this.$request({ url, data, cb });
    },
    getRoleTreeOptions(disabledCode, needRoot = true) {
      const format = (list = []) => {
        if (list.length) {
          list.forEach((item) => {
            if (disabledCode && item.code === disabledCode) {
              item.disabled = true;
            }
            if (item.children && item.children.length) {
              item.children = format(item.children);
            } else {
              item.children = undefined;
            }
          });
        }
        return list;
      };
      const newList = cloneDeep(this.listData || []);
      if (needRoot) {
        newList.unshift({ code: 'root', name: 'Root' });
      }
      return format(newList);
    },
    async loadAppOption() {
      const url = this.$api.getAppList;
      const cb = (data) => {
        this.appOptions = data.data;
        this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
      };
      const data = await this.$request({ url, data: { page_size: 999999 }, cb });
      this.appOptions = data.data;
      this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        // const url = id ? this.$api.updateRole : this.$api.createRole;
        let url = '';
        if (this.operation === 'create') {
          url = this.$api.createRole;
        } else if (this.operation === 'update') {
          url = this.$api.updateRole;
        } else if (this.operation === 'copy') {
          url = this.$api.copyRole;
        }
        let msg = '';
        if (this.operation === 'create') {
          msg = '新建成功';
        } else if (this.operation === 'update') {
          msg = '修改成功';
        } else if (this.operation === 'copy') {
          msg = '复制成功';
        }

        let payload = {};
        if (this.operation === 'update') {
          payload = {
            id,
            description: this.formObj.description,
            name: this.formObj.name,
          };
        } else if (this.operation === 'create') {
          payload = {
            parent_code: this.formObj.parent_code,
            name: this.formObj.name,
            code: this.formObj.code,
            description: this.formObj.description,
            appid: this.searchObj.appid,
          };
        } else if (this.operation === 'copy') {
          payload = {
            copy_type: this.formObj.copy_type,
            description: this.formObj.description,
            appid: this.searchObj.appid,
            code: this.formObj.code,
            from_role_code: this.formObj.from_role_code,
          };
          if (this.formObj.copy_type === 0) {
            payload = {
              ...payload,
              parent_code: this.formObj.parent_code,
              name: this.formObj.name,
            };
          }
        }
        try {
          const data = await this.$request({ url, data: payload });
          console.log('------data------', data);
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          if (this.operation !== 'copy') {
            this.loadListData(true);
          } else {
            this.$router.push({
              name: 'permission_setting',
              query: {
                role_id: data.role_id,
                appid: this.searchObj.appid,
                platform: Number(sessionStorage.getItem('platform') || 2),
              },
            });
          }
        } catch (err) {
          console.log(err);
          this.submitDisable = false;
        }
      });
    },
    async deleteOperation(id) {
      const url = this.$api.deleteRole;
      await this.$request({ url, data: { id } });
      this.$message({
        message: '删除成功',
        type: 'success',
      });
      await this.loadListData(true);
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { openType: 0, hidden: 0, parent_code: 'root' };
      this.opDialogVisible = true;
    },
    handleSubAdd(index, data) {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { openType: 0, hidden: 0, parent_code: data.code };
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    async handleDelete(index, data) {
      try {
        await this.$confirm('是否删除该角色?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.deleteOperation(data.id);
      } catch (e) {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      }
    },
    handleCopy(row) {
      this.operation = 'copy';
      this.formObj.__rowData = row;
      this.formObj.copy_type = 0;
      this.handleCopyTypeChange();
      this.opDialogVisible = true;
    },
    handleCopyTypeChange() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      const row = this.formObj.__rowData;
      if (this.formObj.copy_type === 1) {
        this.formObj = {
          ...this.formObj,
          copy_type: 1,
          parent_code: '',
          from_role_code: row.code,
          from_role_name: row.name,
          code: '',
          name: '',
        };
      } else {
        this.formObj = {
          ...this.formObj,
          copy_type: 0,
          parent_code: row.parent_code,
          from_role_code: row.code,
          from_role_name: row.name,
          code: `${row.code}_COPY`,
          name: `${row.name}_副本`,
        };
      }
    },
    goToPermission(index, data) {
      this.$router.push({
        name: 'permission_setting',
        query: {
          role_id: data.id,
          appid: data.appid,
          platform: Number(sessionStorage.getItem('platform') || 2),
        },
      });
    },
    handleCascaderChange() {
      const ref = this.$refs.cascader;
      let refs = ref;
      if (!isArray(ref)) {
        refs = [ref];
      }
      refs.forEach((item) => {
        item.dropDownVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';

.top {
  display: flex;
  .search-form {
    flex: 1;
    display: flex;
    .form-item {
      width: 232px;
      margin-right: 8px;
    }
  }
}
.text-highlight {
  color: #0077ff;
}

::v-deep .t-table__header tr {
  th {
    background-color: rgba(246, 247, 251, 1);
    color: #667082;
    font-weight: 400;
  }
  border-bottom: 1px solid #ebeef5;
}
::v-deep .t-table__body tr {
  background-color: #fff;
  border-bottom: 1px solid #ebeef5;
}
.dialog {
  ::v-deep .el-dialog__body {
    padding: 28px 32px;
  }
}
.alert {
  margin-top: -24px;
  margin-bottom: 16px;
}
.cascade {
  width: 100%;
}
</style>
