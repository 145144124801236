// git 仓库相关的 api

// 腾讯工蜂仓库（外网版）https://code.tencent.com/help/api/prepare

import { request } from './axios';
import { Base64 } from 'js-base64';

const str2base64 = (str) => {
  return Base64.encode(str);
};

/**
 * gitAPI 由后端转发
 * @param {string} projectId 项目id
 * @param {string} path 请求路径，例如/repository/archive
 * @param {string} method 方法，默认Get，支持Get/Post/Delete/Put
 * @param {string} query Query参数，String 类型, Stringify 的 json，拼接在Url上
 * @param {string} body Body参数, String 类型，Stringify 的 json
 * @param {string} contentType 请求Content-Type，默认application/json
 * @returns {Promise} 返回请求结果
 * @author kianzzhang
 */
export const GitApiRequest = ({
  projectId = '250285',
  path,
  method = 'GET',
  query = '',
  body = '',
  contentType = 'application/json',
}) => {
  return request({
    url: '/api/access/pb/cmd/ApiServer/GitApi/GitRequest',
    // 接口文档  https://git.woa.com/eyao/CRM/EyaoPlatform/blob/feature/23r1sp2/git_api/proto/ApiServer/GitApi.proto
    method: 'post',
    data: {
      project_id: projectId,
      path,
      method,
      query,
      body,
      content_type: contentType,
    },
  });
};

/**
 * 获取文件
 * @param {*} param0
 * @returns {Promise}
 */
export const getFile = ({ ref, filePath }) => {
  return GitApiRequest({
    path: `/repository/files`,
    method: 'GET',
    query: JSON.stringify({ ref, file_path: filePath }),
  });
};

/**
 * 新增文件
 * @param {*} param0
 * @returns {Promise}
 */
export const addFile = ({ branchName, filePath, content, staffName, moduleName }) => {
  return GitApiRequest({
    path: `/repository/files`,
    method: 'POST',
    body: JSON.stringify({
      file_path: filePath,
      branch_name: branchName,
      encoding: 'base64',
      content: str2base64(content),
      commit_message: `由 ${staffName || '未知员工'} 在重楼 web 端 ${
        moduleName || '未知'
      } 模块提交`,
    }),
  });
};

/**
 * 编辑文件（整体替换）
 * @param {*} param0
 * @returns {Promise}
 */
export const editFile = ({ branchName, filePath, content, staffName, moduleName }) => {
  return GitApiRequest({
    path: `/repository/files`,
    method: 'PUT',
    body: JSON.stringify({
      file_path: filePath,
      branch_name: branchName,
      encoding: 'base64',
      content: str2base64(content),
      commit_message: `由 ${staffName || '未知员工'} 在重楼 web 端 ${
        moduleName || '未知'
      } 模块提交`,
    }),
  });
};

/**
 * 删除文件
 * @param {*} param0
 * @returns {Promise}
 */
export const deleteFile = ({ branchName, filePath, staffName, moduleName }) => {
  return GitApiRequest({
    path: `/repository/files`,
    method: 'DELETE',
    body: JSON.stringify({
      file_path: filePath,
      branch_name: branchName,
      commit_message: `由 ${staffName || '未知员工'} 在重楼 web 端 ${
        moduleName || '未知'
      } 模块提交`,
    }),
  });
};
