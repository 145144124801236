<template>
  <div class="container">
    <el-tabs class="top-tab" v-model="activeStatus" type="card">
      <el-tab-pane label="已启用" name="1"></el-tab-pane>
      <el-tab-pane label="已停用" name="2"></el-tab-pane>
    </el-tabs>
    <!-- 功能 -->
    <div class="table-container">
      <div class="table-action-row">
        <div class="table-action-form">
          <el-input
            class="form-item"
            v-model="flowForm.name"
            clearable
            placeholder="请输入名称或流程Key进行检索"
            @input="handleInput"
          >
            <i slot="suffix" class="el-input__icon iconfont icon-search"></i>
          </el-input>
          <el-select
            class="form-item"
            v-model="flowForm.app_id"
            placeholder="请选择产品应用"
            @change="refresh"
            clearable
          >
            <el-option
              v-for="obj in appList"
              :key="obj.label"
              :label="obj.label"
              :value="obj.value"
            ></el-option>
          </el-select>
          <el-button @click="handleReset">重 置</el-button>
        </div>
        <div class="table-action-button">
          <el-button type="primary" @click="add()">新建模型</el-button>
          <el-button type="info" @click="deployVisible = true">模型导入</el-button>
        </div>
      </div>
      <List ref="list" :request="getList" :layout="layout">
        <template v-slot:table-action>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button class="text-button" type="text" @click="edit(scope.row)">编辑</el-button>
              <el-dropdown trigger="click" placement="bottom">
                <el-button type="text">
                  <i class="iconfont icon-more" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="activeStatus === '1'" @click.native="submit(scope.row)">
                    部署发布
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="exportJSON(scope.row)">导出</el-dropdown-item>
                  <el-dropdown-item
                    v-if="activeStatus === '1'"
                    @click.native="changeStatus(scope.row, '2')"
                  >
                    停用
                  </el-dropdown-item>
                  <el-dropdown-item v-else @click.native="changeStatus(scope.row, '1')">
                    启用
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="danger-action"
                    @click.native="del(scope.row, scope.$index)"
                  >
                    删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </template>
      </List>
    </div>

    <AddModel :visible="basicInfoVisable" :app-list="appList" @close="basicInfoVisable = false" />
    <!-- 部署弹窗 -->
    <ImportJson
      :show="deployVisible"
      @cancel="
        (val) => {
          deployVisible = val;
        }
      "
      @refresh="refresh"
    />
    <messageBox ref="messageBox" />
  </div>
</template>

<script>
import List from '@/components/table';
import messageBox from '@/components/messageBox';
import ImportJson from './component/ImportJson.vue';
import AddModel from './component/addModel';
import { mapGetters, mapActions } from 'vuex';
import { openDownloadDialog } from '@/utils/util';
import _ from 'lodash';

export default {
  components: {
    List,
    ImportJson,
    AddModel,
    messageBox,
  },
  data() {
    return {
      // 加载状态
      loading: false,
      // tab状态
      activeStatus: '1',
      tabList: [
        { name: '1', label: '已启用' },
        { name: '2', label: '已停用' },
      ],
      // 搜索表单
      flowForm: {
        name: '',
        app_id: '',
      },
      layout: [
        { name: 'edit_process_name', label: '名称' },
        { name: 'process_key', label: '流程key' },
        { name: 'module', label: '业务模版' },
        { name: 'version', label: '版本' },
        { name: 'preset', label: '系统预置' },
        { name: 'remark', label: '备注', limit: 8 },
        { name: 'create_time', label: '创建时间' },
        { name: 'update_time', label: '更新时间' },
      ],
      // 新建窗口展示
      basicInfoVisable: false,
      // 模型文件部署
      deployVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      appList: 'workflow/getAppList',
      moudleList: 'workflow/getBusinessModuleList',
    }),
  },
  watch: {
    activeStatus() {
      this.flowForm.name = '';
      this.flowForm.app_id = '';
      if (this.loading) this.refresh();
    },
  },
  created() {
    this.getAppList();
    this.requestBusinessOptionList();
    const tab = this.$route?.query?.tab;
    if (tab) {
      this.activeStatus = tab;
    }
    this.loading = true;
  },
  methods: {
    ...mapActions({
      getAppList: 'workflow/requestAppList',
      requestBusinessOptionList: 'workflow/requestBusinessOptionList',
    }),
    refresh() {
      this.$refs.list.resetPage();
      this.$refs.list.getList();
    },
    // 获取列表数据
    getList({ pageIndex, pageSize }) {
      return this.$request({
        url: this.$api.getEditProcessList,
        data: {
          page_index: pageIndex,
          page_size: pageSize,
          status: Number(this.activeStatus),
          name: this.flowForm.name,
          app_id: this.flowForm.app_id,
        },
      }).then(({ edit_process_list, total = 0 }) => {
        const computedList = edit_process_list.map((item) => ({
          ...item,
          module:
            this.moudleList.find((moudle) => {
              return moudle.value === item.module;
            })?.label || '',
          preset: item.preset ? '是' : '否',
        }));
        return {
          list: computedList,
          total: Number(total),
        };
      });
    },
    // 表单查询
    handleInput: _.debounce(function () {
      this.refresh();
    }, 1000),
    handleReset() {
      this.flowForm.name = '';
      this.flowForm.app_id = '';
      this.refresh();
    },
    // 增删改
    add() {
      this.basicInfoVisable = true;
    },
    edit({ id }) {
      this.$router.push({
        name: 'workflow-detail',
        query: {
          id,
          tab: this.activeStatus,
        },
      });
    },
    del({ id }) {
      this.$refs.messageBox.show({
        title: '提示',
        content: '确定删除该数据吗?',
        titleIconType: 'warning',
        exitButton: true,
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.deleteEditProcess,
            data: { id },
            cb: () => {
              this.$refs.messageBox.close();
              this.refresh();
            },
          });
        },
      });
    },
    submit({ id }) {
      this.$refs.messageBox.show({
        title: '确认部署发布？',
        content: '部署发布成功后流转到流程管理页',
        titleIconType: 'warning',
        exitButton: true,
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: this.$api.submitEditProcess,
            data: { id },
            cb: () => {
              this.$refs.messageBox.close();
              this.$message({
                message: '发布成功',
                type: 'success',
              });
            },
          });
        },
      });
    },
    // 导出JSON
    exportJSON({ id, version }) {
      this.$request({
        url: this.$api.getEditProcessById,
        data: { id },
        cb: ({ process }) => {
          const data = {
            edit_process_name: process.edit_process_name,
            edit_json: process.edit_process_json,
            edit_process_desc: process.edit_process_desc || '',
            module: process.module,
            process_key: process.process_key,
            preset: process.preset,
            remark: process.remark || '',
            app_id: process.app_id,
            actions: process.actions || [],
            biz_type: process.biz_type,
          };
          const filename = `${process.edit_process_name || '导出'}${
            process.edit_process_name && version ? `(${version})` : ''
          }`;
          openDownloadDialog(
            new Blob([JSON.stringify(data)], { type: 'application/json' }),
            `${filename}.json`,
          );
        },
      });
    },
    // 启用/禁用
    changeStatus({ id }, status) {
      // 接口名
      let api;
      // 标题
      let title;
      // 图标类型
      let type;
      switch (status) {
        case '1':
          api = this.$api.enableEditProcess;
          title = '确定启用该模型吗?';
          type = 'warning';
          break;
        case '2':
          api = this.$api.disableEditProcess;
          title = '确定停用该模型吗?';
          type = 'danger';
          break;
      }
      if (!api) return;
      this.$refs.messageBox.show({
        title,
        noBody: true,
        titleIconType: type,
        needCancel: true,
        cancelAction: this.$refs.messageBox.close,
        confirmAction: () => {
          this.$request({
            url: api,
            data: { id },
            cb: () => {
              this.$refs.messageBox.close();
              this.refresh();
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/tab.scss';
@import '@/styles/element/table.scss';
@import '@/styles/element/dropdown.scss';

.container {
  display: flex;
  flex-direction: column;
}
.top-tab {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 16px;
  padding-right: 16px;
}
.table-container {
  flex: 1;
  margin: 16px 0;
  padding: 16px;
  background: #fff;
  .table-action-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    .table-action-form {
      display: flex;
      .form-item {
        width: 240px;
        margin-right: 8px;
      }
    }
  }
  .text-button {
    margin-right: 15px;
  }
  .danger-action {
    color: #e34d59;
  }
}
</style>
