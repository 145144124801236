import _ from 'lodash';
import { request as requestSDK } from '@/api/axios';

const filterProperty = [
  'pageOption', // 翻页数据
  'filter', // 筛选数据
  'filter1', // 筛选数据
  'tableName', // 表名
];
// 字段中的双引号处理
function formatStr(str) {
  return str.replace(/"/g, '\\"');
}
function getValue(val) {
  if (_.isArray(val)) {
    return `[${val.map((item) => getValue(item)).join(',')}]`;
  }
  if (_.isObject(val)) {
    const result = _.keys(val)
      .map((key) => {
        return `${key}:${getValue(val[key])}`;
      })
      .join(',');
    return `{${result}}`;
  }
  if (_.isBoolean(val) || _.isNumber(val)) {
    return val;
  }
  return `"${_.toString(val)}"`;
}

function calVariables(query, data = {}, replaceAll = true) {
  let outQuery = query;
  const saveData = Object.assign({}, data);

  console.log('=========', query);
  // 筛选条件默认为空
  filterProperty.forEach((key) => {
    if (!saveData[key]) saveData[key] = '';
  });

  _.keys(saveData).forEach((key) => {
    const replaceKey = `$${key}`;
    if (filterProperty.includes(key)) {
      outQuery = outQuery.replace(replaceKey, saveData[key] || '');
    } else if (replaceAll) {
      outQuery = outQuery.replaceAll(replaceKey, getValue(saveData[key]));
    } else {
      outQuery = outQuery.replace(replaceKey, getValue(saveData[key]));
    }
  });
  return outQuery;
}

function saveJson(str = '{}', url) {
  if (typeof str === 'string') {
    try {
      return JSON.parse(str);
    } catch (err) {
      console.error({ url, message: 'the remote data is not a string of json!' });
      return {};
    }
  }
  return str;
}

function handleResult(res, url) {
  const returnData = saveJson(res.data, url);
  console.log('meowdata', res, returnData);
  return returnData;
}

function request(url, data) {
  // console.log('%c ===Graphql query===', 'color:#0f0;');
  // console.log(`%c ${data?.query}`, 'color:#0f0;');
  // console.log('%c ===Graphql query===', 'color:#0f0;');
  return requestSDK({ url, data }).then((res) => handleResult(res, url));
}

// {
//   status:[1,2],
//   type:'between'
// }
const typeKey = 'whereGqlType';
function optionGql(option) {
  let optionKey = '';
  let optionValue = '';
  _.keys(option).forEach((key) => {
    if (key !== typeKey) {
      optionKey = key;
      optionValue = option[key];
    }
  });
  const optionType = option[typeKey];
  if (!(optionValue || optionValue === 0 || optionValue === false)) {
    return '';
  }
  if (optionType === 'like') {
    return `{${optionKey}:{_like:${getValue(optionValue)}}}`;
  }
  if (optionType === 'min') {
    return `{${optionKey}:{_gte:${getValue(optionValue)}}}`;
  }
  if (optionType === 'max') {
    return `{${optionKey}:{_lte:${getValue(optionValue)}}}`;
  }
  if (optionType === 'eq') {
    return `{${optionKey}:{_eq:${getValue(optionValue)}}}`;
  }
  if (optionType === 'in') {
    return `{${optionKey}:{_in:${getValue(optionValue)}}}`;
  }
  if (optionType === 'is') {
    return `{${optionKey}:${getValue(optionValue)}}`;
  }
  if (optionType === 'include_any') {
    return `{${optionKey}:{_include_any:${getValue(optionValue)}}}`;
  }
}

// relation: _and,_or
function whereGql(options = [], relation = '_and', { cascade = false } = {}) {
  const optionGqls = [];
  options.forEach((option) => {
    if (typeof option === 'string') {
      optionGqls.push(option);
    } else {
      const optionValue = optionGql(option);
      optionValue && optionGqls.push(optionValue);
    }
  });
  if (optionGqls.length > 1) {
    if (cascade) return `{${relation}:[${optionGqls.join(',')}]}`;
    return `_where:{${relation}:[${optionGqls.join(',')}]}`;
  }
  if (optionGqls.length > 0) {
    if (cascade) return `${optionGqls[0]}`;
    return `_where:${optionGqls[0]}`;
  }
  if (cascade) return ``;
  return '_where: {}';
}

function pageGql(pageIndex, pageSize) {
  return `_limit: ${pageSize}, _offset: ${(pageIndex - 1) * pageSize}`;
}

export { formatStr, calVariables, request, getValue, whereGql, pageGql };
