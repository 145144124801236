<template>
  <div>
    <div class="intro-title">基础信息</div>
    <div class="node-title">节点ID</div>
    <el-input disabled :value="value.id" />
    <div class="node-title">节点名称</div>
    <el-input
      :value="value.fullname"
      maxlength="20"
      show-word-limit
      @input="(e) => valueUpdate('fullname', e)"
    />
    <div class="node-title">变量配置</div>
    <div class="start-node-tips" v-if="info.biz_type === 'custom_variable'">
      <i class="iconfont icon-warning-fill" />
      <div>
        当前业务类型为统一使用DCR业务类型变量，请在
        <span style="font-weight: 500">输入变量</span>
        中指定dcr变量
      </div>
    </div>
    <slot name="inputs"></slot>
    <div class="node-title">业务实体</div>
    <el-select
      filterable
      :value="value.metadata.trigger.resource"
      :disabled="disableStartBizType"
      placeholder="请选择"
      @change="(e) => startBizChange(e)"
      clearable
    >
      <el-option
        v-for="item in showBusinessEntityList"
        :key="item.value"
        :value="item.value"
        :label="item.label"
      />
    </el-select>
    <div class="intro-title">流程触发配置</div>
    <div class="node-title">触发方式</div>
    <el-radio
      :value="value.metadata.trigger.event_type"
      label="afterCreate"
      @input="(e) => valueUpdate('metadata.trigger.event_type', e)"
    >
      记录已创建
    </el-radio>
    <el-radio
      :value="value.metadata.trigger.event_type"
      label="afterUpdate"
      @input="(e) => valueUpdate('metadata.trigger.event_type', e)"
    >
      记录已更新
    </el-radio>
    <el-radio
      :value="value.metadata.trigger.event_type"
      label="afterCreatOrUpdate"
      @input="(e) => valueUpdate('metadata.trigger.event_type', e)"
    >
      记录已创建或已更新
    </el-radio>
    <el-radio
      :value="value.metadata.trigger.event_type"
      label="handleSubmit"
      @input="(e) => valueUpdate('metadata.trigger.event_type', e)"
    >
      手工提交
    </el-radio>
    <el-radio
      :value="value.metadata.trigger.event_type"
      label="cron"
      @input="(e) => valueUpdate('metadata.trigger.event_type', e)"
    >
      定时触发
    </el-radio>
    <template v-if="value.metadata.trigger.event_type === 'cron'">
      <div class="node-title">
        执行类型
        <span class="require">*</span>
      </div>
      <el-radio
        :value="value.metadata.trigger.execution_type"
        label="periodic"
        @input="(e) => valueUpdate('metadata.trigger.execution_type', e)"
      >
        周期性
      </el-radio>
      <el-radio
        :value="value.metadata.trigger.execution_type"
        label="once"
        @input="(e) => valueUpdate('metadata.trigger.execution_type', e)"
      >
        仅执行一次
      </el-radio>
      <div class="node-title" v-if="value.metadata.trigger.execution_type === 'periodic'">
        执行周期
        <span class="require">*</span>
      </div>
      <div
        style="display: flex; align-items: center"
        v-if="value.metadata.trigger.execution_type === 'periodic'"
      >
        每
        <el-input-number
          size="mini"
          :controls="false"
          :precision="0"
          :min="1"
          v-model="periodicNumber"
          @input="(e) => periodicNumberChange(e)"
          style="width: 250px"
          placeholder="请输入"
          class="left-margin"
        ></el-input-number>
        <el-select
          v-model="periodicUnit"
          placeholder="请选择"
          @change="(e) => periodicUnitChange(e)"
          class="left-margin"
          style="width: 250px"
        >
          <el-option
            v-for="item in periodicUnitOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          />
        </el-select>
        <span class="left-margin">执行1次</span>
      </div>
      <div class="node-title">
        首次执行时间
        <span class="require">*</span>
      </div>
      <el-date-picker
        v-model="initialTriggerTime"
        :picker-options="pickerOptions"
        type="datetime"
        placeholder="请选择"
        value-format="timestamp"
        @change="(e) => initialTriggerTimeChange(e)"
        style="width: 100%"
      ></el-date-picker>
    </template>

    <div class="node-title">备注描述</div>
    <el-input
      type="textarea"
      :rows="2"
      :value="value.description"
      @input="(e) => valueUpdate('description', e)"
      maxlength="50"
      autocomplete="off"
      placeholder="请输入"
    ></el-input>
    <div class="intro-title">流程超时配置</div>
    <div class="node-title">
      设置超时时长
      <el-tooltip
        effect="dark"
        content="当选择「天」时，系统默认按自然天处理，即到达超时指定日期时，在该日期到达后的0:00 执行下方指定选择的触发方式及触发事件"
        placement="top"
      >
        <i class="iconfont icon-question" />
      </el-tooltip>
    </div>
    <div style="display: flex; align-items: center">
      <el-input-number
        size="mini"
        :controls="false"
        :precision="0"
        :min="1"
        v-model="timeoutNumber"
        @input="(e) => timeoutNumberChange(e)"
        style="width: 295px"
        placeholder="请输入"
        class="left-margin"
      ></el-input-number>
      <el-select
        v-model="timeoutUnit"
        placeholder="请选择"
        @change="(e) => timeoutUnitChange(e)"
        class="left-margin"
        style="width: 295px"
      >
        <el-option
          v-for="item in timeoutUnitOptions"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </div>
    <div class="node-title">
      选择触发方式
      <el-tooltip
        effect="dark"
        content="审批退回：仅退回至申请人
流程结束：审批相关场景请勿选择，仅应用于非审批业务"
        placement="top"
      >
        <i class="iconfont icon-question" />
      </el-tooltip>
    </div>
    <el-radio
      :value="value.metadata.timeout.event_type"
      label="pass"
      @input="(e) => valueUpdate('metadata.timeout.event_type', e)"
    >
      审批同意
    </el-radio>
    <el-radio
      :value="value.metadata.timeout.event_type"
      label="reject"
      @input="(e) => valueUpdate('metadata.timeout.event_type', e)"
    >
      审批拒绝
    </el-radio>
    <el-radio
      :value="value.metadata.timeout.event_type"
      label="refund"
      @input="(e) => valueUpdate('metadata.timeout.event_type', e)"
    >
      审批退回
    </el-radio>
    <el-radio
      :value="value.metadata.timeout.event_type"
      label="end"
      @input="(e) => valueUpdate('metadata.timeout.event_type', e)"
    >
      流程结束
    </el-radio>
    <div class="node-title">选择触发事件</div>
    <EventCascader
      v-model="eventList"
      :event-options="eventOptions"
      ref-name="timeout_events"
      node-type="endReject"
      :hide-label="true"
    />
    <div class="intro-title">其他信息</div>
    <template v-if="value.metadata.trigger.event_type !== 'cron'">
      <div class="node-title">
        条件设置
        <el-tooltip
          effect="dark"
          content="明确指定该业务流程的准入条件，用以减少其他业务触发数据变更时造成该业务流程执行。最大限度地减少非必要业务流程的执行次数。"
          placement="top"
        >
          <i class="iconfont icon-question" />
        </el-tooltip>
      </div>
      <el-radio
        :value="value.metadata.trigger.constraints_enable"
        :label="2"
        @input="
          (e) => {
            valueUpdate('metadata.trigger.constraints', '');
            valueUpdate('metadata.trigger.constraints_enable', e);
          }
        "
      >
        不启用条件配置
      </el-radio>
      <el-radio
        :value="value.metadata.trigger.constraints_enable"
        :label="1"
        @input="(e) => valueUpdate('metadata.trigger.constraints_enable', e)"
      >
        启用条件配置
      </el-radio>
      <template v-if="value.metadata.trigger.constraints_enable === 1">
        <div class="node-title">条件配置</div>
        <el-radio
          :value="value.metadata.trigger.conditional_conig"
          :label="1"
          @input="
            (e) => {
              valueUpdate('metadata.trigger.conditional_conig', e);
            }
          "
        >
          规则配置
        </el-radio>
        <el-radio
          :value="value.metadata.trigger.conditional_conig"
          :label="2"
          @input="
            (e) => {
              valueUpdate('metadata.trigger.conditional_conig', e);
            }
          "
        >
          表达式配置
        </el-radio>
        <FormulaConfig
          :object-id="objectResourceId"
          v-if="value.metadata.trigger.conditional_conig === 2"
          selector-width="130px"
          :value="value.metadata.trigger.constraints"
          @change="(e) => valueUpdate('metadata.trigger.constraints', e)"
        />
        <div style="margin-top: 5px">
          <conditionalSelectionTree
            v-if="value.metadata.trigger.conditional_conig === 1 && loaded"
            :value="value"
            :conditional-symbol-path="'metadata.trigger.formed_constraints.conditional_symbol'"
            :conditionals-path="'metadata.trigger.formed_constraints.conditionals'"
            ref="conditionalSelectionTree"
            @update="valueUpdate"
            :fields="objectFiledsList"
          />
        </div>
      </template>
    </template>

    <CreateCloudEventDiaVue
      ref="createCloudEventDialog"
      :info="info"
      @getEventOptions="getEventOptions"
      @addEventValue="addCloudEventValue"
    />
    <CreateNotifyEventDiaVue
      ref="creatNotifyEventDialog"
      :info="info"
      @getEventOptions="getEventOptions"
      @addEventValue="addNoticeEventValue"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import FormulaConfig from './formulaConfig.vue';
import conditionalSelectionTree from '../../components/conditionalSelectionTree.vue';
import { cloneDeep } from 'lodash';
import CreateCloudEventDiaVue from './createCloudEventDia.vue';
import CreateNotifyEventDiaVue from './createNotifyEventDia.vue';
import EventCascader from '@/components/eventCascader';

/* eslint-disable */
export default {
  name: '',
  components: {
    FormulaConfig,
    conditionalSelectionTree,
    CreateCloudEventDiaVue,
    CreateNotifyEventDiaVue,
    EventCascader,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    // 开始时间
    initialTriggerTime(selectTime) {
      const date = new Date(new Date(selectTime).setHours(0, 0, 0, 0)).getTime();
      const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
      if (date <= today) {
        // 当选择的日期就是当天的时候，这个时候就要限制时间应当大于此时此刻的时分秒
        this.pickerOptions.selectableRange =
          new Date().getHours() + ':' + (new Date().getMinutes() + 1) + ':00' + '- 23:59:59';
      } else {
        // 当选择的日期大于当天的时候，这时需要把时分秒的限制放开，否则不能选择
        this.pickerOptions.selectableRange = '00:00:00 - 23:59:59';
      }
    },
    eventList() {
      if (this.loaded) this.updateEvents();
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange:
          new Date().getHours() + ':' + (new Date().getMinutes() + 1) + ':00' + ' - 23:59:59',
      },
      /* eslint-enable */
      objectFiledsList: [],
      periodicNumber: 0,
      timeoutNumber: 0,
      timeoutUnit: 'd',
      periodicUnit: 'd',
      initialTriggerTime: '',
      periodicUnitOptions: [
        {
          value: 'd',
          label: '天',
        },
        {
          value: 'h',
          label: '小时',
        },
        {
          value: 'm',
          label: '分钟',
        },
        {
          value: 's',
          label: '秒',
        },
      ],
      timeoutUnitOptions: [
        {
          value: 'd',
          label: '天',
        },
        {
          value: 'h',
          label: '小时',
        },
        {
          value: 'm',
          label: '分钟',
        },
      ],
      remark: '',
      loaded: false,
      eventOptions: [],
      // 云函数事件ids
      cloudEventIds: [],
      // 通知事件ids
      notifyEventIds: [],
      eventList: [],
    };
  },
  computed: {
    ...mapGetters({
      businessEntityList: 'notice/getObjectList',
    }),
    disableStartBizType() {
      return !!this.$route.query.objectId;
    },
    objectResourceId() {
      if (this.value.metadata?.trigger?.resource) {
        return (
          this.businessEntityList.find(
            (object) => object.name === this.value.metadata.trigger.resource,
          )?.id || ''
        );
      }
      return '';
    },
    showBusinessEntityList() {
      return this.businessEntityList.map(({ name, display_name }) => {
        return {
          label: display_name ? `${display_name}-${name}` : name,
          value: name,
        };
      });
    },
  },

  async created() {
    await this.getEventOptions();
    if (this.value.metadata?.trigger?.resource) {
      const objId = this.businessEntityList.find(
        (object) => object.name === this.value.metadata.trigger.resource,
      )?.id;
      const { fields } = await this.$request({
        url: this.$api.metaGetObjectFields,
        data: {
          object_id: objId,
        },
      });
      this.objectFiledsList = fields;
    }
    if (this.value.metadata?.trigger?.periodic_value) {
      const cloneVal = cloneDeep(this.value.metadata.trigger.periodic_value);
      this.periodicNumber = +cloneVal.replace(/[^0-9]/gi, '');
      this.periodicUnit = cloneVal.replace(/[0-9]/gi, '');
    }
    if (this.value.metadata?.timeout?.timeout_value) {
      const cloneVal = cloneDeep(this.value.metadata.timeout.timeout_value);
      this.timeoutNumber = +cloneVal.replace(/[^0-9]/gi, '');
      this.timeoutUnit = cloneVal.replace(/[0-9]/gi, '');
    }
    if (this.value.metadata?.trigger?.initial_trigger_time) {
      this.initialTriggerTime = this.value.metadata.trigger.initial_trigger_time * 1000;
    }
    this.loaded = true;
  },
  methods: {
    ...mapMutations('workflow', ['setStartNodeObject']),
    // 添加新建的通知事件到输入框
    addNoticeEventValue(id) {
      if (id) {
        this.eventList.push(['notify', id]);
      }
    },
    // 添加新建的云函数到输入框
    addCloudEventValue(id) {
      if (id) {
        this.eventList.push(['cloud_fun', id]);
      }
    },
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
    startBizChange(e) {
      this.valueUpdate('metadata.trigger.resource', e);
      this.setStartNodeObject(e);
      const objId = this.businessEntityList.find((object) => object.name === e)?.id;
      this.$request({
        url: this.$api.metaGetObjectFields,
        data: {
          object_id: objId,
        },
      }).then((data) => {
        this.objectFiledsList = data.fields;
        this.$refs?.conditionalSelectionTree?.resetNodes();
      });
    },
    periodicNumberChange(e) {
      this.loaded && this.valueUpdate('metadata.trigger.periodic_value', e + this.periodicUnit);
    },
    periodicUnitChange(e) {
      this.loaded && this.valueUpdate('metadata.trigger.periodic_value', this.periodicNumber + e);
    },
    timeoutNumberChange(e) {
      this.loaded && this.valueUpdate('metadata.timeout.timeout_value', e + this.timeoutUnit);
    },
    timeoutUnitChange(e) {
      this.loaded && this.valueUpdate('metadata.timeout.timeout_value', this.timeoutNumber + e);
    },
    initialTriggerTimeChange(e) {
      const startTime = new Date(e).getTime();
      if (startTime < Date.now()) {
        this.initialTriggerTime = Date.parse(new Date());
        this.valueUpdate('metadata.trigger.initial_trigger_time', Date.parse(new Date()) / 1000);
      } else {
        this.valueUpdate('metadata.trigger.initial_trigger_time', e / 1000);
      }
    },

    // 获取事件选择项
    async getEventOptions() {
      return new Promise((resolve) => {
        this.$request({
          url: this.$api.getProcessActionList,
          data: {
            page: 0,
            size: -1,
          },
          cb: (res) => {
            this.cloudEventIds = [];
            this.notifyEventIds = [];
            const notifyOptions = {
              value: 'notify',
              label: '通知消息',
              children: [],
            };
            const cloudFunOptions = {
              value: 'cloud_fun',
              label: '云函数自定义事件',
              children: [],
            };
            res.list.forEach((item) => {
              if (item.category === 1) {
                this.notifyEventIds.push(item.id);
                if (item.status === 2) {
                  notifyOptions.children.push({
                    value: item.id,
                    label: `通知消息-${item.name}`,
                    status: item.status,
                    disabled: true,
                    prefix: '通知消息-',
                  });
                } else {
                  notifyOptions.children.unshift({
                    value: item.id,
                    label: `通知消息-${item.name}`,
                    status: item.status,
                    disabled: false,
                    prefix: '通知消息-',
                  });
                }
              } else {
                this.cloudEventIds.push(item.id);
                if (
                  item.status === 2 ||
                  (item.keys.length && !item.keys.includes(this.info.process_key))
                ) {
                  cloudFunOptions.children.push({
                    value: item.id,
                    label: `云函数-${item.name}`,
                    status: item.status,
                    disabled: true,
                    prefix: '云函数-',
                  });
                } else {
                  cloudFunOptions.children.unshift({
                    value: item.id,
                    label: `云函数-${item.name}`,
                    status: item.status,
                    disabled: false,
                    prefix: '云函数-',
                  });
                }
              }
            });
            this.eventOptions = [notifyOptions, cloudFunOptions];

            // 更新回显
            this.updateList(this.value.metadata.timeout.events);
            resolve();
          },
        });
      });
    },
    updateEvents() {
      const events = this.eventList.map((i) => i[1]);
      this.valueUpdate('metadata.timeout.events', events);
    },
    // 同时满足节点数据结构将开始结束放在一个列表内与事件配置需要将开始结束分两个列表存放的情况
    updateList(value) {
      this.eventList = value
        .map((i) => {
          if (this.cloudEventIds.includes(i)) {
            // 级联选择器返回的单元数据格式为 [父元素value, 字元素value]
            return ['cloud_fun', i];
          }
          if (this.notifyEventIds.includes(i)) {
            // 级联选择器返回的单元数据格式为 [父元素value, 字元素value]
            return ['notify', i];
          }
          return '';
        })
        .filter((i) => i);
    },
    openCreateCloudEventDialog() {
      this.$refs.createCloudEventDialog.openDiaLog();
    },
    openCreateNotifyEventDialog() {
      this.$refs.creatNotifyEventDialog.openDiaLog();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
.start-node-tips {
  display: flex;
  justify-content: center;
  background: #f9e0c7;
  border-radius: 3px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #384050;
  margin-bottom: 10px;
  .iconfont {
    font-size: 18px;
    margin-right: 8px;
    color: #fa9600;
  }
}
.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-inputbar {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f6f7fb;
}
.intro-title {
  height: 40px;
  line-height: 40px;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 14px;
  color: #667082;
  margin: 10px -10px 0;
  padding-left: 10px;
}
.left-margin {
  margin-left: 8px;
}
::v-deep .el-date-editor {
  .el-input__inner {
    padding-left: 25px;
    padding-right: 0;
  }
}
</style>
