<template>
  <el-drawer title="初始化" :visible="visible" :size="720" :before-close="close">
    <div class="drawer">
      <el-alert
        title="数据可见范围如果多组都进行选择，系统将以并集的方式对各组选项对应的权限进行组合"
        type="warning"
        :closable="false"
        show-icon
      />
      <el-form ref="form" class="drawer-body" :model="form" label-position="top" :rules="rules">
        <div class="title">基础信息</div>
        <el-form-item label="数据表" prop="table_code">
          <el-input disabled v-model="form.table_code"></el-input>
        </el-form-item>
        <el-form-item label="自定义filter描述" prop="data_filter_desc">
          <el-input
            v-model="form.data_filter_desc"
            placeholder="请输入"
            :disabled="!jurisdiction.roleManage"
          />
        </el-form-item>
        <div class="title">数据可见范围</div>
        <el-form-item label="个人或全部范围" prop="view_type">
          <el-select
            class="select"
            v-model="form.view_data_type"
            clearable
            placeholder="请选择"
            :disabled="!jurisdiction.roleManage || form.view_job_type !== ''"
          >
            <el-option
              v-for="item in VIEW_DATA_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="岗位范围" prop="view_type">
          <el-select
            class="select"
            v-model="form.view_job_type"
            clearable
            placeholder="请选择"
            :disabled="!jurisdiction.roleManage || form.view_data_type !== ''"
          >
            <el-option
              v-for="item in VIEW_JOB_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="员工组织范围" prop="view_type">
          <el-select
            class="select"
            v-model="form.view_org_type"
            clearable
            placeholder="请选择"
            :disabled="!jurisdiction.roleManage"
          >
            <el-option
              v-for="item in VIEW_ORG_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户下属">
          <el-switch
            v-model="form.view_user_type"
            :active-value="2"
            :inactive-value="1"
          ></el-switch>
        </el-form-item>
        <div class="title">数据设置</div>
        <el-form-item label="数据筛选" prop="data_filter" class="data-filter" v-if="visible">
          <template #label>
            <span class="my-label-text">数据筛选</span>
            <el-tooltip
              effect="dark"
              content="
             1. 一个操作类型只能支持配置一个filter语句
             2. 最终行权限范围为'数据可见范围'+'数据筛选'交集"
              placement="top"
            >
              <i class="el-icon-info" style="margin-left: 5px" />
              <div slot="content">
                1. 一个操作类型只能支持配置一个filter语句
                <br />
                2.最终行权限范围为'数据可见范围'+'数据筛选'交集
                <br />
                <br />
                全局变量：
                <br />
                $_tenant: 租户schema
                <br />
                $_uin: 访问时登录用户的uin
                <br />
                $_territory: 访问时登录用户的岗位code
                <br />
                $_territory_all: 访问时登录用户的当前岗位和下属岗位code,为数组
                <br />
                $_table: exisit操作时, 表示当前对象的别名
              </div>
            </el-tooltip>
          </template>
          <el-tabs v-model="filterTabsValue" type="card" editable @edit="handleFilterTabsEdit">
            <el-tab-pane
              :key="i"
              v-for="(filterItem, i) in form.data_filter"
              :label="filterItem.action | getFilterTabName(i)"
              :name="i + ''"
            >
              <el-checkbox-group v-model="form.data_filter[i].action">
                <el-checkbox
                  v-for="item in objectActionSimpleOptions"
                  :key="item.value"
                  :label="item.value"
                  :disabled="isFilterActionDisabled(item.value)"
                >
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
              <data-filter
                ref="filter"
                v-model="form.data_filter[i]"
                :fields="fieldList"
                :appid="searchObj.appid || 1"
              />
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
      <div class="drawer-footer" style="text-align: center">
        <el-button type="primary" @click="submitDataPermissionForm" v-if="jurisdiction.roleManage">
          保存
        </el-button>
        <el-button class="drawer-footer-btn" type="info" @click="close">取消</el-button>
      </div>
    </div>
    <messageBox ref="messageBox" />
  </el-drawer>
</template>

<script>
import messageBox from '@/components/messageBox';
import DataFilter from '@/components/data-filter';
import { VIEW_DATA_TYPE, VIEW_JOB_TYPE, VIEW_ORG_TYPE } from '@/utils/constant';
import { mapGetters } from 'vuex';

const objectActionSimpleOptions = [
  { label: '查询', value: 'select' },
  { label: '新增', value: 'insert' },
  { label: '更新', value: 'update' },
  { label: '删除', value: 'delete' },
];
const initForm = {
  role_id: 0,
  table_code: '',
  object_id: 0,
  view_data_type: '',
  view_job_type: '',
  view_org_type: '',
  view_user_type: 2,
  data_filter_desc: '',
  data_filter: [
    {
      action: ['select', 'create', 'update', 'delete'],
      connector: 'AND',
      conditions: [],
    },
  ],
};

export default {
  name: 'OverviewInit',
  components: { messageBox, DataFilter },
  filters: {
    getFilterTabName: (action, index) => {
      if (!action || action.length === 0) {
        return `Filter${String(index + 1)}`;
      }
      const actionLabelArr = [];
      objectActionSimpleOptions.forEach((v) => {
        if (action.includes(v.value)) {
          actionLabelArr.push(v.label);
        }
      });
      return actionLabelArr.join(' / ');
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      required: true,
      default: null,
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      VIEW_DATA_TYPE,
      VIEW_JOB_TYPE,
      VIEW_ORG_TYPE,
      objectActionSimpleOptions,
      form: { ...initForm },
      rules: {
        data_filter_desc: [
          {
            validator: (rule, value, callback) => {
              if (this.haveFilter(this.form.data_filter)) {
                if (!value) {
                  callback(new Error('请输入自定义filter描述'));
                  return;
                }
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        view_type: [
          {
            validator: (_, __, callback) => {
              if (
                !this.form.view_data_type &&
                !this.form.view_job_type &&
                !this.form.view_org_type
              ) {
                callback(new Error('请选择数据可见范围'));
                return;
              }
              callback();
            },
          },
        ],
      },
      dataPermissionFields: [],
      filterTabsValue: '0',
      searchObj: {
        role_id: Number(this.$route.query.role_id) || 0,
        appid: Number(this.$route.query.appid) || 0,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        roleManage: this.getElementList.includes('role-manage'),
      };
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = { ...this.row };
        this.form.role_id = this.searchObj.role_id || 0;
        this.filterTabsValue = '0';
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
    },
  },
  methods: {
    handleFilterTabsEdit(targetName, action) {
      const currentIndex = Number(this.filterTabsValue);
      if (action === 'add') {
        if (this.form.data_filter.length === 4) {
          this.$message({
            message: 'filter配置最多不超过四条',
            type: 'warning',
          });
          return;
        }

        this.form.data_filter.push({
          action: [],
          connector: 'AND',
          conditions: [],
        });
        this.filterTabsValue = String(this.form.data_filter.length - 1);
      }
      if (action === 'remove') {
        if (this.form.data_filter.length === 1) {
          this.$message({
            message: 'filter配置最少需要一条',
            type: 'warning',
          });
          return;
        }
        if (this.filterTabsValue === targetName) {
          this.form.data_filter.forEach((tab, index) => {
            if (index === Number(targetName)) {
              if (!this.form.data_filter[index + 1]) {
                this.filterTabsValue = String(currentIndex - 1);
              }
            }
          });
        }
        this.form.data_filter.splice(targetName, 1);
      }
    },
    haveFilter(dataFilter) {
      if (dataFilter) {
        if (dataFilter?.length > 0) {
          for (let index = 0; index < dataFilter.length; index++) {
            const item = dataFilter[index];
            if (item.conditions?.length > 0) {
              return true;
            }
          }
          return false;
        }
        // 兼容老数据（对象形式）
        return dataFilter.conditions?.length > 0;
      }
      return false;
    },
    isFilterActionDisabled(curAction) {
      const curIndex = Number(this.filterTabsValue);
      for (let k = 0; k < this.form.data_filter.length; k++) {
        const v = this.form.data_filter[k];
        if (k !== curIndex && v.action.includes(curAction)) {
          return true;
        }
      }
      return false;
    },
    submitDataPermissionForm() {
      for (let index = 0; index < this.form.data_filter.length; index += 1) {
        const item = this.form.data_filter[index];
        if (item.action.length === 0) {
          this.$message({
            message: '不允许有空类型的filter配置',
            type: 'warning',
          });
          return;
        }
      }
      this.$refs.form.validate((val) => {
        if (!val) {
          return;
        }
        const filerValidList = [];
        this.$refs.filter.forEach((v) => {
          filerValidList.push(
            new Promise(function (resolve, reject) {
              v.validate((valid) => {
                if (valid) {
                  resolve();
                } else {
                  reject();
                }
              });
            }),
          );
        });
        Promise.all(filerValidList).then(() => {
          const url = this.$api.setRoleObjectView;
          const cb = () => {
            this.$message({
              message: '对象数据权限设置成功',
              type: 'success',
            });
            this.$emit('success');
          };
          this.$request({
            url,
            cb,
            data: {
              role_id: this.form.role_id,
              object_id: this.form.object_id,
              view_data_type: this.form.view_data_type || 0,
              view_job_type: this.form.view_job_type || 0,
              view_org_type: this.form.view_org_type || 0,
              view_user_type: this.form.view_user_type,
              data_filter_desc: this.form.data_filter_desc,
              data_filter: this.form.data_filter,
            },
          });
        });
      });
    },
    close() {
      this.$refs.messageBox.show({
        title: '放弃编辑后，本次编辑内容将会丢失，确认放弃？',
        titleIconType: 'warning',
        needCancel: true,
        cancelText: '取消',
        confirmType: 'primary',
        confirmAction: () => {
          this.$refs.messageBox.close();
          this.$emit('close');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/drawer.scss';
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/form.scss';
@import '@/styles/element/select.scss';
.drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  .drawer-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-form-item {
      padding: 0 16px;
    }
    .title {
      background: #f2f3f8;
      color: #1c2028;
      font-weight: 700;
      font-size: 16px;
      padding: 8px 0 8px 16px;
      margin-bottom: 16px;
    }
    .select {
      width: 100%;
    }
  }
  .drawer-footer {
    display: flex;
    border-top: 1px solid #e5eaf2;
    padding: 12px;
    .drawer-footer-btn {
      margin: 0 8px;
    }
  }
}
</style>
