<!-- 菜单管理 -->
<template>
  <el-card class="table-container">
    <div class="top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchObj"
        class="searchForm"
        @submit.native.prevent
        @keyup.enter.native="searchList"
      >
        <el-form-item label="选择产品/应用：" prop="appid">
          <el-select v-model="searchObj.appid" placeholder="应用" @change="searchList">
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择平台：" prop="platform">
          <el-select
            class="platform"
            v-model="searchObj.platform"
            placeholder="请选择"
            @change="searchList"
          >
            <el-option
              v-for="item in PLATFORMS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="keyword">
          <el-input
            v-model="searchObj.keyword"
            clearable
            placeholder="关键词搜索"
            @clear="searchList"
          />
        </el-form-item>
        <el-form-item label="展示状态:" prop="display">
          <el-select v-model="searchObj.display" placeholder="请选择" @change="searchList">
            <el-option label="全部" :value="0" />
            <el-option label="显示" :value="1" />
            <el-option label="隐藏" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchList">查 询</el-button>
          <el-button @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="right" v-if="jurisdiction.menuManage">
        <div v-if="currentBranch" class="branchText">
          当前配置文件版本记录分支：
          <a
            style="color: #409eff"
            :href="'https://git.code.tencent.com/nges/paris-menu/tree/' + currentBranch"
          >
            {{ currentBranch || '没有对应的版本分支' }}
          </a>
        </div>
        <div v-else class="branchText">当前环境版本记录未配置保存在 git 仓库</div>
        <el-button class="detection-button" @click="detection()">检测布局json状态</el-button>
        <el-tooltip
          class="item"
          effect="dark"
          content="点击此按钮，检查选中菜单的所有json状态是否符合要求"
          placement="top-start"
        >
          <i style="margin-top: 12px; margin-right: 16px" class="el-icon-info"></i>
        </el-tooltip>
        <el-button class="issue-btn" type="success" @click="issue" v-if="showissue">
          批量发布
        </el-button>
        <el-button class="add-button" type="primary" icon="el-icon-plus" @click="handleAdd()">
          添加
        </el-button>
      </div>
    </div>

    <el-table
      ref="menuTable"
      :data="filterMenuTree"
      row-key="id"
      :cell-style="{ 'text-align': 'left' }"
      :tree-props="{ children: 'children' }"
      @select-all="handleSelectAll"
      @selection-change="handleSelectionChange"
      @select="handleRowSelect"
    >
      <el-table-column type="selection" width="55" :selectable="checkSelectable" v-if="showissue" />
      <el-table-column fixed prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="名称" min-width="140"></el-table-column>
      <el-table-column prop="code" label="Code" min-width="150" />
      <el-table-column prop="path" label="路径" min-width="180"></el-table-column>
      <el-table-column prop="icon" label="图标" min-width="140" align="left"></el-table-column>
      <el-table-column prop="order_weight" label="权重" width="60"></el-table-column>
      <el-table-column prop="display" label="展示状态" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.display == 2 ? '隐藏' : '显示' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="display" label="发布状态" width="100" v-if="showissue">
        <template slot-scope="scope">
          <span v-if="scope.row.layout_json === scope.row.stable_layout_json" class="released">
            已发布
          </span>
          <el-button v-else size="mini" type="info" @click="viewDiff(scope.row)">
            查看差异
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="currentBranch" prop="file_path" label="版本记录存放地址" width="250" />
      <el-table-column label="操作" width="120" v-if="jurisdiction.menuManage">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleSubAdd(scope.$index, scope.row)">
            新增
          </el-button>
          <el-button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button
            v-if="showissue"
            type="text"
            size="small"
            @click="issueRow(scope.row)"
            :disabled="scope.row.layout_json === scope.row.stable_layout_json"
          >
            发布
          </el-button>
          <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="110px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="菜单名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <label slot="label">
            菜单代码
            <el-tooltip
              class="item"
              effect="dark"
              content="代码用于唯一性识别，不可重复"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-input v-model="formObj.code" :disabled="operation == 'update'" />
        </el-form-item>
        <el-form-item prop="path">
          <label slot="label">
            路径
            <el-tooltip
              class="item"
              effect="dark"
              content="如果一级菜单有子菜单的话，不需要填写路径"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-input v-model="formObj.path"></el-input>
        </el-form-item>
        <el-form-item prop="path">
          <label slot="label">图标</label>
          <el-input v-model="formObj.icon"></el-input>
        </el-form-item>
        <el-form-item label="排序权重" prop="order_weight">
          <el-input-number
            v-model="formObj.order_weight"
            controls-position="right"
            :max="999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="父级菜单" prop="parent_code">
          <template>
            <el-select
              v-model="formObj.parent_code"
              placeholder="请选择"
              filterable
              remote
              :remote-method="loadMenuOption"
              :loading="menuLoading"
            >
              <el-option
                v-for="item in menuOptions"
                :key="item.id"
                :label="`${item.name} ${item.code}`"
                :value="item.code"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>

        <el-form-item label="是否展示" prop="display">
          <template>
            <el-select v-model="formObj.display" placeholder="请选择">
              <el-option
                v-for="item in [1, 2]"
                :key="item"
                :label="item | textTrueFalseV2"
                :value="item"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="currentBranch" label="版本记录存放地址" prop="file_path">
          <el-input v-model="formObj.file_path" disabled />
        </el-form-item>
        <el-form-item label="是否为H5模板" prop="json_type">
          <template>
            <el-switch
              v-model="formObj.json_type"
              :disabled="operation == 'update'"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </template>
        </el-form-item>
        <el-form-item label="布局" prop="layout_json">
          <template>
            <div @click.stop="openJsonEdit">
              <el-input
                type="textarea"
                :rows="10"
                placeholder="请输入内容"
                v-model="formObj.layout_json"
                disabled
              ></el-input>
              <div class="tips">(点击进入编辑)</div>
            </div>
            <el-button
              v-if="formObj.json_type === 1 || formObj.path === '/pages/article/template/index'"
              @click.stop="openDraggablePlatform"
              type="primary"
            >
              可视化编辑
            </el-button>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- JSON编辑器 -->
    <json-edit
      v-if="showJson"
      type="diff"
      :show-json="showJson"
      :json="jsonData"
      :old-json="old_layout_json"
      :title="jsonEditTitle"
      @save="saveJson"
      @cancel="showJson = false"
    />
    <!-- 差异展示 -->
    <diff
      :show="jsonDiff.show"
      :old-string="jsonDiff.oldString"
      :new-string="jsonDiff.newString"
      @cancel="jsonDiff.show = false"
    />
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { MENU_PLATFORMS, TENANT_LIST } from '@/utils/constant';
import jsonEdit from '@/components/jsonEdit/index.vue';
import diff from '@/components/codeDiff';
import config from '@/config';
import { getFile, addFile, editFile, deleteFile } from '@/api/git';

const formattedJson = (str) => {
  const defaultStr = str;
  try {
    return JSON.stringify(JSON.parse(str), null, '\t');
  } catch (err) {
    console.log(err);
  }
  return defaultStr;
};
const getAllIds = (list) => {
  let ids = [];
  list.forEach((item) => {
    if (item.layout_json !== item.stable_layout_json) {
      ids.push(item.id);
    }
    ids = [...ids, ...getAllIds(item.children)];
  });
  return ids;
};
export default {
  components: {
    jsonEdit,
    diff,
  },
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{3,100}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为3到100'));
      }
      callback();
    };
    return {
      PLATFORMS: MENU_PLATFORMS,
      operation: 'create',
      listData: [], // 菜单树(全部)
      filterMenuTree: [], // 菜单树(筛选)
      menuOptions: [],
      count: 0,
      appOptions: [],
      formObj: {},
      oldSearchObj: {},
      searchObj: {
        appid: '',
        platform: Number(this.$route.query.platform) || 2,
        keyword: this.$route.query.keyword || '',
        display: Number(this.$route.query.display) || 0,
      },
      jsonEditTitle: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入菜单名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入菜单Code',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
      showJson: false,
      jsonData: '',
      old_layout_json: '',
      menuLoading: false, // 父级菜单加载loading
      selctData: [], // 同步数据
      jsonDiff: {
        show: false,
        oldString: '',
        newString: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getUserInfo', 'getElementList', 'getTenantInfo']), // user 模块名称
    currentBranch() {
      const env = process.env.VUE_APP_ENV; // ['dev', 'test', 'pre', 'prod']
      const tenant = TENANT_LIST?.[env]?.[this.getTenantInfo.id];
      const tenantName = tenant?.git_record ? tenant?.name_en : undefined;
      return tenantName ? `${tenantName}/${env}` : undefined;
    },
    jurisdiction() {
      return {
        menuManage: this.getElementList.includes('menu-manage'),
      };
    },
    // 是否展示发布操作
    showissue() {
      return [
        'production',
        // 'preview', 'test', 'development'
      ].includes(config.env);
    },
  },
  mounted() {
    this.loadAppOption();
    this.init();
  },
  methods: {
    init() {
      if (!this.searchObj.appid) {
        this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
        this.searchObj.platform = Number(sessionStorage.getItem('platform') || 2);
      } else {
        sessionStorage.setItem('app_id', this.searchObj.appid);
        sessionStorage.setItem('platform', this.searchObj.platform);
      }
      if (this.searchObj.appid) {
        this.loadListData();
      }
    },
    searchList() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.oldSearchObj = Object.assign({}, this.searchObj);
      this.timer = setTimeout(() => {
        this.$router.push({ query: this.searchObj });
        sessionStorage.setItem('app_id', this.searchObj.appid);
        sessionStorage.setItem('platform', this.searchObj.platform);
        this.loadListData();
        this.selctData = [];
      }, 300);
    },
    resetForm() {
      this.searchObj.keyword = '';
      this.searchObj.display = 0;
      this.searchList();
    },
    openJsonEdit() {
      this.jsonData = this.formObj.layout_json;
      this.jsonEditTitle = this.formObj.name;
      this.$nextTick(() => {
        this.showJson = true;
      });
    },
    saveJson(json) {
      this.formObj.layout_json = JSON.stringify(JSON.parse(json));
      this.$nextTick(() => {
        this.showJson = false;
      });
    },
    // 菜单树
    loadListData() {
      const url = this.$api.getMenuTree;
      const data = {
        appid: this.searchObj.appid,
        platform: this.searchObj.platform,
      };
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
        this.filterMenuTree = this.filterMenu(this.listData, this.searchObj);
      };
      this.$request({ url, data, cb });
    },
    // 菜单列表
    async loadMenuOption(keyword = '') {
      this.menuLoading = true;
      try {
        const data = await this.$request({
          url: this.$api.getMenuList,
          data: {
            keyword,
            appid: this.searchObj.appid,
            platform: this.searchObj.platform,
            page_size: 99999,
          },
        });
        this.menuOptions = data.data;
        this.menuOptions.push({
          id: 0,
          code: 'root',
          name: 'Root',
        });
        this.menuLoading = false;
      } catch (err) {
        console.log(err);
        this.menuOptions = [];
        this.menuOptions.push({
          id: 0,
          name: 'Root',
        });
        this.menuLoading = false;
      }
    },
    // 获取菜单信息
    async getMenuInfo(id) {
      try {
        const menuInfo = await this.$request({ url: this.$api.getMenu, data: { id } });
        this.formObj = Object.assign({}, menuInfo);
        this.old_layout_json = menuInfo.layout_json || '';
        if (this.currentBranch) {
          const filePath = this.getFilePath(menuInfo);
          let gitJsonExist = false;
          try {
            const file = await getFile({ ref: this.currentBranch, filePath });
            if (file?.body) {
              gitJsonExist = JSON.parse(file?.body)?.content;
            }
          } catch (e) {
            console.log(e);
            this.$message.info('没有找到对应的版本文件，保存将创建一个新的版本文件');
          }
          this.formObj = Object.assign(menuInfo, {
            file_path: filePath,
            layout_json: menuInfo.layout_json,
            git_layout_json_exist: gitJsonExist,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appOptions = data.data;
        if (!this.searchObj.appid) {
          this.searchObj.appid = this.appOptions[0].id;
          this.init();
        }
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateMenu : this.$api.createMenu;
        const msg = id ? '修改成功' : '创建成功';
        let layoutJson = this.formObj.layout_json || '{}';
        // 小程序，需要将json中的表达式，注入8位id
        if (layoutJson && this.searchObj.platform === 1) {
          try {
            layoutJson = await this.$expManage.resolveModifiedJson(layoutJson);
          } catch (e) {
            this.submitDisable = false;
            this.$alert(e.message);
            throw e;
          }
        }
        // 为 json 增加缩进和换行
        layoutJson = JSON.stringify(JSON.parse(layoutJson), null, 2);
        let data = {
          code: this.formObj.code,
          parent_code: this.formObj.parent_code,
          name: this.formObj.name,
          path: this.formObj.path,
          icon: this.formObj.icon || '-',
          order_weight: this.formObj.order_weight,
          display: this.formObj.display,
          appid: this.searchObj.appid,
          layout_json: layoutJson,
          platform: this.searchObj.platform,
          json_type: this.formObj.json_type,
        };
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            order_weight: this.formObj.order_weight,
            display: this.formObj.display,
            appid: this.searchObj.appid,
            path: this.formObj.path,
            icon: this.formObj.icon || '-',
            layout_json: layoutJson,
            platform: this.searchObj.platform,
            parent_code: this.formObj.parent_code,
            last_layout_json: this.old_layout_json,
          };
        }
        console.log('data :>> ', data);
        const cb = () => {
          this.old_layout_json = '';
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        try {
          await this.$request({ url, data, cb, type });
          // 先用将配置保存到数据库，再将版本记录保存到git仓库
          if (this.currentBranch) {
            console.log(url, data, cb, type);
            if (this.formObj.git_layout_json_exist) {
              console.log('{} layoutJson {}', layoutJson);
              editFile({
                branchName: this.currentBranch,
                filePath: this.formObj.file_path,
                content: layoutJson,
                staffName: this.getUserInfo.staffname,
                moduleName: '菜单管理',
              })
                .then(() => {
                  console.log('git 编辑成功');
                })
                .catch((err) => {
                  this.$message.error(`git 编辑失败：${err}`);
                });
            } else {
              addFile({
                branchName: this.currentBranch,
                filePath: this.formObj.file_path,
                content: layoutJson,
                staffName: this.getUserInfo.staffname,
                moduleName: '菜单管理',
              })
                .then(() => {
                  console.log('git 新增成功');
                })
                .catch((err) => {
                  this.$message.error(`git 新增失败：${err}`);
                });
            }
          }
        } catch (err) {
          console.log(err);
          this.submitDisable = false;
        }
      });
    },
    //  -----表格勾选-----
    checkSelectable(row) {
      return row.layout_json !== row.stable_layout_json;
    },
    setChildren(children, type) {
      children.forEach((j) => {
        this.toggleSelection(j, type);
        if (j.children) {
          this.setChildren(j.children, type);
        }
      });
    },
    toggleSelection(row, select) {
      if (row) {
        if (row.layout_json === row.stable_layout_json) {
          return;
        }
        this.$nextTick(() => {
          this.$refs.menuTable && this.$refs.menuTable.toggleRowSelection(row, select);
        });
      }
    },
    handleSelectionChange(val) {
      setTimeout(() => {
        // 防止全选判断失误
        this.selctData = val.map((item) => item.id);
      }, 100);
    },
    // 规则适用范围勾选数据行
    handleRowSelect(selection, row) {
      const hasSelect = selection.some((el) => {
        return row.id === el.id;
      });
      if (hasSelect) {
        if (row.children) {
          // 解决子组件没有被勾选到
          this.setChildren(row.children, true);
        }
      } else {
        if (row.children) {
          this.setChildren(row.children, false);
        }
      }
    },
    handleSelectAll() {
      const tableData = this.filterMenuTree;
      const allIdList = getAllIds(tableData);
      let type = false;
      if (this.selctData.length < allIdList.length) {
        type = true;
      }
      tableData.forEach((el) => {
        if (el.children) {
          // 解决子组件没有被勾选到
          this.setChildren(el.children, type);
        }
      });
    },
    // -------end-----------
    deleteOperation(id) {
      const url = this.$api.deleteMenu;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    detection() {
      try {
        this.$expManage.getJsons(this.listData);
        this.$message({
          message: '检测布局json状态正常',
          type: 'success',
        });
      } catch (e) {
        this.$alert(e.message);
        throw e;
      }
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { display: 1, parent_code: 'root', layout_json: '{}' };
      this.old_layout_json = '{}';
      this.opDialogVisible = true;
      this.loadMenuOption();
    },
    handleSubAdd(index, data) {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = { display: 1, parent_code: data.code, layout_json: '{}' };
      this.old_layout_json = '{}';
      this.opDialogVisible = true;
      this.loadMenuOption();
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.old_layout_json = data.layout_json || '';
      // 获取最新的菜单列表数据
      this.getMenuInfo(data.id);
      this.opDialogVisible = true;
      this.loadMenuOption();
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
          // 先删除菜单，再删除 git 仓库中的版本记录
          if (this.currentBranch) {
            deleteFile({
              branchName: this.currentBranch,
              filePath: data.file_path,
              staffName: this.getUserInfo.staffname,
              moduleName: '菜单管理',
            })
              .then(() => {
                console.log('版本记录删除成功');
              })
              .catch((err) => {
                this.$message.error(`git 新增失败：${err}`);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    viewDiff(row) {
      this.jsonDiff = {
        show: true,
        oldString: formattedJson(row.stable_layout_json),
        newString: formattedJson(row.layout_json),
      };
    },
    async issue() {
      if (this.selctData.length < 1) {
        this.$message.error('请选择数据再操作');
        return;
      }
      try {
        await this.$confirm(`同步菜单${this.selctData.length}个`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({ url: this.$api.releaseMenuJson, data: { ids: this.selctData } });
        this.$message({
          message: '发布成功',
          type: 'success',
        });
        this.loadListData();
      } catch (err) {
        console.log(err);
      }
    },
    async issueRow(row) {
      try {
        await this.$confirm(`确定发布该菜单吗(${row.name || row.code})?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({ url: this.$api.releaseMenuJson, data: { ids: [row.id] } });
        this.$message({
          message: '发布成功',
          type: 'success',
        });
        this.loadListData();
      } catch (err) {
        console.log(err);
      }
    },
    openDraggablePlatform() {
      if (this.formObj.id) {
        const routerUrl = this.$router.resolve({
          path: `/draggablePlatform/${this.formObj.id}`,
        });
        window.open(routerUrl.href, '_blank');
      } else {
        this.$message({
          type: 'warning',
          message: '请先创建菜单后，再进行模板编辑!',
        });
      }
    },
    // 获取配置文件名
    getFilePath(item) {
      const appName = this.appOptions
        .find((app) => app.id === item.appid)
        ?.name?.split(' ')
        ?.join('-');
      if (appName && item.code) {
        return `${appName}/${item.code}.json`;
      }
      console.log('获取配置文件名失败', item);
      return '';
    },
    filterMenu(tree, { keyword = '', display = 0 }) {
      return tree
        .map((item) => {
          return {
            ...item,
            children: this.filterMenu(item.children, { keyword, display }),
            file_path: this.getFilePath(item),
          };
        })
        .filter((item) => {
          if (item.children && item.children.length) {
            return true;
          }
          // 模糊搜索
          const condition1 = !![item.name, item.code, item.path].find((val) => {
            return val.includes(keyword);
          });
          // 显示状态
          let condition2 = true;
          if (display > 0) {
            condition2 = `${item.display}` === `${display}`;
          }
          return condition1 && condition2;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.released {
  color: #67c23a;
}
.issue-btn {
  float: right;
  margin: 0 5px;
}
.detection-button {
  float: left;
  margin: 0 10px;
}
.top {
  display: flex;
  margin-bottom: 10px;
  .searchForm {
    flex: 1;
  }
  .el-select {
    width: 200px;
  }
}
.tips {
  color: rgba(114, 115, 116, 0.63);
  line-height: 25px;
}
::v-deep .el-textarea {
  .el-textarea__inner {
    cursor: pointer !important;
  }

  textarea::-webkit-scrollbar {
    cursor: pointer;
  }
  /*滚动条里面小方块样式*/
  textarea::-webkit-scrollbar-thumb {
    cursor: pointer;
  }
}
.branchText {
  display: block;
  float: left;
  line-height: 38px;
}
.link {
  cursor: pointer;
  color: blue;
}
.platform {
  margin-left: 10px;
}
</style>
