<template>
  <div class="container">
    <div class="top-bar">
      <div class="top-name">
        <i class="iconfont icon-left" @click="cancel" />
        {{ title }}
      </div>
      <el-tabs class="top-tab" v-model="tab" type="card">
        <el-tab-pane label="基础信息" name="1"></el-tab-pane>
        <el-tab-pane label="模型设计" name="2"></el-tab-pane>
        <el-tab-pane label="高级设计" name="3"></el-tab-pane>
      </el-tabs>
      <div class="top-action">
        <el-button type="info" @click="antvTrigger('upload')">
          <i class="iconfont icon-upload" />
          导入
        </el-button>
        <el-button type="info" @click="antvTrigger('download')">
          <i class="iconfont icon-export" />
          导出
        </el-button>
        <div class="action-split" />
        <el-button type="info" @click="cancel">取消</el-button>
        <el-button type="primary" @click="saveModelInfo">保存</el-button>
      </div>
    </div>
    <div class="content-container">
      <BasicInfo ref="basicInfo" v-show="tab === '1'" v-model="basicInfo" />
      <antv
        v-if="!loading"
        v-show="tab === '2'"
        :edit-data="editJson"
        :info="basicInfo"
        @cancel="cancel"
        @save="save"
        @download="download"
        @upload="upload"
        ref="antv"
      ></antv>
      <AdvanceConfig
        v-if="tab === '3'"
        :action-list="advanceActions"
        :auto-repeat="advanceAutoRepeat"
        :advance-select-assigners="advanceSelectAssigners"
        :neighbor-set="neighborSet"
        @update="advanceUpdate"
      />
    </div>
    <MessageBox ref="messageBox" />
    <JsonEdit
      v-if="showJson"
      :type="editType"
      :show-json="showJson"
      :json="jsonData"
      @save="saveJson"
      @cancel="showJson = false"
    />
  </div>
</template>

<script>
import antv from '@/components/antv/index.vue';
import BasicInfo from './components/basicConfig';
import AdvanceConfig from './components/advanceConfig';
import MessageBox from '@/components/messageBox';
import { createModelInitJson } from '../common';
import { importData, exportData } from '@/components/antv/conversion.js';
import JsonEdit from '@/components/jsonEdit/index.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'WorkflowDetail',
  components: { BasicInfo, AdvanceConfig, MessageBox, antv, JsonEdit },
  data() {
    return {
      tab: '2',
      tabList: [
        {
          name: '1',
          label: '基础信息',
        },
        {
          name: '2',
          label: '模型设计',
        },
        {
          name: '3',
          label: '高级设计',
        },
      ],
      // 基础信息
      basicInfo: {
        edit_process_desc: '',
        edit_process_name: '',
        process_key: '',
        module: '', // 业务类型
        app_id: '', // 产品类型
        preset: false, // 是否系统预置
      },
      // 页面标题 单独保存防止被表单影响
      title: '',
      // 模型设计的json
      editJson: '',
      // 高级设置中选中的事件 pass通过 reject拒绝 revoke撤回
      advanceActions: [],
      // 高级设置中选中的重复审批设置
      advanceAutoRepeat: 1,
      // 高级设置中已选未流转到的仅能查看的审批人
      advanceSelectAssigners: [],
      // 高级设置中选中的发起人和相邻审批人相同设置
      neighborSet: 1,
      // 加载标志
      loading: false,
      // 展示json弹框
      showJson: false,
      // json弹框数据
      jsonData: '',
      // json弹框操作类型
      editType: 'view',
      // 列表页的tab数据
      listTab: '',
    };
  },
  watch: {
    tab() {
      // 切换tab时关闭侧栏
      this.$refs.antv.closeConfiguration();
    },
  },
  created() {
    this.getbusinessEntityList();
    const { id, tab } = this.$route.query;
    this.id = id;
    this.listTab = tab;
    if (id) {
      this.loading = true;
      this.getInfo(id);
      this.requestBusinessOptionList();
    } else {
      this.$router.replace({
        name: 'workflow',
      });
    }
  },
  methods: {
    ...mapActions('workflow', ['requestBusinessOptionList']),
    ...mapMutations('workflow', ['setStartNodeObject']),
    ...mapActions({
      getbusinessEntityList: 'notice/requestObjectList',
    }),
    getInfo(id) {
      this.$request({
        url: this.$api.getEditProcessById,
        data: { id },
        cb: async ({ process }) => {
          try {
            this.basicInfo = {
              edit_process_name: process.edit_process_name,
              process_key: process.process_key,
              edit_process_desc: process.edit_process_desc,
              preset: process.preset,
              module: process.module,
              app_id: process.app_id,
              biz_type: process.biz_type,
            };
            this.title = process.edit_process_name;
            this.advanceActions = process.actions;
            if (process.metadata && process.metadata !== 'null') {
              if (+JSON.parse(process.metadata).repeat_approval_config) {
                this.advanceAutoRepeat = +JSON.parse(process.metadata).repeat_approval_config;
              }

              this.advanceSelectAssigners = JSON.parse(process.metadata).select_assigners || [];
              if (+JSON.parse(process.metadata).neighbor_assigners_set) {
                this.neighborSet = +JSON.parse(process.metadata).neighbor_assigners_set;
              }
            }
            await this.getJson(process.edit_process_json);

            this.loading = false;
          } catch (err) {
            console.log('getInfo', err);
          }
        },
      });
    },
    async getJson(json) {
      let data = this.editJson;
      try {
        if (json) {
          let initJson = JSON.parse(json || '{}');
          // 没有进行过节点配置走默认 json配置
          if (!initJson?.nodes?.length) {
            initJson = createModelInitJson;
          }

          // 获取权限配置数据
          const { list } = await this.$request({
            url: this.$api.metaGetObjectByRelatedWorkflow,
            data: {
              process_def_key: this.basicInfo.process_key,
            },
          });
          let fieldPermission = [];
          if (list[0]) fieldPermission = list[0].workflow_config.fields;
          const importJson = importData(initJson, {
            fieldPermission,
            startNodeCallback: this.setStartNodeObject.bind(this),
          });

          if (importJson) {
            data = JSON.stringify(importJson);
          }
        }
      } catch (err) {
        console.log(err);
      }

      this.editJson = data;
      this.$nextTick(() => {
        this.loading = false;
        this.showJson = false;
      });
    },
    // json编辑器
    async saveJson(json) {
      try {
        this.$refs.messageBox.show({
          title: '提示',
          content: '此操作将会覆盖页面已有数据?',
          titleIconType: 'warning',
          needCancel: true,
          cancelAction: this.$refs.messageBox.close,
          confirmAction: async () => {
            this.$refs.messageBox.close();
            this.loading = true;
            await this.getJson(json);
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    download({ data }) {
      this.editType = 'view';
      this.jsonData = JSON.stringify(exportData(JSON.parse(data)));
      this.$nextTick(() => {
        this.showJson = true;
      });
    },
    upload({ data }) {
      this.editType = 'edit';
      this.jsonData = '';
      this.editJson = data;

      this.$nextTick(() => {
        this.showJson = true;
      });
    },
    save({ data }) {
      console.log('data=====', data);
      this.editJson = data;
      this.saveData();
    },
    saveData() {
      try {
        const info = {
          ...this.basicInfo,
          edit_json: JSON.stringify(exportData(JSON.parse(this.editJson))),
        };
        let url = '';
        const data = {
          id: this.id,
          edit_json: info.edit_json,
          edit_process_name: info.edit_process_name,
          edit_process_desc: info.edit_process_desc,
          process_key: info.process_key,
          preset: info.preset,
          module: info.module,
          actions: this.advanceActions,
          metadata: JSON.stringify({
            repeat_approval_config: this.advanceAutoRepeat,
            select_assigners: this.advanceSelectAssigners,
            neighbor_assigners_set: this.neighborSet,
          }),
          app_id: info.app_id,
          biz_type: info.biz_type,
        };
        url = this.$api.updateEditProcess;

        this.$request({
          url,
          data,
          cb: () => {
            this.$message.success('保存成功');
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    cancel() {
      try {
        this.$refs.messageBox.show({
          title: '提示',
          content: '此操作将不会保存已修改数据?',
          titleIconType: 'warning',
          needCancel: true,
          cancelAction: this.$refs.messageBox.close,
          confirmAction: async () => {
            this.$refs.messageBox.close();
            if (this.listTab) {
              this.$router.push({
                name: 'workflow',
                query: {
                  tab: this.listTab,
                },
              });
            } else {
              this.$router.go(-1);
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    antvTrigger(event) {
      this.$refs.antv.save(event);
    },
    // 保存
    saveModelInfo() {
      // 基础信息检查
      this.$refs.basicInfo.checkData((valid) => {
        if (!valid) {
          this.$message.error('基础信息填写不完整或有误');
          this.tab = '1';
          return;
        }
        // 触发json保存后通过回调触发保存函数
        this.$refs.antv.save();
      });
    },
    // 高级设置值的保存
    advanceUpdate({ type, value }) {
      if (type === 'action') {
        this.advanceActions = value;
      } else if (type === 'repeat') {
        this.advanceAutoRepeat = value;
      } else if (type === 'neighbor_set') {
        this.neighborSet = value;
      } else {
        this.advanceSelectAssigners = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/tab.scss';

.container {
  height: 100vh;
  background: #fff;

  display: flex;
  flex-direction: column;
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .top-name {
      color: #1c2028;
      font-weight: 700;
      font-size: 16px;
      .iconfont {
        cursor: pointer;
      }
    }
    .top-action {
      display: flex;
      .action-split {
        width: 1px;
        height: 32px;
        margin: 0 8px;
        background: #98a2b2;
      }
    }
  }
  .content-container {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background: #f2f3f8;
  }
}
</style>
