import { full } from 'acorn-walk';
import { parse } from 'acorn';
import _ from 'lodash';

const AST_NODE_BLACK_LIST = [
  'BlockStatement',
  'BreakStatement',
  'ContinueStatement',
  'ReturnStatement',
  'SwitchStatement',
  'IfStatement',
  'ArrayExpression',
  'NewExpression',
  'UpdateExpression',
  'SwitchCase',
  'ArrowFunctionExpression',
  'AssignmentExpression',
];
const AST_NODE_BLACK_LIST_MAP_TIPS = {
  BlockStatement: '块级代码',
  BreakStatement: 'break',
  ContinueStatement: 'continue',
  ReturnStatement: 'return',
  SwitchStatement: 'Switch Case',
  IfStatement: '控制流',
  ArrayExpression: '数组',
  NewExpression: 'New',
  UpdateExpression: '更新成员值',
  SwitchCase: 'Switch Case',
  ArrowFunctionExpression: '箭头函数',
  AssignmentExpression: '赋值',
};

// 后台定义的全局对象
const GLOBAL_VAR = [
  'CURRENT_TIME', // 当前时间戳(单位：秒)
  'CURRENT_DATE', // 当前日期(0点时间戳)
  'CURRENT_USER_UIN', // 当前用户uin
  'CURRENT_USER_TERRITORY', // 当前用户岗位code
];
// 后台定义的全局函数
const INT_OP = [
  'ADD', // 加
  'SUB', // 减
  'MUL', // 乘
  'DIV', // 除
  'MOD', // 取模
];
// 后台定义的全局函数
const GLOBAL_FUNC = [
  ...INT_OP,
  'NOT_IN',
  'FORMAT_TIMESTAMP',
  'NOT_BLANK',
  'NOT_NULL', // 是否为空，参数1个
  'IN', // 属于(暂未确定，参数两个？)
  'SPLIT_LENGTH',
  'REGEX_MATCH',
  'COUNT',
  'SUM',
  'IS_INSERT',
  'IS_UPDATE',
  'INT_DIV',
  'DAY',
  'MONTH',
  'GET_TIME',
];
// 后台定义的比较运算符
const OPERATOR_MAP = {
  '===': 'EQ',
  '!==': 'NEQ',
  '>': 'GT',
  '>=': 'GTE',
  '<': 'LT',
  '<=': 'LTE',
};
// 后台定义的数字运算符与函数的对应关系，golang的数字运算和浮点数运算对应的运算符不一样，所以封装成函数有后台进行类型判断再决定使用哪个运算符
const INT_OPERATOR_MAP = {
  '+': 'ADD',
  '-': 'SUB',
  '*': 'MUL',
  '/': 'DIV',
  '%': 'MOD',
};
// 后台定义的逻辑运算符
const CONNECTOR_MAP = {
  '||': 'OR',
  '&&': 'AND',
};
// 前端从字符串获取ast
const getAstFromStr = async (codeStr = '', object = '') => {
  let astErrTip = '';
  return new Promise((re) => {
    try {
      let isValidAst = true;
      const ast = parse(codeStr);
      console.log('=== frontend ast');
      console.log(ast);
      console.log('=== frontend ast');
      if (Array.isArray(ast.body) && ast.body.length > 1) {
        astErrTip = '暂不支持多行表达式！';
        isValidAst = false;
        re({ isValidAst, astErrTip });
      } else {
        full(ast, (node) => {
          if (AST_NODE_BLACK_LIST.includes(node.type)) {
            astErrTip = `暂不支持${AST_NODE_BLACK_LIST_MAP_TIPS[node.type]}`;
            isValidAst = false;
          }
        });
        const newAst = transformer(codeStr, object);
        re({ isValidAst, astErrTip, ast: newAst });
      }
    } catch (err) {
      astErrTip = err.message;
      re({ isValidAst: false, astErrTip });
    }
  });
};
const transformer = (codeStr, object = '') => {
  const ast = parse(codeStr);
  return traverse(ast, object);
};

const traverse = (ast, object) => {
  // 变量栈
  let varStack = [];
  // 操作符栈
  const opStack = [];
  full(ast, (node) => {
    switch (node.type) {
      case 'Identifier': {
        const newNode = handleIdentifier(node);
        newNode && varStack.push(newNode);
        break;
      }
      case 'Literal': {
        const newNode = handleLiteral(node);
        varStack.push(newNode);
        break;
      }
      case 'BinaryExpression': {
        const newNode = handleBinaryExpression(node);
        opStack.push(newNode);
        break;
      }
      case 'MemberExpression': {
        const newNode = handleMemberExpression(node);
        // 多层对象字段，后进栈的覆盖前面不完整的字段
        const exitIndexOfField = varStack.findIndex(
          (variable) => variable.frontendNode.start === newNode.frontendNode.start,
        );
        if (exitIndexOfField >= 0) {
          varStack[exitIndexOfField] = newNode;
        } else {
          varStack.push(newNode);
        }
        break;
      }
      case 'LogicalExpression': {
        const newNode = handleLogicalExpression(node);
        opStack.push(newNode);
        break;
      }
      case 'CallExpression': {
        const newNode = handleCallExpression(node);
        opStack.push(newNode);
        break;
      }
      default:
        break;
    }
  });
  console.log('===varStack, opStack===');
  console.log(varStack, opStack);
  varStack = hadValidVar(varStack, object);
  const newAst = generateNewAst({ varStack, opStack });
  console.log('===newAst===', newAst, JSON.stringify(newAst));
  return newAst;
};
const hadValidVar = (varStack, object) => {
  for (const i in varStack) {
    if (varStack[i]?.backendNode) {
      const { backendNode } = varStack[i];
      const { name, type } = backendNode;
      if (object && type === 'FIELD') {
        if (!name.startsWith(`${object}.`)) {
          throw Error(`${name}字段需要${object}对象前缀`);
        } else {
          varStack[i].backendNode = {
            ...varStack[i].backendNode,
            name: name.slice(object.length + 1),
          };
        }
      }
    }
  }
  return varStack;
};
const handleIdentifier = (node = {}) => {
  if (GLOBAL_VAR.includes(node.name)) {
    // 后台定义的全局变量
    return {
      frontendNode: node,
      backendNode: {
        type: 'VAR',
        name: node.name,
      },
    };
  }
  // 函数不进变量栈
  if (GLOBAL_FUNC.includes(node.name)) {
    return;
  }
  // 标识符，目前代表单个字段
  return {
    frontendNode: node,
    backendNode: {
      type: 'FIELD',
      name: node.name,
    },
  };
};
const handleLiteral = (node = {}) => {
  return {
    frontendNode: node,
    backendNode: {
      type: 'CONST',
      value_type: adjustValueType(node),
      value: `${node.value}`,
    },
  };
};
const handleBinaryExpression = (node = {}) => {
  if (OPERATOR_MAP[node.operator]) {
    node.backendOperator = OPERATOR_MAP[node.operator];
    return node;
  }
  if (INT_OPERATOR_MAP[node.operator]) {
    node.backendOperator = INT_OPERATOR_MAP[node.operator];
    return node;
  }
  throw Error(`暂不支持该运算符：${node.operator}`);
};
const handleMemberExpression = (node = {}) => {
  return {
    frontendNode: node,
    backendNode: {
      type: 'FIELD',
      name: getField(node),
    },
  };
};
const handleLogicalExpression = (node = {}) => {
  if (CONNECTOR_MAP[node.operator]) {
    node.backendOperator = CONNECTOR_MAP[node.operator];
    return node;
  }
  throw Error(`暂不支持该运算符：${node.operator}`);
};
const handleCallExpression = (node = {}) => {
  if (GLOBAL_FUNC.includes(node.callee.name)) {
    return node;
  }
  throw Error(`暂不支持该函数：${node.callee.name}`);
};
const getField = (node = {}) => {
  if (!node.object?.object) {
    return `${node.object.name}.${node.property.name}`;
  }
  return `${getField(node.object)}.${node.property.name}`;
};
const adjustValueType = (node) => {
  const { value, raw } = node;
  if (typeof value === 'number') {
    if (`${value}`.includes('.')) {
      return 'FLOAT';
    }
    // 防止类似这种"1.0"被隐式转换成1后判断为INT，实际上也是FLOAT(强类型语言与弱类型语言的区别，后台是golang)
    if (raw.includes('.')) {
      // 重新覆盖node上被隐式转换的value
      node.value = raw;
      return 'FLOAT';
    }
    return 'INT';
  }
  if (_.isBoolean(value)) {
    return 'BOOL';
  }
  if (_.isString(value)) {
    return 'TEXT';
  }
  if (_.isNil(value)) {
    return 'NULL';
  }
  return 'UNKNOWN';
};
const generateNewAst = ({ opStack, varStack }) => {
  opStack.forEach((op) => {
    const { backendOperator, type } = op;
    if (backendOperator) {
      const isIntOp = INT_OP.includes(backendOperator);
      const isOp = Object.values(OPERATOR_MAP).includes(backendOperator);
      const isLogicalOp = Object.values(CONNECTOR_MAP).includes(backendOperator);
      const left = findVar({ varStack, node: op.left });
      const right = findVar({ varStack, node: op.right });
      if (isIntOp) {
        const node = {
          type: 'FUNC',
          name: backendOperator,
          params: [
            {
              ...getFuncParamsNode(left),
            },
            {
              ...getFuncParamsNode(right),
            },
          ],
        };
        op.VALUE = node;
      } else if (isOp) {
        const node = {
          operator: backendOperator,
          left,
          right,
        };
        op.VALUE = node;
      } else if (isLogicalOp) {
        const newLeft = getLogicalSubNode(left);
        const newRight = getLogicalSubNode(right);
        const node = {
          condition: {
            connector: backendOperator,
            expressions: [
              {
                ...newLeft,
              },
              {
                ...newRight,
              },
            ],
          },
        };
        op.VALUE = node;
      } else {
        throw Error(`暂不支持该运算：${backendOperator}`);
      }
    } else if (type === 'CallExpression') {
      const { callee, arguments: callArguments } = op;
      const params = callArguments.map((argument) =>
        getFuncParamsNode(findVar({ varStack, node: argument })),
      );
      const node = {
        type: 'FUNC',
        name: callee?.name,
        params,
      };
      op.VALUE = node;
    } else {
      throw Error(`暂不支持该表达式`);
    }
  });
  if (opStack.length > 0) {
    const ast = opStack[opStack.length - 1]?.VALUE || {};
    const isOp = _.has(ast, 'condition');
    const isLogicalOp = !!ast.operator;
    // 是否单节点
    const isSingleNode = !(isOp || isLogicalOp);
    if (isSingleNode) {
      return {
        operator: '',
        left: ast,
        right: null,
      };
    }
    return ast;
  }
  if (varStack.length > 0) {
    return {
      operator: '',
      left: varStack[varStack.length - 1]?.backendNode,
      right: null,
    };
  }
  return {};
};
const getLogicalSubNode = (node) => {
  let newNode = null;
  const isLogical = _.has(node, 'condition');
  if (isLogical) {
    newNode = node;
  } else if (_.has(node, 'operator') && Object.values(OPERATOR_MAP).includes(node.operator)) {
    // 二元操作符
    newNode = node;
  } else {
    newNode = {
      operator: '',
      left: {
        ...node,
      },
      right: null,
    };
  }
  return newNode;
};
const getFuncParamsNode = (node) => {
  let newNode = null;
  const isLogical = _.has(node, 'condition');
  const isOp = _.has(node, 'operator') && Object.values(OPERATOR_MAP).includes(node.operator);
  const isExpression = isLogical || isOp;
  if (isExpression) {
    newNode = {
      type: 'EXPR',
      expr: node,
    };
  } else {
    newNode = node;
  }
  return newNode;
};
const findVar = ({ varStack, node }) => {
  if (node.VALUE) {
    return node.VALUE;
  }
  const { start } = node;
  return varStack.find((variable) => variable.frontendNode.start === start)?.backendNode || {};
};

export { getAstFromStr, transformer };
