var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tips-bar"},[_c('i',{staticClass:"iconfont icon-warning-fill"}),_c('div',{staticClass:"right-bar"},[_c('div',[_vm._v("请在节点”输入变量”中配置自定义云函数的输入参数")]),_c('div',[_vm._v("1.配置参数内容为：①业务表单中的审批金额；②流程申请人或者其他用户信息")]),_c('div',[_vm._v("例如：")]),_c('div',[_vm._v("applyAmount_Input ：events_meeting.budget（业务表单的预算金额）")]),_c('div',[_vm._v("applyUser_Input： 申请人或者节点用户信息")]),_c('div',[_vm._v("2.自定义云函数的计算结果需要按以下指定格式返回指定顺序的审批用户UIN，")]),_c('div',[_vm._v("返回格式为 { 顺序数字：用户UIN }，示例如下：")]),_c('div',[_vm._v(_vm._s('{'))]),_c('div',{staticStyle:{"text-indent":"2em"}},[_vm._v(_vm._s('1: user-uin0001'))]),_c('div',{staticStyle:{"text-indent":"2em"}},[_vm._v(_vm._s('2: user-uin0002'))]),_c('div',{staticStyle:{"text-indent":"2em"}},[_vm._v(_vm._s('3: user-uin0003'))]),_c('div',[_vm._v(_vm._s('}'))])])]),_c('div',{staticClass:"node-title"},[_vm._v("节点ID")]),_c('el-input',{attrs:{"disabled":"","value":_vm.value.id}}),_vm._m(0),_c('el-input',{attrs:{"value":_vm.value.fullname,"maxlength":"20","show-word-limit":""},on:{"input":function (e) { return _vm.valueUpdate('fullname', e); }}}),_c('div',{staticClass:"node-title"},[_vm._v("变量配置")]),_vm._t("inputs"),_vm._t("outputs"),_vm._m(1),_vm._t("selectFunction"),_c('div',{staticClass:"node-title"},[_vm._v("退回节点")]),_c('el-switch',{attrs:{"value":_vm.value.metadata.rollback.enable},on:{"change":function (e) {
        _vm.valueUpdate('metadata.rollback.enable', e);
        _vm.valueUpdate('metadata.rollback.rollback_type', e ? 3 : 0);
      }}}),(_vm.value.metadata.rollback.enable)?_c('div',{staticStyle:{"margin-top":"12px"}},[_c('el-radio',{attrs:{"value":_vm.value.metadata.rollback.rollback_type,"label":1,"disabled":""},on:{"input":function (e) {
          _vm.valueUpdate('metadata.rollback.rollback_type', e);
          _vm.valueUpdate('metadata.rollback.skip', 0);
        }}},[_vm._v(" 上一节点 ")]),_c('el-radio',{attrs:{"value":_vm.value.metadata.rollback.rollback_type,"label":2,"disabled":""},on:{"input":function (e) {
          _vm.valueUpdate('metadata.rollback.rollback_type', e);
          _vm.valueUpdate('metadata.rollback.skip', 0);
        }}},[_vm._v(" 指定节点 ")]),_c('el-radio',{attrs:{"value":_vm.value.metadata.rollback.rollback_type,"label":3},on:{"input":function (e) {
          _vm.valueUpdate('metadata.rollback.rollback_type', e);
          _vm.valueUpdate('metadata.rollback.skip', 0);
        }}},[_vm._v(" 退回到申请人 ")]),(_vm.value.metadata.rollback.rollback_type === 3)?_c('el-select',{staticStyle:{"margin-top":"10px"},attrs:{"value":_vm.value.metadata.rollback.skip},on:{"change":function (e) {
          _vm.valueUpdate('metadata.rollback.skip', e);
        }}},_vm._l((_vm.resubmitPolicy),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"node-title"},[_vm._v("备注描述")]),_c('el-input',{attrs:{"type":"textarea","rows":2,"value":_vm.value.description,"maxlength":"50","autocomplete":"off","placeholder":"请输入"},on:{"input":function (e) { return _vm.valueUpdate('description', e); }}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"node-title"},[_vm._v(" 节点名称 "),_c('span',{staticClass:"require"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"node-title"},[_vm._v(" 选择云函数 "),_c('span',{staticClass:"require"},[_vm._v("*")])])}]

export { render, staticRenderFns }