<template>
  <el-dialog :title="title" :visible.sync="myVisible" :width="width" :close-on-click-modal="false">
    <el-transfer
      v-model="checkRule"
      :data="roleList"
      filterable
      :titles="['可选择角色', '已选择角色']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      }"
      @keyup.enter.native="submitForm"
    ></el-transfer>
    <span slot="footer" class="dialog-footer">
      <el-button @click="myVisible = false">取 消</el-button>
      <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'RoleBox',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '角色设置',
    },
    width: {
      type: String,
      default: '850px',
    },
    checkRoleUrl: {
      type: String,
    },
    updateRoleUrl: {
      type: String,
    },
  },
  data() {
    return {
      myVisible: this.visible,
      submitDisable: false,
      groupRoleList: [],
      checkRule: [],
    };
  },
  computed: {
    roleList() {
      const list = [];
      this.groupRoleList.forEach((roleSet) => {
        roleSet.Roles.forEach((role) => {
          list.push({
            key: role.id,
            label: `【${roleSet.roleSetName}】${role.roleName}${
              role.desc ? `（${role.desc}）` : ''
            }`,
          });
        });
      });
      return list;
    },
  },
  watch: {
    visible(val) {
      this.myVisible = val;
    },
    myVisible(val) {
      if (this.myVisible) {
        this.getCheckRole();
      }
      this.$emit('update:visible', val);
    },
  },
  mounted() {},
  methods: {
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.myVisible = false;
      this.submitDisable = true;
      const roleIds = this.checkRule.join(',');
      const url = this.updateRoleUrl;
      const data = { roleIds };
      const cb = () => {
        this.submitDisable = false;
        this.$message({
          message: '角色修改成功',
          type: 'success',
        });
      };
      const type = 'post';
      this.$request({ url, data, cb, type });
    },
    getCheckRole() {
      this.checkRule = [];
      const url = this.checkRoleUrl;
      const cb = (data) => {
        if (!data) {
          return;
        }
        data.forEach((role) => {
          this.checkRule.push(role.id);
        });
      };
      this.$request({ url, cb });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.el-transfer {
  margin: auto;
  width: 740px;
  .el-transfer-panel {
    width: 320px;
  }
}
</style>
