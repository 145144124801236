import { Message } from 'element-ui';
/* eslint-disable camelcase */
import { getValue } from '@/utils/util';
import { nodeTypes } from './config';
import _ from 'lodash';
import store from '../../store/index';

// 节点信息转化 startNode -> start_node
const oldToNew = {
  create_node: 'create_object',
  update_node: 'update_object',
};
// 事件节点类型数组
const eventNodeTypes = ['time_boundary_event'];

function toLine(type) {
  const newType = type.replace(/([A-Z])/g, '_$1').toLowerCase();
  // eslint-disable-next-line no-prototype-builtins
  if (oldToNew[newType]) {
    return oldToNew[newType];
  }
  return newType;
}
// 导入数据
export function importData(editjson, { fieldPermission, startNodeCallback }) {
  let nodes = [];
  let edges = [];
  let combos = [];
  const unknownNodes = [];
  // 用于处理边界事件节点的map
  const events2Map = {};
  // 记录当前模型的权限配置字段
  const fieldPermissionColumnMap = {};
  fieldPermission.forEach((key) => {
    fieldPermissionColumnMap[key] = 1;
  });
  let selectdAssigneers = [];
  store.commit('workflow/setGatewayInclusiveState', false);
  try {
    const { nodes: oldNodes, edges: oldEdges } = editjson;
    console.log('oldNodes===', oldNodes);
    console.log('oldEdges===', oldEdges);
    // 节点
    nodes = oldNodes.map((item) => {
      const { type, subref } = item;
      let newType = toLine(type);
      if (subref) {
        newType = nodeTypes[`${newType}_${subref}`] ? `${newType}_${subref}` : newType;
      }
      if (!Object.keys(nodeTypes).includes(newType)) {
        if (!unknownNodes.includes(type)) {
          unknownNodes.push(type);
        }
      }
      // 设置工作流模型开始节点全数据全局对象
      if (type === 'start_node') {
        startNodeCallback(item.metadata.trigger.resource);
      }
      // 设置该模型全局已选的指定的审批人
      if (type === 'user') {
        const candidateUser = _.get(item, 'metadata.operator_value.candidate_user', []);
        const attributeCheckedUser = _.get(
          item,
          'metadata.operator_value.attribute_checked_user',
          [],
        );
        selectdAssigneers = [...selectdAssigneers, ...candidateUser, ...attributeCheckedUser];
      }
      // 节点中含有包容网关的一些事件
      if (newType === 'gateway_inclusive') {
        store.commit('workflow/setGatewayInclusiveState', true);
      }
      // 配置的属性  suref  outputs  inputs...
      const extraData = {};
      Object.keys(nodeTypes[newType]?.default_config || {}).forEach((key) => {
        // 对所有审批节点的权限配置进行初始化 将当前模型的表单与已存储数据进行合并 同时删除当前模型表单内不存在的字段数据
        if (type === 'user' && key === 'field_permission') {
          const permission = {};
          const nowData = item[key] || nodeTypes[newType]?.default_config[key];
          Object.keys(fieldPermissionColumnMap).forEach((permissionKey) => {
            if (nowData[permissionKey]) permission[permissionKey] = nowData[permissionKey];
            else permission[permissionKey] = fieldPermissionColumnMap[permissionKey];
          });
          extraData[key] = permission;
        } else {
          extraData[key] = deepCopyDefaultConfig(
            item[key],
            nodeTypes[newType]?.default_config[key] || {},
          );
        }
      });
      if (extraData.metadata?.comboInfo?.id) {
        if (!combos.find((combo) => combo.id === extraData.metadata.comboInfo.id)) {
          combos.push(extraData.metadata.comboInfo);
        }
        const { children } = extraData.metadata.comboInfo;
        let falg = true;
        children.forEach((child) => {
          if (!oldNodes.find((node) => node.id === child.id)) {
            falg = false;
          }
        });
        if (!falg || children.length < 2) {
          extraData.metadata.comboInfo = undefined;
          extraData.events2 = [];
        }
      }
      if (extraData.events2?.length) {
        extraData.events2.forEach((event) => {
          events2Map[event.id] = {
            ...event,
            // 值拷贝一份到events_value
            events_value: {
              ...event,
            },
          };
        });
      }
      return {
        id: item.id, // id
        label: item.name.length > 6 ? `${item.name.substr(0, 6)}...` : item.name, // 省略后的节点名
        fullname: item.name || '', // 节点全名
        description: item.description || '', // 节点备注
        type: newType,
        x: item.point.x,
        y: item.point.y,
        comboId: extraData?.metadata?.comboInfo?.id, // 节点组合id
        style: {
          width: item.size[0] || 80,
          height: item.size[1] || 100,
          r: (item.size[0] || item.size[1] || 80) / 2,
          fill: getValue(nodeTypes[newType], '.defaultStyle.fill') || '#fff',
        },
        stateStyles: {
          // 选中时的颜色
          'nodeState:selected': _.get(nodeTypes[newType], 'stateStyles["nodeState:selected"]', {}),
          'nodeState:default': {
            fill: _.get(nodeTypes[newType], 'defaultStyle.fill', '#fff'),
          },
        },
        labelCfg: nodeTypes[newType]?.defaultStyle?.labelCfg,
        ...extraData,
      };
    });
    store.dispatch('workflow/requestModelAssignersInfo', selectdAssigneers);
    console.log('events2Map===', events2Map);
    Object.keys(events2Map).forEach((eventId) => {
      let boundaryEventNode = nodes.find((node) => node.id === eventId);
      if (boundaryEventNode) {
        boundaryEventNode = {
          ...boundaryEventNode,
          ...events2Map[eventId],
        };
        const index = nodes.findIndex((node) => node.id === eventId);
        nodes[index] = boundaryEventNode;
      }
    });
    console.log('ndoes===', nodes);
    // 连线
    edges = oldEdges.map((item) => {
      console.log('item===', item);
      const { sourceAnchor, targetAnchor, controlPoints, label, labelCfg } = item;
      const newEdges = {
        ...item,
        id: item.id, // id
        label: label || item.name || '', // 节点名
        labelCfg,
        type: 'polyline-edge',
        source: item.source, // 线段起始节点
        target: item.target,
        startPoint: {
          x: item.line_points[0].x,
          y: item.line_points[0].y,
          // anchorIndex: item.line_points[0].anchorIndex,
          // id: item.line_points[0].id,
        },
        endPoint: {
          x: item.line_points[1].x,
          y: item.line_points[1].y,
          // anchorIndex: item.line_points[1].anchorIndex,
          // id: item.line_points[1].id,
        },
        controlPoints,
      };
      // 起始节点瞄点
      newEdges.sourceAnchor = sourceAnchor;
      // 终点节点瞄点
      newEdges.targetAnchor = targetAnchor;
      return newEdges;
    });
    // 组合的所有节点都在画布中 且不能只有一个孩子
    combos = combos.filter((combo) => {
      const comboChlidren = combo.children || [];
      let falg = true;
      comboChlidren.forEach((child) => {
        if (!nodes.find((node) => node.id === child.id)) {
          falg = false;
        }
      });
      return falg && comboChlidren.length > 1;
    });
  } catch (err) {
    console.log(err);
    Message.error(err);
    return false;
  }
  // 含有未知节点停止导入
  if (unknownNodes.length) {
    unknownNodes.forEach((type) => {
      setTimeout(() => {
        Message.error(`存在未知节点类型: ${type}`);
      }, 10);
    });
    return false;
  }
  return {
    nodes,
    edges,
    combos,
  };
}
// 导出数据
export function exportData(editjson) {
  console.log('exportDatav');
  const { nodes: oldNodes, edges: oldEdges, combos } = editjson;
  const events2Map = {};
  // 节点
  let nodes = oldNodes.map((item) => {
    const { type, subref } = item;
    // 配置的属性  subref  outputs  inputs...
    // 条件节点处理 "type": "exclusive_gateway", => "type": "gateway"
    let computedType = '';
    if (subref) {
      computedType = nodeTypes[type.replace(`_${subref}`, '')]
        ? type.replace(`_${subref}`, '')
        : type;
    }
    const extraData = {};
    Object.keys(nodeTypes[type].default_config || {}).forEach((key) => {
      extraData[key] = item[key] || nodeTypes[type].default_config[key];
    });
    let comboInfo;
    if (item.comboId) {
      comboInfo = combos.find((combo) => combo.id === item.comboId);
      if (Object.prototype.toString.call(extraData.metadata) === '[object Object]') {
        extraData.metadata = comboInfo
          ? {
              ...extraData.metadata,
              comboInfo,
              comboId: item.comboId,
            }
          : { ...extraData.metadata, comboInfo: undefined, comboId: undefined };
        if (!comboInfo) {
          extraData.events2 = [];
        }
        if (eventNodeTypes.includes(item.type) && comboInfo) {
          events2Map[item.id] = {
            id: item.id,
            ...extraData.events_value,
            inputs: extraData.inputs,
            outputs: extraData.outputs,
          };
        }
      }
    }

    return {
      id: item.id, // id
      name: item.fullname || '', // 节点全名
      description: item.description || '',
      type,
      computedType,
      node_type: nodeTypes[type].id, // 节点类型id
      label: nodeTypes[type].name, // 节点名
      labelCfg: nodeTypes[type].defaultStyle?.labelCfg,
      // 节点坐标
      point: {
        x: item.x,
        y: item.y,
      },
      size: [item.style.width, item.style.height],
      ...extraData,
    };
  });
  // 组合
  combos.forEach((combo) => {
    const childrenNodes = combo.children;
    const eventNodesId = [];
    const normalNodes = [];
    childrenNodes.forEach((node) => {
      // 当前model
      const nodeModel = nodes.find((basicNode) => basicNode.id === node.id);
      if (eventNodeTypes.includes(nodeModel.type)) {
        eventNodesId.push(node.id);
      } else {
        normalNodes.push(nodeModel);
      }
    });
    const events2 = eventNodesId.map((eventNodeId) => {
      return events2Map[eventNodeId];
    });
    normalNodes.forEach((normalNode) => (normalNode.events2 = events2));
  });
  // 连线
  const edges = oldEdges.map((item) => {
    const { sourceAnchor, targetAnchor, controlPoints, label, labelCfg } = item;
    const newEdges = {
      ...item,
      id: item.id, // id
      name: item.label || '', // 节点名
      // type: item.type,
      source: item.source, // 线段起始节点
      target: item.target,
      line_points: [
        // 线段连线点坐标，从起始点到终点
        {
          x: item.startPoint.x,
          y: item.startPoint.y,
          //   anchorIndex: item.startPoint.anchorIndex,
          //   id: item.startPoint.id,
        },
        {
          x: item.endPoint.x,
          y: item.endPoint.y,
          //   anchorIndex: item.endPoint.anchorIndex,
          //   id: item.endPoint.id,
        },
      ],
      controlPoints,
      label,
      labelCfg,
    };
    // eslint-disable-next-line no-prototype-builtins

    // 起始节点瞄点
    newEdges.sourceAnchor = sourceAnchor;
    // 终点节点瞄点
    newEdges.targetAnchor = targetAnchor;
    return newEdges;
  });
  nodes = nodes.map((item) => ({
    ...item,
    type: item.computedType || item.type,
    computedType: undefined,
  }));
  return {
    nodes,
    edges,
  };
}

function deepCopyDefaultConfig(target, newTarget) {
  if (!target) return newTarget;
  if (
    typeof target !== 'object' ||
    target === null ||
    target instanceof Date ||
    target instanceof RegExp
  )
    return target;

  const cloneTarget = Array.isArray(target) ? [] : {};
  if (Array.isArray(target) && target.length) {
    return target;
  }
  Object.keys(newTarget).forEach(
    (key) =>
      (cloneTarget[key] =
        newTarget[key] instanceof Object
          ? deepCopyDefaultConfig(target[key], newTarget[key])
          : target[key]
          ? target[key]
          : newTarget[key]),
  );

  // 返回深拷贝后的对象
  return cloneTarget;
}
