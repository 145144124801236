export default {
  env: 'test',
  domain: ['ngestest.testsite.woa.com', 'iamparistest.testsite.woa.com', 'testadmin.nges.qq.com'],
  mobileDomain: 'v2testh5.tsepcn.com',
  aegisId: 'akNqIsanJXJnMpAhyv',
  reportDomain: 'baike.sparta.html5.qq.com',
  cosAppId: 'nges',
  bucket: 'tsep-test-1304685099',
  region: 'ap-guangzhou',
  sign: '测试',
};
