// 获取会议类型
export const getMeetingTypeListGql = `query{events_meeting_type(_order_by:{update_time:_desc}){type,name}}`;
// 获取会议合规集合
export const getEventsComplianceInfoListGql = `query{$tableName (_limit:$limit,_offset:$offset,_order_by:{create_time:_desc}$filter){_aggregate{_count},id,name,meeting_type_id,identity_tag,description,status}}`;
// 新增会议合规集合
export const addComplianceGql = 'mutation insert{$tableName (_values:{fieldList}) ';
// 新增会议合规规则明细
export const addRestRuleGql = '{events_compliance_rule (_values:[fieldList])}}';
// 编辑会议合规规则明细
export const updateRestRuleGql = '{events_compliance_rule (_values:[fieldList])}}';
// 编辑会议合规集合
export const updateComplianceGql =
  'mutation insert{$tableName (_values:{fieldList}, _on_conflict: {_columns: [meeting_type_id], _update_all:true}) ';
// 编辑会议合规集合状态
export const updateComplianceStatusGql =
  'mutation insert{$tableName (_values:{fieldList}, _on_conflict: {_columns: [meeting_type_id], _update_all:true})} ';
// 删除会议合规集合
export const deleteComplianceGql = 'mutation delete {$tableName (id: $id)}';
