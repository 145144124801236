<template>
  <div>
    <div @click="openJsonEdit">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        :value="value"
        @change="(e) => $emit('update', e)"
        disabled
      ></el-input>
      <div class="tips">(点击进入编辑)</div>
    </div>
    <json-edit
      v-if="showJson"
      :show-json="showJson"
      type="diff"
      :json="value"
      :old-json="oldJson"
      @save="
        (e) => {
          $emit('update', e);
          showJson = false;
        }
      "
      @cancel="showJson = false"
    />
  </div>
</template>

<script>
import jsonEdit from '@/components/jsonEdit/index.vue';

export default {
  name: 'InputJson',
  components: { jsonEdit },
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showJson: false,
      oldJson: '',
    };
  },
  methods: {
    openJsonEdit() {
      this.oldJson = this.value;
      this.$nextTick(() => {
        this.showJson = true;
      });
    },
  },
};
</script>
