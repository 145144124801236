import { request, api } from '@/api';

let actionList = null;

// 所有的eventTransfer共用一个数据源 只请求一次
export function getActionList() {
  if (actionList) return actionList;
  actionList = new Promise((resolve, reject) => {
    request({
      url: api.getProcessActionList,
      data: {
        page: 0,
        size: -1,
      },
      cb: (res) => resolve(res),
      fail: (err) => reject(err),
    });
  });
  // 由于这个组件经常同时出现多个 五秒缓存防止频繁请求
  setTimeout(() => {
    actionList = null;
  }, 5000);
  return actionList;
}
