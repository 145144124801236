import {
  getAuditLogListGql,
  insertRuleGql,
  getRuleGql,
  deleteRuleGql,
  updateRuleGql,
} from '@/graphql/gql/audit_log';
import { calVariables, request } from '@/graphql/util';

export async function getAuditLogList(data) {
  const result = await request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(getAuditLogListGql, data),
  });
  return result;
}

export async function insertRule(data) {
  const result = await request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(insertRuleGql, data),
  });
  return result;
}

export async function getRule(data) {
  const result = await request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(getRuleGql, data),
  });
  return result;
}
export async function deleteRule(data) {
  const result = await request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(deleteRuleGql, data),
  });
  return result;
}
export async function updateRule(data) {
  const result = await request('/api/access/pb/cmd/DataServer/DataService/GraphqlQuery', {
    query: calVariables(updateRuleGql, data),
  });
  return result;
}
