<template>
  <div>
    <el-tabs style="background-color: #fff" v-model="editableTabsValue" type="border-card">
      <el-tab-pane
        :key="item.name"
        v-for="(item, index) in editableTabs"
        :label="item.title"
        :name="item.name"
      >
        <el-collapse v-model="item.activeNames">
          <el-collapse-item name="1">
            <span class="title" slot="title">
              <div class="text">通用</div>
              <div>
                <el-button
                  v-if="item.commonDisable"
                  @click.stop="handleCommonEdit(index, 'edit')"
                  icon="el-icon-edit"
                  type="info"
                  plain
                  size="mini"
                >
                  编辑
                </el-button>
              </div>
            </span>
            <el-input
              :disabled="item.commonDisable"
              type="textarea"
              :rows="15"
              :placeholder="placeholderText"
              v-model="item.commonContent"
            ></el-input>
            <div style="margin-top: 10px" v-if="!item.commonDisable">
              <el-button @click.stop="handleCommonEdit(index, 'save')" type="primary">
                保存
              </el-button>
              <el-button @click.stop="handleCommonCancel(index)">取消</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <span class="title" slot="title">
              <div class="text">个性化</div>
              <div>
                <el-button
                  v-if="item.personalDisable"
                  @click.stop="handlePersonalEdit(index, 'edit')"
                  icon="el-icon-edit"
                  size="mini"
                  type="info"
                  plain
                >
                  编辑
                </el-button>
              </div>
            </span>
            <el-input
              :disabled="item.personalDisable"
              type="textarea"
              :placeholder="placeholderText"
              v-model="item.personalContent"
              :rows="15"
            ></el-input>
            <div style="margin-top: 10px" v-if="!item.personalDisable">
              <el-button @click.stop="handlePersonalEdit(index, 'save')" type="primary">
                保存
              </el-button>
              <el-button @click.stop="handlePersonalCancel(index)">取消</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
      <el-tab-pane name="add" disabled>
        <span slot="label"><el-button type="text" @click="handleTabsAdd">+ 添加</el-button></span>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="添加语言" :visible.sync="dialogVisible">
      <el-select
        style="width: 100%"
        v-model="addValue"
        clearable
        filterable
        multiple
        placeholder="请选择"
      >
        <el-option
          v-for="item in optionsItem"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSelectData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { I18N_LANG_SELECT } from '@/utils/constant';
export default {
  data() {
    return {
      dialogVisible: false, // 添加弹窗开关
      activeNames: ['1', '2'],
      originData: [], // 接口返回值
      textarea: '',
      placeholderText: `可编辑语料内容
例：
field.customer.code=客户编码
其中等号左侧为国际化i18n的查询关键词，右侧为对应语料内容。`,
      editableTabsValue: '2',
      editableTabs: [],
      tabIndex: 2,
      options: [],
      addValue: [],
      optionsItem: [],
    };
  },
  async mounted() {
    await this.initData();
    const arr = this.originData;
    const ans = [];
    if (Array.isArray(arr) && arr.length) {
      arr.forEach((item) => {
        const tmp = {};
        tmp.title = `${I18N_LANG_SELECT[item.lang_type] || ''} ( ${item.lang_type} )`;
        tmp.name = item.lang_type;
        tmp.commonDisable = true;
        tmp.personalDisable = true;
        tmp.commonContent = item.common_text;
        tmp.personalContent = item.personalise_text;
        tmp.activeNames = ['1', '2'];
        ans.push(tmp);
      });
      this.editableTabs = ans;
      this.editableTabsValue = ans[0].name;
    }
    this.initOptions();
  },
  methods: {
    async initData() {
      const request = await this.$request({
        url: this.$api.getInternationConfigList,
        data: {},
      });
      const arr = request.internation_base_info || [];
      this.originData = arr;
    },

    initOptions() {
      const tmp = [];
      Object.keys(I18N_LANG_SELECT).forEach((item) => {
        tmp.push({
          label: `${I18N_LANG_SELECT[item]} ( ${item} )`,
          value: item,
        });
      });
      this.options = tmp;
      this.optionsItem = tmp;
    },

    handleTabsAdd() {
      this.optionsItem = this.options.filter((item) => {
        for (const tmp of this.editableTabs) {
          if (item.value === tmp.name) return false;
        }
        return true;
      }); // 过滤已有选项
      this.dialogVisible = true;
      this.addValue = [];
    },

    async handleCommonEdit(index, type) {
      if (type === 'save') {
        await this.handleSaveData(index);
      }
      this.$set(this.editableTabs[index], 'commonDisable', !this.editableTabs[index].commonDisable);
    },

    async handlePersonalEdit(index, type) {
      if (type === 'save') {
        await this.handleSaveData(index);
      }
      this.$set(
        this.editableTabs[index],
        'personalDisable',
        !this.editableTabs[index].personalDisable,
      );
    },

    async handleSelectData() {
      this.dialogVisible = false;
      try {
        await Promise.all(
          this.addValue.map(async (item) => {
            await this.handleAddData(item);
            this.editableTabs.push({
              title: `${I18N_LANG_SELECT[item] || ''} ( ${item} )`,
              name: item,
              commonContent: '',
              commonDisable: true,
              personalContent: '',
              personalDisable: true,
              activeNames: ['1', '2'],
            });

            this.editableTabsValue = item;
          }),
        );
        this.$message.success('添加成功');
      } catch (e) {
        console.log(e);
      }
    },

    handleCommonCancel(index) {
      this.$set(this.editableTabs[index], 'commonDisable', true);
      this.$set(this.editableTabs[index], 'commonContent', this.originData[index].common_text);
    },

    handlePersonalCancel(index) {
      this.$set(this.editableTabs[index], 'personalDisable', true);
      this.$set(
        this.editableTabs[index],
        'personalContent',
        this.originData[index].personalise_text,
      );
    },

    // 添加调用
    async handleAddData(item) {
      try {
        await this.$request({
          url: this.$api.updateInternationConfigInfo,
          data: {
            lang_type: item,
          },
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    // 保存调用
    async handleSaveData(index) {
      try {
        await this.$request({
          url: this.$api.updateInternationConfigInfo,
          data: {
            lang_type: this.editableTabs[index].name,
            common_text: this.editableTabs[index].commonContent,
            personalise_text: this.editableTabs[index].personalContent,
          },
        });
        console.log(this.editableTabs[index]);
        this.$message.success('保存成功');
        await this.initData();
      } catch (e) {
        console.log(e);
        this.$message.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #1c2028;
  }
}
</style>
