// 多级对象合并
export function merge({ target = {}, sources = {} }) {
  const obj = JSON.parse(JSON.stringify(target));
  if (typeof target !== 'object' || typeof sources !== 'object') {
    return sources; // 如果其中一个不是对象 就返回sources
  }
  for (const key in sources) {
    // 如果target也存在 那就再次合并
    // eslint-disable-next-line no-prototype-builtins
    if (target.hasOwnProperty(key)) {
      obj[key] = merge({
        target: target[key],
        sources: sources[key],
      });
    } else {
      // 不存在就直接添加
      obj[key] = sources[key];
    }
  }
  return obj;
}

export const setValue = ({ obj = {}, keys = [], value }) => {
  const copy = JSON.parse(JSON.stringify(obj));
  if (keys.length > 1) {
    const copKeys = JSON.parse(JSON.stringify(keys));
    copKeys.shift();
    copy[keys[0]] = setValue({ obj: {}, keys: copKeys, value });
  } else {
    copy[keys[0]] = value;
  }
  return copy;
};

// 获取对象数据
export const getTree = ({
  data = [],
  tier = '',
  getkeys = () => [],
  getItem = ({ item, tier }) => {
    return [
      {
        ...item,
        tier,
      },
    ];
  },
}) => {
  let obj = {};
  data.forEach((item) => {
    obj = merge({
      target: obj,
      sources: setValue({
        obj: {},
        keys: getkeys(item),
        value: getItem({ item, tier }),
      }),
    });
  });
  return obj;
};

// 根据对象树获取树形节点
export const getTreeNode = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).map((label) => {
    const value = obj[label];
    if (Array.isArray(value)) {
      return {
        label,
        children: value,
      };
    }
    return {
      label,
      children: getTreeNode(value),
    };
  });
};
