<template>
  <el-card class="table-container">
    <div class="top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchObj"
        class="searchForm"
        @submit.native.prevent
        @keyup.enter.native="searchList"
      >
        <el-form-item label="选择产品/应用：" prop="appid">
          <el-select v-model="searchObj.appid" placeholder="应用" @change="searchList">
            <el-option
              v-for="item in appOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="keyword">
          <el-input
            v-model="searchObj.keyword"
            clearable
            placeholder="关键词搜索"
            @clear="searchList"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchList">查 询</el-button>
          <el-button @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button
          class="add-button"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd()"
          v-if="jurisdiction.objectManage"
        >
          添加
        </el-button>
      </div>
    </div>

    <el-table :data="listData" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="name" label="表中文名" min-width="120"></el-table-column>
      <el-table-column prop="code" label="表名" min-width="120"></el-table-column>
      <!-- <el-table-column prop="appid" label="应用" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.appid | name(appOptions) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="description" label="描述" min-width="140">
        <template slot-scope="scope">
          <span>{{ scope.row.description | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="access_type" label="访问控制">
        <template slot-scope="scope">
          {{
            accessTypeMap[scope.row.access_type === 0 ? 1 : scope.row.access_type] ||
            scope.row.access_type
          }}
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        :width="jurisdiction.objectManage ? '310' : '200'"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="openRelevance(scope.row)">
            关联的角色
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            v-if="jurisdiction.objectManage"
          >
            编辑
          </el-button>
          <el-button size="mini" type="text" @click="goToFieldPage(scope.$index, scope.row)">
            字段
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
            v-if="jurisdiction.objectManage"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      background
      hide-on-single-page
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 编辑弹窗 -->
    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>

        <el-form-item label="数据表名" prop="name">
          <el-input v-model="formObj.name"></el-input>
        </el-form-item>
        <el-form-item label="Code" prop="code">
          <el-input v-model="formObj.code" :disabled="operation == 'update'"></el-input>
        </el-form-item>
        <el-form-item label="访问控制" prop="access_type">
          <el-select v-model="formObj.access_type" placeholder="请选择">
            <el-option
              v-for="item in accesstypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="formObj.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 关联的角色 -->
    <role
      :show="relevanceRole.visible"
      :object-info="relevanceRole.objectInfo"
      @cancel="relevanceRole.visible = false"
    />
  </el-card>
</template>

<script>
import role from './role.vue';
import { mapGetters } from 'vuex';
export default {
  components: { role },
  data() {
    const checkCode = (rule, value, callback) => {
      const reg = /^[A-Za-z-_0-9]{2,50}$/;
      if (!reg.test(value)) {
        return callback(new Error('只能是大小写英文字母、数字以及-_字符，长度为2到50'));
      }
      callback();
    };

    return {
      operation: 'create',
      listData: [],
      count: 0,
      formObj: {},
      appOptions: [],
      oldSearchObj: {},
      searchObj: {
        keyword: this.$route.query.keyword || '',
        appid: Number(this.$route.query.appid) || 0,
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      accesstypeOptions: [
        { label: '私有', value: 1 },
        { label: '公开读/私有写', value: 2 },
        { label: '公开读写', value: 3 },
      ],
      rules: {
        name: [
          {
            required: true,
            message: '请输入数据表名称',
            trigger: 'blur',
          },
        ],
        appid: [
          {
            required: true,
            message: '请选择应用名称',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入数据表Code',
            trigger: 'blur',
          },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      opDialogVisible: false,
      roleDialogVisible: false,
      submitDisable: false,
      // 关联的角色弹窗
      relevanceRole: {
        visible: false,
        objectInfo: {},
      },
    };
  },
  computed: {
    accessTypeMap() {
      const obj = {};
      this.accesstypeOptions.forEach((item) => {
        obj[item.value] = item.label;
      });
      return obj;
    },
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        objectManage: this.getElementList.includes('object-manage'),
      };
    },
  },
  async mounted() {
    if (!this.searchObj.appid) {
      this.searchObj.appid = Number(sessionStorage.getItem('app_id'));
    } else {
      sessionStorage.setItem('app_id', this.searchObj.appid);
    }
    await this.loadAppOption();
    if (this.searchObj.appid) {
      this.loadListData();
    }
  },
  methods: {
    searchList() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
        this.searchObj.page = 1;
      }
      this.oldSearchObj = Object.assign({}, this.searchObj);
      this.timer = setTimeout(() => {
        this.$router.push({ query: this.searchObj });
        sessionStorage.setItem('app_id', this.searchObj.appid);
        this.loadListData();
      }, 300);
    },
    resetForm() {
      this.searchObj.keyword = '';
      this.searchList();
    },
    loadListData() {
      const url = this.$api.getObjectList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.listData = data.data;
      };
      this.$request({ url, data, cb });
    },
    async loadAppOption() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const res = await this.$request({ url, data });
      this.appOptions = res.data;
      this.searchObj.appid = this.searchObj.appid || this.appOptions[0].id;
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateObject : this.$api.createObject;
        const msg = id ? '修改成功' : '创建成功';
        let data = Object.assign({}, this.formObj);
        if (id) {
          data = {
            id,
            name: this.formObj.name,
            code: this.formObj.code,
            access_type: this.formObj.access_type,
            description: this.formObj.description,
          };
        }
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteObject;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {
        appid: this.searchObj.appid,
        access_type: 1,
      };
      this.opDialogVisible = true;
    },
    openRelevance(objectInfo) {
      this.relevanceRole.objectInfo = objectInfo;
      this.$nextTick(() => {
        this.relevanceRole.visible = true;
      });
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      // 是0的话，自动赋值1
      if (`${data.access_type}` === '0') {
        this.formObj.access_type = 1;
      }
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该数据表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    goToFieldPage(index, data) {
      this.$router.push({
        name: 'system_object_field',
        query: {
          object_id: data.id,
        },
      });
    },
    handleCurrentChange(val) {
      this.searchObj.page = val;
      this.searchList();
    },
    handleSizeChange(val) {
      this.searchObj.page = 1;
      this.searchObj.page_size = val;
      this.searchList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.top {
  display: flex;
  margin-bottom: 10px;
  .searchForm {
    flex: 1;
  }
}
</style>
