<template>
  <div>
    <el-row class="line" v-for="(item, index) in value" :key="index">
      <el-col :span="2">
        <span v-if="index > 0">&&</span>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="7">
        <el-select
          :value="item.type"
          :class="{ true: !!item.type }"
          @input="
            (val) => {
              changeType(val, index);
            }
          "
          :disabled="type === 'view'"
          placeholder="请选择"
        >
          <el-option v-for="(val, key) in types" :key="key" :label="val" :value="key" />
        </el-select>
      </el-col>
      <el-col :span="5">
        <el-select
          :value="item.operator"
          :class="{ true: !!item.operator }"
          @input="
            (val) => {
              changeOperator(val, index);
            }
          "
          :disabled="type === 'view'"
          placeholder="请选择"
        >
          <el-option v-for="(val, key) in operators" :key="key" :label="val" :value="key" />
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-input
          :value="item.val"
          :class="{ true: !!item.val }"
          @input="
            (val) => {
              changeVal(val, index);
            }
          "
          :disabled="type === 'view'"
          placeholder="请输入内容"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          @click="del(index)"
          v-if="index > 0 && type === 'edit'"
        />
        <span v-else>&nbsp;</span>
      </el-col>
    </el-row>
    <div class="btn">
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="add"
        v-if="type === 'edit'"
      />
      <el-button type="primary" icon="el-icon-edit" @click="edit" v-if="type === 'view'" />
      <el-tooltip
        class="item"
        effect="dark"
        content="删除整条规则"
        placement="right-start"
        v-if="ruleLength > 1 && type === 'view'"
      >
        <el-button type="danger" icon="el-icon-delete" @click="delRule()" v-if="ruleLength > 1" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'view',
    },
    value: {
      type: Array,
      default: () => [],
    },
    ruleLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      types: {
        package: '包名',
        service: '服务名',
        method: '方法名',
        platform: '客户端类型',
      },
      operators: {
        in: '等于',
        contain: '包含',
      },
    };
  },
  methods: {
    changeType(val, index) {
      const arr = JSON.parse(JSON.stringify(this.value));
      arr[index].type = val;
      this.$emit('input', arr);
    },
    changeOperator(val, index) {
      const arr = JSON.parse(JSON.stringify(this.value));
      arr[index].operator = val;
      this.$emit('input', arr);
    },
    changeVal(val, index) {
      const arr = JSON.parse(JSON.stringify(this.value));
      arr[index].val = val;
      this.$emit('input', arr);
    },
    del(index) {
      const arr = JSON.parse(JSON.stringify(this.value));
      arr.splice(index, 1);
      this.$emit('input', arr);
    },
    add() {
      const arr = JSON.parse(JSON.stringify(this.value));
      this.$emit('input', [
        ...arr,
        {
          type: '',
          operator: '',
          val: '',
        },
      ]);
    },
    edit() {
      this.$emit('edit');
    },
    delRule() {
      this.$emit('del');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin: 7px 0 0;
  display: flex;
  justify-content: space-around;
}
.line {
  margin-bottom: 10px;
}
.el-form-item.is-error {
  .true {
    ::v-deep .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
  .true {
    ::v-deep .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>
