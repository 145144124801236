<template>
  <div>
    <el-tabs v-model="eventConfigCondition">
      <el-tab-pane label="审批同意" name="pass"></el-tab-pane>
      <el-tab-pane label="审批拒绝" name="reject"></el-tab-pane>
      <el-tab-pane label="审批退回" name="rollback"></el-tab-pane>
      <el-tab-pane label="申请人撤回" name="revoke"></el-tab-pane>
    </el-tabs>
    <div class="config-content">
      <div class="config-action">
        <el-button @click="addEventVisible = true" type="info" class="primary">新建事件</el-button>
      </div>
      <ActionList :action-list="actionList" @delete-item="deleteAction" />
    </div>
    <AddEvent
      :visible="addEventVisible"
      :selected="actionList"
      @close="addEventVisible = false"
      @success="addEvent"
    />
  </div>
</template>

<script>
import ActionList from './actionList';
import AddEvent from './addEvent';
export default {
  name: 'AdvanceConfigEndEvent',
  components: { ActionList, AddEvent },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 读取actions 分类生成事件列表
      actions: {
        ended: {
          pass: [],
          reject: [],
          rollback: [],
          revoke: [],
        },
      },
      // 高级设置中选中的一级与二级tab
      eventConfigOn: 'ended',
      eventConfigCondition: 'pass',
      // 用于存储高级设置编辑未保存时的临时数据
      eventConfigEditTemp: [],
      // 事件列表数据源
      eventList: [],
      // 添加事件窗口展示
      addEventVisible: false,
    };
  },
  computed: {
    // 当前展示的action列表
    actionList() {
      return this.eventList.filter((i) =>
        this.actions[this.eventConfigOn][this.eventConfigCondition].includes(i.id),
      );
    },
  },
  watch: {
    // 每次value更新都清空数据再重装 保证和value一致
    value(newValue) {
      Object.keys(this.actions).forEach((key) => {
        Object.keys(this.actions[key]).forEach((key2) => {
          this.actions[key][key2] = [];
        });
      });
      newValue.forEach((action) => {
        if (this.actions[action.on][action.condition]) {
          this.actions[action.on][action.condition].push(action.action_id);
        }
      });
    },
  },
  created() {
    this.$request({
      url: this.$api.getProcessActionList,
      data: {
        page: 0,
        size: -1,
      },
      cb: (res) => {
        this.eventList = res.list;
        this.value.forEach((action) => {
          if (this.actions[action.on][action.condition]) {
            this.actions[action.on][action.condition].push(action.action_id);
          }
        });
      },
    });
  },
  methods: {
    addEvent(eventList) {
      this.actions[this.eventConfigOn][this.eventConfigCondition] = eventList;
      this.$emit('update', this.getNewValue());
      this.addEventVisible = false;
    },
    deleteAction(id) {
      const index = this.actions[this.eventConfigOn][this.eventConfigCondition].findIndex(
        (i) => i === id,
      );
      this.actions[this.eventConfigOn][this.eventConfigCondition].splice(index, 1);
      this.$emit('update', this.getNewValue());
    },
    getNewValue() {
      const actionList = [];
      Object.keys(this.actions).forEach((key) => {
        Object.keys(this.actions[key]).forEach((key2) => {
          actionList.push(
            ...this.actions[key][key2].map((i) => {
              return {
                on: key,
                condition: key2,
                action_id: i,
              };
            }),
          );
        });
      });
      return actionList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/tab.scss';

.config-content {
  margin: 16px;
  .config-action {
    margin-bottom: 16px;
  }
}
</style>
