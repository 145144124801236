<template>
  <div>
    <div class="node-title">节点ID</div>
    <el-input disabled :value="value.id" />
    <div class="node-title">
      节点名称
      <span class="require">*</span>
    </div>
    <el-input
      :value="value.fullname"
      maxlength="20"
      show-word-limit
      @input="(e) => valueUpdate('fullname', e)"
    />
    <div class="node-title">备注描述</div>
    <el-input
      type="textarea"
      :rows="2"
      :value="value.description"
      @input="(e) => valueUpdate('description', e)"
      maxlength="50"
      autocomplete="off"
      placeholder="请输入"
    ></el-input>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},

  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
@import '@/styles/element/button.scss';

.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-inputbar {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f6f7fb;
}
.intro-title {
  height: 40px;
  line-height: 40px;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 14px;
  color: #667082;
  margin: 10px -10px 0;
  padding-left: 10px;
}
</style>
