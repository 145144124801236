<template>
  <!-- 创建账号弹窗 -->
  <el-dialog
    v-loading="loading"
    :title="title"
    :visible.sync="visible"
    width="800px"
    :before-close="close"
    :close-on-click-modal="false"
    center
  >
    <el-form :model="form" ref="form" label-width="130px" v-loading="loading" :rules="rules">
      <el-form-item label="uin:" v-if="type === 'view'">
        {{ form.uin }}
      </el-form-item>
      <el-form-item label="用户名:" prop="name">
        <span v-if="type === 'view'">{{ form.name }}</span>
        <el-input v-else v-model="form.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="账号:" prop="account">
        <span v-if="['view', 'update'].includes(type)">{{ form.account }}</span>
        <el-input v-else v-model="form.account" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="密码:" prop="password" v-if="type === 'create'">
        <el-input v-model="form.password" placeholder="请输入" show-password />
      </el-form-item>
      <el-form-item label="类型:" prop="type">
        <span v-if="['view', 'update'].includes(type)">
          {{ typeOptions[form.type] || form.type }}
        </span>
        <el-select v-else v-model="form.type" placeholder="请选择">
          <el-option v-for="(label, val) in typeOptions" :key="val" :label="label" :value="val" />
        </el-select>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <span v-if="['view'].includes(type)">{{ form.phone }}</span>
        <el-input v-else v-model="form.phone" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="邮箱:" prop="email">
        <span v-if="type === 'view'">{{ form.email }}</span>
        <el-input v-else v-model="form.email" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="微信unionid:" prop="unionid">
        <span v-if="type === 'view'">{{ form.unionid }}</span>
        <el-input v-else v-model="form.unionid" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="介绍:" prop="introduce">
        <span v-if="type === 'view'">{{ form.introduce }}</span>
        <el-input v-else v-model="form.introduce" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="状态:" v-if="type === 'view'">
        {{ statusOptions[form.status] }}
      </el-form-item>
      <el-form-item label="应用信息:" prop="app_infos">
        <el-table v-if="type === 'view'" :data="form.app_infos">
          <el-table-column prop="appid" label="AppID" />
          <el-table-column prop="openid" label="Openid" />
          <el-table-column prop="type" label="Type">
            <template slot-scope="scope">
              {{ scope.row.type | getType }}
            </template>
          </el-table-column>
          <el-table-column prop="userid" label="UserId" />
        </el-table>
        <template v-else>
          <el-table :data="form.app_infos" stripe>
            <el-table-column prop="appid" label="AppID">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.appid"
                  :class="{ noerr: scope.row.appid !== '' }"
                  placeholder="请输入"
                />
              </template>
            </el-table-column>
            <el-table-column prop="openid" label="Openid">
              <template slot-scope="scope">
                <el-input v-model="scope.row.openid" class="noerr" placeholder="请输入" />
              </template>
            </el-table-column>
            <el-table-column prop="type" label="Type">
              <template slot-scope="scope">
                <el-select v-model="scope.row.type" class="noerr" placeholder="请选择">
                  <el-option
                    v-for="(label, val) in typeList"
                    :key="val"
                    :label="label"
                    :value="Number(val)"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="userid" label="UserId">
              <template slot-scope="scope">
                <el-input v-model="scope.row.userid" class="noerr" placeholder="请输入" />
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  :disabled="!scope.row.hasOwnProperty('haveDel')"
                  @click="delApp(scope.$index)"
                />
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addApp" />
          </div>
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{ type === 'view' ? '确 定' : '取 消' }}</el-button>
      <el-button type="primary" v-if="type !== 'view'" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { validatePass, typeOptions, statusOptions, validatePhone } from './common';
const defaultInfo = {
  name: '',
  account: '',
  password: '',
  phone: '',
  email: '',
  introduce: '',
  type: '',
  unionid: '',
  status: '',
  app_infos: [],
};
const typeList = { 1: '订阅号', 2: '服务号', 3: '小程序', 4: '腾会应用', 5: '企业微信' };
export default {
  filters: {
    getType(value) {
      return typeList[value] || value;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'view', // view: 查看 ,create: 创建, update: 更新
    },
    uin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusOptions,
      typeList,
      loading: false,
      form: JSON.parse(JSON.stringify(defaultInfo)),
      // 类型
      typeOptions,
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: ['blur', 'change'] },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        type: [{ required: true, message: '请选择账号类型', trigger: 'blur' }],
        password: [
          {
            validator: validatePass,
            trigger: 'change',
          },
        ],
        app_infos: [
          {
            validator: (rule, value, callback) => {
              if (
                value.some((item) => {
                  return item.appid === '';
                })
              ) {
                callback(new Error('请填写完整信息'));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.$emit('cancel', false);
        }
      },
    },
    title() {
      switch (this.type) {
        case 'view':
          return '账号详情';
        case 'create':
          return '创建账号';
        case 'update':
          return '更新账号信息';
        default:
          return '账号详情';
      }
    },
  },
  created() {
    if (['view', 'update'].includes(this.type)) {
      this.getDetail();
    }
  },
  methods: {
    async getDetail() {
      this.loading = true;
      try {
        const res = await this.$request({
          url: this.$api.AccountGetUserInfo,
          data: { uin: this.uin },
        });
        this.form = res;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    addApp() {
      this.form.app_infos.push({
        appid: '',
        openid: '',
        type: '',
        userid: '',
        haveDel: undefined,
      });
    },
    delApp(index) {
      this.form.app_infos.splice(index, 1);
    },
    close() {
      this.form = JSON.parse(JSON.stringify(defaultInfo));
      this.$refs.form.resetFields();
      this.visible = false;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const res = await this.$request({
              url: this.$api.UpdateLoginAccount,
              data: {
                ...this.form,
                uin: undefined,
                create_state: this.type === 'create' ? 1 : 2, // 1-新建 2-修改
              },
            });
            res?.msg && this.$message.error(res.msg);
            this.$emit('refresh');
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item.is-error {
  .noerr .el-input__inner {
    border-color: #dcdfe6;
  }
}
</style>
