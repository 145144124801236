<template>
  <div class="login-wrapper">
    <div class="login-bg">
      <div class="left">
        <div class="logo">
          <img
            class="img"
            src="https://baike-med-1256891581.file.myqcloud.com/2022070/0e294310-0bfd-11ed-89ce-4ff3ab5f0cf9_0.png"
          />
        </div>
        <div class="copyright">
          Copyright©️2022 Tencent Inc. All Rights Reserved 腾讯公司 版权所有
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="login-area">
      <account :redirect-url="redirectUrl" :storage="storage" :from-url="fromUrl" />
    </div>
  </div>
</template>
<script>
import account from './components/account.vue';
export default {
  components: {
    account,
  },
  data() {
    return {
      redirectUrl: '',
      storage: true,
      fromUrl: '',
    };
  },
  computed: {},
  watch: {},
  mounted() {
    const storage = Number(this.$route.query.storage);
    console.log('------storage------', storage);
    this.fromUrl = this.$route.query.from_url;
    console.log('------fromUrl------', this.fromUrl);
    if (storage === 0) {
      this.storage = false;
    } else {
      this.storage = true;
    }

    console.log('------this.storage------', this.storage);
  },
};
</script>
<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  .login-bg {
    display: flex;
    height: 100vh;
    background: #f2f3f8;
    .left {
      position: relative;
      flex: 1;
      height: 100%;
      background: url('https://baike-med-1256891581.file.myqcloud.com/2022013/091f16e0-6f97-11ec-a3f4-354087f4d05d_0.png')
        no-repeat center;
      .logo {
        position: absolute;
        left: 40px;
        top: 40px;
        font-size: 0;
        .img {
          width: 136px;
          height: 36px;
        }
      }
      .copyright {
        position: absolute;
        bottom: 32px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #98a2b3;
      }
    }
    .right {
      width: 480px;
      height: 100%;
      background: url('https://baike-med-1256891581.file.myqcloud.com/2022015/08c33b40-6f97-11ec-acc4-2daa03d2616b_0.png')
        #0069fa no-repeat center bottom;
    }
  }
  .login-area {
    position: absolute;
    top: 50%;
    margin-top: -204px;
    right: 300px;
    width: 360px;
    height: 408px;
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 60px 40px;
  }
}
</style>
