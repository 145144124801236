/* eslint-disable no-useless-escape */
export const getAuditLogListGql =
  'query{audit_log(_limit:$limit,_offset:$offset,_order_by:{create_time:_desc}$filter){_aggregate{_count},sponsor_uin,operand_id,product_id,version,delete_time,create_by,ext,id,name,belong_territory,owner,update_by,update_time,action_name,create_time,trace_id,package_name,api_name,request,response,is_success,sponsor_name,product_name,operand_name, error}}';
// ,operand_type

// 插入规则
export const insertRuleGql = 'mutation insert {audit_rule(_values: {ruler: $ruler})}';

// 查询规则
export const getRuleGql = `query {
    audit_rule (_order_by: {
            create_time: _asc,
    }) {
            id
            ruler
    }
}`;

// 插入规则
export const deleteRuleGql = 'mutation delete {audit_rule(id: $id)}';

// 更新规则
export const updateRuleGql = `mutation update {
	audit_rule (_where: {id: $id}, _set: {ruler: $ruler})
}`;
