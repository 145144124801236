export const MENU_DESC = {
  overview: {
    desc: '基础信息，对象关联关系与索引',
    help_desc: '元数据对象基础信息，包括对象关联关系与索引',
  },
  'field-collect': {
    desc: '字段名称，类型等属性配置',
    help_desc: '元数据所有字段信息，包括字段类型，是否必填/加密等',
  },
  'metadata-layout': {
    desc: '管理相关联的列表页，详情页与表单等配置',
    help_desc: '管理当前对象相关联的列表页，详情页，新建/编辑表单等配置',
  },
  'metadata-trigger': {
    desc: '管理当前触发条件和逻辑云函数',
    help_desc: '管理当前对象的触发条件和逻辑云函数',
  },
  'object-work-flow': {
    desc: '相关模型配置，发布与执行日志',
    help_desc: '相关工作流模型配置，发布与执行日志',
  },
  'metadata-rule': {
    desc: '配置校验规则和不同层级的消息提示',
    help_desc: '配置校验规则和消息提示',
  },
  'object-permission': {
    desc: '系统内角色对当前对象的使用权限',
    help_desc: '管理系统内角色对当前对象的使用权限',
  },
  'share-rule': {
    desc: '对象数据范围的访问共享及权限设定',
    help_desc: '通过共享规则设置可实现指定目标用户对当前对象数据范围的访问共享及权限设定',
  },
  'query-filter': {
    desc: '自定义筛选条件，gql查询时可指定查询条件',
    help_desc: '类似行权限表达式配置，支持变量、sql等模式',
  },
};
