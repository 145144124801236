<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in visibleLevelList" :key="item.path">
        <span
          v-if="item.redirect === 'noredirect' || index == visibleLevelList.length - 1"
          class="no-redirect"
        >
          {{ item.title }}
        </span>
        <router-link v-else :to="item.redirect || item.path">{{ item.title }}</router-link>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      levelList: null,
    };
  },
  computed: {
    ...mapGetters({
      breadcrumbList: 'user/getBreadcrumb',
    }),
    visibleLevelList() {
      if (this.breadcrumbList.length) {
        return this.breadcrumbList;
      }
      if (this.levelList.length) {
        return this.levelList
          .filter(function (level) {
            return level.meta.title;
          })
          .map((item) => {
            return {
              path: item.path,
              redirect: item.redirect,
              title: item.meta.title,
            };
          });
      }
      return [];
    },
  },
  watch: {
    $route() {
      this.setBreadcrumb([]);
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    ...mapMutations({
      setBreadcrumb: 'user/setBreadcrumb',
    }),
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name);
      this.levelList = matched;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
