import Vue from 'vue';
import Router from 'vue-router';
import { Message } from 'element-ui';
import routes from './routes';
import store from '@/store';
import { gotoHome } from '@/utils/util';
import config from '@/config';
import { METADATA_RULE_DEBUG_CACHE_NAME } from '@/utils/constant';

Vue.use(Router);

const router = new Router({
  routes,
  mode: 'history',
});
const envMap = {
  development: '开发',
  test: '测试',
  preview: '预发',
  production: '正式',
};
router.beforeEach(async (to, from, next) => {
  // 布局页面管理页面，如果从布局详情页回退，则保留查询
  if (to.name === 'layout-manage-list' && from.name !== 'layout-detail') {
    store.commit('layout/clearLayoutParams');
  }

  if (to.name && from.name) {
    // 如果离开规则和规则调试页面 那么删除缓存数据
    const list = ['metadata-rule-debug', 'metadata-rule'];
    if (!(list.includes(to.name) && list.includes(from.name))) {
      delete window[METADATA_RULE_DEBUG_CACHE_NAME];
    }
  }

  if (to.name === 'home') {
    document.title = `${envMap[config.env]}-重楼管理平台`;
    // 处理二级菜单重定向为三级菜单后，面包屑导航只需出现两层问题（例：metadata-details为新元数据列表路径）
  } else if (to.name === 'metadata-details') {
    document.title = `${envMap[config.env]}-元数据-重楼管理平台`;
  } else if (to.name === 'layout-manage-list') {
    document.title = `${envMap[config.env]}-布局管理-重楼管理平台`;
  } else if (to.name === 'callback') {
    document.title = '';
  } else {
    // 处理三级菜单面包屑导航需动态改变问题
    if (to.matched.length > 2) {
      const reg1 = /power\/new-metadata\//;
      const reg2 = /power\/layout-manage\/layout-detail/;
      if (reg1.test(to.path) || reg2.test(to.path)) {
        to.meta.title = sessionStorage.getItem('object_name');
      }
    }
    document.title = `${envMap[config.env]}-${to.meta.title}-重楼管理平台`;
  }
  if (to.name === 'login') {
    next();
    return;
  }
  if (to.name === 'callback') {
    next();
    return;
  }
  try {
    const userMenu = store.state.user.menuList; // 权限配置的餐菜单
    let promiseList = [];
    if (!store.getters['user/isLogin']) {
      promiseList = [store.dispatch('user/GetUserUserInfo'), store.dispatch('user/GetTenantInfo')];
    }
    if (userMenu.length === 0) {
      promiseList.push(store.dispatch('user/GetUserMenuTree'));
    }
    if (promiseList.length) {
      await Promise.all(promiseList);
    }
    // 获取用户信息失败返回登录
    if (!store.getters['user/isLogin']) {
      next({ name: 'login' });
      return;
    }
    // 用户默认进入的菜单
    const defaultMenuName = store.getters['user/getDefaultMenuName'];
    // // 无权限提示语
    const tips = '您无权访问该页面，请联系管理员';
    if (store.getters['user/getJurisdiction'].includes(to.name)) {
      next();
    } else if (defaultMenuName) {
      next({ name: defaultMenuName });
      Message.error(tips);
    } else {
      next({ name: 'login' });
      Message.error(tips);
    }
  } catch (error) {
    console.error(error);
    gotoHome();
  }
});

export default router;
