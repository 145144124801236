export const ERROR_BIZCODE_LIST = {
  31009: '该名称已被占用，请更改名称',
};
export const GQL_SPECIAL_CHAR = ['\\\\', '%'];

export const DEFINE_TYPE = {
  SYSTEM: '系统级别',
  PACKAGE: '标准功能',
  CUSTOM: '租户级别',
};
export const VALUE_TYPE = {
  INT: '整数',
  TEXT: '文本',
  DATETIME: '日期时间',
  SELECT_ONE: '单选',
  SELECT_ONE_INT: '单选（int）',
  SELECT_MANY: '多选',
  BOOL: '布尔',
  FLOAT: '浮点数',
  AUTO_NUMBER: '自动编码',
  ARRAY: '数组',
  FORMULA: '公式字段',
  ROLLUP_SUMMARY: '累计汇总',
  TS_VECTOR: '全文检索向量（tsvector）',
};
export const EXTRESSION_TYPE = [
  { value: 'INT', label: '整数' },
  { value: 'TEXT', label: '文本' },
  { value: 'DATETIME', label: '日期时间' },
  { value: 'BOOL', label: '布尔' },
  { value: 'FLOAT', label: '浮点数' },
];
export const OLAP_VALUE_TYPE = {
  INT: '整数',
  TEXT: '文本',
  DATETIME: '日期时间',
  SELECT_ONE: '单选',
  SELECT_ONE_INT: '单选（int）',
  SELECT_MANY: '多选',
  FLOAT: '浮点数',
};
export const ARRAY_ELEM_TYPE = {
  INT: '整数',
  TEXT: '文本',
  BOOL: '布尔',
  FLOAT: '浮点数',
};
export const COLUMN_TYPE = {
  STANDARD: '标准列',
  EXT: '扩展列',
};

export const RELATION_ACTION = {
  NO_ACTION: '无操作',
  CASCADE: '级联',
  DO_NOT_ALLOW: '不允许',
  SET_NULL: '置空',
};

export const RELATION_TYPE = {
  DEFAULT: '默认',
  ONE_TO_ONE: '一对一',
  ONE_TO_MANY: '一对多',
  ONE_TO_MANY_BY_IDS: '一对多（by IDs）',
  ONE_TO_MANY_BY_IDS_REVERSE: '一对多（by IDs反向）',
};

export const OPERATION_TYPES = {
  EQ: '等于(EQ)',
  NEQ: '不等于(NEQ)',
  GT: '大于(GT)',
  GTE: '大于等于(GTE)',
  LT: '小于(LT)',
  LTE: '小于等于(LTE)',
  IN: '属于(IN)',
  NIN: '不属于(Not IN)',
  LIKE: '模糊匹配(Like)',
  NLIKE: '模糊匹配非(Not Like)',
  ANY: '包含任一(Include Any)',
  EXIST: '存在(Exist)',
  NOT_EXIST: '不存在(Not Exist)',
};

export const CONNECTOR_TYPES = {
  AND: '与',
  OR: '或',
};
// 布局用到的终端有小程序+H5，菜单的没有
// 唯一一个例外是布局分配，也没有
export const PLATFORMS = [
  { value: 4, label: '小程序+H5' },
  { value: 2, label: 'PC/Web' },
  { value: 1, label: '小程序' },
  { value: 3, label: 'H5' },
];

export const MP_PLATFORM = 1;
export const WEB_PLATFORM = 2;
export const H5_PLATFORM = 3;
export const MP_H5_PLATFORM = 4;

export const MENU_PLATFORMS = [
  { value: 1, label: '小程序' },
  { value: 2, label: 'PC/Web' },
  { value: 3, label: 'H5' },
];

// 租户 id 和中文名、英文名对应关系，上新租户时联系 @kianzzhang 更新
export const TENANT_LIST = {
  dev: {
    1: { name_ch: '测试租户', name_en: 'T1', git_record: true },
  },
  test: {
    1: { name_ch: '测试', name_en: 'T1', git_record: true },
  },
  pre: {
    1707191674421504: { name_ch: '阿斯利康-DEV', name_en: 'az-dev', git_record: true },
    1731017797361920: { name_ch: '阿斯利康-SIT', name_en: 'az-sit', git_record: true },
    1731017797361921: { name_ch: '阿斯利康-SIT2', name_en: 'az-sit2', git_record: true },
    1647456415314176: { name_ch: '阿斯利康', name_en: 'az', git_record: true },
    1555456401019136: { name_ch: '埃森哲', name_en: 'accenture', git_record: true },
    1542287994085632: { name_ch: '天士力测试', name_en: 'tasly-test', git_record: true },
    1542287645892864: { name_ch: '天士力', name_en: 'tasly', git_record: true },
    1537482342121728: { name_ch: '迪哲测试', name_en: 'dizal-test', git_record: true },
    1536372064071936: { name_ch: '迪哲', name_en: 'dizal', git_record: true },
    1491417030418688: { name_ch: '辉瑞UAT', name_en: 'pfizer', git_record: true },
    1471299560000191: { name_ch: '卫材', name_en: 'eisai', git_record: true },
    1589415574655232: { name_ch: '复宏汉霖(开发)', name_en: 'henlius-dev', git_record: true },
    1635489416491264: { name_ch: '复宏汉霖', name_en: 'henlius', git_record: true },
    1: { name_ch: '测试1', name_en: 'T1', git_record: true },
  },
  prod: {
    1647456415314176: { name_ch: '阿斯利康', name_en: 'az', git_record: true },
    1542287645892864: { name_ch: '天士力', name_en: 'tasly', git_record: true },
    1536372064071936: { name_ch: '迪哲', name_en: 'dizal', git_record: true },
    1491417030418688: { name_ch: '辉瑞', name_en: 'pfizer', git_record: true },
    1471299560000191: { name_ch: '卫材', name_en: 'eisai', git_record: true },
    1635489416491264: { name_ch: '复宏汉霖', name_en: 'henlius', git_record: true },
    1: { name_ch: '测试1', name_en: 'T1', git_record: true },
  },
};

export const CLASS_OPTIONS = ['info', 'primary', 'success', 'warning', 'danger'];
export const SHOWASSIGNEETYPE = {
  leader: '岗位上级',
  attribute: '指定用户属性',
  candidate: '指定用户',
};
export const USER_PROPERTITY = [
  {
    key: 'role',
    label: '指定角色',
    value: '',
  },
  {
    key: 'department',
    label: '指定部门',
    value: '',
  },
  {
    key: 'region',
    label: '指定区域',
    value: '',
  },
];
export const VARIABLETYPES = [
  { value: 'boolean', label: '布尔' },
  { value: 'string', label: '字符串' },
  { value: 'number', label: '数值' },
  { value: 'const', label: '常量' },
  { value: 'object', label: '对象' },
  { value: 'computed', label: '表达式' },
];
export const ENVIRONMENT = [
  { label: '开发', value: 'Dev' },
  { label: '测试', value: 'Test' },
  { label: '预发布', value: 'Pre' },
  { label: '正式', value: 'Prod' },
];

export const FIELD_TYPES = [
  { value: 'empty', label: '置空' },
  { value: 'const', label: '常量' },
  { value: 'variable', label: '变量' },
];

export const LOGIN_STEP = {
  phone: 'phone',
};

export const FUNCTION_BUSINESS_TYPE = [
  { label: '--空--', value: 0 },
  { label: '触发器', value: 1 },
  { label: 'Action', value: 2 },
  { label: '自定义报表', value: 3 },
  { label: '审批流自定义事件', value: 4 },
];

export const FUNCTION_ARGUMENT_TYPE = [
  { label: '数字型', value: 'int' },
  { label: '文本', value: 'string' },
  { label: '日期时间', value: 'date' },
];
export const METADATA_TRIGGER_TIME = [
  { label: '查询前', value: 'BEFORE_QUERY' },
  { label: '查询后', value: 'AFTER_QUERY' },
  { label: '创建前', value: 'BEFORE_CREATE' },
  { label: '创建后', value: 'AFTER_CREATE' },
  { label: '更新前', value: 'BEFORE_UPDATE' },
  { label: '更新后', value: 'AFTER_UPDATE' },
  { label: '删除前', value: 'BEFORE_DELETE' },
  { label: '删除后', value: 'AFTER_DELETE' },
];

export const CLOUD_FUNCTION_STATUS = {
  0: '正常',
  1: '删除',
  Creating: '函数创建中',
  CreateFailed: '函数创建失败（如果已生成函数信息，则可以删除后再创建）',
  Active: '函数可用',
  Updating: '函数更新中',
  UpdateFailed: '函数更新失败',
  Publishing: '函数版本发布中',
  PublishFailed: '函数版本发布失败',
  Deleting: '函数删除中',
  DeleteFailed: '函数删除失败',
};

export const COLUMN_PERMISSION = {
  readonly: 1,
  editable: 2,
};

export const CLOUD_PROPERTY_TYPE = {
  integer: '数字',
  string: '文本',
  boolean: '布尔',
  number: '数字',
  array: '列表',
};

export const NOTICE_TYPE = {
  mail: '邮件通知',
  wecom: '企微通知',
  system: '站内信',
};
export const CLOUD_FUNCTION_CLASS = [
  { label: '租户级别', value: 1 },
  { label: '标准功能', value: 2 },
  { label: '系统级别', value: 3 },
];

export const CLOUD_FUNCTION_LANGUAGE = [
  { label: 'go', value: 1 },
  { label: 'nodejs', value: 2 },
  // { label: 'java', value: 3 },
];

export const I18N_LANG_SELECT = {
  ar_AE: '阿拉伯文-阿拉伯联合酋长国',
  ar_JO: '阿拉伯文-约旦',
  ar_SY: '阿拉伯文-叙利亚',
  hr_HR: '克罗地亚文-克罗地亚',
  fr_BE: '法文-比利时',
  es_PA: '西班牙文-巴拿马',
  mt_MT: '马耳他文-马耳他',
  es_VE: '西班牙文-委内瑞拉',
  bg: '保加利亚文',
  zh_TW: '中文-台湾地区',
  it: '意大利文',
  ko: '朝鲜文',
  uk: '乌克兰文',
  lv: '拉托维亚-列托',
  da_DK: '丹麦文-丹麦',
  es_PR: '西班牙文-波多黎哥',
  vi_VN: '越南文-越南',
  en_US: '英文-美国',
  sr_ME: '塞尔维亚文-黑山',
  sv_SE: '瑞典文-瑞典',
  es_BO: '西班牙文-玻利维亚',
  en_SG: '英文-新加坡',
  ar_BH: '阿拉伯文-巴林',
  pt: '葡萄牙文',
  ar_SA: '阿拉伯文-沙特阿拉伯',
  sk: '斯洛伐克文',
  ar_YE: '阿拉伯文-也门',
  hi_IN: '印地文-印度',
  ga: '爱尔兰文',
  en_MT: '英文-马耳他',
  fi_FI: '芬兰文-芬兰',
  et: '爱沙尼亚文',
  sv: '瑞典文',
  cs: '捷克文',
  'sr_BA_#Latn': '塞尔维亚文-拉丁文,波斯尼亚和黑山共和国',
  el: '希腊文',
  uk_UA: '乌克兰文-乌克兰',
  hu: '匈牙利文',
  fr_CH: '法文-瑞士',
  in: '印度尼西亚文',
  es_AR: '西班牙文-阿根廷',
  ar_EG: '阿拉伯文-埃及',
  'ja_JP_JP_#u-ca-japanese': '日文-日本,JP',
  es_SV: '西班牙文-萨尔瓦多',
  pt_BR: '葡萄牙文-巴西',
  be: '白俄罗斯文',
  is_IS: '冰岛文-冰岛',
  cs_CZ: '捷克文-捷克共和国',
  es: '西班牙文',
  pl_PL: '波兰文-波兰',
  tr: '土耳其文',
  ca_ES: '加泰罗尼亚文-西班牙',
  sr_CS: '塞尔维亚文-塞尔维亚及黑山',
  ms_MY: '马来文-马来西亚',
  hr: '克罗地亚文',
  lt: '立陶宛文',
  es_ES: '西班牙文-西班牙',
  es_CO: '西班牙文-哥伦比亚',
  bg_BG: '保加利亚文-保加利亚',
  sq: '阿尔巴尼亚文',
  fr: '法文',
  ja: '日文',
  sr_BA: '塞尔维亚文-波斯尼亚和黑山共和国',
  is: '冰岛文',
  es_PY: '西班牙文-巴拉圭',
  de: '德文',
  es_EC: '西班牙文-厄瓜多尔',
  es_US: '西班牙文-美国',
  ar_SD: '阿拉伯文-苏丹',
  en: '英文',
  ro_RO: '罗马尼亚文-罗马尼亚',
  en_PH: '英文-菲律宾',
  ca: '加泰罗尼亚文',
  ar_TN: '阿拉伯文-突尼斯',
  'sr_ME_#Latn': '塞尔维亚文-拉丁文,黑山',
  es_GT: '西班牙文-危地马拉',
  sl: '斯洛文尼亚文',
  ko_KR: '朝鲜文-韩国',
  el_CY: '希腊文-塞浦路斯',
  es_MX: '西班牙文-墨西哥',
  ru_RU: '俄文-俄罗斯',
  es_HN: '西班牙文-洪都拉斯',
  zh_HK: '中文-香港',
  no_NO_NY: '挪威文-挪威,Nynorsk',
  hu_HU: '匈牙利文-匈牙利',
  th_TH: '泰文-泰国',
  ar_IQ: '阿拉伯文-伊拉克',
  es_CL: '西班牙文-智利',
  fi: '芬兰文',
  ar_MA: '阿拉伯文-摩洛哥',
  ga_IE: '爱尔兰文-爱尔兰',
  mk: '马其顿文',
  tr_TR: '土耳其文-土耳其',
  et_EE: '爱沙尼亚文-爱沙尼亚',
  ar_QA: '阿拉伯文-卡塔尔',
  'sr__#Latn': '塞尔维亚文-拉丁文',
  pt_PT: '葡萄牙文-葡萄牙',
  fr_LU: '法文-卢森堡',
  ar_OM: '阿拉伯文-阿曼',
  th: '泰文',
  sq_AL: '阿尔巴尼亚文-阿尔巴尼亚',
  es_DO: '西班牙文-多米尼加共和国',
  es_CU: '西班牙文-古巴',
  ar: '阿拉伯文',
  ru: '俄文',
  en_NZ: '英文-新西兰',
  sr_RS: '塞尔维亚文-塞尔维亚',
  de_CH: '德文-瑞士',
  es_UY: '西班牙文-乌拉圭',
  ms: '马来文',
  el_GR: '希腊文-希腊',
  iw_IL: '希伯来文-以色列',
  en_ZA: '英文-南非',
  'th_TH_TH_#u-nu-thai': '泰文-泰国,TH',
  hi: '印地文',
  fr_FR: '法文-法国',
  de_AT: '德文-奥地利',
  nl: '荷兰文',
  no_NO: '挪威文-挪威',
  en_AU: '英文-澳大利亚',
  vi: '越南文',
  nl_NL: '荷兰文-荷兰',
  fr_CA: '法文-加拿大',
  lv_LV: '拉托维亚-列托-拉脱维亚',
  de_LU: '德文-卢森堡',
  es_CR: '西班牙文-哥斯达黎加',
  ar_KW: '阿拉伯文-科威特',
  sr: '塞尔维亚文',
  ar_LY: '阿拉伯文-利比亚',
  mt: '马耳他文',
  it_CH: '意大利文-瑞士',
  da: '丹麦文',
  de_DE: '德文-德国',
  ar_DZ: '阿拉伯文-阿尔及利亚',
  sk_SK: '斯洛伐克文-斯洛伐克',
  lt_LT: '立陶宛文-立陶宛',
  it_IT: '意大利文-意大利',
  en_IE: '英文-爱尔兰',
  zh_SG: '中文-新加坡',
  ro: '罗马尼亚文',
  en_CA: '英文-加拿大',
  nl_BE: '荷兰文-比利时',
  no: '挪威文',
  pl: '波兰文',
  zh_CN: '中文-中国',
  ja_JP: '日文-日本',
  de_GR: '德文-希腊',
  'sr_RS_#Latn': '塞尔维亚文-拉丁文,塞尔维亚',
  iw: '希伯来文',
  en_IN: '英文-印度',
  ar_LB: '阿拉伯文-黎巴嫩',
  es_NI: '西班牙文-尼加拉瓜',
  zh: '中文',
  mk_MK: '马其顿文-马其顿王国',
  be_BY: '白俄罗斯文-白俄罗斯',
  sl_SI: '斯洛文尼亚文-斯洛文尼亚',
  es_PE: '西班牙文-秘鲁',
  in_ID: '印度尼西亚文-印度尼西亚',
  en_GB: '英文-英国',
};
export const IAM_TAG_EXPIRE = [
  // { label: '1个月', value: 2592000 },
  // { label: '3个月', value: 7776000 },
  { label: '6个月', value: 15552000 },
  { label: '1年', value: 31104000 },
  { label: '永久', value: -1 },
  { label: '自定义', value: Infinity },
];

export const MENU_TYPE = [
  { label: '对象菜单', value: 'object' },
  { label: '自定义菜单', value: 'custom' },
  { label: '外部页面', value: 'external' },
];

export const CUSTOM_MENU_TYPE = [
  { label: '自定义布局', value: 'layout' },
  { label: '定开页面', value: 'page' },
  { label: '兼容旧页面', value: 'jsonpage' },
  { label: '一级菜单', value: 'dir' },
];

export const EXTERNAM_PAGE_OPEN_TYPE = [
  { label: '页面内', value: 'inside' },
  { label: '新页面', value: 'outside' },
  { label: 'iframe', value: 'iframe' },
];

export const AUTH_ROLE_TYPE = [
  { label: '所有角色可见', value: 'all' },
  { label: '部分角色可见', value: 'some' },
];

export const IDENTITY_TYPE = [
  { label: '管理员', value: 'admin' },
  { label: '员工', value: 'staff' },
  { label: '医生', value: 'doctor' },
  { label: '开发运维', value: 'dev_ops' },
];

export const ACCOUNT_STATUS = [
  { label: '有效', value: 'activated' },
  { label: '无效', value: 'inactivated' },
  { label: '冻结', value: 'freezed' },
];

export const USER_APP_TYPE = [
  { label: '订阅号', value: 1 },
  { label: '服务号', value: 2 },
  { label: '小程序', value: 3 },
  { label: '腾讯会议', value: 4 },
  { label: '企业微信', value: 5 },
];
export const LAYOUT_TYPES = [
  { value: 'list', label: '列表' },
  { value: 'detail', label: '详情' },
  { value: 'create', label: '新建表单' },
  { value: 'edit', label: '编辑表单' },
];
export const DATA_ENGINE = {
  OLTP: 'PG',
  OLAP: 'CK',
};
export const WATERMARK_USER_DATA = [
  {
    label: '姓名',
    value: '$user.name',
  },
  {
    label: '邮箱',
    value: '$user.email',
  },
  {
    label: '员工号',
    value: '$user.user_code',
  },
  {
    label: '岗位编号',
    value: '$user.current_territory',
  },
  {
    label: '岗位名称',
    value: '$user.current_territory_name',
  },
  {
    label: '手机号',
    value: '$user.phone',
  },
  {
    label: '日期时间',
    value: '$system.date',
  },
];

export const PRODUCT_MAP = {
  28: 'tq',
  2: '', // nges
  22014: 'md',
  29: 'ic',
  1002: 'faas',
};

export const AGGREGATE_FUNCTION = [
  { label: '求和', value: 'SUM' },
  { label: '平均值', value: 'AVG' },
  { label: '最大值', value: 'MAX' },
  { label: '最小值', value: 'MIN' },
  { label: '计数', value: 'COUNT' },
];

export const VIEW_DATA_TYPE = [
  { label: '个人', value: 1 },
  { label: '全部', value: 2 },
];
export const VIEW_JOB_TYPE = [
  { label: '本岗', value: 1 },
  { label: '本岗及下属', value: 2 },
];
export const VIEW_ORG_TYPE = [
  { label: '本员工组织', value: 1 },
  { label: '本员工组织及下属', value: 2 },
];
export const VIEW_USER_TYPE = [
  { label: '本人', value: 1 },
  { label: '本人下属', value: 2 },
];
export const TRIGGER_TIME = {
  BEFORE_QUERY: ' 查询前',
  AFTER_QUERY: ' 查询后',
  BEFORE_CREATE: ' 创建前',
  AFTER_CREATE: ' 创建后',
  BEFORE_UPDATE: ' 更新前',
  AFTER_UPDATE: ' 更新后',
  BEFORE_DELETE: ' 删除前',
  AFTER_DELETE: ' 删除后',
  ' 查询前': 'BEFORE_QUERY',
  ' 查询后': 'AFTER_QUERY',
  ' 创建前': 'BEFORE_CREATE',
  ' 创建后': 'AFTER_CREATE',
  ' 更新前': 'BEFORE_UPDATE',
  ' 更新后': 'AFTER_UPDATE',
  ' 删除前': 'BEFORE_DELETE',
  ' 删除后': 'AFTER_DELETE',
};
export const OPERATOR_CLASSES = {
  rum_tsvector_ops: 'rum_tsvector_ops',
  rum_tsvector_hash_ops: 'rum_tsvector_hash_ops',
  rum_TYPE_ops: 'rum_TYPE_ops',
  rum_tsvector_addon_ops: 'rum_tsvector_addon_ops',
  rum_tsvector_hash_addon_ops: 'rum_tsvector_hash_addon_ops',
  rum_tsquery_ops: 'rum_tsquery_ops',
  rum_anyarray_ops: 'rum_anyarray_ops',
};

export const METADATA_RULE_DEBUG_CACHE_NAME = 'parisMetadataRuleDebug';
