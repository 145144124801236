<template>
  <el-card class="antvbox">
    <!-- 功能 -->
    <el-form
      ref="searchForm"
      :inline="true"
      :model="searchForm"
      @submit.native.prevent
      @keyup.enter.native="searchList"
    >
      <el-form-item label="产品应用:" prop="app_id">
        <el-select v-model="searchForm.app_id" placeholder="请选择">
          <el-option
            v-for="obj in appList"
            :key="obj.label"
            :label="obj.label"
            :value="obj.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流程名称:" prop="name">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入"
          clearable
          @clear="searchList"
        ></el-input>
      </el-form-item>
      <el-form-item label="流程key:" prop="key">
        <el-input
          v-model="searchForm.key"
          placeholder="请输入"
          clearable
          @clear="searchList"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="latest">
        <el-switch
          v-model="searchForm.latest"
          @change="searchList"
          active-value="true"
          inactive-value="false"
          active-text="全部"
          inactive-text="最新"
          active-color="#0069fa"
          inactive-color="#c5c5c5"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">查询</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
    <br />
    <!-- 列表 -->
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="process_name" label="流程名称" />
      <el-table-column prop="process_key" label="流程key" />
      <el-table-column prop="version" label="版本" />
      <el-table-column prop="deploy_time" label="部署时间" />
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button v-show="false" type="text" @click="createModel(scope.row)">生成模型</el-button>
          <el-button type="text" @click="derive(scope.row)">导出流程</el-button>
          <el-button type="text" @click="toViewLog(scope.row)">查看日志</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>
import { openDownloadDialog } from '@/utils/util';
export default {
  data() {
    return {
      userId: '',
      searchForm: {
        name: '',
        key: '',
        latest: 'true',
        app_id: '',
      },
      list: [],
      // 产品列表
      appList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    try {
      const userInfo = JSON.parse(sessionStorage.getItem('userinfo-ams'));
      this.userId = userInfo.uin;
    } catch (err) {
      console.log(err);
    }
    this.getList();
    this.getAppList();
  },
  methods: {
    searchList() {
      this.pageIndex = 1;
      this.getList();
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.searchList();
    },
    async getList() {
      try {
        const { process_array: list, total = 0 } = await this.$request({
          url: this.$api.getListProcess,
          data: {
            user_id: this.userId,
            input_params: {
              start: `${(this.pageIndex - 1) * this.pageSize}`,
              size: `${this.pageSize}`,
              ...this.searchForm,
              key: this.searchForm.key || undefined,
              name: this.searchForm.name || undefined,
            },
          },
        });
        this.list = list;
        this.total = Number(total);
      } catch (err) {
        console.log(err);
      }
    },
    async createModel({ id }) {
      try {
        await this.$request({
          url: this.$api.generateProcessMaterial,
          data: { id },
        });
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (err) {
        console.log(err);
      }
    },
    async derive(row) {
      const { id, process_name: processName, process_key: processKey, version } = row;
      try {
        const {
          material_json: json,
          name,
          module,
          edit_process_desc: desc = '',
          remark = '',
        } = await this.$request({
          url: this.$api.exportProcessMaterial,
          data: { id },
        });
        const data = {
          edit_json: json,
          edit_process_name: name,
          process_key: processKey,

          edit_process_desc: desc,
          module,
          remark,
        };

        const filename = `${processName || '导出'}${processName && version ? `(${version})` : ''}`;
        openDownloadDialog(
          new Blob([JSON.stringify(data)], { type: 'application/json' }),
          `${filename}.json`,
        );
      } catch (err) {
        console.log(err);
      }
    },
    del({ id }) {
      this.$confirm('确定删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          this.$request({
            url: this.$api.deleteProcess,
            data: { id },
            cb: () => {
              this.pageIndex = 1;
              this.getList();
            },
          });
        } catch (err) {
          console.log(err);
        }
      });
    },
    // 分页器
    handleSizeChange(value) {
      this.pageIndex = 1;
      this.pageSize = value;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getList();
    },
    // 获取产品列表
    getAppList() {
      const url = this.$api.getAppList;
      const data = { page_size: 999999 };
      const cb = (data) => {
        this.appList = data?.data?.map((item) => ({
          value: `${item.id}`,
          label: item.name,
        }));
      };
      this.$request({ url, data, cb, showLoding: false });
    },
    toViewLog(row) {
      this.$router.push({
        name: 'process-log',
        query: {
          row: JSON.stringify(row),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.antvbox {
  background-color: #fff;
  // Switch 开关
  ::v-deep .el-switch {
    margin: 0 7px;
    .el-switch__core {
      width: 54px !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }
    .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }
    &.is-checked .el-switch__core::after {
      margin-left: -21px;
    }
    /*关闭时文字位置设置*/
    .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);
      span {
        font-size: 12px;
      }
    }
    /* 激活时另一个文字消失 */
    .el-switch__label.is-active {
      display: none;
    }
    /*开启时文字位置设置*/
    .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
