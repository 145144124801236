<template>
  <div class="rule-edit">
    <div class="rule-content">
      <el-card>
        <div class="title required">规则名称</div>
        <el-form :model="form" class="rule-form" label-width="80px" :rules="rules" inline>
          <el-form-item label="规则名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入规则名称"
              class="rule-input level-interval"
            />
          </el-form-item>
          <el-form-item label prop="desc">
            <el-input v-model="form.desc" placeholder="请输入规则描述" class="rule-input" />
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <div class="condition">
          <div class="title">条件设置</div>
          <div class="condition-title">是否启用条件表达式</div>
          <el-radio-group v-model="showCondition">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
          <div class="tip">
            提示：条件表达式默认为非启用状态，配置条件表达式且勾选启用后，在满足条件设置的前提下才进行公式配置的规则校验
          </div>
          <template v-if="showCondition">
            <div class="vertical-interval">
              <el-button type="primary" class="level-interval" @click="showMetaPopup('condition')">
                插入对象字段
              </el-button>
              <el-select
                @change="(e) => change(e, 'condition')"
                :value="undefined"
                placeholder="插入运算符"
                class="level-interval"
              >
                <el-option
                  v-for="item in operatorOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                :value="undefined"
                @change="(e) => change(e, 'condition')"
                placeholder="插入函数"
                class="level-interval"
              >
                <el-option-group v-for="group in funcList" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-option-group>
              </el-select>
              <el-select
                :value="undefined"
                @change="(e) => change(e, 'condition')"
                placeholder="插入全局变量"
              >
                <el-option
                  v-for="item in globalVar"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="vertical-interval">
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请设置条件表达式"
                class="condition_text"
                v-model="form.condition_text"
              />
            </div>
            <el-button class="level-interval" @click="checkSyntax('condition')">检查语法</el-button>
            <span v-if="conditionTips.is_valid === null"></span>
            <span
              v-else-if="conditionTips.is_valid"
              :style="{
                color: '#23B812',
              }"
            >
              语法正确
            </span>
            <span
              v-else
              :style="{
                color: '#e7403a',
              }"
            >
              {{ conditionTips.err_msg }}
            </span>
          </template>
        </div>

        <div class="title required">公式配置</div>
        <div class="vertical-interval">
          <el-button type="primary" class="level-interval" @click="showMetaPopup('rule')">
            插入对象字段
          </el-button>
          <el-select
            @change="(e) => change(e, 'rule')"
            :value="undefined"
            placeholder="插入运算符"
            class="level-interval"
          >
            <el-option
              v-for="item in operatorOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            class="level-interval"
            :value="undefined"
            @change="(e) => change(e, 'rule')"
            placeholder="插入函数"
          >
            <el-option-group v-for="group in funcList" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-option-group>
          </el-select>
          <el-select
            :value="undefined"
            @change="(e) => change(e, 'rule')"
            placeholder="插入全局变量"
          >
            <el-option
              v-for="item in globalVar"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="vertical-interval">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请选择对象和运算符"
            class="rule_text"
            v-model="form.rule_text"
          />
        </div>
        <el-button class="level-interval" @click="checkSyntax('rule')">检查语法</el-button>
        <span v-if="ruleTips.is_valid === null"></span>
        <span
          v-else-if="ruleTips.is_valid"
          :style="{
            color: '#23B812',
          }"
        >
          语法正确
        </span>
        <span v-else :style="{ color: '#e7403a' }">{{ ruleTips.err_msg }}</span>
      </el-card>
      <el-card>
        <div class="title">消息提示</div>
        <el-form :model="form" label-width="80px" class="rule-form" :rules="rules">
          <el-form-item label="消息类型" prop="level">
            <el-radio-group v-model="form.level">
              <el-radio label="TIP">提示</el-radio>
              <el-radio label="WARN">警示</el-radio>
              <el-radio label="ERROR">错误</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="tip-pos" v-if="form.level === 'ERROR'">
            <el-form-item label="提示位置" prop="tip_pos" v-if="!customPos">
              <el-select filterable v-model="form.tip_pos" placeholder="请选择">
                <el-option
                  v-for="item in fieldList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="提示位置" prop="tip_pos" v-if="customPos">
              <el-input v-model="form.tip_pos" placeholder="请输入表单字段"></el-input>
            </el-form-item>
            <el-checkbox class="custom-pos" v-model="customPos">是否自定义显示位置</el-checkbox>
          </div>
          <el-form-item label="提示消息" prop="tip_msg">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.tip_msg" />
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- 按钮 -->
    <el-card class="footer">
      <el-button @click="back">取消</el-button>
      <el-button
        type="primary"
        :disabled="saveDisabled"
        @click.stop="saveRule"
        v-if="jurisdiction.metaManage"
      >
        保存
      </el-button>
    </el-card>
    <!-- 弹窗 -->
    <select-meta
      v-if="selectMeta"
      :show="selectMeta"
      :object="object"
      :object-id="objectId"
      @cancel="
        () => {
          selectMeta = false;
        }
      "
      @insertion="(str) => insertion(currentType, str)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import selectMeta from './components/selectMeta.vue';
import { getAstFromStr, transformer } from '@/utils/ast';

// const ERROR_DEMO = '(function() {var a = 1; a++;var c = function(){var ss = [];ss.map(i => i)}; var d= new c();if (a) { console.log(a); } switch(a) { case 1: break; } return x && b || 1})()';

export default {
  components: { selectMeta },
  data() {
    return {
      operator: '插入运算符',
      func: '插入函数',
      objectId: null,
      object: '',
      verison: '',
      currentType: '',
      objectInfo: {},
      fieldList: [],
      customPos: false,
      showCondition: false, // 是否显示条件编辑
      // 规则报错提示配置
      ruleTips: {
        is_valid: null,
        err_code: '',
        err_msg: '', // 错误信息
      },
      // 条件报错提示配置
      conditionTips: {
        is_valid: null,
        err_code: '',
        err_msg: '', // 错误信息
      },
      form: {
        id: undefined,
        name: '',
        desc: '',
        condition: '',
        rule: '',
        // rule_text: 'CURRENT_TIME + (1.2 - hcp.hco_id.id) < 2 && IN(hcp.hco_id, 1, 2) || true',
        rule_text: '',
        condition_text: '',
        level: '',
        tip_pos: '',
        tip_msg: '',
        enable: true,
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入'));
                return;
              }
              callback();
            },
            message: '请输入',
            trigger: 'blur',
          },
        ],
        tip_msg: [{ required: true, message: '请输入提示消息', trigger: 'blur' }],
      },
      selectMeta: false,
      operatorOptions: [
        { label: '加+', value: '+' },
        { label: '减-', value: '-' },
        { label: '乘*', value: '*' },
        { label: '除/', value: '/' },
        { label: '括号()', value: '()' },
        { label: '等于', value: '===' },
        { label: '不等于', value: '!==' },
        { label: '大于', value: '>' },
        { label: '大于等于', value: '>=' },
        { label: '小于', value: '<' },
        { label: '小于等于', value: '<=' },
        { label: '与', value: '&&' },
        { label: '或', value: '||' },
      ],
      funcList: [
        {
          label: '操作判断',
          options: [
            { label: '是否新增操作', value: 'IS_INSERT()' },
            { label: '是否编辑操作', value: 'IS_UPDATE()' },
          ],
        },
        {
          label: '字符串',
          options: [
            { label: '非空值判断', value: 'NOT_BLANK()' },
            { label: '正则匹配', value: 'REGEX_MATCH()' },
            { label: '字符串分隔长度 ', value: 'SPLIT_LENGTH()' },
          ],
        },
        {
          label: '聚合函数',
          options: [
            { label: '计数', value: 'COUNT()' },
            { label: '求和', value: 'SUM()' },
          ],
        },
        {
          label: '其他',
          options: [
            { label: '是否不属于', value: 'NOT_IN()' },
            { label: '格式化时间戳', value: 'FORMAT_TIMESTAMP()' },
            { label: '非空判断', value: 'NOT_NULL()' },
            { label: '是否属于', value: 'IN()' },
          ],
        },
      ],
      globalVar: [
        { label: '当前时间戳(单位：秒)', value: 'CURRENT_TIME' },
        { label: '当前日期(0点时间戳)', value: 'CURRENT_DATE' },
        { label: '当前用户uin', value: 'CURRENT_USER_UIN' },
        { label: '当前用户岗位code', value: 'CURRENT_USER_TERRITORY' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        metaManage: this.getElementList.includes('meta-manage'),
      };
    },
    saveDisabled() {
      if (
        ['name', 'rule_text', 'tip_msg'].some((key) => {
          return !this.form[key];
        })
      ) {
        return true;
      }
      return false;
    },
  },
  async created() {
    const { object_id: objectId, rule_id: ruleId, object, verison } = this.$route.query;
    this.objectId = objectId;
    this.object = object;
    this.verison = verison;
    if (!this.objectId) {
      this.$message.error('缺少必要参数');
      this.$router.go(-1);
      return;
    }
    if (!this.object) {
      this.$message.error('缺少必要参数');
      this.$router.go(-1);
      return;
    }
    await this.getFieldList();
    this.GetObjectDetail();
    this.form.id = ruleId;
    if (this.form.id) {
      this.getDetail();
    }
  },
  methods: {
    showMetaPopup(type) {
      this.selectMeta = true;
      this.currentType = type;
    },
    change(e, type) {
      this.insertion(type, e);
    },
    // type: rule 规则输入框 condition 条件输入框
    // str 要插入的文字
    insertion(type = 'rule', str = '') {
      let selector = '';
      let field = '';
      switch (type) {
        case 'rule':
          selector = '.rule_text .el-textarea__inner';
          field = 'rule_text';
          break;
        case 'condition':
          selector = '.condition_text .el-textarea__inner';
          field = 'condition_text';
      }
      const dom = document.querySelector(selector);
      this.form[field] =
        this.form[field].substring(0, dom.selectionStart) +
        str +
        this.form[field].substring(dom.selectionEnd, dom.textLength);
    },
    async GetObjectDetail() {
      try {
        const { object } = await this.$request({
          url: this.$api.metaGetObjectDetail,
          data: {
            id: this.objectId,
          },
        });
        this.objectInfo = object;
      } catch (err) {
        console.log(err);
      }
    },
    async getFieldList() {
      const getObjecFields = this.$request({
        url: this.$api.metaGetObjectFields,
        data: {
          object_id: this.objectId,
        },
      });
      const getObjectList = this.$request({
        url: this.$api.metaGetObjectList,
        data: {
          object_name: this.object,
        },
      });
      const [{ fields }, objectList] = await Promise.all([getObjecFields, getObjectList]);
      const relations = objectList?.objects?.[0]?.relations || [];
      this.fieldList = [
        ...fields,
        ...relations.map((item, index) => ({
          id: index,
          name: item.virtual_field,
        })),
      ];
    },
    async getDetail() {
      try {
        const { rule } = await this.$request({
          url: this.$api.getValidateRuleDetail,
          data: {
            id: this.form.id,
          },
        });
        this.form = rule;
        if (!['', null, 'null'].includes(rule.condition)) {
          this.showCondition = true;
        }
        if (this.form.tip_pos && !this.fieldList.find((i) => i.name === this.form.tip_pos)) {
          this.customPos = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    verification(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    back() {
      this.$router.go(-1);
    },
    async checkSyntax(type = 'rule') {
      const codeStr = type === 'rule' ? this.form.rule_text : this.form.condition_text;
      const isValidAst = await this.checkAst(type, codeStr);
      if (!isValidAst) {
        return;
      }
      const ast = transformer(codeStr, this.object);
      if (type === 'rule') {
        // if (this.form.rule_text === '') {
        //   this.ruleTips = {
        //     is_valid: false,
        //     err_code: '',
        //     err_msg: '请输入规则', // 错误信息
        //   };
        //   return;
        // }
        try {
          const res = await this.$request({
            url: this.$api.checkValidateRule,
            data: {
              object: this.objectInfo.name,
              rule: JSON.stringify(ast),
            },
          });
          this.ruleTips = res;
          return this.ruleTips.is_valid;
        } catch (err) {
          console.log(err);
          this.ruleTips = {
            is_valid: false,
            err_code: '',
            err_msg: '检查不通过', // 错误信息
          };
        }
      } else if (type === 'condition') {
        // if (this.form.condition === '') {
        //   this.conditionTips = {
        //     is_valid: false,
        //     err_code: '',
        //     err_msg: '请输入条件', // 错误信息
        //   };
        //   return;
        // }
        try {
          const res = await this.$request({
            url: this.$api.checkValidateRule,
            data: {
              object: this.objectInfo.name,
              rule: JSON.stringify(ast),
            },
          });
          this.conditionTips = res;
          return this.conditionTips.is_valid;
        } catch (err) {
          console.log(err);
          this.conditionTips = {
            is_valid: false,
            err_code: '',
            err_msg: '检查不通过', // 错误信息
          };
        }
      }
    },
    async checkAst(type = 'rule', codeStr = '') {
      try {
        this.astErrTip = '';
        const { isValidAst, astErrTip } = await getAstFromStr(codeStr, this.object);
        if (!isValidAst) {
          const errTip = {
            is_valid: false,
            err_code: '',
            err_msg: astErrTip, // 错误信息
          };
          if (type === 'rule') {
            this.ruleTips = errTip;
          } else {
            this.conditionTips = errTip;
          }
        }
        return isValidAst;
      } catch (err) {
        const errTip = err.message || '语法检查异常';
        if (type === 'rule') {
          this.ruleTips = errTip;
        } else {
          this.conditionTips = errTip;
        }
        return false;
      }
    },
    async saveRule() {
      let url = this.$api.greateValidateRule;
      if (this.form.id) {
        // 编辑
        url = this.$api.updateValidateRule;
      }
      if (!this.showCondition) {
        this.form.condition = '';
        this.form.condition_text = '';
      } else {
        const isValid = await this.checkSyntax('condition');
        if (!isValid) {
          return;
        }
        this.form.condition = JSON.stringify(transformer(this.form.condition_text, this.object));
      }
      if (this.form.rule_text) {
        const isValid = await this.checkSyntax('rule');
        if (!isValid) {
          return;
        }
        this.form.rule = JSON.stringify(transformer(this.form.rule_text, this.object));
      }
      await this.$request({
        url,
        data: {
          object: this.object,
          rule: {
            ...this.form,
          },
        },
      });
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-edit {
  position: relative;
  height: calc(100% - 44px);
  .rule-content {
    height: 100%;
    overflow-y: auto;
    .title {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      margin: 22px 0;
      &.required::before {
        content: '*';
        color: #e7403a;
      }
    }
    .condition-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #6a7684;
      display: inline-block;
      padding-right: 16px;
    }
    .tip {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin: 16px 0 24px;
    }
    .rule-form {
      ::v-deep .el-form-item {
        // width: 100%;
        .el-form-item__content {
          //   width: calc(100% - 72px);
          .rule-input {
            width: 240px;
          }
        }
      }
    }
    ::v-deep .el-card {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ::v-deep .el-cascader {
      width: 320px;
    }
  }
  .footer {
    position: absolute;
    right: -20px;
    bottom: -64px;
    left: -20px;
    height: 64px;
    padding: 0 64px;
    display: flex;
    align-items: center;
  }
  .level-interval {
    margin-right: 16px;
  }
  .vertical-interval {
    margin-bottom: 16px;
  }
  .tip-pos {
    display: flex;
    justify-content: flex-start;
  }
  .custom-pos {
    margin: 11px 0 0 10px;
  }
}
</style>
