const blacklist = ['create_by', 'id', 'tag', 'tenant_id', 'version'];
const backData = [undefined, null];
// 禁用action名单
const disableList = ['delete', 'DELETE'];

function keys({ from, to }) {
  const oldVal = from || {};
  const newVal = to || {};
  const keys = [...new Set([...Object.keys(oldVal || {}), ...Object.keys(newVal || {})])];
  const arr = keys
    .sort(function (s, t) {
      const a = s.toLowerCase();
      const b = t.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .filter((key) => {
      return !blacklist.includes(key);
    });

  return { keys: arr, list: [oldVal, newVal] };
}
export function getValue(val) {
  if (val === null) {
    return 'null';
  }
  switch (typeof val) {
    case 'string':
      return `'${val}'`;
    case 'boolean':
      return val ? 'true' : 'false';
    case 'number':
      return `${val}`;
    case 'undefined':
      return '';
    default:
      return JSON.stringify(val);
  }
}

export function getData(list) {
  return list.map((item1) => {
    const children = [];
    // 对象
    if (!backData.includes(item1.from) || !backData.includes(item1.to)) {
      const disabled = disableList.includes(item1.action);
      children.push({
        action: item1.action,
        disabled,
        changes: item1.changes,
        diff_id: disabled ? undefined : item1.diff_id,
        label: item1.object_name,
        children: [{ label: '字段差异列表', fields: keys(item1) }],
        tier: 'object',
      });
    }
    // 字段
    const fieldsChildren = item1.fields.map((item2) => {
      const disabled = disableList.includes(item2.action);
      return {
        action: item2.action,
        disabled,
        changes: item2.changes,
        diff_id: disabled ? undefined : item2.diff_id,
        label: item2.field_name,
        children: [{ label: '字段差异列表', fields: keys(item2) }],
        tier: 'fields',
      };
    });
    if (fieldsChildren.length) {
      children.push({ label: 'fields', children: fieldsChildren });
    }
    // 索引
    const indexesChildren = item1.indexes.map((item2) => {
      const disabled = disableList.includes(item2.action);
      return {
        action: item2.action,
        disabled,
        changes: item2.changes,
        diff_id: disabled ? undefined : item2.diff_id,
        label: item2.index_name,
        children: [{ label: '字段差异列表', fields: keys(item2) }],
        tier: 'indexes',
      };
    });
    if (indexesChildren.length) {
      children.push({ label: 'indexes', children: indexesChildren });
    }
    return {
      action: item1.action,
      changes: item1.changes,
      label: item1.object_name,
      children,
    };
  });
}

const arrGetNodes = (arr) => {
  const data = arr.map((item) => {
    if (item.tier) {
      // 节点级
      if (disableList.includes(item.action)) {
        return;
      }
      return `${item.tier}:${item.action}:${item.diff_id}:${item.label}`;
    }
    if (Array.isArray(item)) {
      return arrGetNodes(item);
    }
    if (item.children) {
      const childrenNodes = arrGetNodes(item.children);
      if (childrenNodes.length) {
        return childrenNodes;
      }
      return;
    }
  });
  return data.filter((item) => item);
};

export const getAllNodes = (data = []) => {
  if (data.length === 0) {
    return [];
  }
  return arrGetNodes(data)
    .join()
    .split(',')
    .map((item) => {
      return {
        tier: item.split(':')[0],
        action: item.split(':')[1],
        diff_id: item.split(':')[2],
        label: item.split(':')[3],
      };
    });
};

export const filterTree = (data = [], operate = []) => {
  const arr = data.map((item) => {
    const { children, tier, action } = item;
    if (tier) {
      if (operate.includes(action)) {
        return JSON.parse(JSON.stringify(item));
      }
      return;
    }
    if (children) {
      const newChildren = filterTree(children, operate);
      if (newChildren.length > 0) {
        return {
          ...item,
          children: newChildren,
        };
      }
    }
    return;
  });
  return arr.filter((item) => item);
};
