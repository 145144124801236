<template>
  <el-card class="box-card">
    <el-page-header @back="goBack" content="索引记录"></el-page-header>
    <br />
    <br />
    <!-- 表单 -->
    <el-button type="primary" @click="openAddDialog" v-if="jurisdiction.metaManage">新增</el-button>
    <!-- 列表 -->
    <el-table :data="list" style="width: 100%" v-loading="loading">
      <el-table-column prop="" label="id">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.id">
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="index_name" label="索引名称 " />
      <el-table-column prop="description" label="描述 " />
      <el-table-column prop="object_id" label="对象id">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.object_id">
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="object" label="对象名" />
      <el-table-column prop="tenant_id" label="租户id" />
      <el-table-column prop="fields" label="字段列表">
        <template slot-scope="scope">
          {{ scope.row.fields.join(',') }}
        </template>
      </el-table-column>
      <el-table-column label="是否唯一约束">
        <template slot-scope="scope">
          {{ scope.row.is_unique ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="更新于" align="center">
        <template slot-scope="scope">
          <el-tooltip :content="`${scope.row.update_by}, ${humanifyDate(scope.row.update_time)}`">
            <span>{{ relativeDate(scope.row.update_time) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="" label="创建于" align="center">
        <template slot-scope="scope">
          <el-tooltip :content="`${scope.row.create_by}, ${humanifyDate(scope.row.create_time)}`">
            <span>{{ relativeDate(scope.row.create_time) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        align="center"
        width="200"
        v-if="jurisdiction.metaManage"
      >
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="itemEdit(scope.row)">编辑</el-button> -->
          <el-button type="text" size="small" @click="itemDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增弹窗 -->
    <el-dialog
      title="新增索引"
      :visible.sync="addDialog"
      width="700px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="addForm" :model="addForm" label-width="100px" :rules="rules">
        <el-form-item label="索引名称:" prop="index_name">
          <el-input
            v-model="defaultIndexName"
            placeholder="请输入"
            disabled
            v-if="addForm.useDefault"
          >
            <template slot="prepend">{{ prefix }}</template>
          </el-input>
          <el-input v-model="addForm.index_name" placeholder="请输入" v-else>
            <template slot="prepend">{{ prefix }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="是否随机名称:">
          <el-radio-group v-model="addForm.useDefault">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述:">
          <el-input
            v-model="addForm.description"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型:">
          <el-select v-model="addForm.type" placeholder="请选择">
            <el-option label="BTREE" value="BTREE" />
            <el-option label="GIN_FT" value="GIN_FT" />
          </el-select>
        </el-form-item>
        <el-form-item label="字段列表:">
          <el-select v-model="addForm.fields" placeholder="请选择" multiple>
            <el-option
              v-for="item in fieldList"
              :label="item.name"
              :value="item.name"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="唯一约束:">
          <el-radio-group v-model="addForm.is_unique" @change="uniqueChange">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
export default {
  data() {
    return {
      loading: false,
      object_id: null,
      objectInfo: null,
      fieldList: [], // 字段列表
      list: [],
      // 新增
      addDialog: false,
      addForm: {
        index_name: '',
        description: '',
        object_id: '',
        fields: [],
        is_unique: false,
        useDefault: false,
      },
      prefix: 'idx_', // index_name idx_开头，如果is_unique为true就uidx_开头
      rules: {
        index_name: [
          {
            validator: (rule, value, callback) => {
              if (this.prefix.length + value.length > 63) {
                callback(new Error('字数超过限制'));
                return;
              }
              callback();
            },
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList']),
    jurisdiction() {
      return {
        metaManage: this.getElementList.includes('meta-manage'),
      };
    },
    defaultIndexName() {
      const time = Number.parseInt(Date.parse(new Date()) / 1000);
      // 默认索引名: 对象名，再加字段名用_相连,最后加个_时间戳(用户未输入)
      const indexName = `${this.objectInfo.name}_${this.addForm.fields.join('_')}_${time}`;
      const name = `${this.prefix}${indexName}`;
      if (name.length > 63) {
        return indexName.substring(0, 63 - this.prefix.length);
      }
      return indexName;
    },
  },
  created() {
    this.object_id = this.$route.query.object_id;
    this.getList(this.object_id);

    this.getObjectInfo(this.object_id);
    this.getFieldList(this.object_id);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    humanifyDate(str) {
      return dayjs(str).format('lll');
    },
    relativeDate(str) {
      return dayjs(str).fromNow();
    },
    getList(object_id = this.object_id) {
      this.loading = true;
      this.$request({
        url: this.$api.metaGetIndexesByObjectID,
        data: { object_id },
        cb: ({ index }) => {
          this.list = index;
          this.loading = false;
        },
      });
    },
    // 获取表信息
    getObjectInfo(objectId) {
      this.$request({
        url: this.$api.metaGetObjectDetail,
        data: { id: objectId },
        cb: ({ object }) => {
          this.objectInfo = object;
        },
      });
    },
    // 获取字段列表
    getFieldList(objectId) {
      this.$request({
        url: this.$api.metaGetObjectFields,
        data: { object_id: objectId },
        cb: ({ fields }) => {
          this.fieldList = fields;
        },
      });
    },
    // 新增
    openAddDialog() {
      this.prefix = 'idx_';
      this.addForm = {
        index_name: '',
        description: '',
        object_id: '',
        fields: [],
        is_unique: false,
        type: 'BTREE',
        useDefault: false,
      };
      this.$nextTick(() => {
        this.addDialog = true;
      });
    },
    uniqueChange(value) {
      switch (value) {
        case false:
          this.prefix = 'idx_';
          break;
        case true:
          this.prefix = 'uidx_';
      }
    },
    add() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const { index_name: indexName, useDefault } = this.addForm;
          let name = '';
          if (useDefault) {
            name = this.defaultIndexName;
          } else {
            // 未输入时使用默认索引名
            name = indexName || this.defaultIndexName;
          }
          const index = {
            ...this.addForm,
            object_id: this.object_id,
            index_name: `${this.prefix}${name}`,
          };
          delete index.useDefault;
          this.$request({
            url: this.$api.metaAddIndex,
            data: {
              index,
            },
            cb: () => {
              this.addDialog = false;
              this.getList();
            },
          });
        } else {
          return false;
        }
      });
    },
    // 删除
    itemDelete({ id }) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request({
            url: this.$api.metaDeleteIndex,
            data: { id },
            cb: () => {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            },
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
