<template>
  <div>
    <div class="node-title">节点ID</div>
    <el-input disabled :value="value.id" />
    <div class="node-title">节点名称</div>
    <el-input
      :value="value.fullname"
      maxlength="20"
      show-word-limit
      @input="(e) => valueUpdate('fullname', e)"
    />
    <div class="node-title">
      延时方式
      <span class="require">*</span>
      <el-tooltip
        effect="dark"
        content="延时至满足以下时间规则时, 跳出该节点，继续执行后续流程"
        placement="top"
      >
        <i class="iconfont icon-question" />
      </el-tooltip>
    </div>
    <el-radio
      :value="value.metadata.wait_time.type"
      label="abs"
      @input="(e) => delayWayChangeHandler(e)"
    >
      等待固定时间后
    </el-radio>
    <el-radio
      :value="value.metadata.wait_time.type"
      label="rel"
      @input="(e) => delayWayChangeHandler(e)"
    >
      指定时间表达式
    </el-radio>
    <div
      style="display: flex; align-items: center; justify-content: space-between; margin-top: 8px"
      v-if="value.metadata.wait_time.type === 'abs'"
    >
      <el-input-number
        size="mini"
        :min="0"
        :controls="false"
        :value="value.metadata.wait_time.value.day"
        @input="(e) => valueUpdate('metadata.wait_time.value.day', e)"
      ></el-input-number>
      天
      <el-input-number
        size="mini"
        :min="0"
        :max="24"
        :controls="false"
        :value="value.metadata.wait_time.value.hour"
        @input="(e) => valueUpdate('metadata.wait_time.value.hour', e)"
      ></el-input-number>
      时
      <el-input-number
        size="mini"
        :min="0"
        :max="60"
        :controls="false"
        :value="value.metadata.wait_time.value.minute"
        @input="(e) => valueUpdate('metadata.wait_time.value.minute', e)"
      ></el-input-number>
      分
    </div>
    <div class="time-inputbar" v-else>
      <el-input
        :value="value.metadata.wait_time.value.time"
        placeholder="请输入时间表达式：eg: meeting_stime"
        @input="(e) => valueUpdate('metadata.wait_time.value.time', e)"
        style="margin-bottom: 8px"
      />
      <el-select
        :value="value.metadata.wait_time.value.operator"
        placeholder="请选择"
        @change="(e) => valueUpdate('metadata.wait_time.value.operator', e)"
        style="width: 120px; margin-bottom: 8px"
      >
        <el-option
          v-for="item in [
            { label: '加+', value: '+' },
            { label: '减-', value: '-' },
          ]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <el-input-number
          size="mini"
          :min="0"
          :controls="false"
          :value="value.metadata.wait_time.value.day"
          @input="(e) => valueUpdate('metadata.wait_time.value.day', e)"
        ></el-input-number>
        天
        <el-input-number
          size="mini"
          :min="0"
          :max="24"
          :controls="false"
          :value="value.metadata.wait_time.value.hour"
          @input="(e) => valueUpdate('metadata.wait_time.value.hour', e)"
        ></el-input-number>
        时
        <el-input-number
          size="mini"
          :min="0"
          :max="60"
          :controls="false"
          :value="value.metadata.wait_time.value.minute"
          @input="(e) => valueUpdate('metadata.wait_time.value.minute', e)"
        ></el-input-number>
        分
      </div>
    </div>
    <div class="node-title">备注描述</div>
    <el-input
      type="textarea"
      :rows="2"
      :value="value.description"
      @input="(e) => valueUpdate('description', e)"
      maxlength="50"
      autocomplete="off"
      placeholder="请输入"
    ></el-input>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    valueUpdate(path, value) {
      this.$emit('update', path, value);
    },
    delayWayChangeHandler(e) {
      this.valueUpdate('metadata.wait_time.value.time', '');
      this.valueUpdate('metadata.wait_time.value.day', 0);
      this.valueUpdate('metadata.wait_time.value.hour', 0);
      this.valueUpdate('metadata.wait_time.value.minute', 0);
      this.valueUpdate('metadata.wait_time.type', e);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/input.scss';
@import '@/styles/element/inputNumber.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
.node-title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1c2028;
  font-weight: 700;
  .require {
    color: #e34d59;
  }
}
.time-inputbar {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f6f7fb;
}
</style>
