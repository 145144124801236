<template>
  <codemirror :value="value" :options="options" @input="(e) => $emit('update', e)" />
</template>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/theme/ambiance.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/theme/idea.css';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/display/placeholder.js';

export default {
  name: 'VueCodeEditor',
  components: { codemirror },
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
