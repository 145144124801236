export const statusOptions = {
  inactivated: '无效',
  activated: '有效',
  freezed: '冻结',
};

let number1timeId = null;
export const validatePass = (rule, value, callback) => {
  if (number1timeId) {
    clearTimeout(number1timeId);
  }
  number1timeId = setTimeout(() => {
    if (value.length < 8 || value.length > 30) {
      callback(new Error('密码长度需在8到30之间'));
      return;
    }
    let numberCount = 0; // 数字
    let lowerCount = 0; // 小写
    let upperCount = 0; // 大写
    let symbolCount = 0; // 符号
    for (let i = 0; i < value.length; i++) {
      const code = value.charCodeAt(i);
      if (code === 32) {
        callback(new Error('不能包含空格'));
        return;
      }
      if (code < 33 || code > 126) {
        callback(new Error(`非法字符:${value[i]}`));
        return;
      }
      if (code > 47 && code < 58) {
        numberCount = 1;
      } else if (code > 96 && code < 123) {
        lowerCount = 1;
      } else if (code > 64 && code < 91) {
        upperCount = 1;
      } else {
        symbolCount = 1;
      }
    }
    const typeCount = numberCount + lowerCount + upperCount + symbolCount;
    if (typeCount > 2) {
      callback();
    } else {
      callback(new Error('必须包含大小写字母、数字、符号中至少3种'));
    }
  }, 300);
};
const regExp = new RegExp('^1[3578]\\d{9}$');
export const validatePhone = (rule, value, callback) => {
  if (!regExp.test(value)) {
    // 引入methods中封装的检查手机格式的方法
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};

export const typeOptions = {
  admin: '管理员',
  staff: '员工',
  doctor: '医生',
  dev_ops: '开发运维',
};
