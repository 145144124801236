<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { setBrowserConfig } from '@/utils/browser';

const getHostFromUrl = function (urlStr) {
  try {
    const url = new URL(decodeURIComponent(urlStr));
    return url.host;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export default {
  name: 'App',
  async mounted() {
    const { state } = this.$route.query;
    if (localStorage.getItem('auth_code') && state) {
      const { target_uri } = this.$route.query;
      this.redirectUrl = target_uri || '';
      if (state) {
        let curState;
        try {
          curState = JSON.parse(state);
        } catch (e) {
          console.error(e);
          curState = {};
        }
        this.redirectUrl = curState?.target_uri || this.redirectUrl;
      }
    } else {
      const { redirect_uri } = this.$route.query; // 中间页
      this.redirectUrl = redirect_uri || '';
    }
    try {
      const appConfig = await this.$request({
        url: this.$api.getAppConfig,
        data: {
          domain: getHostFromUrl(this.redirectUrl) || document?.domain,
        },
        config: {
          timeout: 1000 * 30, // 超时120s
          showErrMessage: false, // 不在接口展示报错信息
        },
      });
      console.log('appConfig', appConfig);
      setBrowserConfig(appConfig);
    } catch (e) {
      console.log(e);
    }
    if (
      this.$cookies.get('saas_token') &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'callback'
    ) {
      this.GetUserElementList();
      this.GetAppOption();
      this.GetCurrentEnv();
    }
  },
  methods: {
    ...mapActions('user', ['GetUserElementList', 'GetAppOption', 'GetCurrentEnv']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  // 鼠标移入高亮
  tbody .hover-row > td {
    background-color: #f6f7fb;
  }
  margin-bottom: 8px;

  .el-table__header {
    th {
      border-bottom: 1px solid #ebeef5;
      padding: 12px 0;
    }
  }
  .el-table__row {
    td {
      padding: 12px 0;
      border-bottom: 1px solid #ebeef5;
      .cell {
        text-align: center;
      }
    }
  }
  th.gutter {
    display: table-cell !important;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}
::-webkit-scrollbar-button {
  background: transparent;
  height: 0;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 16px;
  border-radius: 16px;
  background-color: hsla(0, 0%, 84%, 0.6);
}
</style>
