import { Message } from 'element-ui';
import { formatSdl } from 'format-graphql';
import config from '@/config';
import { ERROR_BIZCODE_LIST, GQL_SPECIAL_CHAR } from '@/utils/constant';
const storeData = {};

const gotoUrl = function (url, oriOptions) {
  if (!url) {
    return;
  }
  const options = oriOptions || {};
  const { replace = false, href = false, open = false, name = false } = options;
  if (open) {
    window.open(url);
    return;
  }
  if (href) {
    if (storeData.href) return;
    storeData.href = true;
    window.location.href = url;
    return;
  }
  window.Vue.routerGo(name ? { name: url } : { path: url }, replace);
};
/**
 * 阿拉伯号转中字
 * @param {Number} num 数字
 */
const getChinese = (num) => {
  const changeNum = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const unit = ['', '十', '二十', '三十', '四十', '五十', '六十', '七十', '八十', '九十', '一百'];
  const newNum = parseInt(num, 10);
  let res = num;
  if (newNum >= 10) {
    const a = String(num)[0];
    const b = String(num)[1];
    res = unit[a] + changeNum[b];
  } else {
    res = changeNum[res];
  }

  return res;
};

/**
 * 数组去重
 * @param {*} arr 接收的原数组
 * @param {*} key 如果是对象数组[{id: 1}, {id: 2}, {id: 3}]，则需要以什么key作为重复的标准，普通数组[1,2,3,2]不需要
 */
const arrUnique = function (arr, key) {
  let returnArr = [];
  if (key) {
    // 对象数组去重
    const obj = {};
    returnArr = arr.reduce((cur, next) => {
      obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next));
      return cur;
    }, []);
    return returnArr;
  }
  // 普通数组去重
  returnArr = arr.reduce((cur, next) => {
    !cur.includes(next) && cur.push(next);
    return cur;
  }, []);
  return returnArr;
};
const loadJs = function (options = {}) {
  const { url, onload, onerror, retry } = options;
  const el = document.createElement('script');
  el.src = url;
  if (typeof onload === 'function') {
    el.onload = onload;
  }
  el.onerror = function (...args) {
    if (typeof onerror === 'function') {
      onerror.call(this, ...args);
    }
    if (retry > 0) {
      loadJs({ url, onload, onerror, retry: retry - 1 });
    }
  };
  document.body.appendChild(el);
};
const mapQuery = function (query) {
  if (Object.prototype.toString.call(query) === '[object Object]') {
    let paramsStr = '';
    // for (const key in query) {
    //   const value = query[key];
    //   if (value !== undefined && value !== null && value !== '') {
    //     paramsStr += `&${key}=${value}`;
    //   }
    // }
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined && value !== null && value !== '') {
        paramsStr += `&${key}=${value}`;
      }
    });
    return paramsStr.replace('&', '?');
  }
};
const gotoHome = function () {
  gotoUrl('/home', { replace: true, href: true });
};

const formatSecondsToMin = (seconds) => {
  if (!seconds) return 0;
  const min = parseInt(seconds / 60, 10);
  const s = seconds % 60;
  return `${min ? `${min}min` : ''} ${s ? `${s}s` : ''}`;
};
const formatMinsToHour = (mins) => {
  if (!mins) return 0;
  const hour = parseInt(mins / 60, 10);
  const min = mins % 60;
  return `${hour ? `${hour}hour` : ''} ${min ? `${min}min` : ''}`;
};
/**
 * 等待值
 * @param {*} key key名
 * @param {*} that 对象 this
 * @param {*} total 等待次数
 */
const awaitInterfaceResponse = (key, that, total) => {
  return new Promise((resolve, reject) => {
    let time = 1;
    let interval = 100;
    const totalTime = total || 10;
    const pollingValue = () => {
      // 先判断有没有值
      const value = that ? that[key] : key;
      if (value) {
        resolve();
      } else {
        console.log(time);
        if (time < totalTime) {
          interval += 100;
          time += 1;
          setTimeout(() => {
            pollingValue();
          }, interval);
        } else {
          reject();
        }
      }
    };
    pollingValue();
  });
};

/** 文件名后缀切割
 * @description: 传入包含后缀的文件名
 * @param {String} value eg: 前端进阶指南.png
 * @return {Object} { prefix, postfix } 前缀 后缀
 */
const postfixFun = (value) => {
  const temp = value;
  const arr = temp.split('.');
  const postfix = `.${arr[arr.length - 1]}`; // 后缀
  const prefix = temp.replace(postfix, ''); // 前缀
  return { prefix, postfix };
};
const getMobileDomain = function () {
  if (config.mobileDomain) return config.mobileDomain;
  const domainArr = window.location.host.split('.');
  return [`${domainArr[0]}h5`].concat(domainArr.slice(1)).join('.');
};

const bytesToSize = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1000; // or 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / k ** i).toPrecision(3)}${sizes[i]}`;
};

const downloadFileAction = (urltemp, fileName) => {
  return new Promise((resolve, reject) => {
    // const urltemp =
    //   'https://tsep-test-1304685099.cos.ap-guangzhou.myqcloud.com/tsep_dev/11501202/2fbd5f8d-ecae-471c-8856-d53624e06e72';
    const url2 = urltemp.replace(/\\/g, '/');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url2, true);
    xhr.responseType = 'blob';
    // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    // 为了避免大文件影响用户体验，建议加loading
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        console.log(xhr);
        console.log(xhr.response);
        const url = window.URL.createObjectURL(new Blob([xhr.response]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        resolve(true);
        return;
      }
      reject(false);
    };
    xhr.send();
  });
};

const dateFormat = (fmt, date) => {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  }
  return fmt;
};

function downloadAndRename(url, name) {
  function getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);

      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };

      xhr.send();
    });
  }
  function saveAs(blob, filename) {
    // ie的下载
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      // 非ie的下载
      const link = document.createElement('a');
      const body = document.querySelector('body');

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // fix Firefox
      link.style.display = 'none';
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    }
  }
  return getBlob(url).then((blob) => {
    saveAs(blob, name);
  });
}

// 类型判断
function toStringCall(obj) {
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  };
  return map[window.Object.prototype.toString.call(obj)];
}

function handleError(e) {
  console.log(e);
  if (e.bizcode) return ERROR_BIZCODE_LIST[e.bizcode];
  return e.message;
}

function gqlCharReplace(str) {
  let string = str;
  GQL_SPECIAL_CHAR.forEach((char) => {
    string = string.replace(new RegExp(char, 'g'), `\\\\${char}`);
  });
  return string;
}

function getValue(obj, keyName) {
  if (!obj) return null;
  const keys = `${keyName}`.split('.');
  let tempObj = obj;
  for (let i = 0; i < keys.length; i++) {
    if (!tempObj) return;
    if (keys[i] !== '') {
      tempObj = tempObj && tempObj[[keys[i]]];
    }
  }
  return tempObj;
}
/**
 * 复制功能
 * text 传入要复制的文本
 * callback 复制完成后的回调
 */
function copyText(text, callback) {
  // 实现复制方法一：
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    callback && callback(true);
    return;
  }
  // 实现复制方法二：
  let copyInput = document.getElementById('COPY_INPUT');
  if (!copyInput) {
    copyInput = document.createElement('input');
    copyInput.setAttribute('id', 'COPY_INPUT');
    copyInput.style.position = 'fixed';
    copyInput.style.left = '-100%';
    copyInput.style.top = '0';
    copyInput.style.zIndex = -100;
    copyInput.style.opacity = 0;
    document.body.appendChild(copyInput);
  }
  copyInput.value = text;
  copyInput.focus();
  copyInput.select();
  // document.execCommand 可能会被废弃
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  copyInput.blur();
  callback && callback(true);
}
/**
 * 计算字符串的长度
 * @param {string} str 指定的字符串
 */
function calcStrLen(str) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
      len++;
    } else {
      len += 2;
    }
  }
  return len;
}

/**
 * 计算显示的字符串
 * @param {string} str 要裁剪的字符串
 * @param {number} maxWidth 最大宽度
 * @param {number} fontSize 字体大小
 */
function fittingString(str, maxWidth, fontSize) {
  const fontWidth = fontSize * 1.3; // 字号+边距
  maxWidth = maxWidth * 2; // 需要根据自己项目调整
  const width = calcStrLen(str) * fontWidth;
  const ellipsis = '…';
  if (width > maxWidth) {
    const actualLen = Math.floor((maxWidth - 10) / fontWidth);
    const result = str.substring(0, actualLen) + ellipsis;
    return result;
  }
  return str;
}

// 多级对象合并
function mergeObject({ sources = {}, target = {} }) {
  const obj = JSON.parse(JSON.stringify(target));
  if (typeof target !== 'object' || typeof sources !== 'object') {
    return sources; // 如果其中一个不是对象 就返回sources
  }
  for (const key in sources) {
    // 如果target也存在 那就再次合并
    // eslint-disable-next-line no-prototype-builtins
    if (target.hasOwnProperty(key)) {
      obj[key] = mergeObject({
        target: target[key],
        sources: sources[key],
      });
    } else {
      // 不存在就直接添加
      obj[key] = sources[key];
    }
  }
  return obj;
}

function formatGraphQL(str, tips = false) {
  let formatStr = str;
  try {
    formatStr = formatSdl(formatStr.replace('.', '__________')).replace('__________', '.');
  } catch (e) {
    if (tips) {
      Message({
        message: e.message,
        type: 'warning',
      });
    }
  }
  return formatStr;
}

// 将blob对象创建bloburl，然后用a标签实现弹出下载框
function openDownloadDialog(blob, fileName) {
  if (typeof blob === 'object' && blob instanceof Blob) {
    blob = URL.createObjectURL(blob); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = blob;
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || '';
  let event;
  if (window.MouseEvent) event = new MouseEvent('click');
  //   移动端
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
  }
  aLink.dispatchEvent(event);
}
// 节流函数
function throttle(fn, delay) {
  let timer;
  return function () {
    const _this = this;
    const args = arguments;
    if (timer) {
      return;
    }
    timer = setTimeout(function () {
      fn.apply(_this, args);
      timer = null;
    }, delay);
  };
}
// 计算两点之间距离
function twoPointDistance(p1, p2) {
  const dep = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
  return dep;
}
// 生成n位数字字符串
function randomNum(n) {
  let res = '';
  for (let i = 0; i < n; i++) {
    res += Math.floor(Math.random() * 10);
  }
  return res;
}
export {
  gotoUrl,
  getChinese,
  arrUnique,
  loadJs,
  mapQuery,
  gotoHome,
  formatSecondsToMin,
  formatMinsToHour,
  awaitInterfaceResponse,
  postfixFun,
  getMobileDomain,
  bytesToSize,
  downloadFileAction,
  dateFormat,
  downloadAndRename,
  toStringCall,
  handleError,
  gqlCharReplace,
  getValue,
  copyText,
  mergeObject,
  formatGraphQL,
  openDownloadDialog,
  fittingString,
  throttle,
  twoPointDistance,
  randomNum,
};

export const getTdesignFormValidateResult = (tFormValidate) => {
  let isCurrect = true;
  Object.keys(tFormValidate).forEach((key) => {
    const keyValidate = tFormValidate[key];
    let keyIsCurrect = true;
    keyValidate.forEach((rule) => {
      if (!rule.result) {
        keyIsCurrect = false;
      }
    });
    if (!keyIsCurrect) {
      isCurrect = false;
    }
  });
  return isCurrect;
};
