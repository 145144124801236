<template>
  <div>
    <!-- json编辑框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      width="800px"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-dialog
        title="代码修改对比"
        :visible.sync="innerDialogVisibility"
        append-to-body
        width="1400px"
      >
        <div class="compare-container">
          <div class="top-line">
            <div class="btns">
              <button class="prev" @click="toPrevDiff">上一个</button>
              <button class="next" @click="toNextDiff">下一个</button>
              <button class="next" @click="toSearch">搜索</button>
              <button class="next" @click="toTop">置顶</button>
              <button class="next" @click="toBottom">置底</button>
              <button class="next" @click="toSave">保存</button>
            </div>
            <div class="summary">{{ diffSummary }}</div>
          </div>
          <div class="search-bar" v-if="showSearchBar">
            <div class="lhs">
              <input
                placeholder="🔍"
                @keyup.enter="searchCall('lhs')"
                type="text"
                v-model="lhsSC"
              />
            </div>
            <div class="rhs">
              <input
                placeholder="🔍"
                @keyup.enter="searchCall('rhs')"
                type="text"
                v-model="rhsSC"
              />
            </div>
          </div>
          <div id="mergely" class="mergely-editor"></div>
        </div>
      </el-dialog>
      <!-- json 编辑器 -->
      <template>
        <el-switch
          class="switch"
          v-model="useMonacoEditor"
          active-text="新编辑器（0.1-beta）"
          inactive-text="原编辑器"
          @change="handleChangeSwitch"
        ></el-switch>
        <div v-show="!useMonacoEditor" id="jsoneditor"></div>
        <!-- <awesome-editor v-if="useMonacoEditor" :value="jsonData" @change="handleChange" /> -->
        <iframe v-if="useMonacoEditor" ref="iframe" class="iframe-container" src="/json-edit" />
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button @click="copyText" type="info">复 制</el-button>
          <el-button type="primary" @click="openDiff" v-if="type === 'diff'">
            对比当前版本
          </el-button>
          <el-button type="primary" @click="submit" v-if="['edit', 'diff'].includes(type)">
            保 存
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 数据差异展示 -->
    <!-- <code-diff
      :show="showDiff"
      :old-string="oldStr"
      :new-string="newStr"
      :context="10"
      output-format="side-by-side"
      @cancel="showDiff = false"
    /> -->
  </div>
</template>

<script>
import JSONEditor from 'jsoneditor';
// import CodeDiff from '@/components/codeDiff';
import { copyText } from '@/utils/util';
export default {
  components: {
    // AwesomeEditor: () => import('../awesomeEditor'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'view',
    },
    mode: {
      type: String,
      default: 'code',
    },
    json: {
      type: String,
      default: '',
    },
    oldJson: {
      type: String,
      default: '',
    },
    showJson: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      innerDialogVisibility: false,
      editor: null, // 编辑器对象
      jsonData: `{}`,
      // showDiff: false,
      diffSummary: '',
      useMonacoEditor: false, // 带json schema的编辑器
      showSearchBar: false,
      lhsSC: '',
      rhsSC: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.showJson;
      },
      set(value) {
        if (!value) {
          this.cancel();
          // this.$nextTick(() => {
          //   this.showDiff = false;
          // });
        }
      },
    },
    dialogTitle() {
      switch (this.type) {
        case 'view':
          return `导出JSON: ${this.title}`;
        case 'edit':
          return `导入JSON: ${this.title}`;
        case 'diff':
          return `布局JSON: ${this.title}`;
      }
      return `导出JSON: ${this.title}`;
    },
    oldStr() {
      let data = this.oldJson;
      try {
        data = this.json ? JSON.stringify(JSON.parse(this.oldJson), null, '\t') : data;
      } catch (err) {
        console.log(err);
      }
      return data;
    },
    newStr() {
      try {
        return JSON.stringify(JSON.parse(this.jsonData), null, '\t');
      } catch (err) {
        console.log(err);
        return this.jsonData;
      }
    },
  },
  created() {
    this.jsonData = this.json || '{}';
  },
  mounted() {
    setTimeout(() => {
      const dom = document.getElementById('jsoneditor');
      dom.innnerHTML = ''; // 防止重复触发
      this.editor = new JSONEditor(dom, {
        language: 'zh-CN',
        mode: this.mode,
        modes: ['code', 'tree', 'view', 'text'],
        search: true,
      });

      // set json
      let data = this.jsonData;
      try {
        data = this.jsonData ? JSON.parse(this.jsonData) : {};
      } catch (err) {
        console.log(err);
      }
      this.editor.set(data);
      if (this.mode === 'tree') {
        // 展开所有节点(tree模式)
        this.editor.expandAll();
      }
    });
    window.addEventListener('message', this.handleIframeChange);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeChange);
  },
  methods: {
    handleIframeChange(event) {
      if (event.origin !== window.location.origin) {
        // 确保消息来自可信任的源
        return;
      }
      if (event?.data?.origin !== 'EDITOR') {
        return;
      }
      if (event.data?.type === 'READY') {
        this.$refs.iframe.contentWindow.postMessage(
          {
            value: this.jsonData,
            origin: 'EDITOR',
          },
          '*',
        );
      } else if (event.data?.type === 'CHANGE') {
        this.handleChange(event.data?.value ?? '{}');
      }
    },
    handleChangeSwitch() {
      try {
        const data = this.jsonData ? JSON.parse(this.jsonData) : {};
        if (!this.useMonacoEditor) {
          this.editor.set(data);
        }
      } catch (err) {
        console.error(err);
      }
    },
    handleChange(jsonValue) {
      this.jsonData = jsonValue;
    },
    format(back) {
      try {
        this.jsonData = this.useMonacoEditor ? this.jsonData : JSON.stringify(this.editor.get());
        if ([`""`].includes(this.jsonData)) {
          return this.$message.error('json格式错误');
        }
        back && back();
      } catch (err) {
        console.log(err);
        this.$message.error('json格式错误');
      }
    },
    copyText() {
      copyText(this.editor.getText(), (val) => {
        if (val) {
          this.$message({
            message: '复制成功',
            type: 'success',
          });
          return;
        }
        this.$message.error('复制失败, 请重试');
      });
    },
    submit() {
      this.format(() => {
        try {
          const json = JSON.parse(this.jsonData);
          this.$emit('save', JSON.stringify(json, null, '\t'));
        } catch (e) {
          console.log(e);
        }
      });
    },
    async openDiff() {
      this.format(async () => {
        if (this.oldStr === this.newStr) {
          this.$message('无差异');
          return;
        }
        this.innerDialogVisibility = true;
        if (!this.mergely) {
          await this.initMergely();
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100));
          this.mergely.rhs(this.newStr);
          await new Promise((resolve) => setTimeout(resolve, 100));
          this.mergely.scrollToDiff('prev');
        }
      });
    },
    async initMergely() {
      const that = this;
      const searchFun = function (event) {
        if (event.metaKey && event.key === 'f') {
          event.preventDefault();
          that.showSearchBar = !that.showSearchBar;
        }
      };
      document.addEventListener('keydown', searchFun);
      await setTimeout(() => {}, 10);
      const mergely = new window.Mergely('#mergely', {
        license: 'lgpl',
        wrap_lines: true,
        lhs: this.oldStr,
        rhs: this.newStr,
        lhs_cmsettings: {
          readOnly: true,
          mode: 'text/json',
        },
      });
      console.log('================> mergely inited!');
      this.mergely = mergely;
      mergely.once('updated', () => {
        mergely.scrollToDiff('next');
      });
      mergely.on('changed', () => {
        setTimeout(() => {
          const res = this.mergely.summary();
          this.diffSummary = `统计：新增${res.a}行，修改${res.c}行，删除${res.d}行`;
        }, 100);
      });
    },
    toSearch() {
      this.showSearchBar = !this.showSearchBar;
    },
    searchCall(dir) {
      if (dir === 'lhs') {
        this.mergely.search('lhs', this.lhsSC);
      } else {
        this.mergely.search('rhs', this.rhsSC);
      }
    },
    toNextDiff() {
      this.mergely.scrollToDiff('next');
    },
    toPrevDiff() {
      this.mergely.scrollToDiff('prev');
    },
    toTop() {
      this.mergely.scrollTo('lhs', 1);
      this.mergely.scrollTo('rhs', 1);
    },
    toBottom() {
      this.mergely.scrollTo('lhs', 1000000);
      this.mergely.scrollTo('rhs', 1000000);
    },
    toSave() {
      const newContent = this.mergely.get('rhs');
      try {
        const newJson = JSON.parse(newContent);
        this.editor.set(newJson);
        this.innerDialogVisibility = false;
      } catch (error) {
        this.$message.error('JSON格式错误');
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    // json处理
    onJsonChange(value) {
      // 实时保存
      this.jsonData = value;
    },
  },
};
</script>

<style lang="scss" scoped>
#jsoneditor {
  height: 600px;
}
::v-deep .bin-json-editor {
  height: 550px !important;
}

.compare-container {
  margin-left: 30px;
  width: auto;
  position: relative;
  .top-line {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 20px;
    .btns {
      display: flex;
      column-gap: 20px;
      padding: 0px 0 5px 10px;
    }
  }
  .search-bar {
    display: flex;
    justify-content: space-between;
    padding: 8px 77px 8px 0;
    input {
      width: 15em;
      margin: 0 10px 0 0;
    }
    input::-webkit-input-placeholder {
      opacity: 0.3;
    }
    input::-moz-placeholder {
      opacity: 0.3;
    }
    input:-ms-input-placeholder {
      opacity: 0.3;
    }
    input::-ms-input-placeholder {
      opacity: 0.3;
    }
    input:-moz-placeholder {
      opacity: 0.3;
    }
  }
  #mergely {
    height: 60vh;
    width: 1400px;
  }
}
/* ind is an inline delete */
.mergely-editor {
  ::v-deep .mergely.ch.ind.lhs {
    text-decoration: line-through;
    color: #ff0000;
  }
  ::v-deep .CodeMirror {
    line-height: 18px;
    height: auto;
    width: 100%;
  }

  ::v-deep .mergely-margin canvas {
    width: 8px;
  }

  ::v-deep .mergely-canvas canvas {
    width: 28px;
  }

  ::v-deep .CodeMirror-linenumber {
    cursor: pointer;
  }
  ::v-deep .CodeMirror {
    width: 600px;
  }

  ::v-deep .CodeMirror-selected {
    background: #0f73ff47;
  }

  ::v-deep .merge-button {
    height: 18px;
    cursor: pointer;
  }
  /* common stles */
  ::v-deep .mergely.CodeMirror-linebackground {
    border-style: solid;
    border-width: 0px;
  }
  ::v-deep .mergely.start.CodeMirror-linebackground {
    border-top-width: 1px;
  }
  ::v-deep .mergely.end.CodeMirror-linebackground {
    border-bottom-width: 1px;
  }
  ::v-deep .mergely.no-end.CodeMirror-linebackground {
    border-bottom-width: 0px;
    background-color: initial;
  }
  ::v-deep .CodeMirror-code {
    color: black;
  }
  ::v-deep .CodeMirror {
    border: 1px solid #ccc;
  }
  ::v-deep .mergely.CodeMirror-gutter-background.current {
    background-color: #777;
  }
  ::v-deep .mergely.current .CodeMirror-linenumber {
    color: #f9f9f9;
    font-weight: bold;
  }
  ::v-deep .merge-button {
    color: #ccc;
  }
  ::v-deep .merge-button:hover {
    color: #666;
  }
  ::v-deep .current .merge-button:hover {
    color: #fff;
  }

  /* changed styles */
  ::v-deep .mergely.c.CodeMirror-linebackground {
    border-color: #a3a3a3;
    background-color: #fafafa;
  }

  /* lhs styles (deleted-from) */
  ::v-deep .mergely.rhs.a.no-end.CodeMirror-linebackground,
  ::v-deep .mergely.rhs.a.no-start.CodeMirror-linebackground,
  ::v-deep .mergely.lhs.no-end.CodeMirror-linebackground,
  ::v-deep .mergely.lhs.d.CodeMirror-linebackground {
    border-color: #ff7f7f;
  }
  ::v-deep .mergely.lhs.d.CodeMirror-linebackground {
    background-color: #ffe9e9;
  }
  /* ind is an inline delete */
  ::v-deep .mergely.ch.ind.lhs {
    text-decoration: line-through;
    color: #ff0000;
  }
  ::v-deep .mergely.ch.d.lhs {
    text-decoration: line-through;
    color: #ff0000;
  }

  /* rhs styles (added-to) */
  ::v-deep .mergely.lhs.no-end.CodeMirror-linebackground,
  ::v-deep .mergely.lhs.no-start.CodeMirror-linebackground,
  ::v-deep .mergely.rhs.a.CodeMirror-linebackground {
    border-color: #a3d1ff;
  }
  ::v-deep .mergely.rhs.a.CodeMirror-linebackground {
    background-color: #eff7ff;
  }
  /* ina is an inline add */
  ::v-deep .mergely.ch.ina.rhs {
    color: #0000ff;
  }
  ::v-deep .mergely.ch.a.rhs {
    color: inherit;
  }
  ::v-deep .mergely.current.lhs.CodeMirror-linebackground,
  ::v-deep .mergely.current.rhs.CodeMirror-linebackground {
    border-color: black;
  }

  ::v-deep .mergely.no-start.end.CodeMirror-linebackground,
  ::v-deep .mergely.no-end.start.CodeMirror-linebackground {
    background: none;
  }
  ::v-deep .mergely.ch.d.lhs {
    text-decoration: line-through;
    color: #ff0000;
  }
}

::v-deep .mergely-splash {
  display: none;
}
.switch {
  margin-bottom: 12px;
}
.iframe-container {
  width: 100%;
  height: 800px;
  border: none;
}
</style>
