<template>
  <t-drawer
    :visible="visible"
    :close-btn="true"
    @close="onClose"
    @confirm="onConfirm"
    size="500px"
    :header="title"
  >
    <div class="container">
      <t-form class="form" ref="ruleForm" :data="form" :rules="rules" label-align="top">
        <t-form-item label="名称" name="name">
          <t-input v-model="form.name"></t-input>
        </t-form-item>
        <t-form-item label="Code" name="code">
          <t-input-adornment prepend="PermissionSet-">
            <t-input
              style="width: 325px"
              v-model="form.code"
              :disabled="Boolean(editRow.id) && !isCopy"
            ></t-input>
          </t-input-adornment>
        </t-form-item>
        <t-form-item label="备注描述" name="description">
          <t-textarea placeholder="请输入" v-model="form.description" />
        </t-form-item>
      </t-form>
    </div>
  </t-drawer>
</template>

<script>
import { DialogPlugin } from 'tdesign-vue';
import { getTdesignFormValidateResult } from '@/utils/util';
const initForm = {
  name: '',
  code: '',
  description: '',
};
export default {
  name: 'PermissionSetEdit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
    appid: {
      type: [Number, String],
      default: '',
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: initForm,
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入code',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      if (this.isCopy) {
        return '复制权限包';
      }
      if (!this.editRow.code) {
        return '新建权限包';
      }
      return '编辑权限包';
    },
  },
  watch: {
    editRow(row) {
      this.form = {
        ...row,
      };
    },
  },
  methods: {
    onClose() {
      const confirmDia = DialogPlugin.confirm({
        header: '提示',
        body: '所作更改将不会被保存，确认关闭?',
        confirmBtn: '确定',
        theme: 'warning',
        cancelBtn: '取消',
        onConfirm: () => {
          confirmDia.hide();
          this.$emit('close');
        },
        onClose: () => {
          confirmDia.hide();
        },
      });
    },
    async onConfirm() {
      const valid = getTdesignFormValidateResult(await this.$refs.ruleForm.validate());

      if (!valid) return;
      console.log('meowf', this.form);
      let request;
      if (!this.editRow.id) {
        console.log('创建权限包');
        request = this.$request({
          url: this.$api.createRole,
          data: {
            ...this.form,
            code: `PermissionSet-${this.form.code}`,
            appid: this.appid,
            type: 'perm_set',
          },
        });
      } else if (this.isCopy) {
        console.log('复制权限包');
        request = this.$request({
          url: this.$api.copyRole,
          data: {
            ...this.form,
            code: `PermissionSet-${this.form.code}`,
            type: 'perm_set',
            copy_type: 0,
          },
        });
      } else {
        console.log('修改权限包');
        request = this.$request({
          url: this.$api.updateRole,
          data: {
            ...this.form,
            code: `PermissionSet-${this.form.code}`,
            type: 'perm_set',
          },
        });
      }
      request.then(() => {
        this.$message.success(this.editRow.id ? '编辑成功' : '新增成功');
        if (!this.editRow.id) {
          this.$message.success('新增成功');
        } else if (this.isCopy) {
          this.$message.success('复制成功');
        } else {
          this.$message.success('编辑成功');
        }
        this.$emit('refresh');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/drawer.scss';
@import '@/styles/element/form.scss';

.container {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    padding: 16px;
    .select {
      width: 100%;
    }
  }
  .drawer-footer {
    display: flex;
    border-top: 1px solid #e5eaf2;
    padding: 12px;
    .drawer-footer-btn {
      margin: 0 8px;
    }
  }
}
</style>
