<template>
  <div class="three-menu">
    <!-- 菜单 -->
    <el-container class="app">
      <!-- 为第二层页面时，左侧菜单隐藏 -->
      <el-header class="menu" v-if="$route.name !== 'metadata-details'">
        <el-menu
          :router="true"
          mode="vertical"
          :default-active="$route.name"
          background-color="#fff"
          text-color="#1C2028"
          active-text-color="#0077FF"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="[
              item.code + '',
              { 'step-over': stepOver },
              { 'step-active': nextStep === index },
            ]"
          >
            <el-menu-item
              :route="{ name: item.path, query: { id: object_id, name: object_name } }"
              :key="item.id"
              :index="item.path"
            >
              <router-link :to="{ name: item.path, query: { id: object_id, name: object_name } }">
                <div class="total">
                  <div class="left">
                    <div class="icon">
                      <i v-if="item.icon" :class="'iconfont ' + item.icon"></i>
                    </div>
                    <div class="line"></div>
                  </div>
                  <div class="right">
                    <div class="title">{{ item.name }}</div>
                    <div class="desc">
                      {{ (MENU_DESC[item.path] && MENU_DESC[item.path].desc) || '' }}
                    </div>
                  </div>
                </div>
              </router-link>
            </el-menu-item>
          </div>
        </el-menu>
      </el-header>
      <el-main class="content">
        <router-view @change-menu="changeMenu"></router-view>
      </el-main>
    </el-container>
    <!-- 菜单新手指引 -->
    <metadata-tour
      :list="list"
      @change-step="(i) => (nextStep = i)"
      @tour-switch="(i) => (stepOver = i)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MENU_DESC } from './menu-desc';
import metadataTour from '@/views/metadata/components/tour';
export default {
  name: 'ObjectList',
  components: { metadataTour },
  data() {
    return {
      MENU_DESC,
      list: [],
      object_id: '',
      object_name: '',
      nextStep: -1,
      stepOver: false,
    };
  },
  computed: {
    ...mapGetters('user', {
      menuList: 'getMenuList',
    }),
    routeList() {
      // const list = [];
      const { routes } = this.$router.options;
      const list = this.getRouteList(routes);
      return list;
    },
  },
  mounted() {
    this.changeMenu();
    this.object_id = this.$route.query.id;
    this.object_name = this.$route.query.name;
    sessionStorage.setItem('object_name', this.object_name);
    this.$watch(
      () => this.$route,
      () => {
        // 路由变化时，获取元数据id和name,以及判断是否显示过新手指引
        this.object_id = this.$route.query.id;
        this.object_name = this.$route.query.name;
      },
    );
  },
  methods: {
    getRouteList(routeList) {
      let names = [];
      routeList.forEach((route) => {
        names.push(route.name);
        if (route.children && route.children.length) {
          names = names.concat(this.getRouteList(route.children));
        }
      });
      return names;
    },
    changeMenu(codeList = []) {
      const children =
        this.menuList
          .find((menu) => {
            return menu.code === 'power';
          })
          ?.children?.find((submenu) => {
            return submenu.code === 'old-metadata';
          })?.children || [];
      if (!codeList.length) {
        this.list = children;
      } else {
        this.list = children.filter(({ code }) => codeList.includes(code));
      }
      this.list = this.list.filter((item) => {
        return this.condition(item);
      });
    },
    condition(menu) {
      const isReg = this.routeList.includes(menu.path) || this.routeList.includes(menu.code);
      return isReg;
    },
  },
};
</script>

<style lang="scss" scoped>
.three-menu {
  position: relative;
  .app {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    margin: -20px;
    border-top: 1px solid #e5eaf2;
    box-sizing: border-box;
    .el-header,
    .el-main {
      padding: 0px !important;
    }
    .menu {
      width: 184px;
      .el-menu {
        border: 0px;
        padding: 8px;
        height: calc(100vh - 55px);
        overflow: hidden;
        background-color: #fff;
        .el-menu-item {
          padding: 8px !important;
          height: 78px;
          line-height: 0px !important;
          white-space: normal !important;
          transition: none !important;
          i {
            color: #667082 !important;
          }
        }
        .step-active {
          .el-menu-item {
            background: #e6f1ff !important;
            border-radius: 4px;
            .title {
              color: #0077ff;
            }
            .icon {
              background-color: #0077ff;
              i {
                color: #fff !important;
              }
            }
            .line {
              border-color: #0077ff;
            }
          }
        }
        .step-over {
          .el-menu-item.is-active {
            background: #e6f1ff !important;
            border-radius: 4px;
            .title {
              color: #0077ff;
            }
            .icon {
              background-color: #0077ff;
              i {
                color: #fff !important;
              }
            }
            .line {
              border-color: #0077ff;
            }
          }
        }
        .el-menu-item:hover {
          background-color: #fff !important;
        }
        .el-menu-item.is-active:hover {
          background: #e6f1ff !important;
        }
      }
      .total {
        display: flex;
        flex-direction: row;
        height: 100%;
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 24px;
          margin-right: 8px;
          color: rgba(0, 119, 255, 1);
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: #f2f3f8;
            border-radius: 50%;
            i {
              font-size: 15px;
            }
          }
          .line {
            width: 1px;
            height: 32px;
            margin-top: 8px;
            border: 1px solid #e5eaf2;
          }
        }
        .right {
          flex: 1;
          .title {
            font-weight: 400;
            color: #1c2028;
            font-size: 14px;
            line-height: 22px;
          }
          .desc {
            color: #667082;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
    .content {
      flex: 1;
      margin: 16px 16px 0 16px;
    }
  }
}
</style>
