<template>
  <span>
    <input v-show="false" ref="copyRef" :data-clipboard-text="copytext" />
    <span @click="copy">
      <slot></slot>
    </span>
  </span>
</template>

<script>
import Clipboard from 'clipboard';
export default {
  name: 'ClipboardCom',
  props: {
    copytext: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    beforeCopy: {
      type: Function,
    },
    afterCopy: {
      type: Function,
    },
  },
  data() {
    return {
      clipInstance: null,
    };
  },
  mounted() {
    this.clipInstance = new Clipboard(this.$refs.copyRef);
    this.clipInstance.on('success', () => {
      this.$message({
        message: '复制成功',
        type: 'success',
      });
      this.afterCopy && typeof this.afterCopy === 'function' && this.afterCopy();
    });
    this.clipInstance.on('error', (e) => {
      this.$message.error('复制失败');
      console.log(e);
    });
  },
  destroyed() {
    this.clipInstance = null;
  },
  methods: {
    copy() {
      if (this.beforeCopy && typeof this.beforeCopy === 'function' && !this.beforeCopy()) {
        return console.warn('can not copy');
      }
      // 兼容不同浏览器，nextTick后再执行点击有兼容问题
      setTimeout(() => {
        this.$refs.copyRef.click();
      }, 100);
    },
  },
};
</script>
