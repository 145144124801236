<template>
  <div class="three-menu">
    <!-- 三级目录 -->
    <el-container class="app">
      <div class="content">
        <router-view></router-view>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'ObjectList',
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.mock {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.three-menu {
  position: relative;
  .app {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    margin: -20px;
    border-top: 1px solid #e5eaf2;
    box-sizing: border-box;
    .content {
      flex: 1;
      width: 100%;
      height: 100%;
      padding: 16px 16px 0 16px;
    }
  }
}
</style>
