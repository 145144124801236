<template>
  <div class="expression-wrapper">
    <div class="insert-bar">
      <el-button type="info" class="primary" @click="showMetaDialog" :disabled="!objectId">
        插入对象字段
      </el-button>
      <div class="select-wrapper">
        <el-select
          @change="insertVariable"
          :value="undefined"
          placeholder="插入运算符"
          :style="{ width: selectorWidth }"
        >
          <el-option
            v-for="item in operatorOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          @change="insertVariable"
          :value="undefined"
          placeholder="插入函数"
          :style="{ width: selectorWidth }"
        >
          <el-option-group v-for="group in funcList" :key="group.label" :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-option-group>
        </el-select>
        <el-select
          :value="undefined"
          @change="insertVariable"
          placeholder="插入全局变量"
          :style="{ width: selectorWidth }"
        >
          <el-option
            v-for="item in globalVar"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div>
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请设置条件表达式"
        class="condition_text"
        :value="value"
        @input="inputChange"
      />
    </div>
    <el-button type="text" @click="checkSyntax">检查语法</el-button>
    <span v-if="conditionTips.is_valid === null"></span>
    <span
      v-else-if="conditionTips.is_valid"
      :style="{
        color: '#23B812',
      }"
    >
      语法正确
    </span>
    <span
      v-else
      :style="{
        color: '#e7403a',
      }"
    >
      {{ conditionTips.err_msg }}
    </span>
    <!-- 弹窗 -->
    <select-meta
      v-if="showSelectMeta"
      :show="showSelectMeta"
      :object="objectInfo.name"
      :object-id="objectId"
      @cancel="
        () => {
          showSelectMeta = false;
        }
      "
      @insertion="(str) => insertVariable(str)"
    />
  </div>
</template>
<script>
import selectMeta from '../../../../views/metadata/rule/components/selectMeta.vue';
import { getAstFromStr, transformer } from '@/utils/ast';

export default {
  name: '',
  components: {
    selectMeta,
  },
  props: {
    selectorWidth: {
      type: String,
      default: '110px',
    },
    objectId: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 条件报错提示配置
      conditionTips: {
        is_valid: null,
        err_code: '',
        err_msg: '', // 错误信息
      },
      showSelectMeta: false,
      objectInfo: {},
      operatorOptions: [
        { label: '加+', value: '+' },
        { label: '减-', value: '-' },
        { label: '乘*', value: '*' },
        { label: '除/', value: '/' },
        { label: '括号()', value: '()' },
        { label: '等于', value: '===' },
        { label: '不等于', value: '!==' },
        { label: '大于', value: '>' },
        { label: '大于等于', value: '>=' },
        { label: '小于', value: '<' },
        { label: '小于等于', value: '<=' },
        { label: '与', value: '&&' },
        { label: '或', value: '||' },
      ],
      funcList: [
        {
          label: '操作判断',
          options: [
            { label: '是否新增操作', value: 'IS_INSERT()' },
            { label: '是否编辑操作', value: 'IS_UPDATE()' },
          ],
        },
        {
          label: '字符串',
          options: [
            { label: '非空值判断', value: 'NOT_BLANK()' },
            { label: '正则匹配', value: 'REGEX_MATCH()' },
            { label: '字符串分隔长度 ', value: 'SPLIT_LENGTH()' },
          ],
        },
        {
          label: '聚合函数',
          options: [
            { label: '计数', value: 'COUNT()' },
            { label: '求和', value: 'SUM()' },
          ],
        },
        {
          label: '其他',
          options: [
            { label: '是否不属于', value: 'NOT_IN()' },
            { label: '格式化时间戳', value: 'FORMAT_TIMESTAMP()' },
            { label: '非空判断', value: 'NOT_NULL()' },
            { label: '是否属于', value: 'IN()' },
          ],
        },
      ],
      globalVar: [
        { label: '当前时间戳(单位：秒)', value: 'CURRENT_TIME' },
        { label: '当前日期(0点时间戳)', value: 'CURRENT_DATE' },
        { label: '当前用户uin', value: 'CURRENT_USER_UIN' },
        { label: '当前用户岗位code', value: 'CURRENT_USER_TERRITORY' },
      ],
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.GetObjectDetail();
  },
  methods: {
    inputChange(e) {
      this.$emit('change', e);
    },
    async showMetaDialog() {
      await this.GetObjectDetail();
      this.showSelectMeta = true;
    },
    async GetObjectDetail() {
      try {
        const { object } = await this.$request({
          url: this.$api.metaGetObjectDetail,
          data: {
            id: this.objectId,
          },
        });
        this.objectInfo = object;
      } catch (err) {
        console.log(err);
      }
    },
    insertVariable(e) {
      const selector = '.condition_text .el-textarea__inner';
      const dom = document.querySelector(selector);
      this.$emit(
        'change',
        this.value.substring(0, dom.selectionStart) +
          e +
          this.value.substring(dom.selectionEnd, dom.textLength),
      );
    },
    async checkSyntax() {
      const codeStr = this.value;
      const isValidAst = await this.checkAst(codeStr);
      if (!isValidAst) {
        return;
      }
      const ast = transformer(codeStr, this.objectInfo.name);
      try {
        const res = await this.$request({
          url: this.$api.checkValidateRule,
          data: {
            object_name: this.objectInfo.name,
            rule: JSON.stringify(ast),
          },
        });
        this.conditionTips = res;
        return this.conditionTips.is_valid;
      } catch (err) {
        console.log(err);
        this.conditionTips = {
          is_valid: false,
          err_code: '',
          err_msg: '检查不通过', // 错误信息
        };
      }
    },
    async checkAst(codeStr = '') {
      try {
        const { isValidAst, astErrTip } = await getAstFromStr(codeStr, this.objectInfo.name);
        if (!isValidAst) {
          const errTip = {
            is_valid: false,
            err_code: '',
            err_msg: astErrTip, // 错误信息
          };
          this.conditionTips = errTip;
        }
        return isValidAst;
      } catch (err) {
        const errTip = err.message || '语法检查异常';
        this.conditionTips = errTip;
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';
@import '@/styles/element/input.scss';
@import '@/styles/element/select.scss';
@import '@/styles/element/radio.scss';
.expression-wrapper {
  margin-top: 10px;
  .insert-bar {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
  }
}
</style>
