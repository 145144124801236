<template>
  <div class="advance-config">
    <div class="side-tab">
      <div
        v-for="item in sideTabList"
        class="side-tab-item"
        :class="{ active: sideTab === item.name }"
        :key="item.name"
        @click="sideTab = item.name"
      >
        {{ item.label }}
      </div>
    </div>
    <EndEvent
      v-if="sideTab === 'ended'"
      class="config-container"
      :value="actionList"
      @update="(val) => update('action', val)"
    />
    <AutoRepeat
      v-else-if="sideTab === 'repeat'"
      class="config-container"
      :value="autoRepeat"
      :advance-select-assigners="advanceSelectAssigners"
      :neighbor-set="neighborSet"
      @update="(val) => update('repeat', val)"
      @updateAssigners="(val) => update('check', val)"
      @neighborSetting="(val) => update('neighbor_set', val)"
    />
  </div>
</template>

<script>
import EndEvent from './endEvent';
import AutoRepeat from './autoRepeat';

export default {
  name: 'WorkflowAdvanceConfig',
  components: { EndEvent, AutoRepeat },
  props: {
    actionList: {
      type: Array,
      default: () => [],
    },
    autoRepeat: {
      type: Number,
      default: 1,
    },
    advanceSelectAssigners: {
      type: Array,
      default: () => [],
    },
    neighborSet: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 侧边tab列表与当前值
      sideTabList: [
        {
          name: 'ended',
          label: '审批流结束事件',
        },
        {
          name: 'repeat',
          label: '审批设置',
        },
      ],
      sideTab: 'ended',
    };
  },
  methods: {
    update(type, value) {
      this.$emit('update', {
        type,
        value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-config {
  width: 100%;
  display: flex;
  align-items: flex-start;
  .side-tab {
    width: 240px;
    flex-shrink: 0;
    height: 100%;
    background: #fff;
    border-top: 1px solid #e5eaf2;
    .side-tab-item {
      cursor: pointer;
      margin: 8px;
      padding: 8px 36px;
      &.active {
        background: #0077ff1a;
        color: #0069fa;
      }
    }
  }
  .config-container {
    flex: 1;
    margin: 16px;
    background: #fff;
  }
}
</style>
