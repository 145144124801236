<template>
  <div class="code-input-group">
    <el-input
      v-for="(item, index) in code"
      :key="index"
      v-model="code[index]"
      :ref="`input${index}`"
      class="code-input"
      maxlength="1"
      @focus="controlFocus"
      @input="input"
      @keydown.delete.native.prevent="deleteFocus"
    />
  </div>
</template>
<script>
export default {
  name: 'AccountCode',
  props: {
    length: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      // 验证码
      code: [],
    };
  },
  created() {
    for (let i = 0; i < Number(this.length); i += 1) {
      this.code.push('');
    }
  },
  methods: {
    // focus事件和input事件都自动更新当前选中的格子
    controlFocus() {
      let aim = 'input';
      // 自动搜索第一个未填满的空格并focus
      for (let i = 0; i < this.code.length; i += 1) {
        if (this.code[i] === '') {
          aim += i.toString();
          break;
        }
      }

      // 所有空格都填满了 指向最后一个
      if (aim === 'input') {
        aim = `input${this.code.length - 1}`;
      }
      this.$refs[aim][0].focus();
    },
    input() {
      this.$emit('change', this.code.join(''));
      this.controlFocus();
    },
    deleteFocus() {
      // 已经全部填满 删除最后一个
      if (this.code[this.code.length - 1] !== '') {
        this.code[this.code.length - 1] = '';
        this.$forceUpdate();
        this.input();
      } else {
        // 寻找最后一个被填满的空格清空它
        for (let i = 0; i < this.code.length; i += 1) {
          if (this.code[i] === '') {
            if (i !== 0) {
              this.code[i - 1] = '';
              this.$forceUpdate();
              this.input();
            }
            break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.code-input-group {
  display: flex;
  .code-input {
    margin-right: 8px;
    width: 40px;
    &:last-child {
      margin-right: 0px;
    }
    ::v-deep.el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #1a2734;
    }
  }
}
</style>
