import { defaultUserNodeFormValue } from '@/components/antv/config.js';

export const businessModuleTypes = [
  {
    label: '会议',
    value: '会议',
  },
  {
    label: 'DCR',
    value: 'DCR',
  },
];
export const candidateUserAtributes = [
  {
    label: '当前审批人',
    value: 'current_assignee',
  },
  {
    label: '下一节点审批人',
    value: 'last_assignee',
  },
  {
    label: '审批发起人',
    value: 'starter',
  },
  {
    label: '由调用方指定',
    value: 'specify_variable',
  },
];
export const noticeTemplateTypes = [
  {
    label: '邮件通知',
    value: 'email',
  },
  {
    label: '企微通知',
    value: 'wxmsg',
  },
  {
    label: '站内信',
    value: 'lclmsg',
  },
];
export const processRunStatus = [
  {
    label: '失败',
    value: '4',
  },
  {
    label: '完成',
    value: '2',
  },
  // {
  //   label: '终止',
  //   value: '3',
  // },
  {
    label: '进行中',
    value: '1',
  },
];
export const createModelInitJson = {
  nodes: [
    {
      id: 'start_node',
      name: '开始',
      type: 'start_node',
      node_type: 1,
      label: '开始节点',
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
      point: {
        x: 599,
        y: 132,
      },
      size: [80, 80],
      business_config: {
        extra_info: {},
      },
      metadata: {
        trigger: {
          resource: '',
          event_type: '',
          constraints_enable: 2,
          constraints: '',
        },
      },
      inputs: [],
      events2: [],
    },
    {
      id: 'user_node_1',
      name: '用户节点',
      type: 'user',
      node_type: 5,
      label: '用户节点',
      labelCfg: {
        style: {
          fill: '#fff',
          fontWeight: '700',
        },
      },
      point: {
        x: 599,
        y: 276,
      },
      size: [160, 30],
      business_config: {
        extra_info: {},
      },
      metadata: {
        operator_type: 'leader',
        operator_value: {
          ...defaultUserNodeFormValue,
        },
      },
      subref: 'approval',
      timed_config: {
        enabled: false,
        duration: 1,
        handle: 1,
      },
      events: [],
      field_permission: {},
      inputs: [],
      outputs: [],
      events2: [],
    },
    {
      id: 'end_node_node_2',
      name: '结束节点',
      type: 'end_node',
      node_type: 2,
      label: '结束节点',
      labelCfg: {
        style: {
          fill: '#384050',
        },
      },
      point: {
        x: 599,
        y: 441,
      },
      size: [80, 80],
      business_config: {
        extra_info: {},
      },
      outputs: [],
    },
  ],
  edges: [
    {
      id: 'start_node_to_user_node_1',
      name: '',
      source: 'start_node',
      target: 'user_node_1',
      line_points: [
        {
          x: 599,
          y: 172.5,
        },
        {
          x: 599,
          y: 260.5,
        },
      ],
      sourceAnchor: 2,
      targetAnchor: 0,
    },
    {
      id: 'user_node_1_to_end_node_node_2',
      name: '',
      source: 'user_node_1',
      target: 'end_node_node_2',
      line_points: [
        {
          x: 599,
          y: 291.5,
        },
        {
          x: 599,
          y: 400.5,
        },
      ],
      sourceAnchor: 2,
      targetAnchor: 0,
    },
  ],
};
