export const getValue = (val) => {
  if (val === null) {
    return 'null';
  }
  switch (typeof val) {
    case 'string':
      return `'${val}'`;
    case 'boolean':
      return val ? 'true' : 'false';
    case 'number':
      return `${val}`;
    case 'undefined':
      return '';
    default:
      return JSON.stringify(val);
  }
};
