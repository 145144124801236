<template>
  <el-dialog title="选择事件" :visible="visible" width="600px" @close="close">
    <EventTip />
    <EventTransfer v-if="visible" class="eventSelect" v-model="actionList" />
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="close">取 消</el-button>
      <el-button type="primary" @click="success">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import EventTransfer from '@/components/eventTransfer';
import EventTip from '@/components/eventTip';

export default {
  name: 'WorkflowDetailAddEvent',
  components: { EventTransfer, EventTip },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      actionList: [],
    };
  },
  watch: {
    // 已经选择的当前数据要展示在弹框内
    visible(val) {
      if (val) {
        this.actionList = this.selected.map((i) => i.id);
      }
    },
  },
  methods: {
    success() {
      this.$emit('success', this.actionList);
    },
    close() {
      this.actionList = [];
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/element/button.scss';
@import '@/styles/element/dialog.scss';

.eventSelect {
  padding-right: 20px;
}
</style>
