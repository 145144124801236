<template>
  <!-- 编辑器 -->
  <div>
    <VueQuillEditor
      ref="text"
      v-model="editContent"
      :content="content"
      :options="editorOption"
      :template-str="templateStr"
    />
  </div>
</template>

<script>
import VueQuillEditor from './editor.vue';

export default {
  components: {
    VueQuillEditor,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    templateStr: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // content: '<h2>这是内容</h2>',
      editContent: '',
      editorOption: {},
    };
  },
  watch: {
    editContent(newval) {
      this.$emit('getContent', newval);
    },
    content: {
      handler(newval) {
        this.editContent = newval;
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
@import './index.scss';
</style>
