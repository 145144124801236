<template>
  <div class="box" @dblclick="consoleData">
    <!-- 表格格式 -->
    <template v-if="type === 'table'">
      <diffTable
        v-if="scene === 'diff'"
        :list="diffData.list"
        :keys="diffData.keys"
        :action="`${data.action}`"
        v-on="$listeners"
      />
      <el-table v-else :data="[data]" style="width: 100%">
        <el-table-column prop="date" label="key:" width="60">
          <template>value:</template>
        </el-table-column>
        <el-table-column
          :prop="key"
          :label="key"
          v-for="key in tableKeys(data)"
          :key="key"
          min-width="200"
        >
          <template slot-scope="scope">
            <span v-if="getValue(scope.row[key]).length < 31">
              {{ getValue(scope.row[key]) }}
            </span>
            <el-tooltip
              v-else
              effect="dark"
              :content="getValue(scope.row[key])"
              placement="top-end"
            >
              <span>{{ getValue(scope.row[key]).slice(0, 30) }}...</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="item from" v-for="(item, index) in diffData" :key="index">
        <div class="title" v-if="scene === 'diff'">
          {{ index === 0 ? '同步前' : '同步后' }}
        </div>
        <div class="content" :style="{ backgroundColor: bgcColor }">
          <!-- json格式 -->
          <template v-if="type === 'json'">
            <span v-if="!item || JSON.stringify(item).length < 71">
              {{ JSON.stringify(item) }}
            </span>
            <el-tooltip v-else effect="dark" :content="JSON.stringify(item)" placement="top-end">
              <span>{{ JSON.stringify(item).slice(0, 70) }}...</span>
            </el-tooltip>
          </template>
          <!-- api格式 -->
          <template v-if="type === 'api'">
            <el-checkbox-group :value="item" :disabled="disabled">
              <el-checkbox
                v-for="option in apiActionOptions"
                :key="option.value"
                :label="option.value"
              >
                {{ option.label }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <!-- 菜单/元素格式 -->
          <template v-if="['element', 'menu'].includes(type)">
            <el-checkbox :value="item" :disabled="disabled">可见</el-checkbox>
          </template>
          <!-- 对象 -->
          <template v-if="['object'].includes(type)">
            <div class="privilege">
              <span>数据可见范围: {{ item.visible }}</span>
            </div>
            <div class="privilege">
              <span>
                数据过滤:
                <span v-if="item.filter.length < 31">'{{ item.filter }}'</span>
                <el-tooltip v-else effect="dark" :content="`'${item.filter}'`" placement="top-end">
                  <span>'{{ item.filter.slice(0, 30) }}'...</span>
                </el-tooltip>
              </span>
            </div>
            <div>{{ item.value }}</div>
            <!-- <el-checkbox-group :value="item.value" :disabled="disabled">
              <el-checkbox
                v-for="option in actionOptions"
                :key="option.value"
                :label="option.value"
              >
                {{ option.label }}
              </el-checkbox>
            </el-checkbox-group> -->
          </template>
          <!-- 字段 -->
          <template v-if="['object_field'].includes(type)">
            <div>{{ item }}</div>
            <!-- <el-checkbox-group :value="item" :disabled="disabled">
              <el-checkbox
                v-for="option in actionOptions"
                :key="option.value"
                :label="option.value"
              >
                {{ option.label }}
              </el-checkbox>
            </el-checkbox-group> -->
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import diffTable from './diffTable.vue';
import { getValue } from './diff';
const keys = ({ from, to }) => {
  const newVal = from || {};
  const oldVal = to || {};
  const keys = [...new Set([...Object.keys(oldVal || {}), ...Object.keys(newVal || {})])];
  const arr = keys.sort(function (s, t) {
    const a = s.toLowerCase();
    const b = t.toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  return { keys: arr, list: [oldVal, newVal] };
};
const visibleScope = ['个人', '本岗', '本岗及下属', '全部'];
const getAction = (action) => {
  return action
    .split(',')
    .reduce((pre, cur) => {
      if (isNaN(Number(cur))) {
        return [...pre, cur];
      }
      return pre;
    }, [])
    .join(',');
};
export default {
  components: {
    diffTable,
  },
  props: {
    scene: {
      type: String,
      default: 'diff', // diff: 差异展示 detail: 详情
    },
    type: {
      type: String,
      default: 'json',
    },
    data: {
      type: Object,
      default: () => {
        return {
          from: {},
          to: {},
          action: 3,
        };
      },
    },
  },
  data() {
    return {
      sync_id: '',
      disabled: true,
      apiActionOptions: [
        { label: 'RPC', value: 'RPC' },
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
    };
  },
  computed: {
    bgcColor() {
      switch (`${this.data.action}`) {
        case '0': // 新增
        case 'CREATE': // 新增
          return '#7FF20B';
        case 'MODIFY': // 修改diff
        case '1': // 修改
          return '#f8c01f';
        case 'DELETE': // 删除
        case '2': // 删除
          return '#e53e31';
      }
      return 'none';
    },
    diffData() {
      // 详情
      if (this.scene === 'detail') {
        let visible = '';
        switch (this.type) {
          case 'json':
            return [this.data];
          case 'table':
            return this.data;
          case 'object':
            this.data.action.split(',').forEach((item) => {
              if (!isNaN(Number(item))) {
                visible = visibleScope[item - 1] || '';
              }
            });
            return [
              {
                value: getAction(this.data.action),
                visible,
                filter: this.data.data_filter || '',
              },
            ];
          case 'api':
            return [this.data.action.split(',')];
          case 'object_field':
            return [getAction(this.data.action)];
          case 'element':
          case 'menu':
            return [this.data.action === 'view'];
          default:
            return [this.data];
        }
      }
      let fromVisible = '';
      let toVisible = '';
      // 差异展示
      switch (this.type) {
        case 'json':
          return [this.data.to, this.data.from];
        case 'table':
          return keys(this.data);
        case 'object':
          this.data.from.split(',').forEach((item) => {
            if (!isNaN(Number(item))) {
              fromVisible = visibleScope[item - 1] || '';
            }
          });
          this.data.to.split(',').forEach((item) => {
            if (!isNaN(Number(item))) {
              toVisible = visibleScope[item - 1] || '';
            }
          });
          return [
            {
              value: getAction(this.data.to),
              visible: toVisible,
              filter: this.data.to_filter || '',
            },
            {
              value: getAction(this.data.from),
              visible: fromVisible,
              filter: this.data.from_filter || '',
            },
          ];
        case 'api':
          return [this.data.to.split(','), this.data.from.split(',')];
        case 'object_field':
          return [getAction(this.data.to), getAction(this.data.from)];
        case 'element':
        case 'menu':
          return [this.data.to === 'view', this.data.from === 'view'];
        default:
          return [this.data.to, this.data.from];
      }
    },
    actionOptions() {
      switch (this.type) {
        case 'object_field':
          return [
            { label: '查询', value: 'select' },
            { label: '新增', value: 'insert' },
            { label: '更新', value: 'update' },
          ];
      }
      return [
        { label: '查询', value: 'select' },
        { label: '新增', value: 'insert' },
        { label: '更新', value: 'update' },
        { label: '删除', value: 'delete' },
      ];
    },
  },
  methods: {
    consoleData() {
      console.log(this);
    },
    tableKeys(obj) {
      return Object.keys(obj).filter((key) => {
        return !['tier'].includes(key);
      });
    },
    getValue(val) {
      return getValue(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin: 3px 0;
  display: flex;
  width: 100%;
  transform: translateX(-40px);
  z-index: 3;
  .item {
    flex: 1;
    text-align: center;
    background-color: #f2f6fc;
    margin: 0px 3px;
    // max-width: 40vw;
    .content {
      height: calc(100% - 19px);
      .privilege {
        display: flex;
        span {
          flex: 1;
        }
      }
    }
  }
  ::v-deep .el-checkbox {
    .el-checkbox__label {
      color: black;
    }
    &.is-disabled.is-checked {
      .el-checkbox__label {
        color: #409eff;
      }
    }
    .el-checkbox__input.is-disabled.is-checked {
      .el-checkbox__inner {
        background-color: #409eff;
        border-color: #409eff;
      }
      .el-checkbox__inner::after {
        border-color: #fff;
      }
    }
  }

  ::v-deep .el-tree-node {
    // .el-checkbox {
    //   display: none;
    // }
    .el-tree-node__content {
      height: auto !important;
    }
    .custom-tree-node {
      max-width: 97%;
    }
  }
}
</style>
