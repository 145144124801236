<template>
  <div class="event-box">
    <div class="event-item">
      <div style="margin-right: 16px" v-if="!hideLabel">选择事件</div>
      <el-cascader
        :ref="refName"
        :value="value"
        :options="eventOptions"
        @change="handleChange"
        :show-all-levels="false"
        :props="props"
        class="cascader-box"
        popper-class="event-transfer-pop-box"
        @expand-change="parentNodeChange"
        @visible-change="(v) => visableChange(v, refName)"
        clearable
      >
        <template slot-scope="{ data }">
          <div>
            <span>{{ data.label.replace(data.prefix, '') }}</span>
          </div>
        </template>
      </el-cascader>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  model: {
    props: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    // 为页面文字标识（如在同一页面中调用多次组件，此参数不可重复）
    refName: {
      type: String,
      default: () => {
        return '';
      },
    },
    eventOptions: {
      type: Array,
      default: () => [],
    },
    nodeType: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      props: { multiple: true },
      // 是否完成初始化
      init: false,
      // 父节点事件类型
      eventType: '',
      createNotifyBtn: '',
      createCloudBtn: '',
    };
  },
  computed: {},
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    visableChange(visable, ref) {
      if (visable) {
        this.$nextTick(() => {
          let { popper } = this.$refs[ref].$refs;
          if (popper.$el) popper = popper.$el;
          const box = Array.from(popper.children).find((v) => v.className === 'el-cascader-panel');
          const ul = Array.from(box.children[0].children[0].children[0].children);
          const expendLi = ul.find((li) => li.ariaExpanded);

          const notfiyCreateBtn = Array.from(box.children).find(
            (v) => v.className === 'notfiy-create-btn',
          );
          if (notfiyCreateBtn) {
            notfiyCreateBtn.remove();
          }
          const cloudCreateBtn = Array.from(box.children).find(
            (v) => v.className === 'cloud-create-btn',
          );
          if (cloudCreateBtn) {
            cloudCreateBtn.remove();
          }

          const el = document.createElement('div');
          if (expendLi && expendLi.innerText === '通知消息') {
            el.innerHTML = '+新建通知消息';
            el.className = 'notfiy-create-btn';
            el.style = `border-top: solid 1px #E4E7ED; display: flex; align-items: center; height: 34px; width: ${
              Array.from(box.children)[1].clientWidth
            }px; padding-left: 10px; position: absolute; right: 0; bottom: 0; background: #FFF; cursor: pointer; z-index: 2031`;
            el.onclick = () => {
              this.openCreateNotify();
            };
            box.appendChild(el);
          } else if (expendLi && expendLi.innerText === '云函数自定义事件') {
            el.innerHTML = '+新建云函数自定义事件';
            el.className = 'cloud-create-btn';
            el.style = `border-top: solid 1px #E4E7ED; display: flex; align-items: center; height: 34px; width: ${
              Array.from(box.children)[1].clientWidth
            }px; padding-left: 10px; position: absolute; right: 0; bottom: 0; background: #FFF; cursor: pointer; z-index: 2031`;
            el.onclick = () => {
              this.openCreateCloud();
            };
            box.appendChild(el);
          }
        });
      }
    },
    handleChange(e) {
      this.$nextTick(() => {
        this.visableChange(true, this.refName);
        this.$emit('update', e);
      });
    },
    parentNodeChange() {
      this.visableChange(true, this.refName);
    },
    openCreateCloud() {
      // 不能用$emit 在onclick 中没有$emit属性
      this.$parent.openCreateCloudEventDialog(this.nodeType);
    },
    openCreateNotify() {
      // 不能用$emit 在onclick 中没有$emit属性
      this.$parent.openCreateNotifyEventDialog(this.nodeType);
    },
  },
};
</script>
<style lang="scss">
.event-transfer-pop-box {
  .el-cascader-menu__wrap {
    min-height: 102px;
    max-height: 204px;
    height: auto;
  }
  .el-cascader-panel {
    div:nth-child(2) {
      .el-cascader-menu__wrap {
        min-width: 220px;
        max-width: 320px;
      }
    }
  }
  .el-cascader-menu__empty-text {
    display: none;
  }
  .el-cascader-menu__list {
    padding-bottom: 34px;
    max-height: 170px;
    overflow-y: auto;
    min-height: 0px;
  }
}
</style>
<style lang="scss" scoped>
.event-box {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .event-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .cascader-box {
      flex: 1;
    }
  }
  ::v-deep.el-input__inner {
    height: 32px;
  }
  ::v-deep.el-input__icon {
    line-height: 32px;
  }
  ::v-deep .el-cascader {
    line-height: 32px;
  }
}
</style>
