<template>
  <div class="table-container">
    <div class="top">
      <el-input clearable placeholder="请输入关键词" v-model="searchObj.keyword" class="keyword">
        <el-button slot="append" @click="loadListData" icon="el-icon-search">搜索</el-button>
      </el-input>
      <el-button class="add-button" type="primary" icon="el-icon-plus" @click="handleAdd()">
        添加
      </el-button>
    </div>

    <el-table :data="roleSets" stripe fit style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="140"></el-table-column>
      <el-table-column prop="roleSetName" label="集合名"></el-table-column>
      <el-table-column prop="desc" label="描述">
        <template slot-scope="scope">
          <span>{{ scope.row.desc | nullString }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_by" label="创建人" width="120" />
      <el-table-column prop="update_by" label="最后修改人" width="120" />
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="searchObj.page"
      :page-size="searchObj.page_size"
      :total="count"
      hide-on-single-page
      background
      layout="total,sizes, prev, pager, next, jumper"
      class="table-pagination"
      @current-change="(val) => (searchObj.page = val)"
      @size-change="(val) => (searchObj.page_size = val)"
    />

    <el-dialog
      :title="operation | operationName"
      :visible.sync="opDialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        @keyup.enter.native="submitForm"
        ref="form"
        :model="formObj"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ID" prop="id" v-if="operation == 'update'">
          <el-input v-model="formObj.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="集合名" prop="roleSetName">
          <el-input v-model="formObj.roleSetName"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="formObj.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="opDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="submitDisable" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operation: 'create',
      roleSets: [],
      count: 0,
      formObj: {},
      oldSearchObj: {},
      searchObj: {
        keyword: this.$route.query.keyword,
        page: Number(this.$route.query.page) || 1,
        page_size: Number(this.$route.query.page_size) || 10,
      },
      rules: {
        roleSetName: [
          {
            required: true,
            message: '请输入集合名称',
            trigger: 'blur',
          },
        ],
      },
      opDialogVisible: false,
      submitDisable: false,
    };
  },
  watch: {
    searchObj: {
      handler() {
        if (this.timer) {
          this.$router.push({ query: this.searchObj });
          sessionStorage.setItem('app_id', this.searchObj.appid);
          clearTimeout(this.timer);
        }
        if (this.oldSearchObj.page && this.oldSearchObj.page === this.searchObj.page) {
          this.searchObj.page = 1;
        }
        this.oldSearchObj = Object.assign({}, this.searchObj);
        this.timer = setTimeout(() => {
          this.loadListData();
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    loadListData() {
      const url = this.$api.getRoleSetList;
      const data = this.searchObj;
      const cb = (data) => {
        this.count = data.total;
        this.roleSets = data.data;
      };
      this.$request({ url, data, cb });
    },
    submitForm() {
      if (this.submitDisable) {
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.opDialogVisible = false;
        this.submitDisable = true;
        const { id } = this.formObj;
        const url = id ? this.$api.updateRoleSet : this.$api.createRoleSet;
        const msg = id ? '修改成功' : '创建成功';
        const data = {
          roleSetName: this.formObj.roleSetName,
          desc: this.formObj.desc,
        };
        const cb = () => {
          this.submitDisable = false;
          this.$message({
            message: msg,
            type: 'success',
          });
          this.loadListData();
        };
        const type = 'post';
        this.$request({ url, data, cb, type });
      });
    },
    deleteOperation(id) {
      const url = this.$api.deleteRoleSet;
      const type = 'post';
      const cb = () => {
        this.$message({
          message: '删除成功',
          type: 'success',
        });
        this.loadListData();
      };
      this.$request({ url, cb, type, data: { id } });
    },
    handleAdd() {
      this.operation = 'create';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = {};
      this.opDialogVisible = true;
    },
    handleEdit(index, data) {
      this.operation = 'update';
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.formObj = Object.assign({}, data);
      this.opDialogVisible = true;
    },
    handleDelete(index, data) {
      this.$confirm('是否删除该集合?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteOperation(data.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
