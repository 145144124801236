import { getTree, getTreeNode, merge } from '../common';

const getDiff = ({ diff, tier, getkeys, getItem }) => {
  // key
  let keys = (item) => {
    return [item.diff_code.split('##')[1]];
  };
  if (typeof getkeys === 'function') {
    keys = getkeys;
  }
  // item
  let defaultItem = ({ item, tier }) => {
    const changeTo = {};
    const changeFrom = {};
    item.changes.forEach((changeItem) => {
      const { attribute, from, to } = changeItem;
      changeFrom[attribute] = from;
      changeTo[attribute] = to;
    });
    return [
      {
        ...item,
        tier,
        from: changeFrom,
        to: changeTo,
      },
    ];
  };
  if (typeof getItem === 'function') {
    defaultItem = getItem;
  }
  return getTree({
    data: diff,
    tier,
    getkeys: keys,
    getItem: defaultItem,
  });
};

// 对象数据处理
/*
  diff_role : 角色
  diff_menu : 菜单
  diff_element : 元素
  diff_api : 接口
  diff_object : 对象
  diff_object_field : 对象字段
*/
export const getData = ({
  diff_role,
  diff_menu,
  diff_element,
  diff_api,
  diff_object,
  diff_object_field,
}) => {
  // 1. 配置差异
  // 菜单
  const menusChildren = getTreeNode(
    getDiff({
      diff: diff_menu.diff_item,
      tier: 'diff_menu',
      getkeys: (item) => {
        return [`${item.diff_code.split('##').pop()}`];
      },
    }),
  );
  // 接口
  const apiChildren = getTreeNode(
    getDiff({
      diff: diff_api.diff_item,
      tier: 'diff_api',
    }),
  );
  // 元素
  const elementChildren = getTreeNode(
    getDiff({
      diff: diff_element.diff_item,
      tier: 'diff_element',
    }),
  );
  // 角色
  const roleChildren = getTreeNode(
    getDiff({
      diff: diff_role.diff_item,
      tier: 'diff_role',
    }),
  );
  // 对象和字段
  const objectTree = getDiff({
    diff: diff_object.diff_item,
    tier: 'diff_object',
    getkeys: (item) => {
      return [item.diff_code.split('##')[1], 'object'];
    },
  });
  const fieldTree = getDiff({
    diff: diff_object_field.diff_item,
    tier: 'diff_object_field',
    getkeys: (item) => {
      return [item.diff_code.split('##')[1], 'field', [item.diff_code.split('##')[2]]];
    },
  });
  const objectChildren = getTreeNode(
    merge({
      target: objectTree,
      sources: fieldTree,
    }),
  );

  const config = [
    { label: '菜单', children: [...menusChildren] },
    { label: '接口', children: [...apiChildren] },
    { label: '元素', children: [...elementChildren] },
    { label: '对象', children: [...objectChildren] },
    { label: '角色', children: [...roleChildren] },
  ];
  // 2. 权限差异
  const permission = getPermission({
    diff_menu,
    diff_element,
    diff_api,
    diff_object,
    diff_object_field,
  });
  return {
    config: config.filter((item) => {
      return item.children.length > 0;
    }),
    permission,
  };
};

export const getPermDiff = ({ diff_perm, tier }) => {
  return getDiff({
    diff: diff_perm,
    tier,
    getkeys: (item) => {
      const keys = [item.diff_code.split('##')[2], '', item.diff_code.split('##')[1]];
      switch (item.type) {
        case 'MENU':
          keys[1] = '菜单';
          break;
        case 'API':
          keys[1] = '接口';
          break;
        case 'ELEMENT':
          keys[1] = '元素';
          break;
        case 'OBJECT':
          keys[1] = '对象';
          break;
        case 'FIELD':
          keys[1] = '对象';
      }

      switch (item.type) {
        case 'FIELD':
          return [
            item.diff_code.split('##')[3],
            '对象',
            item.diff_code.split('##')[1],
            'feild',
            item.diff_code.split('##')[2],
          ];
        case 'OBJECT':
          return [...keys, 'object'];
        default:
          return keys;
      }
    },
    getItem: ({ item, tier }) => {
      return [
        {
          ...item,
          tier,
        },
      ];
    },
  });
};
const getPermission = ({ diff_menu, diff_element, diff_api, diff_object, diff_object_field }) => {
  const roleMenu = getPermDiff({
    diff_perm: diff_menu.diff_perm,
    tier: 'diff_menu',
    label: '菜单',
  });
  const roleElement = getPermDiff({
    diff_perm: diff_element.diff_perm,
    tier: 'diff_element',
    label: '元素',
  });
  const roleApi = getPermDiff({
    diff_perm: diff_api.diff_perm,
    tier: 'diff_api',
    label: '接口',
  });
  const roleObject = getPermDiff({
    diff_perm: diff_object.diff_perm,
    tier: 'diff_object',
    label: '对象',
    type: 'object',
  });
  const roleField = getPermDiff({
    diff_perm: diff_object_field.diff_perm,
    tier: 'diff_object_field',
    label: '字段',
    type: 'field',
  });
  // 合并
  let tree = {};
  [roleMenu, roleElement, roleApi, roleObject, roleField].forEach((item) => {
    tree = merge({ target: tree, sources: item });
  });
  return getTreeNode(tree);
};

// 获取全部的keys
const arrGetKeys = (arr, type = 'key') => {
  let keys = [];
  arr.forEach((item) => {
    if (item.tier) {
      // 不是undefined层级
      if (type === 'key') {
        //   KEY
        keys.push(item.diff_code);
        return;
      }
      //   NODE
      keys.push(item);
      return;
    }
    if (Array.isArray(item)) {
      keys = [...keys, ...arrGetKeys(item, type)];
      return;
    }
    if (item.children) {
      keys = [...keys, ...arrGetKeys(item.children, type)];
      return;
    }
  });
  return keys;
};
// 获取全部key
export const getAllKeys = (data = []) => {
  if (data.length === 0) {
    return [];
  }
  return arrGetKeys(data, 'key');
};

// 获取全部节点
export const getAllNodes = (data = []) => {
  if (data.length === 0) {
    return [];
  }
  return arrGetKeys(data, 'node');
};

// 过滤节点
export const filterTree = (allData, actions = [], tiers = []) => {
  const newArr = allData.map((item) => {
    if (item.tier) {
      // 节点级
      const { action, tier } = item;
      if (actions.includes(action) && tiers.includes(tier)) {
        return item;
      }
      return undefined;
    }
    if (Array.isArray(item)) {
      return filterTree(item, actions, tiers);
    }
    if (item.children) {
      const newChildren = filterTree(item.children, actions, tiers);
      if (newChildren.length) {
        return {
          ...item,
          children: newChildren,
        };
      }
      return undefined;
    }
    return undefined;
  });
  return newArr.filter((item) => {
    return item;
  });
};

export const insertFeild = (data) => {
  const info = JSON.parse(JSON.stringify(data));
  if (info.diff_object_field && info.diff_object) {
    info.diff_object.field = info.diff_object_field;
    delete info.diff_object_field;
  } else if (info.diff_object_field && !info.diff_object) {
    info.diff_object = {};
    info.diff_object.field = info.diff_object_field;
  }
  delete info.diff_object_field;
  return info;
};
// 统计
export const statisticsAsync = (checkConfig, checkPerm) => {
  // 同步统计
  let config = {};
  let perm = {};
  // 获取总数据
  checkConfig.forEach((node) => {
    const { tier, action } = node;
    if (action === 'DELETE') {
      return;
    }
    if (!config[tier]) {
      config[tier] = {};
    }
    if (!config[tier][action]) {
      config[tier][action] = 1;
    } else {
      config[tier][action]++;
    }
  });
  checkPerm.forEach((node) => {
    const { tier, action } = node;
    if (action === 'DELETE') {
      return;
    }
    if (!perm[tier]) {
      perm[tier] = {};
    }
    if (!perm[tier][action]) {
      perm[tier][action] = 1;
    } else {
      perm[tier][action]++;
    }
  });
  // 嵌套字段到对象
  config = insertFeild(config);
  perm = insertFeild(perm);
  return {
    config,
    perm,
  };
};
