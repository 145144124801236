import { getTreeNode, merge } from '../common';
const getConfigChildren = (data = [], lableKey) => {
  return data.map((item) => {
    const label = item[lableKey];
    return {
      label,
      children: [
        {
          ...item,
          tier: 'config',
        },
      ],
    };
  });
};

const getPermChildren = (data, label, tier) => {
  const obj = {};
  data.forEach((item) => {
    const { perm_info: permInfo, role_code: roleCode } = item;
    obj[roleCode] = {};
    obj[roleCode][label] = {};
    // 对象
    if (tier === 'object') {
      permInfo.forEach((info) => {
        const { power_code: powerCode } = info;
        obj[roleCode][label][powerCode] = {
          object: [
            {
              ...info,
              tier,
            },
          ],
        };
      });
      return;
    }
    obj[roleCode][label] = permInfo.map((info) => {
      const { power_code: powerCode } = info;
      const label = powerCode;
      // 普通
      return {
        label,
        children: [
          {
            ...info,
            tier,
          },
        ],
      };
    });
  });
  return obj;
};

const getFieldTree = (data) => {
  const obj = {};
  data.forEach((item) => {
    const { perm_info: permInfo, role_code: roleCode } = item;
    obj[roleCode] = {
      对象: {},
    };
    permInfo.forEach((info) => {
      const { power_code, parent_power_code: parentCode } = info;
      if (!obj[roleCode]['对象'][parentCode]) {
        obj[roleCode]['对象'][parentCode] = {
          field: [],
        };
      }
      // 对象
      obj[roleCode]['对象'][parentCode].field.push({
        label: power_code,
        children: [
          {
            ...info,
            tier: 'object_field',
          },
        ],
      });
    });
  });
  return obj;
};

const getPerm = ({ menu_perm, api_perm, element_perm, object_perm, field_perm }) => {
  // 菜单
  const menuTree = getPermChildren(menu_perm, '菜单', 'menu');
  // 接口
  const apiTree = getPermChildren(api_perm, '接口', 'api');
  // 元素
  const elementTree = getPermChildren(element_perm, '元素', 'element');
  // 对象
  const objectTree = merge({
    target: getPermChildren(object_perm, '对象', 'object'),
    sources: getFieldTree(field_perm),
  });
  // 合并
  let tree = {};
  [menuTree, apiTree, elementTree, objectTree].forEach((item) => {
    tree = merge({ target: tree, sources: item });
  });
  return getTreeNode(tree);
};

export const getData = ({
  // 配置
  menus,
  apis,
  elements,
  fields,
  objects,
  roles,
  // 权限
  menu_perm,
  api_perm,
  element_perm,
  object_perm,
  field_perm,
}) => {
  const objectTree = {};
  objects.forEach((item) => {
    objectTree[item.code] = {};
    objectTree[item.code].object = [
      {
        ...item,
        tier: 'config',
      },
    ];
  });
  const fieldTree = {};
  fields.forEach((item) => {
    if (!fieldTree[item.object_code]) {
      fieldTree[item.object_code] = {};
    }
    if (!fieldTree[item.object_code].field) {
      fieldTree[item.object_code].field = {};
    }
    fieldTree[item.object_code].field[item.code] = [
      {
        ...item,
        tier: 'config',
      },
    ];
  });
  const config = [
    { label: '菜单', children: getConfigChildren(menus, 'code', 'menus') },
    { label: '接口', children: getConfigChildren(apis, 'path') },
    { label: '元素', children: getConfigChildren(elements, 'code') },
    { label: '对象', children: getTreeNode(merge({ target: objectTree, sources: fieldTree })) },
    { label: '角色', children: getConfigChildren(roles, 'code') },
  ];
  const perm = getPerm({ menu_perm, api_perm, element_perm, object_perm, field_perm });
  return {
    config: config.filter((item) => {
      return item.children.length > 0;
    }),
    perm,
  };
};
