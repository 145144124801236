<template>
  <el-card class="app-container">
    <el-page-header @back="goBack" content="字段集"></el-page-header>
    <br />
    <br />
    <div class="top">
      <e-desc :data="editingObject" margin="0 12px" label-width="100px" v-if="editingObject.id">
        <e-desc-item label="对象名">
          {{ editingObject.name }}({{ editingObject.display_name }})
        </e-desc-item>
        <e-desc-item label="租户">{{ editingObject.tenant_id }}</e-desc-item>
        <e-desc-item label="包名">{{ editingObject.package }}</e-desc-item>
        <e-desc-item label="字段数">{{ list.length }}</e-desc-item>
        <e-desc-item label="操作" v-if="jurisdiction.metaManage">
          <template slot="content">
            <el-button class="float-right" type="primary" @click="itemAdd">新增字段</el-button>
          </template>
        </e-desc-item>
      </e-desc>
    </div>
    <el-table
      v-loading="loading"
      :data="list"
      border
      fit
      highlight-current-row
      class="component-table"
    >
      <el-table-column prop="" label="ID" align="center" width="60">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.id">
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="" label="对象ID" align="center" width="80">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.object_id">
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="列名" align="center" width="160" />
      <el-table-column
        prop="display_name"
        align="center"
        label="中文名"
        width="160"
      ></el-table-column>
      <el-table-column align="center" label="描述">
        <template slot-scope="scope">
          <code>{{ scope.row.description }}</code>
        </template>
      </el-table-column>
      <el-table-column align="center" label="定义类型">
        <template slot-scope="scope">
          <code>{{ DefineType[scope.row.define_type] }}</code>
        </template>
      </el-table-column>
      <el-table-column align="center" label="列类型">
        <template slot-scope="scope">
          <code>{{ ColumnType[scope.row.column_type] }}</code>
        </template>
      </el-table-column>
      <el-table-column align="center" label="值类型">
        <template slot-scope="scope">
          <code>{{ ValueType[scope.row.value_type] }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本" align="center" width="60" />
      <el-table-column prop="" label="启用" align="center" width="60">
        <template slot-scope="scope">
          <code>{{ scope.row.is_active ? '是' : '否' }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="" label="唯一" align="center" width="60">
        <template slot-scope="scope">
          <code>{{ scope.row.is_unique ? '是' : '否' }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="" label="必填" align="center" width="60">
        <template slot-scope="scope">
          <code>{{ scope.row.is_require ? '是' : '否' }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="" label="dcr" align="center" width="60">
        <template slot-scope="scope">
          <code>{{ scope.row.is_dcr ? '开启' : '关闭' }}</code>
        </template>
      </el-table-column>
      <el-table-column prop="" label="加密" align="center" width="60">
        <template slot-scope="scope">
          <code>{{ scope.row.encrypt ? '是' : '否' }}</code>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新于">
        <template slot-scope="scope">
          <el-tooltip :content="`${scope.row.update_by}, ${humanifyDate(scope.row.update_time)}`">
            <span>{{ relativeDate(scope.row.update_time) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建于">
        <template slot-scope="scope">
          <el-tooltip :content="`${scope.row.create_by}, ${humanifyDate(scope.row.create_time)}`">
            <span>{{ relativeDate(scope.row.create_time) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="itemEdit(scope.row)">编辑</el-button>
          <el-button
            type="text"
            size="small"
            @click="itemDelete(scope.row)"
            v-if="jurisdiction.metaManage"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="dialogVisible"
      title="详情"
      width="70%"
      :before-close="dialogBeforeClose"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="formData" label-width="140px" :rules="formRules">
        <el-form-item label="ID" v-if="!dialogIsCreate">
          <code>{{ formData.id }}</code>
        </el-form-item>
        <el-form-item label="对象ID">
          <code>{{ formData.object_id }}</code>
        </el-form-item>
        <el-form-item label="列名">
          <el-input v-model="formData.name" :disabled="!dialogIsCreate" />
        </el-form-item>
        <el-form-item label="中文名">
          <el-input v-model="formData.display_name" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formData.description" type="textarea" />
        </el-form-item>
        <el-form-item label="设置默认值">
          <el-tooltip
            class="item"
            effect="dark"
            content="开启了设置默认值，默认值不填为默认空字符串"
            placement="top"
          >
            <el-switch v-model="formData.set_default"></el-switch>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="默认值" v-if="formData.set_default">
          <el-input v-model="formData.default" />
        </el-form-item>
        <el-form-item label="定义类型">
          <el-select v-model="formData.define_type">
            <el-option
              v-for="item in defineTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="列类型">
          <el-select v-model="formData.column_type">
            <el-option v-for="(v, k) in ColumnType" :key="k" :label="v" :value="k" />
          </el-select>
        </el-form-item>
        <el-form-item label="值类型">
          <el-select v-model="formData.value_type" :disabled="!dialogIsCreate">
            <el-option v-for="(v, k) in ValueType" :key="k" :label="v" :value="k" />
          </el-select>
          <el-button
            class="left-margin"
            @click="addDomain"
            v-if="['SELECT_ONE', 'SELECT_MANY', 'SELECT_ONE_INT'].includes(formData.value_type)"
          >
            添加选项
          </el-button>
          <br />
        </el-form-item>
        <el-form-item label="编码格式" prop="format" v-show="formData.value_type === 'AUTO_NUMBER'">
          <el-input
            class="dynamic"
            v-model="formData.format"
            placeholder="请输入编码格式"
          ></el-input>
          &nbsp;
          <span class="auto-mumber-tips">
            实例：HCP{0000},编码为：HCP0001
            <el-link type="primary" @click="onOpenAutoNumberInfo">详细说明</el-link>
          </span>
        </el-form-item>
        <el-form-item
          :label="dialogIsCreate ? '为现有记录生成编号' : '刷新现有记录'"
          prop="update_value"
          v-show="formData.value_type === 'AUTO_NUMBER'"
        >
          <el-switch v-model="formData.update_value"></el-switch>
          &nbsp;
          <span class="auto-mumber-tips">
            若选择此选项则为已有记录重新生成编码，否则只对新纪录生效
          </span>
        </el-form-item>
        <el-form-item
          :label="`选项${index + 1}`"
          v-for="(domain, index) in optionList"
          :key="index"
        >
          label:
          <el-input class="dynamic" v-model="domain.label" placeholder="请输入label"></el-input>
          ,value:
          <el-input
            v-if="formData.value_type === 'SELECT_ONE_INT'"
            class="dynamic"
            type="number"
            v-model.number="domain.value"
            placeholder="请输入value"
            :disabled="index <= oldOptionLen - 1"
          ></el-input>
          <el-input
            class="dynamic"
            v-else
            v-model="domain.value"
            placeholder="请输入value"
            :disabled="index <= oldOptionLen - 1"
          ></el-input>
          ,class:
          <el-select v-model="domain.class" placeholder="请选择" clearable>
            <el-option
              v-for="classItem in classOptions"
              :key="classItem"
              :label="classItem"
              :value="classItem"
            ></el-option>
          </el-select>
          <!-- 删除按钮 -->
          <el-button
            class="left-margin"
            type="danger"
            icon="el-icon-delete"
            circle
            @click.prevent="removeDomain(index)"
          ></el-button>
        </el-form-item>
        <template v-if="formData.value_type === 'TEXT'">
          <el-form-item label="子类型">
            <el-select
              placeholder="请选择"
              v-model="text_option.type"
              :disabled="disabledTextSubtype"
            >
              <el-option label="空" value="" />
              <el-option
                v-for="(label, val) in textSubtypes"
                :key="val"
                :label="label"
                :value="val"
              />
            </el-select>
            &nbsp;
            <span class="tips">子类型确认后不可修改</span>
          </el-form-item>
          <el-form-item label="是否设置掩码">
            <el-tooltip
              class="item"
              effect="dark"
              content="通过设置DataServer/mask接口RPC权限是否展示完整数据"
              placement="top"
            >
              <el-switch v-model="text_option.set_mask"></el-switch>
            </el-tooltip>
          </el-form-item>
          <template v-if="text_option.set_mask">
            <el-form-item label="正则表达式">
              <el-input v-model="text_option.mask_rule.pattern" :controls="false" />
            </el-form-item>
            <el-form-item label="替换表达式">
              <el-input v-model="text_option.mask_rule.replacement" :controls="false" />
            </el-form-item>
          </template>
        </template>

        <el-form-item label="版本" v-if="dialogIsCreate">
          <el-input-number v-model="formData.version" :controls="false" />
        </el-form-item>
        <el-form-item label="">
          <label slot="label">
            可用？
            <el-tooltip
              class="item"
              effect="dark"
              content="不可用的字段无法通过GraphQL接口访问和操作该字段的数据（暂不支持，默认为可用）"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-checkbox v-model="formData.is_active" />
        </el-form-item>
        <el-form-item label="">
          <label slot="label">
            唯一？
            <el-tooltip
              class="item"
              effect="dark"
              content="是否唯一索引，保证字段数据不重复"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-checkbox v-model="formData.is_unique" :disabled="disabledIsUnique" />
        </el-form-item>
        <el-form-item label="必填？">
          <el-checkbox v-model="formData.is_require" />
        </el-form-item>
        <el-form-item label="">
          <label slot="label">
            是否开启dcr？
            <el-tooltip
              class="item"
              effect="dark"
              content="开启DCR的字段不能直接修改值，需要走审批流程"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-checkbox v-model="formData.is_dcr" :disabled="disabledDCR" />
        </el-form-item>
        <el-form-item label="加密？">
          <label slot="label">
            加密？
            <el-tooltip
              class="item"
              effect="dark"
              content="开启加密后，该字段的数据会加密存储，一般用于敏感信息存储，如银行账号等，开启此选项会影响性能"
              placement="top-start"
            >
              <i class="el-icon-info pointer"></i>
            </el-tooltip>
          </label>
          <el-checkbox v-model="formData.encrypt" :disabled="disabledEncrypt" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="jurisdiction.metaManage">
          应用
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="autoNumberInfoDialogVisible"
      title="编码格式详细说明"
      width="60%"
      @close="onCloseInfoDialog"
    >
      <h3>编码说明</h3>
      <el-table :data="autoNumberIllustrate" style="width: 100%">
        <el-table-column prop="format" label="格式" width="180">
          <template slot-scope="scope">
            <span v-html="scope.row.format"></span>
          </template>
        </el-table-column>
        <el-table-column prop="required" label="是否必填" width="180"></el-table-column>
        <el-table-column prop="illustrate" label="说明"></el-table-column>
      </el-table>
      <h3>编码示例</h3>
      <el-table :data="autoNumberExample" style="width: 100%">
        <el-table-column prop="format" label="编码格式" width="180"></el-table-column>
        <el-table-column prop="number" label="序列编号" width="180">
          <template slot-scope="scope">
            <span v-html="scope.row.number"></span>
          </template>
        </el-table-column>
        <el-table-column prop="encodeNumber" label="编码后的编号">
          <template slot-scope="scope">
            <span v-html="scope.row.encodeNumber"></span>
          </template>
        </el-table-column>
        <el-table-column prop="comment" label="备注"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 删除提示(索引列表) -->
    <el-dialog title="字段关联索引列表" :visible.sync="showIndexList" :close-on-click-modal="false">
      <el-table :data="indexList">
        <el-table-column property="index_name" label="索引名称"></el-table-column>
        <el-table-column property="description" label="描述"></el-table-column>
        <el-table-column prop="fields" label="字段列表">
          <template slot-scope="scope">
            {{ scope.row.fields.join(',') }}
          </template>
        </el-table-column>
        <el-table-column label="是否唯一约束">
          <template slot-scope="scope">
            {{ scope.row.is_unique ? '是' : '否' }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showIndexList = false">取 消</el-button>
        <el-button type="primary" @click="daleteData">删 除</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { DEFINE_TYPE, VALUE_TYPE, COLUMN_TYPE, CLASS_OPTIONS } from '@/utils/constant';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import desc from '@/components/desc';
import descItem from '@/components/desc/item';

dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const textSubtypes = {
  id_card: '身份证',
  phone: '手机号',
  public_attachment: '公有附件',
  private_attachment: '私有附件',
  txmap_poi: '腾讯地图-POI',
};

const FieldDefaults = {
  id: '',
  object_id: '',
  name: '',
  display_name: '',
  description: '',
  define_type: 'PACKAGE',
  column_type: 'STANDARD',
  value_type: 'TEXT',
  format: '',
  update_value: false,
  version: 0,
  is_active: false,
  is_unique: false,
  is_require: false,
  is_dcr: false,
  default: '',
  ext: '',
  create_by: '',
  create_time: '',
  update_by: '',
  update_time: '',
  select_one_option: undefined,
  select_many_option: undefined,
  select_one_int_option: undefined,
  text_option: undefined,
  encrypt: false, // 是否加密
  set_default: false,
};

const copy = (o) => JSON.parse(JSON.stringify(o));

export default {
  name: 'ObjectList',
  components: {
    eDesc: desc,
    eDescItem: descItem,
  },
  data() {
    return {
      DefineType: DEFINE_TYPE,
      ValueType: VALUE_TYPE,
      ColumnType: COLUMN_TYPE,
      classOptions: CLASS_OPTIONS,
      dialogVisible: false,
      dialogIsCreate: false,
      queryParams: {
        object_id: '',
      },
      editingObject: {},
      loading: true,
      list: [],
      formData: {
        id: '',
        object_id: '',
        name: '',
        display_name: '',
        description: '',
        define_type: 'PACKAGE',
        column_type: 'STANDARD',
        value_type: 'TEXT',
        format: '',
        update_value: false,
        version: 0,
        is_active: false,
        is_unique: false,
        is_require: false,
        is_dcr: false,
        default: '',
        ext: '',
        create_by: '',
        create_time: '',
        update_by: '',
        update_time: '',
        select_one_option: undefined,
        select_many_option: undefined,
        select_one_int_option: undefined,
        text_option: undefined,
        encrypt: false, // 是否加密
        set_default: false,
      },
      one_option: [{ value: '', label: '', class: '' }], // 单选选项
      one_int_option: [{ value: '', label: '', class: '' }], // 单选（int）
      many_optio: [{ value: '', label: '', class: '' }], // 多选选项
      oldOptionLen: -1, // 用来记录编辑页面已选选项长度
      // 文本类型
      text_option: {
        type: '', // 子类型
        set_mask: false,
        mask_rule: {
          pattern: '',
          replacement: '',
        },
      },
      disabledTextSubtype: false, // 是否禁用文本子类型下拉框
      disabledIsUnique: false, // 是否禁用唯一选择
      disabledEncrypt: false, // 是否禁用加密
      disabledDCR: false, // 是否禁用dcr
      textSubtypes,
      total: 0,
      // 删除
      indexList: [],
      showIndexList: false,
      deleteField: null,
      autoNumberInfoDialogVisible: false,
      availableFieldName: [],
      fieldNameRegs: [],
      autoNumberIllustrate: [
        {
          format: '{0}',
          required: '必需',
          illustrate:
            '序列编号。包括在花括号中的一个或多个零代表序列编号本身。花括号中零的个数表示将显示的最小数字位数。如果实际编号的位数小于此数，将在其前边置零。',
        },
        {
          format: '{YY}<br>{YYYY}',
          required: '可选',
          illustrate:
            '年份。包括在花括号中的两个或四个"Y"字符，代表记录创建日期的年份。如显示年份的两位（如"23"）或全部的四位（如"2023"）。',
        },
        {
          format: '{MM}',
          required: '可选',
          illustrate:
            '月份。2 花括号中包括的两位字符表示记录创建日期的数字月份（如"01"表示一月，"02"表示二月）。',
        },
        {
          format: '{DD}',
          required: '可选',
          illustrate:
            '日期。2 花括号中包括的两位字符表示记录创建日期的数字日期（如"01"至"31"都是一月的有效日期）。',
        },
        {
          format: '{Columnname}',
          required: '可选',
          illustrate: '字段名，限定在当前对象的字段名，取字段中的值作为编码中的一部分',
        },
      ],
      autoNumberExample: [
        {
          format: '{0}',
          number: '1<br>250<br>5000',
          encodeNumber: '1<br>250<br>5000',
          comment: '无前置零，按序列号实际位数存储。',
        },
        {
          format: '{000}',
          number: '1<br>250<br>5000',
          encodeNumber: '001<br>250<br>5000',
          comment: '包括前置零，最多 3 位，超过则按实际序号展示。',
        },
        {
          format: '{000000}',
          number: '1<br>250<br>5000',
          encodeNumber: '000001<br>000250<br>005000',
          comment: '包括前置零，最多 6 位，超过则按实际序号展示。',
        },
        {
          format: '{YYYY}{MM}-{0}',
          number: '1<br>250<br>5000',
          encodeNumber: '202301-1<br>202301-250<br>2023015000',
          comment: '4 位年份及月份用作编号前缀。',
        },
        {
          format: 'BU1-{00000000}',
          number: '1<br>250<br>5000',
          encodeNumber: 'BU1-00000001<br>BU1-00000250<br>BU1-00005000',
          comment: '以"BU1-"（包括中划线）为前缀',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['getElementList', 'getTenantInfo']),
    jurisdiction() {
      return {
        metaManage: this.getElementList.includes('meta-manage'),
      };
    },
    optionList: {
      get() {
        switch (this.formData.value_type) {
          case 'SELECT_ONE':
            return this.one_option;
          case 'SELECT_ONE_INT':
            return this.one_int_option;
          case 'SELECT_MANY':
            return this.many_optio;
          default:
            return [];
        }
      },
      set(value) {
        switch (this.formData.value_type) {
          case 'SELECT_ONE':
            this.one_option = value;
            break;
          case 'SELECT_ONE_INT':
            this.one_int_option = value;
            break;
          case 'SELECT_MANY':
            this.many_optio = value;
            break;
          default:
        }
      },
    },
    formRules() {
      if (this.formData.value_type === 'AUTO_NUMBER') {
        const checkAutoNumberOption = (rule, value, callback) => {
          const regs = this.fieldNameRegs;
          if (this.formData.value_type !== 'AUTO_NUMBER') {
            return callback();
          }
          if (value === '') {
            return callback(new Error('编码规则不能为空'));
          }
          const reg = /([^{}]{1,}|({0+}){1,}|({YY}){1,}|({YYYY}){1,}|({DD}){1,}|({MM}){1,}){1,}/;
          const mustHaveSequence = /{0+}+/;
          if (!mustHaveSequence.test(value)) {
            return callback(new Error('自动编码必须拥有序列编号'));
          }
          console.log('是否存在序列：', mustHaveSequence.test(value));
          if (!reg.test(value)) {
            return callback(new Error('编码规则不正确'));
          }
          console.log('是否符合规则：', mustHaveSequence.test(value));
          const fieldTestResults = regs.map((reg) => {
            return reg.test(value);
          });
          console.log('---fieldTestResults---', fieldTestResults);
          const fieldTestResult = fieldTestResults.reduce((acc, cur) => {
            return acc || cur;
          });
          if (!fieldTestResult) {
            return callback(new Error('无法获取字段'));
          }
          return callback();
        };
        return {
          format: [
            { validator: checkAutoNumberOption, trigger: 'blur' },
            { required: true, rigger: 'blur', message: '编码规则不能为空' },
          ],
        };
      }
      return {};
    },
    defineTypeOption() {
      let DefineType = [];
      Object.keys(this.DefineType).forEach((key) => {
        DefineType.push({
          label: this.DefineType[key],
          value: key,
        });
      });
      if (this.getTenantInfo.tenant_type === 'extend') {
        DefineType = DefineType.filter((item) => item.value === 'CUSTOM');
      }
      return DefineType;
    },
  },
  watch: {
    'formData.value_type'() {},
  },
  mounted() {
    this.queryParams.object_id = this.$route.query.object_id;

    this.getList();
    this.getEditingObject();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    humanifyDate(str) {
      return dayjs(str).format('lll');
    },
    relativeDate(str) {
      return dayjs(str).fromNow();
    },
    addDomain() {
      this.optionList = [...this.optionList, { value: '', label: '', class: '' }];
    },
    removeDomain(index) {
      if (index > this.oldOptionLen - 1) {
        this.optionList.splice(index, 1);
      }
    },
    saveOptions() {
      if (this.formData.value_type === 'SELECT_ONE') {
        this.formData.select_one_option = {
          options: copy(this.optionList),
        };
      } else if (this.formData.value_type === 'SELECT_MANY') {
        this.formData.select_many_option = {
          options: copy(this.optionList),
        };
      } else if (this.formData.value_type === 'SELECT_ONE_INT') {
        this.formData.select_one_int_option = {
          options: copy(this.optionList),
        };
      } else if (this.formData.value_type === 'TEXT') {
        const { set_mask: mask, mask_rule: rule, type } = this.text_option;
        this.formData.text_option = {
          type,
          set_mask: mask,
          mask_rule: mask ? rule : { pattern: '', replacement: '' },
        };
      }
    },
    getOptions() {
      if (this.formData.value_type === 'SELECT_ONE') {
        this.oldOptionLen = this.formData.select_one_option.options.length;
        this.optionList = this.formData.select_one_option.options;
      } else if (this.formData.value_type === 'SELECT_MANY') {
        this.oldOptionLen = this.formData.select_many_option.options.length;
        this.optionList = this.formData.select_many_option.options;
      } else if (this.formData.value_type === 'SELECT_ONE_INT') {
        this.oldOptionLen = this.formData.select_one_int_option.options.length;
        this.optionList = this.formData.select_one_int_option.options;
      } else if (this.formData.value_type === 'TEXT') {
        this.text_option = this.formData.text_option;
        this.disabledTextSubtype = true;
      } else if (this.formData.value_type === 'AUTO_NUMBER') {
        this.disabledDCR = true;
        this.disabledEncrypt = true;
      }
    },
    cearOption() {
      this.one_option = [{ value: '', label: '', class: '' }];
      this.many_optio = [{ value: '', label: '', class: '' }];
      this.one_int_option = [{ value: '', label: '', class: '' }];
      this.text_option = {
        set_mask: false,
        type: '',
        mask_rule: {
          pattern: '',
          replacement: '',
        },
      };
    },
    // 增加字段
    itemAdd() {
      this.formData = copy(FieldDefaults);
      this.formData.object_id = this.queryParams.object_id;
      this.formData.define_type =
        this.getTenantInfo.tenant_type === 'extend' ? 'CUSTOM' : 'PACKAGE';
      console.log('------this.formData------', this.formData);
      this.dialogVisible = true;
      this.dialogIsCreate = true;
      this.disabledTextSubtype = false;
      this.disabledIsUnique = false;
      this.disabledDCR = false;
      this.disabledEncrypt = false;
      this.oldOptionLen = -1;
    },
    // 编辑字段
    itemEdit(row) {
      const data = copy(row);
      this.formData = {
        ...data,
        format: data?.auto_number_option?.format || '',
        update_value: data?.auto_number_option?.update_value || false,
      };
      this.disabledDCR = false;
      this.disabledEncrypt = false;
      this.oldOptionLen = -1;
      this.disabledIsUnique = true;
      this.getOptions();
      this.dialogVisible = true;
      this.dialogIsCreate = false;
    },
    async itemDelete({ id }) {
      this.$request({
        url: this.$api.metaGetFieldIndexesByID,
        data: { id },
        cb: ({ index }) => {
          if (index.length > 0) {
            this.deleteField = id;
            // 展示索引信息
            this.indexList = index;
            this.$nextTick(() => {
              this.showIndexList = true;
            });
            return;
          }
          this.daleteData({ id });
        },
      });
    },
    // 删除再次询问弹窗
    async daleteData({ id = this.deleteField }) {
      await this.$confirm('删除该分组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });

      // await DeleteField({ id });
      this.$request({
        url: this.$api.metaDeleteField,
        data: { id },
        cb: () => {
          this.showIndexList = false;
          this.getList();
        },
      });
    },
    handleCurrentChange(page) {
      // 翻页
      this.queryParams.page = page;
      this.getList();
    },
    handleSizeChange(size) {
      // 一页显示条数修改
      this.queryParams.page_size = size;
      this.getList();
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      this.$request({
        url: this.$api.metaGetObjectFields,
        data: this.queryParams,
        cb: ({ count, fields }) => {
          this.total = parseInt(count, 10);
          this.list = fields;
          this.loading = false;
          this.availableFieldName = this.list
            .filter((item) => {
              return ['INT', 'TEXT', 'SELECT_ONE_INT', 'FLOAT'].includes(item.value_type);
            })
            .map((item) => item.name);
          this.fieldNameRegs = this.availableFieldName.map((name) => {
            return new RegExp(
              `^([^{}]{1,}|({0+}){1,}|({YY}){1,}|({YYYY}){1,}|({DD}){1,}|({MM}){1,}|({${name}}){1,}){1,}$`,
            );
          });
          console.log('---availableFieldName---', this.availableFieldName);
          console.log('---fieldNameRegs---', this.fieldNameRegs);
        },
      });
    },
    // 关闭时，再次询问弹窗
    async dialogBeforeClose() {
      try {
        await this.$confirm('所作更改将不会被保存，确认关闭？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        this.dialogVisible = false;
        this.cearOption();
      } catch (err) {
        console.log(err);
      }
    },
    onOpenAutoNumberInfo() {
      this.dialogVisible = false;
      this.autoNumberInfoDialogVisible = true;
    },
    onCloseInfoDialog() {
      this.dialogVisible = true;
    },
    applyKeywordFilter() {
      this.queryParams.offset = 1;
      this.getList();
    },
    submitForm(formName) {
      console.log('!!!!!!submitForm!!!!!!');
      this.$refs[formName].validate((valid) => {
        console.log('!!!!!!submitForm!!!!!!', valid);
        if (valid) {
          this.saveOptions();
          if (this.formData.value_type === 'TEXT') {
            try {
              if (this.text_option.set_mask) {
                console.log(new RegExp(this.text_option.mask_rule.pattern));
              }
            } catch (err) {
              console.log(err);
              this.$message.error('正则语法错误');
              return;
            }
          }
          const formData = copy(this.formData);
          if (formData.value_type === 'AUTO_NUMBER') {
            formData.auto_number_option = {
              format: formData.format,
              update_value: formData.update_value,
            };
            delete formData.text_option;
          }
          delete formData.format;
          delete formData.update_value;
          if (!formData.set_default) {
            formData.default = '';
          }
          const cb = () => {
            this.dialogVisible = false;
            this.cearOption();
            this.getList();
            this.$message.success('提交成功');
          };
          if (this.dialogIsCreate) {
            // await AddField({ field: formData });
            this.$request({
              url: this.$api.metaAddField,
              data: {
                field: {
                  ...formData,
                  name: formData.define_type === 'CUSTOM' ? `${formData.name}__c` : formData.name,
                },
              },
              cb,
            });
          } else {
            // await UpdateField({
            //     id: formData.id,
            //     field: formData,
            // });
            this.$request({
              url: this.$api.metaUpdateField,
              data: {
                id: formData.id,
                field: formData,
              },
              cb,
            });
            // await SetFieldEncrypt({
            //     id: formData.id,
            //     action: formData.encrypt ? 1 : 0,
            // });
            this.$request({
              url: this.$api.metaSetFieldEncrypt,
              data: {
                id: formData.id,
                action: formData.encrypt ? 1 : 0,
              },
              cb: () => {},
            });
          }
        } else {
          return false;
        }
      });
    },
    async getEditingObject() {
      const objectId = this.queryParams.object_id;
      this.$request({
        url: this.$api.metaGetObjectDetail,
        data: { id: objectId },
        cb: ({ object }) => {
          this.editingObject = object;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/element/table.scss';
.pad-right {
  margin-right: 8px;
}
.pagination {
  margin-top: 30px;
}
.option-wrapper {
  line-height: 1.8rem;

  .option-tag {
    margin-right: 8px;
  }
}
.top {
  margin-bottom: 20px;

  .float-right {
    float: right;
  }

  &:after {
    display: block;
    content: '';
    clear: both;
  }
}
.dynamic {
  width: 180px;
}
.del {
  margin-left: 10px;
}
.left-margin {
  margin-left: 10px;
}
.tips {
  color: #e43d30;
}
.auto-mumber-tips {
  color: #909399;
  .link {
    color: #409eff;
  }
}
</style>
